import arrayMove from 'array-move';
import React, { useState } from 'react';
import {
	SortableContainer as sortableContainer,
	SortableElement as sortableElement,
	SortableHandle as sortableHandle,
	SortEnd,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import ButtonSecondary from 'components/ButtonSecondary';
import { Icon } from 'components/Icon';
import { Popover } from 'components/Popover';
import { StyledButton } from 'components/Sidebar/SidebarSection';
import { Switch } from 'components/Switch';
import { ReactComponent as DragIcon } from 'images/icons/drag.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { state } from 'valtioState';

const DropdownContainer = styled.ul`
	margin-top: 0.5rem;
	margin-bottom: ${(props) =>
		// @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
		props.children.length ? '1rem' : '0'};
`;

const DropdownRow = styled.li<{ isPrimaryAttribute?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.25rem 0.75rem;
	font-size: 0.875rem;
	user-select: none;
	cursor: ${({ isPrimaryAttribute }) =>
		isPrimaryAttribute ? 'default' : 'move'};

	&:hover {
		background: ${neutral[5]};
	}
`;

const Label = styled.span`
	max-width: 12rem;
	margin-left: 0.75rem;
	color: ${neutral[1]};
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DragHandleContainer = styled.div`
	margin-left: auto;
	padding-left: 0.5rem;
`;

const DragHandle = sortableHandle(() => (
	<DragHandleContainer>
		<Icon>
			<DragIcon />
		</Icon>
	</DragHandleContainer>
));

const SortableItem = sortableElement(
	({
		attribute,
		showTableName,
		onClick,
		sqlDatabaseId,
	}: {
		attribute: ApiRecordAttribute;
		showTableName: boolean;
		onClick: () => void;
		sqlDatabaseId: number;
	}) => {
		const snap = useSnapshot(state);
		const tableDisplayName = getTableDisplayName({
			tablesById: snap.entities.tables.byId,
			tableId: attribute.tableId,
		});
		const attributeDisplayName = getAttributeDisplayName({
			attributesById: snap.entities.attributes.byId,
			attributeNamesToIdMap: snap.attributeNamesToIdMap,
			attributeName: attribute.attributeName,
			tableName: attribute.tableName,
			schemaName: attribute.schemaName,
			sqlDatabaseId: sqlDatabaseId,
		});
		return (
			<DropdownRow>
				<Switch checked={attribute.visible ?? false} onChange={onClick} />
				<Label>
					{showTableName ? `${tableDisplayName}.` : ''}
					{attributeDisplayName}
				</Label>
				<DragHandle />
			</DropdownRow>
		);
	}
);

const SortableContainer = sortableContainer(
	({ children }: { children: React.ReactNode }) => {
		return <DropdownContainer>{children}</DropdownContainer>;
	}
);

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem 0.75rem;
`;

interface ListAttributesPopoverProps {
	data: TableData<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
	listAttributes: ApiRecordAttribute[];
	primaryAttributeApiRecordAttribute: ApiRecordAttribute | undefined;
	listAttributesWithoutPrimaryAttribute: ApiRecordAttribute[];
}

export function ListAttributesPopover({
	data,
	listAttributes,
	primaryAttributeApiRecordAttribute,
	listAttributesWithoutPrimaryAttribute,
	extractedTableData,
}: ListAttributesPopoverProps) {
	const snap = useSnapshot(state);

	const [open, setOpen] = useState(false);

	const { databaseId } = extractedTableData;

	const joins = data.view.type === 'BASIC' ? data.view.joins : [];

	const { mutate: updateApiView } = useUpdateApiView({
		view: data.view,
	});

	const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
		const updatedAttributes = arrayMove(
			listAttributesWithoutPrimaryAttribute ?? [],
			oldIndex,
			newIndex
		);
		updateApiView({
			view: {
				id: data.view.id,
				listAttributes: primaryAttributeApiRecordAttribute
					? [primaryAttributeApiRecordAttribute, ...updatedAttributes]
					: updatedAttributes,
			},
		});
	};

	const showAllAttributes = () => {
		const updatedAttributes =
			listAttributes?.map((column) => ({
				...column,
				visible: true,
			})) ?? [];
		updateApiView({
			view: {
				id: data.view.id,
				listAttributes: updatedAttributes,
			},
		});
	};

	const hideAllAttributes = () => {
		const updatedAttributes =
			listAttributes?.map((column) => ({
				...column,
				visible: false,
			})) ?? [];
		updateApiView({
			view: {
				id: data.view.id,
				listAttributes: updatedAttributes,
			},
		});
	};

	const updateAttributeVisibility = (
		columnSchema: string,
		columnTable: string,
		columnName: string,
		visible: boolean
	) => {
		const updatedAttributes =
			listAttributes?.map((column) =>
				column.schemaName === columnSchema &&
				column.tableName === columnTable &&
				column.attributeName === columnName
					? {
							...column,
							visible,
					  }
					: column
			) ?? [];
		updateApiView({
			view: {
				id: data.view.id,
				listAttributes: updatedAttributes,
			},
		});
	};

	const handleBlur = async () => {
		setOpen(false);
	};

	const showTableName = joins.length > 0;

	return (
		<Popover
			trigger={
				<StyledButton
					title="Add attribute"
					icon={<PlusIcon />}
					primary={open}
				/>
			}
			side="right"
			align="start"
			onBlur={handleBlur}
			open={open}
			setOpen={setOpen}
		>
			<SortableContainer
				onSortEnd={onSortEnd}
				lockAxis="y"
				// Required in order to allow switch to be clickable
				distance={3}
				transitionDuration={200}
				helperClass="sortableHelper"
			>
				{primaryAttributeApiRecordAttribute && (
					<DropdownRow isPrimaryAttribute>
						<Switch checked={true} disabled />
						<Label>
							{showTableName
								? `${getTableDisplayName({
										tablesById: snap.entities.tables.byId,
										tableId: primaryAttributeApiRecordAttribute.tableId,
								  })}`
								: ''}
							{getAttributeDisplayName({
								attributesById: snap.entities.attributes.byId,
								attributeNamesToIdMap: snap.attributeNamesToIdMap,
								attributeName: primaryAttributeApiRecordAttribute.attributeName,
								tableName: primaryAttributeApiRecordAttribute.tableName,
								schemaName: primaryAttributeApiRecordAttribute.schemaName,
								sqlDatabaseId: databaseId,
							})}
						</Label>
					</DropdownRow>
				)}
				{listAttributesWithoutPrimaryAttribute.map((column, index) => (
					<SortableItem
						key={`${column.schemaName}/${column.tableName}/${column.attributeName}`}
						index={index}
						attribute={column}
						showTableName={showTableName}
						sqlDatabaseId={databaseId}
						onClick={() => {
							updateAttributeVisibility(
								column.schemaName,
								column.tableName,
								column.attributeName,
								!column.visible
							);
						}}
					/>
				))}
			</SortableContainer>

			<ButtonContainer>
				<ButtonSecondary onClick={hideAllAttributes}>Hide all</ButtonSecondary>
				<ButtonSecondary onClick={showAllAttributes}>Show all</ButtonSecondary>
			</ButtonContainer>
		</Popover>
	);
}
