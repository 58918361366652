import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useDeleteApiRecords } from 'api/reactQueryHooks/useDeleteApiRecords';
import _ButtonPrimary from 'components/Button';
import { deleteRecords } from 'reduxState/actions';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { canWriteViewData } from 'utils/permissions';
import { useCollaborator } from 'utils/useCollaborator';
import { state } from 'valtioState';

const ButtonPrimary = styled(_ButtonPrimary)`
	padding: 0.5rem 0.75rem;
`;

interface DeleteRecordsButtonProps {
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	pageNumber: number;
	searchQuery: string;
	reload?: () => void;
}

export default function DeleteRecordsButton({
	data,
	pageNumber,
	searchQuery,
	extractedTableData,
	reload,
}: DeleteRecordsButtonProps) {
	const snap = useSnapshot(state);
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const collaborator = useCollaborator();
	const { databaseId, schemaName, tableName, tableId } = extractedTableData;
	const sqlDatabase = snap.entities.sqlDatabases.byId[databaseId];

	const { mutate: deleteApiRecords } = useDeleteApiRecords({
		queryClient,
		dataSourceId: sqlDatabase?.dataSourceId ?? 0,
		data,
		pageNumber,
		searchQuery,
		onSuccess: reload,
	});
	const selectedRecords = useSelector((state) => state.selectedRecords);

	const deleteSelectedRecords = async () => {
		if (databaseId === null || schemaName === null || tableName === null) {
			return;
		}
		dispatch(
			deleteRecords({
				databaseId,
				schemaName,
				tableName,
				recordIds: selectedRecords,
			})
		);
		deleteApiRecords({
			tableId,
			recordIds: selectedRecords,
			collaborator,
		});
	};

	if (selectedRecords.length === 0) {
		return null;
	}

	if (!canWriteViewData(collaborator)) {
		return null;
	}

	return (
		<ButtonPrimary onClick={() => deleteSelectedRecords()} danger>
			Delete {selectedRecords.length}{' '}
			{selectedRecords.length === 1 ? 'record' : 'records'}
		</ButtonPrimary>
	);
}
