import { useRef } from 'react';
import styled from 'styled-components';

import { useTheme } from 'components/providers/ThemeProvider';
import { background, neutral, primary } from 'utils/colors';
import { media } from 'utils/styles';

const DatabaseOptionContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	color: ${neutral[1]};
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 0 1px ${neutral[3]};
	}
`;

const HiddenDatabaseRadioInput = styled.input.attrs({ type: 'radio' })`
	opacity: 0;
	position: absolute;

	&:checked + ${DatabaseOptionContent} {
		box-shadow: inset 0 0 0 1px ${primary[1]}, 0 0 0 1px ${primary[1]};
	}
`;

const DatabaseRadioWrapper = styled.div`
	position: relative;
	background-color: ${background[1]};

	z-index: ${(props: { checked?: boolean }) => (props.checked ? 1 : 'unset')};
`;

const DatabaseImage = styled.img`
	height: 2.5rem;
	max-width: 3.75rem;
`;

const DatabaseName = styled.label`
	margin-top: 1rem;
`;

interface OptionProps {
	image: string;
	/** Image source if the image should have a different variation in dark mode */
	imageDarkMode?: string | null;
	name: string;
	checked: boolean;
	onChange: () => void;
	value: string;
}

function Option({
	checked,
	image,
	imageDarkMode,
	name,
	onChange,
	value,
}: OptionProps) {
	const { themeMode } = useTheme();
	const radioInputRef = useRef<HTMLInputElement>(null);
	const handleClick = (): void => {
		if (radioInputRef.current) {
			radioInputRef.current.focus();
			onChange();
		}
	};

	const handleChange = (): void => {
		onChange();
	};

	return (
		<DatabaseRadioWrapper onClick={handleClick} checked={checked}>
			<HiddenDatabaseRadioInput
				checked={checked}
				id={value}
				ref={radioInputRef}
				value={value}
				onChange={handleChange}
			/>
			<DatabaseOptionContent>
				<DatabaseImage
					src={themeMode === 'dark' && imageDarkMode ? imageDarkMode : image}
					alt={name}
				/>
				<DatabaseName htmlFor={value}>{name}</DatabaseName>
			</DatabaseOptionContent>
		</DatabaseRadioWrapper>
	);
}

const StyledOptionsGrid = styled.div`
	display: grid;
	grid-gap: 1px;
	background-color: ${neutral[4]};
	box-shadow: 0 0 0 1px ${neutral[4]};

	${media.desktop`
		grid-template-columns: 1fr 1fr 1fr;

		& > *:nth-child(3n-1):nth-last-of-type(1) {
			grid-column: span 2;
		}

		& > *:nth-child(3n-2):nth-last-of-type(1) {
			grid-column: span 3;
		}
	`}

	${media.tablet`
		grid-template-columns: 1fr;
	`}
`;

interface Props {
	options: {
		type: string;
		name?: string;
		image: string;
		imageDarkMode?: string | null;
	}[];
	onChange: (type: string) => void;
	checkedType: string | null | undefined;
}

export const OptionsGrid = ({ options, checkedType, onChange }: Props) => {
	return (
		<StyledOptionsGrid>
			{options.map(({ type, name, image, imageDarkMode }) => (
				<Option
					key={type}
					value={type}
					image={image}
					imageDarkMode={imageDarkMode}
					name={name ?? type}
					checked={type === checkedType}
					onChange={() => onChange(type)}
				/>
			))}
		</StyledOptionsGrid>
	);
};
