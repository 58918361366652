import * as Sentry from '@sentry/browser';
import {
	isValid as dateFnsIsValid,
	parseISO as dateFnsParseISO,
} from 'date-fns';

import { ColumnType } from 'typings/models';

export const parseISO = ({
	type,
	value,
}: {
	type: ColumnType;
	value: string;
}): Date | null => {
	let parsedDate: Date | null =
		type === 'TIME'
			? // date-fns doesn't support times without dates so
			  // we need to add an arbitrary date to the value
			  dateFnsParseISO(`2000-01-01T${value}`)
			: dateFnsParseISO(value);

	if (!dateFnsIsValid(parsedDate)) {
		Sentry.captureException(new Error('Invalid ISO date found'), {
			contexts: { value: { value } },
		});
		parsedDate = null;
	}
	return parsedDate;
};
