import { AlertDialog } from 'components/AlertDialog';

interface DeleteViewAlertDialogProps {
	viewName: string;
	workspaceName: string;
	open: boolean;
	onOpenChange(open: boolean): void;
	onConfirm: () => void;
}

export function DeleteViewAlertDialog({
	viewName,
	workspaceName,
	open,
	onOpenChange,
	onConfirm,
}: DeleteViewAlertDialogProps) {
	return (
		<AlertDialog
			open={open}
			onOpenChange={onOpenChange}
			title={'Delete view'}
			description={
				<>
					Are you sure you want to delete the <b>{viewName}</b> view? This will
					permanently delete it for everyone in the <b>{workspaceName}</b>{' '}
					workspace.
				</>
			}
			actionText={'Delete'}
			actionType={'danger'}
			onConfirm={onConfirm}
		/>
	);
}
