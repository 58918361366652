import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Page from 'components/Page';
import { SectionContainer } from 'components/SectionContainer';
import DatabaseOverview from 'components/sections/DatabaseOverview';
import RemoveDataSource from 'components/sections/RemoveDataSource';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import AppPageNotFoundRoute from 'routes/AppPageNotFoundRoute';
import { ApiUser } from 'typings/serverTypes';
import { canManageDataSources } from 'utils/permissions';
import { state } from 'valtioState';

const DatabaseSettings = () => {
	const snap = useSnapshot(state);
	const params = useParams<{ databaseId: string }>();
	const databaseId = Number(params.databaseId);
	const sqlDatabase = snap.entities.sqlDatabases.byId[databaseId];
	const dataSource =
		sqlDatabase && snap.entities.dataSources.byId[sqlDatabase.dataSourceId];
	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);

	if (!collaborator) {
		return null;
	}

	if (!sqlDatabase || !dataSource) {
		return <AppPageNotFoundRoute />;
	}

	if (!canManageDataSources(collaborator)) {
		return <Redirect to="/" />;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<CloudIcon />} title={dataSource.name} />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						{/* @ts-expect-error Readonly issues */}
						<DatabaseOverview sqlDatabase={sqlDatabase} />
						{/* @ts-expect-error Readonly issues */}
						<RemoveDataSource sqlDatabase={sqlDatabase} />
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
};

export default DatabaseSettings;
