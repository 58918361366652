import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { UserAvatar } from 'components/UserAvatar';
import { User } from 'typings/models';
import { background } from 'utils/colors';

const StyledAvatarList = styled.div`
	// Required to get the linear-gradient working for realtime user avatars
	z-index: 0;
`;

const AvatarListComponent = styled.div<{ avatarColours: string[] }>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	background-clip: padding-box;
	border: solid 1px transparent;
	border-radius: 99999px;
	padding: 3px;
	position: relative;
	background-color: ${background[1]};

	${({ avatarColours }) =>
		avatarColours.length > 0 &&
		css`
			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: -1px;
				border-radius: inherit;
				background: ${avatarColours.length === 1
					? avatarColours[0]
					: `linear-gradient(to right, ${avatarColours.join(', ')});`};
			}
		`}
`;

const UserAvatarContainer = styled.div`
	width: 1.75rem;
	height: 1.75rem;

	& + & {
		margin-left: -0.625rem;
	}
`;

interface Props {
	userIds: number[];
}

export function AvatarList({ userIds }: Props) {
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const usersById = useMemo(() => {
		return (
			workspace?.collaborators.reduce<{ [userId: string]: User }>(
				(prev, curr) => {
					prev[curr.user.id] = curr.user;
					return prev;
				},
				{}
			) ?? {}
		);
	}, [workspace]);

	const users = userIds
		.filter((userId) => !!usersById[userId])
		.slice(0, 3)
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		.map((userId) => usersById[userId]!);

	if (userIds.length === 0) {
		return null;
	}

	return (
		<StyledAvatarList>
			<AvatarListComponent
				avatarColours={users.map((user) => user.avatarColour ?? '#aaa')}
			>
				{users.map((user) => (
					<UserAvatarContainer key={user.id}>
						<UserAvatar user={user} />
					</UserAvatarContainer>
				))}
			</AvatarListComponent>
		</StyledAvatarList>
	);
}
