import { useQuery, UseQueryOptions } from 'react-query';

import {
	BillingInformationResponse,
	fetchBillingInformation,
} from 'api/fetchBillingInformation';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';

export const useApiBillingInformation = (
	workspaceId: number | undefined,
	options?: UseQueryOptions<
		unknown,
		string,
		BillingInformationResponse,
		[string, number?]
	>
) =>
	useQuery<unknown, string, BillingInformationResponse, [string, number?]>(
		[REACT_QUERY_CACHE_KEY.BILLING_INFORMATION, workspaceId],
		async ({ queryKey }) => {
			if (!workspaceId) {
				throw new Error('Workspace not found');
			}

			const [_key] = queryKey;

			const response = await fetchBillingInformation(workspaceId);
			if (!response.ok) {
				throw new ApiError('Network response was not ok');
			}
			return response.json();
		},
		{ enabled: workspaceId !== undefined, ...options }
	);
