import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Tooltip } from 'components/Tooltip';
import { Input } from 'components/fields/Input';
import Select, { SelectOption, SelectProps } from 'components/fields/Select';
import { Switch } from 'components/fields/Switch';

export const Label = styled.label<{ isCentered?: boolean }>`
	font-size: 0.75rem;
	width: 100%;
	margin-top: 0.5rem;
	flex-shrink: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	align-items: ${(props) => (props.isCentered ? 'center' : 'stretch')};
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	overflow: visible; /* required to show box-shadow on focus */
`;

const Container = styled.div`
	padding: 0.5rem 0.5rem 0 0.5rem;
`;

type SettingFieldProps = {
	label: string;
	helpText?: string;
	disabled?: boolean;
	readOnly?: boolean;
	tooltip?: string;
} & (
	| {
			value: string;
			onClick?: never;
			options?: never;
			onChange: (
				e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
			) => void;
			onBlur?: (
				e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
			) => void;
			isTextArea?: boolean;
			readOnly?: never;
	  }
	| {
			value: boolean;
			options?: never;
			onClick: () => void;
			onChange?: never;
			onBlur?: never;
			isTextArea?: never;
			readOnly?: never;
	  }
	| {
			value: string | number;
			options: SelectOption<string | number>[];
			onClick?: never;
			onChange: SelectProps<string | number>['onChange'];
			onBlur?: never;
			isTextArea?: never;
			readOnly?: never;
	  }
	| {
			value: string;
			onClick?: never;
			options?: never;
			onChange?: never;
			onBlur?: never;
			isTextArea?: boolean;
			readOnly: true;
	  }
);
export const SettingField = (props: SettingFieldProps) => {
	const disabled = props.disabled === undefined ? false : props.disabled;
	if (props.options) {
		return (
			<Container>
				<Select
					name="primaryAttribute"
					label="Primary attribute"
					labelWidth={8}
					labelSize={'small'}
					helpText={props.helpText}
					options={props.options}
					value={props.value}
					errors={null}
					onChange={props.onChange}
				/>
			</Container>
		);
	}
	if (typeof props.value === 'boolean') {
		if (!props.onClick) {
			return null;
		}
		return (
			<Tooltip value={props.tooltip}>
				<Container>
					<Switch
						label={props.label}
						labelWidth={8}
						labelSize={'small'}
						helpText={props.helpText}
						checked={props.value}
						onChange={props.onClick}
						disabled={disabled}
					/>
				</Container>
			</Tooltip>
		);
	}
	return (
		<Container>
			{props.isTextArea ? (
				<Input
					as={'textarea'}
					label={props.label}
					labelWidth={8}
					labelSize={'small'}
					helpText={props.helpText}
					value={props.value}
					onChange={props.onChange}
					onBlur={props.onBlur}
				/>
			) : (
				<Input
					label={props.label}
					labelWidth={8}
					labelSize={'small'}
					helpText={props.helpText}
					value={props.value}
					onChange={props.onChange}
					onBlur={props.onBlur}
					disabled={props.disabled}
					readOnly={props.readOnly}
				/>
			)}
		</Container>
	);
};
