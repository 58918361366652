import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ThemeMode, useTheme } from 'components/providers/ThemeProvider';
import styleAuto from 'images/styles/style-auto.svg';
import styleDark from 'images/styles/style-dark.svg';
import styleLight from 'images/styles/style-light.svg';
import { background, neutral, primary } from 'utils/colors';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import styles, { media } from 'utils/styles';

const Container = styled.div`
	display: flex;
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	overflow: hidden;

	${media.mobile`
		flex-direction: column;
	`}
`;

const Item = styled.button<{ active: boolean; onClick: () => void }>`
	height: 10rem;
	color: ${neutral[1]};
	background: ${({ active }) => (active ? neutral[5] : background[1])};
	border: none;
	cursor: pointer;

	&:hover {
		background: ${neutral[4]};
	}

	${media.mobile`
		&& + & {
			border-top: 1px solid ${neutral[4]};
		}
	`}

	${media.tabletUp`
		width: 15rem;

		&& + & {
			border-left: 1px solid ${neutral[4]};
		}
	`}
`;

const Image = styled.img<{ active: boolean }>`
	width: 8rem;
	border-radius: ${styles.global.borderRadius};
	box-shadow: ${styles.shadows[700]};

	${(props) =>
		props.active &&
		css`
			box-shadow: ${styles.shadows[700]}, 0 0 0 2px ${primary[1]};
		`};
`;

const Title = styled.p`
	margin-top: 0.5rem;
`;

export default function ThemePicker() {
	const [style, setStyle] = useState('auto');
	const { updateThemeMode } = useTheme();

	useEffect(() => {
		const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME_MODE);

		if (savedTheme) {
			setStyle(savedTheme);
		}
	}, []);

	const handleClick = (value: ThemeMode | 'auto') => () => {
		setStyle(value);
		updateThemeMode(value);
	};

	return (
		<Container>
			<Item active={style === 'auto'} onClick={handleClick('auto')}>
				<Image src={styleAuto} active={style === 'auto'} />
				<Title>Match system</Title>
			</Item>
			<Item active={style === 'light'} onClick={handleClick('light')}>
				<Image src={styleLight} active={style === 'light'} />
				<Title>Light</Title>
			</Item>
			<Item active={style === 'dark'} onClick={handleClick('dark')}>
				<Image src={styleDark} active={style === 'dark'} />
				<Title>Dark</Title>
			</Item>
		</Container>
	);
}
