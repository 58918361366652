import { useMutation, UseMutationOptions } from 'react-query';

import {
	updateAttributeSettings,
	UpdateAttributeSettingsParams,
	UpdateAttributeSettingsResponse,
} from 'api/updateAttributeSettings';

export const useUpdateApiAttributeSettings = (
	options?: UseMutationOptions<
		UpdateAttributeSettingsResponse,
		string,
		UpdateAttributeSettingsParams,
		unknown
	>
) =>
	useMutation<
		UpdateAttributeSettingsResponse,
		string,
		UpdateAttributeSettingsParams,
		unknown
	>(async (variables) => {
		const result = await updateAttributeSettings(variables);

		if (!result.ok) {
			throw 'Unable to update attribute settings';
		}
		return await result.json();
	}, options);
