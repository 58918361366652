import { ColumnType } from 'typings/models';

export const sortDirectionLabel = ({
	attributeType,
	ascending,
	lowercase,
}: {
	attributeType: ColumnType | null;
	ascending: boolean;
	lowercase?: boolean;
}) => {
	switch (attributeType) {
		case 'NUMBER': {
			if (ascending) {
				return '1 → 9';
			} else {
				return '9 → 1';
			}
		}
		case 'DATETIME':
		case 'DATE':
		case 'TIME': {
			if (ascending) {
				return lowercase ? 'oldest first' : 'Oldest first';
			} else {
				return lowercase ? 'newest first' : 'Newest first';
			}
		}
		case 'BOOLEAN': {
			if (ascending) {
				return lowercase ? 'false first' : 'False first';
			} else {
				return lowercase ? 'true first' : 'True first';
			}
		}
		case 'TEXT': {
			if (ascending) {
				return 'A → Z';
			} else {
				return 'Z → A';
			}
		}
		default: {
			if (ascending) {
				return lowercase ? 'ascending' : 'Ascending';
			} else {
				return lowercase ? 'descending' : 'Descending';
			}
		}
	}
};
