import { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as KeyCommandIcon } from 'images/icons/keyCommand.svg';
import { ReactComponent as KeyEnterIcon } from 'images/icons/keyEnter.svg';
import { ReactComponent as KeyShiftIcon } from 'images/icons/keyShift.svg';
import { neutral } from 'utils/colors';
import styles from 'utils/styles';

function symbolizeKeyboardKey(keyboardKey: string, isMac: boolean) {
	switch (keyboardKey) {
		case 'Ctrl':
			if (isMac) {
				return <KeyCommandIcon />;
			} else {
				return 'Ctrl';
			}
		case 'Shift':
			return <KeyShiftIcon />;
		case 'Enter':
			return <KeyEnterIcon />;
		default:
			return keyboardKey;
	}
}

const KeyboardKey = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 1.25rem;
	height: 1.25rem;
	padding: 0;
	font-size: 0.875rem;
	color: ${neutral[2]};
	border-radius: 2px;
	background: ${neutral[4]};
	box-shadow: ${styles.shadows[900]};
	pointer-events: none;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: ${neutral[2]};
		}
	}
`;

export const StyledKeyboardShortcut = styled.span`
	display: flex;
	gap: 0.25rem;
	margin-left: 1.5rem;
	font-size: 0.75rem;
`;

interface KeyboardShortcutProps {
	keys: string[];
}

export function KeyboardShortcut({ keys }: KeyboardShortcutProps) {
	const isMac = useMemo(() => {
		return navigator.platform.includes('Mac');
	}, []);

	return (
		<StyledKeyboardShortcut>
			{keys.map((key: string, index: number) => (
				<KeyboardKey key={index}>
					{symbolizeKeyboardKey(key, isMac)}
				</KeyboardKey>
			))}
		</StyledKeyboardShortcut>
	);
}
