import { LOCAL_STORAGE_KEY } from 'utils/constants';

export function getToken() {
	return localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);
}

export const getDefaultHeaders = () => ({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	Authorization: `Bearer ${getToken()}`,
});
