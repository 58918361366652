import { ApiRecordAttribute } from 'typings/serverTypes';

export const formatAttributesForTableRecordsApiPayload = ({
	attributes,
}: {
	attributes: ApiRecordAttribute[];
}) => {
	const attributesPayload = [];
	for (const attribute of attributes) {
		attributesPayload.push({
			schemaName: attribute.schemaName,
			tableName: attribute.tableName,
			name: attribute.attributeName,
			visible: true,
		});
	}
	return attributesPayload;
};
