import { getDefaultHeaders } from 'api/utils';

export type UpdateViewFavoritedParams = {
	viewId: number;
	favorited: boolean;
};

export function updateViewFavorited({
	viewId,
	favorited,
}: UpdateViewFavoritedParams) {
	return fetch(`/api/views/${viewId}/favorited`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({ favorited }),
	});
}
