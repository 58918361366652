import { getToken } from 'api/utils';
import {
	formatDatabaseFormData,
	UpdateSqlDatabaseApiPayload,
} from 'utils/formatDatabaseFormData';

export function updateSqlDatabase(payload: UpdateSqlDatabaseApiPayload) {
	const formData = formatDatabaseFormData({ type: 'update', data: payload });

	return fetch(`/api/sql-databases/${payload.sqlDatabaseId}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: formData,
	});
}
