import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

const DropdownLinkComponent = styled(Link)`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 0.375rem 0.625rem;
	color: ${({
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'blue' does not exist on type 'LinkProps<... Remove this comment to see the full error message
		blue,
	}) => (blue ? primary.text : neutral[1])};
	background: none;
	border: none;
	border-radius: ${styles.global.borderRadius};
	cursor: pointer;
	white-space: nowrap;
	text-align: left;

	&:hover,
	&:focus {
		background: ${neutral[5]};
		color: ${neutral[1]};
		outline: none;
	}
`;

const Icon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 0.5rem;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: currentcolor;
		}
	}
`;

type DropdownLinkProps = {
	icon?: React.ReactNode;
	children?: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
} & Partial<LinkProps>;

export default function DropdownLink({
	children,
	icon,
	...rest
}: DropdownLinkProps) {
	return (
		// @ts-expect-error FIX
		<DropdownLinkComponent {...rest}>
			{icon && <Icon>{icon}</Icon>}
			{children}
		</DropdownLinkComponent>
	);
}
