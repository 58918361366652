import { useMutation, UseMutationOptions } from 'react-query';

import { createBasicView, CreateBasicViewParams } from 'api/createBasicView';
import { ApiView } from 'typings/serverTypes';
import { toast } from 'utils/toast/toast';

export const useCreateBasicApiView = (
	options?: UseMutationOptions<ApiView, string, CreateBasicViewParams, unknown>
) =>
	useMutation<ApiView, string, CreateBasicViewParams, unknown>(
		async (variables) => {
			const result = await createBasicView(variables);

			if (!result.ok) {
				const { error } = await result.json();
				throw error;
			}
			return result.json();
		},
		{
			onError: () => {
				toast.error('Failed to create view');
			},
			...options,
		}
	);
