import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from '@reduxjs/toolkit';

import { deleteDataSource as removeDataSourceApi } from 'api/deleteDataSource';
import { fetchDataSourceHealth } from 'api/fetchDataSourceHealth';
import { RootState } from 'reduxState/reducers';
import { DataSource } from 'typings/models';

type DataSourceEntity = DataSource & {
	healthy?: boolean;
	loading?: boolean;
	sqlDatabase?: { id: number };
};

const dataSourcesAdapter = createEntityAdapter<DataSourceEntity>({
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export type DataSourcesState = EntityState<DataSourceEntity>;

export const removeDataSource = createAsyncThunk(
	'dataSources/removeDataSource',
	async (dataSourceId: number) => {
		const response = await removeDataSourceApi(dataSourceId);
		if (response.ok) {
			return dataSourceId;
		} else {
			throw await response.json();
		}
	}
);

export const checkDataSourceHealth = createAsyncThunk(
	'dataSources/checkDataSourceHealth',
	async (dataSourceId: number) => {
		const response = await fetchDataSourceHealth({ dataSourceId });
		if (response.ok) {
			const healthy = await response.json();
			return { dataSourceId, healthy };
		} else {
			throw await response.json();
		}
	}
);

const dataSourcesSlice = createSlice({
	name: 'dataSources',
	initialState: dataSourcesAdapter.getInitialState(),
	reducers: {
		updateDataSource: (state, action: PayloadAction<DataSource>) => {
			dataSourcesAdapter.updateOne(state, {
				id: action.payload.id,
				changes: {
					...action.payload,
				},
			});
		},
		setDataSourceLoading: (
			state,
			action: PayloadAction<{ dataSourceId: number; loading: boolean }>
		) => {
			dataSourcesAdapter.updateOne(state, {
				id: action.payload.dataSourceId,
				changes: {
					loading: action.payload.loading,
				},
			});
		},
		setDataSourceHealthy: (
			state,
			action: PayloadAction<{ dataSourceId: number; healthy: boolean }>
		) => {
			dataSourcesAdapter.updateOne(state, {
				id: action.payload.dataSourceId,
				changes: {
					healthy: action.payload.healthy,
				},
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(removeDataSource.fulfilled, (state, action) => {
				dataSourcesAdapter.removeOne(state, action.payload);
			})
			.addCase(checkDataSourceHealth.fulfilled, (state, action) => {
				dataSourcesAdapter.updateOne(state, {
					id: action.payload.dataSourceId,
					changes: {
						healthy: action.payload.healthy,
					},
				});
			});
	},
});

export const { updateDataSource, setDataSourceLoading, setDataSourceHealthy } =
	dataSourcesSlice.actions;
export default dataSourcesSlice.reducer;

export const {
	selectAll: selectAllDataSources,
	selectEntities: selectDataSourcesById,
	selectIds: selectDataSourceIds,
} = dataSourcesAdapter.getSelectors<RootState>((state) => state.dataSources);
