export interface DataSourceApiPayload {
	name: string;
	host: string;
	port: number | null;
	database: string;
	username: string;
	password: string;
	sslEnabled: boolean;
	sslClientKey: Blob | null;
	sslClientCertificate: Blob | null;
	sslCustomCertificatesEnabled: boolean;
	sshEnabled: boolean;
	sshHost: string;
	sshPort: number;
	sshUsername: string;
	sshPassword: string;
	sshKeyEnabled: boolean;
	sshKey?: Blob | null;
	sshKeyPassphrase: string;
}

export interface UpdateSqlDatabaseApiPayload
	extends Partial<DataSourceApiPayload> {
	sqlDatabaseId: number;
}

export interface CreateDataSourceApiPayload extends DataSourceApiPayload {
	workspaceId: number;
	dialect: string;
}

export const formatDatabaseFormData = (
	payload:
		| { type: 'update'; data: UpdateSqlDatabaseApiPayload }
		| { type: 'add'; data: CreateDataSourceApiPayload }
) => {
	const formData = new FormData();
	const {
		name,
		host,
		port,
		database,
		username,
		password,
		sslEnabled,
		sslClientKey,
		sslClientCertificate,
		sslCustomCertificatesEnabled,
		sshEnabled,
		sshHost,
		sshPort,
		sshUsername,
		sshPassword,
		sshKeyEnabled,
		sshKey,
		sshKeyPassphrase,
	} = payload.data;
	if (name !== undefined) {
		formData.append('name', name);
	}
	if (host !== undefined) {
		formData.append('host', host);
	}
	if (port !== undefined) {
		formData.append('port', String(port));
	}
	if (database !== undefined) {
		formData.append('database', database);
	}
	if (username !== undefined) {
		formData.append('username', username);
	}
	if (password !== undefined) {
		formData.append('password', password);
	}
	if (sslEnabled !== undefined) {
		formData.append('sslEnabled', String(sslEnabled));
	}
	if (sslClientKey !== undefined) {
		formData.append('sslClientKey', sslClientKey ?? '');
	}
	if (sslClientCertificate !== undefined) {
		formData.append('sslClientCertificate', sslClientCertificate ?? '');
	}
	if (sslCustomCertificatesEnabled !== undefined) {
		formData.append(
			'sslCustomCertificatesEnabled',
			String(sslCustomCertificatesEnabled)
		);
	}
	if (sshEnabled !== undefined) {
		formData.append('sshEnabled', String(sshEnabled));
	}
	if (sshHost !== undefined) {
		formData.append('sshHost', sshHost);
	}
	if (sshPort !== undefined) {
		formData.append('sshPort', String(sshPort));
	}
	if (sshUsername !== undefined) {
		formData.append('sshUsername', sshUsername);
	}
	if (sshPassword !== undefined) {
		formData.append('sshPassword', sshPassword);
	}
	if (sshKeyEnabled !== undefined) {
		formData.append('sshKeyEnabled', String(sshKeyEnabled));
	}
	if (sshKey !== undefined && sshKey !== null) {
		formData.append('sshKey', sshKey);
	}
	if (sshKeyPassphrase !== undefined) {
		formData.append('sshKeyPassphrase', sshKeyPassphrase);
	}

	if (payload.type === 'add') {
		formData.append('dialect', payload.data.dialect);
	}

	return formData;
};
