import { PrimaryAttribute } from 'typings/models';
import { ApiAttribute, ApiForeignKey } from 'typings/serverTypes';
import { ValtioState } from 'valtioState';

export function getPrimaryAttributeAttributeFromGlobalStore({
	primaryAttributes,
	foreignKey,
	databaseId,
	attributesById,
	attributeNamesToIdMap,
}: {
	primaryAttributes: PrimaryAttribute[];
	foreignKey: ApiForeignKey | undefined;
	databaseId: number;
	attributesById: ValtioState['entities']['attributes']['byId'];
	attributeNamesToIdMap: ValtioState['attributeNamesToIdMap'];
}): ApiAttribute | undefined {
	if (!foreignKey) {
		return undefined;
	}

	const primaryAttribute = primaryAttributes.find(
		(primaryAttribute) =>
			primaryAttribute.schemaName === foreignKey.foreignSchemaName &&
			primaryAttribute.tableName === foreignKey.foreignTableName &&
			primaryAttribute.sqlDatabaseId === databaseId
	);

	if (!primaryAttribute) {
		return undefined;
	}

	const id =
		attributeNamesToIdMap[
			databaseId +
				'/' +
				primaryAttribute.schemaName +
				'/' +
				primaryAttribute.tableName +
				'/' +
				primaryAttribute.columnName
		];

	if (id === undefined) {
		return undefined;
	}
	return attributesById[id];
}
