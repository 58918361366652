import styled, { css } from 'styled-components';

import styles from 'utils/styles';

export const SidebarContainer = styled.div<{ fixed?: boolean }>`
	padding: 0.5rem;

	${({ fixed }) =>
		!fixed &&
		css`
			padding-bottom: ${styles.global.overscrollHeight};
			overflow-y: auto;
		`}
`;
