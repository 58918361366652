import { getDefaultHeaders } from 'api/utils';

interface AddDataSourceFromEncryptedStringApiPayload {
	workspaceId: number;
	encryptedCredentials: string;
}

export function addDataSourceFromEncryptedString({
	workspaceId,
	encryptedCredentials,
}: AddDataSourceFromEncryptedStringApiPayload) {
	return fetch(`/api/workspaces/${workspaceId}/data-sources/encrypted`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			encryptedCredentials,
		}),
	});
}
