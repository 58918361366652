import { useMutation, UseMutationOptions } from 'react-query';

import { deleteView } from 'api/deleteView';

export const useDeleteApiView = (
	options?: UseMutationOptions<void, string, number, unknown>
) =>
	useMutation<void, string, number, unknown>(
		async (variables) => {
			const result = await deleteView(variables);

			if (!result.ok) {
				const error = await result.json();
				throw error;
			}
			return;
		},
		{
			...options,
		}
	);
