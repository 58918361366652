import { format } from 'date-fns';

import { Attribute, ColumnType } from 'typings/models';
import { CellRecord } from 'typings/serverTypes';
import {
	DATETIME_FORMAT,
	EMPTY_VISUAL_SYMBOL,
	NULL_VISUAL_SYMBOL,
} from 'utils/constants';
import { getFormattedAttributeId } from 'utils/getFormattedAttributeId';
import { truncatedText } from 'utils/truncatedText';

export const getRecordDisplayValue = ({
	rawValue,
	attributeType,
	primaryAttributeAttribute,
	record,
	attributeId,
}: {
	rawValue: string | boolean | null | Date;
	attributeType?: ColumnType;
	primaryAttributeAttribute?: Attribute | undefined | null;
	record?: CellRecord;
	attributeId: number | string | undefined;
}) => {
	if (rawValue === null) {
		return NULL_VISUAL_SYMBOL;
	}

	if (primaryAttributeAttribute && record && attributeId !== undefined) {
		const formattedAttributeId = getFormattedAttributeId({
			primaryAttributeAttribute,
			attributeId,
		});
		return record[formattedAttributeId] as string;
	}

	if (rawValue instanceof Date) {
		switch (attributeType) {
			case 'DATETIME': {
				return format(rawValue, DATETIME_FORMAT);
			}
			case 'DATE': {
				return format(rawValue, DATETIME_FORMAT);
			}
			case 'TIME': {
				return format(rawValue, 'h:mm a');
			}
			default: {
				return format(rawValue, DATETIME_FORMAT);
			}
		}
	}

	if (typeof rawValue === 'string') {
		if (rawValue === '') {
			return EMPTY_VISUAL_SYMBOL;
		}
		return truncatedText({ value: rawValue });
	} else if (typeof rawValue === 'boolean') {
		return String(rawValue);
	} else {
		return rawValue;
	}
};
