import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSnapshot } from 'valtio';

import { joinDemoWorkspace } from 'api/joinDemoWorkspace';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { Callout } from 'components/Callout';
import _RouteContainer from 'components/RouteContainer';
import TextLink from 'components/TextLink';
import ThemePicker from 'components/ThemePicker';
import FormButton from 'components/fields/FormButton';
import { CreateWorkspaceForm } from 'components/forms/CreateWorkspaceForm';
import { setWorkspaceId } from 'reduxState/slices/workspaceId';
import { background, neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { state } from 'valtioState';

const RouteContainer = styled(_RouteContainer)`
	position: relative;
	height: 100vh;
	background: ${background[1]};
	transition: background 0.5s;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-self: center;
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 1rem 1rem;
`;

interface ItemProps {
	active: boolean;
	position: number;
}

const Item = styled.div<ItemProps>`
	min-width: 22rem;
	z-index: ${(props) => 10 - props.position};
	transition: transform 0.5s, opacity 0.5s;
	${(props) =>
		!props.active
			? css<ItemProps>`
					transition-delay: 0;
					transform: translateY(1rem) scale(0.9);
					opacity: 0;
					pointer-events: none;
			  `
			: css<ItemProps>`
					transition-delay: ${(props) => 0.2 + props.position * 0.2}s;
			  `};

	& + & {
		margin-top: 2rem;
	}
`;

const Subtitle = styled.h3`
	font-size: 1.75rem;
	font-weight: 500;
	text-align: center;
	color: ${neutral[1]};
	transition: color 0.5s;
`;

const Description = styled.p`
	max-width: 45rem;
	text-align: center;
	font-size: 1.125rem;
	color: ${neutral[2]};
	line-height: 1.5;
	transition: color 0.5s;
`;

const Bold = styled.strong`
	font-weight: 600;
`;

const Button = styled(FormButton)`
	margin-top: 0;
`;

function WelcomeRoute() {
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();
	const history = useHistory();
	const queryClient = useQueryClient();
	const snap = useSnapshot(state);

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const goToNextPage = () => {
		setPage(page + 1);
	};

	const handleJoinDemoWorkspace = async () => {
		await joinDemoWorkspace();
		if (snap.config?.demoWorkspaceId != null) {
			dispatch(setWorkspaceId(snap.config?.demoWorkspaceId));
		}
		queryClient.invalidateQueries([REACT_QUERY_CACHE_KEY.WORKSPACE]);
		history.push('/');
	};

	const getStarted = () => {
		if (!workspace || workspace.sqlDatabases.length === 0) {
			history.replace('/databases/add');
		} else {
			history.replace('/');
		}
	};

	return (
		<RouteContainer>
			<Container>
				<Item active={page === 1} position={1}>
					<Subtitle>Choose your style</Subtitle>
				</Item>
				<Item active={page === 1} position={2}>
					<Description>
						You can change your UI style at any time in your account settings.
					</Description>
				</Item>
				<Item active={page === 1} position={3}>
					<ThemePicker />
				</Item>
				<Item active={page === 1} position={4}>
					<Button onClick={goToNextPage}>Continue</Button>
				</Item>
			</Container>

			{!workspace ? (
				<Container>
					<Item active={page === 2} position={1}>
						<Subtitle>Create a workspace</Subtitle>
					</Item>
					<Item active={page === 2} position={2}>
						<Description>
							You'll be able to add your teammates and databases to your
							workspace.
						</Description>
					</Item>
					<Item active={page === 2} position={3}>
						<CreateWorkspaceForm />
					</Item>
					{/* Ask non-self-hosted users if they'd like to join the demo workspace */}
					{!snap.config?.isSelfHosted && (
						<Item active={page === 2} position={4}>
							<Callout>
								Want to try a demo instead?{' '}
								<TextLink as="button" onClick={handleJoinDemoWorkspace}>
									Click here
								</TextLink>{' '}
								to join a public demo workspace with sample data.
							</Callout>
						</Item>
					)}
				</Container>
			) : (
				<Container>
					<Item active={page === 2} position={1}>
						<Subtitle>
							You're in the <Bold>{workspace.name}</Bold> workspace
						</Subtitle>
					</Item>
					<Item active={page === 2} position={2}>
						<Description>
							You'll be able to manage your databases and collaborate with your
							team.
						</Description>
					</Item>
					<Item active={page === 2} position={4}>
						<Button onClick={getStarted}>Continue</Button>
					</Item>
				</Container>
			)}
		</RouteContainer>
	);
}

export default React.memo(WelcomeRoute);
