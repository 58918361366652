import { isValid } from 'date-fns';
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { EditorValue } from 'react-rte';
import { useVirtual } from 'react-virtual';
import { useSnapshot } from 'valtio';

import { updateApiRecord, UpdateRecordParams } from 'api/updateRecord';
import { RichTextEditor } from 'components/RichTextEditor';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
	getRecordId,
} from 'components/Table/tableUtils';
import { getEditableTableCellKeyboardShortcuts } from 'components/TableCell/getEditableTableCellKeyboardShortcuts';
import { useSocket } from 'components/providers/SocketProvider';
import { useToken } from 'components/providers/TokenProvider';
import { UpdatedCellToastRow } from 'components/toast/UpdatedCellToastRow';
import { updateRecord as updateRecordAction } from 'reduxState/slices/actionLog';
import { ColumnType, PrimaryAttribute } from 'typings/models';
import {
	ApiForeignKey,
	ApiRecordAttribute,
	ApiUser,
	CellRecord,
	StringRecordId,
} from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { CELL_VIEW_TYPE, PREVENT_SAVE_DATA_ATTRIBUTE } from 'utils/constants';
import { getCellViewType } from 'utils/getCellViewType';
import { getEnumValues } from 'utils/getEnumValues';
import { getInputValue } from 'utils/getInputValue';
import { getPrimaryAttributeFormattedAttributeId } from 'utils/getPrimaryAttributeFormattedAttributeId';
import { getRecordDisplayValue } from 'utils/getRecordDisplayValue';
import { getRecordValue } from 'utils/getRecordValue';
import { getSelectedCellDetails } from 'utils/getSelectedCellDetails';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import { isApiRecordAttribute } from 'utils/isApiRecordAttribute';
import { toast } from 'utils/toast/toast';
import { truncateValue } from 'utils/truncateValue';
import { state, ValtioState } from 'valtioState';
import { updateAttributeValueWithinRecord as updateRecordInGlobalState } from 'valtioState/records/updateAttributeValueWithinRecord';

type UseEditableRecordValueProps = {
	attribute: ApiRecordAttribute;
	editable?: boolean;
	pageNumber?: number;
	searchQuery?: string;
	rowNumber?: number;
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	onUpdateRecord?: (record: CellRecord) => void;
	isFormPanel?: boolean;
	record: CellRecord;
	rootTablePrimaryAttribute: PrimaryAttribute | undefined;
	lastRowIndex?: number;
	lastVisibleColumnIndex?: number;
	scrollToColumnIndex?: ReturnType<typeof useVirtual>['scrollToOffset'];
	scrollToRowIndex?: ReturnType<typeof useVirtual>['scrollToOffset'];
};

export type UseEditableTableRecordValueResult = {
	canEdit: boolean;
	cellRef: React.MutableRefObject<HTMLTableDataCellElement | null>;
	dataSource: ValtioState['entities']['dataSources']['byId'][number];
	databaseId: number;
	displayValue: string | boolean | null;

	highlighted: boolean;
	inputValue: string | EditorValue | null;
	isEditing: boolean;
	primaryAttributeAttribute: ValtioState['entities']['attributes']['byId'][number];
	rawValue: string | boolean | Date | null;
	rowHeight: number;
	selected: { userId: string; cellId: StringRecordId } | undefined;
	setValue: ({
		valueAfter,
		formattedValueAfter,
	}: {
		valueAfter: boolean | string | Date | null;
		formattedValueAfter?: string | boolean | null | undefined;
	}) => Promise<void>;
	stopEditing: () => void;
	tableId: number;
	isObscured: boolean;

	handleBlur: () => void;
	handleChange: (
		eventOrValue: ChangeEvent<HTMLInputElement> | EditorValue | string
	) => void;
	handleDoubleClick: () => void;
	handleFocus: () => void;
	handleKeyDown: (event: React.KeyboardEvent<Element>) => void;

	// cellViewType is how the cell show be rendered as, while attributeType is the 'intrinsic'
	// type of the cell. For example, you might want a cell to be rendered as a checkbox, but
	// it is intrinsically a tinyint/number
	cellViewType: CELL_VIEW_TYPE;
	attributeType: ColumnType;

	// Only used by dates
	handleChangeDate: (value: Date | null) => void;

	// Only used by checkboxes
	toggleCheckbox: () => void;
	checked: boolean;

	// Only used by foreign keys
	foreignKey: ApiForeignKey | undefined;
	isDropdownOpen: boolean;
	setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;

	// Only used by enums
	columnEnumValues: string[];
};

export const useEditableRecordValue = ({
	attribute,
	editable = true,
	record,
	pageNumber,
	searchQuery,
	rowNumber,
	data,
	onUpdateRecord,
	isFormPanel = false,
	extractedTableData,
	scrollToColumnIndex,
	scrollToRowIndex,
	lastRowIndex,
	lastVisibleColumnIndex,
}: UseEditableRecordValueProps): UseEditableTableRecordValueResult => {
	const dispatch = useDispatch();
	const snap = useSnapshot(state);

	const {
		databaseId,
		rowHeight,
		foreignKeys,
		tableId,
		enums,
		primaryKeyAttributes,
	} = extractedTableData;

	const table = snap.entities.tables.byId[attribute.tableId];
	const sqlDatabase = snap.entities.sqlDatabases.byId[databaseId];
	const dataSource =
		sqlDatabase && snap.entities.dataSources.byId[sqlDatabase.dataSourceId];

	const columnSchemaName = attribute.schemaName;
	const columnTableName = attribute.tableName;

	const attributeId = attribute.id;
	const foreignKey = getForeignKey(attribute.name, foreignKeys);
	const isObscured = isApiRecordAttribute(attribute)
		? attribute.isObscured
		: false;

	const attributeType = useMemo(
		() =>
			getAttributeTypeFromDbColumnType({
				dbColumnType: attribute.typeInDb,
				isForeignKey: Boolean(foreignKey),
			}),
		[attribute, foreignKey]
	);

	const getPrimaryAttributeAttribute = () => {
		if (!foreignKey || !sqlDatabase) {
			return undefined;
		}
		const foreignKeyTableId =
			snap.tableNamesToIdMap[
				sqlDatabase.id +
					'/' +
					foreignKey.foreignSchemaName +
					'/' +
					foreignKey.foreignTableName
			];
		if (foreignKeyTableId === undefined) {
			return undefined;
		}
		const foreignKeyTable = snap.entities.tables.byId[foreignKeyTableId];
		if (!foreignKeyTable) {
			return undefined;
		}
		const primaryAttribute = foreignKeyTable.primaryAttribute;
		if (!primaryAttribute) {
			return undefined;
		}

		const id =
			snap.attributeNamesToIdMap[
				databaseId +
					'/' +
					primaryAttribute.schemaName +
					'/' +
					primaryAttribute.tableName +
					'/' +
					primaryAttribute.columnName
			];

		if (id === undefined) {
			return undefined;
		}
		return snap.entities.attributes.byId[id];
	};

	const primaryAttributeAttribute = getPrimaryAttributeAttribute();

	const rawValue = getRecordValue({
		record,
		attributeId,
		attributeType,
	});

	const displayValue = getRecordDisplayValue({
		rawValue,
		attributeType,
		primaryAttributeAttribute,
		record,
		attributeId,
	});

	const columnEnumValues = getEnumValues(
		enums,
		columnSchemaName,
		columnTableName,
		attribute
	);

	const cellViewType: CELL_VIEW_TYPE = useMemo(
		() => getCellViewType(attribute, rawValue, attributeType, columnEnumValues),
		[attributeType, attribute, columnEnumValues, rawValue]
	);

	const richTextFormat = attribute.viewOptions?.richTextFormat || 'html';

	const [isEditing, setIsEditing] = useState(false);
	const [inputValue, setInputValue] = useState<string | EditorValue | null>(
		getInputValue(rawValue, cellViewType, richTextFormat)
	);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const selectedCells = useSelector((state) => state.selectedCells);

	const cellRef = useRef<HTMLTableDataCellElement | null>(null);

	const queryClient = useQueryClient();

	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;

	const cellId = `${attribute.id}/${record.id as StringRecordId}` as const;

	const startEditing = useCallback(() => {
		setIsEditing(true);
		if (isObscured) {
			setInputValue('');
		} else {
			setInputValue(getInputValue(rawValue, cellViewType, richTextFormat));
		}
	}, [isObscured, rawValue, cellViewType, richTextFormat]);

	const stopEditing = useCallback(() => {
		setIsEditing(false);
	}, []);

	const socket = useSocket();
	const { token } = useToken();

	const selected = useMemo(
		() =>
			getSelectedCellDetails({ selectedCells: selectedCells, userId, cellId }),
		[cellId, selectedCells, userId]
	);
	const highlighted = useMemo(() => {
		if (!searchQuery || isObscured) {
			return false;
		}

		return (
			String(displayValue)
				.toLocaleUpperCase()
				.includes(searchQuery.toLocaleUpperCase()) ||
			String(rawValue)
				.toLocaleUpperCase()
				.includes(searchQuery.toLocaleUpperCase())
		);
	}, [rawValue, displayValue, searchQuery, isObscured]);

	const canEdit =
		(editable &&
			!attribute.isPrimaryKeyInDb &&
			attribute?.editable &&
			dataSource?.editable &&
			table?.editable) ??
		false;

	const updateRecord = useCallback(
		async (
			params: Omit<UpdateRecordParams, 'value'> & {
				valueBefore: string | boolean | null;
				valueAfter: string | boolean | null;
				formattedValueBefore: string | boolean | null;
				formattedValueAfter: string | boolean | null;
			}
		) => {
			dispatch(
				updateRecordAction({
					attributeId: params.attributeId,
					tableId: params.tableId,
					recordId: params.recordId,
					valueBefore: params.valueBefore,
					valueAfter: params.valueAfter,
					formattedValueBefore: params.formattedValueBefore,
					formattedValueAfter: params.formattedValueAfter,
				})
			);
			// This condition should only be true when the hook is used in the NewRecordCell component
			// and in that case, the updateApiRecord function should never be called anyways
			if (
				!record ||
				rowNumber === undefined ||
				pageNumber === undefined ||
				searchQuery === undefined ||
				attributeId === undefined ||
				isAdvancedViewData(data)
			) {
				return;
			}
			const primaryAttributeRecordKey = primaryAttributeAttribute
				? getPrimaryAttributeFormattedAttributeId(
						attributeId,
						primaryAttributeAttribute
				  )
				: undefined;

			updateRecordInGlobalState({
				recordId: record.id as StringRecordId,
				attributeId,
				newValueForAttribute: params.valueAfter,
			});
			if (primaryAttributeRecordKey) {
				updateRecordInGlobalState({
					recordId: record.id as StringRecordId,
					attributeId: primaryAttributeRecordKey,
					newValueForAttribute: params.formattedValueAfter,
				});
			}

			// TODO: onUpdateRecord value is being used in the form panel to properly update the UI after a record
			// has been updated. However, this should not be necessary once the form panel points to the record
			// in the global store since the UI should update automatically.
			if (onUpdateRecord) {
				onUpdateRecord({
					...record,
					[attributeId]: params.valueAfter,
					...(primaryAttributeRecordKey
						? { [primaryAttributeRecordKey]: params.formattedValueAfter }
						: {}),
				});
			}

			try {
				await updateApiRecord(params);
				window.analytics.track('Cell Edited', {
					cellId,
				});
				toast.update(
					(updateToast) => (
						<UpdatedCellToastRow
							type={attributeType}
							dismiss={() => toast.dismiss(updateToast.id)}
							previousRawValue={params.valueBefore}
							newRawValue={params.valueAfter}
							previousDisplayValue={truncateValue({
								value: params.formattedValueBefore,
							})}
							newDisplayValue={truncateValue({
								value: params.formattedValueAfter,
							})}
							data={data}
							extractedTableData={extractedTableData}
							pageNumber={pageNumber}
							searchQuery={searchQuery}
							rowNumber={rowNumber}
							record={record}
							primaryAttribute={primaryAttributeAttribute}
							isObscured={isObscured}
						/>
					),
					{
						// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ meta: { type: string; }; }' is... Remove this comment to see the full error message
						meta: {
							type: 'update',
						},
					}
				);
			} catch (error) {
				if (error && typeof error === 'object' && 'error' in error) {
					// @ts-expect-error FIX
					toast.error(error.error);
				} else {
					toast.error('Unable to update record.');
				}

				updateRecordInGlobalState({
					recordId: record.id as StringRecordId,
					attributeId,
					newValueForAttribute: params.valueBefore,
				});

				const primaryAttributeRecordKey = primaryAttributeAttribute
					? getPrimaryAttributeFormattedAttributeId(
							attributeId,
							primaryAttributeAttribute
					  )
					: undefined;

				if (primaryAttributeRecordKey) {
					updateRecordInGlobalState({
						recordId: record.id as StringRecordId,
						attributeId: primaryAttributeRecordKey,
						newValueForAttribute: params.formattedValueBefore,
					});
				}
			}
		},
		[
			attributeId,
			attributeType,
			cellId,
			data,
			dispatch,
			extractedTableData,
			isObscured,
			onUpdateRecord,
			pageNumber,
			primaryAttributeAttribute,
			record,
			rowNumber,
			searchQuery,
		]
	);

	const setValue = useCallback(
		async ({
			valueAfter,
			formattedValueAfter,
		}: {
			valueAfter: boolean | string | Date | null;
			formattedValueAfter?: string | boolean | null;
		}): Promise<void> => {
			if (!canEdit) {
				return;
			}
			if (primaryKeyAttributes.length === 0) {
				toast.error(
					'Unable to update record since there is no primary key associated with the table.'
				);
				return;
			}
			const valueBefore: string | boolean | Date | null =
				rawValue instanceof Date ? rawValue.toISOString() : rawValue;
			let formattedValueBefore: string | boolean | null = displayValue;
			formattedValueAfter =
				formattedValueAfter !== undefined
					? formattedValueAfter
					: getRecordDisplayValue({
							rawValue: valueAfter,
							attributeType,
							attributeId,
					  });

			// Make sure this code runs after formattedValueAfter has been calculated in order to get
			// the right formatted date
			if (valueAfter instanceof Date) {
				if (!isValid(valueAfter)) {
					valueAfter = null;
				} else {
					valueAfter = valueAfter.toISOString();
				}
			}

			if (attributeType !== 'TEXT') {
				if (valueAfter === '') {
					valueAfter = null;
				}
			}

			if (
				cellViewType === CELL_VIEW_TYPE.JSON &&
				typeof valueAfter === 'string'
			) {
				if (formattedValueBefore !== null) {
					try {
						if (typeof formattedValueBefore === 'string') {
							const jsonValue = JSON.parse(formattedValueBefore);
							formattedValueBefore = JSON.stringify(jsonValue);
						} else if (typeof formattedValueBefore === 'object') {
							formattedValueBefore = JSON.stringify(formattedValueBefore);
						}
					} catch (error) {}
				}

				if (valueAfter !== null) {
					try {
						const jsonValue = JSON.parse(valueAfter);
						valueAfter = JSON.stringify(jsonValue);
					} catch (error) {
						toast.error('Invalid JSON');
						return;
					}
				}
			}

			if (valueAfter !== valueBefore) {
				if (databaseId == null || tableId === undefined) {
					return;
				}

				const objectRecordId = getRecordId({
					record,
					primaryKeyAttributes,
					schemaName: attribute.schemaName,
					tableName: attribute.tableName,
				});

				if (!objectRecordId) {
					return;
				}

				await updateRecord({
					attributeId,
					tableId,
					recordId: objectRecordId,
					valueBefore,
					valueAfter,
					formattedValueBefore,
					formattedValueAfter,
				});
			}
		},
		[
			canEdit,
			rawValue,
			displayValue,
			attributeType,
			attributeId,
			cellViewType,
			databaseId,
			tableId,
			record,
			primaryKeyAttributes,
			attribute.schemaName,
			attribute.tableName,
			updateRecord,
		]
	);

	const handleChange = (
		eventOrValue: ChangeEvent<HTMLInputElement> | EditorValue | string
	) => {
		if (eventOrValue instanceof EditorValue) {
			setInputValue(eventOrValue);
		} else if (typeof eventOrValue === 'string') {
			setInputValue(eventOrValue);
		} else {
			setInputValue(eventOrValue.target.value);
		}
	};

	const handleChangeDate = useCallback(
		(value: Date | null) => {
			setValue({ valueAfter: value });
		},
		[setValue]
	);

	const checked = useMemo(() => {
		if (rawValue === null) {
			return false;
		}
		if (typeof rawValue === 'boolean') {
			return rawValue;
		}
		if (typeof rawValue === 'string') {
			return rawValue !== '0';
		}
		return false;
	}, [rawValue]);
	const toggleCheckbox = useCallback(() => {
		if (cellViewType !== CELL_VIEW_TYPE.CHECKBOX) {
			return;
		}

		const newCheckboxValue = !checked;
		let newFormattedCheckboxValue: boolean | '1' | '0' = newCheckboxValue;
		if (attributeType !== 'BOOLEAN') {
			newFormattedCheckboxValue = newCheckboxValue ? '1' : '0';
		}
		setValue({ valueAfter: newFormattedCheckboxValue });
	}, [attributeType, cellViewType, checked, setValue]);

	useEffect(() => {
		if (
			cellViewType !== CELL_VIEW_TYPE.RICH_TEXT ||
			typeof rawValue === 'boolean' ||
			rawValue instanceof Date
		) {
			return;
		}
		setInputValue(
			RichTextEditor.createValueFromString(rawValue || '', richTextFormat)
		);
	}, [rawValue, cellViewType, richTextFormat]);

	const handleFocus = useCallback(() => {
		// in FormPanel we need to call start editing here, in Table Cell we rely on the double click handler to invoke start editing
		if (isFormPanel) {
			startEditing();
		}

		socket.emit('select-cell', {
			token,
			userId,
			tableId: attribute.tableId,
			cellId,
		});
	}, [
		isFormPanel,
		socket,
		token,
		userId,
		attribute.tableId,
		cellId,
		startEditing,
	]);

	const handleBlur = useCallback(() => {
		socket.emit('deselect-cell', {
			token,
			userId,
			tableId: attribute.tableId,
			cellId,
		});

		if (cellRef.current) {
			/* When hitting the Escape key, we set a data-attribute to true to know
			   that we should not save the latest changes. */
			if (cellRef.current.getAttribute(PREVENT_SAVE_DATA_ATTRIBUTE)) {
				cellRef.current.removeAttribute(PREVENT_SAVE_DATA_ATTRIBUTE);
				return;
			}
		}

		if (isEditing) {
			if (
				cellViewType === CELL_VIEW_TYPE.RICH_TEXT &&
				inputValue instanceof EditorValue
			) {
				setValue({ valueAfter: inputValue.toString(richTextFormat) });
			} else if (cellViewType === CELL_VIEW_TYPE.DATE) {
				setValue({ valueAfter: rawValue });
			} else if (!(inputValue instanceof EditorValue)) {
				if (isObscured) {
					// If user blurs cell without editing, don't save changes
					if (inputValue === '') {
						stopEditing();
						return;
					}
				}
				setValue({ valueAfter: inputValue });
			}
			stopEditing();
		}
	}, [
		socket,
		token,
		userId,
		attribute.tableId,
		cellId,
		isEditing,
		cellViewType,
		inputValue,
		stopEditing,
		setValue,
		richTextFormat,
		rawValue,
		isObscured,
	]);

	const handleDoubleClick = () => {
		if (!canEdit || cellViewType === CELL_VIEW_TYPE.CHECKBOX) {
			return;
		}

		if (cellViewType === CELL_VIEW_TYPE.FOREIGN_KEY) {
			setIsDropdownOpen(true);
			return;
		}

		startEditing();
	};

	// Update input value whenever record value changes
	useEffect(() => {
		setInputValue(getInputValue(rawValue, cellViewType, richTextFormat));
	}, [cellViewType, rawValue, richTextFormat]);

	const handleKeyDown = getEditableTableCellKeyboardShortcuts({
		isEditing,
		canEdit,
		setValue,
		cellViewType,
		foreignKey,
		startEditing,
		toggleCheckbox,
		isDropdownOpen,
		setIsDropdownOpen,
		stopEditing,
		value: rawValue,
		cellRef,
		lastRowIndex,
		lastVisibleColumnIndex,
		scrollToColumnIndex,
		scrollToRowIndex,
	});

	return {
		/**
		 * Adding a ✅ if the variable shouldn't affect cell rendering performance
		 * Adding a ⚠️ if the variable might affect cell rendering performance
		 */
		canEdit, // ✅
		cellRef, // ✅
		dataSource, // ✅
		databaseId, // ✅
		displayValue, // ⚠️ getRecordDisplayValue might be expensive (maybe truncating the text)

		highlighted, // ⚠️ Array.includes
		inputValue, // ⚠️ getInputValue might be expensive
		isEditing, // ✅
		primaryAttributeAttribute, // ✅
		rawValue, // ⚠️ lots of if statements
		rowHeight, // ✅
		selected, // ⚠️ Object.entries, Array.find
		setValue, // ✅
		stopEditing, // ✅
		tableId, // ✅

		handleBlur, // ✅
		handleChange, // ✅
		handleChangeDate, // ✅
		handleDoubleClick, // ✅
		handleFocus, // ✅
		handleKeyDown, // ✅

		cellViewType, // ⚠️ lots of if statements
		attributeType, // ⚠️ lots of Array.includes checks

		toggleCheckbox, // ✅
		checked, // ⚠️ sucks we have to calculate this for non-checkbox cells

		foreignKey, // ⚠️ Array.find method
		isDropdownOpen, // ✅
		setIsDropdownOpen, // ✅

		columnEnumValues, // ⚠️ Array.filter, Array.map
		isObscured, // ✅
	};
};
