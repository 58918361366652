import { format, toDate } from 'date-fns';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { useApiBillingInformation } from 'api/reactQueryHooks/useApiBillingInformation';
import Card from 'components/Card';
import PaymentMethodInfo from 'components/PaymentMethodInfo';
import Section from 'components/Section';
import TextLink from 'components/TextLink';
import ApplyCreditForm from 'components/forms/ApplyCreditForm';
import { Member } from 'typings/models';
import { ApiUser, ApiWorkspaceDetails } from 'typings/serverTypes';
import { canManageBilling } from 'utils/permissions';
import { getPlanPrice, Plan } from 'utils/plans';

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;
`;

const Text = styled.p`
	cursor: default;
`;

const Bold = styled.strong`
	font-weight: 500;
`;

const TextLinkSecondary = styled(TextLink)`
	margin-left: 1rem;
	font-size: 0.75rem;
`;

const InvoicesTitle = styled.h4`
	margin-top: 1.5rem;
	font-size: 1.25rem;
	font-weight: 500;
	cursor: default;
`;

const InvoicesTable = styled.table`
	width: 100%;
	margin-top: 0.5rem;
	border-collapse: collapse;
	table-layout: auto;
`;

const Invoice = styled.tr`
	height: 1.75rem;
`;

const InvoiceDate = styled.td`
	width: 100%;
	cursor: default;
`;

const InvoiceInfo = styled.td`
	padding: 0 1rem;
	text-align: right;
	white-space: nowrap;
	cursor: default;
`;

interface Props {
	workspace: ApiWorkspaceDetails;
	collaborator: Member;
}

export function BillingOverview({ workspace, collaborator }: Props) {
	const { data: billingInformation } = useApiBillingInformation(workspace.id, {
		enabled: canManageBilling(collaborator),
	});
	const queryClient = useQueryClient();
	const collaboratorCount = workspace.collaborators.length;
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const [applyCreditFormVisible, setApplyCreditFormVisible] = useState(false);

	if (user === null) {
		return null;
	}

	return (
		<Section title="Billing">
			<Card>
				{workspace.plan !== 'Basic' &&
				billingInformation &&
				billingInformation.nextPaymentDue ? (
					<Text>
						This workspace's <Bold>{workspace.plan}</Bold> plan is set to{' '}
						<Bold>
							${getPlanPrice(workspace.plan as Plan) * (collaboratorCount ?? 1)}{' '}
							per month
						</Bold>{' '}
						and will renew on{' '}
						<Bold>
							{format(
								toDate(billingInformation.nextPaymentDue * 1000),
								'MMMM d, yyyy'
							)}
						</Bold>
						.
					</Text>
				) : (
					<Text>
						This workspace's <Bold>{workspace.plan}</Bold> plan is set to{' '}
						<Bold>
							${getPlanPrice(workspace.plan as Plan) * (collaboratorCount ?? 1)}{' '}
							per month
						</Bold>
						.
					</Text>
				)}

				{billingInformation && billingInformation.nextPaymentAmount != null && (
					<ItemContainer>
						<Text>
							Current bill total{' '}
							<Bold>
								${(billingInformation.nextPaymentAmount / 100).toFixed(2)}
							</Bold>
						</Text>
						<TextLinkSecondary to="/settings/downgrade">
							Cancel subscription
						</TextLinkSecondary>
					</ItemContainer>
				)}

				{billingInformation?.paymentMethod ? (
					<ItemContainer>
						<PaymentMethodInfo
							paymentMethod={billingInformation.paymentMethod}
						/>
						<TextLinkSecondary to="/settings/payment">
							Update payment method
						</TextLinkSecondary>
					</ItemContainer>
				) : (
					<ItemContainer>
						<Text>You have not added a payment method</Text>
						<TextLinkSecondary to="/settings/payment">
							Add payment method
						</TextLinkSecondary>
					</ItemContainer>
				)}

				{billingInformation && billingInformation.credit !== null && (
					<>
						<ItemContainer>
							<Text>
								Workspace credit{' '}
								<Bold>${(billingInformation.credit / 100).toFixed(2)}</Bold>
							</Text>
							{collaborator.isOwner && user.credit > 0 && (
								<TextLinkSecondary
									as="button"
									onClick={() =>
										setApplyCreditFormVisible(!applyCreditFormVisible)
									}
								>
									Apply credit
								</TextLinkSecondary>
							)}
						</ItemContainer>

						{applyCreditFormVisible && collaborator.isOwner && (
							<ApplyCreditForm
								workspaceId={workspace.id}
								userCredit={user.credit}
							/>
						)}
					</>
				)}

				{billingInformation?.invoices && billingInformation.invoices.length && (
					<>
						<InvoicesTitle>Invoices</InvoicesTitle>
						<InvoicesTable>
							<tbody>
								{billingInformation.invoices.map((invoice) => (
									<Invoice key={invoice.id}>
										<InvoiceDate>
											{format(toDate(invoice.date * 1000), 'MMMM d, yyyy')}
										</InvoiceDate>
										<InvoiceInfo>{invoice.status}</InvoiceInfo>
										<InvoiceInfo>
											${(invoice.amountDue / 100).toFixed(2)}
										</InvoiceInfo>
										<InvoiceInfo>
											<TextLink as="a" href={invoice.pdfUrl}>
												Download
											</TextLink>
										</InvoiceInfo>
									</Invoice>
								))}
							</tbody>
						</InvoicesTable>
					</>
				)}
			</Card>
		</Section>
	);
}
