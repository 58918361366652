import { ApiRecordAttribute } from 'typings/serverTypes';
import { CELL_VIEW_TYPE } from 'utils/constants';
import { isApiRecordAttribute } from 'utils/isApiRecordAttribute';

export function getCellViewType(
	column: ApiRecordAttribute,
	rawValue: string | Date | null | boolean,
	attributeType:
		| 'DATE'
		| 'FOREIGN_KEY'
		| 'NUMBER'
		| 'ARRAY'
		| 'DATETIME'
		| 'TEXT'
		| 'JSON'
		| 'TIME'
		| 'BOOLEAN',
	columnEnumValues: string[]
) {
	if (isApiRecordAttribute(column)) {
		if (attributeType === 'FOREIGN_KEY') {
			return CELL_VIEW_TYPE.FOREIGN_KEY;
		}
		if (column.viewOptions?.type === 'image') {
			return CELL_VIEW_TYPE.IMAGE;
		}
		const urlPattern = /^(https?:\/\/[^\s]+)/g;
		if (typeof rawValue === 'string' && urlPattern.test(rawValue)) {
			return CELL_VIEW_TYPE.URL;
		}
		if (column.viewOptions?.type === 'richText') {
			return CELL_VIEW_TYPE.RICH_TEXT;
		}
		if (
			column.viewOptions?.type === 'checkbox' ||
			attributeType === 'BOOLEAN'
		) {
			return CELL_VIEW_TYPE.CHECKBOX;
		}
		if (column.viewOptions?.type === 'json' || attributeType === 'JSON') {
			return CELL_VIEW_TYPE.JSON;
		}
		if (attributeType === 'DATE' || attributeType === 'DATETIME') {
			return CELL_VIEW_TYPE.DATE;
		}
		if (columnEnumValues?.length > 0) {
			return CELL_VIEW_TYPE.ENUM;
		}
	}
	return CELL_VIEW_TYPE.TEXT;
}
