import { createWorkspaceDataSource as createWorkspaceDataSourceApi } from 'api/createWorkspaceDataSource';
import { FetchWorkspaceResponse } from 'api/fetchWorkspace';
import { CreateDataSourceResponse } from 'typings/serverTypes';
import { CreateDataSourceApiPayload } from 'utils/formatDatabaseFormData';
import { state } from 'valtioState/index';
import { setAllByIds, setAllIds } from 'valtioState/utils';

export const createWorkspaceDataSource = async (
	payload: CreateDataSourceApiPayload
) => {
	const res = await createWorkspaceDataSourceApi(payload);
	if (res.ok) {
		const { sqlDatabase, ...dataSource } =
			(await res.json()) as CreateDataSourceResponse;

		setAllByIds([dataSource], state.entities.dataSources.byId);
		setAllIds([dataSource], state.entities.dataSources.ids);

		setAllByIds(
			[sqlDatabase as FetchWorkspaceResponse['sqlDatabases'][number]],
			state.entities.sqlDatabases.byId
		);
		setAllIds([sqlDatabase], state.entities.sqlDatabases.ids);
		return dataSource;
	} else {
		throw await res.json();
	}
};
