import { useQuery, UseQueryOptions } from 'react-query';

import {
	fetchActivity,
	FetchActivityParams,
	FetchActivityResponse,
} from 'api/fetchActivity';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';

export const useApiActvities = (
	params: FetchActivityParams,
	options?: UseQueryOptions<
		unknown,
		string,
		FetchActivityResponse,
		[string, FetchActivityParams]
	>
) =>
	useQuery<
		unknown,
		string,
		FetchActivityResponse,
		[string, FetchActivityParams]
	>(
		[REACT_QUERY_CACHE_KEY.ACTIVITY, params],
		async ({ queryKey }) => {
			const [_key, params] = queryKey;

			const response = await fetchActivity(params);
			if (!response.ok) {
				throw new ApiError('Network response was not ok');
			}
			return response.json();
		},
		options
	);
