import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useApiBillingInformation } from 'api/reactQueryHooks/useApiBillingInformation';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Card from 'components/Card';
import Page from 'components/Page';
import PaymentMethodInfo from 'components/PaymentMethodInfo';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import { CardInput } from 'components/fields/CardInput';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { useAppDispatch } from 'reduxState/store';
import { setPaymentMethod } from 'reduxState/thunks/setPaymentMethod';
import { ApiUser } from 'typings/serverTypes';
import { canManageBilling } from 'utils/permissions';
import styles from 'utils/styles';
import { toast } from 'utils/toast/toast';

const Label = styled.label`
	display: block;
	font-weight: 500;
	user-select: none;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
`;

const ErrorText = styled.p`
	color: ${styles.colours.error[500]};
	font-size: 0.875rem;
	font-weight: 400;
`;

export default function WorkspacePaymentRoute() {
	const stripe = useStripe();
	const elements = useElements();
	const history = useHistory();
	const theme = useTheme();
	const dispatch = useAppDispatch();

	const [cardError, setCardError] = useState(null);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState(null);
	const [loading, setLoading] = useState(false);

	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;

	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);

	const { data: billingInformation } = useApiBillingInformation(workspace?.id, {
		enabled: canManageBilling(collaborator ?? null) && workspace !== undefined,
	});

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			// @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
			case 'name': {
				setName(value);
				setNameError(null);
			}
			// @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
			case 'card': {
				setCardError(null);
			}
			default: {
				return;
			}
		}
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleSubmit = async (event) => {
		event.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);
		setCardError(null);
		setNameError(null);

		if (!name.length) {
			// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Enter the name on your card."' ... Remove this comment to see the full error message
			setNameError('Enter the name on your card.');
			setLoading(false);
			return;
		}

		// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
		const cardElement = elements.getElement(CardElement);
		// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
		const { token, error } = await stripe.createToken(cardElement, { name });

		if (error) {
			// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'StripeError' is not assignable t... Remove this comment to see the full error message
			setCardError(error);
			setLoading(false);
			return;
		}

		if (!token || !workspaceId) {
			setLoading(false);
			return;
		}

		dispatch(setPaymentMethod({ workspaceId, tokenId: token.id }))
			.unwrap()
			.then(() => {
				history.push(`/settings`);
			})
			.catch(() => {
				toast.error(`There was a problem setting your payment method.`);
				setLoading(false);
			});
	};

	const stripeElementsStyle = () => ({
		base: {
			color:
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'mode' does not exist on type 'DefaultThe... Remove this comment to see the full error message
				theme.mode === 'light'
					? styles.colours.neutral[100]
					: styles.colours.neutral[1000],
			fontSize: '1.125rem',
			fontFamily: styles.text.fontFamilyText,
		},
	});

	if (!collaborator || !workspace) {
		return <Spinner />;
	}

	if (!canManageBilling(collaborator)) {
		return <Redirect to="/settings" />;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<SettingsIcon />} title="Payment method" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section>
							<Card>
								<Form onSubmit={handleSubmit}>
									{billingInformation?.paymentMethod && (
										<>
											<Label>Current payment method</Label>
											<PaymentMethodInfo
												paymentMethod={billingInformation.paymentMethod}
											/>
										</>
									)}

									<Label>
										Card information
										<CardInput
											// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
											stripe={stripe}
											elements={elements}
											options={{ style: stripeElementsStyle() }}
										/>
										{/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
										{cardError && <ErrorText>{cardError.message}</ErrorText>}
									</Label>

									<Input
										name="name"
										type="text"
										label="Name on card"
										value={name}
										onChange={handleChange}
										errors={nameError && [nameError]}
									/>
									<FormButton loading={loading}>
										{!billingInformation?.paymentMethod
											? 'Save payment method'
											: 'Update payment method'}
									</FormButton>
								</Form>
							</Card>
						</Section>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
