import styled, { css } from 'styled-components';

import { StyledFormAttributeValue } from 'components/StyledFormAttributeValue';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import styles from 'utils/styles';

export const TableCellContentsText = styled.p<{
	padded?: boolean;
	hideOnFocus?: boolean;
}>`
	word-break: break-all;
	${(props) =>
		props.padded &&
		css`
			padding: ${styles.table.cell.padding};
		`};

	${(props) =>
		props.hideOnFocus &&
		css`
			${StyledTableCell}:focus-within &&,
			${StyledFormAttributeValue}:focus-within && {
				display: none;
			}
		`};
`;
