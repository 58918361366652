import { InfoButton, InfoContainer, InfoText } from 'components/Page';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';

export function ConnectDataSourceCta() {
	return (
		<InfoContainer>
			<InfoText>Connect your first data source to get started.</InfoText>
			<InfoButton
				to="/databases/add"
				icon={<PlusIcon />}
				value="Connect a data source"
			/>
		</InfoContainer>
	);
}
