import { getDefaultHeaders } from 'api/utils';

export function updateAcceptedEmailDomain(
	workspaceId: number,
	acceptedEmailDomain: string | null
) {
	return fetch(`/api/workspaces/${workspaceId}/accepted-email-domain`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			acceptedEmailDomain,
		}),
	});
}
