export const setAllIds = <T extends string | number, K extends { id: T }>(
	values: K[],
	ids: T[]
) => {
	for (const value of values) {
		if (!ids.includes(value.id)) {
			ids.push(value.id);
		}
	}
};

export const setAllByIds = <
	T extends string | number,
	R extends K,
	K extends { id: T }
>(
	values: R[],
	byId: { [id in T]: K | undefined }
) => {
	for (const value of values) {
		if (value !== undefined) {
			const existingValue = byId[value.id];
			// Don't want to overwrite existing values
			if (existingValue) {
				byId[value.id] = {
					...existingValue,
					...value,
				};
			} else {
				byId[value.id] = value;
			}
		}
	}
};
