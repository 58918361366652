import { DeepReadonly } from 'utils/constants';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { ValtioState } from 'valtioState';

export const getTableDisplayNameFromSchemaAndTableName = ({
	tablesById,
	tableNamesToIdMap,
	schemaName,
	tableName,
	sqlDatabaseId,
}: {
	tablesById: DeepReadonly<ValtioState['entities']['tables']['byId']>;
	tableNamesToIdMap: ValtioState['tableNamesToIdMap'];
	schemaName: string;
	tableName: string;
	sqlDatabaseId: number;
}): string => {
	const tableId =
		tableNamesToIdMap[sqlDatabaseId + '/' + schemaName + '/' + tableName];
	if (tableId === undefined) {
		return '';
	}
	return getTableDisplayName({ tablesById, tableId });
};
