import styled from 'styled-components';

import { RowNumber } from 'components/Table/RowNumber';

export const SelectRecordCheckbox = styled.input`
	width: 1rem;
	height: 1rem;
	padding: 0;
	margin: 0;
	vertical-align: middle;
	display: none;

	&:checked {
		display: inline-block;

		& + ${RowNumber} {
			display: none;
		}
	}
`;
