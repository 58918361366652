import styled from 'styled-components';

import styles from 'utils/styles';

const DropdownList = styled.ul`
	display: flex;
	flex-direction: column;
	margin: ${styles.global.borderRadius};
	list-style-type: none;
`;

export default DropdownList;
