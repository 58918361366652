import { createAsyncThunk } from '@reduxjs/toolkit';

import { inviteMember as inviteMemberApi } from 'api/inviteMember';
import { Role } from 'typings/models';

export const inviteMember = createAsyncThunk(
	'inviteMember',
	async (
		{
			workspaceId,
			email,
			role,
		}: {
			workspaceId: number;
			email: string;
			role: Role;
		},
		thunkAPI
	) => {
		const response = await inviteMemberApi(workspaceId, email, role);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
	}
);
