import { FetchWorkspaceResponse } from 'api/fetchWorkspace';
import { state } from 'valtioState/index';
import { setAllIds } from 'valtioState/utils';

/** Used to set data source information into valtio state after fetching data with React Query */
export const setSqlDatabases = (
	sqlDatabases: FetchWorkspaceResponse['sqlDatabases']
) => {
	for (const sqlDatabase of sqlDatabases) {
		state.entities.sqlDatabases.byId[sqlDatabase.id] = sqlDatabase;
		setAllIds([sqlDatabase], state.entities.sqlDatabases.ids);
	}
};
