import styled from 'styled-components';

import { runAction } from 'api/runAction';
import { Title } from 'components/toast/ToastContainer';
import { bodyBackground, neutral } from 'utils/colors';
import { toast } from 'utils/toast/toast';

function statusCodeSuccess(httpStatus: number) {
	return httpStatus >= 200 && httpStatus <= 299;
}

const ColoredStatusCode = styled.span<{
	$success: boolean;
}>`
	color: ${(props) => (props.$success ? 'green' : 'red')};
`;

const ExternalResponseRoot = styled.div`
	& > textarea {
		height: 8em;
		resize: none;
		overflow-y: scroll;
		font-family: monospace;
		margin-left: 0.25rem;
		padding: 0.5rem;
		color: ${neutral[1]};
		background: ${bodyBackground};
		border: none;
		box-shadow: inset 0 0 0 1px ${neutral[4]};
	}
`;

interface ExternalResponseProps {
	statusCode: number;
	body: string;
}

function ExternalResponse({ statusCode, body }: ExternalResponseProps) {
	return (
		<ExternalResponseRoot>
			<p>
				Response (
				<ColoredStatusCode $success={statusCodeSuccess(statusCode)}>
					{statusCode}
				</ColoredStatusCode>
				):
			</p>
			<textarea>{body}</textarea>
		</ExternalResponseRoot>
	);
}

const ActionResultInfo = styled.div`
	& p {
		padding: 0.25rem;
	}
`;

export async function handleAction(
	luckyNumber: number,
	pretendExternalCallSucceeds: boolean
) {
	// Pretend parameters, for development. Eventually, this will pull up the
	// instance of the `action` model, by its ID, and get its recipe for what
	// to do from there.
	const res = await runAction({
		luckyNumber,
		pretendExternalCallSucceeds,
	});

	if (res.ok) {
		const response = await res.json();

		if (response.externalStatusCode === 200) {
			toast(
				<ActionResultInfo>
					<Title>Action completed successfully</Title>
					<ExternalResponse
						statusCode={response.externalStatusCode}
						body={response.externalResponseBody}
					/>
				</ActionResultInfo>
			);
		} else {
			toast.error(
				<ActionResultInfo>
					<Title>Action failed</Title>
					<ExternalResponse
						statusCode={response.externalStatusCode}
						body={response.externalResponseBody}
					/>
				</ActionResultInfo>
			);
		}
	} else {
		const error = await res.json();

		toast.error(
			<ActionResultInfo>
				<Title>Action could not be run</Title>
				<p>{error.title}</p>
				<p>{error.detail}</p>
			</ActionResultInfo>
		);
	}
}
