import styled from 'styled-components';

import styles from 'utils/styles';

export const SectionContainer = styled.div`
	display: block;
	align-self: center;
	max-width: 50rem;
	width: 100%;
	height: auto;
	padding: 3rem 1rem;
	padding-bottom: ${styles.global.overscrollHeight};
`;
