import React, { FormEvent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { Callout } from 'components/Callout';
import { DataSourceInstructions } from 'components/DataSourceInstructions';
import TextLink from 'components/TextLink';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Switch } from 'components/fields/Switch';
import { Form as _Form } from 'components/forms/Form';
import { updateDatabase } from 'reduxState/legacyWorkspaceThunksAndActions';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import {
	HostingProvider,
	DataSourceValue,
	DefaultDataSourceValues,
	FormStateField,
} from 'typings/types';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';
import { toggleChat } from 'utils/toggleChat';
import { state } from 'valtioState';
import { createWorkspaceDataSource } from 'valtioState/createWorkspaceDataSource';
import { getAndSyncDataSourceSchema } from 'valtioState/getAndSyncDataSourceSchema';

const formLabelWidth = 11;

const Form = styled(_Form)`
	max-width: 100%;
	margin: 0;

	> :first-child {
		margin-top: 0;
	}
`;

const StyledDataSourceInstructions = styled(DataSourceInstructions)`
	margin-top: 2rem;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 2rem;
`;

const StyledFormButton = styled(FormButton)`
	width: auto;
	min-width: 15rem;
	margin-top: 0;
`;

const Bold = styled.strong`
	font-weight: 600;
	cursor: text;
`;

const TroubleConnectingCallout = styled(Callout)`
	margin-top: 3rem;
`;

interface DatabaseCredentialsFormProps {
	workspaceId: number;
	/** Used if updating existing credentials */
	database?: ApiWorkspaceDetails['sqlDatabases'][number];
	/** Used if adding a new database since the databaseName is specified outside this component */
	databaseName?: string;
	dataSourceValue?: DataSourceValue;
	hostingProvider?: HostingProvider;
	hostingProviderDataSourceType?: DefaultDataSourceValues | null;
}

export function DatabaseCredentialsForm({
	workspaceId,
	database,
	databaseName,
	dataSourceValue,
	hostingProvider,
	hostingProviderDataSourceType,
}: DatabaseCredentialsFormProps) {
	const dispatch = useDispatch();
	const history = useHistory();
	const snap = useSnapshot(state);
	const dataSource =
		database && snap.entities.dataSources.byId[database.dataSourceId];

	const portDefault = useMemo(() => {
		if (hostingProviderDataSourceType?.portDefaultValue) {
			return hostingProviderDataSourceType.portDefaultValue;
		}
		switch (dataSourceValue) {
			case 'postgres':
				return '5432';
			case 'redshift':
				return '5439';
			case 'mssql':
				return '1433';
			case 'mysql':
			case 'mariadb':
				return '3306';
			default:
				return '';
		}
	}, [dataSourceValue, hostingProviderDataSourceType]);

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState<
		FormStateField<'name', string> &
			FormStateField<'host', string> &
			FormStateField<'port', string> &
			FormStateField<'database', string> &
			FormStateField<'username', string> &
			FormStateField<'password', string> &
			FormStateField<'sslEnabled', boolean> &
			FormStateField<'sslClientKey', Blob | null> &
			FormStateField<'sslClientCertificate', Blob | null> &
			FormStateField<'sslCustomCertificatesEnabled', boolean> &
			FormStateField<'sshEnabled', boolean> &
			FormStateField<'sshHost', string> &
			FormStateField<'sshPort', string> &
			FormStateField<'sshUsername', string> &
			FormStateField<'sshPassword', string> &
			FormStateField<'sshKeyEnabled', boolean> &
			FormStateField<'sshKey', Blob | null | undefined> &
			FormStateField<'sshKeyPassphrase', string>
	>({
		name: {
			value: dataSource?.name ?? '',
			errors: null,
		},
		host: {
			value:
				database?.host ?? hostingProviderDataSourceType?.hostDefaultValue ?? '',
			errors: null,
		},
		port: {
			value: database?.port ?? portDefault,
			errors: null,
		},
		database: {
			value:
				database?.database ??
				hostingProviderDataSourceType?.databaseNameDefaultValue ??
				'',
			errors: null,
		},
		username: {
			value:
				database?.username ??
				hostingProviderDataSourceType?.usernameDefaultValue ??
				'',
			errors: null,
		},
		password: {
			value: database?.password ?? '',
			errors: null,
		},
		sslEnabled: {
			value:
				database?.sslEnabled ??
				hostingProviderDataSourceType?.sslEnabledDefaultValue ??
				true,
			errors: null,
		},
		sslClientKey: {
			value: null,
			errors: null,
		},
		sslClientCertificate: {
			value: null,
			errors: null,
		},
		sslCustomCertificatesEnabled: {
			value: database?.sslCustomCertificatesEnabled ?? false,
			errors: null,
		},
		sshEnabled: {
			value: database?.sshEnabled ?? false,
			errors: null,
		},
		sshHost: {
			value: database?.sshHost ?? '',
			errors: null,
		},
		sshPort: {
			value:
				typeof database?.sshPort === 'number' ? String(database.sshPort) : '22',
			errors: null,
		},
		sshUsername: {
			value: database?.sshUsername ?? '',
			errors: null,
		},
		sshPassword: {
			value: database?.sshPassword ?? '',
			errors: null,
		},
		sshKeyEnabled: {
			value: database?.sshKeyEnabled ?? false,
			errors: null,
		},
		sshKey: {
			value: null,
			errors: null,
		},
		sshKeyPassphrase: {
			value: database?.sshKeyPassphrase ?? '',
			errors: null,
		},
	});
	const [connectionError, setConnectionError] = useState<string[] | null>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, type, checked, files } = event.target;
		let value = event.target.value;

		if (['port', 'sshPort'].includes(name)) {
			value = value.replace(/\D/g, '');
		}

		setFields({
			...fields,
			[name]: {
				value:
					type === 'checkbox' ? checked : type === 'file' ? files?.[0] : value,
				errors: null,
			},
		});
	};

	const handleCheckboxClick = (
		name:
			| 'sshKeyEnabled'
			| 'sshEnabled'
			| 'sslCustomCertificatesEnabled'
			| 'sslEnabled',
		value: boolean
	) => {
		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
		event?.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);

		const name = fields.name.value;
		const host = fields.host.value;
		const port = parseInt(fields.port.value);
		const formDatabase = fields.database.value;
		const username = fields.username.value;
		const password = fields.password.value;
		const sslEnabled = fields.sslEnabled.value;
		const sslClientKey = fields.sslClientKey.value;
		const sslClientCertificate = fields.sslClientCertificate.value;
		const sslCustomCertificatesEnabled =
			fields.sslCustomCertificatesEnabled.value;
		const sshEnabled = fields.sshEnabled.value;
		const sshHost = fields.sshHost.value;
		const sshPort = parseInt(fields.sshPort.value);
		const sshUsername = fields.sshUsername.value;
		const sshPassword = fields.sshPassword.value;
		const sshKeyEnabled = fields.sshKeyEnabled.value;
		const sshKey =
			fields.sshKeyEnabled.value && fields.sshKey.value !== null
				? fields.sshKey.value
				: undefined;
		const sshKeyPassphrase = fields.sshKeyPassphrase.value;

		const values = {
			name,
			host,
			port,
			database: formDatabase,
			username,
			password,
			sslEnabled,
			sslClientKey,
			sslClientCertificate,
			sslCustomCertificatesEnabled,
			sshEnabled,
			sshHost,
			sshPort,
			sshUsername,
			sshPassword,
			sshKeyEnabled,
			sshKey,
			sshKeyPassphrase,
		};

		if (hostingProvider?.name === 'localhost') {
			if (values.host.startsWith('tcp://')) {
				values.host = values.host.substring(6);
			}
			// Check if the host includes the port at the end
			const match = values.host.match(/^.*ngrok\.io(:[\d]{4,5})$/);
			if (match) {
				const portString = match[1];
				// Strip out the port from the host
				values.host = values.host.slice(0, -1 * portString.length);
			}
		}

		try {
			let redirectUrl;
			let dataSourceId;

			if (database) {
				await dispatch(
					updateDatabase({
						...values,
						sqlDatabaseId: database.id,
					})
				);
				window.analytics.track('Credentials updated', {
					name: fields.name.value,
				});
				toast('Credentials updated');

				redirectUrl = `/databases/${database.id}/settings`;
				dataSourceId = database.dataSourceId;
			} else {
				const dataSource = await createWorkspaceDataSource({
					...values,
					workspaceId,
					dialect: dataSourceValue as DataSourceValue,
					name: databaseName ?? '',
				});
				if (!dataSource) {
					throw { connectionError: 'Data source creation failed' };
				}
				window.analytics.track('Data source added', {
					name: fields.name.value,
				});
				toast('Data source added');

				redirectUrl = '/data-sources';
				dataSourceId = dataSource.id;

				// Set newly-added datasource to be initially expanded in sidebar
				const localStorageKey =
					LOCAL_STORAGE_KEY.DATA_SOURCE_EXPANDED + '-' + dataSource.id;
				localStorage.setItem(localStorageKey, String(true));
			}

			history.push(redirectUrl);
			getAndSyncDataSourceSchema({ dataSourceId: dataSourceId });
		} catch (error) {
			setLoading(false);
			setConnectionError(null);

			// @ts-expect-error FIX
			if (error.connectionError) {
				// @ts-expect-error FIX
				setConnectionError(error.connectionError);
			}

			// @ts-expect-error FIX
			if (error.formErrors) {
				const updatedFields = { ...fields };
				for (const [field, value] of Object.entries<string[]>(
					// @ts-expect-error FIX
					error.formErrors
				)) {
					const updatedField =
						updatedFields[field as keyof typeof updatedFields];

					if (value.length) {
						updatedField.errors = value;
					} else {
						updatedField.errors = null;
					}
				}

				setFields(updatedFields);
			}

			window.analytics.track('Data source creation/update failed', {
				dialect: dataSourceValue,
				error,
			});
		}
	};

	const allRequiredFieldsFilled =
		fields.host.value && fields.username.value && fields.database.value;

	return (
		<Form onSubmit={handleSubmit}>
			{database && (
				<>
					{fields.sshEnabled.value === false && (
						<Callout>
							Ensure that your database is set to allow remote access from the
							Basedash server's IP address, <Bold>138.197.164.114</Bold>.
						</Callout>
					)}
					<Input
						name="name"
						type="text"
						label="Name"
						helpText="Name used to identify data source in Basedash"
						placeholder="Production"
						value={fields.name.value}
						errors={fields.name.errors}
						onChange={handleChange}
						autoFocus
						labelWidth={formLabelWidth}
					/>
				</>
			)}
			<Input
				name="host"
				type="text"
				label={hostingProvider?.hostLabel ? hostingProvider.hostLabel : 'Host'}
				placeholder=""
				value={fields.host.value}
				errors={fields.host.errors}
				onChange={handleChange}
				maskContent
				labelWidth={formLabelWidth}
			/>
			<Input
				name="port"
				type="text"
				label={hostingProvider?.portLabel ? hostingProvider.portLabel : 'Port'}
				value={fields.port.value}
				errors={fields.port.errors}
				onChange={handleChange}
				maskContent
				labelWidth={formLabelWidth}
			/>
			<Input
				name="database"
				type="text"
				label={
					hostingProvider?.databaseNameLabel
						? hostingProvider.databaseNameLabel
						: 'Database name'
				}
				placeholder="basedash-production"
				value={fields.database.value}
				errors={fields.database.errors}
				onChange={handleChange}
				maskContent
				labelWidth={formLabelWidth}
			/>
			<Input
				name="username"
				type="text"
				label={
					hostingProvider?.usernameLabel
						? hostingProvider.usernameLabel
						: 'Username'
				}
				placeholder="admin"
				value={fields.username.value}
				errors={fields.username.errors}
				onChange={handleChange}
				maskContent
				labelWidth={formLabelWidth}
			/>
			<Input
				name="password"
				type="password"
				label={
					hostingProvider?.passwordLabel
						? hostingProvider.passwordLabel
						: 'Password'
				}
				placeholder="••••••••"
				value={fields.password.value}
				errors={fields.password.errors}
				onChange={handleChange}
				maskContent
				labelWidth={formLabelWidth}
			/>
			<Switch
				label={
					hostingProvider?.sslEnabledLabel
						? hostingProvider.sslEnabledLabel
						: 'Connect with SSL'
				}
				helpText="Recommended for secure communication"
				checked={fields.sslEnabled.value}
				onChange={() =>
					handleCheckboxClick('sslEnabled', !fields.sslEnabled.value)
				}
				errors={fields.sslEnabled.errors}
				maskContent
				labelWidth={formLabelWidth}
			/>
			{fields.sslEnabled.value && (
				<Switch
					label={
						hostingProvider?.sslClientKeyLabel
							? hostingProvider.sslClientKeyLabel
							: 'Custom SSL certificates'
					}
					helpText={'Enable if you have custom certificates to upload'}
					checked={fields.sslCustomCertificatesEnabled.value}
					onChange={() =>
						handleCheckboxClick(
							'sslCustomCertificatesEnabled',
							!fields.sslCustomCertificatesEnabled.value
						)
					}
					errors={fields.sslCustomCertificatesEnabled.errors}
					maskContent
					labelWidth={formLabelWidth}
				/>
			)}
			{fields.sslCustomCertificatesEnabled.value && (
				<>
					<Input
						name="sslClientCertificate"
						type="file"
						label={
							hostingProvider?.sslClientCertificateLabel
								? hostingProvider.sslClientCertificateLabel
								: 'SSL Client Certificate'
						}
						errors={fields.sslClientCertificate.errors}
						onChange={handleChange}
						helpText={database && 'Leave blank to default to existing key.'}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<Input
						name="sslClientKey"
						type="file"
						label={
							hostingProvider?.sslClientKeyLabel
								? hostingProvider.sslClientKeyLabel
								: 'SSL Client Key'
						}
						errors={fields.sslClientKey.errors}
						onChange={handleChange}
						helpText={database && 'Leave blank to default to existing key.'}
						maskContent
						labelWidth={formLabelWidth}
					/>
				</>
			)}

			<Switch
				label={
					hostingProvider?.sshEnabledLabel
						? hostingProvider.sshEnabledLabel
						: 'Connect with SSH'
				}
				helpText="Required if database is in a private network"
				checked={fields.sshEnabled.value}
				onChange={() =>
					handleCheckboxClick('sshEnabled', !fields.sshEnabled.value)
				}
				errors={fields.sshEnabled.errors}
				maskContent
				labelWidth={formLabelWidth}
			/>
			{fields.sshEnabled.value && (
				<>
					<Input
						name="sshHost"
						type="text"
						label={
							hostingProvider?.sshHostLabel
								? hostingProvider.sshHostLabel
								: 'Host'
						}
						value={fields.sshHost.value}
						errors={fields.sshHost.errors}
						onChange={handleChange}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<Input
						name="sshPort"
						type="text"
						label={
							hostingProvider?.sshPortLabel
								? hostingProvider.sshPortLabel
								: 'Port'
						}
						value={fields.sshPort.value}
						errors={fields.sshPort.errors}
						onChange={handleChange}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<Input
						name="sshUsername"
						type="text"
						label={
							hostingProvider?.sshUsernameLabel
								? hostingProvider.sshUsernameLabel
								: 'Username'
						}
						placeholder="admin"
						value={fields.sshUsername.value}
						errors={fields.sshUsername.errors}
						onChange={handleChange}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<Input
						name="sshPassword"
						type="password"
						label={
							hostingProvider?.sshPasswordLabel
								? hostingProvider.sshPasswordLabel
								: 'Password'
						}
						placeholder="••••••••"
						value={fields.sshPassword.value}
						errors={fields.sshPassword.errors}
						onChange={handleChange}
						disabled={fields.sshKeyEnabled.value}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<Switch
						label={
							hostingProvider?.sshKeyEnabledLabel
								? hostingProvider.sshKeyEnabledLabel
								: 'Use SSH key instead of password'
						}
						checked={fields.sshKeyEnabled.value}
						onChange={() =>
							handleCheckboxClick('sshKeyEnabled', !fields.sshKeyEnabled.value)
						}
						errors={fields.sshKeyEnabled.errors}
						maskContent
						labelWidth={formLabelWidth}
					/>

					{fields.sshKeyEnabled.value && (
						<>
							<Input
								name="sshKey"
								type="file"
								label={
									hostingProvider?.sshPrivateKeyLabel
										? hostingProvider.sshPrivateKeyLabel
										: 'SSH private key'
								}
								errors={fields.sshKey.errors}
								onChange={handleChange}
								helpText={
									database &&
									database.sshKeyEnabled &&
									'Leave blank to default to existing key.'
								}
								maskContent
								labelWidth={formLabelWidth}
							/>
							<Input
								name="sshKeyPassphrase"
								type="password"
								label={
									hostingProvider?.sshKeyPassphraseLabel
										? hostingProvider.sshKeyPassphraseLabel
										: 'SSH key passphrase'
								}
								value={fields.sshKeyPassphrase.value}
								errors={fields.sshKeyPassphrase.errors}
								onChange={handleChange}
								helpText="If your SSH private key is not encrypted, leave this blank."
								optional
								maskContent
								labelWidth={formLabelWidth}
							/>
						</>
					)}
				</>
			)}
			{fields.sshEnabled.value === true && (
				<Callout>
					Ensure that your SSH server is set to allow remote access from the
					Basedash server's IP address, <Bold>138.197.164.114</Bold>.
				</Callout>
			)}

			{connectionError && (
				<Callout error>
					There was a problem connecting this data source:
					<br />
					{connectionError}
				</Callout>
			)}

			<ButtonContainer>
				<StyledFormButton
					loading={loading}
					disabled={loading || !allRequiredFieldsFilled}
				>
					{database ? 'Update credentials' : 'Connect data source'}
				</StyledFormButton>
			</ButtonContainer>

			{(hostingProvider?.credentialsInstructions ||
				hostingProvider?.credentialsVideoUrl) && (
				<StyledDataSourceInstructions
					title={'Where do I find that?'}
					instructions={hostingProvider.credentialsInstructions}
					videoUrl={hostingProvider?.credentialsVideoUrl}
				/>
			)}

			<TroubleConnectingCallout>
				Trouble connecting your database?{' '}
				<TextLink as="button" type="button" onClick={toggleChat}>
					Chat with us
				</TextLink>{' '}
				or{' '}
				<TextLink as="a" href="mailto:support@basedash.com">
					email us
				</TextLink>{' '}
				and we'll help you out.
			</TroubleConnectingCallout>
		</Form>
	);
}
