import { rgba } from 'polished';
import theme from 'styled-theming';

import styles from 'utils/styles';

export const background = {
	1: theme('mode', {
		light: '#ffffff',
		dark: '#151515',
	}),
	2: theme('mode', {
		light: rgba('#ffffff', 0.6),
		dark: rgba('#151515', 0.5),
	}),
};

export const primary = {
	1: theme('mode', {
		light: '#061de0',
		dark: '#3c50ff',
	}),
	2: theme('mode', {
		light: '#6a73f4',
		dark: '#2d33a7',
	}),
	3: theme('mode', {
		light: '#1503bf',
		dark: '#636eff',
	}),
	text: theme('mode', {
		light: '#061de0',
		dark: '#636eff',
	}),
};

// For use on top of a primary background (e.g. for text, icons)
export const onPrimary = theme('mode', {
	light: '#ffffff',
	dark: '#ffffff',
});

export const neutral = {
	1: theme('mode', {
		light: '#151515',
		dark: '#ffffff',
	}),
	2: theme('mode', {
		light: rgba('#151515', 0.6),
		dark: rgba('#ffffff', 0.8),
	}),
	3: theme('mode', {
		light: rgba('#151515', 0.3),
		dark: rgba('#ffffff', 0.6),
	}),
	4: theme('mode', {
		light: rgba('#151515', 0.1),
		dark: rgba('#ffffff', 0.15),
	}),
	5: theme('mode', {
		light: rgba('#151515', 0.07),
		dark: rgba('#ffffff', 0.09),
	}),
};

export const bodyBackground = theme('mode', {
	light: rgba('#151515', 0.04),
	dark: rgba('#ffffff', 0.06),
});

export const good = theme('mode', {
	light: '#59d25e',
	dark: '#3fa143',
});

export const warning = theme('mode', {
	light: '#ffc849',
	dark: '#eca500',
});

export const danger = theme('mode', {
	light: '#d8424c',
	dark: '#c2313a',
});

export const backgroundOverlay = theme('mode', {
	light: 'rgba(0, 0, 0, 0.5)',
	dark: 'rgba(0, 0, 0, 0.6)',
});

export const backgroundError = theme('mode', {
	light: styles.colours.error[800],
	dark: styles.colours.error[200],
});
