import { keyframes } from 'goober';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import useMouseLeave from 'use-mouse-leave';

import { Icon } from 'components/Icon';
import { ReactComponent as Dismiss } from 'images/icons/dismiss.svg';
import { background, danger, neutral } from 'utils/colors';
import { MODAL_Z_INDEX } from 'utils/constants';
import styles from 'utils/styles';
import { useStore as useToasterStore } from 'utils/toast/store';
import { toast } from 'utils/toast/toast';
import { Toast, ToastType, resolveValueOrFunction } from 'utils/toast/types';
import { useToaster } from 'utils/toast/use-toaster';

import { ActionButton } from './ActionButton';
import { RefreshFooter } from './RefreshFooter';

const ProgressCircle = ({
	paused,
	duration,
	dismiss,
}: {
	paused: boolean;
	duration: number;
	dismiss: () => void;
}) => {
	const [hovered, setHovered] = useState(false);
	// onMouseLeave doesn't reliably work in React (https://github.com/facebook/react/issues/6807), so
	// we are using a custom hook (https://github.com/mjsarfatti/use-mouse-leave) that provides reliable
	// behavior when a user leaves an element
	const [mouseLeft, ref] = useMouseLeave();
	const radius = 8;
	const stroke = 1;
	const normalizedRadius = radius - stroke * 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const { strokeDashoffset } = useSpring({
		strokeDashoffset: -circumference,
		from: { strokeDashoffset: 0 },
		config: {
			duration,
		},
		pause: paused,
		onRest: dismiss,
	});
	useEffect(() => {
		if (mouseLeft) {
			setHovered(false);
		}
	}, [mouseLeft]);
	return (
		<div
			onMouseEnter={() => setHovered(true)}
			style={{ width: 23, height: 23 }}
			ref={ref}
		>
			{hovered ? (
				<ActionButton onClick={dismiss}>
					<Icon>
						<Dismiss />
					</Icon>
				</ActionButton>
			) : (
				<div style={{ padding: 4, display: 'flex', alignItems: 'center' }}>
					<svg height={radius * 2} width={radius * 2}>
						<animated.circle
							stroke="currentColor"
							fill="transparent"
							strokeWidth={stroke}
							strokeDasharray={circumference + ' ' + circumference}
							style={{
								strokeDashoffset,
								transform: 'rotate(-90deg)',
								transformOrigin: '50% 50%',
							}}
							r={normalizedRadius}
							cx={radius}
							cy={radius}
						/>
					</svg>
				</div>
			)}
		</div>
	);
};

const ToastContainerRoot = styled.div`
	z-index: 3;
	position: absolute;
	right: 0.625rem;
	bottom: 0.625rem;

	> div {
		margin-bottom: 0.625rem;

		&:last-child {
			margin-bottom: 0px;
		}
	}
`;

export const ToastRoot = styled.div`
	color: ${neutral[1]};
	background-color: ${background[1]};
	border-color: ${({ type }: { type?: ToastType }) =>
		type === 'error' ? danger : neutral[1]};
	border-radius: ${styles.global.borderRadius};
	border-style: solid;
	border-width: 1px;
	width: 20rem;
`;

export const TitleContainer = styled.div`
	display: flex;
	padding: 0.5rem;
	padding-bottom: 0px;
`;

export const Title = styled.div`
	font-size: 0.75rem;
	margin-right: auto;
	padding: 0.25rem;
`;

const RowContainer = styled.div`
	padding: 0.5rem;
`;

const RowItem = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 1rem;
	padding-top: 5px;
	padding-bottom: 5px;
`;

const enterAnimation = `
	0% { transform: translateY(calc(100% + 0.625rem)); }
	100% { transform: translateY(0); }
`;

const exitAnimation = `
	0% { opacity: 1; }
	100% { opacity: 0; }
`;

const getAnimationStyle = (visible: boolean): React.CSSProperties => {
	return visible
		? {
				animation: `${keyframes(
					enterAnimation
				)} 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
		  }
		: {
				animation: `${keyframes(exitAnimation)} 0.3s ease-out forwards`,
				pointerEvents: 'none',
		  };
};

interface ToastSectionProps {
	startPause: () => void;
	endPause: () => void;
	toasts: Toast[];
	onDismissAll: () => void;
	pausedAt: number | undefined;
	title?: string;
	type: ToastType;
}

const ToastSection = ({
	startPause,
	endPause,
	toasts,
	onDismissAll,
	pausedAt,
	title,
	type,
}: ToastSectionProps) => {
	return (
		<ToastRoot
			type={type}
			onMouseEnter={startPause}
			onMouseLeave={endPause}
			style={{
				...getAnimationStyle(
					toasts.some((filteredToast) => filteredToast.visible)
				),
			}}
		>
			{title !== undefined && (
				<TitleContainer>
					<Title>{title}</Title>
					<ActionButton>
						<Icon>
							<Dismiss onClick={onDismissAll} />
						</Icon>
					</ActionButton>
				</TitleContainer>
			)}
			<RowContainer>
				{toasts.map((mappedToast) => (
					<RowItem
						key={mappedToast.id}
						role={mappedToast.role}
						aria-live={mappedToast.ariaLive}
					>
						<div style={{ marginRight: 10 }}>
							<ProgressCircle
								paused={pausedAt !== undefined}
								duration={
									mappedToast.duration === undefined
										? 8_000
										: mappedToast.duration
								}
								dismiss={() => toast.dismiss(mappedToast.id)}
							/>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								lineHeight: 1.4,
							}}
						>
							{resolveValueOrFunction(mappedToast.message, mappedToast)}
						</div>
					</RowItem>
				))}
			</RowContainer>
			{toasts.some((filteredToast) => filteredToast.type === 'created') && (
				<RefreshFooter>Refresh view</RefreshFooter>
			)}
		</ToastRoot>
	);
};

function ToastContainer() {
	const { toasts, handlers } = useToaster();
	const element = useRef(document.createElement('div'));
	const { pausedAt } = useToasterStore();
	const { startPause, endPause } = handlers;

	toasts.sort((a, b) => a.createdAt - b.createdAt);

	const errorToasts = toasts.filter(
		(filteredToast) => filteredToast.type === 'error'
	);

	const updateAndCreatedToasts = toasts.filter((filteredToast) =>
		['update', 'created'].includes(filteredToast.type)
	);

	const infoToasts = toasts.filter(
		(filteredToast) => filteredToast.type === 'blank'
	);

	const persistToasts = toasts.filter(
		(filteredToast) => filteredToast.type === 'persist'
	);

	const handleDismiss = (type: ToastType) => {
		if (type === 'error') {
			for (const t of errorToasts) {
				toast.dismiss(t.id);
			}
		}
		if (type === 'update') {
			for (const t of updateAndCreatedToasts) {
				toast.dismiss(t.id);
			}
		}
		if (type === 'blank') {
			for (const t of infoToasts) {
				toast.dismiss(t.id);
			}
		}
	};

	useEffect(() => {
		element.current.className = 'ToastContainerPortal';
		element.current.style.zIndex = String(MODAL_Z_INDEX + 1);
		element.current.style.position = 'relative';
		document.body.appendChild(element.current);
		() => document.body.removeChild(element.current);
	}, []);

	return ReactDOM.createPortal(
		<ToastContainerRoot>
			{persistToasts.map((infoToast) => {
				return resolveValueOrFunction(infoToast.message, infoToast);
			})}
			{infoToasts.length > 0 && (
				<ToastSection
					startPause={startPause}
					endPause={endPause}
					onDismissAll={() => handleDismiss('blank')}
					pausedAt={pausedAt}
					toasts={infoToasts}
					type="blank"
				/>
			)}
			{updateAndCreatedToasts.length > 0 && (
				<ToastSection
					startPause={startPause}
					endPause={endPause}
					onDismissAll={() => handleDismiss('update')}
					pausedAt={pausedAt}
					title="Updates"
					toasts={updateAndCreatedToasts}
					type="update"
				/>
			)}
			{errorToasts.length > 0 && (
				<ToastSection
					startPause={startPause}
					endPause={endPause}
					onDismissAll={() => handleDismiss('error')}
					pausedAt={pausedAt}
					title="Errors"
					toasts={errorToasts}
					type="error"
				/>
			)}
		</ToastContainerRoot>,
		element.current
	);
}

export { ToastContainer };
