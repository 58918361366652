import * as ace from 'ace-builds/src-noconflict/ace';
import { isValid, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _Editor from 'react-ace';
import ReactAce from 'react-ace/lib/ace';
import { Bar, Line } from 'react-chartjs-2';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import {
	runQuery as runApiQuery,
	RunQueryParams,
	RunQueryResponse,
} from 'api/runQuery';
import { trackEvent } from 'api/trackEvent';
import { Container } from 'components/ActionBar';
import { ButtonHovering } from 'components/ButtonHovering';
import { KeyboardShortcut } from 'components/KeyboardShortcut';
import Table from 'components/Table';
import { ViewFooter } from 'components/ViewFooter';
import { useTheme, ThemeMode } from 'components/providers/ThemeProvider';
import { ApiAdvancedView, CellRecord } from 'typings/serverTypes';
import { Layout, TableData__View } from 'typings/types';
import { background, bodyBackground, neutral } from 'utils/colors';
import { CHART_COLORS, REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { extractTableDataForView } from 'utils/extractTableData';
import { nonNullable } from 'utils/nonNullable';
import { canManageViews } from 'utils/permissions';
import styles from 'utils/styles';
import { useCollaborator } from 'utils/useCollaborator';
import { useWorkspace } from 'utils/useWorkspace';
import { state } from 'valtioState';
import { setAttributesInAView } from 'valtioState/views/setAttributesInAView';

import QueryControls from './QueryControls';

import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/mode-pgsql';
import 'ace-builds/src-min-noconflict/theme-xcode';
import 'ace-builds/src-min-noconflict/theme-monokai';

const languageTools = ace.acequire('ace/ext/language_tools');

const QueryContentComponent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const ErrorContainer = styled.p`
	padding: 2rem;
	height: 100%;
	background-color: ${bodyBackground};
`;

const ErrorLabel = styled.span`
	color: ${styles.colours.error[500]};
	font-weight: 700;
	display: inline-block;
	margin-right: 0.25rem;
`;

const ErrorMessage = styled.span`
	color: ${neutral[1]};
`;

const EditorContainer = styled.div`
	position: relative;
	height: 0;
	flex: 1 0 auto;
	padding: 1rem 0;
	background: ${background[1]};
`;

const ResultsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 4rem; // Makes up for padding in EditorContainer
	flex: 1 0 auto;
`;

const Editor = styled(_Editor)<{ themeMode: ThemeMode }>`
	z-index: 0;

	.ace_scroller {
		// Theme colors don't work on the editor so they're hard-coded here
		background: ${({ themeMode }) =>
			themeMode === 'light' ? '#fff' : '#151515'};
	}

	.ace_gutter {
		background: ${({ themeMode }) =>
			themeMode === 'light' ? '#fff' : '#151515'} !important;
		color: ${styles.text.colourTertiary} !important;
	}

	.ace_gutter-active-line {
		background: ${({ themeMode }) =>
			themeMode === 'light' ? '#fff' : '#151515'} !important;
	}

	.ace_placeholder {
		margin: 0;
		padding: 0 0.25rem !important;
		transform: none;
		color: ${styles.text.colourTertiary} !important;
		font-family: inherit;
	}

	.ace_search {
		color: ${({ themeMode }) =>
			themeMode === 'light'
				? styles.colours.neutral[100]
				: styles.colours.neutral[1000]};
		background: ${({ themeMode }) =>
			themeMode === 'light'
				? styles.colours.neutral[1000]
				: styles.colours.neutral[200]};
		border: 1px solid
			${({ themeMode }) =>
				themeMode === 'light'
					? styles.colours.neutral[800]
					: styles.colours.neutral[400]};
		border-radius: 0;
		cursor: default;
	}

	.ace_search_options {
		margin-top: 0.5rem;
	}

	.ace_button {
		padding: 0.25rem;
		color: ${({ themeMode }) =>
			themeMode === 'light'
				? styles.colours.neutral[100]
				: styles.colours.neutral[1000]};
		border: 1px solid
			${({ themeMode }) =>
				themeMode === 'light'
					? styles.colours.neutral[800]
					: styles.colours.neutral[400]};

		&.checked {
			border: 1px solid
				${({ themeMode }) =>
					themeMode === 'light'
						? styles.colours.primary[500]
						: styles.colours.primary[700]};
		}

		&:hover {
			background: ${({ themeMode }) =>
				themeMode === 'light'
					? styles.colours.neutral[900]
					: styles.colours.neutral[300]};
		}
	}

	.ace_search_field,
	.ace_searchbtn {
		color: ${({ themeMode }) =>
			themeMode === 'light'
				? styles.colours.neutral[100]
				: styles.colours.neutral[1000]};
		background: ${({ themeMode }) =>
			themeMode === 'light' ? '#fff' : styles.colours.neutral[100]};
		border: 1px solid
			${({ themeMode }) =>
				themeMode === 'light'
					? styles.colours.neutral[800]
					: styles.colours.neutral[400]};
	}

	.ace_searchbtn {
		border-left: none;
	}

	.ace_searchbtn:hover {
		background: ${({ themeMode }) =>
			themeMode === 'light'
				? styles.colours.neutral[900]
				: styles.colours.neutral[300]};
	}
`;

const Window = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
`;

const ChartContainer = styled.div`
	overflow: auto;
	padding: 2rem;
	height: 100%;
`;

interface Props {
	view: ApiAdvancedView;
	isInViewBuilder?: boolean;
}

export function QueryContent({ view, isInViewBuilder }: Props) {
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);
	const { themeMode } = useTheme();
	const snap = useSnapshot(state);

	const { workspace } = useWorkspace();

	const [editorVisible, setEditorVisible] = useState(false);
	const [resultsVisible, setResultsVisible] = useState(true);
	const [initialQueryValue, setInitialQueryValue] = useState(view.query);
	const [queryValue, setQueryValue] = useState(view.query);
	const [pageNumber, setPageNumber] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [running, setRunning] = useState(false);
	const [hasRun, setHasRun] = useState(false);
	const [records, setRecords] = useState<CellRecord[] | null>(null);
	const [numRecords, setNumRecords] = useState<number | null>(null);
	const [error, setError] = useState<string | null>(null);

	const attributes = view.attributes;

	const editorRef = useRef<ReactAce | null>(null);

	const collaborator = useCollaborator();
	const canEditQuery = canManageViews(collaborator);

	const data: TableData__View = useMemo(
		() => ({
			type: 'view',
			view,
		}),
		[view]
	);

	const { mutate: saveAndRunQuery } = useUpdateApiView(
		{
			view,
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.VIEW,
					{ viewId: view.id, workspaceId },
				]);

				const params: RunQueryParams = {
					viewId: view.id,
					pageNumber,
					searchQuery,
				};
				queryClient
					.fetchQuery<
						unknown,
						string,
						RunQueryResponse,
						[string, RunQueryParams]
					>(['runQuery', params], async () => {
						const response = await runApiQuery(params);
						if (!response.ok) {
							throw await response.json();
						}
						return response.json();
					})
					.then(({ records, numRecords, attributes }) => {
						setRecords(records);
						setNumRecords(numRecords);
						setAttributesInAView(view.id, attributes);

						// Hack to fix bug where editor becomes uneditable
						// after running query (linked to ResizableBox)
						if (editorRef.current && editorRef.current.editor) {
							editorRef.current.editor.blur();
							editorRef.current.editor.focus();
						}
					})
					.catch((error) => {
						setError(error.title);
					})
					.finally(() => {
						setRunning(false);
					});
			},
		}
	);

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view,
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.VIEW,
					{ viewId: view.id, workspaceId },
				]);
			},
		}
	);

	const chartOptions = useMemo(() => {
		const fontColor =
			themeMode === 'light'
				? styles.colours.neutral[100]
				: styles.colours.neutral[1000];

		return {
			maintainAspectRatio: false,
			legend: {
				labels: {
					fontColor,
				},
			},
		};
	}, [themeMode]);

	const displayChartAsLine = useMemo(() => {
		if (
			!records ||
			!attributes ||
			records.length === 0 ||
			attributes.length === 0
		) {
			return false;
		}

		const firstColumnValue = records[0][attributes[0].name];
		const firstColumnIsNumber =
			typeof firstColumnValue === 'number' && !isNaN(firstColumnValue);
		const firstColumnIsDate =
			typeof firstColumnValue === 'string' &&
			isValid(parseISO(firstColumnValue));

		return firstColumnIsNumber || firstColumnIsDate;
	}, [records, attributes]);

	const runQuery = useCallback(() => {
		setRunning(true);
		setError(null);
		setInitialQueryValue(queryValue);
		saveAndRunQuery({ view: { id: view.id, query: queryValue } });
	}, [queryValue, saveAndRunQuery, view.id]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleLoad = (editor: any) => {
		// Remove keybinding used by search bar
		// The find command is remapped to Ctrl/Command-Shift-F
		editor.commands.bindKeys({
			'Ctrl-F': null,
			'Command-F': null,
		});
	};

	useEffect(() => {
		setHasRun(false);
		setInitialQueryValue(view.query);
		setQueryValue(view.query);

		trackEvent({
			type: 'VIEW_OPENED',
			viewId: view.id,
		});
		window.analytics.track('Query Viewed', {
			viewId: view.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [view.id]);

	useEffect(() => {
		setEditorVisible(canEditQuery);
	}, [canEditQuery]);

	useEffect(() => {
		if (queryValue && initialQueryValue && !hasRun) {
			setHasRun(true);
			runQuery();
		}
		setEditorVisible(canEditQuery);
	}, [view.id, queryValue, initialQueryValue, hasRun, canEditQuery, runQuery]);

	useEffect(() => {
		if (!hasRun) {
			return;
		}

		runQuery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber, searchQuery]);

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleKeyDown = (event) => {
		if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
			event.preventDefault();
			runQuery();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
	const handleChange = (value) => {
		setQueryValue(value);
	};

	// Autosave query while typing
	useEffect(() => {
		// Debounce time
		const delay = 500;

		const handler = setTimeout(() => {
			updateApiView({ view: { id: view.id, query: queryValue } });
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [queryValue, updateApiView, view.id]);

	const toggleEditor = () => {
		// If hiding editor, ensure results are visible
		if (editorVisible) {
			setResultsVisible(true);
		}

		setEditorVisible(!editorVisible);
	};

	const toggleResults = () => {
		// If hiding results, ensure editor is visible
		if (resultsVisible) {
			setEditorVisible(true);
		}

		setResultsVisible(!resultsVisible);
	};

	useEffect(() => {
		function wrapInQuotesIfContainsCapitals(name: string) {
			if (name === name.toLocaleLowerCase()) {
				return name;
			} else {
				return `"${name}"`;
			}
		}

		const schemaCompletions = Object.values(snap.entities.schemas.byId)
			.filter(nonNullable)
			.map((schema) => schema.name)
			.map(wrapInQuotesIfContainsCapitals)
			.map((name) => ({ value: name, meta: 'schema' }));

		const tableCompletions = Object.values(snap.entities.tables.byId)
			.filter(nonNullable)
			.map((table) => table.tableName)
			.map(wrapInQuotesIfContainsCapitals)
			.map((name) => ({ value: name, meta: 'table' }));

		const attributeCompletions = Object.values(snap.entities.attributes.byId)
			.filter(nonNullable)
			.map((attribute) => attribute.attributeName)
			.map(wrapInQuotesIfContainsCapitals)
			.map((name) => ({ value: name, meta: 'column' }));

		const completer = {
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
			getCompletions: function (editor, session, pos, prefix, callback) {
				callback(null, [
					...schemaCompletions,
					...tableCompletions,
					...attributeCompletions,
				]);
			},
		};
		languageTools.setCompleters([completer]);
	}, [
		snap.entities.attributes.byId,
		snap.entities.schemas.byId,
		snap.entities.tables.byId,
		view,
	]);

	const extractedTableData = extractTableDataForView(data);

	const renderEditor = () => {
		return (
			<EditorContainer>
				<Editor
					key={String(resultsVisible)}
					ref={editorRef}
					mode="pgsql"
					theme={themeMode === 'light' ? 'xcode' : 'monokai'}
					themeMode={themeMode}
					width="100%"
					height="100%"
					highlightActiveLine={false}
					showPrintMargin={false}
					focus={typeof view.sqlDatabaseId === 'number'}
					value={queryValue || ''}
					onChange={handleChange}
					onLoad={handleLoad}
					readOnly={!canEditQuery || !view.sqlDatabaseId}
					placeholder='select * from "User" limit 10'
					style={{
						fontSize: '14px',
						fontFamily: styles.text.fontFamilyMonospace,
						lineHeight: '1.5',
					}}
					enableLiveAutocompletion
					editorProps={{
						$blockScrolling: Infinity,
					}}
					commands={[
						{
							name: 'find',
							bindKey: { win: 'Ctrl-Shift-F', mac: 'Command-Shift-F' },
							exec: 'find',
						},
					]}
				/>

				{queryValue !== initialQueryValue && (
					<ButtonHovering onClick={runQuery}>
						Run query <KeyboardShortcut keys={['Ctrl', 'Enter']} />
					</ButtonHovering>
				)}
			</EditorContainer>
		);
	};

	const renderResults = () => {
		return (
			<>
				<ResultsContainer>
					{records &&
					attributes.length > 0 &&
					data.view.layout === Layout.CHART ? (
						<ChartContainer>
							{displayChartAsLine ? (
								<Line
									options={chartOptions}
									data={{
										labels: records.map((record) => record[attributes[0].name]),
										datasets: attributes.slice(1).map((column, index) => ({
											label: column.name,
											fill: false,
											lineTension: 0.1,
											backgroundColor:
												CHART_COLORS[index % CHART_COLORS.length],
											borderColor: CHART_COLORS[index % CHART_COLORS.length],
											borderCapStyle: 'butt',
											data: records.map((record) => record[column.name]),
										})),
									}}
								/>
							) : (
								<Bar
									options={chartOptions}
									data={{
										labels: records.map((record) => record[attributes[0].name]),
										datasets: attributes.slice(1).map((column, index) => ({
											label: column.name,
											backgroundColor:
												CHART_COLORS[index % CHART_COLORS.length],
											data: records.map((record) => record[column.name]),
										})),
									}}
								/>
							)}
						</ChartContainer>
					) : (
						<Window>
							{error ? (
								<ErrorContainer>
									<ErrorLabel>Error: </ErrorLabel>
									<ErrorMessage>{error}</ErrorMessage>
								</ErrorContainer>
							) : (
								<Table
									collaborators={workspace?.collaborators ?? []}
									pageNumber={pageNumber}
									setPageNumber={setPageNumber}
									editable={false}
									records={records}
									recordsLoading={running ? true : false}
									recordsError={null}
									data={data}
									extractedTableData={extractedTableData}
									searchQuery={searchQuery}
									numRecords={numRecords ?? undefined}
								/>
							)}
						</Window>
					)}
				</ResultsContainer>

				{data.view.layout !== Layout.CHART && (
					<ViewFooter
						loading={running ? true : undefined}
						numRecords={numRecords ?? 0}
						setPageNumber={setPageNumber}
						pageNumber={pageNumber}
						extractedTableData={extractedTableData}
					/>
				)}
			</>
		);
	};

	return (
		<QueryContentComponent>
			{!isInViewBuilder && (
				<QueryControls
					data={data}
					editorVisible={editorVisible}
					resultsVisible={resultsVisible}
					toggleEditor={toggleEditor}
					toggleResults={toggleResults}
					runQuery={runQuery}
					records={records ?? []}
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					setPageNumber={setPageNumber}
				/>
			)}

			<Container>
				{editorVisible && renderEditor()}
				{resultsVisible && renderResults()}
			</Container>
		</QueryContentComponent>
	);
}
