import { useEffect } from 'react';

import { checkDataSourceHealth } from 'valtioState/dataSources/checkDataSourceHealth';
import { DefinedDataSource } from 'valtioState/dataSources/index';

export function useDataSourceHealthCheck(dataSource: DefinedDataSource) {
	useEffect(() => {
		if (
			dataSource.healthy === undefined &&
			dataSource.id !== undefined &&
			dataSource.connected
		) {
			checkDataSourceHealth(dataSource);
		}
	}, [dataSource, dataSource.healthy, dataSource.id, dataSource.connected]);
}
