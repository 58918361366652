import styled from 'styled-components';

import { RefreshFooter } from './RefreshFooter';
import { ToastRoot, Title, TitleContainer } from './ToastContainer';

const Message = styled.p`
	padding: 0.75rem;
	padding-bottom: 0.5rem;
	padding-top: 0.25rem;
`;

export function NewUpdateAvailable() {
	return (
		<ToastRoot>
			<TitleContainer>
				<Title>New update available</Title>
			</TitleContainer>
			<Message>
				Basedash has updated. Refresh to get the latest version.
			</Message>
			<RefreshFooter>Refresh</RefreshFooter>
		</ToastRoot>
	);
}
