import { getDefaultHeaders } from 'api/utils';
import { View } from 'typings/models';

export type FetchTableAffectedViewsResponse = View[];
type GetTableAffectedViewsParams = {
	tableId: number;
};

export function getTableAffectedViews({
	tableId,
}: GetTableAffectedViewsParams) {
	return fetch(`/api/tables/${tableId}/affected-views`, {
		headers: getDefaultHeaders(),
	});
}
