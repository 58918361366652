import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useApiBillingInformation } from 'api/reactQueryHooks/useApiBillingInformation';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Card from 'components/Card';
import { Icon } from 'components/Icon';
import Page from 'components/Page';
import PaymentMethodInfo from 'components/PaymentMethodInfo';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import { CardInput } from 'components/fields/CardInput';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { changeWorkspacePlan } from 'reduxState/legacyWorkspaceThunksAndActions';
import { useAppDispatch } from 'reduxState/store';
import { setPaymentMethod } from 'reduxState/thunks/setPaymentMethod';
import { ApiUser } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { canManageBilling } from 'utils/permissions';
import { Plan } from 'utils/plans';
import { getPlanPrice } from 'utils/plans';
import styles from 'utils/styles';
import { toast } from 'utils/toast/toast';

const CardContent = styled.div`
	max-width: 30rem;
	margin: 0 auto;

	p + p {
		margin-top: 2rem;
	}
`;

const InfoSection = styled.section`
	display: flex;
	flex-direction: column;
	margin-bottom: 2.5rem;
`;

const InfoSectionName = styled.h4`
	margin-bottom: 0.375rem;
	font-size: 0.875rem;
	font-weight: 500;
	text-transform: uppercase;
	color: ${neutral[2]};
	cursor: default;
`;

const InfoSectionTitle = styled.strong`
	font-size: 1.125rem;
	font-weight: 500;
	margin-bottom: 0.125rem;
	cursor: default;
`;

const InfoSectionDetails = styled.span`
	font-size: 0.875rem;
	color: ${neutral[2]};
	cursor: default;
`;

const BillingItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	cursor: default;
`;

const BillingItemTitle = styled.strong`
	font-weight: 500;
	cursor: default;
`;

const FeatureContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 4rem;
`;

const Feature = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.75rem;
	line-height: 1.125;
	cursor: default;
`;

const FeatureCheck = styled.div`
	margin-right: 0.5rem;
`;

const Label = styled.label`
	display: block;
	font-weight: 500;
	user-select: none;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
`;

const ErrorText = styled.p`
	color: ${styles.colours.error[500]};
	font-size: 0.875rem;
	font-weight: 400;
`;

export default function UpgradePlanRoute() {
	const stripe = useStripe();
	const elements = useElements();
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	// eslint-disable-next-line @typescript-eslint/ban-types
	const [cardError, setCardError] = useState<Object | null>(null);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState(null);
	const [loading, setLoading] = useState(false);
	const workspaceId = useSelector((state) => state.workspaceId);
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const history = useHistory();

	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);

	const { data: billingInformation } = useApiBillingInformation(workspace?.id, {
		enabled: canManageBilling(collaborator ?? null) && workspace !== undefined,
	});

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleChange = (event) => {
		const { name, value } = event.target;

		switch (name) {
			// @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
			case 'name': {
				setName(value);
				setNameError(null);
			}
			// @ts-expect-error ts-migrate(7029) FIXME: Fallthrough case in switch.
			case 'card': {
				setCardError(null);
			}
			default: {
				return;
			}
		}
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading || workspaceId === null || stripe === null) {
			return;
		}
		setLoading(true);

		if (billingInformation?.paymentMethod === undefined) {
			setCardError(null);
			setNameError(null);

			if (!name.length) {
				// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Enter the name on your card."' ... Remove this comment to see the full error message
				setNameError('Enter the name on your card.');
				setLoading(false);
				return;
			}

			const cardElement = elements?.getElement(CardElement);
			const {
				token,
				error,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} = await stripe.createToken(cardElement as any, { name });

			if (error) {
				setCardError(error);
				setLoading(false);
				return;
			}

			if (!token) {
				setLoading(false);
				return;
			}

			try {
				await dispatch(
					setPaymentMethod({ workspaceId, tokenId: token.id })
				).unwrap();
			} catch {
				toast.error('There was a problem setting your payment method.');
				return;
			}
		}

		await dispatch(changeWorkspacePlan(workspaceId, 'Startup'));
		await queryClient.invalidateQueries([
			REACT_QUERY_CACHE_KEY.WORKSPACE,
			workspaceId,
		]);
		history.push('/settings');
		toast('Workspace upgraded.');
	};

	const stripeElementsStyle = () => ({
		base: {
			color:
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'mode' does not exist on type 'DefaultThe... Remove this comment to see the full error message
				theme.mode === 'light'
					? styles.colours.neutral[100]
					: styles.colours.neutral[1000],
			fontSize: '1.125rem',
			fontFamily: styles.text.fontFamilyText,
		},
	});

	if (!collaborator || !workspace) {
		return <Spinner />;
	}

	if (!canManageBilling(collaborator)) {
		return <Redirect to="/settings" />;
	}

	if (workspace.plan === 'Startup') {
		return <Redirect to="/settings" />;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<SettingsIcon />} title="Upgrade workspace" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section>
							<Card>
								<CardContent>
									<InfoSection>
										<InfoSectionName>Workspace</InfoSectionName>
										<InfoSectionTitle>{workspace.name}</InfoSectionTitle>
										<InfoSectionDetails>
											{workspace.plan}, ${getPlanPrice(workspace.plan as Plan)}{' '}
											per user / month
										</InfoSectionDetails>
									</InfoSection>

									<InfoSection>
										<BillingItem>
											<BillingItemTitle>New monthly price</BillingItemTitle>
											<p>${getPlanPrice(Plan.Startup)} / month</p>
										</BillingItem>
									</InfoSection>

									<FeatureContainer>
										<Feature>
											<FeatureCheck>
												<Icon>
													<CheckIcon />
												</Icon>
											</FeatureCheck>
											Unlimited databases
										</Feature>
										<Feature>
											<FeatureCheck>
												<Icon>
													<CheckIcon />
												</Icon>
											</FeatureCheck>
											Unlimited collaborators
										</Feature>
										<Feature>
											<FeatureCheck>
												<Icon>
													<CheckIcon />
												</Icon>
											</FeatureCheck>
											Unlimited edit history
										</Feature>
										<Feature>
											<FeatureCheck>
												<Icon>
													<CheckIcon />
												</Icon>
											</FeatureCheck>
											Real-time Slack notifications
										</Feature>
										<Feature>
											<FeatureCheck>
												<Icon>
													<CheckIcon />
												</Icon>
											</FeatureCheck>
											Email + Slack support
										</Feature>
									</FeatureContainer>

									<Form onSubmit={handleSubmit}>
										{billingInformation?.paymentMethod ? (
											<PaymentMethodInfo
												paymentMethod={billingInformation.paymentMethod}
											/>
										) : (
											<>
												<Label>
													Card information
													<CardInput
														// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
														stripe={stripe}
														elements={elements}
														options={{ style: stripeElementsStyle() }}
													/>
													{cardError && (
														// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
														<ErrorText>{cardError.message}</ErrorText>
													)}
												</Label>

												<Input
													name="name"
													type="text"
													label="Name on card"
													value={name}
													onChange={handleChange}
													errors={nameError && [nameError]}
												/>
											</>
										)}
										<FormButton loading={loading}>Upgrade workspace</FormButton>
									</Form>
								</CardContent>
							</Card>
						</Section>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
