import { ApiRecordAttribute } from 'typings/serverTypes';

export function areAttributesEqual({
	attribute,
	listAttribute,
}: {
	attribute: ApiRecordAttribute;
	listAttribute: ApiRecordAttribute;
}) {
	return attribute.id === listAttribute.id;
}
