import { getDefaultHeaders } from 'api/utils';

export function oauthSlack(viewId: number, code: string | null) {
	return fetch('/auth/oauth/slack', {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			viewId,
			code,
		}),
	});
}
