import { getDefaultHeaders } from 'api/utils';

export function deleteMemberInvitation(
	workspaceId: number,
	invitationId: number
) {
	return fetch(
		`/api/workspaces/${workspaceId}/member-invitations/${invitationId}`,
		{
			method: 'DELETE',
			headers: getDefaultHeaders(),
		}
	);
}
