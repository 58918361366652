import { isValid, parse } from 'date-fns';
import JsonEditor from 'react-ace';
import styled from 'styled-components';

import { Checkbox } from 'components/Checkbox';
import { Chip } from 'components/Chip';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as ArrowRightIcon } from 'images/icons/arrowRight.svg';
import { ColumnType } from 'typings/models';
import { CellValue } from 'typings/serverTypes';
import {
	DATE_FORMAT,
	DATETIME_FORMAT,
	EMPTY_VISUAL_SYMBOL,
	NULL_VISUAL_SYMBOL,
} from 'utils/constants';
import { formatDateAndTime } from 'utils/formatDateAndTime';
import { getCheckedValue } from 'utils/getCheckedValue';
import styles from 'utils/styles';

import { useTheme } from './providers/ThemeProvider';

interface TextValueProps {
	isLongFormat: boolean;
	isPrevious: boolean;
	isObscured?: boolean;
	value?: null | '';
}

const StyledJsonEditor = styled(JsonEditor)`
	flex-shrink: 0;
	z-index: 0;
	flex-grow: 1;
	border-radius: ${styles.global.borderRadius};
	font-size: 14px;
	font-family: ${styles.text.fontFamilyMonospace};
	line-height: 1.5;
	border: 1px solid
		${(props: { themeMode: 'light' | 'dark' }) =>
			props.themeMode === 'light'
				? styles.colours.neutral[800]
				: styles.colours.neutral[400]};

	.ace_scroller {
		background: ${(props: { themeMode: 'light' | 'dark' }) =>
			props.themeMode === 'light' ? '#fff' : styles.colours.neutral[100]};
	}
`;

const TextValue = styled.span.attrs({
	className: 'fs-mask highlight-block',
})`
	white-space: ${({ isLongFormat }: TextValueProps) =>
		isLongFormat ? `normal` : `nowrap`};
	max-width: ${({ isLongFormat }: TextValueProps) =>
		// 15 px is to account for the arrow
		isLongFormat ? `calc(50% - 15px)` : `100px`};
	overflow: hidden;
	text-overflow: ellipsis;
	opacity: ${({ isPrevious }: TextValueProps) => (isPrevious ? 0.6 : 1)};
	font-style: ${({ value }: TextValueProps) =>
		value === null || value === '' ? 'italic' : 'normal'};
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-family: ${({ isObscured }: TextValueProps) =>
		isObscured ? `Basehash` : `${styles.text.fontFamilyText}`};
`;

interface StyledForeignKeyValueProps {
	isLongFormat: boolean;
	isPrevious: boolean;
}

const StyledForeignKeyValue = styled(Chip).withConfig({
	shouldForwardProp: (prop) => !['isLongFormat', 'isPrevious'].includes(prop),
})`
	max-width: ${({ isLongFormat }: StyledForeignKeyValueProps) =>
		isLongFormat ? `unset` : `96px`};
	display: inline-block;
	white-space: nowrap;
	opacity: ${({ isPrevious }: StyledForeignKeyValueProps) =>
		isPrevious ? 0.6 : 1};
`;

const CheckboxWrapper = styled.div`
	transform: scale(0.8);
`;

const Arrow = styled(Icon)`
	display: inline-flex;
	margin: 0 0.5rem;
`;

interface PrettyValueProps {
	type: ColumnType;
	rawValue: CellValue;
	displayValue: CellValue;
	isPrevious?: boolean;
	themeMode: 'light' | 'dark';
	isLongFormat: boolean;
	isObscured?: boolean;
	dateInfo: {
		isSameDate?: boolean | null;
	};
}

function PrettyValue({
	type,
	displayValue,
	rawValue,
	isPrevious = false,
	dateInfo,
	isLongFormat,
	themeMode,
	isObscured = false,
}: PrettyValueProps) {
	if (rawValue === null || rawValue === '') {
		return (
			<TextValue
				isPrevious={isPrevious}
				isLongFormat={isLongFormat}
				value={rawValue}
			>
				{rawValue === null ? NULL_VISUAL_SYMBOL : EMPTY_VISUAL_SYMBOL}
			</TextValue>
		);
	}
	if (type === 'BOOLEAN' || typeof displayValue === 'boolean') {
		const checked = getCheckedValue(displayValue);

		return (
			<CheckboxWrapper className="fs-exclude highlight-block">
				<Checkbox checked={checked} disabled />
			</CheckboxWrapper>
		);
	}
	if (type === 'JSON' && typeof displayValue === 'string') {
		const formattedJSON = JSON.stringify(JSON.parse(displayValue), null, 2);
		return (
			<StyledJsonEditor
				className="fs-mask highlight-block"
				readOnly
				mode="json"
				wrapEnabled={true}
				theme={themeMode === 'light' ? 'xcode' : 'monokai'}
				themeMode={themeMode}
				width="unset"
				height="6rem"
				showGutter={false}
				highlightActiveLine={false}
				showPrintMargin={false}
				value={formattedJSON}
				editorProps={{
					$blockScrolling: Infinity,
				}}
			/>
		);
	}
	if (type === 'FOREIGN_KEY') {
		return (
			<StyledForeignKeyValue
				isPrevious={isPrevious}
				isLongFormat={isLongFormat}
				maskContent
			>
				{displayValue}
			</StyledForeignKeyValue>
		);
	}
	if (['DATETIME', 'DATE'].includes(type) && typeof displayValue === 'string') {
		const parsedDate = parse(
			displayValue,
			type === 'DATE' ? DATE_FORMAT : DATETIME_FORMAT,
			new Date()
		);
		if (parsedDate && isValid(parsedDate)) {
			const { formattedDate, formattedDateWithTime } = formatDateAndTime({
				type,
				value: parsedDate,
				dateInfo,
			});
			return (
				<Tooltip value={formattedDateWithTime}>
					<TextValue isPrevious={isPrevious} isLongFormat={isLongFormat}>
						{formattedDate}
					</TextValue>
				</Tooltip>
			);
		}
		return (
			<Tooltip value={displayValue}>
				<TextValue isPrevious={isPrevious} isLongFormat={isLongFormat}>
					{displayValue}
				</TextValue>
			</Tooltip>
		);
	}
	return (
		<TextValue
			isPrevious={isPrevious}
			isLongFormat={isLongFormat}
			isObscured={isObscured}
		>
			{displayValue}
		</TextValue>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	white-space: pre;
`;

interface Props {
	previousRawValue: CellValue;
	newRawValue: CellValue;
	previousDisplayValue: CellValue;
	newDisplayValue: CellValue;
	type: ColumnType;
	isSameDate?: boolean | null;
	/** Determines how much the text will be truncated */
	isLongFormat?: boolean;
	isObscured?: boolean;
}

export function BeforeAndAfterValues({
	previousRawValue,
	newRawValue,
	previousDisplayValue,
	newDisplayValue,
	type,
	isSameDate,
	isLongFormat = false,
	isObscured = false,
}: Props) {
	const { themeMode } = useTheme();

	if (isObscured) {
		return (
			<Container>
				<>Obscured value overwritten</>
			</Container>
		);
	}

	if (!isLongFormat && type === 'JSON') {
		return (
			<Container>
				<>JSON value modified</>
			</Container>
		);
	}

	return (
		<Container>
			<PrettyValue
				rawValue={previousRawValue}
				displayValue={previousDisplayValue}
				isPrevious
				type={type}
				dateInfo={{ isSameDate }}
				isLongFormat={isLongFormat}
				themeMode={themeMode}
				isObscured={isObscured}
			/>
			<Arrow>
				<ArrowRightIcon />
			</Arrow>
			<PrettyValue
				rawValue={newRawValue}
				displayValue={newDisplayValue}
				type={type}
				dateInfo={{ isSameDate }}
				isLongFormat={isLongFormat}
				themeMode={themeMode}
				isObscured={isObscured}
			/>
		</Container>
	);
}
