import copy from 'copy-text-to-clipboard';

import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';
import { toast } from 'utils/toast/toast';

import { Icon } from './Icon';
import { Input } from './fields/Input';

interface Props {
	label?: string;
	value: string;
	name?: string;
	autoFocus?: boolean;
}

function CopyToClipboardInput(props: Props) {
	const handleClick = () => {
		copy(props.value);
		toast('Copied to clipboard.');
	};
	return (
		<Input
			name="name"
			type="text"
			readOnly
			autoFocus
			onFocus={(event) => event.target.select()}
			onClick={handleClick}
			icon={
				<Icon>
					<CopyIcon />
				</Icon>
			}
			pointer
			disabledBackground
			labelWidth={11}
			{...props}
		/>
	);
}

export { CopyToClipboardInput };
