export enum Plan {
	Basic = 'Basic',
	Startup = 'Startup',
	Enterprise = 'Enterprise',
}

export const getPlanPrice = (plan: Plan) => {
	switch (plan) {
		case Plan.Basic: {
			return 0;
		}
		case Plan.Startup: {
			return 25;
		}
		case Plan.Enterprise: {
			return 0;
		}
	}
};

export const basicPlanMemberLimit = 2;
export const basicPlanActivityHistoryWeeks = 2;
