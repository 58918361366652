import { useEffect, useRef, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

import { trackEvent } from 'api/trackEvent';
import { useSocket } from 'components/providers/SocketProvider';
import { useToken } from 'components/providers/TokenProvider';
import { setConnectedUsers } from 'reduxState/slices/connectedUsers';
import {
	addSelectedCell,
	removeSelectedCell,
} from 'reduxState/slices/selectedCells';
import { TableStackState } from 'reduxState/slices/tableStack';
import {
	ApiBasicView,
	ApiWorkspaceDetails,
	CellRecord,
	Filter,
	Sort,
	StringRecordId,
} from 'typings/serverTypes';
import { SelectedRecord } from 'typings/types';
import { state } from 'valtioState';
import { getViewRecords } from 'valtioState/records/getRecords';
import { updateAttributeValueWithinRecord } from 'valtioState/records/updateAttributeValueWithinRecord';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

export interface UseViewRecordsResult {
	reload: () => void;
	records: CellRecord[] | null;
	recordsLoading: boolean;
	recordsError: string | null;
	pageNumber: number;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	numRecords: number;
	tableStack: TableStackState;
	windowRef: React.RefObject<HTMLDivElement>;
	collaborators: ApiWorkspaceDetails['collaborators'];
}

type Props = {
	workspace?: ApiWorkspaceDetails;
	viewId: number;
	filters?: Filter[];
	sorts?: Sort[];
	view: ApiBasicView;
	isInViewBuilder?: boolean;
};

export const useViewRecords = ({
	workspace,
	viewId,
	filters,
	sorts,
	view,
	isInViewBuilder = false,
}: Props): UseViewRecordsResult => {
	const windowRef = useRef<HTMLDivElement>(null);
	const socket = useSocket();
	const [autoFocusFirstTableCell, setAutoFocusFirstTableCell] = useState(true);
	const dispatch = useDispatch();
	const { token } = useToken();
	const queryClient = useQueryClient();
	const snap = useSnapshot(state);

	const viewFromValtio = snap.entities.views.byId[viewId];

	const [pageNumber, setPageNumber] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');

	const [recordsLoading, setRecordsLoading] = useState(false);
	const [recordsError, setRecordsError] = useState<null | string>(null);

	const [recordIds, setRecordIds] = useState<StringRecordId[]>([]);
	const [numRecords, setNumRecords] = useState(0);

	const fetchRecordsFromApiAndUpdateGlobalState = useCallback(async () => {
		setRecordsLoading(true);
		try {
			const result = await getViewRecords({
				tableId: view.baseTableId,
				viewId,
				// Filters and sorts to be passed when temporary filters/sorts are supported
				filters: [],
				sorts: [],
				pageNumber,
				searchQuery,
				primaryKeyAttributes: view.primaryKeyAttributes,
				includeAllConnectedAttributes: isInViewBuilder,
			});
			setRecordIds(result.recordIds);
			setNumRecords(result.numRecords);
		} catch (e) {
			if (e instanceof Error) {
				setRecordsError(e.message);
			} else {
				setRecordsError('Something went wrong');
			}
		} finally {
			setRecordsLoading(false);
		}
	}, [
		pageNumber,
		searchQuery,
		view.baseTableId,
		view.primaryKeyAttributes,
		viewId,
		isInViewBuilder,
	]);

	useEffect(() => {
		fetchRecordsFromApiAndUpdateGlobalState();
	}, [fetchRecordsFromApiAndUpdateGlobalState]);

	useEffect(() => {
		if (viewFromValtio?.data?.staleRecords) {
			fetchRecordsFromApiAndUpdateGlobalState();
			updateViewRecordsStaleStatus(viewId, false);
		}
	}, [
		fetchRecordsFromApiAndUpdateGlobalState,
		viewFromValtio?.data?.staleRecords,
		viewId,
	]);

	const getApiRecordsFromGlobalState = (): CellRecord[] => {
		const result = [];
		for (const recordId of recordIds) {
			const record = snap.entities.records.byId[recordId];
			if (record) {
				result.push(record);
			}
		}
		return result;
	};

	const records = getApiRecordsFromGlobalState();

	const tableStack = useSelector((state) => state.tableStack);

	useEffect(() => {
		// Reset state on new view
		setPageNumber(0);
		setSearchQuery('');

		// Scroll to top of view
		if (windowRef.current) {
			windowRef.current.scrollTo(0, 0);
		}

		trackEvent({
			type: 'VIEW_OPENED',
			viewId,
		});
		window.analytics.track('View Viewed', {
			viewId,
		});
	}, [viewId, windowRef]);

	// Focus on first cell of table
	useEffect(() => {
		if (records === null || records.length === 0) {
			return;
		}

		if (autoFocusFirstTableCell && windowRef.current) {
			setAutoFocusFirstTableCell(false);

			const firstCell = windowRef.current.querySelector('.firstCell > div');
			if (firstCell) {
				(firstCell as HTMLTableCellElement).focus();
			}
		}
	}, [records, autoFocusFirstTableCell, windowRef]);

	// Realtime updates
	useEffect(() => {
		if (!view || !view.schemaName || !view.tableName) {
			return;
		}

		socket.emit('join-room', {
			token,
			tableId: view.baseTableId,
		});

		const handleUpdateRecord = (data: {
			value: string;
			attributeId: number;
			stringRecordId: StringRecordId;
		}) => {
			updateAttributeValueWithinRecord({
				recordId: data.stringRecordId,
				attributeId: data.attributeId,
				newValueForAttribute: data.value,
			});
		};
		socket.on('update-record', handleUpdateRecord);

		const handleUpdateConnectedUsers = (userIds: number[]) => {
			dispatch(setConnectedUsers(userIds));
		};
		socket.on('update-connected-users', handleUpdateConnectedUsers);

		const handleAddSelectedCell = ({ userId, cellId }: SelectedRecord) => {
			dispatch(addSelectedCell({ userId, cellId }));
		};
		socket.on('add-selected-cell', handleAddSelectedCell);

		const handleRemoveSelectedCell = ({ userId }: { userId: number }) => {
			dispatch(removeSelectedCell({ userId }));
		};
		socket.on('remove-selected-cell', handleRemoveSelectedCell);

		return () => {
			if (!view || !view.schemaName || !view.tableName) {
				return;
			}

			socket.emit('leave-room', {
				token,
				tableId: view.baseTableId,
			});

			socket.removeEventListener('update-record');
			socket.removeEventListener('update-connected-users');
			socket.removeEventListener('add-selected-cell');
			socket.removeEventListener('remove-selected-cell');
		};
	}, [
		token,
		socket,
		dispatch,
		queryClient,
		pageNumber,
		searchQuery,
		filters,
		sorts,
		viewId,
		view,
		snap.attributeNamesToIdMap,
	]);

	useEffect(() => {
		if (!recordsError) {
			return;
		}

		console.error('Table loading error', recordsError);
	}, [recordsError]);

	return {
		reload: fetchRecordsFromApiAndUpdateGlobalState,
		records,
		recordsLoading,
		recordsError,
		pageNumber,
		setPageNumber,
		searchQuery,
		setSearchQuery,
		numRecords,
		tableStack,
		windowRef,
		collaborators: workspace?.collaborators ?? [],
	};
};
