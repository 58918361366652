import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { handleFormErrors } from 'components/forms/formUtils';
import { enableTwoFactorAuthentication } from 'reduxState/thunks/enableTwoFactorAuthentication';
import { ApiUser } from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';

export function TwoFactorAuthenticationForm() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({
		countryCode: {
			value: '+1',
			errors: null,
		},
		phoneNumber: {
			value: '',
			errors: null,
		},
	});
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading || userId === null) {
			return;
		}
		setLoading(true);

		try {
			await dispatch(
				enableTwoFactorAuthentication({
					userId,
					countryCode: fields.countryCode.value,
					phoneNumber: fields.phoneNumber.value,
				})
			);
			queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
			setLoading(false);
			toast('Two-factor authentication enabled.');
		} catch (error) {
			handleFormErrors(error, fields, setFields);
			setLoading(false);
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="countryCode"
				type="text"
				label="Country code"
				placeholder="+1"
				value={fields.countryCode.value}
				errors={fields.countryCode.errors}
				onChange={handleChange}
			/>
			<Input
				name="phoneNumber"
				type="tel"
				label="Phone number"
				placeholder="415-555-4823"
				value={fields.phoneNumber.value}
				errors={fields.phoneNumber.errors}
				onChange={handleChange}
			/>
			<FormButton loading={loading}>
				Enable two-factor authentication
			</FormButton>
		</Form>
	);
}
