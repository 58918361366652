import { Attribute } from 'typings/models';
import { CellRecord } from 'typings/serverTypes';

export function formatStringRecordIdFromTableIdAndPrimaryKeyValues({
	tableId,
	primaryKeyAttributes,
	record,
}: {
	primaryKeyAttributes: Attribute[];
	tableId: number;
	record: CellRecord;
}) {
	return `${tableId}/${primaryKeyAttributes
		.map((attribute) => `${attribute.id}/${record[attribute.id]}`)
		.join('/')}` as const;
}
