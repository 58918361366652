import { useMutation, UseMutationOptions } from 'react-query';

import {
	updateDataSourceSettings,
	UpdateDataSourceSettingsParams,
	UpdateDataSourceSettingsResponse,
} from 'api/updateDataSourceSettings';

export const useUpdateApiDataSourceSettings = (
	options?: UseMutationOptions<
		UpdateDataSourceSettingsResponse,
		string,
		UpdateDataSourceSettingsParams,
		unknown
	>
) =>
	useMutation<
		UpdateDataSourceSettingsResponse,
		string,
		UpdateDataSourceSettingsParams,
		unknown
	>(async (variables) => {
		const result = await updateDataSourceSettings(variables);

		if (!result.ok) {
			throw 'Unable to update data source settings';
		}
		return await result.json();
	}, options);
