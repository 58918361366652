// NOTE: The logic in this file is duplicated in server/src/utils/permissions.ts
// If you edit one, make sure to edit the other
// We should find a way to share logic between then

import { Member, View } from 'typings/models';
import { Role } from 'typings/types';

export function canAccessWorkspace(member: Member | null) {
	if (!member) {
		return false;
	}

	return true;
}

export function canManageWorkspace(member: Member | null) {
	if (!member) {
		return false;
	}

	return member.isOwner;
}

export function canManageBilling(member: Member | null) {
	if (!member) {
		return false;
	}

	return member.isOwner;
}

export function canManageDataSources(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return false;
		case Role.READ_ONLY:
			return false;
		default:
			return false;
	}
}

export function canManageMembers(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return false;
		case Role.READ_ONLY:
			return false;
		default:
			return false;
	}
}

export function canInviteMembers(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return true;
		case Role.READ_ONLY:
			return true;
		default:
			return false;
	}
}

export function canInviteMembersAsRole(member: Member | null, role: Role) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return (
				[Role.ADMIN, Role.COLLABORATOR, Role.READ_ONLY] as Role[]
			).includes(role);
		case Role.COLLABORATOR:
			return ([Role.COLLABORATOR, Role.READ_ONLY] as Role[]).includes(role);
		case Role.READ_ONLY:
			return ([Role.READ_ONLY] as Role[]).includes(role);
		default:
			return false;
	}
}

export function canManageViews(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return false;
		case Role.READ_ONLY:
			return false;
		default:
			return false;
	}
}

export function canAccessRawTables(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return false;
		case Role.READ_ONLY:
			return false;
		default:
			return false;
	}
}

export function canAccessViews(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return true;
		case Role.READ_ONLY:
			return true;
		default:
			return false;
	}
}

export function canAccessView(member: Member | null, view: View) {
	if (!member) {
		return false;
	}

	if (view.isPublished) {
		switch (member.role) {
			case Role.ADMIN:
				return true;
			case Role.COLLABORATOR:
				return true;
			case Role.READ_ONLY:
				return true;
			default:
				return false;
		}
	} else {
		switch (member.role) {
			case Role.ADMIN:
				return true;
			case Role.COLLABORATOR:
				return false;
			case Role.READ_ONLY:
				return false;
			default:
				return false;
		}
	}
}

export function canWriteViewData(member: Member | null) {
	if (!member) {
		return false;
	}

	switch (member.role) {
		case Role.ADMIN:
			return true;
		case Role.COLLABORATOR:
			return true;
		case Role.READ_ONLY:
			return false;
		default:
			return false;
	}
}
