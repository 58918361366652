import styled from 'styled-components';

import { neutral } from 'utils/colors';

const ButtonSecondaryComponent = styled.button`
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
	color: ${neutral[1]};

	&:hover,
	&:active {
		color: ${neutral[3]};
	}
`;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function ButtonSecondary(props) {
	return (
		<ButtonSecondaryComponent {...props}>
			{props.children}
		</ButtonSecondaryComponent>
	);
}

export default ButtonSecondary;
