import { ValtioState } from 'valtioState';
import { DefinedDataSource } from 'valtioState/dataSources/index';

export function getSortedWorkspaceDataSources(
	dataSourcesById: ValtioState['entities']['dataSources']['byId'],
	workspaceId: number
) {
	return Object.values(dataSourcesById)
		.filter(
			(dataSource): dataSource is DefinedDataSource => dataSource !== undefined
		)
		.filter((dataSource) => dataSource.workspaceId === workspaceId)
		.sort(
			(a, b) =>
				// Explicitly check for false to group healthy and loading data sources
				((a.healthy === false) === (b.healthy === false)
					? 0
					: a.healthy === false
					? 1
					: -1) ||
				(a.connected === b.connected ? 0 : a.connected ? -1 : 1) ||
				a.name.localeCompare(b.name)
		);
}
