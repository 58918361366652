import styled from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles, { media } from 'utils/styles';

const Card = styled.div<{ noPadding?: boolean }>`
	background: ${background[1]};
	border-radius: ${styles.global.borderRadius};
	box-shadow: ${styles.shadows[700]};
	color: ${neutral[1]};

	${media.tabletUp`
		padding: ${(
			props // @ts-expect-error FIXME:
		) => (props.noPadding ? '0' : '2rem')};
	`};

	${media.mobile`
		padding: ${(
			props // @ts-expect-error FIXME:
		) => (props.noPadding ? '0' : '1rem')};
	`};
`;

export default Card;
