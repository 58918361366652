import { createAsyncThunk } from '@reduxjs/toolkit';

import { setPrimaryAttribute as setPrimaryAttributeApi } from 'api/setPrimaryAttribute';
import { PrimaryAttribute } from 'typings/models';

export const setPrimaryAttribute = createAsyncThunk<
	PrimaryAttribute,
	{
		dataSourceId: number;
		schemaName: string;
		tableName: string;
		columnName: string;
	},
	{
		rejectValue: string;
	}
>('setPrimaryAttribute', async (params, { rejectWithValue }) => {
	const response = await setPrimaryAttributeApi(params);
	if (response.ok) {
		const primaryAttribute: PrimaryAttribute = await response.json();
		return primaryAttribute;
	} else {
		const error: string = await response.json();
		return rejectWithValue(error);
	}
});
