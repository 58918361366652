import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';

export function useWorkspace() {
	const workspaceId = useSelector((state) => state.workspaceId);

	const { data: workspace, isLoading: workspaceLoading } = useApiWorkspace(
		workspaceId,
		{
			enabled: workspaceId !== null,
		}
	);

	return { workspace, workspaceLoading };
}
