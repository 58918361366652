import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as ChevronRightIcon } from 'images/icons/chevronRight.svg';
import { primary, neutral, onPrimary } from 'utils/colors';
import styles, { media } from 'utils/styles';

interface SidebarItemRootProps {
	exact?: boolean;
	to?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	as?: any;
	activeClassName?: string;
	onClick?: () => void;
	indentation?: number;
	blue?: boolean;
	active?: boolean;
	disabled?: boolean;
	clickable?: boolean;
	hideOnMobile?: boolean;
}

export const SidebarItemRoot = styled.button.withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) =>
		['exact'].includes(prop) ||
		(!['indentation', 'blue', 'active'].includes(prop) &&
			defaultValidatorFn(prop)),
})<SidebarItemRootProps>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 1.875rem;
	height: 2rem;
	padding: 0 0.5rem;
	padding-left: calc(
		0.5rem + ${({ indentation }) => indentation || 0} * 1.5rem
	);
	border: none;
	border-radius: ${styles.global.borderRadius};
	background: ${({ active }) => (active ? primary[1] : 'none')};
	color: ${({ blue, active }) =>
		blue ? primary.text : active ? onPrimary : neutral[1]};
	text-align: left;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	${({ hideOnMobile }) =>
		media.tabletDown`
		display: ${hideOnMobile ? 'none' : 'flex'};
	`}

	${(props) =>
		(props.to || props.onClick || props.clickable) &&
		css`
			cursor: ${props.disabled ? 'default' : 'pointer'};

			&:hover {
				background: ${props.active
					? primary[1]
					: props.disabled
					? 'none'
					: neutral[5]};
				color: ${props.blue
					? primary.text
					: props.active
					? onPrimary
					: neutral[1]};
			}
		`}

	&.active {
		background: ${primary[1]};
		color: ${onPrimary};

		svg * {
			fill: ${onPrimary};
		}
	}
`;

export const Icon = styled.span`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${neutral[1]};
	}
`;

export const IconLeft = styled(Icon)<{
	active?: boolean;
	differentOnHover?: boolean;
	showOnHover?: boolean;
}>`
	display: ${({ differentOnHover, showOnHover }) =>
		differentOnHover && showOnHover ? 'none' : 'block'};
	margin-right: 0.5rem;

	${({ active }) =>
		active &&
		css`
			& > svg,
			& > svg * {
				fill: ${onPrimary};
			}
		`}

	${SidebarItemRoot}:hover && {
		display: ${({ differentOnHover, showOnHover }) =>
			differentOnHover && !showOnHover ? 'none' : 'block'};
	}
`;

export const IconRight = styled(Icon)<{
	active?: boolean;
	showOnActive?: boolean;
}>`
	margin-left: 0.5rem;

	${({ active }) =>
		active &&
		css`
			& > svg,
			& > svg * {
				fill: ${onPrimary};
			}
		`}

	${({ showOnActive }) =>
		showOnActive &&
		css`
			& {
				visibility: hidden;
			}
			.active & {
				visibility: visible;
			}
		`}
`;

const DashedLine = styled.div`
	margin-right: 1rem;
	margin-left: 7px;
	border-left: 1px dotted;
	border-color: currentcolor;
	height: 50%;
	transform: translateY(-50%);
`;

export const SidebarItemTitle = styled.div`
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export interface SidebarItemProps {
	className?: string;
	activeClassName?: string;
	dashedLine?: boolean;
	iconLeft?: React.ReactNode;
	iconLeftHover?: React.ReactNode;
	title?: React.ReactNode;
	iconRightPrimary?: React.ReactNode;
	iconRightSecondary?: React.ReactNode;
	buttonRight?: React.ReactNode;
	exact?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	to?: any;
	onClick?: () => void;
	indentation?: number;
	blue?: boolean;
	active?: boolean;
	disabled?: boolean;
	clickable?: boolean;
	hideOnMobile?: boolean;
	showRightChevron?: boolean;
}

export function SidebarItem({
	className,
	iconLeft,
	iconLeftHover,
	title,
	iconRightPrimary,
	iconRightSecondary,
	buttonRight,
	exact,
	to,
	onClick,
	indentation,
	blue,
	active,
	disabled,
	clickable,
	dashedLine = false,
	hideOnMobile = false,
	showRightChevron = false,
}: SidebarItemProps) {
	return (
		<SidebarItemRoot
			as={to && !disabled ? NavLink : undefined}
			exact={to ? exact : undefined}
			className={className}
			activeClassName={to ? 'active' : undefined}
			to={disabled ? undefined : to}
			onClick={disabled ? undefined : onClick}
			indentation={indentation}
			blue={blue}
			active={active}
			disabled={disabled}
			clickable={clickable}
			hideOnMobile={hideOnMobile}
		>
			{dashedLine ? (
				<DashedLine />
			) : (
				<IconLeft
					active={active}
					differentOnHover={!!iconLeftHover}
					showOnHover={!iconLeftHover}
				>
					{iconLeft}
				</IconLeft>
			)}
			{iconLeftHover && (
				<IconLeft
					active={active}
					differentOnHover={!!iconLeftHover}
					showOnHover={!!iconLeftHover}
				>
					{iconLeftHover}
				</IconLeft>
			)}
			<SidebarItemTitle>{title}</SidebarItemTitle>
			{iconRightSecondary && (
				<IconRight active={active}>{iconRightSecondary}</IconRight>
			)}
			{iconRightPrimary && (
				<IconRight active={active}>{iconRightPrimary}</IconRight>
			)}
			{showRightChevron && (
				<IconRight active={active} showOnActive>
					<ChevronRightIcon />
				</IconRight>
			)}
			{buttonRight}
		</SidebarItemRoot>
	);
}
