import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { contentShow, Header, overlayShow } from 'components/Modal';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { background, backgroundOverlay, neutral } from 'utils/colors';
import { MODAL_Z_INDEX } from 'utils/constants';
import styles from 'utils/styles';

const Overlay = styled(RadixAlertDialog.Overlay)`
	background-color: ${backgroundOverlay};
	position: fixed;
	inset: 0;
	z-index: ${MODAL_Z_INDEX};

	@media (prefers-reduced-motion: no-preference) {
		animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
	}
`;

const StyledContent = styled(RadixAlertDialog.Content)`
	background-color: ${background[1]};
	border: 1px solid ${neutral[1]};
	color: ${neutral[1]};
	border-radius: ${styles.global.borderRadius};
	box-shadow: ${styles.shadows['900']};
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90vw;
	max-width: 30rem;
	max-height: 85vh;
	z-index: ${MODAL_Z_INDEX + 1};

	@media (prefers-reduced-motion: no-preference) {
		animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
		will-change: transform;
	}

	&:focus {
		outline: none;
	}
`;

const Title = styled(RadixAlertDialog.Title)`
	color: ${neutral[1]};
	font-size: 1rem;
	font-weight: 500;
	cursor: default;
`;

const StyledDescription = styled(RadixAlertDialog.Description)`
	padding: 1.25rem 2rem;
	line-height: 1.5;
	min-height: 6rem;
`;

const ButtonContainer = styled.div`
	padding: 1.25rem 2rem;
	display: flex;
	border-top: 1px solid ${neutral[4]};
`;

const StyledButton = styled(Button)`
	width: 50%;
	justify-content: center;
	margin-right: 1rem;

	&:last-child {
		margin-right: 0;
	}
`;

export type AlertDialogProps = {
	open: boolean;
	onOpenChange(open: boolean): void;
	title: string;
	description: React.ReactNode;
	cancelText?: string;
	actionText?: string;
	actionType?: 'danger' | 'primary';
	onConfirm: () => void;
};

export const AlertDialog = ({
	open,
	onOpenChange,
	title,
	description,
	cancelText = 'Cancel',
	actionText = 'Confirm',
	actionType = 'primary',
	onConfirm,
}: AlertDialogProps) => {
	return (
		<RadixAlertDialog.Root open={open} onOpenChange={onOpenChange}>
			<Overlay />
			<StyledContent>
				<Header>
					<Title>{title}</Title>
					<RadixAlertDialog.Cancel asChild>
						<Button size="small" icon={<CloseIcon />} />
					</RadixAlertDialog.Cancel>
				</Header>
				<StyledDescription>{description}</StyledDescription>
				<ButtonContainer>
					<RadixAlertDialog.Cancel asChild>
						<StyledButton outlined>{cancelText}</StyledButton>
					</RadixAlertDialog.Cancel>
					<RadixAlertDialog.Action asChild>
						<StyledButton
							danger={actionType === 'danger'}
							primary={actionType === 'primary'}
							outlined
							onClick={onConfirm}
						>
							{actionText}
						</StyledButton>
					</RadixAlertDialog.Action>
				</ButtonContainer>
			</StyledContent>
		</RadixAlertDialog.Root>
	);
};
