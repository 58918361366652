import { useMemo } from 'react';
import styled from 'styled-components';

import { SidebarItemRoot } from 'components/Sidebar/SidebarItem';
import { ReactComponent as ArrayIcon } from 'images/icons/array.svg';
import { ReactComponent as BooleanIcon } from 'images/icons/boolean.svg';
import { ReactComponent as ForeignKeyIcon } from 'images/icons/dataRelationship.svg';
import { ReactComponent as DateIcon } from 'images/icons/date.svg';
import { ReactComponent as DotIcon } from 'images/icons/dot.svg';
import { ReactComponent as DropdownIcon } from 'images/icons/dropdown.svg';
import { ReactComponent as JsonIcon } from 'images/icons/json.svg';
import { ReactComponent as KeyIcon } from 'images/icons/key.svg';
import { ReactComponent as NumericIcon } from 'images/icons/numeric.svg';
import { ReactComponent as TextIcon } from 'images/icons/text.svg';
import {
	ApiAttribute,
	ApiEnum,
	ApiForeignKey,
	ApiRecordAttribute,
	ApiWorkspaceDetails,
} from 'typings/serverTypes';
import { background, neutral, onPrimary } from 'utils/colors';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';

import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
} from './Table/tableUtils';

export const StyledDataTypeIcon = styled.div<{
	isPrimaryAttribute?: boolean;
}>`
	margin-right: 0.375rem;
	width: 15px;
	height: 15px;
	background: ${({ isPrimaryAttribute }) =>
		isPrimaryAttribute ? neutral[1] : undefined};
	border-radius: 2px;
	line-height: 1;

	// Need to fight with the '.active svg *' styles applied on StyledSidebarItem
	&& svg * {
		fill: ${({ isPrimaryAttribute }) =>
			isPrimaryAttribute ? background[1] : neutral[1]};
	}

	${SidebarItemRoot}.active && svg * {
		fill: ${({ isPrimaryAttribute }) =>
			isPrimaryAttribute ? background[1] : onPrimary};
	}
`;

interface DataTypeIconProps {
	workspace: ApiWorkspaceDetails | undefined;
	attribute: ApiRecordAttribute | ApiAttribute;
	foreignKeys: ApiForeignKey[];
	enums: ApiEnum[];
	sqlDatabaseId: number;
}

export function DataTypeIcon({
	workspace,
	sqlDatabaseId,
	attribute,
	foreignKeys,
	enums,
}: DataTypeIconProps) {
	let name: string | undefined,
		isPrimaryKey: boolean | undefined,
		type: string | undefined;
	if ('name' in attribute) {
		const { name: attributeName, isPrimaryKeyInDb, typeInDb } = attribute;
		name = attributeName;
		isPrimaryKey = isPrimaryKeyInDb;
		type = typeInDb;
	} else {
		const { attributeName, isPrimaryKeyInDb, typeInDb } = attribute;
		name = attributeName;
		isPrimaryKey = isPrimaryKeyInDb;
		type = typeInDb;
	}
	const foreignKey = useMemo(
		() => (foreignKeys ? getForeignKey(name ?? '', foreignKeys) : undefined),
		[foreignKeys, name]
	);
	const isForeignKey = !!foreignKey;

	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId,
		schemaName: attribute.schemaName,
		tableName: attribute.tableName,
	});

	const isPrimaryAttribute = primaryAttribute
		? primaryAttribute.schemaName === attribute.schemaName &&
		  primaryAttribute.tableName === attribute.tableName &&
		  primaryAttribute.columnName === name
		: isPrimaryKey;

	const renderIcon = () => {
		if (isPrimaryKey) {
			return <KeyIcon />;
		}

		if (isForeignKey) {
			return <ForeignKeyIcon />;
		}

		const columnEnumValues =
			name &&
			enums &&
			enums.filter((enumValue) => enumValue.columnName === name);

		if (columnEnumValues && columnEnumValues.length) {
			return <DropdownIcon />;
		}

		if (!type) {
			return <DotIcon />;
		}
		const attributeType = getAttributeTypeFromDbColumnType({
			dbColumnType: type,
		});

		switch (attributeType) {
			case 'ARRAY': {
				return <ArrayIcon />;
			}
			case 'BOOLEAN': {
				return <BooleanIcon />;
			}
			case 'DATETIME':
			case 'DATE':
			case 'TIME': {
				return <DateIcon />;
			}
			case 'JSON': {
				return <JsonIcon />;
			}
			case 'NUMBER': {
				return <NumericIcon />;
			}
			case 'TEXT': {
				return <TextIcon />;
			}
		}
	};

	return (
		<StyledDataTypeIcon isPrimaryAttribute={isPrimaryAttribute}>
			{renderIcon()}
		</StyledDataTypeIcon>
	);
}
