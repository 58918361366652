import { getDefaultHeaders } from 'api/utils';
import { Filter, Sort } from 'typings/serverTypes';

export type FetchViewRecordsParams = {
	viewId: number | null;
	filters: Filter[];
	sorts: Sort[];
	pageNumber: number | null;
	searchQuery: string | null;
	prettyAttributeNames?: boolean;
	includeAllConnectedAttributes?: boolean;
};

export function fetchViewRecords({
	viewId,
	filters,
	sorts,
	pageNumber,
	searchQuery,
	prettyAttributeNames = false,
	includeAllConnectedAttributes = false,
}: FetchViewRecordsParams) {
	return fetch(`/api/views/${viewId}/records/get`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			filters,
			sorts,
			pageNumber,
			searchQuery,
			prettyAttributeNames,
			includeAllConnectedAttributes,
		}),
	});
}
