import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from 'components/Button';
import { ReactComponent as MenuIcon } from 'images/icons/menu.svg';
import { sidebarToggled } from 'reduxState/slices/sidebarExpanded';
import { background, neutral } from 'utils/colors';
import styles, { media } from 'utils/styles';

const StyledActionBar = styled.div<{ topLevel?: boolean }>`
	width: 100%;
	height: ${styles.sizes.actionBarHeight};
	padding: 0 0.5rem;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	z-index: 10;
	background: ${background[1]};
	color: ${neutral[1]};
	border-bottom: 1px solid ${neutral[4]};
`;

interface ActionBarProps {
	topLevel?: boolean;
	children?: React.ReactNode;
	ignoreMobileMenuButton?: boolean;
}

export function ActionBar({
	topLevel,
	children,
	ignoreMobileMenuButton = false,
}: ActionBarProps) {
	const dispatch = useDispatch();

	const handleToggleSidebar = () => {
		dispatch(sidebarToggled());
	};

	return (
		<StyledActionBar topLevel={topLevel}>
			{!ignoreMobileMenuButton && (
				<SidebarMenuButton icon={<MenuIcon />} onClick={handleToggleSidebar} />
			)}
			{children}
		</StyledActionBar>
	);
}

const ActionBarTitleComponent = styled.div`
	display: flex;
	align-items: center;

	${media.desktop`
		padding: 0 0.5rem;
	`}
`;

const SidebarMenuButton = styled(Button)`
	${media.desktop`
		display: none;
	`}

	${media.tabletDown`
		&& {
			margin-right: 0.25rem;
		}
	`}
`;

const Icon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 0.5rem;
	display: flex;
	align-items: center;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${neutral[1]};
	}
`;

const Title = styled.h1<{ limitWidth?: boolean }>`
	font-size: 1rem;
	font-weight: 500;
	color: ${neutral[1]};
	line-height: 1.25rem;
	cursor: default;

	${({ limitWidth }) =>
		limitWidth &&
		css`
			max-width: 10rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`}
`;

interface ActionBarTitleProps {
	icon?: React.ReactNode;
	title: string;
	limitWidth?: boolean;
}

export function ActionBarTitle({
	title,
	icon,
	limitWidth,
}: ActionBarTitleProps) {
	return (
		<ActionBarTitleComponent>
			{icon && <Icon>{icon}</Icon>}
			<Title limitWidth={limitWidth}>{title}</Title>
		</ActionBarTitleComponent>
	);
}

export const ActionBarSection = styled.div<{ padded?: boolean }>`
	display: flex;
	align-items: center;

	&:nth-of-type(2) {
		flex-grow: 1;
		justify-content: flex-end;
	}

	${({ padded }) =>
		padded &&
		css`
			${ActionBarItem} + ${ActionBarItem} {
				margin-left: 0.5rem;
			}
		`}
`;

export const ActionBarItem = styled.div<{
	hideOnMobile?: boolean;
}>`
	display: inline-flex;
	align-items: center;
	margin: 0;

	${({ hideOnMobile }) =>
		hideOnMobile &&
		media.mobile`
		display: none;
	`}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: auto;
`;
