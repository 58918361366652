import * as RadixSwitch from '@radix-ui/react-switch';
import styled from 'styled-components';

import { background, neutral, onPrimary, primary } from 'utils/colors';

const SwitchRoot = styled(RadixSwitch.Root)`
	position: relative;
	width: 28px; // 26 + 2 to account for border
	height: 15px; // 13 + 2 to account for border
	border-radius: 13px;
	border: 1px solid ${({ checked }) => (checked ? primary[1] : neutral[1])};
	background: ${({ checked }) => (checked ? primary[1] : background[1])};
	cursor: pointer;
	transition: border 0.25s, background 0.25s;
	opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

const Thumb = styled(RadixSwitch.Thumb)<{ checked: boolean }>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: ${({ checked }) => (checked ? 'calc(100% - 9px - 2px)' : '2px')};
	width: 9px;
	height: 9px;
	border-radius: 9px;
	border: 1px solid ${({ checked }) => (checked ? onPrimary : neutral[1])};
	background: ${({ checked }) => (checked ? onPrimary : neutral[1])};
	transition: left 0.25s, border 0.25s, background 0.25s;
`;

interface SwitchProps {
	checked: boolean;
	/** Not needed if disabled */
	onChange?: () => void;
	className?: string;
	disabled?: boolean;
}

export function Switch({
	checked,
	onChange,
	className,
	disabled = false,
}: SwitchProps) {
	return (
		<SwitchRoot
			checked={checked}
			onCheckedChange={onChange}
			disabled={disabled}
			className={className}
		>
			<Thumb checked={checked} />
		</SwitchRoot>
	);
}
