import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import Card from 'components/Card';
import Section from 'components/Section';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';
import { state } from 'valtioState';
import { removeWorkspaceDataSource } from 'valtioState/dataSources/removeWorkspaceDataSource';

const InfoText = styled.p`
	line-height: 1.5;

	& + & {
		margin-top: 1.5rem;
	}
`;

const Bold = styled.strong`
	font-weight: 600;
`;

interface Props {
	sqlDatabase: ApiWorkspaceDetails['sqlDatabases'][number];
}

function RemoveDataSource({ sqlDatabase }: Props) {
	const history = useHistory();
	const snap = useSnapshot(state);
	const dataSource = snap.entities.dataSources.byId[sqlDatabase.dataSourceId];
	const [loading, setLoading] = useState(false);
	const [dataSourceName, setDataSourceName] = useState('');
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setDataSourceName(value);
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (dataSourceName !== dataSource?.name) {
			return;
		}

		setLoading(true);
		try {
			await removeWorkspaceDataSource(sqlDatabase.dataSourceId);
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			history.push('/data-sources');
		} catch (error) {
			toast.error('Failed to remove data source.');
			setLoading(false);
		}
	};

	return (
		<Section title="Remove database">
			<Card>
				<InfoText>
					This will remove the <Bold>{dataSource?.name}</Bold> data source and
					permanently delete all associated comments, tracked edits, queries,
					and views.
				</InfoText>
				<InfoText>Enter the data source name to confirm.</InfoText>
				<Form onSubmit={handleSubmit}>
					<Input
						name="dataSourceName"
						type="text"
						label="Data source name"
						value={dataSourceName}
						onChange={handleChange}
					/>
					<FormButton
						loading={loading}
						disabled={dataSourceName !== dataSource?.name}
					>
						Remove data source
					</FormButton>
				</Form>
			</Card>
		</Section>
	);
}

export default RemoveDataSource;
