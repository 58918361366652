import { getWorkspaceSettings } from 'api/getWorkspaceSettings';
import { AllSettings } from 'typings/serverTypes';
import { toast } from 'utils/toast/toast';
import { state, ValtioState } from 'valtioState/index';
import { setAllByIds, setAllIds } from 'valtioState/utils';

export const getAllSettings = async (
	workspaceId: number
): Promise<
	Pick<
		ValtioState['entities'],
		'tables' | 'schemas' | 'dataSources' | 'attributes'
	>
> => {
	state.entities.dataSources.loading = true;
	state.entities.schemas.loading = true;
	state.entities.tables.loading = true;
	state.entities.attributes.loading = true;
	try {
		const res = await getWorkspaceSettings({ workspaceId });
		const data = (await res.json()) as AllSettings;

		state.attributeNamesToIdMap = {
			...state.attributeNamesToIdMap,
			...data.attributeNamesToIdMap,
		};
		state.tableNamesToIdMap = {
			...state.tableNamesToIdMap,
			...data.tableNamesToIdMap,
		};

		setAllByIds(data.dataSources, state.entities.dataSources.byId);
		setAllByIds(data.schemas, state.entities.schemas.byId);
		setAllByIds(data.tables, state.entities.tables.byId);
		setAllByIds(data.attributes, state.entities.attributes.byId);

		setAllIds(data.dataSources, state.entities.dataSources.ids);
		setAllIds(data.schemas, state.entities.schemas.ids);
		setAllIds(data.tables, state.entities.tables.ids);
		setAllIds(data.attributes, state.entities.attributes.ids);

		state.hasLoadedWorkspaceSettings = true;
	} catch (e) {
		toast.error('Problem loading workspace data');
	} finally {
		state.entities.dataSources.loading = false;
		state.entities.schemas.loading = false;
		state.entities.tables.loading = false;
		state.entities.attributes.loading = false;
	}

	return {
		attributes: state.entities.attributes,
		tables: state.entities.tables,
		dataSources: state.entities.dataSources,
		schemas: state.entities.schemas,
	};
};
