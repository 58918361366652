import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';

const StyledPopover = styled.div`
	position: relative;

	&:focus {
		outline: none;
	}
`;

const Toggle = styled.div`
	height: 100%;
`;

const PopoverContent = styled.div<{
	padded?: boolean;
	centerAligned?: boolean;
	rightAligned?: boolean;
}>`
	position: absolute;
	min-width: 14rem;
	max-height: 70vh;
	transform: translateY(0.25rem);
	overflow-y: auto;
	padding: ${({ padded }) => (padded ? '1rem' : '0')};
	box-shadow: ${styles.shadows[700]};
	background: ${background[1]};
	border: 1px solid ${neutral[1]};
	border-radius: ${styles.global.borderRadius};
	outline: none;
	z-index: 2;

	${({ rightAligned }) =>
		rightAligned &&
		css`
			right: 0;
		`};

	${({ centerAligned }) =>
		centerAligned &&
		css`
			right: 50%;
			transform: translate(50%, 0.25rem);
		`};
`;

type Props = {
	toggle?: React.ReactNode;
	rightClick?: boolean;
	onBlur?: () => Promise<void>;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	padded?: boolean;
	centerAligned?: boolean;
	rightAligned?: boolean;
	children: React.ReactNode;
	as?: string;
	forwardRef?: React.Ref<HTMLDivElement>;
} & (
	| {
			setOpen?: (open: () => void) => void;
			setClose?: (close: () => void) => void;
			isOpen?: never;
			setIsOpen?: never;
	  }
	//  The preferred way to use dropdowns is to pass `isOpen` and `setIsOpen` to handle the dropdown state. The
	// legacy way of handling state uses the `setOpen` and `setClose` which is a bit more difficult to understand
	// and also complicates things when needing to perform actions when opening the dropdown (such as is the case
	// in the PrimaryAttributeDropdown where we need to fetch results when opening the dropdown).
	| {
			setOpen?: never;
			setClose?: never;
			isOpen: boolean;
			setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	  }
);

export function DepracatedPopover({
	toggle,
	rightClick,
	onBlur,
	onKeyDown,
	setOpen,
	padded,
	centerAligned,
	rightAligned,
	children,
	forwardRef,
	setClose,
	isOpen: isOpenProp,
	setIsOpen: setIsOpenProp,
}: Props) {
	const history = useHistory();
	const [isOpenState, setIsOpenState] = useState(false);

	const open = () => {
		if (setIsOpenProp) {
			setIsOpenProp(true);
		} else {
			setIsOpenState(true);
		}
	};

	const close = () => {
		if (setIsOpenProp) {
			setIsOpenProp(false);
		} else {
			setIsOpenState(false);
		}

		if (onBlur) {
			onBlur();
		}
	};

	useEffect(() => {
		const unlisten = history.listen(() => {
			close();
		});

		return () => {
			unlisten();
		};
	});

	useEffect(
		() => {
			if (setOpen) {
				setOpen(open);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setOpen, isOpenState]
	);

	useEffect(
		() => {
			if (setClose) {
				setClose(close);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setClose, isOpenState]
	);

	const toggleDropdown = () => {
		if (isOpenState || isOpenProp) {
			close();
		} else {
			open();
		}
	};

	const handleBlur = (event: React.FocusEvent) => {
		if (!event.currentTarget.contains(event?.relatedTarget as Node)) {
			close();
		}
	};

	const handleClick = (event: React.MouseEvent) => {
		if (rightClick) {
			if (isOpenState || isOpenProp) {
				close();
			}
		} else {
			event.preventDefault();
			toggleDropdown();
		}
	};

	const handleRightClick = (event: React.MouseEvent) => {
		if (rightClick) {
			event.preventDefault();
			toggleDropdown();
		}
	};

	return (
		<StyledPopover onBlur={handleBlur} onKeyDown={onKeyDown} tabIndex={0}>
			<>
				{toggle && (
					<Toggle onClick={handleClick} onContextMenu={handleRightClick}>
						{toggle}
					</Toggle>
				)}

				{(isOpenState || isOpenProp) && (
					<PopoverContent
						padded={padded}
						centerAligned={centerAligned}
						rightAligned={rightAligned}
						ref={forwardRef}
					>
						{children}
					</PopoverContent>
				)}
			</>
		</StyledPopover>
	);
}
