import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { logout } from 'reduxState/actions';

interface TableItem {
	type: 'table';
	tableId: number;
	dataSourceId: number;
	filterColumnName?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterColumnValue?: any;
}

// Views can only appear on the bottom of the stack, so never have filters
interface ViewItem {
	type: 'view';
	viewId: number;
	name: string;
}

export type TableStackState = (TableItem | ViewItem)[];

const initialState: TableStackState = [];

const tableStackSlice = createSlice({
	name: 'tableStack',
	initialState,
	reducers: {
		tableAdded(state, action: PayloadAction<TableItem | ViewItem>) {
			state.push(action.payload);
		},
		tableRemoved(state) {
			state.pop();
		},
		tableStackCleared() {
			return [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { tableAdded, tableRemoved, tableStackCleared } =
	tableStackSlice.actions;

export default tableStackSlice.reducer;
