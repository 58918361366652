import styled from 'styled-components';

import { neutral, primary } from 'utils/colors';

interface Props {
	active?: boolean | null;
}

export const Icon = styled.span<Props>`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;

	& > svg {
		width: 15px;
		height: 15px;

		* {
			fill: ${({ active }) => (active ? primary.text : neutral[1])};
		}
	}

	& > img {
		width: 15px;
		height: 15px;
		border-radius: 2px;
		object-fit: scale-down;
	}
`;
