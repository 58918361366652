import React from 'react';

import { DateInput } from 'components/DateInput';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';

type DateTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	| 'rawValue'
	| 'attributeType'
	| 'handleChangeDate'
	| 'handleBlur'
	| 'canEdit'
	| 'isAddingNewRecord'
	| 'column'
> & {
	handleFocus?: EditableTableCellContentsProps['handleFocus'];
};

export const DateTableCellContents = ({
	rawValue,
	attributeType,
	handleChangeDate,
	handleFocus,
	handleBlur,
	canEdit,
	isAddingNewRecord,
	column,
}: DateTableCellContentsProps): React.ReactElement | null => {
	if (
		(rawValue instanceof Date || rawValue === null) &&
		attributeType !== undefined
	) {
		return (
			<DateInput
				value={rawValue}
				attributeType={attributeType}
				onChange={handleChangeDate}
				isInTableCell
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={!canEdit}
				isAddingNewRecord={isAddingNewRecord}
				column={column}
			/>
		);
	} else {
		return null;
	}
};
