import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { DataViewer } from 'components/DataViewer';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { TableData__Table } from 'typings/types';
import { extractTableDataForDataSourceTable } from 'utils/extractTableData';
import { canAccessRawTables } from 'utils/permissions';
import { useCollaborator } from 'utils/useCollaborator';
import { useTableRecords } from 'utils/useTableRecords';
import { state } from 'valtioState';

interface TableProps {
	workspace: ApiWorkspaceDetails;
	data: TableData__Table;
	tableId: number;
	isInViewBuilder: boolean;
}

function Table({ workspace, data, tableId, isInViewBuilder }: TableProps) {
	const collaborator = useCollaborator();
	const snap = useSnapshot(state);

	const extractedTableData = useMemo(
		// @ts-expect-error Readonly issues
		() => extractTableDataForDataSourceTable(tableId, snap),
		[tableId, snap]
	);

	const {
		reload,
		records,
		pageNumber,
		searchQuery,
		recordsLoading,
		recordsError,
		numRecords,
		tableStack,
		windowRef,
		setPageNumber,
		setSearchQuery,
		collaborators,
	} = useTableRecords({
		workspace,
		tableId,
	});

	return (
		<DataViewer
			workspace={workspace}
			data={data}
			extractedTableData={extractedTableData}
			reload={reload}
			records={records}
			pageNumber={pageNumber}
			searchQuery={searchQuery}
			recordsLoading={recordsLoading}
			recordsError={recordsError}
			numRecords={numRecords}
			tableStack={tableStack}
			windowRef={windowRef}
			setPageNumber={setPageNumber}
			setSearchQuery={setSearchQuery}
			collaborators={collaborators}
			canWriteData={isInViewBuilder ? false : canAccessRawTables(collaborator)}
			isInViewBuilder={isInViewBuilder}
		/>
	);
}

interface TableContentProps {
	tableId: number;
	isInViewBuilder?: boolean;
}

export default function TableContent({
	tableId,
	isInViewBuilder = false,
}: TableContentProps) {
	const workspaceId = useSelector((state) => state.workspaceId);

	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const data: TableData__Table = {
		type: 'table',
		id: tableId,
	};

	if (!workspace) {
		return null;
	}

	return (
		<Table
			key={tableId}
			workspace={workspace}
			data={data}
			tableId={tableId}
			isInViewBuilder={isInViewBuilder}
		/>
	);
}
