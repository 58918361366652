import { useMutation, UseMutationOptions } from 'react-query';

import {
	createAdvancedView,
	CreateAdvancedViewParams,
} from 'api/createAdvancedView';
import { ApiView } from 'typings/serverTypes';
import { toast } from 'utils/toast/toast';

export const useCreateAdvancedApiView = (
	options?: UseMutationOptions<
		ApiView,
		string,
		CreateAdvancedViewParams,
		unknown
	>
) =>
	useMutation<ApiView, string, CreateAdvancedViewParams, unknown>(
		async (variables) => {
			const result = await createAdvancedView(variables);

			if (!result.ok) {
				const { error } = await result.json();
				throw error;
			}
			return result.json();
		},
		{
			onError: () => {
				toast.error('Failed to create view');
			},
			...options,
		}
	);
