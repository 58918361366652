import { getDefaultHeaders } from 'api/utils';

export type CreateAdvancedViewParams = {
	databaseId: number;
	query?: string;
	name?: string;
};

export function createAdvancedView({
	databaseId,
	query,
	name,
}: CreateAdvancedViewParams) {
	return fetch(`/api/views/advanced`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			databaseId,
			query,
			name,
		}),
	});
}
