import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import styles from 'utils/styles';

const spin = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Spinner = styled.div`
	width: 5rem;
	height: 5rem;
	margin: 2rem auto;
	border: 0.625rem solid ${rgba(styles.colours.neutral[500], 0.2)};
	border-top-color: ${styles.colours.neutral[500]};
	border-radius: 50%;
	animation: ${spin} 1s infinite linear;
`;

export default Spinner;
