import { DeepReadonly } from 'utils/constants';
import { ValtioState } from 'valtioState';

export function filterSchemasByDataSource(
	dataSourceId: number,
	sqlDatabasesById: DeepReadonly<
		ValtioState['entities']['sqlDatabases']['byId']
	>,
	schemasById: ValtioState['entities']['schemas']['byId']
) {
	return Object.values(schemasById)
		.filter((schema) => schema?.foundInDb)
		.filter((schema) => {
			if (schema?.sqlDatabaseId !== undefined) {
				const sqlDatabase = sqlDatabasesById[schema.sqlDatabaseId];
				return sqlDatabase?.dataSourceId === dataSourceId;
			}
		});
}

export function filterTablesByMultipleSchemas(
	schemas: ValtioState['entities']['schemas']['byId'][number][],
	tablesById: ValtioState['entities']['tables']['byId']
) {
	return (
		Object.values(tablesById)
			.filter((table) => table?.foundInDb)
			.filter((table) =>
				schemas.some((schema) => schema?.id == table?.schemaId)
			)
			.sort(
				(a, b) => a?.displayName.localeCompare(b?.displayName ?? '') ?? 0
			) ?? []
	);
}

export function filterTablesBySchema(
	schemaId: number,
	tables: ValtioState['entities']['tables']['byId'][number][]
) {
	return tables.filter((table) => table?.schemaId === schemaId);
}

export function schemaHasSomeConnectedTables(
	schemaId: number,
	tables: ValtioState['entities']['tables']['byId'][number][]
) {
	return filterTablesBySchema(schemaId, tables).some(
		(table) => table?.connected
	);
}
