import styled from 'styled-components';

import { FetchWorkspaceInvitationsResponse } from 'api/getMemberInvitations';
import { Modal } from 'components/Modal';
import { InviteMember } from 'components/pages/Members/InviteMember';
import { MemberList } from 'components/pages/Members/MemberList';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { canInviteMembers } from 'utils/permissions';
import { media } from 'utils/styles';
import { useCollaborator } from 'utils/useCollaborator';

const Container = styled.div`
	display: flex;
	min-height: 100%;

	${media.mobile`
		flex-direction: column;
	`};
`;

const MemberListContainer = styled.div`
	flex-grow: 1;
`;

const InviteMemberContainer = styled.div`
	${media.tabletUp`
		width: 50%;
		border-left: 1px solid ${neutral[4]};
	`};
`;

interface Props {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	workspace: ApiWorkspaceDetails;
	workspaceInvitations: FetchWorkspaceInvitationsResponse;
}

export function MembersModal({
	open,
	onOpenChange,
	workspace,
	workspaceInvitations,
}: Props) {
	const member = useCollaborator();

	return (
		<Modal open={open} onOpenChange={onOpenChange} title="Members">
			<Container>
				<MemberListContainer>
					<MemberList
						workspaceInvitations={workspaceInvitations}
						members={workspace.collaborators}
					/>
				</MemberListContainer>
				{canInviteMembers(member) && (
					<InviteMemberContainer>
						<InviteMember
							workspace={workspace}
							workspaceInvitations={workspaceInvitations}
						/>
					</InviteMemberContainer>
				)}
			</Container>
		</Modal>
	);
}
