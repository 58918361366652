import React, { createContext, useContext, useEffect, useState } from 'react';
import { ThemeProvider as StyleThemeProvider } from 'styled-components';

import { LOCAL_STORAGE_KEY } from 'utils/constants';

export type ThemeMode = 'light' | 'dark';

interface ThemeContextProps {
	themeMode: ThemeMode;
	updateThemeMode: (newThemeMode: ThemeMode | 'auto') => void;
}

const ThemeContext = createContext<ThemeContextProps>({
	themeMode: 'light',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	updateThemeMode: () => {},
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
	children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
	const [themeMode, setThemeMode] = useState<ThemeMode>('light');

	const getThemeMode = () => {
		const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME_MODE);
		if (savedTheme === 'light' || savedTheme === 'dark') {
			return savedTheme;
		} else {
			const prefersDark =
				window.matchMedia &&
				window.matchMedia('(prefers-color-scheme: dark)').matches;

			return prefersDark ? 'dark' : 'light';
		}
	};

	const updateThemeMode = (newThemeMode: ThemeMode | 'auto') => {
		localStorage.setItem(LOCAL_STORAGE_KEY.THEME_MODE, newThemeMode);
		const mode = getThemeMode();
		setThemeMode(mode);
	};

	useEffect(() => {
		const mode = getThemeMode();
		setThemeMode(mode);
	}, []);

	useEffect(() => {
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'media' implicitly has an 'any' type.
		const handleChange = (media) => {
			const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME_MODE);
			if (savedTheme === 'light' || savedTheme === 'dark') {
				return;
			}

			const mode = media.matches ? 'dark' : 'light';
			setThemeMode(mode);
		};

		const colorSchemeMedia =
			window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
		if (colorSchemeMedia) {
			colorSchemeMedia.addEventListener('change', handleChange);
		}

		return () => {
			if (colorSchemeMedia) {
				colorSchemeMedia.removeEventListener('change', handleChange);
			}
		};
	}, []);

	return (
		<ThemeContext.Provider value={{ themeMode, updateThemeMode }}>
			<StyleThemeProvider
				theme={{
					mode: themeMode,
				}}
			>
				{children}
			</StyleThemeProvider>
		</ThemeContext.Provider>
	);
}
