import { getDefaultHeaders } from 'api/utils';
import { ApiView } from 'typings/serverTypes';

export type FetchViewResponse = ApiView;

export type FetchViewParams = {
	viewId: number | null;
};

export function fetchView({ viewId }: FetchViewParams) {
	return fetch(`/api/views/${viewId}`, {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
