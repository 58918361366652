import deepEqual from 'deep-equal';
import { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Button from 'components/Button';
import ButtonSecondary from 'components/ButtonSecondary';
import { Popover } from 'components/Popover';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import { Input } from 'components/fields/Input';
import Select from 'components/fields/Select';
import { ReactComponent as FilterIcon } from 'images/icons/filter.svg';
import { Filter } from 'typings/serverTypes';
import {
	TableData__Table,
	TableData__View,
	ExtractedTableData,
	ViewType,
} from 'typings/types';
import { neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { state } from 'valtioState';
import { updateTableSettings } from 'valtioState/tables/updateTableSettings';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

const InputsContainer = styled.div`
	display: flex;
	flex-shrink: 0;
	margin-right: auto;
	padding-left: 0.75rem;

	> *:not(:last-child) {
		margin-right: 0.75rem;
	}
`;

const DropdownContainer = styled.ul`
	min-width: 16rem;
	margin-bottom: ${(props) =>
		// @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
		props.children.length ? '1rem' : '0'};
`;

const DropdownRow = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.5rem 0;
	font-size: 0.875rem;
	user-select: none;

	> *:not(:last-child) {
		margin-right: 0.75rem;
	}
`;

const InfoText = styled.span`
	color: ${neutral[1]};
	width: 4rem;
	white-space: nowrap;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ColumnSelect = styled(Select)`
	max-width: 10rem;
`;

interface Props {
	data: TableData__Table | TableData__View<ViewType.BASIC>;
	setPageNumber: (page: number) => void;
	extractedTableData: ExtractedTableData;
}

function FilterDropdown({ data, extractedTableData, setPageNumber }: Props) {
	const snap = useSnapshot(state);
	const {
		attributes,
		joins,
		databaseId,
		filters: savedFilters,
	} = extractedTableData;
	const unobscuredAttributes = attributes.filter(
		(attribute) => !attribute.isObscured
	);

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: 'view' in data ? data.view : null,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);

	const [filters, setFilters] = useState<Filter[]>(savedFilters);
	const [appliedFilters, setAppliedFilters] = useState<Filter[]>(savedFilters);

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const filtersChanged = useMemo(
		() => !deepEqual(filters, appliedFilters),
		[filters, appliedFilters]
	);

	const addFilter = () => {
		const id =
			filters.length === 0
				? 0
				: filters.map((filter) => filter.id).sort((a, b) => b - a)[0] + 1;
		const schemaName = unobscuredAttributes[0].schemaName;
		const tableName = unobscuredAttributes[0].tableName;
		const columnName = unobscuredAttributes[0].attributeName;
		const columnType = unobscuredAttributes[0].typeInDb;
		const attributeType = getAttributeTypeFromDbColumnType({
			dbColumnType: columnType,
		});
		let operator, specifier, content;

		switch (attributeType) {
			case 'NUMBER': {
				operator = '=';
				content = '0';
				break;
			}
			case 'DATETIME':
			case 'DATE': {
				operator = '=';
				specifier = 'exact date';
				content = null;
				break;
			}
			case 'TIME': {
				operator = '=';
				specifier = 'exact time';
				content = null;
				break;
			}
			case 'BOOLEAN': {
				operator = '=';
				specifier = 'is true';
				break;
			}
			case 'ARRAY': {
				operator = '=';
				content = '{}';
				break;
			}
			case 'TEXT':
			case 'JSON':
			default: {
				operator = '=';
				content = '';
				break;
			}
		}

		const filter: Filter = {
			id,
			schemaName,
			tableName,
			columnName,
			attributeType,
			operator,
			specifier,
			content,
		};

		setFilters([...filters, filter]);
	};

	const removeFilter = (filterId: number) => {
		setFilters(filters.filter((filter) => filter.id !== filterId));
		if (dropdownRef.current) {
			dropdownRef.current.focus();
		}
	};

	const updateFilter = (
		filterId: number,
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const targetName = event.target.name as
			| 'column'
			| 'operator'
			| 'specifier'
			| 'content';
		const existingFilter = filters.find((filter) => filter.id === filterId);

		if (!existingFilter) {
			return;
		}

		const updatedFilter = { ...existingFilter };

		if (targetName === 'column') {
			const columnIdentifier = JSON.parse(event.target.value);
			const column = attributes.find(
				(column) =>
					column.schemaName === columnIdentifier.schemaName &&
					column.tableName === columnIdentifier.tableName &&
					column.attributeName === columnIdentifier.columnName
			);
			if (!column) {
				return;
			}
			const columnType = column.typeInDb;
			const attributeType = getAttributeTypeFromDbColumnType({
				dbColumnType: columnType,
			});

			updatedFilter.schemaName = columnIdentifier.schemaName;
			updatedFilter.tableName = columnIdentifier.tableName;
			updatedFilter.columnName = columnIdentifier.columnName;
			updatedFilter.attributeType = attributeType;

			switch (attributeType) {
				case 'NUMBER': {
					updatedFilter.operator = '=';
					updatedFilter.content = '0';
					updatedFilter.specifier = undefined;
					break;
				}
				case 'DATETIME':
				case 'DATE': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'today';
					updatedFilter.content = null;
					break;
				}
				case 'TIME': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'now';
					updatedFilter.content = null;
					break;
				}
				case 'BOOLEAN': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'is true';
					updatedFilter.content = null;
					break;
				}
				case 'ARRAY': {
					updatedFilter.operator = '=';
					updatedFilter.content = '{}';
					updatedFilter.specifier = undefined;
					break;
				}
				case 'TEXT':
				case 'JSON':
				default: {
					updatedFilter.operator = '=';
					updatedFilter.content = '';
					updatedFilter.specifier = undefined;
					break;
				}
			}
		} else {
			if (targetName in updatedFilter) {
				updatedFilter[targetName] = event.target.value;
			}
		}

		setFilters(
			filters.map((filter) => (filter.id === filterId ? updatedFilter : filter))
		);
	};

	const applyChanges = async (event?: FormEvent<HTMLFormElement>) => {
		if (event) {
			event.preventDefault();
		}

		if (!filtersChanged) {
			return;
		}

		setAppliedFilters(filters.map((filter) => ({ ...filter })));
		if (data.type === 'view') {
			updateApiView({
				view: {
					id: data.view.id,
					filters,
				},
			});
		} else {
			updateTableSettings({ tableId: data.id, filters });
		}

		setPageNumber(0);
	};

	const hasOperator = (filter: Filter) => {
		return filter.attributeType !== 'BOOLEAN';
	};

	const hasSpecifier = (filter: Filter) => {
		if (filter.attributeType === 'BOOLEAN') {
			return true;
		}
		if (
			['DATETIME', 'DATE', 'TIME', 'ARRAY', 'JSON'].includes(
				filter.attributeType
			)
		) {
			if (filter.operator !== 'is null' && filter.operator !== 'is not null') {
				return true;
			}
		}

		return false;
	};

	const contentInputType = (filter: Filter): 'text' | 'number' | null => {
		if (
			['is empty', 'is not empty', 'is null', 'is not null'].includes(
				filter.operator
			)
		) {
			return null;
		}

		if (filter.attributeType === 'ARRAY') {
			if (hasSpecifier(filter)) {
				return 'text';
			}
		}

		if (filter.attributeType === 'JSON') {
			if (hasSpecifier(filter)) {
				return 'text';
			}
		}

		if (filter.attributeType === 'BOOLEAN') {
			return null;
		}

		if (filter.attributeType === 'NUMBER') {
			return 'number';
		}

		if (filter.attributeType === 'TEXT') {
			return 'text';
		}

		if (['DATETIME', 'DATE'].includes(filter.attributeType)) {
			if (hasSpecifier(filter)) {
				if (
					filter.specifier === 'number of days ago' ||
					filter.specifier === 'number of days from now'
				) {
					return 'number';
				}

				if (filter.specifier === 'exact date') {
					return 'text';
				}
			}
		}

		if (filter.attributeType === 'TIME') {
			if (hasSpecifier(filter)) {
				if (
					filter.specifier === 'number of minutes ago' ||
					filter.specifier === 'number of minutes from now'
				) {
					return 'number';
				}

				if (filter.specifier === 'exact time') {
					return 'text';
				}
			}
		}

		return null;
	};

	const [open, setOpen] = useState(false);
	return (
		<Popover
			trigger={
				<Button
					icon={<FilterIcon />}
					value={filters.length > 0 ? String(filters.length) : undefined}
					active={filters.length > 0}
					title="Filter"
				/>
			}
			side="bottom"
			align="end"
			onBlur={applyChanges}
			open={open}
			setOpen={setOpen}
		>
			<form onSubmit={applyChanges} id="filterForm">
				<DropdownContainer>
					{filters.map((filter, index) => (
						<DropdownRow key={filter.id}>
							<ButtonSecondary
								type="button"
								onClick={removeFilter.bind(null, filter.id)}
							>
								×
							</ButtonSecondary>

							<InfoText>{index === 0 ? 'Where' : 'and'}</InfoText>

							<InputsContainer>
								<ColumnSelect
									mini
									name="column"
									value={JSON.stringify({
										schemaName: filter.schemaName,
										tableName: filter.tableName,
										columnName: filter.columnName,
									})}
									onChange={updateFilter.bind(null, filter.id)}
									options={unobscuredAttributes.map((column) => {
										const tableDisplayName = getTableDisplayName({
											tablesById: snap.entities.tables.byId,
											tableId: column.tableId,
										});
										const attributeDisplayName = getAttributeDisplayName({
											attributesById: snap.entities.attributes.byId,
											attributeNamesToIdMap: snap.attributeNamesToIdMap,
											attributeName: column.attributeName,
											tableName: column.tableName,
											schemaName: column.schemaName,
											sqlDatabaseId: databaseId,
										});
										return {
											label:
												(joins.length ?? 0) > 0
													? `${tableDisplayName} ${attributeDisplayName}`
													: attributeDisplayName,
											value: JSON.stringify({
												schemaName: column.schemaName,
												tableName: column.tableName,
												columnName: column.attributeName,
											}),
										};
									})}
								/>

								{hasOperator(filter) && (
									<Select
										mini
										name="operator"
										value={filter.operator}
										onChange={updateFilter.bind(null, filter.id)}
										options={
											filter.attributeType === 'NUMBER'
												? [
														{
															label: '=',
															value: '=',
														},
														{
															label: '≠',
															value: '!=',
														},
														{
															label: '<',
															value: '<',
														},
														{
															label: '≤',
															value: '<=',
														},
														{
															label: '>',
															value: '>',
														},
														{
															label: '≥',
															value: '>=',
														},
														{
															label: 'is null',
															value: 'is null',
														},
														{
															label: 'is not null',
															value: 'is not null',
														},
												  ]
												: ['ARRAY', 'JSON'].includes(filter.attributeType)
												? [
														{
															label: 'is...',
															value: '=',
														},
														{
															label: 'is not...',
															value: '!=',
														},
														{
															label: 'is null',
															value: 'is null',
														},
														{
															label: 'is not null',
															value: 'is not null',
														},
												  ]
												: ['DATETIME', 'DATE', 'TIME'].includes(
														filter.attributeType
												  )
												? [
														{
															label: 'is...',
															value: '=',
														},
														{
															label: 'is not...',
															value: '!=',
														},
														{
															label: 'is before...',
															value: '<',
														},
														{
															label: 'is after...',
															value: '>',
														},
														{
															label: 'is on or before...',
															value: '<=',
														},
														{
															label: 'is on or after...',
															value: '>=',
														},
														{
															label: 'is null',
															value: 'is null',
														},
														{
															label: 'is not null',
															value: 'is not null',
														},
												  ]
												: [
														{
															label: 'is...',
															value: '=',
														},
														{
															label: 'is not...',
															value: '!=',
														},
														{
															label: 'contains...',
															value: 'contains',
														},
														{
															label: 'does not contain...',
															value: 'does not contain',
														},
														{
															label: 'is empty',
															value: 'is empty',
														},
														{
															label: 'is not empty',
															value: 'is not empty',
														},
												  ]
										}
									/>
								)}

								{hasSpecifier(filter) && (
									<>
										{['DATETIME', 'DATE'].includes(filter.attributeType) && (
											<Select
												mini
												name="specifier"
												value={filter.specifier ?? ''}
												onChange={updateFilter.bind(null, filter.id)}
												options={[
													{
														label: 'today',
														value: 'today',
													},
													{
														label: 'number of days ago...',
														value: 'number of days ago',
													},
													{
														label: 'number of days from now...',
														value: 'number of days from now',
													},
													{
														label: 'exact date...',
														value: 'exact date',
													},
												]}
											/>
										)}
										{filter.attributeType === 'TIME' && (
											<Select
												mini
												name="specifier"
												value={filter.specifier ?? ''}
												onChange={updateFilter.bind(null, filter.id)}
												options={[
													{
														label: 'now',
														value: 'now',
													},
													{
														label: 'number of minutes ago...',
														value: 'number of minutes ago',
													},
													{
														label: 'number of minutes from now...',
														value: 'number of minutes from now',
													},
													{
														label: 'exact time...',
														value: 'exact time',
													},
												]}
											/>
										)}
										{filter.attributeType === 'BOOLEAN' && (
											<Select
												mini
												name="specifier"
												value={filter.specifier ?? ''}
												onChange={updateFilter.bind(null, filter.id)}
												options={[
													{
														label: 'is true',
														value: 'is true',
													},
													{
														label: 'is false',
														value: 'is false',
													},
													{
														label: 'is null',
														value: 'is null',
													},
													{
														label: 'is not null',
														value: 'is not null',
													},
												]}
											/>
										)}
									</>
								)}

								{contentInputType(filter) !== null && (
									<Input
										mini
										name="content"
										// @ts-expect-error contentInputType cannot be null
										type={contentInputType(filter)}
										// @ts-expect-error FIX
										value={filter.content}
										onChange={updateFilter.bind(null, filter.id)}
									/>
								)}
							</InputsContainer>
						</DropdownRow>
					))}
				</DropdownContainer>
			</form>

			<ButtonContainer>
				<Button onClick={addFilter}>+ Add filter</Button>
				{filtersChanged && (
					<Button primary form="filterForm">
						Apply changes
					</Button>
				)}
			</ButtonContainer>
		</Popover>
	);
}

export default FilterDropdown;
