import { rgba } from 'polished';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useApiWorkspaces } from 'api/reactQueryHooks/useApiWorkspaces';
import Button from 'components/Button';
import {
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from 'components/DropdownMenu';
import { Icon } from 'components/Icon';
import _Spinner from 'components/Spinner';
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevronDown.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as WorkspaceIcon } from 'images/icons/workspace.svg';
import { setWorkspaceId } from 'reduxState/slices/workspaceId';
import styles from 'utils/styles';

const Spinner = styled(_Spinner)`
	width: 1.75rem;
	height: 1.75rem;
	margin: 1rem auto;
	border: 0.25rem solid ${rgba(styles.colours.neutral[500], 0.2)};
	border-top-color: ${styles.colours.neutral[500]};
`;

export function WorkspaceSwitcher() {
	const dispatch = useDispatch();

	const workspaceId = useSelector((state) => state.workspaceId);

	const { data: apiWorkspaces, isLoading: isLoadingWorkspaces } =
		useApiWorkspaces();

	const workspaces = useMemo(() => {
		return (
			[...(apiWorkspaces ?? [])].sort((a, b) => a.name.localeCompare(b.name)) ??
			[]
		);
	}, [apiWorkspaces]);

	const { data: apiWorkspace, isLoading: isLoadingWorkspace } = useApiWorkspace(
		workspaceId,
		{
			enabled: workspaceId !== null,
		}
	);
	const workspace = useMemo(() => {
		return apiWorkspace ?? null;
	}, [apiWorkspace]);

	const handleSetWorkspaceId = (workspaceId: number) => {
		dispatch(setWorkspaceId(workspaceId));
	};

	const isLoading = isLoadingWorkspaces || isLoadingWorkspace;

	function getWorkspaceIcon(logoUrl: string | null) {
		if (logoUrl === null) {
			return <WorkspaceIcon />;
		}

		return (
			<Icon>
				<img src={logoUrl} />
			</Icon>
		);
	}

	if (!workspace) {
		return null;
	}

	return (
		<DropdownMenu
			align="start"
			trigger={
				<Button
					icon={getWorkspaceIcon(workspace.logoUrl)}
					value={workspace.name}
					iconRight={<ChevronDownIcon />}
					outlined
				/>
			}
		>
			{isLoading ? (
				<Spinner />
			) : (
				workspaces.map((workspace) => (
					<DropdownMenuItem
						key={workspace.id}
						to="/"
						icon={getWorkspaceIcon(workspace.logoUrl)}
						active={workspace.id === workspaceId}
						onSelect={() => {
							handleSetWorkspaceId(workspace.id);
						}}
					>
						{workspace.name}
					</DropdownMenuItem>
				))
			)}

			<DropdownMenuSeparator />

			<DropdownMenuItem to="/workspaces/new" icon={<PlusIcon />}>
				New workspace
			</DropdownMenuItem>
		</DropdownMenu>
	);
}
