import styled, { css } from 'styled-components';

import { RowNumber } from 'components/Table/RowNumber';
import { SelectRecordCheckbox } from 'components/Table/SelectRecordCheckbox';
import { background, bodyBackground, neutral } from 'utils/colors';
import styles from 'utils/styles';

export const FirstCellInRow = styled.div<{
	noBorderBottom?: boolean;
	showCheckboxOnHover?: boolean;
	editable?: boolean;
	noBorder?: boolean;
	noBackground?: boolean;
	rowHeight?: number;
	isSticky?: boolean;
}>`
	width: 4rem;
	min-width: 4rem;
	padding: 0;
	text-align: center;
	overflow: hidden;
	position: ${({ isSticky }) => (isSticky ? 'sticky' : 'absolute')};
	left: 0;
	${(props) => css`
		height: calc(
			${styles.table.cell.paddingVerticalInPixels}px * 2 +
				${styles.table.row.lineHeightInPixels}px * ${props.rowHeight}
		);
	`}
	background: ${background[1]};
	background-clip: padding-box; // Fixes hidden border bug on Firefox (https://stackoverflow.com/a/16337203)
	box-shadow: ${({ noBorder, noBorderBottom }) => {
			if (noBorder) {
				return `inset 0 0 0 0`;
			}
			return noBorderBottom ? `inset -1px 0 0 0` : `inset -1px -1px 0 0`;
		}}
		${neutral[4]};
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: ${({ noBackground }) =>
			noBackground ? 'none' : bodyBackground};
		pointer-events: none;
	}

	&:hover,
	&:focus-within {
		${SelectRecordCheckbox} {
			display: inline-block;
		}

		${(props) =>
			props.editable &&
			css`
				${RowNumber} {
					display: none;
				}
			`};
	}
`;
