import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { EditableTableCellProps } from 'components/TableCell/EditableTableCell/index';
import { DropdownTableCellContents } from 'components/TableCell/EditableTableCellContents/DropdownTableCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { UseEditableTableRecordValueResult } from 'utils/useEditableRecordValue';

type DropdownTableCellProps = Pick<
	EditableTableCellProps,
	| 'isFirstCell'
	| 'isPrimaryAttribute'
	| 'usersById'
	| 'column'
	| 'dataColumn'
	| 'dataRow'
> &
	Pick<
		UseEditableTableRecordValueResult,
		| 'highlighted'
		| 'isEditing'
		| 'canEdit'
		| 'handleKeyDown'
		| 'handleFocus'
		| 'handleBlur'
		| 'cellRef'
		| 'handleDoubleClick'
		| 'selected'
		| 'rowHeight'
		| 'rawValue'
		| 'displayValue'
		| 'setValue'
	> & { allowedValues: string[]; hasCompletedFirstRender: boolean };

export const DropdownTableCell = ({
	isFirstCell,
	isPrimaryAttribute,
	highlighted,
	isEditing,
	canEdit,
	handleFocus,
	cellRef,
	handleDoubleClick,
	selected,
	rowHeight,
	rawValue,
	handleKeyDown,
	usersById,
	column,
	hasCompletedFirstRender,
	setValue,
	displayValue,
	allowedValues,
	dataColumn,
	dataRow,
}: DropdownTableCellProps) => {
	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			highlighted={highlighted}
			editing={isEditing}
			editable={canEdit}
			ref={cellRef}
			tabIndex={0}
			onFocus={handleFocus}
			onKeyDown={handleKeyDown}
			data-row={dataRow}
			data-column={dataColumn}
			onDoubleClick={handleDoubleClick}
		>
			<StyledTableCellContainer
				selected={selected !== undefined}
				selectColour={selected && usersById[selected.userId]?.avatarColour}
				editable={canEdit}
				rowHeight={rowHeight}
				hasCompletedFirstRender={hasCompletedFirstRender}
			>
				<DropdownTableCellContents
					setValue={setValue}
					cellRef={cellRef}
					rawValue={rawValue}
					displayValue={displayValue}
					canEdit={canEdit}
					column={column}
					allowedValues={allowedValues}
				/>
			</StyledTableCellContainer>
		</StyledTableCell>
	);
};
