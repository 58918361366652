import { useVirtual } from 'react-virtual';
import { useSnapshot } from 'valtio';

import { getReadOnlyTableCellKeyboardShortcuts } from 'components/TableCell/getReadOnlyTableCellKeyboardShortcuts';
import { AdvancedViewAttribute, CellRecord } from 'typings/serverTypes';
import { TableData, ViewType } from 'typings/types';
import { getRecordDisplayValue } from 'utils/getRecordDisplayValue';
import { getRecordValue } from 'utils/getRecordValue';
import { state } from 'valtioState';

type Props = {
	column: AdvancedViewAttribute;
	data: TableData<ViewType.ADVANCED>;
	record: CellRecord;
	lastRowIndex: number;
	lastVisibleColumnIndex: number;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
};

export const useReadOnlyRecordValue = ({
	column,
	record,
	data,
	lastRowIndex,
	lastVisibleColumnIndex,
	scrollToColumnIndex,
	scrollToRowIndex,
}: Props) => {
	const snap = useSnapshot(state);

	const databaseId = data.view.sqlDatabaseId;
	const rowHeight = data.view.tableRowHeight;

	const sqlDatabase = snap.entities.sqlDatabases.byId[databaseId];
	const dataSource =
		sqlDatabase && snap.entities.dataSources.byId[sqlDatabase.dataSourceId];
	const attributeId = column.name;

	const rawValue = getRecordValue({
		record,
		attributeId,
	});

	const displayValue = getRecordDisplayValue({
		rawValue,
		record,
		attributeId,
	});

	const handleKeyDown = getReadOnlyTableCellKeyboardShortcuts({
		value: rawValue,
		scrollToColumnIndex,
		scrollToRowIndex,
		lastRowIndex,
		lastVisibleColumnIndex,
	});

	return {
		rawValue,
		rowHeight,
		databaseId,
		handleKeyDown,
		displayValue,
		dataSource,
	};
};
