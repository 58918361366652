import { useState } from 'react';
import styled from 'styled-components';

import { NewViewPage } from 'components/pages/NewView';
import { NewViewHeader } from 'components/pages/NewView/NewViewHeader';
import { NewViewSidebar } from 'components/pages/NewView/NewViewSidebar';
import { ViewType } from 'typings/types';

import { SidebarView } from './AppRoute';

const StyledNewViewRoute = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

export function NewViewRoute() {
	const [viewType, setViewType] = useState<ViewType>(ViewType.BASIC);
	const [tableId, setTableId] = useState<number | null>(null);
	const [dataSourceId, setDataSourceId] = useState<number | null>(null);

	return (
		<StyledNewViewRoute>
			<NewViewHeader
				viewType={viewType}
				tableId={tableId}
				dataSourceId={dataSourceId}
			/>

			<SidebarView fixedHeight>
				<NewViewSidebar
					viewType={viewType}
					setViewType={setViewType}
					tableId={tableId}
					setTableId={setTableId}
					dataSourceId={dataSourceId}
					setDataSourceId={setDataSourceId}
				/>
				<NewViewPage
					viewType={viewType}
					tableId={tableId}
					dataSourceId={dataSourceId}
				/>
			</SidebarView>
		</StyledNewViewRoute>
	);
}
