import ReactRteRichTextEditor from 'react-rte';
import styled, { css } from 'styled-components';

import { background } from 'utils/colors';
import {
	JSON_AND_RICH_TEXT_HEIGHT_IN_REM,
	JSON_AND_RICH_TEXT_WIDTH_IN_REM,
} from 'utils/constants';
import styles from 'utils/styles';

export const RichTextEditor = styled(ReactRteRichTextEditor)<{
	isEditing?: boolean;
}>`
	height: 100%;
	border: none;
	background: transparent;
	border-radius: 0;

	* {
		font-size: 1rem;
		font-family: ${styles.text.fontFamilyText};
		z-index: 0;
	}

	.public-DraftEditor-content {
		padding: ${styles.table.cell.padding};
	}

	${(props) =>
		!props.autoFocus &&
		css`
			* {
				cursor: default;
			}
		`};

	${(props) =>
		props.isEditing &&
		css`
			height: ${JSON_AND_RICH_TEXT_HEIGHT_IN_REM}rem;
			position: absolute;
			width: ${JSON_AND_RICH_TEXT_WIDTH_IN_REM}rem;
			z-index: 1;
			background: ${background[1]};
		`};
`;
