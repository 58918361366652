import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import Spinner from 'components/Spinner';
import { Tooltip } from 'components/Tooltip';
import { DataSourceNavigator } from 'components/pages/DataSourceNavigator';
import { ReactComponent as DatabaseIcon } from 'images/icons/database.svg';
import { ReactComponent as DisconnectedIcon } from 'images/icons/disconnected.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { neutral } from 'utils/colors';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { getSortedWorkspaceDataSources } from 'utils/getSortedWorkspaceDataSources';
import { useDataSourceHealthCheck } from 'utils/useDataSourceHealthCheck';
import { state } from 'valtioState';
import { DefinedDataSource } from 'valtioState/dataSources/index';

import { SectionTitle, UnhealthyDataSourceIndicator } from '.';
import { SidebarContainer } from './SidebarContainer';
import { SidebarItem, Icon } from './SidebarItem';
import { SidebarSection } from './SidebarSection';
import Sidebar from './index';

const StyledSpinner = styled(Spinner)`
	width: 15px;
	height: 15px;
	border-width: 1px;
	margin: 0;
`;

export const SettingsButton = styled(SidebarItem)`
	padding: 0.5rem;
	width: auto;
	&& ${Icon} {
		margin-right: 0;
	}
`;

const LoadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	margin-top: 2rem;
	color: ${neutral[1]};
`;

interface DataSourceSidebarSectionProps {
	dataSource: DefinedDataSource;
}

function DataSourceSidebarSection({
	dataSource,
}: DataSourceSidebarSectionProps) {
	useDataSourceHealthCheck(dataSource);
	const getStatusIndicator = () => {
		if (!dataSource.connected) {
			return (
				<Tooltip value="Not connected">
					<DisconnectedIcon />
				</Tooltip>
			);
		}
		if (dataSource.loading || dataSource.healthy === undefined) {
			return (
				<Tooltip value="Connecting...">
					{/* div is required to get the tooltip to show up properly */}
					<div>
						<StyledSpinner />
					</div>
				</Tooltip>
			);
		}
		if (dataSource.healthy === false) {
			return <UnhealthyDataSourceIndicator />;
		}
		return undefined;
	};

	return (
		<SidebarSection
			Header={(props) => (
				<>
					<SectionTitle
						title={dataSource.name}
						statusIndicator={getStatusIndicator()}
						{...props}
					/>
					<SettingsButton
						iconLeft={<SettingsIcon />}
						to={`/settings/data-sources/${dataSource.id}`}
					/>
				</>
			)}
			initiallyExpanded={true}
			topLevel
			localStorageKey={
				LOCAL_STORAGE_KEY.DATA_SOURCE_EXPANDED + '-' + dataSource.id
			}
		>
			<DataSourceNavigator dataSource={dataSource} />
		</SidebarSection>
	);
}

export function DataSidebar() {
	const snap = useSnapshot(state);
	const workspaceId = useSelector((state) => state.workspaceId);
	if (!workspaceId) {
		return null;
	}

	const dataSources = getSortedWorkspaceDataSources(
		snap.entities.dataSources.byId,
		workspaceId
	);

	return (
		<Sidebar id={'dataSidebar'}>
			<SidebarContainer>
				<SidebarItem
					exact
					to="/data-sources"
					iconLeft={<DatabaseIcon />}
					title="Data"
				/>
				<SidebarItem
					to="/databases/add"
					iconLeft={<PlusIcon />}
					title="Connect data source"
					blue
				/>

				{/* Show a loading indicator if the persistent storage hasn't be hydrated or if there are no data sources
				 and the workspace settings haven't yet loaded. */}
				{!snap.hasLoadedStateFromPersistentStorage ||
					(!snap.hasLoadedWorkspaceSettings && dataSources.length === 0 && (
						<LoadingContainer>
							<span>Loading workspace data</span>
							<StyledSpinner />
						</LoadingContainer>
					))}

				{dataSources.map((dataSource) => {
					return (
						<DataSourceSidebarSection
							key={dataSource.id}
							dataSource={dataSource}
						/>
					);
				})}
			</SidebarContainer>
		</Sidebar>
	);
}
