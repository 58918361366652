import { useEffect } from 'react';

export function useDocumentTitle(title: string | undefined) {
	const defaultTitle = 'Basedash';

	useEffect(() => {
		document.title = title ?? defaultTitle;

		return () => {
			document.title = defaultTitle;
		};
	}, [title]);
}
