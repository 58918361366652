import { createAsyncThunk } from '@reduxjs/toolkit';

import { deleteMemberInvitation as deleteMemberInvitationApi } from 'api/deleteMemberInvitation';

export const deleteMemberInvitation = createAsyncThunk(
	'deleteMemberInvitation',
	async (
		{
			workspaceId,
			invitationId,
		}: {
			workspaceId: number;
			invitationId: number;
		},
		thunkAPI
	) => {
		const response = await deleteMemberInvitationApi(workspaceId, invitationId);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
	}
);
