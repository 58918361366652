import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ViewSettingsPage } from 'components/pages/ViewSettings';
import { AdvancedViewSettingsSidebar } from 'components/pages/ViewSettings/AdvancedViewSettingsSidebar';
import { BasicViewSettingsSidebar } from 'components/pages/ViewSettings/BasicViewSettingsSidebar';
import { ViewSettingsHeader } from 'components/pages/ViewSettings/ViewSettingsHeader';
import { TableData__View, ViewType } from 'typings/types';
import { extractTableDataForView } from 'utils/extractTableData';
import { useView } from 'utils/useView';

import { SidebarView } from './AppRoute';

const StyledViewSettingsRoute = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

export function ViewSettingsRoute() {
	const params = useParams<{ viewId: string }>();
	const viewId = Number(params.viewId);
	const { view, loading: viewLoading } = useView(viewId);

	// @ts-expect-error Readonly issues
	const data: TableData__View | null = useMemo(
		() => (view ? { type: 'view', view } : null),
		[view]
	);

	if (viewLoading || !view) {
		return null;
	}

	return (
		<StyledViewSettingsRoute>
			{/* @ts-expect-error Readonly issues */}
			<ViewSettingsHeader view={view} />

			<SidebarView fixedHeight>
				{data?.view.type === ViewType.BASIC && (
					<BasicViewSettingsSidebar
						data={data}
						extractedTableData={extractTableDataForView(data)}
						dataLoading={viewLoading}
					/>
				)}
				{data?.view.type === ViewType.ADVANCED && (
					<AdvancedViewSettingsSidebar data={data} dataLoading={viewLoading} />
				)}
				{/* @ts-expect-error Readonly issues */}
				<ViewSettingsPage view={view} />
			</SidebarView>
		</StyledViewSettingsRoute>
	);
}
