import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Tooltip } from 'components/Tooltip';
import { background, bodyBackground, neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

export const ChipRoot = styled.div<{
	active?: boolean;
	inline?: boolean;
	hasIcon: boolean;
}>`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	padding: 0.125rem 0.25rem;
	align-items: center;
	line-height: 1.25;
	/* Used to make sure the box-shadow stays within the box model */
	margin: 1px;
	background: ${bodyBackground};
	box-shadow: inset 0 0 0 1px ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	overflow: hidden;
	color: ${neutral[1]};
	text-overflow: ellipsis;
	white-space: nowrap;

	${({ active }) =>
		active &&
		css`
			background: ${background[1]};
			box-shadow: 0 0 0 1px ${primary[1]};
			z-index: 1;
		`}
`;

const Icon = styled.div`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	margin-right: 0.25rem;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${neutral[1]};
	}
`;

interface ChipProps {
	active?: boolean;
	title?: React.ReactNode;
	icon?: React.ReactNode;
	to?: string;
	maskContent?: boolean;
	inline?: boolean;
	children: React.ReactNode;
	className?: string;
}

export function Chip({
	active,
	title,
	icon,
	to,
	maskContent,
	inline,
	className,
	children,
}: ChipProps) {
	const renderChip = () => {
		return (
			<ChipRoot
				active={active}
				inline={inline}
				className={
					maskContent ? `${className} fs-mask highlight-block` : className
				}
				hasIcon={Boolean(icon)}
			>
				{icon && <Icon>{icon}</Icon>}
				<span>{children}</span>
			</ChipRoot>
		);
	};

	if (to && title) {
		return (
			<Tooltip value={title}>
				<Link to={to}>{renderChip()}</Link>
			</Tooltip>
		);
	}

	if (to) {
		return <Link to={to}>{renderChip()}</Link>;
	}

	if (title) {
		return <Tooltip value={title}>{renderChip()}</Tooltip>;
	}

	return renderChip();
}
