import { FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Card from 'components/Card';
import { Icon } from 'components/Icon';
import Page from 'components/Page';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import FormButton from 'components/fields/FormButton';
import { Form } from 'components/forms/Form';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { changeWorkspacePlan } from 'reduxState/legacyWorkspaceThunksAndActions';
import { ApiUser } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { canManageBilling } from 'utils/permissions';
import { getPlanPrice, Plan } from 'utils/plans';
import { toast } from 'utils/toast/toast';

const CardContent = styled.div`
	max-width: 30rem;
	margin: 0 auto;

	p + p {
		margin-top: 2rem;
	}
`;

const InfoSection = styled.section`
	display: flex;
	flex-direction: column;
	margin-bottom: 2.5rem;
`;

const InfoSectionName = styled.h4`
	margin-bottom: 0.375rem;
	font-size: 0.875rem;
	font-weight: 500;
	text-transform: uppercase;
	color: ${neutral[2]};
	cursor: default;
`;

const InfoSectionTitle = styled.strong`
	font-size: 1.125rem;
	font-weight: 500;
	margin-bottom: 0.125rem;
	cursor: default;
`;

const InfoSectionDetails = styled.span`
	font-size: 0.875rem;
	color: ${neutral[2]};
	cursor: default;
`;

const BillingItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	cursor: default;
`;

const BillingItemTitle = styled.strong`
	font-weight: 500;
	cursor: default;
`;

const FeatureContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 4rem;
`;

const Feature = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.75rem;
	line-height: 1.125;
	cursor: default;
`;

const FeatureCancel = styled.div`
	margin-right: 0.5rem;
`;

const Text = styled.p`
	cursor: default;
`;

export default function DowngradePlan() {
	const dispatch = useDispatch();

	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const history = useHistory();
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading || workspaceId === null) {
			return;
		}
		setLoading(true);

		await dispatch(changeWorkspacePlan(workspaceId, 'Basic'));

		setLoading(false);

		window.analytics.track('Workspace Downgraded', {
			workspaceId,
		});
		toast('Workspace downgraded.');
		history.push('/settings');
	};

	if (!collaborator || !workspace) {
		return <Spinner />;
	}

	if (!canManageBilling(collaborator)) {
		return <Redirect to="/settings" />;
	}

	if (workspace.plan === 'Basic') {
		return <Redirect to="/settings" />;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle
							icon={<SettingsIcon />}
							title="Downgrade workspace"
						/>
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section>
							<Card>
								<CardContent>
									<InfoSection>
										<InfoSectionName>Workspace</InfoSectionName>
										<InfoSectionTitle>{workspace.name}</InfoSectionTitle>
										<InfoSectionDetails>
											{workspace.plan}, ${getPlanPrice(workspace.plan as Plan)}{' '}
											per user / month
										</InfoSectionDetails>
									</InfoSection>

									<InfoSection>
										<BillingItem>
											<BillingItemTitle>New monthly price</BillingItemTitle>
											<p>$0 / month</p>
										</BillingItem>
									</InfoSection>

									<FeatureContainer>
										<Feature>
											<FeatureCancel>
												<Icon>
													<CloseIcon />
												</Icon>
											</FeatureCancel>
											Unlimited collaborators
										</Feature>
										<Feature>
											<FeatureCancel>
												<Icon>
													<CloseIcon />
												</Icon>
											</FeatureCancel>
											Unlimited edit history
										</Feature>
										<Feature>
											<FeatureCancel>
												<Icon>
													<CloseIcon />
												</Icon>
											</FeatureCancel>
											Real-time Slack notifications
										</Feature>
										<Feature>
											<FeatureCancel>
												<Icon>
													<CloseIcon />
												</Icon>
											</FeatureCancel>
											Slack support
										</Feature>
									</FeatureContainer>

									<Text>
										Downgrading will remove all collaborators from the workspace
										except for yourself.
									</Text>

									<Text>
										You may be charged one final invoice for any outstanding
										usage if you added new collaborators this payment period.
									</Text>

									<Form onSubmit={handleSubmit}>
										<FormButton loading={loading}>
											Downgrade workspace
										</FormButton>
									</Form>
								</CardContent>
							</Card>
						</Section>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
