import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useCreateBasicApiView } from 'api/reactQueryHooks/useCreateBasicApiView';
import Button from 'components/Button';
import DeleteRecordsButton from 'components/Table/DeleteRecordsButton';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { background, neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import styles, { media } from 'utils/styles';

import { Pagination } from './Pagination';

const ViewFooterComponent = styled.div`
	display: flex;
	justify-content: space-between;
	height: ${styles.sizes.actionBarHeight};
	align-items: center;
	flex-shrink: 0;
	padding: 0 0.5rem;
	font-size: 0.875rem;
	color: ${neutral[2]};
	background: ${background[1]};
	border-top: 1px solid ${neutral[4]};

	${media.mobile`
		width: 100vw;
	`};
`;

const Section = styled.section`
	display: flex;

	&:nth-child(1) {
		width: 25%;
		justify-content: flex-start;
	}

	&:nth-child(2) {
		width: 50%;
		justify-content: center;
	}

	&:nth-child(3) {
		width: 25%;
		justify-content: flex-end;
	}
`;

const Text = styled.span`
	padding: 0 0.5rem;
	cursor: default;
`;

interface Props {
	loading?: boolean;
	canDelete?: boolean;
	tableData?: TableData<ViewType.BASIC | void>;
	searchQuery?: string;
	numRecords: number;
	pageNumber: number;
	setPageNumber: (page: number) => void;
	extractedTableData: ExtractedTableData;
	reload?: () => void;
}

export function ViewFooter({
	loading,
	canDelete,
	tableData,
	searchQuery,
	numRecords,
	pageNumber,
	setPageNumber,
	extractedTableData,
	reload,
}: Props) {
	const history = useHistory();
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);

	const { mutate: createBasicApiView } = useCreateBasicApiView({
		onSuccess: (view) => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			history.push(`/views/${view.id}/settings`);
		},
	});

	const createView = () => {
		if (!(tableData && tableData.type === 'table' && workspaceId)) {
			return;
		}

		const { databaseId, schemaName, tableName } = extractedTableData;

		createBasicApiView({
			workspaceId,
			databaseId,
			schemaName,
			tableName,
		});
	};

	if (loading) {
		return (
			<ViewFooterComponent>
				<Section />
				<Section>
					<Text>Loading records</Text>
				</Section>
				<Section />
			</ViewFooterComponent>
		);
	}

	return (
		<ViewFooterComponent>
			<Section>
				<Text>
					{numRecords} {numRecords === 1 ? 'record' : 'records'}
				</Text>
			</Section>
			<Section>
				<Pagination
					numRecords={numRecords}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
				/>
			</Section>
			<Section>
				{canDelete &&
				tableData &&
				pageNumber !== undefined &&
				searchQuery !== undefined ? (
					<DeleteRecordsButton
						data={tableData}
						pageNumber={pageNumber}
						searchQuery={searchQuery}
						extractedTableData={extractedTableData}
						reload={reload}
					/>
				) : null}

				{tableData?.type === 'table' && (
					<Button icon={<PlusIcon />} value="New view" onClick={createView} />
				)}
			</Section>
		</ViewFooterComponent>
	);
}
