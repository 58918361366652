import { ApiEnum, ApiRecordAttribute } from 'typings/serverTypes';

export function getEnumValues(
	enums: ApiEnum[],
	columnSchemaName: string | undefined,
	columnTableName: string | undefined,
	column: ApiRecordAttribute
) {
	return (
		enums
			.filter(
				(enumValue) =>
					enumValue.schemaName === columnSchemaName &&
					enumValue.tableName === columnTableName &&
					enumValue.columnName === column.name
			)
			.map((columnEnumValue) => columnEnumValue.enumValue) ?? []
	);
}
