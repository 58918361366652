import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConnectedUsers = number[];

const connectedUsers = createSlice({
	name: 'connectedUsers',
	initialState: [] as ConnectedUsers,
	reducers: {
		setConnectedUsers: (state, action: PayloadAction<ConnectedUsers>) => {
			return action.payload;
		},
	},
});

export const { setConnectedUsers } = connectedUsers.actions;
export const reducer = connectedUsers.reducer;
