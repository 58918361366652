import { fetchView, FetchViewResponse } from 'api/fetchView';
import { state } from 'valtioState/index';

export const getView = async (viewId: number) => {
	const view = state.entities.views.byId[viewId];
	const value = view?.data;

	// Set a loading state
	state.entities.views.byId[viewId] = {
		loading: true,
		error: null,
		data: value,
	};

	// Make API call
	const response = await fetchView({ viewId });

	// Set view data in global store
	if (!response.ok) {
		state.entities.views.byId[viewId] = {
			loading: false,
			error: 'Unable to load view',
			data: undefined,
		};
	} else {
		const data: FetchViewResponse = await response.json();
		state.entities.views.byId[viewId] = {
			loading: false,
			error: null,
			data: { ...data, staleRecords: false },
		};
	}
};
