/* eslint-disable */
import { AppConfig } from 'typings/serverTypes';

export const initIntercom = function (data: AppConfig) {
	const APP_ID = data.intercomAppId;
	const w = window;
	const ic = w.Intercom;
	if (typeof ic === 'function') {
		// @ts-ignore
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		const d = document;
		var i = function () {
		// @ts-ignore
			i.c(arguments);
		};
		// @ts-ignore
		i.q = [];
		// @ts-ignore
		i.c = function (args) {
		// @ts-ignore
			i.q.push(args);
		};
		// @ts-ignore
		w.Intercom = i;
		const l = function () {
			const s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = 'https://widget.intercom.io/widget/' + APP_ID;
			const x = d.getElementsByTagName('script')[0];
		// @ts-ignore
			x.parentNode.insertBefore(s, x);
		};
		if (document.readyState === 'complete') {
			l();
		// @ts-ignore
		} else if (w.attachEvent) {
		// @ts-ignore
			w.attachEvent('onload', l);
		} else {
			w.addEventListener('load', l, false);
		}
	}
};
