import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Card from 'components/Card';
import { DatabaseCredentialsForm } from 'components/DatabaseCredentialsForm';
import Page from 'components/Page';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ApiUser } from 'typings/serverTypes';
import { canManageDataSources } from 'utils/permissions';

export default function UpdateDatabaseRoute() {
	const { databaseId } = useParams<{ databaseId: string }>();

	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);
	const database = workspace?.sqlDatabases.find(
		(database) => database.id === Number(databaseId)
	);

	if (!collaborator || !workspaceId || !database) {
		return null;
	}

	if (!canManageDataSources(collaborator)) {
		return <Redirect to="/" />;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<CloudIcon />} title="Update credentials" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section>
							<Card>
								<DatabaseCredentialsForm
									workspaceId={workspaceId}
									database={database}
								/>
							</Card>
						</Section>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
