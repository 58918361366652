import styled from 'styled-components';

import { neutral } from 'utils/colors';

const InstructionsContainer = styled.div``;

const Rule = styled.hr`
	margin-top: 0.75rem;
	margin-bottom: 1.25rem;
	border: none;
	border-bottom: 1px solid ${neutral[4]};
`;

const InstructionsTitle = styled.h3`
	font-size: 1rem;
	font-weight: 500;
	color: ${neutral[1]};
`;

const Instructions = styled.p``;

const LoomVideoContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	margin-top: 1.5rem;
`;

const LoomIframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

interface Props {
	title: string;
	instructions: string | null;
	videoUrl: string | null;
	className?: string;
}

function DataSourceInstructions({
	title,
	instructions,
	videoUrl,
	className,
}: Props) {
	return (
		<InstructionsContainer className={className}>
			<InstructionsTitle>{title}</InstructionsTitle>
			<Rule />
			{instructions && <Instructions>{instructions}</Instructions>}
			{videoUrl && (
				<LoomVideoContainer>
					<LoomIframe
						src={videoUrl}
						frameBorder="0"
						allowFullScreen
					></LoomIframe>
				</LoomVideoContainer>
			)}
		</InstructionsContainer>
	);
}

export { DataSourceInstructions };
