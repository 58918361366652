import { getDefaultHeaders } from 'api/utils';
import { ApiBasicView } from 'typings/serverTypes';

export type CreateBasicViewParams = {
	workspaceId: number;
	databaseId: number;
	schemaName?: string;
	tableName?: string;
	view?: Partial<
		Pick<
			ApiBasicView,
			| 'name'
			| 'layout'
			| 'joins'
			| 'attributes'
			| 'attributeProperties'
			| 'listAttributes'
			| 'filters'
			| 'sorts'
			| 'tableRowHeight'
			| 'listRowHeight'
		>
	>;
};

export function createBasicView({
	workspaceId,
	databaseId,
	schemaName,
	tableName,
	view,
}: CreateBasicViewParams) {
	return fetch(`/api/views/basic`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			workspaceId,
			databaseId,
			schemaName,
			tableName,
			view,
		}),
	});
}
