export const isSequinDataSource = (dataSource: string | null | undefined) => {
	const sequinDataSources = [
		'airtable',
		'stripe',
		'shopify',
		'twilio',
		'close',
	];
	return dataSource ? sequinDataSources.includes(dataSource) : false;
};
