import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Resizable from 'react-resizable';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Title from 'components/Title';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as CaretDownIcon } from 'images/icons/caretDown.svg';
import { ReactComponent as CaretLeftIcon } from 'images/icons/caretLeft.svg';
import { sidebarClosed } from 'reduxState/slices/sidebarExpanded';
import { background, neutral } from 'utils/colors';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import styles, { media } from 'utils/styles';

import { ResizableSidebar } from './ResizableSidebar';

export const SidebarText = styled.p<{ indentation?: number }>`
	width: 100%;
	padding: 0.125rem 0.5rem;
	padding-left: calc(
		0.5rem + ${({ indentation }) => indentation || 0} * 1.5rem
	);
	border: none;
	border-top: 1px solid ${background[1]};
	border-bottom: 1px solid ${background[1]};
	border-radius: ${styles.global.borderRadius};
	background: none;
	color: ${neutral[2]};
	text-align: left;
	line-height: 1.5;
	cursor: default;
`;

export const InlineIcon = styled.span`
	display: inline-flex;
	align-items: center;
	margin: 0 1px;
	transform: translateY(1.5px);
	width: 15px;
	height: 15px;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${neutral[2]};
	}
`;

export const ErrorIndicator = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.25rem;
	font-size: 0.75rem;
	border-radius: 0.5rem;
	background: ${styles.colours.error[700]};
`;

export const UnhealthyDataSourceIndicator = () => {
	return (
		<Tooltip value="Connection failed, check your database credentials">
			<ErrorIndicator />
		</Tooltip>
	);
};

const SidebarComponent = styled.nav`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	background: ${background[1]};
	border-right: 1px solid ${neutral[4]};
`;

const SidebarContainer = styled.div<{ expanded: boolean }>`
	z-index: 20;
	height: 100%;
	flex-shrink: 0;

	${({ expanded }) => media.tabletDown`
		display: ${expanded ? 'block' : 'none'};
	`};
`;

const Mask = styled.div<{ active: boolean }>`
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 19;
`;

export const SidebarInfoText = styled.div`
	width: 100%;
	padding: 0.375rem 0.5rem;
	background: none;
	color: ${neutral[2]};
	text-align: left;
`;

const ExpansionIconContainer = styled.div<{ expanded: boolean }>`
	margin-left: 1rem;
	visibility: ${({ expanded }) => (!expanded ? 'visible' : 'hidden')};
`;

const StyledSectionTitle = styled(Title)`
	display: flex;
	align-items: center;
	margin: 0.125rem 0 0;
	border: none;
	background: none;
	color: ${neutral[1]};
	font-size: 0.75rem;
	cursor: pointer;
	height: 1.875rem;
	padding: 0.5rem;
	border: none;
	width: 100%;
	border-radius: ${styles.global.borderRadius};

	svg {
		width: 0.875rem;
		height: 0.875rem;

		* {
			fill: ${neutral[1]};
		}
	}

	&:hover {
		background: ${neutral[5]};

		${ExpansionIconContainer} {
			visibility: visible;
		}
	}
`;

export const TitleText = styled.div`
	text-align: start;
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

interface SectionTitleProps {
	title: string;
	expanded: boolean;
	statusIndicator?: React.ReactNode;
}

export function SectionTitle({
	title,
	expanded,
	statusIndicator,
	...props
}: SectionTitleProps) {
	return (
		<StyledSectionTitle as="button" {...props}>
			<TitleText>{title}</TitleText>
			{statusIndicator}
			<ExpansionIconContainer expanded={expanded}>
				{expanded ? <CaretDownIcon /> : <CaretLeftIcon />}
			</ExpansionIconContainer>
		</StyledSectionTitle>
	);
}

type SidebarProps = {
	children: React.ReactNode;
} & React.ComponentProps<typeof SidebarContainer>;

function Sidebar({ children, ...rest }: SidebarProps) {
	const dispatch = useDispatch();
	const location = useLocation();

	const [width, setWidth] = useState(
		Number(localStorage.getItem(LOCAL_STORAGE_KEY.SIDEBAR_WIDTH)) || 16 * 16
	);
	const sidebarExpanded = useSelector((state) => state.sidebarExpanded);

	useEffect(() => {
		dispatch(sidebarClosed());
	}, [dispatch, location]);

	useEffect(() => {
		const storedWidth = Number(
			localStorage.getItem(LOCAL_STORAGE_KEY.SIDEBAR_WIDTH)
		);

		if (storedWidth) {
			setWidth(storedWidth);
		}
	}, []);

	const handleResize = (
		_event: React.SyntheticEvent<Element, Event>,
		{ size }: Resizable.ResizeCallbackData
	) => {
		setWidth(size.width);
		localStorage.setItem(LOCAL_STORAGE_KEY.SIDEBAR_WIDTH, String(size.width));
	};

	const handleCloseSidebar = () => {
		dispatch(sidebarClosed());
	};

	return (
		<>
			<SidebarContainer expanded={sidebarExpanded} {...rest}>
				<ResizableSidebar
					axis="x"
					width={width}
					minConstraints={[12 * 16, Infinity]}
					maxConstraints={[40 * 16, Infinity]}
					resizeHandles={['e']}
					onResizeStop={handleResize}
					height={Infinity}
				>
					<SidebarComponent>{children}</SidebarComponent>
				</ResizableSidebar>
			</SidebarContainer>

			<Mask active={sidebarExpanded} onClick={handleCloseSidebar} />
		</>
	);
}

export default React.memo(Sidebar);
