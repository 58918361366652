import { getDefaultHeaders } from 'api/utils';
import { BillingInformation } from 'typings/types';

export type BillingInformationResponse = BillingInformation | null;

export function fetchBillingInformation(workspaceId: number) {
	return fetch(`/api/workspaces/${workspaceId}/billing-information`, {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
