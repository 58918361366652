import { getDefaultHeaders } from 'api/utils';

export type UpdateDataSourceSettingsParams = {
	dataSourceId: number;
	editable?: boolean;
	connected?: boolean;
	displayName?: string;
};
export type UpdateDataSourceSettingsResponse = {
	editable: boolean;
	connected: boolean;
	name: string;
};

export function updateDataSourceSettings({
	dataSourceId,
	...rest
}: UpdateDataSourceSettingsParams) {
	return fetch(`/api/data-sources/${dataSourceId}`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify(rest),
	});
}
