import { format, isValid, parse } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { updateApiRecord } from 'api/updateRecord';
import { BeforeAndAfterValues } from 'components/BeforeAndAfterValues';
import { Icon } from 'components/Icon';
import { ReactComponent as Undo } from 'images/icons/undo.svg';
import { updateRecord } from 'reduxState/slices/actionLog';
import { ColumnType } from 'typings/models';
import {
	ApiAttribute,
	CellRecord,
	CellValue,
	StringRecordId,
} from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { DATETIME_FORMAT } from 'utils/constants';
import { getPrimaryAttributeFormattedAttributeId } from 'utils/getPrimaryAttributeFormattedAttributeId';
import { toast } from 'utils/toast/toast';
import { truncateValue } from 'utils/truncateValue';
import { updateAttributeValueWithinRecord } from 'valtioState/records/updateAttributeValueWithinRecord';

import { ActionButton } from './ActionButton';

const ValueGroup = styled.div`
	display: flex;
	white-space: pre;
	align-items: center;
`;

const UndoButton = styled(ActionButton)`
	margin-left: auto;
`;

interface UpdatedCellToastRowProps {
	dismiss: () => void;
	type: ColumnType;
	previousDisplayValue: CellValue;
	newDisplayValue: CellValue;
	previousRawValue: CellValue;
	newRawValue: CellValue;
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	pageNumber: number;
	searchQuery: string;
	rowNumber: number;
	record: CellRecord;
	primaryAttribute?: ApiAttribute;
	isObscured?: boolean;
}

export function UpdatedCellToastRow({
	dismiss,
	type,
	previousRawValue,
	newRawValue,
	previousDisplayValue,
	newDisplayValue,
	data,
	pageNumber,
	searchQuery,
	rowNumber,
	record,
	primaryAttribute,
	isObscured = false,
	extractedTableData,
}: UpdatedCellToastRowProps) {
	const dispatch = useDispatch();
	const actionLog = useSelector((state) => state.actionLog);

	const handleUndo = async () => {
		const lastAction = actionLog[actionLog.length - 1];
		switch (lastAction.type) {
			case 'UPDATE_RECORD': {
				const {
					attributeId,
					tableId,
					recordId,
					valueBefore,
					valueAfter,
					formattedValueAfter,
					formattedValueBefore,
				} = lastAction;

				dispatch(
					updateRecord({
						attributeId,
						tableId,
						recordId: recordId,
						valueBefore: valueAfter,
						valueAfter: valueBefore,
						formattedValueBefore: formattedValueAfter,
						formattedValueAfter: formattedValueBefore,
					})
				);

				const primaryAttributeRecordKey = primaryAttribute
					? getPrimaryAttributeFormattedAttributeId(
							attributeId,
							primaryAttribute
					  )
					: undefined;
				updateAttributeValueWithinRecord({
					recordId: record.id as StringRecordId,
					attributeId,
					newValueForAttribute: previousRawValue,
				});
				if (primaryAttributeRecordKey) {
					updateAttributeValueWithinRecord({
						recordId: record.id as StringRecordId,
						attributeId: primaryAttributeRecordKey,
						newValueForAttribute: previousDisplayValue,
					});
				}

				try {
					await updateApiRecord({
						tableId,
						attributeId,
						recordId,
						valueBefore: valueAfter,
						valueAfter: valueBefore,
						formattedValueBefore: formattedValueAfter,
						formattedValueAfter: formattedValueBefore,
					});
					window.analytics.track('Edit Undone', {
						attributeId,
						recordId: record.id,
					});
					toast.update(
						(updateToast) => (
							<UpdatedCellToastRow
								type={type}
								dismiss={() => toast.dismiss(updateToast.id)}
								previousRawValue={valueAfter}
								newRawValue={valueBefore}
								previousDisplayValue={truncateValue({
									value: formattedValueAfter,
								})}
								newDisplayValue={truncateValue({
									value: formattedValueBefore,
								})}
								data={data}
								extractedTableData={extractedTableData}
								pageNumber={pageNumber}
								searchQuery={searchQuery}
								rowNumber={rowNumber}
								record={record}
								primaryAttribute={primaryAttribute}
								isObscured={isObscured}
							/>
						),
						{
							// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ meta: { type: string; }; }' is... Remove this comment to see the full error message
							meta: {
								type: 'update',
							},
						}
					);
				} catch (e) {
					toast.error('Unable to update record.');
					const primaryAttributeRecordKey = primaryAttribute
						? getPrimaryAttributeFormattedAttributeId(
								attributeId,
								primaryAttribute
						  )
						: undefined;
					updateAttributeValueWithinRecord({
						recordId: record.id as StringRecordId,
						attributeId,
						newValueForAttribute: newRawValue,
					});
					if (primaryAttributeRecordKey) {
						updateAttributeValueWithinRecord({
							recordId: record.id as StringRecordId,
							attributeId: primaryAttributeRecordKey,
							newValueForAttribute: newDisplayValue,
						});
					}
				}

				break;
			}
			case 'DELETE_RECORDS': {
				break;
			}
		}
		dismiss();
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	const handleKeyDown = (event: KeyboardEvent) => {
		if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
			event.preventDefault();
			handleUndo();
		}
	};

	let isSameDate = null;

	if (
		type === 'DATETIME' &&
		typeof previousDisplayValue === 'string' &&
		typeof newDisplayValue === 'string'
	) {
		const parsedPreviousDate = parse(
			previousDisplayValue,
			DATETIME_FORMAT,
			new Date()
		);
		const parsedNewDate = parse(newDisplayValue, DATETIME_FORMAT, new Date());

		if (isValid(parsedPreviousDate) && isValid(parsedNewDate)) {
			isSameDate =
				format(parsedNewDate, 'MMM d yyyy') ===
				format(parsedPreviousDate, 'MMM d yyyy');
		}
	}

	return (
		<>
			<ValueGroup>
				{previousDisplayValue !== undefined && newDisplayValue !== undefined && (
					<>
						<BeforeAndAfterValues
							previousRawValue={previousRawValue}
							newRawValue={newRawValue}
							previousDisplayValue={previousDisplayValue}
							newDisplayValue={newDisplayValue}
							isSameDate={isSameDate}
							type={type}
							isObscured={isObscured}
						/>
					</>
				)}
			</ValueGroup>
			{!isObscured && (
				<UndoButton onClick={handleUndo}>
					<Icon>
						<Undo />
					</Icon>
				</UndoButton>
			)}
		</>
	);
}
