import styled, { keyframes } from 'styled-components';

import _RouteContainer from 'components/RouteContainer';
import { useTheme } from 'components/providers/ThemeProvider';
import logoDark from 'images/logos/logo-dark.svg';
import logoLight from 'images/logos/logo-light.svg';
import { background, neutral } from 'utils/colors';

const RouteContainer = styled(_RouteContainer)<{ active: boolean }>`
	position: absolute;
	width: 100%;
	height: 100vh;
	background: ${background[1]};
	align-items: center;
	justify-content: center;
	pointer-events: none;
	opacity: ${(props) => (props.active ? 1 : 0)};
	transition: opacity 0.25s;
	// Needs to be above the app sidebar which has a z-index of 20
	z-index: 21;
`;

const shrink = keyframes`
	from {
		height: 4.5rem;
	}

	to {
		height: 3rem;
	}
`;

const Logo = styled.img`
	display: block;
	height: 4rem;
	animation: ${shrink} 3s forwards;
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const LoadingText = styled.p`
	margin-top: 1rem;
	opacity: 0;
	animation-name: ${fadeIn};
	animation-duration: 0.5s;
	animation-delay: 2s;
	animation-fill-mode: forwards;
	color: ${neutral[2]};
`;

interface Props {
	active: boolean;
}

function LoadingScreen({ active }: Props) {
	const { themeMode } = useTheme();

	return (
		<RouteContainer active={active}>
			<Logo src={themeMode === 'dark' ? logoDark : logoLight} />
			<LoadingText>Loading</LoadingText>
		</RouteContainer>
	);
}

export default LoadingScreen;
