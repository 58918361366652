import { ColumnType } from 'typings/models';
import { ApiAttribute, CellRecord } from 'typings/serverTypes';
import { parseISO } from 'utils/parseISO';

export const getRecordValue = ({
	record,
	attributeId,
	attributeType,
}: {
	record: CellRecord;
	attributeId: number | string | undefined;
	attributeType?: ColumnType;
	primaryAttributeAttribute?: ApiAttribute | undefined | null;
}): string | boolean | Date | null => {
	if (record === undefined) {
		return null;
	}
	if (attributeId === undefined) {
		return null;
	}
	const apiValue =
		record[`${attributeId}`] !== undefined ? record[`${attributeId}`] : '';

	if (apiValue === undefined) {
		return null;
	}

	if (apiValue === null) {
		return null;
	}

	if (typeof apiValue === 'object') {
		if (apiValue.type === 'Buffer') {
			// @ts-expect-error FIX
			return Buffer.from(apiValue.data).toString();
		} else if (attributeType === 'ARRAY') {
			// This is a hack because Sequelize returns a JS array
			// but PostgreSQL stores arrays with {} instead of []
			return JSON.stringify(apiValue).replaceAll('[', '{').replaceAll(']', '}');
		} else {
			return JSON.stringify(apiValue);
		}
	} else if (typeof apiValue === 'boolean') {
		return apiValue;
	} else {
		if (attributeType === 'DATE' || attributeType === 'DATETIME') {
			const parsedDate = parseISO({
				type: attributeType,
				value: apiValue,
			});
			return parsedDate;
		}
		return apiValue.toString();
	}
};
