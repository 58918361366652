import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { ReactComponent as ActivityIcon } from 'images/icons/activity.svg';
import { ReactComponent as FavoritedOffIcon } from 'images/icons/favoritedOff.svg';
import { ReactComponent as HomeIcon } from 'images/icons/home.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { ReactComponent as UserIcon } from 'images/icons/user.svg';
import { ApiUser, WorkspaceApiView } from 'typings/serverTypes';
import { LOCAL_STORAGE_KEY } from 'utils/constants';

import { SidebarContainer } from './SidebarContainer';
import { SidebarItem } from './SidebarItem';
import { SidebarSection } from './SidebarSection';
import { viewSort, ViewItem } from './ViewsSidebar';
import Sidebar, { InlineIcon, SectionTitle, SidebarText } from './index';

export function HomeSidebar() {
	const queryClient = useQueryClient();

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: apiWorkspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const items = apiWorkspace?.views.sort(viewSort) ?? [];
	const favoriteItems = items.filter((item) => item.favorited === true);

	if (!user || !apiWorkspace) {
		return null;
	}

	return (
		<Sidebar id={'homeSidebar'}>
			<SidebarContainer>
				<SidebarItem exact to="/" iconLeft={<HomeIcon />} title="Home" />

				<SidebarItem
					exact
					to="/activity"
					iconLeft={<ActivityIcon />}
					title="Activity"
				/>
				<SidebarItem
					exact
					to="/members"
					iconLeft={<UserIcon />}
					title="Members"
				/>
				<SidebarItem
					to="/settings"
					iconLeft={<SettingsIcon />}
					title="Settings"
				/>

				<SidebarSection
					Header={(props) => <SectionTitle title="Favorites" {...props} />}
					initiallyExpanded
					localStorageKey={LOCAL_STORAGE_KEY.FAVORITES_SIDEBAR_SECTION_EXPANDED}
					topLevel
				>
					{favoriteItems.length === 0 ? (
						<SidebarText>
							Click{' '}
							<InlineIcon>
								<FavoritedOffIcon />
							</InlineIcon>{' '}
							to add a view to favorites
						</SidebarText>
					) : (
						favoriteItems.map((item: WorkspaceApiView) => (
							<ViewItem
								view={item}
								key={JSON.stringify({ type: item.type, id: item.id })}
							/>
						))
					)}
				</SidebarSection>
			</SidebarContainer>
		</Sidebar>
	);
}
