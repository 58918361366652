import { useMutation, UseMutationOptions } from 'react-query';

import { createRecord, CreateRecordParams } from 'api/createRecord';

export const useCreateApiRecord = (
	options?: UseMutationOptions<
		void,
		| { formErrors: { [key: string]: string[] } }
		| {
				name: string;
				errors: [
					{
						message: string;
						type: string;
						path: string;
						value: string;
						origin: string;
					}
				];
				fields: { [key: string]: string };
				original: {
					length: number;
					name: string;
					severity: string;
					code: string;
					detail: string;
					schema: string;
					table: string;
					constraint: string;
					file: string;
					sql: string;
				};
				sql: string;
		  },
		CreateRecordParams,
		unknown
	>
) =>
	useMutation<
		void,
		{ formErrors: { [key: string]: string[] } },
		CreateRecordParams,
		unknown
	>(
		async (variables) => {
			const result = await createRecord(variables);

			if (!result.ok) {
				const error = await result.json();
				throw error;
			}
			return;
		},
		{
			...options,
		}
	);
