import deepEqual from 'deep-equal';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { FetchViewParams } from 'api/fetchView';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Button from 'components/Button';
import { Popover } from 'components/Popover';
import { StyledButton } from 'components/Sidebar/SidebarSection';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ApiForeignKey, Join } from 'typings/serverTypes';
import { TableData__View, ExtractedTableData, ViewType } from 'typings/types';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayNameFromSchemaAndTableName } from 'utils/getTableDisplayNameFromSchemaAndTableName';
import { state } from 'valtioState';

const DropdownContainer = styled.ul`
	min-width: 16rem;
`;

const ForeignKey = styled.li`
	display: flex;
	align-items: center;
	padding: 0.25rem 0;
	font-size: 0.875rem;
`;

const Label = styled.label`
	color: ${neutral[1]};
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
`;

const TableName = styled.strong`
	font-weight: 600;
`;

const Checkbox = styled.input`
	margin-right: 0.75rem;
	font-size: 1rem;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
`;

interface JoinPopoverProps {
	data: TableData__View<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
}

export function JoinPopover({ data, extractedTableData }: JoinPopoverProps) {
	const snap = useSnapshot(state);
	const [joins, setJoins] = useState<Join[]>([]);
	const [previousJoins, setPreviousJoins] = useState<Join[]>([]);
	const queryClient = useQueryClient();

	const [open, setOpen] = useState(false);

	const { foreignKeys, schemaName, tableName, databaseId } = extractedTableData;

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					queryClient.refetchQueries([
						REACT_QUERY_CACHE_KEY.VIEW,
						{ viewId: data.view.id } as FetchViewParams,
					]);
				}
			},
		}
	);

	useEffect(() => {
		if (data.type === 'view' && data.view.type === 'BASIC') {
			setJoins(data.view.joins);
			setPreviousJoins(data.view.joins.map((join) => ({ ...join })));
		}
	}, [data]);

	const joinsChanged = useMemo(
		() => !deepEqual(joins, previousJoins),
		[joins, previousJoins]
	);

	const baseTableForeignKeys = foreignKeys.filter(
		(foreignKey) =>
			foreignKey.baseSchemaName === schemaName &&
			foreignKey.baseTableName === tableName
	);

	const toggleJoin = (
		foreignKey: ApiForeignKey,
		event: ChangeEvent<HTMLInputElement>
	) => {
		const enabled = event.target.checked;

		if (enabled) {
			setJoins([
				// @ts-expect-error FIX
				...joins,
				// @ts-expect-error FIX
				{
					baseSchemaName: foreignKey.baseSchemaName,
					baseTableName: foreignKey.baseTableName,
					baseColumnName: foreignKey.baseColumnName,
					foreignSchemaName: foreignKey.foreignSchemaName,
					foreignTableName: foreignKey.foreignTableName,
					foreignColumnName: foreignKey.foreignColumnName,
				},
			]);
		} else {
			setJoins(
				joins.filter(
					(join) => join.baseColumnName !== foreignKey.baseColumnName
				)
			);
		}
	};

	const applyChanges = async (event?: FormEvent<HTMLFormElement>) => {
		if (event) {
			event.preventDefault();
		}

		if (joinsChanged) {
			setPreviousJoins(joins.map((join) => ({ ...join })));
			updateApiView({ view: { id: data.view.id, joins } });
		}

		setOpen(false);
	};

	return (
		<Popover
			trigger={
				<StyledButton
					title="Add joined table"
					icon={<PlusIcon />}
					primary={open}
				/>
			}
			side="right"
			align="start"
			onBlur={applyChanges}
			open={open}
			setOpen={setOpen}
		>
			<DropdownContainer>
				<form onSubmit={applyChanges} id="joinForm">
					{baseTableForeignKeys.map((foreignKey) => {
						const tableDisplayName = getTableDisplayNameFromSchemaAndTableName({
							tablesById: snap.entities.tables.byId,
							tableNamesToIdMap: snap.tableNamesToIdMap,
							schemaName: foreignKey.foreignSchemaName ?? '',
							tableName: foreignKey.foreignTableName,
							sqlDatabaseId: databaseId,
						});
						const attributeDisplayName = getAttributeDisplayName({
							attributesById: snap.entities.attributes.byId,
							attributeNamesToIdMap: snap.attributeNamesToIdMap,
							attributeName: foreignKey.name,
							tableName: foreignKey.foreignTableName,
							schemaName: foreignKey.foreignSchemaName,
							sqlDatabaseId: databaseId,
						});
						return (
							<ForeignKey key={foreignKey.name}>
								<Label>
									<Checkbox
										type="checkbox"
										checked={joins.some(
											(join) =>
												join.baseColumnName === foreignKey.baseColumnName
										)}
										onChange={toggleJoin.bind(null, foreignKey)}
									/>
									<TableName>{tableDisplayName}</TableName> on{' '}
									{attributeDisplayName}
								</Label>
							</ForeignKey>
						);
					})}
				</form>
			</DropdownContainer>

			{joinsChanged && (
				<ButtonContainer>
					<Button primary form="joinForm">
						Apply changes
					</Button>
				</ButtonContainer>
			)}
		</Popover>
	);
}
