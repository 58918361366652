import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useFindWorkspace } from 'api/reactQueryHooks/useFindWorkspace';
import { sendEmailVerification } from 'api/sendEmailVerification';
import {
	AppNavigationBar,
	appNavigationItems,
} from 'components/AppNavigationBar';
import { Banner } from 'components/Banner';
import RouteContainer from 'components/RouteContainer';
import { DataSidebar } from 'components/Sidebar/DataSidebar';
import { HomeSidebar } from 'components/Sidebar/HomeSidebar';
import { ViewsSidebar } from 'components/Sidebar/ViewsSidebar';
import ActivityPage from 'components/pages/Activity';
import { ConnectDataSource } from 'components/pages/ConnectDataSource';
import { DataPage } from 'components/pages/Data';
import { DataSourceSettings } from 'components/pages/DataSourceSettings';
import { DataSourceTables } from 'components/pages/DataSourceTables';
import DatabaseSettingsPage from 'components/pages/DatabaseSettings';
import DowngradePlanPage from 'components/pages/DowngradePlan';
import { HomePage } from 'components/pages/Home';
import { MembersPage } from 'components/pages/Members';
import { NewWorkspacePage } from 'components/pages/NewWorkspace';
import { SettingsPage } from 'components/pages/Settings';
import UpdateDatabasePage from 'components/pages/UpdateDatabase';
import UpgradePlanPage from 'components/pages/UpgradePlan';
import ViewPage from 'components/pages/View';
import { ViewsPage } from 'components/pages/Views';
import WorkspacePaymentPage from 'components/pages/WorkspacePayment';
import { setWorkspaceId } from 'reduxState/slices/workspaceId';
import AppPageNotFoundRoute from 'routes/AppPageNotFoundRoute';
import { ApiUser } from 'typings/serverTypes';
import { canAccessRawTables, canManageDataSources } from 'utils/permissions';
import { media } from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';
import { state } from 'valtioState';

import { NewViewRoute } from './NewViewRoute';
import { ViewSettingsRoute } from './ViewSettingsRoute';

export const SidebarView = styled.div<{ fixedHeight?: boolean }>`
	flex: 1;
	overflow: auto;
	display: flex;
	align-items: flex-start;

	${media.tabletUp`
		width: 100vw;
	`};
`;

function AppRoute() {
	const collaborator = useCollaborator();
	const snap = useSnapshot(state);

	const history = useHistory();
	const dispatch = useDispatch();

	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();

	const [verificationEmailSent, setVerificationEmailSent] = useState(false);

	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const params = useParams<{
		viewId?: string;
		databaseId?: string;
	}>();

	const viewId =
		params.viewId !== undefined
			? Number(params.viewId) || undefined
			: undefined;
	const databaseId =
		params.databaseId !== undefined
			? Number(params.databaseId) || undefined
			: undefined;

	const hasParamId = [viewId, databaseId].some(
		(value) => typeof value === 'number'
	);

	// Set the correct workspace based on the view/database ID. Mainly used when another user
	// shares a URL to another user and the other user is not currently in the workspace associated
	// with the shared URL
	const { isFetched: isFindWorkspaceDataFetched } = useFindWorkspace(
		{ databaseId, viewId },
		{
			enabled: hasParamId,
			onSuccess: (workspaceId) => {
				dispatch(setWorkspaceId(workspaceId));
			},
			onError: () => {
				toast('Unable to find associated workspace.');
			},
			retry: false,
			refetchOnMount: false,
		}
	);

	useEffect(() => {
		const routerPush = (url: string) => history.push(url);
		window.CommandBar?.addRouter(routerPush);
	}, [history]);
	// Don't show anything until the workspace has been found if the page is a view/database
	if (!workspaceId && !isFindWorkspaceDataFetched && hasParamId) {
		return null;
	}

	// Manually determine the current nav item from the current URL
	let activeNavItem = appNavigationItems.Home;
	if (window.location.pathname.match(/^\/views/)) {
		activeNavItem = appNavigationItems.Views;
	}
	if (
		window.location.pathname.match(
			/^\/data-sources|^\/databases|^\/settings\/data-sources/
		)
	) {
		activeNavItem = appNavigationItems.Data;
	}

	const renderSidebar = () => {
		if (activeNavItem === appNavigationItems.Views) {
			return <ViewsSidebar />;
		}
		if (
			activeNavItem === appNavigationItems.Data &&
			canManageDataSources(collaborator)
		) {
			return <DataSidebar />;
		}
		return <HomeSidebar />;
	};

	const handleSendEmailVerification = async () => {
		if (!user) return;
		const res = await sendEmailVerification();
		if (res.status === 200) {
			setVerificationEmailSent(true);
		}
	};

	return (
		<RouteContainer fixedHeight>
			<AppNavigationBar activeNavItem={activeNavItem} />

			{workspaceId === snap.config?.demoWorkspaceId && (
				<Banner to="/workspaces/new">
					You're currently in a public demo workspace. Click here to create your
					own workspace.
				</Banner>
			)}

			{user && !user.isEmailVerified && (
				<Banner
					as="button"
					disabled={verificationEmailSent}
					onClick={() => handleSendEmailVerification()}
				>
					{verificationEmailSent
						? `A verification email has been sent to ${user.email}.`
						: 'You email is not yet verified. Click here to receive a verification email.'}
				</Banner>
			)}

			<Switch>
				{/* Render new/edit view pages completely separately because they use a different high-level layout */}
				<Route exact path="/views/new" component={NewViewRoute} />
				<Route
					exact
					path="/views/:viewId/settings"
					component={ViewSettingsRoute}
				/>

				<Route>
					<SidebarView>
						{renderSidebar()}

						<Switch>
							{/* Note, make sure not to wrap routes in fragments since it can result in unexpected behaviour */}
							<Route
								exact
								path="/workspaces/new"
								component={NewWorkspacePage}
							/>
							<Route exact path="/settings" component={SettingsPage} />
							<Route
								exact
								path="/settings/downgrade"
								component={DowngradePlanPage}
							/>
							<Route
								exact
								path="/settings/upgrade"
								component={UpgradePlanPage}
							/>
							<Route
								exact
								path="/settings/payment"
								component={WorkspacePaymentPage}
							/>
							<Route exact path="/" component={HomePage} />
							<Route exact path="/members" component={MembersPage} />
							<Route exact path="/activity" component={ActivityPage} />
							<Route exact path="/views" component={ViewsPage} />
							<Route exact path="/views/:viewId" component={ViewPage} />
							<Route
								exact
								path="/databases/add"
								component={ConnectDataSource}
							/>
							<Route
								exact
								path="/databases/:databaseId/settings"
								component={DatabaseSettingsPage}
							/>
							<Route
								exact
								path="/databases/:databaseId/update"
								component={UpdateDatabasePage}
							/>
							{canManageDataSources(collaborator) && (
								<Route exact path="/data-sources" component={DataPage} />
							)}
							{canAccessRawTables(collaborator) && (
								<Route
									exact
									path="/data-sources/:dataSourceId/tables/:tableId"
									component={DataSourceTables}
								/>
							)}
							{canManageDataSources(collaborator) && (
								<Route
									exact
									path="/settings/data-sources/:dataSourceId"
									component={DataSourceSettings}
								/>
							)}
							{canManageDataSources(collaborator) && (
								<Route
									exact
									path="/settings/data-sources/:dataSourceId/tables/:tableId"
									component={DataSourceSettings}
								/>
							)}
							{canManageDataSources(collaborator) && (
								<Route
									exact
									path="/settings/data-sources/:dataSourceId/tables/:tableId/attributes/:attributeId"
									component={DataSourceSettings}
								/>
							)}

							<Route component={AppPageNotFoundRoute} />
						</Switch>
					</SidebarView>
				</Route>
			</Switch>
		</RouteContainer>
	);
}

export default React.memo(AppRoute);
