import { useMutation, UseMutationOptions } from 'react-query';

import { updateView, UpdateViewParams } from 'api/updateView';
import { ApiView } from 'typings/serverTypes';
import { updateView as updateViewInValtio } from 'valtioState/views/updateView';

export const useUpdateApiView = (
	{
		view,
	}: {
		view: ApiView | null;
	},
	options?: UseMutationOptions<
		unknown,
		string,
		UpdateViewParams,
		UpdateViewParams
	>
) =>
	useMutation<unknown, string, UpdateViewParams, UpdateViewParams>(
		async (variables) => {
			const result = await updateView(variables);

			if (result !== undefined && !result.ok) {
				const { error } = await result.json();
				throw error;
			}
		},
		{
			onMutate: (params) => {
				if (!view) {
					return;
				}

				const updatedView = params.view;
				if (updatedView) {
					updateViewInValtio(updatedView);
				}

				return { view };
			},
			...options,
		}
	);
