import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { background, neutral, onPrimary, primary } from 'utils/colors';
import styles from 'utils/styles';

const Icon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 0.5rem;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: currentcolor;
		}
	}
`;

export const DropdownMenuSeparator = styled(RadixDropdownMenu.Separator)`
	margin: 0.25rem 0.5rem;
	height: 1px;
	background: ${neutral[4]};
`;

const StyledDropdownMenuItem = styled(RadixDropdownMenu.Item)<{
	active?: boolean;
}>`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 0.375rem 0.625rem;
	color: ${neutral[1]};
	background: none;
	border: none;
	border-radius: ${styles.global.borderRadius};
	cursor: pointer;
	white-space: nowrap;
	text-align: left;

	${({ active }) =>
		active &&
		css`
			background: ${primary[1]};
			color: ${onPrimary};

			svg * {
				fill: ${onPrimary};
			}
		`}

	&:hover,
	&:focus {
		background: ${({ active }) => (active ? primary[1] : neutral[5])};
		color: ${({ active }) => (active ? onPrimary : neutral[1])};
		outline: none;
	}
`;

interface DropdownMenuItemProps {
	onSelect?: () => void;
	to?: string;
	href?: string;
	icon?: React.ReactNode;
	active?: boolean;
	children: React.ReactNode;
}

export function DropdownMenuItem({
	onSelect,
	to,
	href,
	icon,
	active,
	children,
}: DropdownMenuItemProps) {
	const content = (
		<>
			{icon && <Icon>{icon}</Icon>}
			{children}
		</>
	);
	if (to) {
		return (
			<StyledDropdownMenuItem asChild active={active} onSelect={onSelect}>
				<Link to={to}>{content}</Link>
			</StyledDropdownMenuItem>
		);
	}
	if (href) {
		return (
			<StyledDropdownMenuItem asChild active={active}>
				<a href={href} target="_blank" rel="noopener noreferrer">
					{content}
				</a>
			</StyledDropdownMenuItem>
		);
	}
	return (
		<StyledDropdownMenuItem active={active} onSelect={onSelect}>
			{content}
		</StyledDropdownMenuItem>
	);
}

const StyledDropdownMenuContent = styled(RadixDropdownMenu.Content)`
	min-width: 14rem;
	max-height: 70vh;
	overflow-y: auto;
	padding: 0.25rem;
	box-shadow: ${styles.shadows[700]};
	background: ${background[1]};
	border: 1px solid ${neutral[1]};
	border-radius: ${styles.global.borderRadius};
	color: ${neutral[1]};

	&:focus {
		outline: none;
	}
`;

interface DropdownMenuProps {
	trigger: React.ReactNode;
	align?: 'start' | 'center' | 'end';
	children: React.ReactNode;
}

export function DropdownMenu({ trigger, children, align }: DropdownMenuProps) {
	return (
		/* Wrapping the trigger in a div is a workaround to avoid a potential ref
		 forwarding rabbit hole. We want `RadixDropdownMenu.Trigger` to use
		 `asChild` to render itself as whatever child it receives, otherwise it
		 renders itself as a native `button` and that clobbers existing styling.
		 But, with `asChild`, if `trigger` is a ReactNode which reifies to a
		 `button` then that clobbers the radix `onSelect` event handler. */
		<RadixDropdownMenu.Root>
			<RadixDropdownMenu.Trigger asChild>
				<div>{trigger}</div>
			</RadixDropdownMenu.Trigger>

			<StyledDropdownMenuContent align={align}>
				{children}
			</StyledDropdownMenuContent>
		</RadixDropdownMenu.Root>
	);
}
