import { state, ValtioState } from 'valtioState/index';

export type DefinedDataSource = Exclude<
	ValtioState['entities']['dataSources']['byId'][number],
	undefined
>;

export function setDataSourceLoading(dataSourceId: number, loading: boolean) {
	const dataSource = state.entities.dataSources.byId[dataSourceId];
	if (dataSource) {
		dataSource.loading = loading;
	}
}

export function setDataSourceHealthy(dataSourceId: number, healthy: boolean) {
	const dataSource = state.entities.dataSources.byId[dataSourceId];
	if (dataSource) {
		dataSource.healthy = healthy;
	}
}
