import { useQuery, UseQueryOptions } from 'react-query';

import { fetchWorkspaces, FetchWorkspacesResponse } from 'api/fetchWorkspaces';
import { ApiError } from 'utils/errors';

export const useApiWorkspaces = (
	options?: UseQueryOptions<unknown, string, FetchWorkspacesResponse, [string]>
) =>
	useQuery<unknown, string, FetchWorkspacesResponse, [string]>(
		['workspaces'],
		async ({ queryKey }) => {
			const [_key] = queryKey;

			const response = await fetchWorkspaces();
			if (!response.ok) {
				throw new ApiError('Network response was not ok');
			}
			return response.json();
		},
		{ refetchOnMount: false, ...options }
	);
