import { useEffect, useState } from 'react';

import { CheckboxTableCell } from 'components/TableCell/EditableTableCell/CheckboxTableCell';
import { DateTableCell } from 'components/TableCell/EditableTableCell/DateTableCell';
import { DropdownTableCell } from 'components/TableCell/EditableTableCell/DropdownTableCell';
import { ForeignKeyTableCell } from 'components/TableCell/EditableTableCell/ForeignKeyTableCell';
import { ImageTableCell } from 'components/TableCell/EditableTableCell/ImageTableCell';
import { JsonTableCell } from 'components/TableCell/EditableTableCell/JsonTableCell';
import { RichTextTableCell } from 'components/TableCell/EditableTableCell/RichTextTableCell';
import { TextTableCell } from 'components/TableCell/EditableTableCell/TextTableCell';
import { UrlTableCell } from 'components/TableCell/EditableTableCell/UrlTableCell';
import { TableCellProps } from 'components/TableCell/index';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { CELL_VIEW_TYPE } from 'utils/constants';
import { useEditableRecordValue } from 'utils/useEditableRecordValue';

export type EditableTableCellProps = TableCellProps & {
	column: ApiRecordAttribute;
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	lastRowIndex: number;
	lastVisibleColumnIndex: number;
};

export const EditableTableCell = ({
	column,
	editable,
	record,
	isFirstCell,
	isPrimaryAttribute,
	pageNumber,
	searchQuery,
	rowNumber,
	data,
	extractedTableData,
	usersById,
	routeLocationSearch,
	rootTablePrimaryAttribute,
	dataRow,
	dataColumn,
	lastRowIndex,
	lastVisibleColumnIndex,
	scrollToRowIndex,
	scrollToColumnIndex,
}: EditableTableCellProps) => {
	const {
		rowHeight,
		stopEditing,
		isEditing,
		canEdit,
		setValue,
		primaryAttributeAttribute,
		foreignKey,
		cellViewType,
		inputValue,
		highlighted,
		handleBlur,
		handleFocus,
		cellRef,
		selected,
		handleChange,
		handleDoubleClick,
		setIsDropdownOpen,
		isDropdownOpen,
		handleKeyDown,
		tableId,
		databaseId,
		attributeType,
		displayValue,
		rawValue,
		handleChangeDate,
		checked,
		toggleCheckbox,
		dataSource,
		columnEnumValues,
	} = useEditableRecordValue({
		attribute: column,
		editable,
		record,
		pageNumber,
		searchQuery,
		rowNumber,
		data,
		rootTablePrimaryAttribute,
		extractedTableData,
		lastRowIndex,
		lastVisibleColumnIndex,
		scrollToRowIndex,
		scrollToColumnIndex,
	});

	// Let the cell render at default width for 1 cycle, then set the width
	const [hasCompletedFirstRender, setHasCompletedFirstRender] = useState(true);
	useEffect(() => {
		setHasCompletedFirstRender(false);
	}, []);

	if (column.hasAllowedValues) {
		const allowedValuesArray = column.allowedValues.split('\n');

		return (
			<DropdownTableCell
				isFirstCell={isFirstCell}
				isPrimaryAttribute={isPrimaryAttribute}
				highlighted={highlighted}
				isEditing={isEditing}
				canEdit={canEdit}
				handleFocus={handleFocus}
				handleBlur={handleBlur}
				cellRef={cellRef}
				handleDoubleClick={handleDoubleClick}
				selected={selected}
				rowHeight={rowHeight}
				rawValue={rawValue}
				handleKeyDown={handleKeyDown}
				usersById={usersById}
				column={column}
				hasCompletedFirstRender={hasCompletedFirstRender}
				setValue={setValue}
				displayValue={displayValue}
				allowedValues={allowedValuesArray}
				dataRow={dataRow}
				dataColumn={dataColumn}
			/>
		);
	}

	switch (cellViewType) {
		case CELL_VIEW_TYPE.RICH_TEXT:
			return (
				<RichTextTableCell
					inputValue={inputValue}
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleChange={handleChange}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.JSON:
			return (
				<JsonTableCell
					inputValue={inputValue}
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleChange={handleChange}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					displayValue={displayValue}
					stopEditing={stopEditing}
					setValue={setValue}
					rawValue={rawValue}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.DATE:
			return (
				<DateTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					hasCompletedFirstRender={hasCompletedFirstRender}
					attributeType={attributeType}
					handleChangeDate={handleChangeDate}
					column={column}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.CHECKBOX:
			return (
				<CheckboxTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					checked={checked}
					toggleCheckbox={toggleCheckbox}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.ENUM:
			return (
				<DropdownTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					setValue={setValue}
					displayValue={displayValue}
					allowedValues={columnEnumValues}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.FOREIGN_KEY:
			return (
				<ForeignKeyTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					setValue={setValue}
					displayValue={displayValue}
					foreignKey={foreignKey}
					databaseId={databaseId}
					tableId={tableId}
					primaryAttributeAttribute={primaryAttributeAttribute}
					isDropdownOpen={isDropdownOpen}
					setIsDropdownOpen={setIsDropdownOpen}
					data={data}
					dataSource={dataSource}
					routeLocationSearch={routeLocationSearch}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.IMAGE:
			return (
				<ImageTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					inputValue={inputValue}
					handleChange={handleChange}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.URL:
			return (
				<UrlTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					rawValue={rawValue}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					inputValue={inputValue}
					handleChange={handleChange}
					displayValue={displayValue}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
		case CELL_VIEW_TYPE.TEXT:
			return (
				<TextTableCell
					isFirstCell={isFirstCell}
					isPrimaryAttribute={isPrimaryAttribute}
					highlighted={highlighted}
					isEditing={isEditing}
					canEdit={canEdit}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					cellRef={cellRef}
					handleDoubleClick={handleDoubleClick}
					selected={selected}
					rowHeight={rowHeight}
					handleKeyDown={handleKeyDown}
					usersById={usersById}
					column={column}
					hasCompletedFirstRender={hasCompletedFirstRender}
					inputValue={inputValue}
					handleChange={handleChange}
					displayValue={displayValue}
					rawValue={rawValue}
					dataRow={dataRow}
					dataColumn={dataColumn}
				/>
			);
	}
};
