import {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import ReactRteRichTextEditor, { EditorValue } from 'react-rte';
import { useVirtual } from 'react-virtual';
import { useSnapshot } from 'valtio';

import { RichTextEditor } from 'components/RichTextEditor';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
} from 'components/Table/tableUtils';
import { getEditableTableCellKeyboardShortcuts } from 'components/TableCell/getEditableTableCellKeyboardShortcuts';
import { PrimaryAttribute } from 'typings/models';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { ExtractedTableData } from 'typings/types';
import { CELL_VIEW_TYPE } from 'utils/constants';
import { getCellViewType } from 'utils/getCellViewType';
import { getEnumValues } from 'utils/getEnumValues';
import { getInputValue } from 'utils/getInputValue';
import { getPrimaryAttributeAttributeFromGlobalStore } from 'utils/getPrimaryAttributeAttributeFromGlobalStore';
import { getRecordDisplayValue } from 'utils/getRecordDisplayValue';
import { state } from 'valtioState';

type Props = {
	attribute: ApiRecordAttribute;
	editable?: boolean;
	rowNumber?: number;
	extractedTableData: ExtractedTableData;
	primaryAttributes: PrimaryAttribute[];
	setNewRecordValue: (value: string | boolean | null | Date) => void;
	newRecordValue: string | boolean | null | Date;
	isFormPanel?: boolean;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
};

export const useNewRecordValue = ({
	attribute,
	editable = true,
	extractedTableData,
	primaryAttributes,
	setNewRecordValue,
	newRecordValue,
	isFormPanel = false,
	scrollToRowIndex,
	scrollToColumnIndex,
}: Props) => {
	const snap = useSnapshot(state);

	const cellRef = useRef<HTMLTableDataCellElement | null>(null);

	const columnSchemaName = attribute.schemaName;
	const columnTableName = attribute.tableName;

	const { databaseId, rowHeight, foreignKeys, tableId, enums } =
		extractedTableData;

	const sqlDatabase = snap.entities.sqlDatabases.byId[databaseId];
	const dataSource =
		sqlDatabase && snap.entities.dataSources.byId[sqlDatabase.dataSourceId];
	const tableSettings =
		tableId !== undefined ? snap.entities.tables.byId[tableId] : undefined;
	const attributeId = attribute.id;
	const rawValue = newRecordValue ?? null;
	const richTextFormat = attribute.viewOptions?.richTextFormat || 'html';
	const foreignKey = useMemo(
		() => foreignKeys && getForeignKey(attribute.name, foreignKeys),
		[attribute, foreignKeys]
	);
	const attributeType = useMemo(
		() =>
			getAttributeTypeFromDbColumnType({
				dbColumnType: attribute.typeInDb,
				isForeignKey: Boolean(foreignKey),
			}),
		[attribute, foreignKey]
	);
	// TODO: Find a way to display primary attribute value for new record cells (https://linear.app/basedash/issue/BAS-637/display-table-identifier-values-for-new-record-cells)
	const displayValue = getRecordDisplayValue({
		rawValue: newRecordValue ?? null,
		attributeType,
		attributeId,
	});

	const primaryAttributeAttribute = useMemo(
		() =>
			getPrimaryAttributeAttributeFromGlobalStore({
				primaryAttributes: primaryAttributes,
				foreignKey: foreignKey,
				databaseId: databaseId,
				attributeNamesToIdMap: snap.attributeNamesToIdMap,
				attributesById: snap.entities.attributes.byId,
			}),
		[
			databaseId,
			foreignKey,
			snap.attributeNamesToIdMap,
			snap.entities.attributes.byId,
			primaryAttributes,
		]
	);

	const columnEnumValues = useMemo(() => {
		return getEnumValues(enums, columnSchemaName, columnTableName, attribute);
	}, [enums, columnSchemaName, columnTableName, attribute]);

	const cellViewType: CELL_VIEW_TYPE = useMemo(() => {
		return getCellViewType(
			attribute,
			rawValue,
			attributeType,
			columnEnumValues
		);
	}, [attributeType, attribute, columnEnumValues, rawValue]);

	const [isEditing, setIsEditing] = useState(isFormPanel);
	const [inputValue, setInputValue] = useState<string | EditorValue | null>(
		cellViewType === CELL_VIEW_TYPE.RICH_TEXT
			? ReactRteRichTextEditor.createEmptyValue()
			: getInputValue(rawValue, cellViewType, richTextFormat)
	);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const startEditing = useCallback(() => {
		setIsEditing(true);
		setInputValue(getInputValue(rawValue, cellViewType, richTextFormat));
	}, [cellViewType, rawValue, richTextFormat]);

	const stopEditing = useCallback(() => {
		if (isFormPanel) {
			return;
		}
		setIsEditing(false);
	}, [isFormPanel]);

	const canEdit = useMemo(
		() =>
			(editable &&
				attribute?.editable &&
				dataSource?.editable &&
				tableSettings?.editable) ??
			false,
		[editable, attribute, dataSource, tableSettings]
	);

	const handleChange = (
		eventOrValue: ChangeEvent<HTMLInputElement> | EditorValue
	) => {
		if (eventOrValue instanceof EditorValue) {
			setInputValue(eventOrValue);
		} else if (typeof eventOrValue === 'string') {
			setInputValue(eventOrValue);
		} else {
			setInputValue(eventOrValue.target.value);
		}
	};

	const handleChangeDate = useCallback(
		(value: Date | null) => {
			setNewRecordValue(value);
		},
		[setNewRecordValue]
	);

	const checked = useMemo(() => {
		if (rawValue === null) {
			return false;
		}
		if (typeof rawValue === 'boolean') {
			return rawValue;
		}
		if (typeof rawValue === 'string') {
			return rawValue !== '0';
		}
		return false;
	}, [rawValue]);
	const toggleCheckbox = useCallback(() => {
		if (cellViewType !== CELL_VIEW_TYPE.CHECKBOX) {
			return;
		}

		const newCheckboxValue = !checked;
		let newFormattedCheckboxValue: boolean | '1' | '0' = newCheckboxValue;
		if (attributeType !== 'BOOLEAN') {
			newFormattedCheckboxValue = newCheckboxValue ? '1' : '0';
		}
		setNewRecordValue(newFormattedCheckboxValue);
	}, [attributeType, cellViewType, checked, setNewRecordValue]);

	const handleBlur = useCallback(() => {
		if (isEditing) {
			if (
				cellViewType === CELL_VIEW_TYPE.RICH_TEXT &&
				inputValue instanceof EditorValue
			) {
				setNewRecordValue(inputValue.toString(richTextFormat));
			} else if (cellViewType === CELL_VIEW_TYPE.DATE) {
				setNewRecordValue(rawValue);
			} else if (!(inputValue instanceof EditorValue)) {
				setNewRecordValue(inputValue);
			}
			stopEditing();
		}
	}, [
		inputValue,
		cellViewType,
		isEditing,
		rawValue,
		richTextFormat,
		setNewRecordValue,
		stopEditing,
	]);

	const handleDoubleClick = () => {
		if (!canEdit || cellViewType === CELL_VIEW_TYPE.CHECKBOX) {
			return;
		}

		if (cellViewType === CELL_VIEW_TYPE.ENUM || foreignKey) {
			setIsDropdownOpen(true);
			return;
		}

		startEditing();
	};

	const handleKeyDown = getEditableTableCellKeyboardShortcuts({
		isEditing,
		canEdit,
		cellViewType,
		foreignKey,
		startEditing,
		toggleCheckbox,
		isDropdownOpen,
		setIsDropdownOpen,
		stopEditing,
		value: rawValue,
		setNewRecordValue,
		cellRef,
		scrollToRowIndex,
		scrollToColumnIndex,
	});

	useEffect(() => {
		if (
			cellViewType !== CELL_VIEW_TYPE.RICH_TEXT ||
			typeof rawValue === 'boolean' ||
			rawValue instanceof Date
		) {
			return;
		}

		setInputValue(
			RichTextEditor.createValueFromString(rawValue || '', richTextFormat)
		);
	}, [rawValue, cellViewType, richTextFormat]);

	// Update input value whenever record value changes
	useEffect(() => {
		setInputValue(getInputValue(rawValue, cellViewType, richTextFormat));
	}, [cellViewType, rawValue, richTextFormat]);

	return {
		stopEditing,
		isEditing,
		canEdit,
		primaryAttribute: primaryAttributeAttribute,
		rawValue,
		inputValue,
		handleBlur,
		cellRef,
		handleChange,
		handleChangeDate,
		rowHeight,
		databaseId,
		handleDoubleClick,
		handleKeyDown,
		tableId,
		displayValue,
		dataSource,

		// cellViewType is how the cell show be rendered as, while attributeType is the 'intrinsic'
		// type of the cell. For example, you might want a cell to be rendered as a checkbox, but
		// it is intrinsically a tinyint/number
		cellViewType,
		attributeType,

		// Checkbox
		toggleCheckbox,
		checked,

		// Foreign keys
		foreignKey,

		// enums,
		columnEnumValues,

		// Foreign keys and enums
		isDropdownOpen,
		setIsDropdownOpen,
	};
};
