import styled, { css } from 'styled-components';

interface RouteContainerProps {
	fixedHeight?: boolean;
}

const RouteContainer = styled.div<RouteContainerProps>`
	display: flex;
	flex-direction: column;
	flex: 1;
	z-index: 0;

	${(props) =>
		props.fixedHeight &&
		css`
			height: 100vh;
		`};
`;

export default RouteContainer;
