import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import { Callout } from 'components/Callout';
import { ConnectDataSourceCta } from 'components/ConnectDataSourceCta';
import Page from 'components/Page';
import { SectionContainer } from 'components/SectionContainer';
import TextLink from 'components/TextLink';
import { ReactComponent as ActivityIcon } from 'images/icons/activity.svg';
import { canManageDataSources } from 'utils/permissions';
import { basicPlanActivityHistoryWeeks } from 'utils/plans';
import { useCollaborator } from 'utils/useCollaborator';
import { useDocumentTitle } from 'utils/useDocumentTitle';

import { ActivitySection } from './ActivitySection';

export default function Activity() {
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const workspaceDatabases = workspace?.sqlDatabases ?? [];
	const collaborator = useCollaborator();

	useDocumentTitle('Activity');

	if (!workspace) {
		return null;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<ActivityIcon />} title="Activity" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					{canManageDataSources(collaborator) &&
					workspaceDatabases.length === 0 ? (
						<ConnectDataSourceCta />
					) : (
						<SectionContainer>
							{workspace?.plan === 'Basic' && (
								<Callout>
									Your workspace is currently on the Basic plan which is limited
									to {basicPlanActivityHistoryWeeks} weeks of activity history.{' '}
									<TextLink to="/settings/upgrade">
										Click here to upgrade
									</TextLink>{' '}
									and unlock your full activity history.
								</Callout>
							)}

							<ActivitySection workspace={workspace} />
						</SectionContainer>
					)}
				</Content>
			</Container>
		</Page>
	);
}
