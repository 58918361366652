import { getToken } from 'api/utils';
import {
	CreateDataSourceApiPayload,
	formatDatabaseFormData,
} from 'utils/formatDatabaseFormData';

export function createWorkspaceDataSource(payload: CreateDataSourceApiPayload) {
	const formData = formatDatabaseFormData({ type: 'add', data: payload });

	return fetch(`/api/workspaces/${payload.workspaceId}/data-sources`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: formData,
	});
}
