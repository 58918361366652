import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext<SocketIOClient.Socket | undefined>(
	undefined
);

export const useSocket = () => {
	const socket = useContext(SocketContext);
	if (socket === undefined) {
		throw new Error('useSocket must be used within a provider');
	}
	return socket;
};

interface SocketProviderProps {
	children: React.ReactNode;
}

const socket = io();

export function SocketProvider({ children }: SocketProviderProps) {
	return (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	);
}
