import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useUpdateApiTracker } from 'api/reactQueryHooks/useUpdateApiTracker';
import Button from 'components/Button';
import { Modal } from 'components/Modal';
import { Switch } from 'components/Switch';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import TextLink from 'components/TextLink';
import _Title from 'components/Title';
import Radio from 'components/fields/Radio';
import Select from 'components/fields/Select';
import { ApiBasicView } from 'typings/serverTypes';
import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';
import { state } from 'valtioState';
import { getView } from 'valtioState/views/getView';
import { updateView } from 'valtioState/views/updateView';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	padding-bottom: 1rem;
	background: ${background[1]};
`;

const Title = styled(_Title)`
	padding: 0.25rem 0;
`;

const Section = styled.section`
	padding: 1rem 2rem;

	& + & {
		border-top: 1px solid ${neutral[4]};
	}
`;

const Item = styled.div`
	display: flex;
	justify-content: space-between;
	width: calc(100% + 1rem);
	padding: 0.5rem 0.5rem;
	margin: 0 -0.5rem;
	background: none;
	border: none;
	line-height: 1.4;
	border-radius: ${styles.global.borderRadius};
	/* Increase specifity of color so it doesn't get overridden by
	 * conflicting value from blueprint upon hover. See PR 396. */
	&& {
		color: ${neutral[1]};
	}
	${({
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'as' does not exist on type 'Pick<Detaile... Remove this comment to see the full error message
		as,
	}) =>
		(as === 'button' || as === 'a') &&
		css`
			cursor: pointer;

			&:hover {
				background: ${neutral[5]};
			}
		`}
`;

const Label = styled.span`
	color: ${neutral[2]};
`;

const StyledButton = styled(Button)`
	text-align: center;
	justify-content: center;
	margin-top: 1rem;
`;

interface Props {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	view: ApiBasicView;
}

export default function SlackModal({ view, open, onOpenChange }: Props) {
	const snap = useSnapshot(state);
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: apiWorkspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const tracker = view.tracker;
	const { mutate } = useUpdateApiTracker({
		onMutate: (variables) => {
			if (tracker) {
				updateView({
					id: view.id,
					tracker: { ...tracker, ...variables.tracker },
				});
			}
		},
	});

	const handleFinishConfiguration = async () => {
		// Refetch the view to get the latest tracker and update the view
		// in the global store
		await getView(view.id);
	};

	const handleToggleEnabled = () => {
		mutate({
			viewId: view.id,
			tracker: { enabled: !tracker?.enabled },
		});
	};

	const handleChangeCreatedAtColumn = (
		event: ChangeEvent<HTMLSelectElement>
	) => {
		const createdAtColumn = event.target.value;
		mutate({
			viewId: view.id,
			tracker: { createdAtColumn: createdAtColumn },
		});
	};

	const handleChangeFrequency = (event: ChangeEvent<HTMLInputElement>) => {
		const frequency = event.target.value;
		mutate({
			viewId: view.id,
			tracker: { frequency: frequency },
		});
	};

	const attributes = view.attributes;

	const renderModalContents = () => {
		if (snap.config?.slackClientId === null) {
			return (
				<Section>
					<Title>Missing Slack client ID</Title>
					<Item>
						SLACK_CLIENT_ID environment variable is missing on the server. It's
						required in order to have the Slack integration working.
					</Item>
				</Section>
			);
		}
		if (tracker) {
			return (
				<>
					<Section>
						<Title>Connection</Title>
						<Item>
							Notifications enabled
							<Switch
								checked={tracker.enabled}
								onChange={handleToggleEnabled}
							/>
						</Item>
						<Item>
							Workspace
							<Label>{tracker.teamName}</Label>
						</Item>
						<Item>
							Channel <Label>{tracker.channelName}</Label>
						</Item>
						<StyledButton
							target="_blank"
							rel="noopener noreferrer"
							href={tracker.configurationUrl}
							fullWidth
							outlined
							size={'small'}
						>
							Manage Slack configuration
						</StyledButton>
					</Section>

					<Section>
						<Title>Notifications</Title>
						<Select
							name="viewAs"
							label="Created at attribute"
							helpText="This is the attribute we'll watch to detect new records being created. This is usually named createdAt."
							options={[
								{
									label: 'Select an attribute',
									value: '',
								},
								...attributes
									.filter((column) =>
										['DATETIME', 'DATE'].includes(
											getAttributeTypeFromDbColumnType({
												dbColumnType: column.typeInDb,
											})
										)
									)
									.map((column) => ({
										label: column.attributeName,
										value: column.attributeName,
									})),
							]}
							value={tracker.createdAtColumn ?? ''}
							onChange={handleChangeCreatedAtColumn}
						/>
						<Radio
							name="frequency"
							label="Notification frequency"
							options={[
								{
									label: 'Real-time',
									value: 'Minute',
									disabled: !(apiWorkspace?.plan !== 'Basic'),
									description: !(apiWorkspace?.plan !== 'Basic') ? (
										<>
											<TextLink to="/settings/upgrade">Upgrade</TextLink> to
											unlock real-time notifications.
										</>
									) : null,
								},
								{
									label: 'Hourly',
									value: 'Hour',
								},
								{
									label: 'Daily',
									value: 'Day',
								},
							]}
							value={tracker.frequency}
							onChange={handleChangeFrequency}
						/>
					</Section>
				</>
			);
		}
		return (
			<>
				<Section>
					<Title>Step 1</Title>
					<Item
						as="a"
						target="_blank"
						rel="noopener noreferrer"
						href={`https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${snap.config?.slackClientId}&redirect_uri=${snap.config?.baseUrl}/oauth/slack?ViewId=${view.id}`}
					>
						Connect view to Slack
					</Item>
				</Section>

				<Section>
					<Title>Step 2</Title>
					<Item as="button" onClick={handleFinishConfiguration}>
						Finish configuration
					</Item>
				</Section>
			</>
		);
	};

	return (
		<Modal open={open} onOpenChange={onOpenChange} title="Slack settings">
			<Container>{renderModalContents()}</Container>
		</Modal>
	);
}
