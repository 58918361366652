import { useQuery, UseQueryOptions } from 'react-query';

import {
	findWorkspace,
	FindWorkspaceParams,
	FindWorkspaceResponse,
} from 'api/findWorkspace';

export const useFindWorkspace = (
	params: FindWorkspaceParams,
	options?: UseQueryOptions<
		unknown,
		string,
		FindWorkspaceResponse,
		[string, FindWorkspaceParams]
	>
) =>
	useQuery<
		unknown,
		string,
		FindWorkspaceResponse,
		[string, FindWorkspaceParams]
	>(
		['find-workspace', params],
		async ({ queryKey }) => {
			const [_key, params] = queryKey;

			const response = await findWorkspace(params);
			if (!response.ok) {
				throw Error(await response.json());
			}
			return response.json();
		},
		options
	);
