import React from 'react';
import styled from 'styled-components';

import { background, neutral, primary } from 'utils/colors';

const Checkmark = styled.svg`
	width: 1rem;
	height: 1rem;
	fill: none;
	stroke: ${neutral[1]};
	stroke-width: 3px;
`;

export const StyledCheckbox = styled.div<{
	disabled: boolean;
	checked: boolean;
	className?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.25rem;
	height: 1.25rem;
	background: ${background[1]};
	box-shadow: 0 0 0 1px ${({ checked }) => (checked ? neutral[1] : neutral[4])};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	outline: 0;

	&:focus {
		box-shadow: 0 0 0 2px ${primary[1]};
	}

	${Checkmark} {
		visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
	}
`;

interface Props {
	checked: boolean;
	onClick?: () => void;
	checkboxRef?: React.RefObject<HTMLDivElement>;
	className?: string;
	disabled?: boolean;
	tabIndex?: number;
	id?: string;
}

export const Checkbox = ({
	checked,
	onClick,
	checkboxRef,
	className,
	disabled = false,
	tabIndex,
	id,
}: Props) => {
	const handleClick = () => {
		if (!disabled && onClick !== undefined) {
			onClick();
		}
	};
	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleClick();
		}
	};
	return (
		<StyledCheckbox
			checked={checked}
			onClick={handleClick}
			disabled={disabled}
			ref={checkboxRef}
			className={`fs-mask highlight-block${className ? ' ' + className : ''}`}
			tabIndex={tabIndex}
			onKeyDown={handleKeyDown}
			id={id}
		>
			<Checkmark viewBox="0 0 24 24">
				<polyline points="20 6 9 17 4 12" />
			</Checkmark>
		</StyledCheckbox>
	);
};
