import { getDefaultHeaders } from 'api/utils';
import { EventType } from 'typings/models';

type TrackEventParams = {
	type: EventType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metadata?: any;
	workspaceId?: number;
	databaseId?: number;
	viewId?: number;
	userId?: number;
};

export function trackEvent({
	type,
	metadata,
	workspaceId,
	databaseId,
	viewId,
	userId,
}: TrackEventParams) {
	return fetch(`/api/events`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			type,
			metadata,
			workspaceId,
			databaseId,
			viewId,
			userId,
		}),
	});
}
