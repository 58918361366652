import Page, {
	InfoContainer,
	InfoText,
	InfoTextDetails,
} from 'components/Page';
import TextLink from 'components/TextLink';
import { toggleChat } from 'utils/toggleChat';

export function OopsErrorPage() {
	return (
		<Page>
			<InfoContainer>
				<InfoText>Oops, something went wrong.</InfoText>
				<InfoTextDetails>
					The issue has been logged and we're looking into it. Refreshing may
					resolve it, but if the problem continues,{' '}
					<TextLink as="button" onClick={toggleChat}>
						send us a message
					</TextLink>{' '}
					and we'll try to help you out.
				</InfoTextDetails>
			</InfoContainer>
		</Page>
	);
}
