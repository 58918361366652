import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import { ApiRecordAttribute, Filter } from 'typings/serverTypes';

export const getUrlFilters = ({
	attributes,
	schemaName,
	tableName,
}: {
	attributes: ApiRecordAttribute[];
	schemaName: string | null;
	tableName: string;
}): Filter[] => {
	const urlFilters = new URLSearchParams(location.search);
	const filters: Filter[] = [];
	for (const columnName of urlFilters.keys()) {
		const id: number = filters.length;
		let attributeType = null;
		if (attributes) {
			const column = attributes.find(
				(column) => column.attributeName === columnName
			);
			if (column) {
				attributeType = getAttributeTypeFromDbColumnType({
					dbColumnType: column.typeInDb,
				});
			}
		}
		const operator = '=';
		const content = urlFilters.get(columnName);
		filters.push({
			id,
			schemaName: schemaName ?? '',
			tableName: tableName,
			columnName,
			attributeType: attributeType ?? 'TEXT',
			operator,
			content,
			specifier: undefined,
		});
	}
	return filters;
};
