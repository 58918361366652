import { format } from 'date-fns';

import { ColumnType } from 'typings/models';
import { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT } from 'utils/constants';

export function formatDateAndTime({
	type,
	value,
	dateInfo = { isSameDate: false },
}: {
	type: ColumnType;
	value: Date;
	dateInfo?: { isSameDate?: boolean | null };
}) {
	const formattedDateWithTime = format(value, DATETIME_FORMAT);
	let formattedDate = format(value, DATE_FORMAT);

	switch (type) {
		case 'DATETIME': {
			if (dateInfo.isSameDate) {
				formattedDate = format(value, TIME_FORMAT);
			} else {
				formattedDate = format(value, DATE_FORMAT);
			}
			break;
		}
		case 'TIME': {
			formattedDate = format(value, TIME_FORMAT);
			break;
		}
	}
	return { formattedDate, formattedDateWithTime };
}
