import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { FirstCellInRow } from 'components/Table/FirstCellInRow';
import { RowNumber } from 'components/Table/RowNumber';
import { SelectRecordCheckbox } from 'components/Table/SelectRecordCheckbox';
import { TableHeadCell } from 'components/Table/TableHeadCell';
import { getRecordId, recordIdsAreEqual } from 'components/Table/tableUtils';
import TableCell from 'components/TableCell';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import {
	deselectRecords,
	selectRecords,
} from 'reduxState/slices/selectedRecords';
import { PrimaryAttribute, User } from 'typings/models';
import {
	AdvancedViewAttribute,
	ApiRecordAttribute,
	CellRecord,
	ObjectRecordId,
} from 'typings/serverTypes';
import { ExtractedTableData, TableData } from 'typings/types';
import { neutral } from 'utils/colors';
import { pageSize } from 'utils/constants';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import { isApiRecordAttribute } from 'utils/isApiRecordAttribute';

const SelectAllRecordsCheckboxCell = styled(StyledTableCell)`
	position: sticky;
	height: 100%;
	left: 0;
	z-index: 3;
	flex-shrink: 0;
	width: 4rem;
	text-align: center;
	box-shadow: inset -1px -1px 0 0 ${neutral[4]};
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SelectAllRecordsCheckbox = styled.input`
	width: 1rem;
	height: 1rem;
	padding: 0;
	margin: 0 1rem;
`;

export const RowNumberCell = styled(StyledTableCellContainer)`
	display: inline-block;
	width: 3rem;
	vertical-align: top;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

type VirtualizedCellProps = {
	columnIndex: number;
	rowIndex: number;
	schemaName: string;
	tableName: string;
	records: CellRecord[];
	extractedTableData: ExtractedTableData;
	selectedRecords: ObjectRecordId[];
	data: TableData;
	editable: boolean;
	pageNumber: number;
	searchQuery: string;
	usersById: { [userId: string]: User };
	routeLocationSearch: string;
	primaryAttribute: PrimaryAttribute | undefined;
	visibleAttributes: ApiRecordAttribute[] | AdvancedViewAttribute[];
	primaryAttributeAttribute?: ApiRecordAttribute;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	numberOfStickyColumns: number;
	measureRef?: (el: HTMLElement | null) => void;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
};

export const VirtualizedCell = React.memo(
	({
		columnIndex,
		rowIndex,
		schemaName,
		tableName,
		records,
		extractedTableData,
		selectedRecords,
		data,
		pageNumber,
		searchQuery,
		usersById,
		primaryAttribute,
		routeLocationSearch,
		editable,
		visibleAttributes,
		primaryAttributeAttribute,
		setPageNumber,
		numberOfStickyColumns,
		measureRef,
		scrollToColumnIndex,
		scrollToRowIndex,
	}: VirtualizedCellProps) => {
		const dispatch = useDispatch();

		const lastRowIndex = Math.min(pageSize - 1, records.length - 1);
		const lastVisibleColumnIndex = visibleAttributes.length - 1;

		const dataColumn = primaryAttributeAttribute?.visible
			? columnIndex - 1
			: columnIndex + 1;
		const dataRow = rowIndex - 1;
		const isPrimaryAttribute =
			(columnIndex === 1 && primaryAttributeAttribute?.visible) ?? false;
		const column =
			columnIndex === 1 && primaryAttributeAttribute?.visible
				? primaryAttributeAttribute
				: visibleAttributes[columnIndex - numberOfStickyColumns];

		if (rowIndex === 0) {
			// Render sticky header table cell
			if (columnIndex === 0) {
				// Render the checkbox cell for selecting all records

				const allRecordsSelected =
					records != null &&
					selectedRecords.length >= 1 &&
					selectedRecords.length === records.length;

				const selectAllRecords = (event: ChangeEvent<HTMLInputElement>) => {
					const checked = event.target.checked;
					if (!isAdvancedViewData(data)) {
						const { schemaName, tableName, primaryKeyAttributes } =
							extractedTableData;
						if (checked) {
							const unselectedRecords =
								records
									?.map((record) =>
										getRecordId({
											schemaName,
											tableName,
											record,
											primaryKeyAttributes,
										})
									)
									.filter((recordId): recordId is ObjectRecordId => {
										if (recordId === null) {
											return false;
										}
										for (const selectedRecord of selectedRecords) {
											if (recordIdsAreEqual(selectedRecord, recordId)) {
												return false;
											}
										}

										return true;
									}) ?? [];

							dispatch(selectRecords(unselectedRecords));
						} else {
							dispatch(deselectRecords(selectedRecords));
						}
					}
				};

				return (
					<SelectAllRecordsCheckboxCell>
						{editable && (
							<SelectAllRecordsCheckbox
								type="checkbox"
								checked={allRecordsSelected}
								onChange={selectAllRecords}
							/>
						)}
					</SelectAllRecordsCheckboxCell>
				);
			}

			if (column) {
				return (
					<TableHeadCell
						setPageNumber={setPageNumber}
						column={column}
						isPrimaryAttribute={isPrimaryAttribute}
						editable={editable}
						primaryAttribute={primaryAttribute}
						data={data}
						extractedTableData={extractedTableData}
						measureRef={measureRef}
					/>
				);
			}
		}

		// Render the main record table cells

		if (columnIndex === 0) {
			const recordId = isAdvancedViewData(data)
				? undefined
				: getRecordId({
						schemaName,
						tableName,
						record: records[rowIndex - 1],
						primaryKeyAttributes: extractedTableData.primaryKeyAttributes,
				  });

			const isSelected = selectedRecords.some(
				(selectedRecord) =>
					recordId && recordIdsAreEqual(selectedRecord, recordId)
			);

			const toggleSelected = () => {
				if (recordId == null) {
					return;
				}
				if (isSelected) {
					dispatch(deselectRecords([recordId]));
				} else {
					dispatch(selectRecords([recordId]));
				}
			};

			return (
				<FirstCellInRow showCheckboxOnHover editable={editable}>
					<RowNumberCell>
						{editable && (
							<SelectRecordCheckbox
								type="checkbox"
								checked={isSelected}
								onChange={toggleSelected}
							/>
						)}
						<RowNumber>{rowIndex + pageNumber * pageSize}</RowNumber>
					</RowNumberCell>
				</FirstCellInRow>
			);
		}

		if (!column) {
			return null;
		}

		// If statements only to make TS happy
		if (isAdvancedViewData(data) && !isApiRecordAttribute(column)) {
			return (
				<TableCell
					dataRow={dataRow}
					dataColumn={dataColumn}
					column={column}
					editable={editable}
					record={records[rowIndex - 1]}
					data={data}
					rowNumber={rowIndex}
					pageNumber={pageNumber}
					searchQuery={searchQuery}
					usersById={usersById}
					routeLocationSearch={routeLocationSearch}
					rootTablePrimaryAttribute={primaryAttribute}
					extractedTableData={extractedTableData}
					lastRowIndex={lastRowIndex}
					lastVisibleColumnIndex={lastVisibleColumnIndex}
					scrollToColumnIndex={scrollToColumnIndex}
					scrollToRowIndex={scrollToRowIndex}
				/>
			);
		} else if (!isAdvancedViewData(data) && isApiRecordAttribute(column)) {
			return (
				<TableCell
					dataRow={dataRow}
					dataColumn={dataColumn}
					column={column}
					editable={editable}
					record={records[rowIndex - 1]}
					data={data}
					rowNumber={rowIndex}
					pageNumber={pageNumber}
					searchQuery={searchQuery}
					usersById={usersById}
					routeLocationSearch={routeLocationSearch}
					rootTablePrimaryAttribute={primaryAttribute}
					extractedTableData={extractedTableData}
					lastRowIndex={lastRowIndex}
					lastVisibleColumnIndex={lastVisibleColumnIndex}
					scrollToColumnIndex={scrollToColumnIndex}
					scrollToRowIndex={scrollToRowIndex}
				/>
			);
		}
		return null;
	}
);

VirtualizedCell.displayName = 'Cell';
