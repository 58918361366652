import React, { ChangeEvent } from 'react';
import { EditorValue } from 'react-rte';

import { CheckboxCellContents } from 'components/TableCell/EditableTableCellContents/CheckboxCellContents';
import { DateTableCellContents } from 'components/TableCell/EditableTableCellContents/DateTableCellContents';
import { DropdownTableCellContents } from 'components/TableCell/EditableTableCellContents/DropdownTableCellContents';
import { ForeignKeyTableCellContents } from 'components/TableCell/EditableTableCellContents/ForeignKeyTableCellContents';
import { ImageCellContents } from 'components/TableCell/EditableTableCellContents/ImageCellContents';
import { RichTextTableCellContents } from 'components/TableCell/EditableTableCellContents/RichTextTableCellContents';
import { TextTableCellContents } from 'components/TableCell/EditableTableCellContents/TextTableCellContents';
import { UrlTableCellContents } from 'components/TableCell/EditableTableCellContents/UrlTableCellContents';
import { ColumnType } from 'typings/models';
import { ApiForeignKey, ApiRecordAttribute } from 'typings/serverTypes';
import { TableData } from 'typings/types';
import { CELL_VIEW_TYPE } from 'utils/constants';
import { ValtioState } from 'valtioState';

export type EditableTableCellContentsProps = {
	cellViewType: CELL_VIEW_TYPE;
	rawValue: string | boolean | Date | null;
	foreignKey: ApiForeignKey | undefined;
	displayValue: string | boolean | null;
	column: ApiRecordAttribute;
	attributeType: ColumnType | undefined;
	handleChangeDate: (value: Date | null) => void;
	handleBlur: () => void;
	canEdit: boolean;
	isFormPanel?: boolean;
	checked: boolean;
	toggleCheckbox: () => void;
	id?: string;
	columnEnumValues: string[];
	inputValue: string | EditorValue | null;
	handleChange: (
		eventOrValue: ChangeEvent<HTMLInputElement> | EditorValue
	) => void;
	databaseId: number;
	dataSource: ValtioState['entities']['dataSources']['byId'][number];
	routeLocationSearch?: string;
	data: TableData;
	cellRef: React.MutableRefObject<HTMLTableDataCellElement | null>;
} & (
	| {
			setNewRecordValue?: never;
			isAddingNewRecord?: false;
			highlighted?: boolean;
			handleFocus: () => void;
			setValue: ({
				valueAfter,
				formattedValueAfter,
			}: {
				valueAfter: boolean | string | null;
				formattedValueAfter?: string | boolean | null;
			}) => void;
	  }
	| {
			setNewRecordValue: (value: string | boolean | null | Date) => void;
			setValue?: never;
			isAddingNewRecord: true;
			highlighted?: never;
			handleFocus?: never;
	  }
);

export const EditableTableCellContents = ({
	cellViewType,
	rawValue,
	foreignKey,
	displayValue,
	isAddingNewRecord = false,
	column: attribute,
	attributeType,
	handleChangeDate,
	handleFocus,
	handleBlur,
	canEdit,
	isFormPanel = false,
	checked,
	toggleCheckbox,
	id,
	columnEnumValues,
	inputValue,
	databaseId,
	dataSource,
	highlighted,
	routeLocationSearch,
	setNewRecordValue,
	setValue,
	data,
	cellRef,
	handleChange,
}: EditableTableCellContentsProps) => {
	switch (cellViewType) {
		case CELL_VIEW_TYPE.ENUM:
			return (
				<DropdownTableCellContents
					setNewRecordValue={setNewRecordValue}
					setValue={setValue}
					cellRef={cellRef}
					rawValue={rawValue}
					displayValue={displayValue}
					canEdit={canEdit}
					id={id}
					column={attribute}
					allowedValues={columnEnumValues}
					isFormPanel={isFormPanel}
					isAddingNewRecord={isAddingNewRecord}
				/>
			);
		case CELL_VIEW_TYPE.FOREIGN_KEY:
			return (
				<ForeignKeyTableCellContents
					column={attribute}
					foreignKey={foreignKey}
					data={data}
					databaseId={databaseId}
					dataSource={dataSource}
					routeLocationSearch={routeLocationSearch}
					rawValue={rawValue}
					highlighted={highlighted}
					displayValue={displayValue}
				/>
			);
		case CELL_VIEW_TYPE.DATE:
			return (
				<DateTableCellContents
					rawValue={rawValue}
					attributeType={attributeType}
					handleChangeDate={handleChangeDate}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					canEdit={canEdit}
					isAddingNewRecord={isAddingNewRecord}
					column={attribute}
				/>
			);
		case CELL_VIEW_TYPE.CHECKBOX:
			return (
				<CheckboxCellContents
					rawValue={rawValue}
					isFormPanel={isFormPanel}
					checked={checked}
					toggleCheckbox={toggleCheckbox}
					canEdit={canEdit}
					id={id}
					isAddingNewRecord={isAddingNewRecord}
					column={attribute}
				/>
			);
		case CELL_VIEW_TYPE.IMAGE:
			return (
				<ImageCellContents
					column={attribute}
					rawValue={rawValue}
					isAddingNewRecord={isAddingNewRecord}
				/>
			);
		case CELL_VIEW_TYPE.URL:
			return (
				<UrlTableCellContents
					rawValue={rawValue}
					isAddingNewRecord={isAddingNewRecord}
					column={attribute}
					displayValue={displayValue}
				/>
			);
		case CELL_VIEW_TYPE.RICH_TEXT:
			return (
				<RichTextTableCellContents
					rawValue={rawValue}
					isAddingNewRecord={isAddingNewRecord}
					column={attribute}
					inputValue={inputValue}
					handleChange={handleChange}
				/>
			);
		case CELL_VIEW_TYPE.JSON:
		case CELL_VIEW_TYPE.TEXT:
			return (
				<TextTableCellContents
					displayValue={displayValue}
					column={attribute}
					rawValue={rawValue}
				/>
			);
	}
};
