import { CellValue, StringRecordId } from 'typings/serverTypes';
import { state } from 'valtioState/index';

export const updateAttributeValueWithinRecord = ({
	recordId,
	attributeId,
	newValueForAttribute,
}: {
	recordId: StringRecordId;
	attributeId: string | number;
	newValueForAttribute: CellValue;
}) => {
	const recordToBeUpdated = state.entities.records.byId[recordId];
	if (recordToBeUpdated) {
		state.entities.records.byId[recordId] = {
			...recordToBeUpdated,
			[attributeId]: newValueForAttribute,
		};
	}
};
