import { useMemo } from 'react';

import { ApiWorkspaceDetails } from 'typings/serverTypes';

import { usePrimaryAttributes } from './usePrimaryAttributes';

interface Props {
	workspace: ApiWorkspaceDetails | undefined;
	sqlDatabaseId: number;
	schemaName: string | undefined;
	tableName: string | undefined;
}

export function usePrimaryAttribute({
	workspace,
	sqlDatabaseId,
	schemaName,
	tableName,
}: Props) {
	const primaryAttributes = usePrimaryAttributes({ workspace });

	const primaryAttribute = useMemo(() => {
		return primaryAttributes?.find(
			(primaryAttribute) =>
				primaryAttribute.sqlDatabaseId === sqlDatabaseId &&
				primaryAttribute.schemaName === schemaName &&
				primaryAttribute.tableName === tableName
		);
	}, [primaryAttributes, sqlDatabaseId, schemaName, tableName]);

	return primaryAttribute;
}
