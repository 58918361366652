import { StringCellId } from 'typings/serverTypes';

export const getSelectedCellDetails = ({
	selectedCells,
	cellId,
	userId,
}: {
	selectedCells: { [userId: string]: StringCellId };
	cellId: StringCellId;
	userId: number | null;
}): { userId: string; cellId: StringCellId } | undefined => {
	// TODO: Make the shape of selected cells be Map<StringCellId, Set<number>>
	// for efficient access and checks
	const selectedCell = Object.entries(selectedCells).find(
		([cellUserId, selectedCellId]) =>
			Number(cellUserId) !== userId && selectedCellId === cellId
	);
	if (selectedCell) {
		return {
			userId: selectedCell[0],
			cellId: selectedCell[1],
		};
	}
};
