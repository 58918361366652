import { Emoji } from 'emoji-mart';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { ReactComponent as AllViewsIcon } from 'images/icons/allViews.svg';
import { ReactComponent as FavoritedOffIcon } from 'images/icons/favoritedOff.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { ApiUser, WorkspaceApiView } from 'typings/serverTypes';
import { ViewType } from 'typings/types';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { canManageViews } from 'utils/permissions';
import { useCollaborator } from 'utils/useCollaborator';

import { SidebarContainer } from './SidebarContainer';
import { SidebarItem } from './SidebarItem';
import { SidebarSection } from './SidebarSection';
import Sidebar, { InlineIcon, SectionTitle, SidebarText } from './index';

export function viewSort(a: WorkspaceApiView, b: WorkspaceApiView): number {
	return (
		(a.isPublished === b.isPublished ? 0 : a.isPublished ? 1 : -1) ||
		(b.emoji ? 1 : 0) - (a.emoji ? 1 : 0) ||
		(a.emoji ?? '').localeCompare(b.emoji ?? '') ||
		a.name.localeCompare(b.name)
	);
}

export function ViewItem(props: { view: WorkspaceApiView }) {
	return (
		<SidebarItem
			to={`/views/${props.view.id}`}
			iconLeft={
				props.view.emoji ? (
					<Emoji emoji={props.view.emoji} size={15} />
				) : props.view.type === ViewType.BASIC ? (
					<ViewIcon />
				) : (
					<QueryIcon />
				)
			}
			title={props.view.name}
			activeClassName="active"
		/>
	);
}

export function ViewsSidebar() {
	const collaborator = useCollaborator();

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: apiWorkspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const items = apiWorkspace?.views.sort(viewSort) ?? [];
	const favoriteItems = items.filter((item) => item.favorited === true);
	const draftItems = items.filter((item) => item.isPublished === false);
	const publishedItems = items.filter((item) => item.isPublished === true);

	if (!user || !apiWorkspace) {
		return null;
	}

	return (
		<Sidebar id={'viewsSidebar'}>
			<SidebarContainer>
				<SidebarItem
					exact
					to="/views"
					iconLeft={<AllViewsIcon />}
					title="Views"
				/>
				{canManageViews(collaborator) && (
					<SidebarItem
						to="/views/new"
						iconLeft={<PlusIcon />}
						title="New view"
						disabled={apiWorkspace.sqlDatabases.length === 0}
						blue
					/>
				)}

				<SidebarSection
					Header={(props) => <SectionTitle title="Favorites" {...props} />}
					initiallyExpanded
					localStorageKey={LOCAL_STORAGE_KEY.FAVORITES_SIDEBAR_SECTION_EXPANDED}
					topLevel
				>
					{favoriteItems.length === 0 ? (
						<SidebarText>
							Click{' '}
							<InlineIcon>
								<FavoritedOffIcon />
							</InlineIcon>{' '}
							to add a view to favorites
						</SidebarText>
					) : (
						favoriteItems.map((item: WorkspaceApiView) => (
							<ViewItem
								view={item}
								key={JSON.stringify({ type: item.type, id: item.id })}
							/>
						))
					)}
				</SidebarSection>

				{canManageViews(collaborator) && (
					<SidebarSection
						Header={(props) => <SectionTitle title="Drafts" {...props} />}
						initiallyExpanded
						localStorageKey={
							LOCAL_STORAGE_KEY.DRAFT_VIEWS_SIDEBAR_SECTION_EXPANDED
						}
						topLevel
					>
						{draftItems.map((item: WorkspaceApiView) => (
							<ViewItem
								view={item}
								key={JSON.stringify({ type: item.type, id: item.id })}
							/>
						))}
					</SidebarSection>
				)}

				<SidebarSection
					Header={(props) => <SectionTitle title="Published" {...props} />}
					initiallyExpanded
					localStorageKey={
						LOCAL_STORAGE_KEY.PUBLISHED_VIEWS_SIDEBAR_SECTION_EXPANDED
					}
					topLevel
				>
					{publishedItems.map((item: WorkspaceApiView) => (
						<ViewItem
							view={item}
							key={JSON.stringify({ type: item.type, id: item.id })}
						/>
					))}
				</SidebarSection>
			</SidebarContainer>
		</Sidebar>
	);
}
