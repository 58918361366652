import { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { updateUserAvatar } from 'reduxState/thunks/updateUserAvatar';

interface Props {
	userId: number;
}

const AvatarForm = ({ userId }: Props) => {
	const [loading, setLoading] = useState(false);
	const [avatar, setAvatar] = useState<File | null>(null);
	const dispatch = useDispatch();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] ?? null;

		setAvatar(file);
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);
		await dispatch(updateUserAvatar({ userId: userId, avatar }));
		setLoading(false);
	};

	return (
		<>
			{!loading && (
				<Form onSubmit={handleSubmit}>
					<Input
						name="avatar"
						type="file"
						label="Profile photo"
						helpText="Must be a .png or .jpg file under 2 MB."
						onChange={handleChange}
					/>
					<FormButton loading={loading ? true : undefined}>
						Upload photo
					</FormButton>
				</Form>
			)}
		</>
	);
};
export default AvatarForm;
