import { getDefaultHeaders } from 'api/utils';

export function setPrimaryAttribute({
	dataSourceId,
	schemaName,
	tableName,
	columnName,
}: {
	dataSourceId: number;
	schemaName: string;
	tableName: string;
	columnName: string;
}) {
	return fetch(
		`/api/primary-attributes/${dataSourceId}/${schemaName}/${tableName}`,
		{
			method: 'PUT',
			headers: getDefaultHeaders(),
			body: JSON.stringify({
				columnName,
			}),
		}
	);
}
