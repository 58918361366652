import styled from 'styled-components';

import Button from 'components/Button';
import { ReactComponent as JumpToIcon } from 'images/icons/jumpTo.svg';
import { media } from 'utils/styles';

const StyledButton = styled(Button)`
	${media.mobile`
		display: none;
	`}
`;

export function JumpToButton() {
	const handleOpenSearch = () => {
		window.CommandBar?.open();
	};

	return (
		<StyledButton
			onClick={handleOpenSearch}
			icon={<JumpToIcon />}
			outlined
			value="Jump to"
			keyboardShortcut={['Ctrl', 'K']}
		/>
	);
}
