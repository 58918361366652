import React, { useState } from 'react';
import styled from 'styled-components';
import { DecodedValueMap, SetQuery } from 'use-query-params';

import {
	verifyDatabaseNameAvailable,
	VerifyDatabaseNameAvailableResponse,
} from 'api/verifyDatabaseNameAvailable';
import Card from 'components/Card';
import Section from 'components/Section';
import { Input } from 'components/fields/Input';
import { DataSourceValue } from 'typings/types';
import { getVerboseDataSource } from 'utils/verboseNames';

import {
	databaseSteps,
	DatabaseSteps as DataSourceSteps,
	QueryParamsConfig,
} from '.';
import { handleNextNavigation } from './NavigationButtons';
import { NavigationButtons } from './NavigationButtons';

const Text = styled.p`
	cursor: default;
`;

const InputWrapper = styled.div`
	margin-top: 1rem;
`;

interface Props {
	setQuery: SetQuery<QueryParamsConfig>;
	dataSource: DecodedValueMap<QueryParamsConfig>['dataSource'];
	dataSourceName: DecodedValueMap<QueryParamsConfig>['dataSourceName'];
	databaseStep: keyof DataSourceSteps;
	workspaceId: number;
	onNext: () => void;
}

export function NameDataSourceSection({
	setQuery,
	dataSource,
	databaseStep,
	dataSourceName,
	workspaceId,
	onNext,
}: Props) {
	const [dataSourceNameErrors, setDataSourceNameErrors] = useState<string[]>(
		[]
	);

	const validateDataSourceName = (value: string) => {
		const errors = [];
		if (value.trim().length === 0) {
			errors.push('Data source display name cannot be empty');
		}
		setDataSourceNameErrors(errors);
	};

	const handleChangeDataSourceName = (value: string) => {
		// Uses the 'replaceIn' strategy so the back button behaviour doesn't get affected
		setQuery({ dataSourceName: value }, 'replaceIn');
		validateDataSourceName(value);
	};

	// Validate that the data source name is not already used
	const handleNext = () => {
		const stepValue = databaseSteps[databaseStep];
		if (dataSourceName) {
			verifyDatabaseNameAvailable(workspaceId, dataSourceName).then((res) => {
				if (res.ok) {
					res.json().then((val: VerifyDatabaseNameAvailableResponse) => {
						if (!val) {
							setDataSourceNameErrors([
								'Data source display name already used',
							]);
						} else {
							// Set the query using the default 'pushIn' strategy so that the user can
							// go back properly
							setQuery({ dataSourceName });
							if (onNext) {
								onNext();
							} else {
								handleNextNavigation({
									setQuery,
									nextStep: stepValue.nextStep,
								});
							}
						}
					});
				}
			});
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleNext();
		}
	};
	return (
		<Section
			title={`What do you want to call your ${getVerboseDataSource(
				dataSource as DataSourceValue
			)} data source?`}
		>
			<Card>
				<Text>
					This is what everyone in your workspace will see as well. You can
					always change this later.
				</Text>
				<InputWrapper>
					<Input
						name="name"
						type="text"
						label="Display name"
						labelWidth="auto"
						value={dataSourceName ?? ''}
						errors={dataSourceNameErrors}
						onChange={(e) => handleChangeDataSourceName(e.target.value)}
						onKeyDown={handleKeyDown}
						autoFocus
					/>
				</InputWrapper>
			</Card>
			<NavigationButtons
				step={databaseStep}
				setQuery={setQuery}
				onNext={handleNext}
				disabled={
					dataSourceName === null ||
					dataSourceName === undefined ||
					dataSourceName.length === 0 ||
					dataSourceNameErrors.length > 0
				}
			/>
		</Section>
	);
}
