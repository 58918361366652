import { lighten } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { KeyboardShortcut } from 'components/KeyboardShortcut';
import { Tooltip } from 'components/Tooltip';
import { danger, neutral, onPrimary, primary } from 'utils/colors';
import styles from 'utils/styles';

const Icon = styled.div<{
	danger?: boolean;
	primary?: boolean;
	primaryIcon?: boolean;
	disabled?: boolean;
	iconOnly?: boolean;
}>`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${(props) => {
			if (props.primaryIcon) {
				return primary.text;
			} else if (props.danger) {
				return onPrimary;
			} else if (props.primary) {
				return onPrimary;
			} else {
				return neutral[1];
			}
		}};
	}
`;

const IconLeft = styled(Icon)`
	margin-right: ${({ iconOnly }) => (iconOnly ? '0' : '0.5rem')};
`;

const IconRight = styled(Icon)`
	margin-left: 0.5rem;
`;

type ButtonRootProps = Pick<
	ButtonProps,
	'disabled' | 'fullWidth' | 'size' | 'active'
> & {
	$red: ButtonProps['danger'];
	$blue: ButtonProps['primary'];
	outlined: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[propName: string]: any;
};

const ButtonRoot = styled.button.withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) =>
		!['fullWidth', 'active', '$red', '$blue'].includes(prop as string) &&
		defaultValidatorFn(prop),
})<ButtonRootProps>`
	display: inline-flex;
	align-items: center;
	width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
	margin: 0;
	padding: ${(props) => {
		switch (props.size) {
			case 'large':
				return '0.75rem 1.25rem';
			case 'medium':
			default:
				return '0.5rem 0.625rem';
			case 'small':
				return '0.25rem 0.25rem';
		}
	}};
	font-size: ${(props) => {
		switch (props.size) {
			case 'medium':
			case 'large':
			default:
				return '1rem';
			case 'small':
				return '0.875rem';
		}
	}};
	font-weight: 400;
	overflow-wrap: break-word;
	border: 1px solid transparent;
	border-radius: ${styles.global.borderRadius};
	color: ${(props) => {
		if (props.$red) {
			if (props.outlined) {
				return danger;
			} else {
				return onPrimary;
			}
		} else if (props.$blue) {
			return onPrimary;
		} else {
			return neutral[1];
		}
	}};
	background: ${(props) => {
		if (props.outlined) {
			return 'transparent';
		}
		if (props.active) {
			return neutral[4];
		} else if (props.$red) {
			return styles.colours.logo.red;
		} else if (props.$blue) {
			return primary[1];
		} else {
			return 'transparent';
		}
	}};
	opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

	&:hover {
		cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	}

	${(props) =>
		props.outlined &&
		css`
			border-color: ${() => {
				if (props.$red) {
					return danger;
				} else if (props.$blue) {
					return primary[1];
				} else {
					return neutral[4];
				}
			}};
		`};

	${(props) =>
		!props.disabled &&
		css`
			&:hover {
				color: ${() => {
					if (props.$red) {
						if (props.outlined) {
							return danger;
						} else {
							return onPrimary;
						}
					} else if (props.$blue) {
						return onPrimary;
					} else {
						return neutral[1];
					}
				}};
				background: ${() => {
					if (props.outlined) {
						return neutral[5];
					}
					if (props.$red) {
						return lighten(0.1, styles.colours.logo.red);
					} else if (props.$blue) {
						return primary[3];
					} else {
						return neutral[5];
					}
				}};
			}
		`};
`;

interface ButtonProps {
	className?: string;
	as?: string | React.ReactNode;
	href?: string;
	to?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	active?: boolean;
	danger?: boolean;
	primary?: boolean;
	primaryIcon?: boolean;
	keyboardShortcut?: string[];
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	icon?: React.ReactNode;
	iconRight?: React.ReactNode;
	value?: string;
	/** Value appears on hover as a Radix UI tooltip */
	title?: React.ReactNode;
	target?: HTMLAnchorElement['target'];
	rel?: string;
	children?: React.ReactNode;
	/** Used by Radix UI tooltip, do not manually set */
	'data-state'?: string;
	/** Used by Radix UI tooltip, do not manually set */
	'aria-describedby'?: string;
	form?: string;
	size?: 'small' | 'medium' | 'large';
	outlined?: boolean;
}

const Button = React.forwardRef(
	(
		{
			className,
			as,
			href,
			to,
			disabled,
			fullWidth,
			active,
			danger,
			primary,
			primaryIcon,
			keyboardShortcut,
			onClick,
			icon,
			iconRight,
			value,
			title,
			target,
			rel,
			'data-state': dataState,
			'aria-describedby': describedBy,
			children,
			form,
			size = 'medium',
			outlined = false,
		}: ButtonProps,
		ref
	) => {
		const renderButtonComponent = () => (
			<ButtonRoot
				ref={ref}
				as={as ?? href ? 'a' : to ? Link : undefined}
				href={disabled ? undefined : href}
				to={disabled ? undefined : to}
				disabled={disabled}
				fullWidth={fullWidth}
				active={active}
				$red={danger}
				$blue={primary}
				onClick={disabled ? undefined : onClick}
				target={target}
				rel={rel}
				className={className}
				data-state={dataState}
				aria-describedby={describedBy}
				form={form}
				size={size}
				outlined={outlined}
			>
				{icon && (
					<IconLeft
						danger={danger}
						primary={primary}
						primaryIcon={primaryIcon}
						disabled={disabled}
						iconOnly={!value && !children}
					>
						{icon}
					</IconLeft>
				)}
				{value ?? children}
				{iconRight && (
					<IconRight
						danger={danger}
						primary={primary}
						primaryIcon={primaryIcon}
						disabled={disabled}
					>
						{iconRight}
					</IconRight>
				)}
				{keyboardShortcut && <KeyboardShortcut keys={keyboardShortcut} />}
			</ButtonRoot>
		);

		if (title) {
			return <Tooltip value={title}>{renderButtonComponent()}</Tooltip>;
		}

		return renderButtonComponent();
	}
);

Button.displayName = 'Button';

export default Button;
