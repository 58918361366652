export function loginWithGoogle(googleIdToken: string) {
	return fetch('/auth/login-with-google', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			credential: googleIdToken,
		}),
	});
}
