import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { oauthSlack } from 'api/oauthSlack';
import _RouteContainer from 'components/RouteContainer';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import { neutral } from 'utils/colors';
import { media } from 'utils/styles';

const RouteContainer = styled(_RouteContainer)`
	flex-direction: column;
`;

const Container = styled(SectionContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 40rem;
	color: ${neutral[1]};

	${media.desktop`
		padding-top: 2rem;
		padding-bottom: 2rem;
	`};

	${media.tabletUp`
		min-height: 100vh;
	`};

	${media.mobile`
		min-height: 100vh;
		padding-top: 0;
		padding-bottom: 1rem;
	`};
`;

function CreateAccountRoute() {
	const location = useLocation();
	const viewId = Number(new URLSearchParams(location.search).get('ViewId'));
	const code = new URLSearchParams(location.search).get('code');

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const authenticateSlack = async () => {
			const response = await oauthSlack(viewId, code);

			if (!response.ok) {
				// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
				setError(await response.text());
			} else {
				window.close();
			}

			setLoading(false);
		};

		authenticateSlack();
	}, [viewId, code]);

	return (
		<RouteContainer>
			<Container>
				{loading ? (
					<Spinner />
				) : error ? (
					error
				) : (
					'Successfully connected to Slack! You can now close this window and select "Finish configuration" in Basedash.'
				)}
			</Container>
		</RouteContainer>
	);
}

export default CreateAccountRoute;
