import styled from 'styled-components';
import { SetQuery } from 'use-query-params';
import { useSnapshot } from 'valtio';

import Card from 'components/Card';
import { CopyToClipboardInput } from 'components/CopyToClipboardInput';
import { DataSourceInstructions } from 'components/DataSourceInstructions';
import Section from 'components/Section';
import TextLink from 'components/TextLink';
import { HostingProvider } from 'typings/types';
import { bodyBackground, neutral } from 'utils/colors';
import styles from 'utils/styles';
import { state } from 'valtioState';

import { DatabaseSteps as DataSourceSteps, QueryParamsConfig } from '.';
import { HostingProviderDashboardButton } from '../../HostingProviderDashboardButton';
import { NavigationButtons } from './NavigationButtons';

const Paragraph = styled.p`
	cursor: default;

	p + & {
		margin-top: 1rem;
	}
`;

const CodeSnippet = styled.span`
	margin: 0 1px;
	padding: 3px;
	background: ${bodyBackground};
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	font-family: ${styles.text.fontFamilyMonospace};
	cursor: text;
`;

const CodeBlock = styled.p`
	padding: 1rem;
	background: ${bodyBackground};
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	font-family: ${styles.text.fontFamilyMonospace};

	p + & {
		margin-top: 1rem;
	}
`;

const Text = styled.p`
	cursor: default;
`;

const InputWrapper = styled.div`
	margin-top: 2rem;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;
`;

const StyledDataSourceInstructions = styled(DataSourceInstructions)`
	margin-top: 2rem;
`;

interface TrustedSourceSetupSectionProps {
	setQuery: SetQuery<QueryParamsConfig>;
	databaseStep: keyof DataSourceSteps;
	hostingProvider: HostingProvider | null;
}

export function TrustedSourceSetupSection({
	setQuery,
	databaseStep,
	hostingProvider,
}: TrustedSourceSetupSectionProps) {
	const snap = useSnapshot(state);
	if (hostingProvider?.name === 'localhost') {
		return (
			<Section title="Open connection with Ngrok">
				<Card>
					<Paragraph>
						Basedash supports connecting to local databases through Ngrok. Ngrok
						is a third-party CLI that lets you open access of a local port to
						the internet. It offers free and paid plans.
					</Paragraph>
					<Paragraph>
						To get started,{' '}
						<TextLink
							as="a"
							href="https://ngrok.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							sign up for Ngrok
						</TextLink>{' '}
						and follow the instructions to download and set up the CLI.
					</Paragraph>
					<Paragraph>
						Once Ngrok is installed, run the following command, replacing{' '}
						<CodeSnippet>5432</CodeSnippet> with the port your local database is
						running on:
					</Paragraph>
					<CodeBlock>ngrok tcp 5432</CodeBlock>
					<Paragraph>
						Running this will display some values. Look for a line that looks
						like the following:
					</Paragraph>
					<CodeBlock>
						Forwarding tcp://4.tcp.ngrok.io:10220 -&gt; localhost:5432
					</CodeBlock>
					<Paragraph>
						The two values to note are the host (
						<CodeSnippet>4.tcp.ngrok.io</CodeSnippet> in this example) and the
						port (<CodeSnippet>10220</CodeSnippet> in this example). You'll need
						these two values to connect your database on the next step.
					</Paragraph>
					<Paragraph>
						Note that you will need to keep the Ngrok process running while
						using Basedash.
					</Paragraph>
				</Card>

				<NavigationButtons step={databaseStep} setQuery={setQuery} />
			</Section>
		);
	}

	return (
		<Section title="Add Basedash as a trusted source">
			<Card>
				{snap.config?.isSelfHosted ? (
					<Text>
						Add the your Basedash server IP address as a trusted source for the
						database you're trying to connect in order to allow connections from
						Basedash.
					</Text>
				) : (
					<>
						<Text>
							Add the Basedash IP address below as a trusted source for your
							database in order to give permission to Basedash to connect.
						</Text>
						<InputWrapper>
							<CopyToClipboardInput
								name="trustedSourceIpAddress"
								label={
									hostingProvider?.trustedSourceLabel
										? hostingProvider.trustedSourceLabel
										: 'Trusted source IP address'
								}
								value="138.197.164.114"
								autoFocus
							/>
						</InputWrapper>
					</>
				)}

				{(hostingProvider?.trustedSourceInstructions ||
					hostingProvider?.trustedSourceVideoUrl) && (
					<StyledDataSourceInstructions
						title={`How to add a trusted source`}
						instructions={hostingProvider.trustedSourceInstructions}
						videoUrl={hostingProvider?.trustedSourceVideoUrl}
					/>
				)}

				{hostingProvider?.dashboardUrl && (
					<ButtonContainer>
						<HostingProviderDashboardButton
							dashboardUrl={hostingProvider.dashboardUrl}
							logoLight={hostingProvider.logoLight}
							logoDark={hostingProvider.logoDark}
							name={hostingProvider.verboseName}
						/>
					</ButtonContainer>
				)}
			</Card>

			<NavigationButtons step={databaseStep} setQuery={setQuery} />
		</Section>
	);
}
