import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { logout } from 'reduxState/actions';

export type WorkspaceIdState = number | null;

const initialState: WorkspaceIdState = null;

const workspaceIdSlice = createSlice({
	name: 'workspaceId',
	initialState: initialState as WorkspaceIdState,
	reducers: {
		setWorkspaceId(_state, action: PayloadAction<number>) {
			return action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { setWorkspaceId } = workspaceIdSlice.actions;

export default workspaceIdSlice.reducer;
