import { useQuery, UseQueryOptions } from 'react-query';

import {
	FetchWorkspaceInvitationParams,
	FetchWorkspaceInvitationsResponse,
	getMemberInvitations,
} from 'api/getMemberInvitations';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';

export const useApiMemberInvitations = (
	params: FetchWorkspaceInvitationParams,
	options?: UseQueryOptions<
		unknown,
		string,
		FetchWorkspaceInvitationsResponse,
		[string, FetchWorkspaceInvitationParams]
	>
) =>
	useQuery<
		unknown,
		string,
		FetchWorkspaceInvitationsResponse,
		[string, FetchWorkspaceInvitationParams]
	>(
		[REACT_QUERY_CACHE_KEY.MEMBER_INVITATIONS, params],
		async ({ queryKey }) => {
			const [_key, workspaceId] = queryKey;

			const response = await getMemberInvitations(workspaceId);
			if (!response.ok) {
				throw new ApiError('Network response was not ok');
			}
			return response.json();
		},
		options
	);
