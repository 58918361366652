import { getDefaultHeaders } from 'api/utils';

export type UpdateSlackAlertSubscriptionParams = {
	viewId: number;
	tracker: { createdAtColumn?: string; enabled?: boolean; frequency?: string };
};

export function updateSlackAlertSubscription({
	viewId,
	tracker,
}: UpdateSlackAlertSubscriptionParams) {
	return fetch(`/api/views/${viewId}/slack-alert-subscription`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			tracker,
		}),
	});
}
