import { Attribute, ColumnType } from 'typings/models';
import { ObjectRecordId, ApiForeignKey, CellRecord } from 'typings/serverTypes';

export function recordIdsAreEqual(
	recordId1: ObjectRecordId,
	recordId2: ObjectRecordId
) {
	if (
		recordId1.schemaName !== recordId2.schemaName ||
		recordId1.tableName !== recordId2.tableName
	) {
		return false;
	}

	if (Object.keys(recordId1.pk).length !== Object.keys(recordId2.pk).length) {
		return false;
	}

	return Object.keys(recordId1.pk).every(
		// eslint-disable-next-line eqeqeq
		(column) => recordId1.pk[column] == recordId2.pk[column]
	);
}

export function getRecordId({
	schemaName,
	tableName,
	record,
	primaryKeyAttributes,
}: {
	schemaName: string | undefined | null;
	tableName: string | undefined | null;
	record: CellRecord;
	primaryKeyAttributes: Attribute[];
}): ObjectRecordId | null {
	if (!record || schemaName == null || tableName == null) {
		return null;
	}

	const recordId: ObjectRecordId = {
		schemaName,
		tableName,
		pk: {},
	};

	if (primaryKeyAttributes.length > 0) {
		for (const attribute of primaryKeyAttributes) {
			recordId.pk[attribute.attributeName] = record[attribute.id];
		}
	} else {
		// If there are no primary key attributes, we use the entire record as the
		// value for pk in order to uniquely identify the record.
		// It's important to be able to identify records in order to give them
		// a human friendly name (see getVerboseRecordId in RecentActivity.tsx)
		// or when comparing records (see `getSelectedCellDetails` which is used
		// figure out if a cell is selected in realtime).
		recordId.pk = record;
	}
	return recordId;
}

export function getVerbosePrimaryKey(recordId: ObjectRecordId) {
	return Object.keys(recordId.pk)
		.map((columnName) => `${columnName}=${recordId.pk[columnName]}`)
		.join('&');
}

export function getAttributeTypeFromDbColumnType({
	dbColumnType,
	isForeignKey,
}: {
	dbColumnType: string | undefined | null;
	isForeignKey?: boolean;
}): ColumnType {
	if (dbColumnType === undefined || dbColumnType === null) {
		return 'TEXT';
	}
	if (isForeignKey) {
		return 'FOREIGN_KEY';
	}
	const numericTypes = [
		'tinyint',
		'smallint',
		'int',
		'integer',
		'mediumint',
		'bigint',
		'decimal',
		'double',
		'float',
		'numeric',
		'real',
		'double precision',
		'smallserial',
		'serial',
		'bigserial',
		'money',
	];

	const datetimeTypes = ['datetime', 'timestamp', 'timestamptz'];

	const dateTypes = ['date'];

	const timeTypes = ['time'];

	const booleanTypes = ['boolean', 'bit'];

	const jsonTypes = ['json', 'jsonb'];

	const arrayTypes = ['array'];

	const lowerCasedDbColumnType = dbColumnType.toLowerCase();

	if (numericTypes.includes(lowerCasedDbColumnType.split('(')[0])) {
		return 'NUMBER';
	} else if (datetimeTypes.includes(lowerCasedDbColumnType.split(' ')[0])) {
		return 'DATETIME';
	} else if (dateTypes.includes(lowerCasedDbColumnType.split(' ')[0])) {
		return 'DATE';
	} else if (timeTypes.includes(lowerCasedDbColumnType.split(' ')[0])) {
		return 'TIME';
	} else if (booleanTypes.includes(lowerCasedDbColumnType)) {
		return 'BOOLEAN';
	} else if (jsonTypes.includes(lowerCasedDbColumnType)) {
		return 'JSON';
	} else if (arrayTypes.includes(lowerCasedDbColumnType)) {
		return 'ARRAY';
	} else {
		return 'TEXT';
	}
}

export function getForeignKey(
	columnName: string,
	foreignKeys: ApiForeignKey[]
) {
	return foreignKeys.find(
		(foreignKey) => foreignKey.baseColumnName === columnName
	);
}
