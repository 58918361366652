import { getDefaultHeaders } from 'api/utils';
import { ApiRunQueryResponse } from 'typings/serverTypes';

export type RunQueryParams = {
	viewId: number;
	pageNumber: number | null;
	searchQuery: string | null;
};
export type RunQueryResponse = ApiRunQueryResponse;

export function runQuery({ viewId, pageNumber, searchQuery }: RunQueryParams) {
	return fetch(`/api/views/${viewId}/run`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({ pageNumber, searchQuery }),
	});
}
