import styled from 'styled-components';

import { StyledFormAttributeValue } from 'components/StyledFormAttributeValue';
import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { background, neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

export const StyledLinkButton = styled.button`
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
	border: 1px solid ${neutral[4]};
	background: ${background[1]};
	border-radius: ${styles.global.borderRadius};
	color: ${neutral[1]};
	text-align: center;
	box-shadow: ${styles.shadows[900]};
	cursor: pointer;
	z-index: 1;
	outline: 0;

	&:focus,
	*:focus &,
	${StyledTableCellContainer}:hover &,
	${StyledFormAttributeValue}:hover & {
		opacity: 1;
	}

	&:focus {
		box-shadow: 0 0 0 2px ${primary[1]};
	}

	&:hover::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: ${neutral[4]};
		pointer-events: none;
	}
`;
