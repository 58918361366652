import * as localForage from 'localforage';
import { proxy, subscribe } from 'valtio';

import { DataSource, Schema } from 'typings/models';
import {
	ApiAttribute,
	ApiTable,
	ApiView,
	AppConfig,
	CellRecord,
	Filter,
	Sort,
	SqlDatabaseDetailsBasedOnUserRole,
	StringRecordId,
} from 'typings/serverTypes';
import { LOCAL_STORAGE_KEY } from 'utils/constants';

localForage.config();

export type ValtioState = {
	hasLoadedStateFromPersistentStorage: boolean;
	// Ideally this is part of a workspace entity in the store, but we currently
	// don't save workspaces as entities in the store
	hasLoadedWorkspaceSettings: boolean;
	config: AppConfig | undefined;
	tableNamesToIdMap: { [schemaAndTableName: string]: number | undefined };
	attributeNamesToIdMap: {
		[schemaAndTableAndAttributeName: string]: number | undefined;
	};
	entities: {
		sqlDatabases: {
			loading: boolean;
			ids: number[];
			byId: {
				[id: number]: SqlDatabaseDetailsBasedOnUserRole | undefined;
			};
		};
		dataSources: {
			ids: number[];
			loading: boolean;
			byId: {
				[id: number]:
					| (DataSource & {
							healthy?: boolean;
							loading?: boolean;
							sqlDatabase?: { id: number };
					  })
					| undefined;
			};
		};
		schemas: {
			loading: boolean;
			ids: number[];
			byId: {
				[id: number]: Schema | undefined;
			};
		};
		tables: {
			loading: boolean;
			ids: number[];
			byId: {
				[id: number]:
					| (ApiTable & {
							sorts?: Sort[];
							filters?: Filter[];
					  })
					| undefined;
			};
		};
		attributes: {
			loading: boolean;
			ids: number[];
			byId: {
				[id: number]: ApiAttribute | undefined;
			};
		};
		records: {
			byId: {
				[id: StringRecordId]: (CellRecord & { id: StringRecordId }) | undefined;
			};
		};
		views: {
			byId: {
				[id: number]:
					| {
							loading: boolean;
							error: string | null;
							data?: ApiView & {
								staleRecords: boolean;
							};
					  }
					| undefined;
			};
		};
	};
};

const initialTableNamesToIdMap = {};
const initialAttributeNamesToIdMap = {};
const initialEntities = {
	dataSources: {
		ids: [],
		loading: false,
		byId: {},
	},
	sqlDatabases: {
		ids: [],
		loading: false,
		byId: {},
	},
	schemas: {
		ids: [],
		loading: false,
		byId: {},
	},
	tables: {
		ids: [],
		loading: false,
		byId: {},
	},
	attributes: {
		ids: [],
		loading: false,
		byId: {},
	},
	records: {
		byId: {},
	},
	views: {
		byId: {},
	},
};

export const state = proxy<ValtioState>({
	hasLoadedStateFromPersistentStorage: false,
	hasLoadedWorkspaceSettings: false,
	tableNamesToIdMap: initialTableNamesToIdMap,
	attributeNamesToIdMap: initialAttributeNamesToIdMap,
	entities: initialEntities,
	config: undefined,
});

const persistentStorageEntities: {
	persistentStorageKey: LOCAL_STORAGE_KEY;
	valtioKey: keyof ValtioState;
	defaultValue:
		| typeof initialEntities
		| typeof initialAttributeNamesToIdMap
		| typeof initialTableNamesToIdMap;
}[] = [
	{
		persistentStorageKey: LOCAL_STORAGE_KEY.STORE_ENTITIES,
		valtioKey: 'entities',
		defaultValue: initialEntities,
	},
	{
		persistentStorageKey: LOCAL_STORAGE_KEY.STORE_ATTRIBUTE_NAMES_TO_ID_MAP,
		valtioKey: 'attributeNamesToIdMap',
		defaultValue: initialAttributeNamesToIdMap,
	},
	{
		persistentStorageKey: LOCAL_STORAGE_KEY.STORE_TABLE_NAMES_TO_ID_MAP,
		valtioKey: 'tableNamesToIdMap',
		defaultValue: initialTableNamesToIdMap,
	},
];

const loadStateFromPersistentStorage = async () => {
	const persistenStorageResults = await Promise.all(
		persistentStorageEntities.map(
			(entity) =>
				new Promise<string | null>(async (resolve) => {
					const value: string | null = await localForage.getItem(
						entity.persistentStorageKey
					);
					return resolve(value);
				})
		)
	);

	for (let i = 0; i < persistenStorageResults.length; i++) {
		const persistentStorageResult = persistenStorageResults[i];
		const entity = persistentStorageEntities[i];
		let value = entity.defaultValue;
		if (persistentStorageResult) {
			value = JSON.parse(persistentStorageResult);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		state[entity.valtioKey] = value as any;
	}

	state.hasLoadedStateFromPersistentStorage = true;
};

loadStateFromPersistentStorage();

subscribe(state, async () => {
	for (const persistentStorageEntity of persistentStorageEntities) {
		await localForage.setItem(
			persistentStorageEntity.persistentStorageKey,
			JSON.stringify(state[persistentStorageEntity.valtioKey])
		);
	}
});
