export function throwFetchError(response: Response) {
	if (!response.ok) {
		return response
			.clone()
			.json()
			.then((error) => {
				throw error;
			});
	}

	return response;
}
