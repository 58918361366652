import { getDefaultHeaders } from 'api/utils';
import { MemberInvitation } from 'typings/models';

export type FetchWorkspaceInvitationParams = number;
export type FetchWorkspaceInvitationsResponse = MemberInvitation[];

export function getMemberInvitations(
	workspaceId: FetchWorkspaceInvitationParams
) {
	return fetch(`/api/workspaces/${workspaceId}/member-invitations`, {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
