// Note: these colors are duplicated in server/utils/constants.ts
// If you change one, change the other too
// These ones are ordered to optimize for nice colors coming first
import RichTextEditor from 'react-rte';

export const CHART_COLORS = [
	'#FF1640',
	'#00D1FF',
	'#7509FF',
	'#E006B0',
	'#FD8900',
	'#00F4D7',
	'#FFE70B',
	'#00FB37',
	'#FF71CF',
	'#CCEB12',
];

export const pageSize = 100;

export const DATETIME_FORMAT = 'MMM d yyyy, h:mm a';
export const DATE_FORMAT = 'MMM d yyyy';
export const TIME_FORMAT = 'h:mm a';

export enum LOCAL_STORAGE_KEY {
	TOKEN = 'token',
	THEME_MODE = 'mode',
	SIDEBAR_WIDTH = 'sidebarWidth',
	FEATURE_FLAGS = 'featureFlags',
	FAVORITES_SIDEBAR_SECTION_EXPANDED = 'favoritesSidebarSectionExpanded',
	DRAFT_VIEWS_SIDEBAR_SECTION_EXPANDED = 'draftViewsSidebarSectionExpanded',
	PUBLISHED_VIEWS_SIDEBAR_SECTION_EXPANDED = 'publishedViewsSidebarSectionExpanded',

	STORE_ENTITIES = 'entities',
	STORE_TABLE_NAMES_TO_ID_MAP = 'tableNamesToIdMap',
	STORE_ATTRIBUTE_NAMES_TO_ID_MAP = 'attributeNamesToIdMap',

	DATA_SOURCE_EXPANDED = 'dataSourceExpanded',
}

export enum FEATURE_FLAGS {
	ACTIONS = 'actions',
}

export enum CELL_VIEW_TYPE {
	IMAGE = 'IMAGE',
	URL = 'URL',
	RICH_TEXT = 'RICH_TEXT',
	CHECKBOX = 'CHECKBOX',
	JSON = 'JSON',
	DATE = 'DATE',
	ENUM = 'ENUM',
	TEXT = 'TEXT',
	FOREIGN_KEY = 'FOREIGN_KEY',
}

export const richTextToolbarConfig: RichTextEditor['props']['toolbarConfig'] = {
	display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
	INLINE_STYLE_BUTTONS: [
		{ label: 'Bold', style: 'BOLD' },
		{ label: 'Italic', style: 'ITALIC' },
		{ label: 'Underline', style: 'UNDERLINE' },
	],
	BLOCK_TYPE_BUTTONS: [
		{ label: 'UL', style: 'unordered-list-item' },
		{ label: 'OL', style: 'ordered-list-item' },
	],
	BLOCK_TYPE_DROPDOWN: [
		{ label: 'UL', style: 'unordered-list-item' },
		{ label: 'OL', style: 'ordered-list-item' },
	],
};

export const NULL_VISUAL_SYMBOL = 'null';
export const DEFAULT_VISUAL_SYMBOL = 'default';
export const EMPTY_VISUAL_SYMBOL = 'empty';

export const PREVENT_SAVE_DATA_ATTRIBUTE = 'data-save-on-blur';

// This is duplicated in /server/utils/constants.ts
export const RESTRICTED_DOMAINS = [
	'gmail.com',
	'qq.com',
	'hotmail.com',
	'yahoo.com',
	'outlook.com',
	'me.com',
	'protonmail.com',
	'icloud.com',
	'hey.com',
	'live.com',
];

export const DEFAULT_COLUMN_WIDTH = 100;

export const JSON_AND_RICH_TEXT_HEIGHT_IN_REM = 10;
export const JSON_AND_RICH_TEXT_WIDTH_IN_REM = 20;
export const NEW_ROW_DATA_ROW_ATTRIBUTE_VALUE = 'new-row';
export const ONE_REM_IN_PIXELS = 14;

export const MODAL_Z_INDEX = 100;

export type DeepReadonly<T> = {
	readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export enum REACT_QUERY_CACHE_KEY {
	RECORDS = 'records',
	VIEW = 'view',
	WORKSPACE = 'workspace',
	BILLING_INFORMATION = 'billing-information',
	USER = 'user',
	ACTIVITY = 'activity',
	MEMBER_INVITATIONS = 'member-invitations',
}
