import { getDefaultHeaders } from 'api/utils';
import { ObjectRecordId } from 'typings/serverTypes';

export type UpdateRecordParams = {
	tableId: number;
	attributeId: number;
	recordId: ObjectRecordId;
	value: boolean | string | null;
};

export function updateRecord({
	attributeId,
	recordId,
	value,
	tableId,
}: UpdateRecordParams) {
	return fetch(`/api/tables/${tableId}/records`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			recordId,
			attributeId,
			value,
		}),
	});
}

export const updateApiRecord = async (
	variables: Omit<UpdateRecordParams, 'value'> & {
		valueBefore: string | boolean | null;
		valueAfter: string | boolean | null;
		formattedValueBefore: string | boolean | null;
		formattedValueAfter: string | boolean | null;
	}
) => {
	const result = await updateRecord({
		...variables,
		value: variables.valueAfter,
	});

	if (!result.ok) {
		const error = await result.json();
		throw error;
	}
};
