import { FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { updateAcceptedEmailDomain } from 'api/updateAcceptedEmailDomain';
import FormButton from 'components/fields/FormButton';
import { Switch } from 'components/fields/Switch';
import { Form as _Form } from 'components/forms/Form';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { FormStateField } from 'typings/types';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY, RESTRICTED_DOMAINS } from 'utils/constants';
import { toast } from 'utils/toast/toast';

import { Input } from './fields/Input';

const formLabelWidth = 11;

const Form = styled(_Form)`
	max-width: 100%;
	margin: 0;

	> :first-child {
		margin-top: 0;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 2rem;
`;

const StyledFormButton = styled(FormButton)`
	width: auto;
	min-width: 15rem;
	margin-top: 0;
`;

const ItemContainer = styled.div`
	display: flex;
	min-width: 100%;
	align-items: center;
	justify-content: space-between;
	margin: 0;
`;

const Label = styled.label`
	font-size: 0.875rem;
	font-weight: 400;
	color: ${neutral[1]};
	cursor: default;
`;

const StyledInput = styled(Input)`
	margin-top: 1.5rem;
`;
interface WorkspaceAccessProps {
	workspace: ApiWorkspaceDetails;
}

const formatAcceptedEmailDomain = (acceptedEmailDomain: string | null) =>
	acceptedEmailDomain ?? '';

export default function WorkspaceAccess({ workspace }: WorkspaceAccessProps) {
	const queryClient = useQueryClient();

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState<
		FormStateField<'domain', string> &
			FormStateField<'allowDomainBasedAccess', boolean>
	>({
		domain: {
			value: formatAcceptedEmailDomain(workspace.acceptedEmailDomain),
			errors: null,
		},
		allowDomainBasedAccess: {
			value: formatAcceptedEmailDomain(workspace.acceptedEmailDomain) !== '',
			errors: null,
		},
	});

	const checkForErrors = () => {
		const emailPattern = /^[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9-.]+$/;
		const domain = fields.domain.value.trim().toLowerCase();
		const errors: string[] = [];
		let isError = false;

		if (!emailPattern.test(domain)) {
			isError = true;
			errors.push("Domain doesn't follow the expected pattern.");
			setFields({
				...fields,
				domain: {
					value: fields.domain.value,
					errors: errors,
				},
			});
		}

		if (RESTRICTED_DOMAINS.includes(domain)) {
			isError = true;
			errors.push('Email domain is restricted.');
			setFields({
				...fields,
				domain: {
					value: fields.domain.value,
					errors: errors,
				},
			});
		}

		return isError;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = event.target;
		const value = event.target.value;
		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	const handleToggle = async () => {
		// If Accepted Email Domain is set in the database, switching the toggle 'off' will set it to null in the database
		if (workspace.acceptedEmailDomain) {
			setFields({
				domain: {
					value: '',
					errors: null,
				},
				allowDomainBasedAccess: {
					value: false,
					errors: null,
				},
			});
			await updateAcceptedEmailDomain(workspace.id, null)
				.then(() => {
					queryClient.invalidateQueries([
						REACT_QUERY_CACHE_KEY.WORKSPACE,
						workspace.id,
					]);
					toast(`Workspace domain removed.`);
				})
				.catch(() => {
					toast.error(`Problem updating workspace domain.`);
				});
		}
		// If Accepted Email Domain is not set in the database, switching the toggle 'on or off' will simply show or display the input UI to set the domain
		setFields({
			...fields,
			allowDomainBasedAccess: {
				value: !fields.allowDomainBasedAccess.value,
				errors: null,
			},
		});
	};

	const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
		event?.preventDefault();
		if (loading) {
			return;
		}

		if (checkForErrors()) return;
		setLoading(true);
		const acceptedEmailDomain = fields.domain.value.trim().toLowerCase();
		await updateAcceptedEmailDomain(workspace.id, acceptedEmailDomain)
			.then(() => {
				setLoading(false);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspace.id,
				]);
				toast(`Workspace domain updated to ${acceptedEmailDomain}.`);
			})
			.catch(() => {
				setLoading(false);
				toast.error(`Problem updating workspace domain`);
			});
	};

	return (
		<Form onSubmit={handleSubmit}>
			<ItemContainer>
				<Label>Allow domain-based access to this workspace</Label>
				<Switch
					onChange={handleToggle}
					checked={fields.allowDomainBasedAccess.value}
				/>
			</ItemContainer>
			{fields.allowDomainBasedAccess.value && (
				<>
					<StyledInput
						name="domain"
						type="text"
						label={'Domain'}
						placeholder={'basedash.com'}
						value={fields.domain.value}
						errors={fields.domain.errors}
						onChange={handleChange}
						maskContent
						labelWidth={formLabelWidth}
					/>
					<ButtonContainer>
						<StyledFormButton
							loading={loading}
							disabled={
								fields.domain.value.trim() === '' ||
								fields.domain.value ===
									workspace.acceptedEmailDomain?.trim()?.toLowerCase()
							}
						>
							Update domain
						</StyledFormButton>
					</ButtonContainer>
				</>
			)}
		</Form>
	);
}
