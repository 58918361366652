import styled from 'styled-components';

import { FullHeightAndCenteredContent } from 'components/FullHeightAndCenteredContent';
import { InfoText } from 'components/Page';
import TableContent from 'components/TableContent';
import { ViewType } from 'typings/types';
import { onPrimary, primary } from 'utils/colors';
import { media } from 'utils/styles';

const NewViewPageContainer = styled.div`
	position: relative;
	border-top: 1px solid ${primary[1]};
	width: 100%;
	height: 100%;
	overflow: auto;

	${media.tabletUp`
		border-left: 1px solid ${primary[1]};
	`}
`;

const Banner = styled.div`
	width: 100%;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${primary[1]};
	cursor: default;
	color: ${onPrimary};
	flex-shrink: 0;
`;

const Content = styled.div`
	height: calc(100% - 2rem);
`;

interface NewViewPageProps {
	viewType: ViewType;
	tableId: number | null;
	dataSourceId: number | null;
}

export function NewViewPage({
	viewType,
	tableId,
	dataSourceId,
}: NewViewPageProps) {
	if (viewType === ViewType.BASIC && tableId !== null) {
		return (
			<NewViewPageContainer>
				<Banner>Data preview. Confirm to continue.</Banner>

				<Content>
					<TableContent tableId={tableId} isInViewBuilder />
				</Content>
			</NewViewPageContainer>
		);
	}

	if (viewType === ViewType.ADVANCED && dataSourceId !== null) {
		return (
			<NewViewPageContainer>
				<FullHeightAndCenteredContent>
					<InfoText>Confirm data source to create a view</InfoText>
				</FullHeightAndCenteredContent>
			</NewViewPageContainer>
		);
	}

	return null;
}
