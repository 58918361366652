import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/Icon';
import { StyledFormAttributeValue } from 'components/StyledFormAttributeValue';
import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { TableCellContentsText } from 'components/TableCell/TableCellContentsText';
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevronDown.svg';
import { neutral } from 'utils/colors';
import { NULL_VISUAL_SYMBOL } from 'utils/constants';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

const Select = styled.select<{ isFormPanel?: boolean }>`
	display: ${({ isFormPanel }) => (isFormPanel ? 'block' : 'none')};
	width: 100%;
	height: 100%;
	font-size: ${({ isFormPanel }) => (isFormPanel ? '0.875rem' : '1rem')};
	color: ${neutral[1]};
	background: transparent;
	border: none;
	border-radius: 0;
	appearance: none;
	outline: 0;

	${StyledTableCell}:focus-within &&,
	${StyledFormAttributeValue}:focus-within && {
		display: block;
	}
`;

const DropdownIconContainer = styled.div`
	display: none;
	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translateY(-50%);
	pointer-events: none;

	${StyledTableCell}:focus-within &,
	${StyledFormAttributeValue}:focus-within & {
		display: block;
	}
`;

type DropdownTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	| 'setNewRecordValue'
	| 'setValue'
	| 'cellRef'
	| 'rawValue'
	| 'displayValue'
	| 'canEdit'
	| 'id'
	| 'column'
	| 'isFormPanel'
	| 'isAddingNewRecord'
> & {
	allowedValues: string[];
};

export const DropdownTableCellContents = ({
	setNewRecordValue,
	setValue,
	cellRef,
	isFormPanel,
	rawValue,
	displayValue,
	canEdit,
	id,
	column,
	allowedValues,
	isAddingNewRecord,
}: DropdownTableCellContentsProps): React.ReactElement | null => {
	const handleChangeValue = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value || null;
		if (setNewRecordValue !== undefined) {
			setNewRecordValue(value);
		} else if (setValue !== undefined) {
			setValue({ valueAfter: value });
		}

		if (cellRef.current) {
			cellRef.current.focus();
		}
	};

	return (
		<>
			{!isFormPanel && (
				<>
					{rawValue === null ? (
						<StyledNullOrEmptyText hideOnFocus>
							{renderNullOrDefaultValue({
								isAddingNewRecord,
								column: column,
							})}
						</StyledNullOrEmptyText>
					) : (
						<TableCellContentsText
							className="fs-mask highlight-block"
							hideOnFocus
						>
							{displayValue}
						</TableCellContentsText>
					)}
				</>
			)}

			<Select
				className="fs-mask highlight-block"
				value={rawValue === null ? '' : String(rawValue)}
				onChange={handleChangeValue}
				isFormPanel={isFormPanel}
				disabled={!canEdit}
				id={id}
			>
				{/* When adding a new record, we show the null value by default.
				Otherwise, if the user wants to select the first non-null value, it won't
				trigger setNewRecordValue (because it will be selected by default). */}
				{(!column.isRequiredInDb || isAddingNewRecord) && (
					<option value={''}>{NULL_VISUAL_SYMBOL}</option>
				)}

				{allowedValues.map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</Select>

			<DropdownIconContainer>
				<Icon>
					<ChevronDownIcon />
				</Icon>
			</DropdownIconContainer>
		</>
	);
};
