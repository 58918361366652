import styled from 'styled-components';

import Button from 'components/Button';
import { Icon } from 'components/Icon';
import { useTheme } from 'components/providers/ThemeProvider';
import { ReactComponent as ExternalLinkIcon } from 'images/icons/externalLink.svg';
import { neutral } from 'utils/colors';

const ButtonLogo = styled.img`
	height: 0.75rem;
	margin-right: 0.5rem;
`;

const StyledButtonPrimary = styled(Button)`
	border: 1px solid ${neutral[4]};
`;

const ExternalLinkIconWrapper = styled(Icon)`
	margin-left: 0.5rem;
`;

interface Props {
	dashboardUrl: string;
	logoDark: string | null;
	logoLight: string;
	name: string;
	className?: string;
}

function HostingProviderDashboardButton({
	dashboardUrl,
	logoLight,
	logoDark,
	name,
	className,
}: Props) {
	const theme = useTheme();
	return (
		<StyledButtonPrimary
			href={dashboardUrl}
			target="_blank"
			className={className}
		>
			<ButtonLogo
				src={theme.themeMode === 'dark' && logoDark ? logoDark : logoLight}
				alt={name}
			/>
			{name}
			<ExternalLinkIconWrapper>
				<ExternalLinkIcon />
			</ExternalLinkIconWrapper>
		</StyledButtonPrimary>
	);
}

export { HostingProviderDashboardButton };
