import { Attribute } from 'typings/models';
import { getPrimaryAttributeFormattedAttributeId } from 'utils/getPrimaryAttributeFormattedAttributeId';

/**
 *   If the record value is for a primary attribute, then the attribute ID is a combination of the base column
 *   attribute ID and the foreign column attribute ID
 *
 * @param primaryAttributeAttribute
 * @param attributeId
 */
export function getFormattedAttributeId({
	primaryAttributeAttribute,
	attributeId,
}: {
	primaryAttributeAttribute?: Attribute | undefined | null;
	attributeId: number | string;
}) {
	return primaryAttributeAttribute
		? getPrimaryAttributeFormattedAttributeId(
				attributeId,
				primaryAttributeAttribute
		  )
		: attributeId;
}
