import styled from 'styled-components';
import { SetQuery } from 'use-query-params';

import Button from 'components/Button';

import {
	AddDataSourceStep,
	databaseSteps,
	DatabaseSteps,
	QueryParamPerStep,
	QueryParamsConfig,
} from '.';

const NavigationButton = styled(Button)`
	margin-top: 1rem;
	min-width: 15rem;
	justify-content: center;
	visibility: ${(props: { hidden?: boolean }) =>
		props.hidden ? 'hidden' : 'visible'};
`;

const StyledNavigationButtons = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const handleNextNavigation = ({
	nextStep,
	setQuery,
	value = null,
}: {
	nextStep: AddDataSourceStep | undefined;
	setQuery: SetQuery<QueryParamsConfig>;
	value?: null | string;
}) => {
	if (nextStep !== undefined) {
		setQuery({
			[QueryParamPerStep[nextStep]]: value,
		});
	}
};

interface NavigationButtonsProps {
	disabled?: boolean;
	step: keyof DatabaseSteps;
	/** Callback used to update current step */
	setQuery: SetQuery<QueryParamsConfig>;
	onNext?: () => void;
}

export const NavigationButtons = ({
	disabled,
	step,
	setQuery,
	onNext,
}: NavigationButtonsProps) => {
	const stepValue = databaseSteps[step];
	const handleNext = () => {
		if (onNext) {
			return onNext();
		}
		handleNextNavigation({
			setQuery,
			nextStep: stepValue.nextStep,
		});
	};
	return (
		<StyledNavigationButtons>
			<NavigationButton
				primary
				disabled={disabled}
				hidden={stepValue.nextStep === undefined}
				onClick={handleNext}
			>
				Next
			</NavigationButton>
		</StyledNavigationButtons>
	);
};
