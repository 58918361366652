import React, { CSSProperties } from 'react';
import { EditorValue } from 'react-rte';
import { useVirtual } from 'react-virtual';
import styled, { css } from 'styled-components';

import { ForeignKeyPopover } from 'components/ForeignKeyPopover';
import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { EditableTableCellContents } from 'components/TableCell/EditableTableCellContents';
import { PrimaryAttribute } from 'typings/models';
import { ApiRecordAttribute } from 'typings/serverTypes';
import {
	TableData__Table,
	TableData__View,
	ExtractedTableData,
	ViewType,
} from 'typings/types';
import { background, neutral, primary } from 'utils/colors';
import {
	CELL_VIEW_TYPE,
	NEW_ROW_DATA_ROW_ATTRIBUTE_VALUE,
} from 'utils/constants';
import styles from 'utils/styles';
import { useNewRecordValue } from 'utils/useNewRecordValue';

const StyledNewRecord = styled.div`
	position: relative;
	padding: 0;
	color: ${neutral[1]};
	background: ${background[1]};
	text-align: left;
	box-shadow: inset -1px -1px 0 0 ${neutral[4]};
	flex-shrink: 0;

	&:focus,
	&:focus-within {
		outline: ${primary[1]} solid 2px;
		outline-offset: -2px;
	}
`;

const CellInput = styled.input<{ rowHeight: number }>`
	width: 100%;
	${(props) => css`
		height: calc(
			${styles.table.cell.paddingVertical} * 2 + ${styles.table.row.lineHeight} *
				${props.rowHeight}
		);
	`}
	padding: ${styles.table.cell.padding};
	color: ${neutral[1]};
	font-weight: 400;
	background: transparent;
	overflow: hidden;
	white-space: nowrap;

	&:focus {
		outline: ${primary[1]} solid 2px;
		outline-offset: -2px;
	}
`;

type Props = {
	value: string | boolean | null | Date;
	setValue: (value: string | boolean | null | Date) => void;
	data: TableData__View<ViewType.BASIC> | TableData__Table;
	extractedTableData: ExtractedTableData;
	errors: string[] | null;
	column: ApiRecordAttribute;
	primaryAttributes: PrimaryAttribute[];
	/** Used to add the data-column attribute to the cell */
	index: number;
	style: CSSProperties;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
};

const NewRecordCell = React.memo(
	({
		value,
		setValue,
		data,
		extractedTableData,
		errors,
		column,
		primaryAttributes,
		scrollToRowIndex,
		scrollToColumnIndex,
		index,
		style,
	}: Props) => {
		const {
			rowHeight,
			primaryAttribute,
			isEditing,
			inputValue,
			handleChange,
			handleBlur,
			handleDoubleClick,
			cellRef,
			cellViewType,
			foreignKey,
			isDropdownOpen,
			setIsDropdownOpen,
			handleKeyDown,
			tableId,
			databaseId,
			rawValue,
			displayValue,
			handleChangeDate,
			canEdit,
			checked,
			toggleCheckbox,
			dataSource,
			attributeType,
			columnEnumValues,
		} = useNewRecordValue({
			attribute: column,
			extractedTableData: extractedTableData,
			primaryAttributes,
			setNewRecordValue: setValue,
			newRecordValue: value,
			scrollToRowIndex,
			scrollToColumnIndex,
		});

		const renderValue = () => {
			if (
				isEditing &&
				cellViewType !== CELL_VIEW_TYPE.DATE &&
				!(inputValue instanceof EditorValue)
			) {
				return (
					<CellInput
						value={inputValue ?? ''}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						onBlur={handleBlur}
						autoFocus
						rowHeight={rowHeight}
					/>
				);
			}
			return (
				<StyledTableCellContainer
					rowHeight={rowHeight}
					errors={errors}
					editable
					isEnum={cellViewType === CELL_VIEW_TYPE.ENUM}
				>
					<EditableTableCellContents
						cellViewType={cellViewType}
						rawValue={rawValue}
						foreignKey={foreignKey}
						displayValue={displayValue}
						column={column}
						attributeType={attributeType}
						handleChangeDate={handleChangeDate}
						handleBlur={handleBlur}
						canEdit={canEdit}
						checked={checked}
						toggleCheckbox={toggleCheckbox}
						columnEnumValues={columnEnumValues}
						inputValue={inputValue}
						databaseId={databaseId}
						dataSource={dataSource}
						setNewRecordValue={setValue}
						data={data}
						cellRef={cellRef}
						handleChange={handleChange}
						isAddingNewRecord
					/>
				</StyledTableCellContainer>
			);
		};

		return (
			<StyledNewRecord
				ref={cellRef}
				tabIndex={0}
				onKeyDown={handleKeyDown}
				onDoubleClick={handleDoubleClick}
				onMouseDown={(event) => {
					// If clicking on a date input, prevent focus on the date input if the
					// cell (or it's child elements) is not already in focus. This is to match the behaviour of all
					// the other cells where you must click twice in order to edit
					const focusedDocumentElement = document.activeElement;
					if (
						cellViewType === CELL_VIEW_TYPE.DATE &&
						!(
							focusedDocumentElement === cellRef.current ||
							cellRef.current?.contains(focusedDocumentElement)
						)
					) {
						event.preventDefault();
						cellRef.current?.focus();
					}
				}}
				data-column={index}
				data-row={NEW_ROW_DATA_ROW_ATTRIBUTE_VALUE}
				style={style}
			>
				{renderValue()}

				{foreignKey && isDropdownOpen && (
					<ForeignKeyPopover
						tableId={tableId}
						sqlDatabaseId={databaseId}
						primaryAttributeAttribute={primaryAttribute}
						foreignKey={foreignKey}
						cellRef={cellRef}
						setNewRecordValue={setValue}
						setIsDropdownOpen={setIsDropdownOpen}
					/>
				)}
			</StyledNewRecord>
		);
	}
);

export default NewRecordCell;
