import * as RadixPopover from '@radix-ui/react-popover';
import { useState } from 'react';
import styled from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';

const Content = styled(RadixPopover.Content)`
	min-width: 14rem;
	max-height: 70vh;
	overflow-y: auto;
	padding: 1rem;
	box-shadow: ${styles.shadows[700]};
	background: ${background[1]};
	border: 1px solid ${neutral[1]};
	border-radius: ${styles.global.borderRadius};
	color: ${neutral[1]};

	&:focus {
		outline: none;
	}
`;

interface PopoverProps {
	trigger: React.ReactNode;
	side?: 'top' | 'right' | 'bottom' | 'left';
	align?: 'start' | 'center' | 'end';
	avoidCollisions?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onBlur?: (_?: any) => Promise<void>;
	open?: boolean;
	setOpen?: (open: boolean) => void;
	children: React.ReactNode;
}

export function Popover({
	trigger,
	side,
	align,
	avoidCollisions,
	onBlur,
	open,
	setOpen,
	children,
}: PopoverProps) {
	const [openUncontrolled, setOpenUncontrolled] = useState(false);

	const handleOpenChange = (open: boolean) => {
		if (open === false && onBlur) {
			onBlur();
		}

		if (setOpen) {
			setOpen(open);
		} else {
			setOpenUncontrolled(open);
		}
	};

	return (
		<RadixPopover.Root
			open={open ?? openUncontrolled}
			onOpenChange={handleOpenChange}
		>
			<RadixPopover.Anchor>
				<RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
			</RadixPopover.Anchor>

			<Content
				side={side}
				align={align}
				avoidCollisions={avoidCollisions}
				sideOffset={8}
			>
				{children}
			</Content>
		</RadixPopover.Root>
	);
}
