import { useMutation, UseMutationOptions } from 'react-query';

import {
	updateViewFavorited,
	UpdateViewFavoritedParams,
} from 'api/updateViewFavorited';
import { updateView as updateViewInValtio } from 'valtioState/views/updateView';

export const useUpdateApiViewFavorited = (
	options?: UseMutationOptions<
		unknown,
		string,
		UpdateViewFavoritedParams,
		unknown
	>
) =>
	useMutation<unknown, string, UpdateViewFavoritedParams, unknown>(
		async ({ viewId, favorited }) => {
			const result = await updateViewFavorited({ viewId, favorited });

			if (!result.ok) {
				const { error } = await result.json();
				throw error;
			}
		},
		{
			onMutate: (params) => {
				updateViewInValtio({ id: params.viewId, favorited: params.favorited });
			},
			...options,
		}
	);
