import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { useCreateAdvancedApiView } from 'api/reactQueryHooks/useCreateAdvancedApiView';
import { useCreateBasicApiView } from 'api/reactQueryHooks/useCreateBasicApiView';
import {
	ActionBar,
	ActionBarItem,
	ActionBarSection,
	ActionBarTitle,
} from 'components/ActionBar';
import Button from 'components/Button';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from 'images/icons/arrowRight.svg';
import { ViewType } from 'typings/types';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { state } from 'valtioState';

interface NewViewHeaderProps {
	viewType: ViewType;
	/** Will not be null if creating a BASIC view */
	tableId: number | null;
	/** Will not be null if creating an SQL view */
	dataSourceId: number | null;
}

export function NewViewHeader({
	viewType,
	tableId,
	dataSourceId,
}: NewViewHeaderProps) {
	const snap = useSnapshot(state);
	const table = tableId !== null ? snap.entities.tables.byId[tableId] : null;
	const schema = table && snap.entities.schemas.byId[table.schemaId];
	const sqlDatabaseId =
		dataSourceId !== null
			? snap.entities.dataSources.byId[dataSourceId]?.sqlDatabase?.id
			: null;
	const history = useHistory();
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);

	const [loading, setLoading] = useState(false);

	const { mutate: createBasicApiView } = useCreateBasicApiView({
		onMutate: () => {
			setLoading(true);
		},
		onSuccess: (view) => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			history.push(`/views/${view.id}/settings`);
		},
		onSettled: () => {
			setLoading(false);
		},
	});

	const { mutate: createAdvancedApiView } = useCreateAdvancedApiView({
		onMutate: () => {
			setLoading(true);
		},
		onSuccess: (view) => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			history.push(`/views/${view.id}/settings`);
		},
		onSettled: () => {
			setLoading(false);
		},
	});

	const dataSourceSelected =
		viewType === ViewType.BASIC ? tableId !== null : dataSourceId !== null;

	const createView = () => {
		if (!workspaceId || !dataSourceSelected) {
			return;
		}

		if (viewType === ViewType.BASIC) {
			if (table == null || schema == null) {
				return;
			}

			createBasicApiView({
				workspaceId,
				databaseId: schema.sqlDatabaseId,
				schemaName: schema.name,
				tableName: table.tableName,
			});
		} else {
			if (sqlDatabaseId == null) {
				return;
			}

			createAdvancedApiView({ databaseId: sqlDatabaseId });
		}
	};

	return (
		<ActionBar topLevel>
			<ActionBarSection>
				<ActionBarItem>
					<Button onClick={history.goBack} icon={<ArrowLeftIcon />} />
				</ActionBarItem>
				<ActionBarTitle title="New view" />
			</ActionBarSection>

			<ActionBarSection>
				<ActionBarItem>
					<Button
						onClick={createView}
						value="Confirm data source"
						iconRight={<ArrowRightIcon />}
						disabled={loading || !dataSourceSelected}
						primary
					/>
				</ActionBarItem>
			</ActionBarSection>
		</ActionBar>
	);
}
