import React from 'react';
import styled from 'styled-components';

import { neutral } from 'utils/colors';

import Field, { FieldProps } from './Field';

const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.25rem;
`;

const RadioComponent = styled.input`
	position: relative;
`;

const Label = styled.label`
	font-weight: 400;
	color: ${neutral[1]};

	& + & {
		margin-top: 0.75rem;
	}
`;

const LabelText = styled.span`
	margin-left: 0.5rem;
`;

const Description = styled.p`
	margin-left: 1.5rem;
	font-size: 0.875rem;
	color: ${neutral[2]};
`;

interface RadioOption {
	label: string;
	value: string | number;
	disabled?: boolean;
	description?: React.ReactNode;
}

interface Props {
	description?: string;
	label?: React.ReactNode;
	labelWidth?: FieldProps['labelWidth'];
	labelSize?: FieldProps['labelSize'];
	optional?: boolean;
	errors?: string[];
	helpText?: React.ReactNode;
	name: string;
	value: string | number;
	options: RadioOption[];
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

export default function Radio({
	label,
	labelWidth,
	labelSize,
	optional,
	errors,
	helpText,
	name,
	value,
	options,
	onChange,
	disabled,
}: Props) {
	return (
		<Field
			type="radio"
			label={label}
			labelWidth={labelWidth}
			labelSize={labelSize}
			optional={optional}
			errors={errors}
			helpText={helpText}
		>
			<RadioContainer>
				{options.map((option) => (
					<Label key={option.value}>
						<RadioComponent
							type="radio"
							name={name}
							value={option.value}
							checked={value == option.value}
							onChange={onChange}
							disabled={disabled || option.disabled}
						/>
						<LabelText>{option.label}</LabelText>
						{option.description && (
							<Description>{option.description}</Description>
						)}
					</Label>
				))}
			</RadioContainer>
		</Field>
	);
}
