import styled from 'styled-components';

import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import _Card from 'components/Card';
import Page from 'components/Page';
import { SectionContainer } from 'components/SectionContainer';
import { CreateWorkspaceForm } from 'components/forms/CreateWorkspaceForm';
import { ReactComponent as WorkspaceIcon } from 'images/icons/workspace.svg';
import { neutral } from 'utils/colors';

const Card = styled(_Card)`
	width: 100%;
`;

const Description = styled.p`
	color: ${neutral[1]};
	line-height: 1.5;
`;

export function NewWorkspacePage() {
	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<WorkspaceIcon />} title="New workspace" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Card>
							<Description>
								Each workspace has its own databases, collaborators, roles, and
								billing. Typically, each company has one workspace.
							</Description>
							<CreateWorkspaceForm />
						</Card>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
