import React from 'react';

import { Icon } from 'components/Icon';
import { StyledLinkButton } from 'components/TableCell/EditableTableCellContents/StyledLinkButton';
import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { TableCellContentsText } from 'components/TableCell/TableCellContentsText';
import { ReactComponent as ExternalLinkIcon } from 'images/icons/externalLink.svg';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

type UrlTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	'rawValue' | 'isAddingNewRecord' | 'column' | 'displayValue'
>;

export const UrlTableCellContents = ({
	rawValue,
	isAddingNewRecord,
	column,
	displayValue,
}: UrlTableCellContentsProps): React.ReactElement | null => {
	if (rawValue === null || rawValue === '') {
		return (
			<StyledNullOrEmptyText>
				{renderNullOrDefaultValue({ isAddingNewRecord, column })}
			</StyledNullOrEmptyText>
		);
	}

	return (
		<>
			<TableCellContentsText className="fs-mask highlight-block">
				{displayValue}
			</TableCellContentsText>
			<StyledLinkButton
				as="a"
				target="_blank"
				rel="noopener noreferrer"
				href={rawValue as string}
			>
				<Icon>
					<ExternalLinkIcon />
				</Icon>
			</StyledLinkButton>
		</>
	);
};
