import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { Container } from 'components/ActionBar';
import { ListView } from 'components/ListView';
import Table from 'components/Table';
import { ReactComponent as BackIcon } from 'images/icons/back.svg';
import { tableRemoved } from 'reduxState/slices/tableStack';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import {
	TableData__Table,
	TableData__View,
	ExtractedTableData,
} from 'typings/types';
import { UseTableRecordsResult } from 'utils/useTableRecords';
import { state } from 'valtioState';

import { ButtonHovering } from './ButtonHovering';

function BackButton() {
	const dispatch = useDispatch();
	const snap = useSnapshot(state);

	const previousItem = useSelector(
		(state) => state.tableStack[state.tableStack.length - 1]
	);

	let to, title;
	if (previousItem.type === 'view') {
		to = `/views/${previousItem.viewId}`;
		title = previousItem.name;
	} else {
		const urlParams =
			previousItem.filterColumnName &&
			`?${previousItem.filterColumnName}=${previousItem.filterColumnValue}`;
		to = `/data-sources/${previousItem.dataSourceId}/tables/${
			previousItem.tableId
		}${urlParams || ''}`;
		const table = snap.entities.tables.byId[previousItem.tableId];
		title = table?.displayName ?? '';
	}

	const handlePopTableStack = () => {
		dispatch(tableRemoved());
	};

	return (
		<ButtonHovering as={Link} to={to} onClick={handlePopTableStack}>
			<BackIcon />
			<span>Back to {title}</span>
		</ButtonHovering>
	);
}

interface Props extends UseTableRecordsResult {
	workspace: ApiWorkspaceDetails;
	data: TableData__Table | TableData__View;
	extractedTableData: ExtractedTableData;
	canWriteData: boolean;
	isInViewBuilder?: boolean;
}

export const DataViewer = memo(
	({
		reload,
		records,
		recordsLoading,
		recordsError,
		pageNumber,
		setPageNumber,
		searchQuery,
		setSearchQuery,
		numRecords,
		tableStack,
		windowRef,
		collaborators,
		workspace,
		data,
		canWriteData,
		isInViewBuilder,
		extractedTableData,
	}: Props) => {
		return (
			<Container>
				{(data.type === 'table' ||
					(data.type === 'view' && data.view.layout !== 'LIST')) && (
					<Table
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						editable={canWriteData}
						records={records}
						recordsLoading={recordsLoading}
						recordsError={Boolean(recordsError)}
						data={data}
						extractedTableData={extractedTableData}
						searchQuery={searchQuery}
						collaborators={collaborators}
						workspace={workspace}
						reload={reload}
						numRecords={numRecords}
						setSearchQuery={setSearchQuery}
						windowRef={windowRef}
						isInViewBuilder={isInViewBuilder}
					/>
				)}

				{data.type === 'view' && data.view.layout === 'LIST' && (
					<ListView
						records={records}
						recordsLoading={recordsLoading}
						recordsError={Boolean(recordsError)}
						data={data}
						numRecords={numRecords}
						workspace={workspace}
						reload={reload}
						setSearchQuery={setSearchQuery}
						setPageNumber={setPageNumber}
						pageNumber={pageNumber}
						searchQuery={searchQuery}
						isInViewBuilder={isInViewBuilder}
						extractedTableData={extractedTableData}
					/>
				)}

				{!recordsLoading && tableStack.length > 0 && <BackButton />}
			</Container>
		);
	}
);

DataViewer.displayName = 'DataViewer';
