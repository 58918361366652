import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import Button from 'components/Button';
import {
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from 'components/DropdownMenu';
import { HistoryNavigationButtons } from 'components/HistoryNavigationButtons';
import { JumpToButton } from 'components/JumpToButton';
import { UserAvatar, UserAvatarImage } from 'components/UserAvatar';
import { WorkspaceSwitcher } from 'components/WorkspaceSwitcher';
import { ActionsTester } from 'components/actions/ActionsTester';
import { useFeatureFlags } from 'components/providers/FeatureFlagProvider';
import { ReactComponent as AllViewsIcon } from 'images/icons/allViews.svg';
import { ReactComponent as DatabaseIcon } from 'images/icons/database.svg';
import { ReactComponent as HomeIcon } from 'images/icons/home.svg';
import { ApiUser } from 'typings/serverTypes';
import { background, neutral } from 'utils/colors';
import { FEATURE_FLAGS, REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { canAccessRawTables } from 'utils/permissions';
import styles, { media } from 'utils/styles';
import { toggleChat } from 'utils/toggleChat';
import { useCollaborator } from 'utils/useCollaborator';

const AppNavigationBarRoot = styled.div`
	width: 100%;
	// 50px is the height required to center the traffic light buttons on macOS
	// We can change the traffic light position by contacting Dave at ToDesktop
	height: 50px;
	padding: 0 0.5rem;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	background: ${background[1]};
	color: ${neutral[1]};
	border-bottom: 1px solid ${neutral[4]};

	html.todesktop-platform-darwin & {
		padding-left: 5.5rem;

		-webkit-app-region: drag;

		* {
			-webkit-app-region: no-drag;
		}
	}

	// Render navbar on bottom on mobile (non-desktop)
	:not(html.todesktop-platform-darwin &) {
		${media.mobile`
			order: 100;
			border-bottom: none;
			border-top: 1px solid ${neutral[4]};

			// Leave space for iOS home indicator
			@supports (-webkit-overflow-scrolling: touch) { // This is only true on iOS
				height: calc(${styles.sizes.actionBarHeight} + 1.5rem);
				padding-bottom: 1.5rem;
			}
		`}
	}
`;

const LeftWingNav = styled.nav`
	display: flex;
	gap: 0.5rem;

	${media.mobile`
	width: 100%;

		& > * {
			flex: 1;
		}
	`}
`;

const RightWing = styled.div`
	display: flex;
	gap: 0.5rem;

	${media.mobile`
		display: none;
	`}
`;

const UserAvatarContainer = styled.button`
	width: 2.25rem;
	height: 2.25rem;
	background: none;
	border: none;

	& ${UserAvatarImage} {
		cursor: pointer;

		&:hover {
			opacity: 0.75;
		}
	}
`;

export enum appNavigationItems {
	Home,
	Views,
	Data,
}

export type AppNavigationBarProps = {
	activeNavItem: appNavigationItems;
};

export function AppNavigationBar({ activeNavItem }: AppNavigationBarProps) {
	const queryClient = useQueryClient();
	const collaborator = useCollaborator();
	const user =
		queryClient.getQueryData<ApiUser>(REACT_QUERY_CACHE_KEY.USER) ?? null;

	const { hasFeatureFlag } = useFeatureFlags();

	return (
		<AppNavigationBarRoot>
			<LeftWingNav id={'appNav'}>
				<HistoryNavigationButtons />

				<Button
					to="/"
					primary={activeNavItem === appNavigationItems.Home}
					value="Home"
					icon={<HomeIcon />}
				/>
				<Button
					to="/views"
					primary={activeNavItem === appNavigationItems.Views}
					value="Views"
					icon={<AllViewsIcon />}
				/>
				{canAccessRawTables(collaborator) && (
					<Button
						to="/data-sources"
						primary={activeNavItem === appNavigationItems.Data}
						value="Data"
						icon={<DatabaseIcon />}
					/>
				)}

				<JumpToButton />
			</LeftWingNav>
			<RightWing>
				{hasFeatureFlag(FEATURE_FLAGS.ACTIONS) && <ActionsTester />}

				<WorkspaceSwitcher />

				<DropdownMenu
					align="end"
					trigger={
						<UserAvatarContainer>
							<UserAvatar user={user ?? undefined} />
						</UserAvatarContainer>
					}
				>
					<DropdownMenuItem href="https://www.basedash.com/changelog">
						Changelog
					</DropdownMenuItem>

					<DropdownMenuItem href="https://roadmap.basedash.com">
						Request a feature
					</DropdownMenuItem>

					<DropdownMenuItem href="https://join.slack.com/t/basedash-community/shared_invite/zt-ok8mp9iv-GBygUk8~2f4McC4vhZtvnw">
						Join Slack community
					</DropdownMenuItem>

					<DropdownMenuItem onSelect={toggleChat}>
						Chat with us
					</DropdownMenuItem>

					<DropdownMenuItem href="mailto:support@basedash.com">
						Email us
					</DropdownMenuItem>

					<DropdownMenuSeparator />

					<DropdownMenuItem to="/logout">Log out</DropdownMenuItem>
				</DropdownMenu>
			</RightWing>
		</AppNavigationBarRoot>
	);
}
