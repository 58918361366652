import React from 'react';
import styled, { css } from 'styled-components';

import _Spinner from 'components/Spinner';
import { InputRoot } from 'components/fields/Input';
import { onPrimary, primary } from 'utils/colors';
import styles from 'utils/styles';

const InputButton = InputRoot.withComponent('button');

interface ComponentProps {
	loading?: boolean;
}

const FormButtonComponent = styled(InputButton).withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) =>
		!['loading'].includes(prop as string) && defaultValidatorFn(prop),
})<ComponentProps>`
	margin-top: 2rem;
	font-weight: 500;
	color: ${onPrimary};
	text-align: center;
	background: ${primary[1]};
	box-shadow: ${styles.shadows[500]};
	cursor: pointer;

	&:focus {
		box-shadow: ${styles.shadows[500]}, 0 0 0 3px ${styles.colours.primary[900]};
		box-shadow: 0 0 0 0 transparent, 0 0 0 3px ${styles.colours.primary[900]};
	}

	${(props) =>
		props.disabled || props.loading
			? css`
					cursor: default;
					opacity: 0.4;
					box-shadow: ${styles.shadows[900]};

					&:hover {
						opacity: 0.4;
						box-shadow: ${styles.shadows[900]};
					}
			  `
			: css`
					&:hover {
						background: ${primary[3]};
					}

					&:active {
						background: ${primary[3]};
						box-shadow: ${styles.shadows[500]};
					}
			  `};
`;

const Spinner = styled(_Spinner)`
	width: 1.5rem;
	height: 1.5rem;
	margin: 0 auto;
	border-width: 0.25rem;
`;

interface Props {
	loading?: boolean;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent) => void;
	children: React.ReactNode;
	className?: string;
}

export default function FormButton({
	loading,
	disabled,
	onClick,
	children,
	className,
}: Props) {
	const handleClick = (event: React.MouseEvent) => {
		if (disabled || loading) {
			event.preventDefault();
			return;
		}

		if (onClick) {
			onClick(event);
		}
	};

	return (
		<FormButtonComponent
			loading={loading}
			disabled={disabled}
			onClick={handleClick}
			className={className}
		>
			{loading ? <Spinner /> : children}
		</FormButtonComponent>
	);
}
