import { getDefaultHeaders } from 'api/utils';

type FetchDataSourceHealthParams = {
	dataSourceId: number;
};

export function fetchDataSourceHealth({
	dataSourceId,
}: FetchDataSourceHealthParams) {
	return fetch(`/api/data-sources/${dataSourceId}/health`, {
		headers: getDefaultHeaders(),
	});
}
