import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { state } from 'valtioState';
import { getView } from 'valtioState/views/getView';

export function useView(viewId: number | null) {
	const snap = useSnapshot(state);

	const viewById = viewId ? snap.entities.views.byId[viewId] : undefined;
	const loading = viewById ? viewById.loading : false;
	const error = viewById ? viewById.error : undefined;
	const view = viewById ? viewById.data : undefined;

	useEffect(() => {
		if (viewId) {
			getView(viewId);
		}
	}, [viewId]);

	return { view, error, loading };
}
