import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/Button';
import { ReactComponent as ArrowLeft } from 'images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'images/icons/arrowRight.svg';

const HistoryNavigationButtonsRoot = styled.div`
	:not(html.todesktop-platform-darwin &) {
		display: none;
	}
`;

export function HistoryNavigationButtons() {
	const history = useHistory();

	return (
		<HistoryNavigationButtonsRoot>
			<Button onClick={history.goBack} icon={<ArrowLeft />} title="Go back" />
			<Button
				onClick={history.goForward}
				icon={<ArrowRight />}
				title="Go forward"
			/>
		</HistoryNavigationButtonsRoot>
	);
}
