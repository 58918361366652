import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { createWorkspaceThunk } from 'reduxState/slices/workspaces';
import { useAppDispatch } from 'reduxState/store';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';

export function CreateWorkspaceForm() {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const queryClient = useQueryClient();
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState<{
		[key: string]: { value: string; errors: string[] | null };
	}>({
		name: {
			value: '',
			errors: null,
		},
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);

		dispatch(createWorkspaceThunk(fields.name.value))
			.unwrap()
			.then(() => {
				queryClient.invalidateQueries([REACT_QUERY_CACHE_KEY.WORKSPACE]);
				history.push('/databases/add');
			})
			.catch((error) => {
				setLoading(false);
				toast.error(`Problem creating workspace`);

				if (error.formErrors) {
					const updatedFields = { ...fields };
					for (const field of Object.keys(error.formErrors)) {
						if (error.formErrors[field].length) {
							updatedFields[field].errors = error.formErrors[field];
						} else {
							updatedFields[field].errors = null;
						}
					}

					setFields(updatedFields);
				}
			});
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="name"
				type="text"
				label="Workspace name"
				value={fields.name.value}
				errors={fields.name.errors}
				onChange={handleChange}
				autoFocus
			/>
			<FormButton loading={loading}>Create workspace</FormButton>
		</Form>
	);
}
