import { applyCreditToWorkspace as applyCreditToWorkspaceApi } from 'api/applyCreditToWorkspace';
import { changeWorkspacePlan as changeWorkspacePlanApi } from 'api/changeWorkspacePlan';
import { updateSqlDatabase as updateSqlDatabaseApi } from 'api/updateSqlDatabase';
import { UpdateSqlDatabaseApiPayload } from 'utils/formatDatabaseFormData';
import { throwFetchError } from 'utils/throwFetchError';

export function changeWorkspacePlan(workspaceId: number, plan: string) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (dispatch: any) => {
		dispatch(changeWorkspacePlanRequest());

		return changeWorkspacePlanApi(workspaceId, plan)
			.then(() => dispatch(changeWorkspacePlanFulfilled(workspaceId, plan)))
			.catch((error) => dispatch(changeWorkspacePlanRejected(error)));
	};
}

function changeWorkspacePlanRequest() {
	return {
		type: 'CHANGE_WORKSPACE_PLAN_REQUEST',
	};
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
function changeWorkspacePlanFulfilled(workspaceId, plan) {
	return {
		type: 'CHANGE_WORKSPACE_PLAN_FULFILLED',
		payload: {
			workspaceId,
			plan,
		},
	};
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
function changeWorkspacePlanRejected(error) {
	return {
		type: 'CHANGE_WORKSPACE_PLAN_REJECTED',
		payload: error,
	};
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'workspaceId' implicitly has an 'any' ty... Remove this comment to see the full error message
export function applyCreditToWorkspace(workspaceId, amount) {
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'amount' implicitly has an 'any' type.
	return (dispatch) => {
		dispatch(applyCreditToWorkspaceRequest());

		return applyCreditToWorkspaceApi(workspaceId, amount)
			.then((res) => res.json())
			.then(({ userId, userCredit, workspaceId, workspaceCredit }) =>
				dispatch(
					applyCreditToWorkspaceFulfilled(
						userId,
						userCredit,
						workspaceId,
						workspaceCredit
					)
				)
			)
			.catch((error) => dispatch(applyCreditToWorkspaceRejected(error)));
	};
}

function applyCreditToWorkspaceRequest() {
	return {
		type: 'APPLY_CREDIT_TO_WORKSPACE_REQUEST',
	};
}

function applyCreditToWorkspaceFulfilled(
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
	userId,
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
	userCredit,
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'workspaceId' implicitly has an 'any' ty... Remove this comment to see the full error message
	workspaceId,
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'workspaceCredit' implicitly has an 'any... Remove this comment to see the full error message
	workspaceCredit
) {
	return {
		type: 'APPLY_CREDIT_TO_WORKSPACE_FULFILLED',
		payload: {
			userId,
			userCredit,
			workspaceId,
			workspaceCredit,
		},
	};
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
function applyCreditToWorkspaceRejected(error) {
	return {
		type: 'APPLY_CREDIT_TO_WORKSPACE_REJECTED',
		payload: error,
	};
}

export function updateDatabase(payload: UpdateSqlDatabaseApiPayload) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (dispatch: any) => {
		dispatch(updateDatabaseRequest());

		return updateSqlDatabaseApi(payload)
			.then((res) => throwFetchError(res))
			.then((res) => res.json())
			.then((databaseModel) =>
				dispatch(updateDatabaseFulfilled(payload.sqlDatabaseId, databaseModel))
			)
			.catch((errors) => {
				// @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
				dispatch(updateDatabaseRejected(errors));
				throw errors;
			});
	};
}

function updateDatabaseRequest() {
	return {
		type: 'UPDATE_DATABASE_REQUEST',
	};
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'databaseId' implicitly has an 'any' typ... Remove this comment to see the full error message
function updateDatabaseFulfilled(databaseId, databaseModel) {
	return {
		type: 'UPDATE_DATABASE_FULFILLED',
		payload: {
			databaseId,
			databaseModel,
		},
	};
}

function updateDatabaseRejected() {
	return {
		type: 'UPDATE_DATABASE_REJECTED',
	};
}
