import * as Sentry from '@sentry/browser';
import { H as Highlight } from 'highlight.run';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { useApiUser } from 'api/reactQueryHooks/useApiUser';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useApiWorkspaces } from 'api/reactQueryHooks/useApiWorkspaces';
import { trackEvent } from 'api/trackEvent';
import { updateUser } from 'api/updateUser';
import LoadingScreen from 'components/LoadingScreen';
import PrivateRoute from 'components/PrivateRoute';
import { useToken } from 'components/providers/TokenProvider';
import { logout } from 'reduxState/actions';
import { setCurrentMember } from 'reduxState/slices/currentMember';
import { setWorkspaceId } from 'reduxState/slices/workspaceId';
import AppRoute from 'routes/AppRoute';
import PageNotFoundRoute from 'routes/PageNotFoundRoute';
import VerifyEmailRoute from 'routes/VerifyEmailRoute';
import WelcomeRoute from 'routes/WelcomeRoute';
import LoginMagicLinkRoute from 'routes/marketing/LoginMagicLinkRoute';
import { LoginRoute } from 'routes/marketing/LoginRoute';
import LogoutRoute from 'routes/marketing/LogoutRoute';
import OauthSlackRoute from 'routes/marketing/OauthSlackRoute';
import { SignupRoute } from 'routes/marketing/SignupRoute';
import { ApiUser } from 'typings/serverTypes';
import { getUserAvatarUrl } from 'utils/getUserAvatarUrl';
import { canAccessRawTables } from 'utils/permissions';
import { useBuildNumber } from 'utils/useBuildNumber';
import { useCollaborator } from 'utils/useCollaborator';
import { useConsoleWelcomeMessage } from 'utils/useConsoleWelcomeMessage';
import { state } from 'valtioState';
import { getAllSettings } from 'valtioState/getAllSettings';
import { getAndSyncDataSourceSchema } from 'valtioState/getAndSyncDataSourceSchema';
import { setSqlDatabases } from 'valtioState/sqlDatabases/setSqlDatabases';

import '@blueprintjs/core/lib/css/blueprint.css';

export default function App() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const queryClient = useQueryClient();
	const snap = useSnapshot(state);

	const [hasLoadedSettings, setHasLoadedSettings] = useState(false);

	const workspaceId = useSelector((state) => state.workspaceId);
	const rehydrated = useSelector((state) => state._persist.rehydrated);

	const { token } = useToken();
	const authenticated = token !== null;

	useConsoleWelcomeMessage();

	const { isLoading: isLoadingUser } = useApiUser({
		onSuccess: (user) => {
			trackEvent({
				type: 'SESSION_STARTED',
			});

			Sentry.setUser({
				email: user.email,
				id: String(user.id),
				fullName: user.fullName,
			});
			window.analytics.identify(String(user.id), {
				name: user.fullName,
				email: user.email,
			});
			window.CommandBar?.boot({ id: String(user.id) });
			Highlight.identify(user.email, {
				id: user.id,
				name: user.fullName,
				avatar: getUserAvatarUrl(user),
			});
			window.Canny('identify', {
				appID: '610c8c1a5303a829609df937',
				user: {
					email: user.email,
					name: user.fullName,
					id: user.id,
					avatarURL: getUserAvatarUrl(user),
				},
			});
			if (snap.config?.intercomAppId && window.Intercom) {
				window.Intercom('boot', {
					app_id: snap.config.intercomAppId,
					email: user.email,
					user_id: String(user.id),
					name: user.fullName,
					hide_default_launcher: true,
					created_at: new Date(user.createdAt).getTime() / 1000,
				});
			}

			updateUser();
		},
		enabled: authenticated,
	});
	useBuildNumber();

	const currentMember = useCollaborator();
	useEffect(() => {
		dispatch(setCurrentMember(currentMember));
	}, [currentMember, dispatch]);

	const { isLoading: isLoadingWorkspaces } = useApiWorkspaces({
		onSuccess: async (data) => {
			// workspaceId will be undefined if no workspaceId has been saved in local storage
			if (workspaceId === null && data.length > 0) {
				dispatch(setWorkspaceId(data[0].id));
			} else if (data.length === 0) {
				if (!['/welcome', '/logout'].includes(location.pathname)) {
					history.push('/welcome');
				}
			}
		},
		// Need to wait until the store is rehydrated in order to be able to properly redirect
		// to the correct workspace on initial page load
		enabled: rehydrated && authenticated,
	});

	const { isLoading: isLoadingWorkspace } = useApiWorkspace(workspaceId, {
		// Need to wait until the store is rehydrated before loading workspace since
		// the onSuccess callback wll call the setSQLDatabases which will set the
		// SQL databases in the store. If we don't wait for the rehydration, than
		// the rehydration can wipe out the SQL databases in the store in a race
		// condition.
		enabled: workspaceId !== null && snap.hasLoadedStateFromPersistentStorage,
		onSuccess: async (apiWorkspace) => {
			setSqlDatabases(apiWorkspace.sqlDatabases);
			if (workspaceId !== null && !hasLoadedSettings) {
				const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
				const collaborator =
					apiWorkspace.collaborators.find(
						(collaborator) => collaborator.userId === userId
					) ?? null;
				setHasLoadedSettings(true);

				window.CommandBar?.addContext(
					'views',
					apiWorkspace?.views.map((view) => ({
						id: view.id,
						label: view.name,
					}))
				);

				if (canAccessRawTables(collaborator)) {
					window.CommandBar?.addContext(
						'tables',
						apiWorkspace.sqlDatabases
							.flatMap((sqlDatabase) =>
								sqlDatabase.schemas.flatMap((schema) =>
									schema.tables.map((table) => ({
										...table,
										dataSourceId: sqlDatabase.dataSourceId,
									}))
								)
							)
							.map((table) => ({
								...table,
								dataSource: snap.entities.dataSources.byId[table.dataSourceId],
							}))
							.filter(
								(table) =>
									table.dataSource?.connected &&
									table.dataSource?.healthy !== false
							)
							.map((table) => ({
								label: table.displayName,
								id: table.id,
								dataSourceId: table.dataSourceId,
								description: table.dataSource?.name,
							}))
					);
				}
			}
		},
		onError: () => {
			// Need to clear out the redux store that is persisted in case the workspace ID in local storage is no good (e.g. corresponds to a deleted workspace)
			dispatch(logout());
		},
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		window.analytics.page();
	}, [location]);

	useEffect(() => {
		// Need to make sure to only fetch the settings after loading the state
		// from persistent storage
		if (workspaceId === null || !snap.hasLoadedStateFromPersistentStorage) {
			return;
		}

		const getAndSyncSettings = async () => {
			const settings = await getAllSettings(workspaceId);

			for (const dataSource of Object.values(settings.dataSources.byId)) {
				if (dataSource?.connected) {
					await getAndSyncDataSourceSchema({
						dataSourceId: dataSource.id,
					});
				}
			}
		};

		getAndSyncSettings();

		trackEvent({
			type: 'WORKSPACE_ACCESSED',
			workspaceId,
		});
	}, [snap.hasLoadedStateFromPersistentStorage, workspaceId]);

	const loading =
		isLoadingUser ||
		isLoadingWorkspaces ||
		isLoadingWorkspace ||
		!snap.hasLoadedStateFromPersistentStorage;
	return (
		<div>
			<LoadingScreen active={loading} />
			<Switch>
				<Route exact path="/login" component={LoginRoute} />

				<Route
					exact
					path="/login/:magicLinkKey"
					component={LoginMagicLinkRoute}
				/>
				<Route exact path="/request-access">
					<Redirect to="/signup" />
				</Route>
				<Route exact path="/create-account">
					<Redirect to="/signup" />
				</Route>
				<Route exact path="/logout" component={LogoutRoute} />
				<Route exact path="/signup" component={SignupRoute} />
				<Route exact path="/oauth/slack" component={OauthSlackRoute} />
				<PrivateRoute
					exact
					path="/verify-email/:emailVerificationKey"
					component={VerifyEmailRoute}
				/>
				<PrivateRoute exact path="/welcome" component={WelcomeRoute} />

				<PrivateRoute exact path="/" component={AppRoute} />
				<PrivateRoute exact path="/workspaces/new" component={AppRoute} />
				<PrivateRoute path="/settings" component={AppRoute} />
				<PrivateRoute exact path="/members" component={AppRoute} />
				<PrivateRoute exact path="/activity" component={AppRoute} />
				<PrivateRoute exact path="/views" component={AppRoute} />
				<PrivateRoute exact path="/views/new" component={AppRoute} />
				<PrivateRoute exact path="/views/:viewId" component={AppRoute} />
				<PrivateRoute
					exact
					path="/views/:viewId/settings"
					component={AppRoute}
				/>
				<PrivateRoute exact path="/queries/:queryId" component={AppRoute} />
				<PrivateRoute exact path="/databases/add" component={AppRoute} />
				<PrivateRoute
					exact
					path="/databases/add/:dialect"
					component={AppRoute}
				/>
				<PrivateRoute
					exact
					path="/databases/:databaseId/settings"
					component={AppRoute}
				/>
				<PrivateRoute
					exact
					path="/databases/:databaseId/update"
					component={AppRoute}
				/>
				<PrivateRoute exact path="/data-sources" component={AppRoute} />
				<PrivateRoute
					path="/data-sources/:dataSourceId/tables"
					component={AppRoute}
				/>
				<PrivateRoute path="/settings/data-sources" component={AppRoute} />

				<Route component={PageNotFoundRoute} />
			</Switch>
		</div>
	);
}
