import humanizeString from 'humanize-string';

import { ObjectRecordId } from 'typings/serverTypes';
import { DataSourceValue, Role } from 'typings/types';

export const getVerboseRecordId = (recordId: ObjectRecordId | null) => {
	if (!recordId) {
		return '';
	}

	if (Object.keys(recordId.pk).length === 1) {
		return recordId.pk[Object.keys(recordId.pk)[0]];
	}

	return JSON.stringify(recordId.pk);
};

export const getHumanizedString = (rawValue: string) => {
	const abbreviations = ['id', 'url', 'ssh', 'ssl', 'iv', 'dob', 'sql'];

	return humanizeString(rawValue)
		.split(' ')
		.map((word) =>
			abbreviations.includes(word.toLowerCase())
				? word.toLocaleUpperCase()
				: word
		)
		.join(' ');
};

export const getVerboseDataSource = (dialect: DataSourceValue) => {
	switch (dialect) {
		case 'postgres':
			return 'PostgreSQL';
		case 'redshift':
			return 'Redshift';
		case 'mysql':
			return 'MySQL';
		case 'mariadb':
			return 'MariaDB';
		case 'mssql':
			return 'SQL Server';
		case 'airtable':
			return 'Airtable';
		case 'stripe':
			return 'Stripe';
		case 'shopify':
			return 'Shopify';
		case 'twilio':
			return 'Twilio';
		case 'close':
			return 'Close';
		default:
			return null;
	}
};

export const getVerboseRole = (role: Role) => {
	switch (role) {
		case Role.ADMIN:
			return 'Admin';
		case Role.COLLABORATOR:
			return 'Collaborator';
		case Role.READ_ONLY:
			return 'Read-only';
	}
};

export const getRoleDescription = (role: Role) => {
	switch (role) {
		case Role.ADMIN:
			return 'Can create views, add data sources, and access raw tables.';
		case Role.COLLABORATOR:
			return 'Can read and write data in existing views.';
		case Role.READ_ONLY:
			return 'Can read data in existing views.';
	}
};
