export const truncatedText = ({
	value,
	length = 500,
}: {
	value: string;
	length?: number;
}) => {
	return value
		? `${value.slice(0, length)}${value.length > length ? '...' : ''}`
		: '';
};
