import { getDefaultHeaders } from 'api/utils';

export type FindWorkspaceParams = {
	viewId?: number;
	databaseId?: number;
};
export type FindWorkspaceResponse = number;

export function findWorkspace(params: FindWorkspaceParams) {
	return fetch(`/api/workspaces/find-workspace`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify(params),
	});
}
