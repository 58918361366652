import Resizable from 'react-resizable';
import styled from 'styled-components';

import { background, neutral, primary } from 'utils/colors';
import { media } from 'utils/styles';

export const ResizableSidebar = styled(Resizable.ResizableBox)`
	background-color: ${background[1]};
	flex-shrink: 0;
	position: relative;
	height: 100%;

	${media.tabletDown`
		width: 14rem !important;
		position: absolute;
	`}

	${media.desktop`
		.react-resizable {
			position: relative;
		}
		.react-resizable-handle {
			width: 0.75rem;
			height: 100%;
			position: absolute;
			top: 0;
			right: -0.5rem;
			cursor: col-resize;

			&::after {
				content: '';
				display: block;
				margin-left: calc(0.25rem - 1px);
				width: 0;
				height: 100%;
				background: ${neutral[5]};
				transition: width 0.25s;
			}

			&:hover::after,
			&:active::after {
				width: calc(0.5rem + 1px);
			}

			&:active::after {
				border-left: 1px solid ${primary[1]};
			}
		}
	`}
`;
