import { getDefaultHeaders } from 'api/utils';
import { Member } from 'typings/models';
import { ObjectRecordId } from 'typings/serverTypes';

export type DeleteRecordsParams = {
	tableId: number;
	recordIds: ObjectRecordId[];
	collaborator: Member | null;
};

export function deleteRecords({ tableId, recordIds }: DeleteRecordsParams) {
	return fetch(`/api/tables/${tableId}/records`, {
		method: 'DELETE',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			recordIds,
		}),
	});
}
