import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
	EntityState,
} from '@reduxjs/toolkit';

import { createWorkspace } from 'api/createWorkspace';
import { setWorkspaceId } from 'reduxState/slices/workspaceId';
import { ApiWorkspace, StandardApiError } from 'typings/serverTypes';

type WorkspaceEntity = ApiWorkspace;

const workspacesEntityAdapter = createEntityAdapter<WorkspaceEntity>({
	selectId: (workspace) => workspace.id,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export type WorkspacesState = EntityState<WorkspaceEntity>;

export const createWorkspaceThunk = createAsyncThunk(
	'workspaces/addWorkspace',
	async (name: string, { rejectWithValue, dispatch }) => {
		const response = await createWorkspace(name);
		if (response.ok) {
			const workspace: ApiWorkspace = await response.json();
			dispatch(setWorkspaceId(workspace.id));
			return workspace;
		} else {
			const error: StandardApiError & {
				formErrors?: { [key: string]: string[] };
			} = await response.json();
			throw rejectWithValue(error);
		}
	}
);

const workspacesSlice = createSlice({
	name: 'dataSources',
	initialState: workspacesEntityAdapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(createWorkspaceThunk.fulfilled, (state, action) => {
			workspacesEntityAdapter.addOne(state, action.payload);
		});
	},
});

export const { reducer } = workspacesSlice;
