import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useToken } from 'components/providers/TokenProvider';
import { logout } from 'reduxState/actions';
import { ApiUser } from 'typings/serverTypes';

export default function LogoutRoute() {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { setToken } = useToken();
	const history = useHistory();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;

	useEffect(() => {
		if (userId === null) {
			history.replace('/login');
		}
		setToken(null);
		Sentry.configureScope((scope) => scope.setUser(null));
		dispatch(logout());
		queryClient.clear();
	}, [dispatch, setToken, queryClient, history, userId]);

	return null;
}
