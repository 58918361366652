import { getHumanizedString } from 'utils/verboseNames';
import { ValtioState } from 'valtioState';

export const getAttributeDisplayName = ({
	attributeNamesToIdMap,
	attributesById,
	attributeName,
	tableName,
	schemaName,
	sqlDatabaseId,
}: {
	attributeNamesToIdMap: ValtioState['attributeNamesToIdMap'];
	attributesById: ValtioState['entities']['attributes']['byId'];
	attributeName: string;
	tableName: string;
	schemaName: string;
	sqlDatabaseId: number;
}): string => {
	const attributeId =
		attributeNamesToIdMap[
			sqlDatabaseId + '/' + schemaName + '/' + tableName + '/' + attributeName
		];
	return (
		attributesById[attributeId ?? 0]?.displayName ??
		getHumanizedString(attributeName)
	);
};
