import * as Sentry from '@sentry/browser';

import {
	FetchRecordsResponse,
	fetchTableRecords,
	FetchTableRecordsParams,
} from 'api/fetchTableRecords';
import { fetchViewRecords, FetchViewRecordsParams } from 'api/fetchViewRecords';
import { Attribute } from 'typings/models';
import {
	ApiRecordAttribute,
	CellRecord,
	StringRecordId,
} from 'typings/serverTypes';
import { ApiError } from 'utils/errors';
import { formatStringRecordIdFromTableIdAndPrimaryKeyValues } from 'utils/formatStringRecordIdFromTableIdAndPrimaryKeyValues';
import { state } from 'valtioState/index';

export const getTableRecords = async ({
	tableId,
	attributes,
	filters,
	sorts,
	pageNumber,
	searchQuery,
	primaryKeyAttributes,
}: FetchTableRecordsParams & {
	primaryKeyAttributes: ApiRecordAttribute[];
}): Promise<{
	recordIds: StringRecordId[];
	numRecords: number;
}> => {
	const response = await fetchTableRecords({
		tableId,
		attributes,
		filters,
		sorts,
		pageNumber,
		searchQuery,
	});
	if (!response.ok) {
		const error = await response.json();
		Sentry.captureException('Failed to fetch records', {
			contexts: {
				details: {
					message: error,
				},
			},
		});
		throw new ApiError('Failed to fetch records');
	}
	const data: FetchRecordsResponse = await response.json();
	primaryKeyAttributes.sort((a, b) => (a.id < b.id ? -1 : 1));

	const recordIds = setRecords({
		records: data.records,
		primaryKeyAttributes,
		tableId,
	});
	return { recordIds, numRecords: data.numRecords };
};

export const getViewRecords = async ({
	tableId,
	viewId,
	filters,
	sorts,
	pageNumber,
	searchQuery,
	primaryKeyAttributes,
	includeAllConnectedAttributes = false,
}: FetchViewRecordsParams & {
	tableId: number;
	primaryKeyAttributes: Attribute[];
}): Promise<{
	recordIds: StringRecordId[];
	numRecords: number;
}> => {
	const response = await fetchViewRecords({
		viewId,
		filters,
		sorts,
		pageNumber,
		searchQuery,
		includeAllConnectedAttributes,
	});
	if (!response.ok) {
		const error = await response.json();
		Sentry.captureException('Failed to fetch records', {
			contexts: {
				details: {
					message: error,
				},
			},
		});
		throw new ApiError('Failed to fetch records');
	}
	const data: FetchRecordsResponse = await response.json();
	primaryKeyAttributes.sort((a, b) => (a.id < b.id ? -1 : 1));

	const recordIds = setRecords({
		records: data.records,
		primaryKeyAttributes,
		tableId,
	});
	return { recordIds, numRecords: data.numRecords };
};

export const setRecords = ({
	tableId,
	records,
	primaryKeyAttributes,
}: {
	tableId: number;
	records: CellRecord[];
	primaryKeyAttributes: Attribute[];
}): StringRecordId[] => {
	primaryKeyAttributes.sort((a, b) => (a.id < b.id ? -1 : 1));

	const recordIds: StringRecordId[] = [];
	for (const record of records) {
		if (record !== undefined) {
			const recordId = formatStringRecordIdFromTableIdAndPrimaryKeyValues({
				tableId,
				primaryKeyAttributes,
				record,
			});
			state.entities.records.byId[recordId] = { ...record, id: recordId };
			recordIds.push(recordId);
		}
	}
	return recordIds;
};
