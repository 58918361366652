import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BooleanParam, createEnumParam } from 'serialize-query-params';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';

import { addDataSourceFromEncryptedString } from 'api/addDataSourceFromEncryptedString';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import { FullHeightAndCenteredContent } from 'components/FullHeightAndCenteredContent';
import Page from 'components/Page';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ApiUser } from 'typings/serverTypes';
import { DataSourceValue } from 'typings/types';
import {
	dataSourceTypes,
	hostingProviders,
	defaultDataSourceValues,
	hostingProvidersByDataSourceType,
} from 'utils/dataSourceInfo';
import { isSequinDataSource } from 'utils/isSequinDataSource';
import { canManageDataSources } from 'utils/permissions';
import { toast } from 'utils/toast/toast';

import { BackButton } from './BackButton';
import { DataSourceDetailsSection } from './DataSourceDetailsSection';
import { HostingProviderSection } from './HostingProviderSection';
import { NameDataSourceSection } from './NameDataSourceSection';
import { handleNextNavigation } from './NavigationButtons';
import { SelectDataSourceSection } from './SelectDataSourceSection';
import { TrustedSourceSetupSection } from './TrustedSourceSetupSection';

const StyledSectionContainer = styled(SectionContainer)`
	padding: 2rem 1rem;
	line-height: 1.5;
`;

export enum AddDataSourceStep {
	SELECT_DATA_SOURCE,
	SELECT_HOSTING_PROVIDER,
	NAME_DATA_SOURCE,
	TRUSTED_SOURCE_SETUP,
	DATA_SOURCE_DETAILS,
}

export enum QueryParams {
	DATA_SOURCE = 'dataSource',
	HOSTING_PROVIDER = 'hostingProvider',
	DATA_SOURCE_NAME = 'dataSourceName',
	TRUSTED_SOURCE_COMPLETED = 'trustedSourceCompleted',
	DATA_SOURCE_DETAILS = 'dataSourceDetails',
}

export const QueryParamPerStep: { [step in AddDataSourceStep]: QueryParams } = {
	[AddDataSourceStep.SELECT_DATA_SOURCE]: QueryParams.DATA_SOURCE,
	[AddDataSourceStep.SELECT_HOSTING_PROVIDER]: QueryParams.HOSTING_PROVIDER,
	[AddDataSourceStep.NAME_DATA_SOURCE]: QueryParams.DATA_SOURCE_NAME,
	[AddDataSourceStep.TRUSTED_SOURCE_SETUP]:
		QueryParams.TRUSTED_SOURCE_COMPLETED,
	[AddDataSourceStep.DATA_SOURCE_DETAILS]: QueryParams.DATA_SOURCE_DETAILS,
};

const queryParamsConfig = {
	[QueryParams.DATA_SOURCE]: StringParam,
	[QueryParams.HOSTING_PROVIDER]: StringParam,
	[QueryParams.DATA_SOURCE_NAME]: StringParam,
	[QueryParams.TRUSTED_SOURCE_COMPLETED]: BooleanParam,
	[QueryParams.DATA_SOURCE_DETAILS]: BooleanParam,
};

export type QueryParamsConfig = typeof queryParamsConfig;

export const databaseSteps = {
	0: {
		previousStep: undefined,
		currentStep: AddDataSourceStep.SELECT_DATA_SOURCE,
		nextStep: AddDataSourceStep.SELECT_HOSTING_PROVIDER,
	},
	1: {
		previousStep: AddDataSourceStep.SELECT_DATA_SOURCE,
		currentStep: AddDataSourceStep.SELECT_HOSTING_PROVIDER,
		nextStep: AddDataSourceStep.NAME_DATA_SOURCE,
	},
	2: {
		previousStep: AddDataSourceStep.SELECT_HOSTING_PROVIDER,
		currentStep: AddDataSourceStep.NAME_DATA_SOURCE,
		nextStep: AddDataSourceStep.TRUSTED_SOURCE_SETUP,
	},
	3: {
		previousStep: AddDataSourceStep.NAME_DATA_SOURCE,
		currentStep: AddDataSourceStep.TRUSTED_SOURCE_SETUP,
		nextStep: AddDataSourceStep.DATA_SOURCE_DETAILS,
	},
	4: {
		previousStep: AddDataSourceStep.TRUSTED_SOURCE_SETUP,
		currentStep: AddDataSourceStep.DATA_SOURCE_DETAILS,
		nextStep: undefined,
	},
} as const;

export type DatabaseSteps = typeof databaseSteps;
export type DatabaseStep = DatabaseSteps[keyof DatabaseSteps];

interface ConnectDataSourceContentProps {
	workspaceId: number;
}

function ConnectDataSourceContent({
	workspaceId,
}: ConnectDataSourceContentProps) {
	const DataSourceEnumParam = createEnumParam(Object.keys(dataSourceTypes));
	const HostingProviderEnumParam = createEnumParam(
		Object.keys(hostingProviders)
	);
	queryParamsConfig.dataSource = DataSourceEnumParam;
	queryParamsConfig.hostingProvider = HostingProviderEnumParam;
	const [query, setQuery] = useQueryParams(queryParamsConfig);
	const [databaseStep, setDatabaseStep] = useState<
		keyof DatabaseSteps | undefined
	>();

	// Determine which state to move the user to on initial page load and whenever
	// a query param value changes
	useEffect(() => {
		let step: keyof typeof databaseSteps = 0;
		if (query.dataSource !== undefined) {
			if (query.hostingProvider !== undefined) {
				step = 1;
				if (query.dataSourceName !== undefined) {
					step = 2;
					if (query.trustedSourceCompleted !== undefined) {
						step = 3;
						if (query.dataSourceDetails !== undefined) {
							step = 4;
						}
					}
				}
			}
		}
		setDatabaseStep(step);
	}, [
		query.dataSource,
		query.hostingProvider,
		query.dataSourceName,
		query.trustedSourceCompleted,
		query.dataSourceDetails,
		databaseStep,
	]);

	// Rely on the useEffect to get the correct databaseStep based no the URL query params
	if (databaseStep === undefined) {
		return null;
	}

	const hostingProvider = query.hostingProvider
		? hostingProviders[
				query.hostingProvider as keyof typeof hostingProviders
		  ] ?? null
		: null;
	const defaultDataSourceValuesForGivenHostingProvider =
		query.dataSource && query.hostingProvider
			? defaultDataSourceValues[
					query.dataSource as keyof typeof dataSourceTypes
			  ]?.[query.hostingProvider as keyof typeof hostingProviders] ?? null
			: null;

	const renderSection = (): JSX.Element | null => {
		switch (databaseStep) {
			case AddDataSourceStep.SELECT_DATA_SOURCE:
				return (
					<SelectDataSourceSection
						setQuery={setQuery}
						databaseStep={databaseStep}
						dataSource={query.dataSource}
						onNext={() => {
							const nextNextStep = databaseSteps[
								(databaseStep + 1) as keyof DatabaseSteps
							].nextStep as AddDataSourceStep;
							if (query.dataSource === 'redshift') {
								return setQuery({
									hostingProvider: hostingProvidersByDataSourceType[
										'redshift'
									][0] as string,
									[QueryParamPerStep[nextNextStep]]: null,
								});
							}

							if (isSequinDataSource(query.dataSource)) {
								if (query.dataSource) {
									return setQuery({
										hostingProvider: hostingProvidersByDataSourceType[
											query.dataSource as keyof typeof dataSourceTypes
										][0] as string,
									});
								}
							}

							const nextStep = databaseSteps[databaseStep]
								.nextStep as AddDataSourceStep;
							handleNextNavigation({
								setQuery,
								nextStep,
							});
						}}
					/>
				);
			case AddDataSourceStep.SELECT_HOSTING_PROVIDER:
				return (
					<HostingProviderSection
						setQuery={setQuery}
						dataSource={query.dataSource}
						databaseStep={databaseStep}
						hostingProvider={query.hostingProvider}
						hostingProviders={hostingProvidersByDataSourceType[
							query.dataSource as keyof typeof dataSourceTypes
						].map(
							(hostingProviderName) =>
								hostingProviders[
									hostingProviderName as keyof typeof hostingProviders
								]
						)}
					/>
				);
			case AddDataSourceStep.NAME_DATA_SOURCE:
				return (
					<NameDataSourceSection
						setQuery={setQuery}
						workspaceId={workspaceId}
						databaseStep={databaseStep}
						dataSource={query.dataSource}
						dataSourceName={query.dataSourceName}
						onNext={() => {
							const nextStep = databaseSteps[databaseStep].nextStep;
							// If the hosting provider doesn't require a trusted source to be used, then skip that step
							if (hostingProvider && !hostingProvider.hasTrustedSource) {
								return setQuery({
									trustedSourceCompleted: true,
									[QueryParamPerStep[
										databaseSteps[(databaseStep + 1) as keyof DatabaseSteps]
											.nextStep as AddDataSourceStep
									]]: null,
								});
							}
							handleNextNavigation({
								setQuery,
								nextStep,
							});
						}}
					/>
				);
			case AddDataSourceStep.TRUSTED_SOURCE_SETUP:
				return (
					<TrustedSourceSetupSection
						setQuery={setQuery}
						databaseStep={databaseStep}
						hostingProvider={hostingProvider}
					/>
				);
			case AddDataSourceStep.DATA_SOURCE_DETAILS:
				if (hostingProvider) {
					return (
						<DataSourceDetailsSection
							setQuery={setQuery}
							databaseStep={databaseStep}
							workspaceId={workspaceId}
							databaseName={query.dataSourceName as string}
							dataSourceValue={query.dataSource as DataSourceValue}
							hostingProvider={hostingProvider}
							defaultDataSourceValues={
								defaultDataSourceValuesForGivenHostingProvider
							}
						/>
					);
				}
				return null;
			default:
				return null;
		}
	};

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<CloudIcon />} title="Connect data source" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					{databaseStep !== 0 && (
						<BackButton databaseStep={databaseStep} setQuery={setQuery} />
					)}
					<StyledSectionContainer>{renderSection()}</StyledSectionContainer>
				</Content>
			</Container>
		</Page>
	);
}

export function ConnectDataSource() {
	const history = useHistory();
	const encryptedCredentials = new URLSearchParams(location.search).get(
		'encryptedCredentials'
	);

	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === userId
	);

	useEffect(() => {
		window.analytics.track('Connect Data Source Page Viewed');
	}, []);

	useEffect(() => {
		if (!workspaceId || !encryptedCredentials) {
			return;
		}

		addDataSourceFromEncryptedString({
			workspaceId,
			encryptedCredentials,
		}).then((response) => {
			if (!response.ok) {
				toast.error('Unable to connect data source.');
			}

			history.push('/data-sources');
		});
	}, [workspaceId, encryptedCredentials, history]);

	if (!collaborator || !workspaceId) {
		return null;
	}

	if (!canManageDataSources(collaborator)) {
		history.replace('/');
		return null;
	}

	if (encryptedCredentials) {
		return (
			<FullHeightAndCenteredContent>
				<Spinner />
			</FullHeightAndCenteredContent>
		);
	}

	return <ConnectDataSourceContent workspaceId={workspaceId} />;
}
