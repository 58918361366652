import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import Button from 'components/Button';
import { Icon } from 'components/Icon';
import { DataSourceSettingsPanel } from 'components/pages/DataSourceSettings/DataSourceSettingsPanel';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/arrowLeft.svg';
import { background, neutral } from 'utils/colors';
import { media } from 'utils/styles';

import { AttributeSettingsPanel } from './AttributeSettingsPanel';
import { TableSettingsPanel } from './TableSettingsPanel';

const StyledDataSourceSettings = styled.div`
	background-color: ${background[1]};
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
`;

export const Panel = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow-y: auto;
	background-color: ${background[1]};
	border-right: 1px solid ${neutral[4]};

	${media.wideDesktop`
		position: relative;
		max-width: 30rem;
		width: calc(100% / 3);
	`}
`;

const IconWrapper = styled(Icon)`
	margin-right: 0.5rem;
`;

const StyledPanelHeader = styled.div`
	height: 3.125rem;
	display: flex;
	position: sticky;
	top: 0;
	align-items: center;
	padding: 1rem;
	flex-shrink: 0;
	background: ${background[1]};
	z-index: 1;
	border-bottom: 1px solid ${neutral[4]};
	font-size: 1rem;
	color: ${neutral[1]};
	cursor: default;
`;

export const PanelContent = styled.div<{ connected: boolean }>`
	padding: 0.5rem;
	padding-bottom: ${({ connected }) => (connected ? '10rem' : '0')};
	background-color: ${({ connected }) =>
		connected ? background[1] : neutral[4]};
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;
export const NotConnectedContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 6rem;
	padding-bottom: 6rem;
	flex-grow: 1;
	color: ${neutral[1]};
`;

export const EntitiesListContainer = styled.div`
	margin-top: 1.5rem;
`;

export const EntitiesListLabel = styled.span`
	font-size: 0.75rem;
	display: inline-block;
	margin-left: 0.5rem;
	margin-bottom: 0.25rem;
	color: ${neutral[1]};
`;

const MobileButton = styled(Button)<{ hideOnDesktop: boolean }>`
	margin-right: 0.25rem;

	${({ hideOnDesktop }) => media.desktop`
		display: ${hideOnDesktop ? 'none' : 'block'}
	`}

	${media.wideDesktop`
		display: none;
		margin-right: 0;
		margin-left: 0;
	`}
`;

type PanelHeaderProps = {
	title: string;
	icon: React.ReactNode;
	previousPageUrl: string;
};

export const PanelHeader = ({
	title,
	icon,
	previousPageUrl,
}: PanelHeaderProps) => {
	return (
		<StyledPanelHeader>
			<MobileButton
				icon={<ArrowLeftIcon />}
				to={previousPageUrl}
				hideOnDesktop={!previousPageUrl}
			/>
			<IconWrapper>{icon}</IconWrapper>
			{`${title} – Configuration`}
		</StyledPanelHeader>
	);
};

const DataSourceSettings = () => {
	const { dataSourceId, tableId, attributeId } = useParams<{
		dataSourceId: string;
		tableId?: string;
		attributeId?: string;
	}>();
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	if (!workspace) {
		return null;
	}
	return (
		<StyledDataSourceSettings>
			<DataSourceSettingsPanel
				dataSourceId={Number(dataSourceId)}
				workspace={workspace}
			/>
			{tableId && (
				<TableSettingsPanel
					dataSourceId={Number(dataSourceId)}
					tableId={Number(tableId)}
					workspace={workspace}
				/>
			)}
			{attributeId && (
				<AttributeSettingsPanel
					dataSourceId={Number(dataSourceId)}
					attributeId={Number(attributeId)}
					workspace={workspace}
				/>
			)}
		</StyledDataSourceSettings>
	);
};

export { DataSourceSettings };
