import styled from 'styled-components';

import { Icon } from 'components/Icon';
import { ReactComponent as ReloadIcon } from 'images/icons/reload.svg';
import { neutral, primary } from 'utils/colors';

import { ActionButton } from './ActionButton';

type Props = {
	children: string;
};

const RefreshViewContainer = styled.div`
	border-top: 1px solid ${neutral[4]};
	padding: 0.375rem 0.5rem;
`;

const RefreshViewButton = styled(ActionButton)`
	color: ${primary.text};
	font-size: inherit;
	display: flex;
	width: 100%;
`;

const StyledIcon = styled(Icon)`
	margin-left: auto;

	& > svg * {
		fill: ${primary.text};
	}
`;

export function RefreshFooter({ children }: Props) {
	return (
		<RefreshViewContainer>
			<RefreshViewButton onClick={() => window.location.reload()}>
				{children}
				<StyledIcon>
					<ReloadIcon />
				</StyledIcon>
			</RefreshViewButton>
		</RefreshViewContainer>
	);
}
