import { Deletion, Edit, Comment, Attribute } from 'typings/models';
import {
	ApiView,
	ApiRecordAttribute,
	Join,
	Sort,
	Filter,
	ApiForeignKey,
	ApiEnum,
	StringCellId,
} from 'typings/serverTypes';

export enum DataSourceValue {
	POSTGRES = 'postgres',
	MYSQL = 'mysql',
	MARIADB = 'mariadb',
	MSSQL = 'mssql',
	AIRTABLE = 'airtable',
	STRIPE = 'stripe',
	SHOPIFY = 'shopify',
	TWILIO = 'twilio',
	CLOSE = 'close',
	REDSHIFT = 'redshift',
}

export interface DbColumnConfig {
	schemaName: string;
	tableName: string;
	name: string;
	type: string;
	isPrimaryKey: boolean;
	isRequired: boolean;
	hasDefault: boolean;
	isGenerated: boolean;
	isIdentity: boolean;
}

export type TableData__View<T extends ViewType = ViewType> = {
	type: 'view';
	view: ApiView<T>;
};

export type TableData__Table = {
	type: 'table';
	id: number;
};

export type TableData<T extends ViewType | void = ViewType | void> =
	T extends ViewType ? TableData__View<T> : TableData__Table;

export type ExtractedTableData = {
	attributes: ApiRecordAttribute[];
	listAttributes: ApiRecordAttribute[];
	joins: Join[];
	sorts: Sort[];
	filters: Filter[];
	foreignKeys: ApiForeignKey[];
	enums: ApiEnum[];
	schemaName: string;
	tableName: string;
	databaseId: number;
	rowHeight: number;
	tableId: number;
	primaryKeyAttributes: Attribute[];
};

export type SelectedRecord = {
	userId: number;
	cellId: StringCellId;
};

export type FormStateField<
	Name extends string,
	Value extends string | boolean | null | Blob | undefined
> = {
	[key in Name]: {
		value: Value;
		errors: string[] | null;
	};
};

type Invoice = {
	id: string;
	amountDue: number;
	date: number;
	pdfUrl: string;
	status: string;
};

export type PaymentMethod = {
	id: string;
	brand: string;
	expMonth: number;
	expYear: number;
	last4: number;
	name: string;
};

export type BillingInformation = {
	credit: number;
	nextPaymentDue: number;
	nextPaymentAmount: number;
	invoices: Invoice[];
	paymentMethod: PaymentMethod;
};

type ActivityType<T, K> = {
	type: T;
} & K;

type StringDates<T extends { createdAt: Date; updatedAt: Date }> = Omit<
	T,
	'createdAt' | 'updatedAt'
> & {
	createdAt: string;
	updatedAt: string;
};

export type ApiActivity =
	| ActivityType<'comment', StringDates<Comment>>
	| ActivityType<'edit', StringDates<Edit>>
	| ActivityType<'deletion', StringDates<Deletion>>;

export enum ViewType {
	BASIC = 'BASIC',
	ADVANCED = 'ADVANCED',
}

export enum Layout {
	TABLE = 'TABLE',
	LIST = 'LIST',
	CHART = 'CHART',
}

export enum JobRole {
	FOUNDER = 'FOUNDER',
	ENGINEERING = 'ENGINEERING',
	CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
	OPERATIONS = 'OPERATIONS',
	DESIGN = 'DESIGN',
	DATA = 'DATA',
	OTHER = 'OTHER',
}

export const Role: {
	ADMIN: 'ADMIN';
	COLLABORATOR: 'COLLABORATOR';
	READ_ONLY: 'READ_ONLY';
} = {
	ADMIN: 'ADMIN',
	COLLABORATOR: 'COLLABORATOR',
	READ_ONLY: 'READ_ONLY',
};

export type Role = typeof Role[keyof typeof Role];

export type DataSourceType = {
	name: string;
	order: number;
	verboseName: string;
	logoLight: string;
	logoDark: string | null;
};

export type DefaultDataSourceValues = {
	hostDefaultValue: string | null;
	portDefaultValue: string | null;
	databaseNameDefaultValue: string | null;
	usernameDefaultValue: string | null;
	sslEnabledDefaultValue: boolean | null;
};

export type HostingProvider = {
	name: string;
	verboseName: string;
	order: number;
	logoLight: string;
	logoDark: string | null;
	hidden: boolean | null;
	dashboardUrl: string | null;
	connectionStringInstructions: string | null;
	connectionStringVideoUrl: string | null;
	credentialsInstructions: string | null;
	credentialsVideoUrl: string | null;
	hasTrustedSource: boolean | null;
	trustedSourceInstructions: string | null;
	trustedSourceVideoUrl: string | null;
	hostLabel: string | null;
	portLabel: string | null;
	databaseNameLabel: string | null;
	usernameLabel: string | null;
	passwordLabel: string | null;
	hasConnectionString: boolean | null;
	connectionStringLabel: string | null;
	trustedSourceLabel: string | null;
	sslEnabledLabel: string | null;
	customSslCertificateLabel: string | null;
	sslClientCertificateLabel: string | null;
	sslClientKeyLabel: string | null;
	sshEnabledLabel: string | null;
	sshHostLabel: string | null;
	sshPortLabel: string | null;
	sshUsernameLabel: string | null;
	sshPasswordLabel: string | null;
	sshKeyEnabledLabel: string | null;
	sshPrivateKeyLabel: string | null;
	sshKeyPassphraseLabel: string | null;
};
