import styled from 'styled-components';

import Button from 'components/Button';
import _Card from 'components/Card';
import { Icon } from 'components/Icon';
import Section from 'components/Section';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { getPlanPrice } from 'utils/plans';
import { media } from 'utils/styles';

const CardContainer = styled.div`
	display: flex;

	${media.desktop`
		margin-left: -1rem;
	`}

	${media.tabletDown`
		flex-direction: column;
	`}
`;

const Card = styled(_Card)`
	${media.desktop`
		width: calc(100% / 3);
		margin-left: 1rem;
	`}

	${media.tabletDown`
		flex-direction: column;

		& + & {
			margin-top: 1rem;
		}
	`}
`;

const PlanName = styled.h4`
	font-size: 1.125rem;
	font-weight: 500;
	cursor: default;
`;

const PlanPriceContainer = styled.div`
	display: flex;
	margin-top: 0.75rem;
	margin-bottom: 1rem;
`;

const PlanPrice = styled.span`
	font-size: 1.5rem;
	cursor: default;
`;

const PlanPriceDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 0.5rem;
	font-size: 0.75rem;
	color: ${neutral[2]};
	line-height: 1;
	cursor: default;
`;

const FeatureContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
`;

const Feature = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.75rem;
	line-height: 1.125;
	cursor: default;
`;

const FeatureCheck = styled.div`
	margin-right: 0.5rem;
`;

interface Props {
	workspace: ApiWorkspaceDetails;
}

export function PlansOverview({ workspace }: Props) {
	const getBasicPlanCta = () => {
		if (workspace.plan === 'Basic') {
			return (
				<Button primary disabled>
					Current plan
				</Button>
			);
		} else if (workspace.plan === 'Enterprise') {
			return (
				<Button primary href="mailto:support@basedash.com">
					Contact sales
				</Button>
			);
		} else {
			return (
				<Button primary to="/settings/downgrade">
					Downgrade
				</Button>
			);
		}
	};
	const getStartupPlanCta = () => {
		if (workspace.plan === 'Startup') {
			return (
				<Button primary disabled>
					Current plan
				</Button>
			);
		} else if (workspace.plan === 'Enterprise') {
			return (
				<Button primary href="mailto:support@basedash.com">
					Contact sales
				</Button>
			);
		} else {
			return (
				<Button primary to="/settings/upgrade">
					Upgrade
				</Button>
			);
		}
	};
	const getEnterprisePlanCta = () => {
		if (workspace.plan === 'Enterprise') {
			return (
				<Button primary disabled>
					Current plan
				</Button>
			);
		} else {
			return (
				<Button primary href="mailto:support@basedash.com">
					Contact sales
				</Button>
			);
		}
	};
	return (
		<Section title="Plans">
			<CardContainer>
				<Card>
					<PlanName>Basic</PlanName>
					<PlanPriceContainer>
						<PlanPrice>Free</PlanPrice>
					</PlanPriceContainer>

					{getBasicPlanCta()}

					<FeatureContainer>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited databases
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Up to 2 collaborators
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							2-week edit history
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Hourly Slack notifications
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Email support
						</Feature>
					</FeatureContainer>
				</Card>
				<Card>
					<PlanName>Startup</PlanName>
					<PlanPriceContainer>
						{/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Startup"' is not assignable to ... Remove this comment to see the full error message */}
						<PlanPrice>${getPlanPrice('Startup')}</PlanPrice>
						<PlanPriceDetails>
							<span>per user</span>
							<span>per month</span>
						</PlanPriceDetails>
					</PlanPriceContainer>

					{getStartupPlanCta()}

					<FeatureContainer>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited databases
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited collaborators
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited edit history
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Real-time Slack notifications
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Email + Slack support
						</Feature>
					</FeatureContainer>
				</Card>
				<Card>
					<PlanName>Enterprise</PlanName>
					<PlanPriceContainer>
						<PlanPrice>Custom</PlanPrice>
					</PlanPriceContainer>

					{getEnterprisePlanCta()}

					<FeatureContainer>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited databases
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited collaborators
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Unlimited edit history
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Real-time Slack notifications
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							Dedicated support manager
						</Feature>
						<Feature>
							<FeatureCheck>
								<Icon>
									<CheckIcon />
								</Icon>
							</FeatureCheck>
							On-premises deployment
						</Feature>
					</FeatureContainer>
				</Card>
			</CardContainer>
		</Section>
	);
}
