import React from 'react';
import styled from 'styled-components';

import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { TableCellContentsText } from 'components/TableCell/TableCellContentsText';
import styles from 'utils/styles';

const ObscuredText = styled(TableCellContentsText)`
	font-family: 'Basehash', ${styles.text.fontFamilyText};
`;

type TextTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	'displayValue' | 'column' | 'rawValue'
>;

export const TextTableCellContents = ({
	displayValue,
	rawValue,
	column,
}: TextTableCellContentsProps): React.ReactElement | null => {
	if (rawValue === '' || rawValue === null) {
		return <StyledNullOrEmptyText>{displayValue}</StyledNullOrEmptyText>;
	}

	if (column.isObscured) {
		return (
			<ObscuredText className="fs-mask highlight-block">
				{displayValue}
			</ObscuredText>
		);
	}

	return (
		<TableCellContentsText className="fs-mask highlight-block">
			{displayValue}
		</TableCellContentsText>
	);
};
