import { Emoji } from 'emoji-mart';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getWorkspaceOverview } from 'api/getWorkspaceOverview';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import { ConnectDataSourceCta } from 'components/ConnectDataSourceCta';
import { Icon } from 'components/Icon';
import Page, { InfoButton, InfoContainer, InfoText } from 'components/Page';
import { viewSort } from 'components/Sidebar/ViewsSidebar';
import Spinner from 'components/Spinner';
import { ReactComponent as HomeIcon } from 'images/icons/home.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { WorkspaceApiView } from 'typings/serverTypes';
import { ViewType } from 'typings/types';
import { background, neutral } from 'utils/colors';
import { canManageDataSources, canManageViews } from 'utils/permissions';
import styles, { media } from 'utils/styles';
import { useCollaborator } from 'utils/useCollaborator';
import { useDocumentTitle } from 'utils/useDocumentTitle';

const CardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	${media.tabletUp`
		padding: 3rem;
	`}

	${media.mobile`
		padding: 1rem;
	`}
`;

const Card = styled(Link)`
	color: ${neutral[1]};
	background: ${background[1]};
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};

	&:hover {
		color: ${neutral[1]};
		background: ${neutral[5]};
	}

	${media.desktop`
		width: calc(100% / 3 - 1rem * 2 / 3);
	`}

	${media.wideDesktop`
		width: calc(100% / 4 - 1rem * 3 / 4);
	`}

	${media.tablet`
		width: calc(100% / 3 - 1rem * 2 / 3);
	`}

	${media.mobile`
		width: 100%;
	`}
`;

const CardTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 0.5rem;
	border-bottom: 1px solid ${neutral[4]};
`;

const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 8rem;
	padding: 0 0.5rem;
`;

const StyledSpinner = styled(Spinner)`
	width: 2rem;
	height: 2rem;
	border-width: 3px;
	margin: auto;
`;

const Metric = styled.span`
	font-size: 3rem;
	font-weight: 500;
`;

export function HomePage() {
	const collaborator = useCollaborator();

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const [
		favoritedViewsRecordCountsByViewId,
		setFavoritedViewsRecordCountsByViewId,
	] = useState<{
		[viewId: number]: number;
	}>({});

	const noViewsExist = workspace?.views.length === 0;
	const favoritedViews: WorkspaceApiView[] =
		workspace?.views.filter((view) => view.favorited === true).sort(viewSort) ??
		[];

	useEffect(() => {
		if (!workspaceId) {
			return;
		}

		getWorkspaceOverview(workspaceId)
			.then((response) => response.json())
			.then(({ viewRecordCounts }) =>
				setFavoritedViewsRecordCountsByViewId(viewRecordCounts)
			);
	}, [workspaceId]);

	useDocumentTitle('Home');

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<HomeIcon />} title="Home" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					{workspace &&
					canManageDataSources(collaborator) &&
					workspace.sqlDatabases.length === 0 ? (
						<ConnectDataSourceCta />
					) : (
						<>
							{noViewsExist ? (
								canManageViews(collaborator) ? (
									<InfoContainer>
										<InfoText>Create your first view to get started.</InfoText>
										<InfoButton
											to="/views/new"
											icon={<PlusIcon />}
											value="New view"
										/>
									</InfoContainer>
								) : (
									<InfoContainer>
										<InfoText>You don't have access to any views yet.</InfoText>
									</InfoContainer>
								)
							) : (
								favoritedViews.length === 0 && (
									<InfoContainer>
										<InfoText>
											Favorite a view to pin it to your Home page.
										</InfoText>
									</InfoContainer>
								)
							)}

							<CardContainer>
								{favoritedViews.map((view) => (
									<Card key={view.id} to={`/views/${view.id}`}>
										<CardTitle>
											<Icon>
												{view.emoji ? (
													<Emoji emoji={view.emoji} size={15} />
												) : view.type === ViewType.BASIC ? (
													<ViewIcon />
												) : (
													<QueryIcon />
												)}
											</Icon>
											{view.name}
										</CardTitle>
										<CardContent>
											{favoritedViewsRecordCountsByViewId[view.id] !==
											undefined ? (
												<>
													<Metric>
														{favoritedViewsRecordCountsByViewId[
															view.id
														]?.toLocaleString()}
													</Metric>
													<span>records</span>
												</>
											) : (
												<StyledSpinner />
											)}
										</CardContent>
									</Card>
								))}
							</CardContainer>
						</>
					)}
				</Content>
			</Container>
		</Page>
	);
}
