import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { recordIdsAreEqual } from 'components/Table/tableUtils';
import { deleteRecords } from 'reduxState/actions';
import { ObjectRecordId } from 'typings/serverTypes';

export type SelectedRecords = ObjectRecordId[];

export const selectedRecordsSlice = createSlice({
	name: 'selectedRecords',
	initialState: [] as SelectedRecords,
	reducers: {
		selectRecords: (
			state,
			action: PayloadAction<SelectedRecords>
		): SelectedRecords => {
			return [...state, ...action.payload];
		},
		deselectRecords: (state, action: PayloadAction<SelectedRecords>) => {
			return state.filter((selectedRecord) => {
				for (const recordId of action.payload) {
					if (recordIdsAreEqual(selectedRecord, recordId)) {
						return false;
					}
				}

				return true;
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deleteRecords, (state, action) => {
			return state.filter((selectedRecordId) => {
				for (const recordId of action.payload.recordIds) {
					if (recordIdsAreEqual(selectedRecordId, recordId)) {
						return false;
					}
				}

				return true;
			});
		});
	},
});

export const { selectRecords, deselectRecords } = selectedRecordsSlice.actions;
export const reducers = selectedRecordsSlice.reducer;
