import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { onPrimary, primary } from 'utils/colors';

interface BannerProps {
	disabled?: boolean;
}

export const Banner = styled(Link)<BannerProps>`
	border: none;
	padding: 0.625rem 1rem;
	background: ${primary[2]};
	color: ${onPrimary};
	text-align: center;
	&:hover {
		background: ${(props) => primary[props.disabled ? 2 : 1]};
		color: ${onPrimary};
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	}
`;
