import { DeepReadonly } from 'utils/constants';
import { getHumanizedString } from 'utils/verboseNames';
import { ValtioState } from 'valtioState';

export const getTableDisplayName = ({
	tablesById,
	tableId,
}: {
	tablesById: DeepReadonly<ValtioState['entities']['tables']['byId']>;
	tableId: number;
}): string => {
	return (
		tablesById[tableId]?.displayName ??
		getHumanizedString(tablesById[tableId]?.tableName ?? '')
	);
};
