import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as actionLog, ActionLog } from 'reduxState/slices/actionLog';
import {
	ConnectedUsers,
	reducer as connectedUsers,
} from 'reduxState/slices/connectedUsers';
import currentMember, {
	CurrentMemberState,
} from 'reduxState/slices/currentMember';
import dataSources, { DataSourcesState } from 'reduxState/slices/dataSources';
import {
	reducer as selectedCells,
	SelectedCells,
} from 'reduxState/slices/selectedCells';
import {
	reducers as selectedRecords,
	SelectedRecords,
} from 'reduxState/slices/selectedRecords';
import sidebarExpanded, {
	SidebarExpandedState,
} from 'reduxState/slices/sidebarExpanded';
import tableStack, { TableStackState } from 'reduxState/slices/tableStack';
import workspaceId, { WorkspaceIdState } from 'reduxState/slices/workspaceId';
import {
	reducer as workspaces,
	WorkspacesState,
} from 'reduxState/slices/workspaces';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['workspaceId', 'currentMember'],
};

const rootReducer = combineReducers<{
	dataSources: DataSourcesState;
	workspaces: WorkspacesState;
	sidebarExpanded: SidebarExpandedState;
	tableStack: TableStackState;
	connectedUsers: ConnectedUsers;
	workspaceId: WorkspaceIdState;
	selectedCells: SelectedCells;
	currentMember: CurrentMemberState;
	selectedRecords: SelectedRecords;
	actionLog: ActionLog;
	_persist: {
		rehydrated: boolean;
	};
}>({
	dataSources,
	workspaces,
	sidebarExpanded,
	tableStack,
	workspaceId,
	currentMember,
	selectedRecords,
	connectedUsers,
	actionLog,
	selectedCells,
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer<RootState>(persistConfig, rootReducer);
