import styled, { css } from 'styled-components';

import { StyledFormAttributeValue } from 'components/StyledFormAttributeValue';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { TableCellContentsText } from 'components/TableCell/TableCellContentsText';
import styles from 'utils/styles';

export const StyledNullOrEmptyText = styled(TableCellContentsText)<{
	// Rich text cells don't have padding since the rich text editor adds its own
	// padding, so we need to add padding manually on null or empty values
	// to compensate
	addDefaultCellPadding?: boolean;
}>`
	opacity: 0.3;
	font-style: italic;

	padding: ${(props) =>
		props.addDefaultCellPadding ? styles.table.cell.padding : '0'};

	${(props) =>
		props.hideOnFocus &&
		css`
			${StyledTableCell}:focus &&,
			${StyledFormAttributeValue}:focus && {
				display: none;
			}
		`};
`;
