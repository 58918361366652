export const toggleChat = () => {
	if (window.Intercom) {
		// Can't show the chat unless we set the `hide_default_launcher` to false. We initialize `hide_default_launcher` to
		// true since we don't want the launcher to be shown by default in the app (only when the user clicks 'chat with us').
		Intercom('update', {
			hide_default_launcher: false,
		});
		window.Intercom('show');
	}
};
