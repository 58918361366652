import { UpdateAttributeSettingsParams } from 'api/updateAttributeSettings';
import { state } from 'valtioState/index';

export const updateAttributeSettings = (
	updatedSettings: UpdateAttributeSettingsParams
): void => {
	const { attributeId, ...settingsToUpdate } = updatedSettings;
	const existingAttribute = state.entities.attributes.byId[attributeId];
	if (existingAttribute) {
		state.entities.attributes.byId[attributeId] = {
			...existingAttribute,
			...settingsToUpdate,
		};
	}
};
