import * as RadixContextMenu from '@radix-ui/react-context-menu';
import styled from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';

export const StyledContextMenuContent = styled(RadixContextMenu.Content)`
	min-width: 14rem;
	padding: 0.25rem;
	background: ${background[1]};
	border: 1px solid ${neutral[1]};
	border-radius: ${styles.global.borderRadius};
`;

const Icon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 0.5rem;

	svg * {
		fill: ${neutral[1]};
	}
`;

const StyledContextMenuItem = styled(RadixContextMenu.Item)`
	display: flex;
	font-size: 1rem;
	color: ${neutral[1]};
	padding: 0.375rem 0.625rem;
	border-radius: ${styles.global.borderRadius};
	cursor: pointer;

	&:hover,
	&:focus {
		background: ${neutral[5]};
		color: ${neutral[1]};
		outline: none;
	}
`;

interface ContextMenuItemProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	as?: string | React.ComponentType<any>;
	onSelect?: () => void;
	to?: string;
	icon?: React.ReactNode;
	value: string;
}

export function ContextMenuItem({
	as,
	onSelect,
	to,
	icon,
	value,
}: ContextMenuItemProps) {
	return (
		<StyledContextMenuItem
			as={as}
			to={to}
			onSelect={onSelect}
			textValue={value}
		>
			{icon && <Icon>{icon}</Icon>}
			{value}
		</StyledContextMenuItem>
	);
}

interface ContextMenuProps {
	content: React.ReactNode;
	enabled?: boolean;
	children: React.ReactNode;
}

export function ContextMenu({ content, enabled, children }: ContextMenuProps) {
	if (enabled === false) {
		return <>{children}</>;
	}

	return (
		<RadixContextMenu.Root>
			<RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>

			<StyledContextMenuContent>{content}</StyledContextMenuContent>
		</RadixContextMenu.Root>
	);
}
