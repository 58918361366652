import { createAsyncThunk } from '@reduxjs/toolkit';

import { removeMember as removeMemberApi } from 'api/removeMember';

export const removeMember = createAsyncThunk(
	'removeMember',
	async (
		{
			workspaceId,
			memberId,
		}: {
			workspaceId: number;
			memberId: number;
		},
		thunkAPI
	) => {
		const response = await removeMemberApi(workspaceId, memberId);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
	}
);
