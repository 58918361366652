import { JobRole } from 'typings/types';

export function signup(
	fullName: string,
	jobRole: JobRole,
	email: string,
	password: string
) {
	return fetch('/auth/signup', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			fullName,
			jobRole,
			email,
			password,
		}),
	});
}
