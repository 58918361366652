import { ApiRecordAttribute } from 'typings/serverTypes';
import { DEFAULT_VISUAL_SYMBOL, NULL_VISUAL_SYMBOL } from 'utils/constants';

export const renderNullOrDefaultValue = ({
	isAddingNewRecord,
	column,
}: {
	isAddingNewRecord?: boolean;
	column: ApiRecordAttribute;
}) => {
	if (
		isAddingNewRecord &&
		(column.hasDefaultInDb || column.isGeneratedInDb || column.isIdentityInDb)
	) {
		return DEFAULT_VISUAL_SYMBOL;
	} else {
		return NULL_VISUAL_SYMBOL;
	}
};
