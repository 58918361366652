import { createAsyncThunk } from '@reduxjs/toolkit';

import { changeName as changeNameApi } from 'api/changeName';

export const changeUserName = createAsyncThunk(
	'account/changeName',
	async ({ userId, name }: { userId: number; name: string }, thunkAPI) => {
		const response = await changeNameApi(name);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
		return { userId, name };
	}
);
