import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { ValtioState } from 'valtioState';

export const extractTableDataForView = (
	data: TableData<ViewType.BASIC>
): ExtractedTableData => {
	return {
		attributes: data.view.attributes,
		listAttributes: data.view.listAttributes,
		joins: data.view.joins,
		sorts: data.view.sorts,
		filters: data.view.filters,
		schemaName: data.view.schemaName ?? '',
		tableName: data.view.tableName ?? '',
		tableId: data.view.baseTableId,
		databaseId: data.view.sqlDatabaseId ?? 0,
		rowHeight: data.view.tableRowHeight,
		foreignKeys: data.view.foreignKeys,
		enums: data.view.enums,
		primaryKeyAttributes: data.view.primaryKeyAttributes,
	};
};

export const extractTableDataForDataSourceTable = (
	tableId: number,
	snap: ValtioState
): ExtractedTableData => {
	const table = snap.entities.tables.byId[tableId];
	const schema = table && snap.entities.schemas.byId[table.schemaId];

	const attributes: ApiRecordAttribute[] = [];
	for (const attributeId of table?.attributeIds ?? []) {
		const attributeFromState = snap.entities.attributes.byId[attributeId];
		if (attributeFromState) {
			attributes.push({ ...attributeFromState, visible: true });
		}
	}

	return {
		attributes,
		// Not possible to view list layout in a data source table
		listAttributes: [],
		// Not possible to have joins in a data source table
		joins: [],
		sorts: table?.sorts ?? [],
		filters: table?.filters ?? [],
		schemaName: schema?.name ?? '',
		tableId,
		tableName: table?.tableName ?? '',
		databaseId: schema?.sqlDatabaseId ?? 0,
		// Not possible to change row height in a data source table
		rowHeight: 1,
		foreignKeys: table?.foreignKeys ?? [],
		enums: table?.enums ?? [],
		primaryKeyAttributes: table?.primaryKeyAttributes ?? [],
	};
};
