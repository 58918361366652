import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import Sidebar from 'components/Sidebar';
import { SidebarContainer } from 'components/Sidebar/SidebarContainer';
import {
	SegmentedController,
	SegmentedControllerItem,
} from 'components/fields/SegmentedController';
import { DatabasesSection } from 'components/pages/NewView/DatabasesSection';
import { TablesSection } from 'components/pages/NewView/TablesSection';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { ApiUser } from 'typings/serverTypes';
import { ViewType } from 'typings/types';

const StyledSidebarContainer = styled(SidebarContainer)`
	padding-top: 1rem;
`;

interface NewViewSidebarProps {
	viewType: ViewType;
	setViewType: (viewType: ViewType) => void;
	tableId: number | null;
	setTableId: (id: number) => void;
	dataSourceId: number | null;
	setDataSourceId: (dataSourceId: number) => void;
}

export function NewViewSidebar({
	viewType,
	setViewType,
	tableId,
	setTableId,
	dataSourceId,
	setDataSourceId,
}: NewViewSidebarProps) {
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: apiWorkspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const handleChangeViewType = (viewType: string) => {
		if (viewType === '') {
			return;
		}

		setViewType(viewType as ViewType);
	};

	if (!user || !apiWorkspace) {
		return null;
	}

	return (
		<Sidebar>
			<StyledSidebarContainer>
				<SegmentedController
					type="single"
					value={viewType}
					onValueChange={handleChangeViewType}
				>
					<SegmentedControllerItem
						value={ViewType.BASIC}
						icon={<ViewIcon />}
						label="Basic"
					/>
					<SegmentedControllerItem
						value={ViewType.ADVANCED}
						icon={<QueryIcon />}
						label="SQL"
					/>
				</SegmentedController>

				{viewType === ViewType.BASIC && (
					<TablesSection tableId={tableId} setTableId={setTableId} />
				)}

				{viewType === ViewType.ADVANCED && (
					<DatabasesSection
						selectedDataSourceId={dataSourceId}
						setSelectedDataSourceId={setDataSourceId}
					/>
				)}
			</StyledSidebarContainer>
		</Sidebar>
	);
}
