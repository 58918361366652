import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Sidebar, { SectionTitle } from 'components/Sidebar';
import { SidebarContainer } from 'components/Sidebar/SidebarContainer';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { SidebarSection } from 'components/Sidebar/SidebarSection';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import {
	basicViewSettingsLabelWidth,
	ContainerWithHorizontalMargin,
} from 'components/pages/ViewSettings/BasicViewSettingsSidebar';
import { ReactComponent as ChartIcon } from 'images/icons/chart.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { ApiUser } from 'typings/serverTypes';
import { TableData__View, ViewType } from 'typings/types';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';

interface ViewSettingsSidebarProps {
	data: TableData__View<ViewType.ADVANCED>;
	dataLoading: boolean;
}

export function AdvancedViewSettingsSidebar({
	data,
	dataLoading,
}: ViewSettingsSidebarProps) {
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const [viewNameValue, setViewNameValue] = useState(
		data?.view.name ?? 'Untitled view'
	);

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.VIEW,
					{ viewId: data?.view.id, workspaceId },
				]);
			},
		}
	);

	const handleChangeViewName = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setViewNameValue(value);
	};

	const handleSubmitViewName = async (event: FormEvent) => {
		event.preventDefault();

		if (!data) {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				name: viewNameValue,
			},
		});
	};

	const handleChangeLayout = async (layout: 'TABLE' | 'CHART') => {
		if (!data) {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				layout,
			},
		});
	};

	if (!user || !workspace) {
		return null;
	}

	return (
		<Sidebar>
			{!dataLoading && data !== null && (
				<SidebarContainer>
					<SidebarSection initiallyExpanded={true} topLevel>
						<ContainerWithHorizontalMargin>
							<Form
								onSubmit={handleSubmitViewName}
								onBlur={handleSubmitViewName}
								noMargin
							>
								<Input
									name="viewName"
									type="text"
									label="Name"
									value={viewNameValue}
									onChange={handleChangeViewName}
									spellCheck={false}
									labelWidth={basicViewSettingsLabelWidth}
									labelSize={'small'}
								/>
							</Form>
						</ContainerWithHorizontalMargin>
					</SidebarSection>

					<SidebarSection
						Header={(props) => <SectionTitle title="Layouts" {...props} />}
						initiallyExpanded={true}
						topLevel
					>
						<SidebarItem
							active={data.view.layout === 'TABLE'}
							onClick={() => handleChangeLayout('TABLE')}
							iconLeft={<TableIcon />}
							title="Table"
						/>
						<SidebarItem
							active={data.view.layout === 'CHART'}
							onClick={() => handleChangeLayout('CHART')}
							iconLeft={<ChartIcon />}
							title="Chart"
						/>
					</SidebarSection>
				</SidebarContainer>
			)}
		</Sidebar>
	);
}
