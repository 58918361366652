import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useApiActvities } from 'api/reactQueryHooks/useApiActvities';
import { Chip } from 'components/Chip';
import Section from 'components/Section';
import { Tooltip } from 'components/Tooltip';
import { UserAvatar } from 'components/UserAvatar';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { User } from 'typings/models';
import { ObjectRecordId, ApiWorkspaceDetails } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayNameFromSchemaAndTableName } from 'utils/getTableDisplayNameFromSchemaAndTableName';
import { getVerboseRecordId } from 'utils/verboseNames';
import { state } from 'valtioState';

import { BeforeAndAfterValues } from '../../BeforeAndAfterValues';
import Card from '../../Card';
import Spinner from '../../Spinner';

const StyledActivity = styled.div`
	padding: 1rem;
	display: flex;

	&:not(:last-child) {
		border-bottom: 1px solid ${neutral[4]};
	}
`;

const SpinnerContainer = styled.div`
	padding-top: 2rem;
	padding-bottom: 2rem;
`;

const UserAvatarContainer = styled.div`
	display: flex;
	flex-shrink: 0;
	width: 2rem;
	height: 2rem;
	margin-right: 0.75rem;
`;

const InfoContainer = styled.div`
	width: 100%;
	overflow: hidden;
`;

const Bold = styled.strong`
	font-weight: 600;
`;

const ChipWithIconContainer = styled.div`
	display: inline-flex;
	position: relative;
	top: 2px;
`;

const DescriptionContainer = styled.div`
	display: flex;
	align-items: center;
	color: ${neutral[1]};
`;

const StyledDescription = styled.div`
	margin-right: 1.5rem;
	cursor: default;
`;

const Timestamp = styled.span`
	flex-shrink: 0;
	margin-left: auto;
	color: ${neutral[2]};
	font-size: 0.75rem;
	font-weight: 400;
	cursor: default;
`;

const Content = styled.div`
	margin-top: 0.75rem;
	color: ${neutral[1]};
`;

const ColumnName = styled.p`
	margin-bottom: 0.25rem;
	color: ${neutral[2]};
	font-size: 0.75rem;
	font-weight: 400;
	cursor: default;
`;

interface ActivityProps {
	content?: React.ReactNode;
	description: JSX.Element;
	user: User | undefined;
	createdAt: string;
}

const Activity = ({ content, description, user, createdAt }: ActivityProps) => {
	return (
		<StyledActivity>
			<UserAvatarContainer>
				<UserAvatar user={user} />
			</UserAvatarContainer>

			<InfoContainer>
				<DescriptionContainer>
					<StyledDescription>{description}</StyledDescription>
					<Tooltip value={format(parseISO(createdAt), 'MMM d yyyy, h:mm a')}>
						<Timestamp>
							{formatDistanceToNowStrict(parseISO(createdAt))} ago
						</Timestamp>
					</Tooltip>
				</DescriptionContainer>

				{content && <Content>{content}</Content>}
			</InfoContainer>
		</StyledActivity>
	);
};

type TableAndDataSourceIconsProps = {
	schemaName: string | null;
	tableName: string;
	sqlDatabaseId: number;
};

const TableAndDataSourceIcons = ({
	schemaName,
	tableName,
	sqlDatabaseId,
}: TableAndDataSourceIconsProps) => {
	const snap = useSnapshot(state);
	const sqlDatabase = snap.entities.sqlDatabases.byId[sqlDatabaseId];
	const tableId =
		snap.tableNamesToIdMap[sqlDatabaseId + '/' + schemaName + '/' + tableName];
	const isDataSourceConnected =
		sqlDatabase &&
		snap.entities.dataSources.byId[sqlDatabase.dataSourceId]?.connected;
	const isTableConnected =
		tableId &&
		snap.entities.tables.byId[tableId]?.connected &&
		isDataSourceConnected;
	return (
		<>
			<ChipWithIconContainer>
				<Chip
					to={
						isTableConnected
							? `/data-sources/${sqlDatabase.dataSourceId}/tables/${tableId}`
							: undefined
					}
					inline
					icon={<TableIcon />}
				>
					{getTableDisplayNameFromSchemaAndTableName({
						tablesById: snap.entities.tables.byId,
						tableNamesToIdMap: snap.tableNamesToIdMap,
						schemaName: schemaName ?? '',
						tableName: tableName,
						sqlDatabaseId: sqlDatabaseId,
					})}
				</Chip>
			</ChipWithIconContainer>{' '}
			<ChipWithIconContainer>
				{sqlDatabase &&
					snap.entities.dataSources.byId[sqlDatabase?.dataSourceId] && (
						<Chip
							icon={<CloudIcon />}
							to={
								isDataSourceConnected
									? `/settings/data-sources/${sqlDatabase.dataSourceId}`
									: undefined
							}
							inline
						>
							{snap.entities.dataSources.byId[sqlDatabase?.dataSourceId]?.name}
						</Chip>
					)}
			</ChipWithIconContainer>
		</>
	);
};

type RecordIdIconProps = {
	schemaName: string | null;
	tableName: string;
	sqlDatabaseId: number;
	recordId: ObjectRecordId;
};

const RecordIdIcon = ({
	schemaName,
	tableName,
	sqlDatabaseId,
	recordId,
}: RecordIdIconProps) => {
	const snap = useSnapshot(state);
	const sqlDatabase = snap.entities.sqlDatabases.byId[sqlDatabaseId];
	const tableId =
		snap.tableNamesToIdMap[sqlDatabaseId + '/' + schemaName + '/' + tableName];
	const isDataSourceConnected =
		sqlDatabase &&
		snap.entities.dataSources.byId[sqlDatabase.dataSourceId]?.connected;
	const isTableConnected =
		tableId &&
		snap.entities.tables.byId[tableId]?.connected &&
		isDataSourceConnected;
	const getRecordIdQueryParams = (recordId: ObjectRecordId) => {
		return Object.keys(recordId.pk)
			.map((columnName) => `${columnName}=${recordId.pk[columnName]}`)
			.join('&');
	};
	return (
		<Chip
			to={
				isTableConnected
					? `/data-sources/${
							sqlDatabase.dataSourceId
					  }/tables/${tableId}?${getRecordIdQueryParams(
							recordId as unknown as ObjectRecordId
					  )}`
					: undefined
			}
			inline
			maskContent
		>
			{getVerboseRecordId(recordId as unknown as ObjectRecordId)}
		</Chip>
	);
};

interface Props {
	workspace: ApiWorkspaceDetails;
}

export function ActivitySection({ workspace }: Props) {
	const snap = useSnapshot(state);
	const { data: activity, isLoading } = useApiActvities(workspace.id);
	const usersById = useMemo(() => {
		return (
			workspace?.collaborators.reduce<{ [userId: string]: User }>(
				(prev, curr) => {
					prev[curr.user.id] = curr.user;
					return prev;
				},
				{}
			) ?? {}
		);
	}, [workspace]);

	return (
		<Section>
			<Card noPadding>
				{isLoading ? (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				) : (
					activity?.map((action, index) => {
						if (action.type === 'edit') {
							const { sqlDatabaseId, schemaName, tableName, columnName } =
								action;
							const attributeKey =
								sqlDatabaseId +
								'/' +
								schemaName +
								'/' +
								tableName +
								'/' +
								columnName;

							const attributeId = snap.attributeNamesToIdMap[attributeKey];
							let isObscured = false;

							if (attributeId) {
								const attribute = snap.entities.attributes.byId[attributeId];
								if (attribute) {
									isObscured = attribute.isObscured;
								}
							}

							return (
								<Activity
									key={index}
									createdAt={action.createdAt}
									user={usersById[action.userId] as User | undefined}
									description={
										<>
											<Bold>
												{usersById[action.userId]
													? usersById[action.userId].fullName
													: 'Someone'}
											</Bold>{' '}
											edited{' '}
											<RecordIdIcon
												schemaName={action.schemaName}
												tableName={action.tableName}
												recordId={action.recordId as unknown as ObjectRecordId}
												sqlDatabaseId={action.sqlDatabaseId}
											/>{' '}
											from{' '}
											<TableAndDataSourceIcons
												sqlDatabaseId={action.sqlDatabaseId}
												tableName={action.tableName}
												schemaName={action.schemaName}
											/>
										</>
									}
									content={
										<>
											<ColumnName>
												{getAttributeDisplayName({
													attributesById: snap.entities.attributes.byId,
													attributeNamesToIdMap: snap.attributeNamesToIdMap,
													attributeName: action.columnName ?? '',
													schemaName: action.schemaName ?? '',
													tableName: action.tableName,
													sqlDatabaseId: action.sqlDatabaseId,
												})}
											</ColumnName>
											<BeforeAndAfterValues
												previousRawValue={action.oldValue}
												newRawValue={action.newValue}
												previousDisplayValue={action.oldValue}
												newDisplayValue={action.newValue}
												type={action.columnType}
												isObscured={isObscured}
												isLongFormat
											/>
										</>
									}
								/>
							);
						} else if (action.type === 'comment') {
							return (
								<Activity
									key={index}
									createdAt={action.createdAt}
									user={usersById[action.userId] as User | undefined}
									description={
										<>
											<Bold>
												{usersById[action.userId]
													? usersById[action.userId].fullName
													: 'Someone'}
											</Bold>{' '}
											commented on{' '}
											<RecordIdIcon
												schemaName={action.schemaName}
												tableName={action.tableName}
												recordId={action.recordId as unknown as ObjectRecordId}
												sqlDatabaseId={action.sqlDatabaseId}
											/>{' '}
											from{' '}
											<TableAndDataSourceIcons
												sqlDatabaseId={action.sqlDatabaseId}
												tableName={action.tableName}
												schemaName={action.schemaName}
											/>
										</>
									}
									content={action.message}
								/>
							);
						} else if (action.type === 'deletion') {
							return (
								<Activity
									key={index}
									createdAt={action.createdAt}
									user={usersById[action.userId] as User | undefined}
									description={
										<>
											<Bold>
												{usersById[action.userId]
													? usersById[action.userId].fullName
													: 'Someone'}
											</Bold>{' '}
											deleted{' '}
											<RecordIdIcon
												schemaName={action.schemaName}
												tableName={action.tableName}
												recordId={action.recordId as unknown as ObjectRecordId}
												sqlDatabaseId={action.sqlDatabaseId}
											/>{' '}
											from{' '}
											<TableAndDataSourceIcons
												sqlDatabaseId={action.sqlDatabaseId}
												tableName={action.tableName}
												schemaName={action.schemaName}
											/>
										</>
									}
								/>
							);
						} else {
							return null;
						}
					})
				)}
				{!isLoading && activity?.length === 0 && (
					<StyledActivity>
						No activity to display. Start editing your data to see activity.
					</StyledActivity>
				)}
			</Card>
		</Section>
	);
}
