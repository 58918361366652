import { CellValue } from 'typings/serverTypes';

export function getCheckedValue(value: CellValue): boolean {
	if (typeof value === 'string') {
		if (value === 'true' || value === '1') {
			return true;
		} else {
			return false;
		}
	}
	if (typeof value === 'object') {
		return true;
	}
	return value;
}
