import styled from 'styled-components';

import { backgroundError, bodyBackground, danger, neutral } from 'utils/colors';
import styles from 'utils/styles';

interface CalloutProps {
	error?: boolean;
}

export const Callout = styled.p<CalloutProps>`
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	line-height: 1.5;
	background: ${({ error }) => (error ? backgroundError : bodyBackground)};
	border: 1px solid ${({ error }) => (error ? danger : neutral[4])};
	border-radius: ${styles.global.borderRadius};
	color: ${neutral[1]};
	cursor: default;
`;
