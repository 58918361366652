import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/Icon';
import { InputRoot } from 'components/fields/Input';
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevronDown.svg';

import Field, { FieldProps } from './Field';

const StyledSelect = styled.select`
	padding-right: 1.5rem !important;
	overflow: hidden;
	text-overflow: ellipsis;
	resize: none;
`;

const SelectContainer = styled.div`
	position: relative;
`;

const DropdownContainer = styled.div`
	position: absolute;
	top: 50%;
	right: 0.375rem;
	transform: translateY(-50%);
	pointer-events: none;
`;

export interface SelectOption<OptionValue extends string | number> {
	label: string;
	value: OptionValue;
	disabled?: boolean;
}

export interface SelectProps<OptionValue extends string | number> {
	className?: string;
	label?: React.ReactNode;
	labelWidth?: FieldProps['labelWidth'];
	labelSize?: FieldProps['labelSize'];
	optional?: boolean;
	errors?: string[] | null;
	helpText?: React.ReactNode;
	name: string;
	value: OptionValue;
	options?: SelectOption<OptionValue>[];
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	autoFocus?: boolean;
	disabled?: boolean;
	highlight?: boolean;
	shadow?: boolean;
	mini?: boolean;
}

export default function Select<OptionValue extends string | number>({
	className,
	label,
	optional,
	errors,
	helpText,
	name,
	value,
	options,
	onChange,
	autoFocus,
	disabled,
	highlight,
	shadow,
	mini,
	labelWidth,
	labelSize,
}: SelectProps<OptionValue>) {
	return (
		<Field
			type="select"
			className={className}
			label={label}
			optional={optional}
			errors={errors}
			helpText={helpText}
			inline={mini}
			labelWidth={labelWidth}
			labelSize={labelSize}
		>
			<SelectContainer>
				<InputRoot
					as={StyledSelect}
					hasLabel={!!label}
					hasHelpText={!!helpText}
					hasErrors={errors && errors.length}
					name={name}
					value={value}
					onChange={onChange}
					autoFocus={autoFocus}
					disabled={disabled}
					highlight={highlight}
					shadow={shadow}
					mini={mini}
				>
					{options?.map((option) => (
						<option
							key={option.value}
							value={option.value}
							disabled={option.disabled}
						>
							{option.label}
						</option>
					))}
				</InputRoot>
				<DropdownContainer>
					<Icon>
						<ChevronDownIcon />
					</Icon>
				</DropdownContainer>
			</SelectContainer>
		</Field>
	);
}
