import React from 'react';
import { EditorValue } from 'react-rte';

import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { StyledCellInput } from 'components/TableCell/EditableTableCell/StyledCellInput';
import { EditableTableCellProps } from 'components/TableCell/EditableTableCell/index';
import { ImageCellContents } from 'components/TableCell/EditableTableCellContents/ImageCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { UseEditableTableRecordValueResult } from 'utils/useEditableRecordValue';

type ImageTableCellProps = Pick<
	EditableTableCellProps,
	| 'isFirstCell'
	| 'isPrimaryAttribute'
	| 'usersById'
	| 'column'
	| 'dataColumn'
	| 'dataRow'
> &
	Pick<
		UseEditableTableRecordValueResult,
		| 'highlighted'
		| 'isEditing'
		| 'canEdit'
		| 'handleKeyDown'
		| 'handleFocus'
		| 'handleBlur'
		| 'cellRef'
		| 'handleDoubleClick'
		| 'selected'
		| 'rowHeight'
		| 'rawValue'
		| 'inputValue'
		| 'handleChange'
	> & { hasCompletedFirstRender: boolean };

export const ImageTableCell = ({
	isFirstCell,
	isPrimaryAttribute,
	highlighted,
	isEditing,
	canEdit,
	handleFocus,
	handleBlur,
	cellRef,
	handleDoubleClick,
	selected,
	rowHeight,
	rawValue,
	handleKeyDown,
	usersById,
	column,
	hasCompletedFirstRender,
	inputValue,
	handleChange,
	dataColumn,
	dataRow,
}: ImageTableCellProps) => {
	if (inputValue instanceof EditorValue) {
		return null;
	}
	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			highlighted={highlighted}
			editing={isEditing}
			editable={canEdit}
			ref={cellRef}
			tabIndex={0}
			onFocus={handleFocus}
			onKeyDown={handleKeyDown}
			data-row={dataRow}
			data-column={dataColumn}
		>
			{isEditing ? (
				<StyledCellInput
					className="fs-mask highlight-ignore"
					value={inputValue || ''}
					onChange={handleChange}
					onBlur={handleBlur}
					selected={selected !== undefined}
					rowHeight={rowHeight}
					autoFocus
				/>
			) : (
				<StyledTableCellContainer
					onDoubleClick={handleDoubleClick}
					selected={selected !== undefined}
					selectColour={selected && usersById[selected.userId]?.avatarColour}
					editable={canEdit}
					rowHeight={rowHeight}
					hasCompletedFirstRender={hasCompletedFirstRender}
				>
					<ImageCellContents column={column} rawValue={rawValue} />
				</StyledTableCellContainer>
			)}
		</StyledTableCell>
	);
};
