import { state } from 'valtioState/index';

export const updateViewRecordsStaleStatus = (
	viewId: number,
	stale: boolean
) => {
	const view = state.entities.views.byId[viewId];
	if (view !== undefined) {
		const value = view.data;
		if (value) {
			state.entities.views.byId[viewId] = {
				...view,
				data: { ...value, staleRecords: stale },
			};
		}
	}
};
