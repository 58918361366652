import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';

const Content = styled(RadixTooltip.Content)`
	background: ${neutral[1]};
	color: ${background[1]};
	border-radius: ${styles.global.borderRadius};
	font-size: 0.75rem;
	padding: 0.125rem 0.25rem;
	max-width: 10rem;
	text-align: center;
`;

const Arrow = styled(RadixTooltip.Arrow)`
	width: 3px;
	height: 10px;
	background: ${neutral[1]};
	border: 1px solid ${background[1]};

	${Content}[data-side='top'] && {
		border-bottom: none;
	}

	${Content}[data-side='bottom'] && {
		border-top: none;
	}

	* {
		/* Hide default Radix UI arrow */
		display: none;
	}
`;

interface TooltipProps {
	value: React.ReactNode;
	children: React.ReactNode;
}

export function Tooltip({ value, children }: TooltipProps) {
	if (value === undefined) {
		return <>{children}</>;
	}
	return (
		<RadixTooltip.Root>
			<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

			<Content side="bottom" align="center">
				<Arrow />
				{value}
			</Content>
		</RadixTooltip.Root>
	);
}
