import { UpdateTableSettingsParams } from 'api/updateTableSettings';
import { state, ValtioState } from 'valtioState/index';

export const updateTableSettings = (
	updatedSettings: UpdateTableSettingsParams &
		Partial<ValtioState['entities']['tables']['byId'][number]>
): void => {
	const { tableId, ...settingsToUpdate } = updatedSettings;
	const existingTable = state.entities.tables.byId[tableId];
	if (existingTable) {
		state.entities.tables.byId[tableId] = {
			...existingTable,
			...settingsToUpdate,
		};
	}
};
