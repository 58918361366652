import React from 'react';
import styled from 'styled-components';

import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
`;

type ImageCellContentsProps = Pick<
	EditableTableCellContentsProps,
	'column' | 'rawValue' | 'isAddingNewRecord'
>;

export const ImageCellContents = ({
	column,
	rawValue,
	isAddingNewRecord,
}: ImageCellContentsProps): React.ReactElement | null => {
	const imageBaseUrl = column.viewOptions?.baseUrl;
	if (rawValue === null || rawValue === '') {
		return (
			<StyledNullOrEmptyText>
				{renderNullOrDefaultValue({ isAddingNewRecord, column })}
			</StyledNullOrEmptyText>
		);
	}
	if (typeof rawValue !== 'string') {
		return null;
	}
	return (
		<Image
			className="fs-exclude highlight-block"
			src={rawValue && `${imageBaseUrl}${rawValue}`}
		/>
	);
};
