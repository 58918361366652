import React from 'react';

import { ForeignKeyPopover } from 'components/ForeignKeyPopover';
import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { EditableTableCellProps } from 'components/TableCell/EditableTableCell/index';
import { ForeignKeyTableCellContents } from 'components/TableCell/EditableTableCellContents/ForeignKeyTableCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { UseEditableTableRecordValueResult } from 'utils/useEditableRecordValue';

type ForeignKeyTableCellProps = Pick<
	EditableTableCellProps,
	| 'isFirstCell'
	| 'isPrimaryAttribute'
	| 'usersById'
	| 'column'
	| 'data'
	| 'routeLocationSearch'
	| 'dataColumn'
	| 'dataRow'
> &
	Pick<
		UseEditableTableRecordValueResult,
		| 'highlighted'
		| 'isEditing'
		| 'canEdit'
		| 'handleKeyDown'
		| 'handleFocus'
		| 'handleBlur'
		| 'cellRef'
		| 'handleDoubleClick'
		| 'selected'
		| 'rowHeight'
		| 'rawValue'
		| 'displayValue'
		| 'setValue'
		| 'foreignKey'
		| 'databaseId'
		| 'tableId'
		| 'primaryAttributeAttribute'
		| 'isDropdownOpen'
		| 'setIsDropdownOpen'
		| 'dataSource'
	> & { hasCompletedFirstRender: boolean };

export const ForeignKeyTableCell = ({
	isFirstCell,
	isPrimaryAttribute,
	highlighted,
	isEditing,
	canEdit,
	handleFocus,
	cellRef,
	handleDoubleClick,
	selected,
	rowHeight,
	rawValue,
	handleKeyDown,
	usersById,
	column,
	hasCompletedFirstRender,
	setValue,
	displayValue,
	foreignKey,
	databaseId,
	tableId,
	primaryAttributeAttribute,
	isDropdownOpen,
	setIsDropdownOpen,
	data,
	dataSource,
	routeLocationSearch,
	dataColumn,
	dataRow,
}: ForeignKeyTableCellProps) => {
	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			highlighted={highlighted}
			editing={isEditing}
			editable={canEdit}
			ref={cellRef}
			tabIndex={0}
			onFocus={handleFocus}
			onKeyDown={handleKeyDown}
			data-row={dataRow}
			data-column={dataColumn}
		>
			<StyledTableCellContainer
				onDoubleClick={handleDoubleClick}
				selected={selected !== undefined}
				selectColour={selected && usersById[selected.userId]?.avatarColour}
				editable={canEdit}
				rowHeight={rowHeight}
				hasCompletedFirstRender={hasCompletedFirstRender}
			>
				<ForeignKeyTableCellContents
					column={column}
					foreignKey={foreignKey}
					data={data}
					databaseId={databaseId}
					dataSource={dataSource}
					routeLocationSearch={routeLocationSearch}
					rawValue={rawValue}
					highlighted={highlighted}
					displayValue={displayValue}
				/>
			</StyledTableCellContainer>
			{foreignKey && tableId !== undefined && isDropdownOpen && (
				<ForeignKeyPopover
					sqlDatabaseId={databaseId}
					tableId={tableId}
					primaryAttributeAttribute={primaryAttributeAttribute}
					foreignKey={foreignKey}
					cellRef={cellRef}
					setValue={setValue}
					setIsDropdownOpen={setIsDropdownOpen}
				/>
			)}
		</StyledTableCell>
	);
};
