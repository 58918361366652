import styled from 'styled-components';
import { DecodedValueMap, SetQuery } from 'use-query-params';

import Card from 'components/Card';
import Section from 'components/Section';
import TextLink from 'components/TextLink';
import { HostingProvider } from 'typings/types';
import { isSequinDataSource } from 'utils/isSequinDataSource';

import { DatabaseSteps as DataSourceSteps, QueryParamsConfig } from '.';
import { NavigationButtons } from './NavigationButtons';
import { OptionsGrid } from './OptionsGrid';

const Paragraph = styled.p`
	line-height: 1.5;
	cursor: default;

	& + & {
		margin-top: 2rem;
	}
`;

interface Props {
	setQuery: SetQuery<QueryParamsConfig>;
	hostingProvider: DecodedValueMap<QueryParamsConfig>['hostingProvider'];
	dataSource: DecodedValueMap<QueryParamsConfig>['dataSource'];
	databaseStep: keyof DataSourceSteps;
	hostingProviders: HostingProvider[] | undefined;
}

export function HostingProviderSection({
	hostingProvider,
	setQuery,
	databaseStep,
	hostingProviders,
	dataSource,
}: Props) {
	if (isSequinDataSource(dataSource)) {
		const dataSourceDisplayName = dataSource
			? dataSource?.charAt(0).toUpperCase() + dataSource?.slice(1)
			: null;

		return (
			<Section title={`${dataSourceDisplayName} connection through Sequin`}>
				<Card>
					<Paragraph>
						We support connecting to {dataSourceDisplayName} through Sequin, a
						third-party service that maintains a read-only copy of your{' '}
						{dataSourceDisplayName} data source as a PostgreSQL database. This
						allows you to explore your data in Basedash with views and SQL
						queries.
					</Paragraph>

					<Paragraph>
						To connect your {dataSourceDisplayName} data source,{' '}
						<TextLink
							as="a"
							href="https://docs.sequin.io/playbooks/basedash"
							target="_blank"
							rel="noopener noreferrer"
						>
							sign up for Sequin
						</TextLink>{' '}
						and follow the instructions to connect your {dataSourceDisplayName}{' '}
						data source.
					</Paragraph>

					<Paragraph>
						Once your database is created, click the next button to enter your
						credentials and connect your database to Basedash.
					</Paragraph>
				</Card>

				<NavigationButtons step={databaseStep} setQuery={setQuery} />
			</Section>
		);
	}

	return (
		<Section title="Where is your data hosted?">
			<Card>
				{hostingProviders !== undefined && (
					<OptionsGrid
						options={hostingProviders.map((hostingProvider) => ({
							type: hostingProvider.name,
							name: hostingProvider.verboseName,
							image: hostingProvider.logoLight,
							imageDarkMode: hostingProvider.logoDark,
						}))}
						checkedType={hostingProvider}
						onChange={(type) => setQuery({ hostingProvider: type })}
					/>
				)}
			</Card>

			<NavigationButtons
				step={databaseStep}
				setQuery={setQuery}
				disabled={hostingProvider === undefined || hostingProvider === null}
			/>
		</Section>
	);
}
