import { ApiView } from 'typings/serverTypes';
import { state } from 'valtioState/index';

export const updateView = (view: { id: number } & Partial<ApiView>) => {
	const viewInStore = state.entities.views.byId[view.id];
	if (viewInStore?.data) {
		state.entities.views.byId[view.id] = {
			loading: false,
			error: null,
			data: {
				...viewInStore.data,
				...(view as ApiView),
				staleRecords: false,
			},
		};
	}
};
