import { useMemo, useState, useEffect } from 'react';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { StyledButton } from 'components/Sidebar/SidebarSection';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { Filter } from 'typings/serverTypes';
import { TableData__View, ExtractedTableData, ViewType } from 'typings/types';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

interface AddFilterProps {
	data: TableData__View<ViewType.BASIC>;
	setOpenFilterId: (filterId: number) => void;
	extractedTableData: ExtractedTableData;
}

export function AddFilterButton({
	data,
	extractedTableData,
	setOpenFilterId,
}: AddFilterProps) {
	const { attributes } = extractedTableData;
	const unobscuredAttributes = attributes.filter(
		(attribute) => !attribute.isObscured
	);
	const savedFilters = useMemo(() => data.view.filters ?? [], [data]);
	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);

	const [filters, setFilters] = useState<Filter[]>(savedFilters);

	useEffect(() => {
		setFilters(savedFilters);
	}, [data, savedFilters]);

	const addFilter = () => {
		const id =
			filters.length === 0
				? 0
				: filters.map((filter) => filter.id).sort((a, b) => b - a)[0] + 1;
		const schemaName = unobscuredAttributes[0].schemaName;
		const tableName = unobscuredAttributes[0].tableName;
		const columnName = unobscuredAttributes[0].name;
		const columnType = unobscuredAttributes[0].typeInDb;
		const attributeType = getAttributeTypeFromDbColumnType({
			dbColumnType: columnType,
		});
		let operator, specifier, content;

		switch (attributeType) {
			case 'NUMBER': {
				operator = '=';
				content = '0';
				break;
			}
			case 'DATETIME':
			case 'DATE': {
				operator = '=';
				specifier = 'exact date';
				content = null;
				break;
			}
			case 'TIME': {
				operator = '=';
				specifier = 'exact time';
				content = null;
				break;
			}
			case 'BOOLEAN': {
				operator = '=';
				specifier = 'is true';
				break;
			}
			case 'ARRAY': {
				operator = '=';
				content = '{}';
				break;
			}
			case 'TEXT':
			case 'JSON':
			default: {
				operator = '=';
				content = '';
				break;
			}
		}

		const filter: Filter = {
			id,
			schemaName,
			tableName,
			columnName,
			attributeType,
			operator,
			specifier,
			content,
		};

		updateApiView({
			view: {
				id: data.view.id,
				filters: [...filters, filter],
			},
		});

		setOpenFilterId(filter.id);
	};

	return (
		<StyledButton title="Add filter" icon={<PlusIcon />} onClick={addFilter} />
	);
}
