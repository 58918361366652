import styled from 'styled-components';

import Page from 'components/Page';
import TextLink from 'components/TextLink';
import { neutral } from 'utils/colors';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	max-width: 50rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 3rem 1rem;
	padding-bottom: 10rem;
`;

const Title = styled.h3`
	margin-bottom: 2rem;
	font-size: 1.5rem;
	font-weight: 500;
	color: ${neutral[1]};
`;

const InfoText = styled.p`
	color: ${neutral[2]};
`;

export default function AppPageNotFound() {
	return (
		<Page>
			<Container>
				<Title>
					Sorry, but we couldn't find the page you were looking for.
				</Title>
				<InfoText>
					You can <TextLink to="/">return to your dashboard</TextLink>, or hang
					out here for a while.
				</InfoText>
			</Container>
		</Page>
	);
}
