import * as ToggleGroup from '@radix-ui/react-toggle-group';
import styled, { css } from 'styled-components';

import { Tooltip } from 'components/Tooltip';
import { neutral } from 'utils/colors';
import styles from 'utils/styles';

export const SegmentedController = styled(ToggleGroup.Root)`
	display: flex;
	box-shadow: 0 0 0 1px ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
`;

const StyledSegmentedControllerItem = styled(ToggleGroup.Item)`
	display: flex;
	align-items: center;
	// Force all items to have the same width
	flex-basis: 0;
	flex-grow: 1;
	justify-content: center;
	padding: 0.5rem 0.375rem;
	font-size: 0.875rem;
	color: ${neutral[1]};
	background: none;
	border: none;
	border-radius: ${styles.global.borderRadius};
	cursor: pointer;

	svg * {
		fill: ${neutral[1]};
	}

	&:hover {
		background: ${neutral[5]};
	}

	&:focus {
		outline: none;
	}

	&[data-state='on'],
	&[data-active-state='true'] {
		box-shadow: 0 0 0 1px ${neutral[1]};
		position: relative;
		z-index: 1;
	}

	@supports not selector(:focus-visible) {
		&:focus {
			box-shadow: 0 0 0 2px ${neutral[1]};
		}
	}

	@supports selector(:focus-visible) {
		// Show focus when using keyboard navigation but not on click
		// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible#selectively_showing_the_focus_indicator
		&:focus-visible {
			box-shadow: 0 0 0 2px ${neutral[1]};
		}
	}
`;

const IconContainer = styled.div<{ hasLabel: boolean }>`
	height: 15px;

	${({ hasLabel }) =>
		hasLabel &&
		css`
			margin-right: 0.375rem;
		`}
`;

type SegmentedControllerItemProps = {
	value: string;
	icon?: React.ReactNode;
	label?: React.ReactNode;
} & (
	| // Hack: Need to manually define `active` status when `title` is set
	{
			title: string;
			active: boolean;
	  }
	| {
			title?: never;
			active?: never;
	  }
);

export function SegmentedControllerItem({
	value,
	active,
	icon,
	label,
	title,
}: SegmentedControllerItemProps) {
	const renderItem = () => (
		<StyledSegmentedControllerItem value={value} data-active-state={active}>
			{icon && (
				<IconContainer hasLabel={label !== undefined}>{icon}</IconContainer>
			)}
			{label}
		</StyledSegmentedControllerItem>
	);

	if (title) {
		return <Tooltip value={title}>{renderItem()}</Tooltip>;
	}

	return renderItem();
}
