import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Page from 'components/Page';
import { ViewType } from 'typings/types';
import { toast } from 'utils/toast/toast';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { useView } from 'utils/useView';

import { QueryContent } from './QueryContent';
import { ViewContent } from './ViewContent';

export default function View() {
	const [errorDisplayed, setErrorDisplayed] = useState(false);
	const params = useParams<{ viewId: string }>();
	const viewId = Number(params.viewId);
	const { view, loading, error } = useView(viewId);

	useEffect(() => {
		if (!errorDisplayed && !loading && error) {
			setErrorDisplayed(true);
			toast.error('Unable to load view.');
		}
	}, [error, errorDisplayed, loading, viewId]);

	// Reset error state whenever changing views
	useEffect(() => {
		setErrorDisplayed(false);
	}, [viewId]);

	useDocumentTitle(view?.name);

	if (view && !view.isPublished) {
		return <Redirect to={`/views/${viewId}/settings`} />;
	}

	if (loading || !view) {
		return null;
	}

	return (
		<Page>
			{/* @ts-expect-error Readonly issues */}
			{view.type === ViewType.BASIC && <ViewContent view={view} />}
			{/* @ts-expect-error Readonly issues */}
			{view.type === ViewType.ADVANCED && <QueryContent view={view} />}
		</Page>
	);
}
