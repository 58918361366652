import { getDefaultHeaders } from 'api/utils';

export type UpdateTableSettingsParams = {
	tableId: number;
	editable?: boolean;
	connected?: boolean;
	displayName?: string;
};
export type UpdateTableSettingsResponse = {
	editable: boolean;
	connected: boolean;
	displayName: string;
};

export function updateTableSettings({
	tableId,
	...rest
}: UpdateTableSettingsParams) {
	return fetch(`/api/tables/${tableId}`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify(rest),
	});
}
