import { useEffect, useState } from 'react';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { StyledButton } from 'components/Sidebar/SidebarSection';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { Sort } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

interface AddSortButtonProps {
	data: TableData<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
}

export function AddSortButton({
	data,
	extractedTableData,
}: AddSortButtonProps) {
	const { attributes, sorts: savedSorts } = extractedTableData;

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);

	const [sorts, setSorts] = useState<Sort[]>([]);

	const availableSorts = (includedSort: Sort | null = null) => {
		return (
			attributes.filter((column) => {
				for (const sort of sorts) {
					if (
						includedSort &&
						column.schemaName === includedSort.schemaName &&
						column.tableName === includedSort.tableName &&
						column.attributeName === includedSort.columnName
					) {
						break;
					}

					if (
						column.schemaName === sort.schemaName &&
						column.tableName === sort.tableName &&
						column.attributeName === sort.columnName
					) {
						return false;
					}
				}
				return true;
			}) ?? []
		);
	};

	const [disabled, setDisabled] = useState<boolean>(
		availableSorts().length - 1 === 0
	);

	useEffect(() => {
		setSorts(savedSorts);
	}, [savedSorts]);

	const addSort = async () => {
		const availableSortsLength = availableSorts().length;

		if (availableSortsLength !== 0) {
			const column = availableSorts()[0];
			const updatedSorts = [
				...sorts,
				{
					schemaName: column.schemaName,
					tableName: column.tableName,
					columnName: column.attributeName,
					ascending: true,
				},
			];

			updateApiView({ view: { id: data.view.id, sorts: updatedSorts } });
			setDisabled(availableSortsLength - 1 === 0);
		}
	};

	return (
		<StyledButton
			title="Add sort"
			icon={<PlusIcon />}
			onClick={addSort}
			disabled={disabled}
		/>
	);
}
