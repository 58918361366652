import React, { useEffect, useState } from 'react';

import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { ReadOnlyCellContents } from 'components/TableCell/ReadOnlyTableCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { TableCellProps } from 'components/TableCell/index';
import { AdvancedViewAttribute } from 'typings/serverTypes';
import { TableData, ViewType } from 'typings/types';
import { useReadOnlyRecordValue } from 'utils/useReadOnlyRecordValue';

type ApiRecordAttributeTableCellProps = TableCellProps & {
	column: AdvancedViewAttribute;
	data: TableData<ViewType.ADVANCED>;
	lastRowIndex: number;
	lastVisibleColumnIndex: number;
};

export const ReadOnlyTableCell = ({
	column,
	record,
	isFirstCell,
	isPrimaryAttribute,
	data,
	scrollToRowIndex,
	scrollToColumnIndex,
	lastRowIndex,
	lastVisibleColumnIndex,
}: ApiRecordAttributeTableCellProps) => {
	const { rawValue, rowHeight, handleKeyDown, displayValue } =
		useReadOnlyRecordValue({
			column,
			record,
			data,
			scrollToColumnIndex,
			scrollToRowIndex,
			lastRowIndex,
			lastVisibleColumnIndex,
		});

	// Let the cell render at default width for 1 cycle, then set the width
	const [defaultWidth, setDefaultWidth] = useState(true);
	useEffect(() => {
		setDefaultWidth(false);
	}, []);

	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			editable={false}
		>
			<StyledTableCellContainer
				// ref={cellRef}
				tabIndex={0}
				onKeyDown={handleKeyDown}
				editable={false}
				rowHeight={rowHeight}
				hasCompletedFirstRender={defaultWidth}
			>
				<ReadOnlyCellContents rawValue={rawValue} displayValue={displayValue} />
			</StyledTableCellContainer>
		</StyledTableCell>
	);
};
