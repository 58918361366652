import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { signup } from 'api/signup';
import { updateUser } from 'api/updateUser';
import { SignInWithGoogleButton } from 'components/SignInWithGoogleButton';
import TextLink from 'components/TextLink';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import Select from 'components/fields/Select';
import { Form } from 'components/forms/Form';
import { useToken } from 'components/providers/TokenProvider';
import { ApiUser } from 'typings/serverTypes';
import { FormStateField, JobRole } from 'typings/types';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import styles from 'utils/styles';

const TermsText = styled.p`
	color: ${styles.text.colourTertiary};
	margin-top: 2.5rem;
`;

const LoginText = styled.p`
	margin-top: 2.5rem;
`;

export const signupLabelWidth = 6;

export function SignupForm() {
	const location = useLocation();
	const { setToken } = useToken();
	const fullName = new URLSearchParams(location.search).get('name');
	const jobRole = new URLSearchParams(location.search).get('role');
	const email = new URLSearchParams(location.search).get('email');

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;
	const userId = user?.id ?? null;

	const [loading, setLoading] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [fields, setFields] = useState<
		FormStateField<'fullName', string> &
			FormStateField<'jobRole', JobRole | ''> &
			FormStateField<'email', string> &
			FormStateField<'password', string>
	>({
		fullName: {
			value: fullName || '',
			errors: null,
		},
		jobRole: {
			value: (jobRole as JobRole) || '',
			errors: null,
		},
		email: {
			value: email || '',
			errors: null,
		},
		password: {
			value: '',
			errors: null,
		},
	});

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value } = event.target;

		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);

		if (fields.jobRole.value === '') {
			setFields({
				...fields,
				jobRole: {
					...fields.jobRole,
					errors: ['Required.'],
				},
			});
			setLoading(false);
			return;
		}

		try {
			const response = await signup(
				fields.fullName.value,
				fields.jobRole.value as JobRole,
				fields.email.value,
				fields.password.value
			);
			if (!response.ok) {
				const error: {
					readonly formErrors: {
						fullName: string[];
						email: string[];
						password: string[];
					};
				} = await response.json();
				const newFields = fields;
				for (const field of Object.keys(error.formErrors)) {
					if (
						error.formErrors[field as keyof typeof error['formErrors']].length
					) {
						// @ts-expect-error FIXME
						newFields[field].errors = error.formErrors[field];
					} else {
						// @ts-expect-error FIXME
						newFields[field].errors = null;
					}
				}

				setFields({ ...fields, ...newFields });
			}

			const token = await response.json();
			setToken(token);

			await updateUser();
			queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(
		() => {
			if (user) {
				window.analytics.identify(String(user.id), {
					name: user.fullName,
					email: user.email,
				});
				window.analytics.track('User Account Created', {});

				setRedirect(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userId]
	);

	if (redirect) {
		return <Redirect to="/welcome" />;
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="fullName"
				type="text"
				label="Name"
				value={fields.fullName.value}
				errors={fields.fullName.errors}
				onChange={handleChange}
				autoFocus={!fullName}
				labelWidth={signupLabelWidth}
			/>
			<Select
				name="jobRole"
				label="Role"
				value={fields.jobRole.value}
				errors={fields.jobRole.errors}
				options={[
					{ value: '', label: 'Select an option' },
					{ value: JobRole.FOUNDER, label: 'Founder' },
					{ value: JobRole.ENGINEERING, label: 'Engineering' },
					{ value: JobRole.CUSTOMER_SUPPORT, label: 'Customer support' },
					{ value: JobRole.OPERATIONS, label: 'Operations' },
					{ value: JobRole.DESIGN, label: 'Design' },
					{ value: JobRole.DATA, label: 'Data' },
					{ value: JobRole.OTHER, label: 'Other' },
				]}
				onChange={handleChange}
				autoFocus={Boolean(fullName && !jobRole)}
				labelWidth={signupLabelWidth}
			/>
			<Input
				name="email"
				type="email"
				label="Email"
				value={fields.email.value}
				errors={fields.email.errors}
				onChange={handleChange}
				autoFocus={Boolean(fullName && jobRole && !email)}
				labelWidth={signupLabelWidth}
			/>
			<Input
				name="password"
				type="password"
				label="Password"
				value={fields.password.value}
				errors={fields.password.errors}
				onChange={handleChange}
				autoFocus={Boolean(fullName && jobRole && email)}
				labelWidth={signupLabelWidth}
			/>
			<FormButton loading={loading}>Get started</FormButton>

			<SignInWithGoogleButton />

			<TermsText>
				By creating an account, you agree to the{' '}
				<TextLink as="a" href="https://www.basedash.com/terms-of-service">
					Terms of Service
				</TextLink>{' '}
				and{' '}
				<TextLink as="a" href="https://www.basedash.com/privacy-policy">
					Privacy Policy
				</TextLink>
				.
			</TermsText>

			<LoginText>
				Already have an account? <TextLink to="/login">Log in</TextLink>
			</LoginText>
		</Form>
	);
}
