import { getDefaultHeaders } from 'api/utils';

export function createWorkspace(name: string) {
	return fetch('/api/workspaces', {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			name,
		}),
	});
}
