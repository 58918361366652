import { UpdateDataSourceSettingsParams } from 'api/updateDataSourceSettings';
import { state } from 'valtioState/index';

export const updateDataSourceSettings = (
	updatedSettings: UpdateDataSourceSettingsParams
): void => {
	const { dataSourceId, ...settingsToUpdate } = updatedSettings;
	const existingDataSource = state.entities.dataSources.byId[dataSourceId];
	if (existingDataSource) {
		state.entities.dataSources.byId[dataSourceId] = {
			...existingDataSource,
			...settingsToUpdate,
		};
	}
};
