import * as Popover from '@radix-ui/react-popover';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { fetchTableRecords } from 'api/fetchTableRecords';
import DropdownLink from 'components/DropdownLink';
import { ListItem } from 'components/List';
import DropdownList from 'components/List/DropdownList';
import Spinner from 'components/Spinner';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { ApiAttribute, ApiForeignKey } from 'typings/serverTypes';
import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { state } from 'valtioState';

const SpinnerContainer = styled.div`
	width: 100%;
	padding: 1rem;
`;

const StyledSpinner = styled(Spinner)`
	width: 2rem;
	height: 2rem;
	border-width: 3px;
	margin: auto;
`;

const StyledForm = styled(Form)`
	width: 17rem;
	padding-bottom: 0.375rem;
`;

const PrimaryAttributeValue = styled.span`
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PrimaryKeyValue = styled.span`
	padding-left: 0.5rem;
	flex-basis: 3rem;
	flex-shrink: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: auto;
	text-align: right;
	color: ${neutral[2]};
`;

const PopoverContent = styled(Popover.Content)`
	background-color: ${background[1]};
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	padding: 0.625rem;
`;

const StyledDropdownList = styled(DropdownList)`
	width: 17rem;
	max-height: 15.5rem;
	overflow: scroll;
	margin: 0;

	* {
		width: 100%;
	}
`;

type Props = {
	sqlDatabaseId: number;
	tableId: number;
	primaryAttributeAttribute: ApiAttribute | undefined;
	foreignKey: ApiForeignKey;
	cellRef: React.MutableRefObject<HTMLTableDataCellElement | null>;
	setNewRecordValue?: (value: string | boolean | null) => void;
	setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & (
	| {
			setNewRecordValue?: never;
			setValue: ({
				valueAfter,
				formattedValueAfter,
			}: {
				valueAfter: boolean | string | null;
				formattedValueAfter?: string | boolean | null;
			}) => Promise<void>;
	  }
	| {
			setNewRecordValue: (value: string | boolean | null) => void;
			setValue?: never;
	  }
);

export function ForeignKeyPopover({
	primaryAttributeAttribute,
	foreignKey,
	cellRef,
	setValue,
	setNewRecordValue,
	setIsDropdownOpen,
	tableId,
	sqlDatabaseId,
}: Props) {
	const snap = useSnapshot(state);
	const foreignKeyAttributeId =
		snap.attributeNamesToIdMap[
			sqlDatabaseId +
				'/' +
				foreignKey.foreignSchemaName +
				'/' +
				foreignKey.foreignTableName +
				'/' +
				foreignKey.foreignColumnName
		];
	const foreignKeyAttribute =
		foreignKeyAttributeId &&
		snap.entities.attributes.byId[foreignKeyAttributeId];
	const [dropdownSearchValue, setDropdownSearchValue] = useState('');
	const [dropdownRecords, setDropdownRecords] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const handleEscapeKeyDown = () => {
		setIsDropdownOpen(false);
		setDropdownRecords([]);
		if (cellRef.current) {
			cellRef.current.focus();
		}
	};
	const handleChangeForeignKey = ({
		label,
		value,
	}: {
		label: string;
		value: string;
	}): void => {
		if (setValue) {
			setValue({ valueAfter: value.toString(), formattedValueAfter: label });
		}
		if (setNewRecordValue) {
			setNewRecordValue(value.toString());
		}
		setIsDropdownOpen(false);
		if (cellRef.current) {
			cellRef.current.focus();
		}
	};
	const fetchDropdownRecords = useCallback(
		(searchValue: string) => {
			setDropdownSearchValue(searchValue);

			if (!foreignKeyAttribute) {
				return;
			}

			const foreignTableId = foreignKeyAttribute.tableId;

			if (!foreignKey || tableId == null || foreignTableId === undefined) {
				toast.error('Problem fetching foreign keys');
				return;
			}

			setIsLoading(true);

			const pkAttribute = {
				schemaName: foreignKey.foreignSchemaName,
				tableName: foreignKey.foreignTableName,
				name: foreignKey.foreignColumnName,
				visible: true,
				isPrimaryKey: true,
			};
			const attributes = [pkAttribute];
			if (primaryAttributeAttribute) {
				attributes.push({
					schemaName: primaryAttributeAttribute.schemaName,
					tableName: primaryAttributeAttribute.tableName,
					name: primaryAttributeAttribute.attributeName,
					visible: true,
					isPrimaryKey: false,
				});
			}
			fetchTableRecords({
				tableId: foreignTableId,
				attributes,
				filters: [],
				sorts: [],
				pageNumber: 0,
				searchQuery: searchValue,
			})
				.then((res) => res.json())
				.then(({ records }) => {
					setDropdownRecords(records);
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[foreignKeyAttribute, foreignKey, tableId, primaryAttributeAttribute]
	);
	const handleChangeDropdownSearch = async (
		event: ChangeEvent<HTMLInputElement>
	) => {
		const searchValue = event.target.value;
		fetchDropdownRecords(searchValue);
	};

	useEffect(() => {
		fetchDropdownRecords('');
	}, [fetchDropdownRecords]);

	return (
		// Always open, because this component is conditionally rendered by its parent
		<Popover.Root open onOpenChange={setIsDropdownOpen}>
			<Popover.Anchor />
			<PopoverContent align={'start'} onEscapeKeyDown={handleEscapeKeyDown}>
				<StyledForm noMargin onSubmit={(event) => event.preventDefault()}>
					<Input
						className="fs-mask highlight-ignore"
						name="foreignKeySearch"
						placeholder="Search"
						type="text"
						value={dropdownSearchValue}
						onChange={handleChangeDropdownSearch}
						autoFocus
					/>
				</StyledForm>

				{isLoading ? (
					<SpinnerContainer>
						<StyledSpinner />
					</SpinnerContainer>
				) : (
					<StyledDropdownList>
						{dropdownRecords &&
							foreignKeyAttribute &&
							dropdownRecords.map((record) => {
								const primaryKeyValue = record[foreignKeyAttribute.id];
								const primaryAttributeValue = primaryAttributeAttribute
									? record[primaryAttributeAttribute.id]
									: primaryKeyValue;

								return (
									<ListItem key={JSON.stringify(primaryKeyValue)}>
										<DropdownLink
											className="fs-mask highlight-block"
											as="button"
											onClick={() =>
												handleChangeForeignKey({
													label: primaryAttributeValue,
													value: primaryKeyValue,
												})
											}
										>
											<PrimaryAttributeValue>
												{primaryAttributeValue}
											</PrimaryAttributeValue>

											{primaryAttributeAttribute && (
												<PrimaryKeyValue>{primaryKeyValue}</PrimaryKeyValue>
											)}
										</DropdownLink>
									</ListItem>
								);
							})}
					</StyledDropdownList>
				)}
			</PopoverContent>
		</Popover.Root>
	);
}
