import { getDefaultHeaders } from 'api/utils';

export function enableTwoFactorAuthentication(
	countryCode: string,
	phoneNumber: string
) {
	return fetch('/api/user/two-factor-authentication', {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			countryCode,
			phoneNumber,
		}),
	});
}
