import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Callout } from 'components/Callout';
import TextLink from 'components/TextLink';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import Radio from 'components/fields/Radio';
import { Form as _Form } from 'components/forms/Form';
import { useAppDispatch } from 'reduxState/store';
import { inviteMember } from 'reduxState/thunks/inviteMember';
import { MemberInvitation } from 'typings/models';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { Role } from 'typings/types';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { canInviteMembersAsRole } from 'utils/permissions';
import { basicPlanMemberLimit } from 'utils/plans';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';
import { getRoleDescription, getVerboseRole } from 'utils/verboseNames';

const InviteMemberComponent = styled.div`
	width: 100%;
	padding: 1.5rem;
`;

const Title = styled.h4`
	margin-bottom: -0.25rem;
	font-size: 1.25rem;
	font-weight: 500;
	color: ${neutral[1]};
	cursor: default;
`;

const Form = styled(_Form)`
	max-width: 100%;
`;

interface Props {
	workspace: ApiWorkspaceDetails;
	workspaceInvitations: MemberInvitation[];
}

export function InviteMember({ workspace, workspaceInvitations }: Props) {
	const dispatch = useAppDispatch();
	const member = useCollaborator();

	const queryClient = useQueryClient();
	const memberCount = workspace.collaborators.length;
	const workspaceInvitationCount = workspaceInvitations.length;

	const atMemberLimit =
		workspace?.plan === 'Basic' &&
		workspaceInvitationCount !== null &&
		memberCount !== null &&
		memberCount + workspaceInvitationCount >= basicPlanMemberLimit;

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({
		email: {
			value: '',
			errors: [],
		},
		role: {
			value: member ? member.role : Role.READ_ONLY,
			errors: [],
		},
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFields((prevFields) => ({
			...prevFields,
			[name]: {
				value,
				errors: [],
			},
		}));
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (atMemberLimit) {
			return;
		}

		setLoading(true);

		dispatch(
			inviteMember({
				workspaceId: workspace.id,
				email: fields.email.value,
				role: fields.role.value,
			})
		)
			.unwrap()
			.then(() => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.MEMBER_INVITATIONS,
					workspace.id,
				]);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspace.id,
				]);
				toast('Member invited.');
				setFields((prevFields) => ({
					...prevFields,
					email: {
						value: '',
						errors: [],
					},
				}));
			})
			.catch(() => {
				toast.error('Unable to invite member.');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<InviteMemberComponent>
			<Title>Invite a member</Title>

			{atMemberLimit && (
				<Callout>
					Your workspace is currently on the Basic plan which is limited to{' '}
					{basicPlanMemberLimit} members.
					<br />
					<br />
					<TextLink to="/settings/upgrade">Click here to upgrade</TextLink> and
					unlock unlimited members.
				</Callout>
			)}

			<Form onSubmit={handleSubmit}>
				<Input
					name="email"
					type="email"
					label="Email"
					labelWidth={7}
					value={fields.email.value}
					errors={fields.email.errors}
					onChange={handleChange}
					disabled={loading || atMemberLimit}
					autoFocus
				/>
				<Radio
					name="role"
					label="Role"
					labelWidth={7}
					options={[Role.ADMIN, Role.COLLABORATOR, Role.READ_ONLY].map(
						(role) => ({
							label: getVerboseRole(role) ?? '',
							value: role,
							disabled: !canInviteMembersAsRole(member, role),
							description: getRoleDescription(role),
						})
					)}
					value={fields.role.value ?? ''}
					errors={fields.role.errors}
					onChange={handleChange}
					disabled={loading || atMemberLimit}
				/>
				<FormButton loading={loading} disabled={atMemberLimit}>
					Invite member
				</FormButton>
			</Form>
		</InviteMemberComponent>
	);
}
