import React from 'react';
import { useVirtual } from 'react-virtual';

export function navigateUpWithKeyboard({
	event,
	scrollToRowIndex,
}: {
	event: React.KeyboardEvent<Element>;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
}) {
	const activeTableCell = document.activeElement;
	let columnIndex: null | string = null;
	let rowIndex: null | string = null;
	if (activeTableCell) {
		columnIndex = activeTableCell.getAttribute('data-column');
		rowIndex = activeTableCell.getAttribute('data-row');
	}
	if (columnIndex && rowIndex) {
		const numberRowIndex = parseInt(rowIndex, 10);

		if (event.ctrlKey || event.metaKey) {
			if (scrollToRowIndex) {
				event.preventDefault();
				scrollToRowIndex(0, { align: 'start' });
				setImmediate(() => {
					const tableCellToFocus = document.querySelector(
						`[data-column="${columnIndex}"][data-row="0"]`
					);
					if (tableCellToFocus && tableCellToFocus instanceof HTMLElement) {
						event.preventDefault();
						tableCellToFocus.focus();
					}
				});
			}
		} else {
			const previousRowIndex = numberRowIndex - 1;

			if (previousRowIndex >= 0) {
				const tableCellToFocus = document.querySelector(
					`[data-column="${columnIndex}"][data-row="${previousRowIndex}"]`
				);
				if (tableCellToFocus && tableCellToFocus instanceof HTMLElement) {
					event.preventDefault();
					tableCellToFocus.focus();
				}
			}
		}
	}
}

export function navigateDownWithKeyboard({
	event,
	scrollToRowIndex,
	lastRowIndex,
}: {
	event: React.KeyboardEvent<Element>;
	lastRowIndex: number | undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
}) {
	const activeTableCell = document.activeElement;
	let columnIndex: null | string = null;
	let rowIndex: null | string = null;
	if (activeTableCell) {
		columnIndex = activeTableCell.getAttribute('data-column');
		rowIndex = activeTableCell.getAttribute('data-row');
	}
	if (columnIndex && rowIndex) {
		const numberColumnIndex = parseInt(columnIndex, 10);
		const numberRowIndex = parseInt(rowIndex, 10);

		const nextRowIndex = numberRowIndex + 1;

		if (event.ctrlKey || event.metaKey) {
			if (scrollToRowIndex && lastRowIndex) {
				event.preventDefault();
				scrollToRowIndex(lastRowIndex, { align: 'start' });
				setImmediate(() => {
					const tableCellToFocus = document.querySelector(
						`[data-column="${columnIndex}"][data-row="${lastRowIndex}"]`
					);
					if (tableCellToFocus instanceof HTMLElement) {
						tableCellToFocus.focus();
					}
				});
			}
		} else {
			const tableCellToFocus = document.querySelector(
				`[data-column="${numberColumnIndex}"][data-row="${nextRowIndex}"]`
			);
			if (tableCellToFocus && tableCellToFocus instanceof HTMLElement) {
				event.preventDefault();
				tableCellToFocus.focus();
			}
		}
	}
}

export function navigateRightWithKeyboard({
	event,
	scrollToColumnIndex,
	lastVisibleColumnIndex,
}: {
	event: React.KeyboardEvent<Element>;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	lastVisibleColumnIndex: number | undefined;
}) {
	event.preventDefault();

	const activeTableCell = document.activeElement;
	let columnIndex: null | string = null;
	let rowIndex: null | string = null;
	if (activeTableCell) {
		columnIndex = activeTableCell.getAttribute('data-column');
		rowIndex = activeTableCell.getAttribute('data-row');
	}
	if (columnIndex && rowIndex) {
		const numberColumnIndex = parseInt(columnIndex, 10);

		if (event.ctrlKey || event.metaKey) {
			if (scrollToColumnIndex && lastVisibleColumnIndex) {
				scrollToColumnIndex(lastVisibleColumnIndex, { align: 'start' });
				setImmediate(() => {
					const tableCellToFocus = document.querySelector(
						`[data-column="${
							lastVisibleColumnIndex + 1
						}"][data-row="${rowIndex}"]`
					);
					if (tableCellToFocus instanceof HTMLElement) {
						tableCellToFocus.focus();
					}
				});
			}
		} else {
			const nextColumnIndex = numberColumnIndex + 1;

			const tableCellToFocus = document.querySelector(
				`[data-column="${nextColumnIndex}"][data-row="${rowIndex}"]`
			);
			if (tableCellToFocus && tableCellToFocus instanceof HTMLElement) {
				tableCellToFocus.focus();
			}
		}
	}
}

export function navigateLeftWithKeyboard({
	event,
	scrollToColumnIndex,
}: {
	event: React.KeyboardEvent<Element>;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
}) {
	event.preventDefault();

	const activeTableCell = document.activeElement;
	let columnIndex: null | string = null;
	let rowIndex: null | string = null;
	if (activeTableCell) {
		columnIndex = activeTableCell.getAttribute('data-column');
		rowIndex = activeTableCell.getAttribute('data-row');
	}
	if (columnIndex && rowIndex) {
		const numberColumnIndex = parseInt(columnIndex, 10);

		if (event.ctrlKey || event.metaKey) {
			if (scrollToColumnIndex) {
				scrollToColumnIndex(0, { align: 'start' });
				setImmediate(() => {
					const tableCellToFocus = document.querySelector(
						`[data-column="0"][data-row="${rowIndex}"]`
					);
					if (tableCellToFocus instanceof HTMLElement) {
						tableCellToFocus.focus();
					}
				});
			}
		} else {
			const previousColumnIndex = numberColumnIndex - 1;
			const tableCellToFocus = document.querySelector(
				`[data-column="${previousColumnIndex}"][data-row="${rowIndex}"]`
			);
			if (tableCellToFocus && tableCellToFocus instanceof HTMLElement) {
				tableCellToFocus.focus();
			}
		}
	}
}
