import React from 'react';
import styled from 'styled-components';

const InfoListComponent = styled.section`
	display: flex;
	flex-direction: column;
`;

const Item = styled.div`
	display: flex;

	& + & {
		margin-top: 1.25rem;
	}
`;

const Key = styled.span`
	flex: 0 1 20%;
	margin-right: 1rem;
	font-weight: 500;
`;

const Value = styled.span`
	width: 75%;
	word-break: break-all;
`;

interface Item {
	key: string;
	value: string | React.ReactNode;
}

interface Props {
	items: Item[];
	maskContent?: boolean;
}

export default function InfoList({ items, maskContent }: Props) {
	return (
		<InfoListComponent>
			{items.map((item) => (
				<Item key={item.key}>
					<Key className={maskContent ? 'fs-mask highlight-block' : undefined}>
						{item.key}
					</Key>
					<Value
						className={maskContent ? 'fs-mask highlight-block' : undefined}
					>
						{item.value}
					</Value>
				</Item>
			))}
		</InfoListComponent>
	);
}
