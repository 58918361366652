import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StringCellId } from 'typings/serverTypes';

export type SelectedCells = { [userId: number]: StringCellId };

const selectedCells = createSlice({
	name: 'selectedCells',
	initialState: {} as SelectedCells,
	reducers: {
		addSelectedCell: (
			state,
			action: PayloadAction<{ userId: number; cellId: StringCellId }>
		) => {
			return {
				...state,
				[action.payload.userId]: action.payload.cellId,
			};
		},
		removeSelectedCell: (state, action: PayloadAction<{ userId: number }>) => {
			const {
				// Remove the cell ID
				[action.payload.userId]: _,
				...selectedCells
			} = state;

			return selectedCells;
		},
	},
});
export const { addSelectedCell, removeSelectedCell } = selectedCells.actions;
export const reducer = selectedCells.reducer;
