import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'components/Card';
import InfoList from 'components/InfoList';
import Section, { Button } from 'components/Section';
import TextLink from 'components/TextLink';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { DataSourceValue } from 'typings/types';
import { getVerboseDataSource } from 'utils/verboseNames';

const Password = styled.span`
	margin-right: 1rem;
`;

interface Props {
	sqlDatabase: ApiWorkspaceDetails['sqlDatabases'][number];
}

export default function DatabaseOverview({ sqlDatabase }: Props) {
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [sshPasswordVisible, setSshPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const toggleSshPasswordVisibility = () => {
		setSshPasswordVisible(!sshPasswordVisible);
	};

	return (
		<Section
			title="Database credentials"
			details={
				<Button as={Link} to={`/databases/${sqlDatabase.id}/update`}>
					Update credentials
				</Button>
			}
		>
			<Card>
				<InfoList
					maskContent
					items={[
						{
							key: 'Host',
							value: sqlDatabase.host ?? '',
						},
						{
							key: 'Port',
							value: sqlDatabase.port ?? '',
						},
						{
							key: 'Database',
							value: sqlDatabase.database ?? '',
						},
						{
							key: 'Dialect',
							value: sqlDatabase.dialect
								? getVerboseDataSource(sqlDatabase.dialect as DataSourceValue)
								: '',
						},
						{
							key: 'Username',
							value: sqlDatabase.username ?? '',
						},
						{
							key: 'Password',
							value: (
								<>
									<Password>
										{passwordVisible
											? sqlDatabase.password
											: sqlDatabase.password?.replace(/./g, '\u2022')}
									</Password>
									<TextLink as="button" onClick={togglePasswordVisibility}>
										{passwordVisible ? 'Hide' : 'Show'}
									</TextLink>
								</>
							),
						},
						{
							key: 'SSL',
							value: sqlDatabase.sslEnabled ? 'Enabled' : 'Disabled',
						},
						...(sqlDatabase.sshEnabled
							? [
									{
										key: 'SSH host',
										value: sqlDatabase.sshHost,
									},
									{
										key: 'SSH port',
										value: sqlDatabase.sshPort,
									},
									{
										key: 'SSH username',
										value: sqlDatabase.sshUsername,
									},
									{
										key: 'SSH password',
										value: !sqlDatabase.sshKeyEnabled ? (
											<>
												<Password>
													{sshPasswordVisible
														? sqlDatabase.sshPassword
														: sqlDatabase.sshPassword?.replace(/./g, '\u2022')}
												</Password>
												<TextLink
													as="button"
													onClick={toggleSshPasswordVisibility}
												>
													{sshPasswordVisible ? 'Hide' : 'Show'}
												</TextLink>
											</>
										) : (
											'Using SSH key'
										),
									},
							  ]
							: []),
					]}
				/>
			</Card>
		</Section>
	);
}
