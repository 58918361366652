import React from 'react';

import { Switch as SwitchComponent } from 'components/Switch';
import Field, { FieldProps } from 'components/fields/Field';

interface Props {
	label?: React.ReactNode;
	labelWidth?: FieldProps['labelWidth'];
	labelSize?: FieldProps['labelSize'];
	optional?: boolean;
	errors?: string[] | null;
	helpText?: string;
	checked: boolean;
	onChange?: () => void;
	disabled?: boolean;
	maskContent?: boolean;
}

export function Switch({
	label,
	labelWidth,
	labelSize,
	optional,
	errors,
	helpText,
	checked,
	onChange,
	disabled,
	maskContent,
}: Props) {
	return (
		<Field
			type="checkbox"
			label={label}
			labelWidth={labelWidth}
			labelSize={labelSize}
			optional={optional}
			errors={errors}
			helpText={helpText}
		>
			<SwitchComponent
				checked={checked}
				onChange={onChange}
				className={maskContent ? 'fs-mask highlight-block' : undefined}
				disabled={disabled}
			/>
		</Field>
	);
}
