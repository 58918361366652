import styled, { css } from 'styled-components';

import { neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

export const StyledCellInput = styled.input<{
	selected?: boolean;
	rowHeight?: number;
}>`
	width: 100%;
	${(props) => css`
		height: calc(
			${styles.table.cell.paddingVertical} * 2 + ${styles.table.row.lineHeight} *
				${props.rowHeight}
		);
	`}
	padding: ${styles.table.cell.padding};
	color: ${neutral[1]};
	font-weight: 400;
	background: transparent;
	overflow: hidden;
	white-space: nowrap;

	&:focus {
		outline: ${primary[1]} solid 2px;
		outline-offset: -2px;
	}
`;
