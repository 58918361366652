import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { primary } from 'utils/colors';

const TextLink = styled(Link)`
	font-size: inherit;
	font-weight: inherit;
	color: ${primary.text};
	background: none;
	border: none;
	cursor: pointer;

	&:hover {
		color: ${primary.text};
		text-decoration: underline;
	}
`;

export default TextLink;
