import { ChangeEvent, FormEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { Chip } from 'components/Chip';
import { DataTypeIcon } from 'components/DataTypeIcon';
import Sidebar, { SectionTitle } from 'components/Sidebar';
import { SidebarContainer } from 'components/Sidebar/SidebarContainer';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { SidebarSection } from 'components/Sidebar/SidebarSection';
import { Input } from 'components/fields/Input';
import Select from 'components/fields/Select';
import { Form } from 'components/forms/Form';
import { ReactComponent as DatabaseIcon } from 'images/icons/database.svg';
import { ReactComponent as ListIcon } from 'images/icons/list.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { ApiRecordAttribute, ApiUser } from 'typings/serverTypes';
import { TableData__View, ExtractedTableData, ViewType } from 'typings/types';
import { areAttributesEqual } from 'utils/areAttributesEqual';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';
import { state } from 'valtioState';

import { AddFilterButton } from './AddFilterButton';
import { AddSortButton } from './AddSortButton';
import { AttributePopover } from './AttributePopover';
import { FilterPopover } from './FilterPopover';
import { JoinPopover } from './JoinPopover';
import { ListAttributesPopover } from './ListAttributesPopover';
import { SortsList } from './SortsList';
import { VisibleAttributesPopover } from './VisibleAttributesPopover';

export const basicViewSettingsLabelWidth = 5;

interface ViewSettingsSidebarProps {
	data: TableData__View<ViewType.BASIC>;
	dataLoading: boolean;
	extractedTableData: ExtractedTableData;
}

const BaseTableNameContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;
`;

const BaseTableNameLabel = styled.span`
	color: ${neutral[1]};
	font-size: 0.75rem;
	width: ${basicViewSettingsLabelWidth}rem;
`;

export const ContainerWithHorizontalMargin = styled.div`
	margin-left: 0.5rem;
	margin-right: 0.5rem;
`;

function filterListAttributes({
	listAttributes,
	attributes,
}: {
	listAttributes: ApiRecordAttribute[];
	attributes: ApiRecordAttribute[];
}) {
	return listAttributes.filter((listAttribute) => {
		const attribute = attributes.find((attribute) =>
			areAttributesEqual({ listAttribute, attribute })
		);
		if (attribute) {
			return attribute.visible;
		} else {
			return false;
		}
	});
}

export function BasicViewSettingsSidebar({
	data,
	extractedTableData,
	dataLoading,
}: ViewSettingsSidebarProps) {
	const snap = useSnapshot(state);
	const { foreignKeys, enums, databaseId, tableName, schemaName } =
		extractedTableData;
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;

	const [viewNameValue, setViewNameValue] = useState(
		data?.view.name ?? 'Untitled view'
	);

	const [openFilterId, setOpenFilterId] = useState(0);

	const table =
		data?.view.baseTableId && snap.entities.tables.byId[data.view.baseTableId];
	const schema = table && snap.entities.schemas.byId[table.schemaId];
	const baseTableForeignKeys = foreignKeys.filter(
		(foreignKey) =>
			table &&
			schema &&
			foreignKey.baseSchemaName === schema.name &&
			foreignKey.baseTableName === table.tableName
	);

	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: databaseId,
		schemaName,
		tableName,
	});

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
			},
		}
	);

	const handleChangeViewName = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setViewNameValue(value);
	};

	const handleSubmitViewName = async (event: FormEvent) => {
		event.preventDefault();

		if (!data) {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				name: viewNameValue,
			},
		});
	};

	const handleChangeLayout = async (layout: 'TABLE' | 'LIST') => {
		if (!data) {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				layout,
			},
		});
	};

	const handleChangeRowHeight = (event: ChangeEvent<HTMLSelectElement>) => {
		event.preventDefault();

		if (!data) {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				tableRowHeight: Number(event.target.value),
			},
		});
	};

	const listAttributes =
		data.view.type === 'BASIC'
			? filterListAttributes({
					listAttributes: data.view.listAttributes,
					attributes: data.view.attributes,
			  })
			: [];

	const primaryAttributeApiRecordAttribute = listAttributes.find((attribute) =>
		primaryAttribute
			? primaryAttribute.schemaName === attribute.schemaName &&
			  primaryAttribute.tableName === attribute.tableName &&
			  primaryAttribute.columnName === attribute.attributeName
			: attribute.isPrimaryKeyInDb
	);
	const listAttributesWithoutPrimaryAttribute = listAttributes.filter(
		(attribute) =>
			primaryAttribute
				? !(
						primaryAttribute.schemaName === attribute.schemaName &&
						primaryAttribute.tableName === attribute.tableName &&
						primaryAttribute.columnName === attribute.attributeName
				  )
				: !attribute.isPrimaryKeyInDb
	);

	if (!user || !workspace) {
		return null;
	}

	return (
		<Sidebar>
			{!dataLoading && data !== null && (
				<SidebarContainer>
					<SidebarSection initiallyExpanded={true} topLevel>
						<ContainerWithHorizontalMargin>
							<Form
								onSubmit={handleSubmitViewName}
								onBlur={handleSubmitViewName}
								noMargin
							>
								<Input
									name="viewName"
									type="text"
									label="Name"
									value={viewNameValue}
									onChange={handleChangeViewName}
									spellCheck={false}
									labelWidth={basicViewSettingsLabelWidth}
									labelSize={'small'}
								/>
								{table && (
									<BaseTableNameContainer>
										<BaseTableNameLabel>Base Table</BaseTableNameLabel>
										<Chip>{table.displayName}</Chip>
									</BaseTableNameContainer>
								)}
							</Form>
						</ContainerWithHorizontalMargin>
					</SidebarSection>

					{baseTableForeignKeys.length >= 1 && (
						<SidebarSection
							Header={(props) => (
								<SectionTitle title="Joined tables" {...props} />
							)}
							button={
								<JoinPopover
									data={data}
									extractedTableData={extractedTableData}
								/>
							}
							initiallyExpanded={true}
							topLevel
							limitHeight
						>
							{data.view.type === 'BASIC' &&
								data.view.joins.map((join) => (
									<SidebarItem
										key={`${join.foreignSchemaName}/${join.foreignTableName}/${join.foreignColumnName}`}
										iconLeft={<DatabaseIcon />}
										title={join.foreignTableName}
									/>
								))}
						</SidebarSection>
					)}

					<SidebarSection
						Header={(props) => <SectionTitle title="Root filters" {...props} />}
						button={
							<AddFilterButton
								data={data}
								extractedTableData={extractedTableData}
								setOpenFilterId={setOpenFilterId}
							/>
						}
						initiallyExpanded={true}
						topLevel
						limitHeight
					>
						{data.view.type === 'BASIC' &&
							data.view.filters.map((filter) => {
								const column =
									data.view.type === 'BASIC' &&
									data.view.attributes.find(
										(attribute) =>
											attribute.schemaName === filter.schemaName &&
											attribute.tableName === filter.tableName &&
											attribute.attributeName === filter.columnName
									);

								if (!column) {
									return;
								}

								return (
									<FilterPopover
										key={filter.id}
										data={data}
										originalFilter={filter}
										workspace={workspace}
										column={column}
										foreignKeys={foreignKeys}
										enums={enums}
										openFilterId={openFilterId}
										setOpenFilterId={setOpenFilterId}
										extractedTableData={extractedTableData}
									/>
								);
							})}
					</SidebarSection>

					<SidebarSection
						Header={(props) => (
							<SectionTitle title="Visible attributes" {...props} />
						)}
						button={
							<VisibleAttributesPopover
								data={data}
								extractedTableData={extractedTableData}
							/>
						}
						initiallyExpanded={true}
						topLevel
						limitHeight
					>
						{data.view.type === 'BASIC' &&
							data.view.attributes
								.filter((attribute) => attribute.visible)
								.map((attribute) => (
									<AttributePopover
										key={`${attribute.id}`}
										tableData={data}
										attribute={attribute}
										extractedTableData={extractedTableData}
									/>
								))}
					</SidebarSection>

					<SidebarSection
						Header={(props) => (
							<SectionTitle title="Default sorts" {...props} />
						)}
						button={
							<AddSortButton
								data={data}
								extractedTableData={extractedTableData}
							/>
						}
						initiallyExpanded={true}
						topLevel
						limitHeight
					>
						<SortsList data={data} extractedTableData={extractedTableData} />
					</SidebarSection>

					<SidebarSection
						Header={(props) => <SectionTitle title="Layouts" {...props} />}
						initiallyExpanded={true}
						topLevel
					>
						<SidebarItem
							active={data.view.layout === 'TABLE'}
							onClick={() => handleChangeLayout('TABLE')}
							iconLeft={<TableIcon />}
							title="Table"
						/>
						<SidebarItem
							active={data.view.layout === 'LIST'}
							onClick={() => handleChangeLayout('LIST')}
							iconLeft={<ListIcon />}
							title="List"
						/>
					</SidebarSection>

					{data.view.layout === 'TABLE' && (
						<SidebarSection initiallyExpanded={true} topLevel>
							<ContainerWithHorizontalMargin>
								<Select
									name="rowHeight"
									label="Row height"
									value={data.view.tableRowHeight}
									labelWidth={basicViewSettingsLabelWidth}
									labelSize={'small'}
									options={[
										{
											label: 'Short',
											value: 1,
										},
										{
											label: 'Medium',
											value: 2,
										},
										{
											label: 'Tall',
											value: 4,
										},
										{
											label: 'Extra tall',
											value: 8,
										},
									]}
									onChange={handleChangeRowHeight}
								/>
							</ContainerWithHorizontalMargin>
						</SidebarSection>
					)}

					{data.view.layout === 'LIST' && (
						<SidebarSection
							Header={(props) => (
								<SectionTitle title="Visible in list" {...props} />
							)}
							button={
								<ListAttributesPopover
									data={data}
									extractedTableData={extractedTableData}
									listAttributes={listAttributes}
									primaryAttributeApiRecordAttribute={
										primaryAttributeApiRecordAttribute
									}
									listAttributesWithoutPrimaryAttribute={
										listAttributesWithoutPrimaryAttribute
									}
								/>
							}
							initiallyExpanded={true}
							topLevel
							limitHeight
						>
							{' '}
							{primaryAttributeApiRecordAttribute && (
								<SidebarItem
									iconLeft={
										<DataTypeIcon
											workspace={workspace}
											sqlDatabaseId={databaseId}
											attribute={primaryAttributeApiRecordAttribute}
											foreignKeys={foreignKeys}
											enums={enums}
										/>
									}
									title={getAttributeDisplayName({
										attributesById: snap.entities.attributes.byId,
										attributeNamesToIdMap: snap.attributeNamesToIdMap,
										attributeName:
											primaryAttributeApiRecordAttribute.attributeName,
										tableName: primaryAttributeApiRecordAttribute.tableName,
										schemaName: primaryAttributeApiRecordAttribute.schemaName,
										sqlDatabaseId: databaseId,
									})}
								/>
							)}
							{data.view.type === 'BASIC' &&
								listAttributes
									.filter((attribute) => attribute.visible)
									.map((attribute) => {
										const attributeDisplayName = getAttributeDisplayName({
											attributesById: snap.entities.attributes.byId,
											attributeNamesToIdMap: snap.attributeNamesToIdMap,
											attributeName: attribute.attributeName,
											tableName: attribute.tableName,
											schemaName: attribute.schemaName,
											sqlDatabaseId: databaseId,
										});
										return (
											<SidebarItem
												key={`${attribute.id}`}
												iconLeft={
													<DataTypeIcon
														workspace={workspace}
														sqlDatabaseId={databaseId}
														attribute={attribute}
														foreignKeys={foreignKeys}
														enums={enums}
													/>
												}
												title={attributeDisplayName}
											/>
										);
									})}
						</SidebarSection>
					)}
				</SidebarContainer>
			)}
		</Sidebar>
	);
}
