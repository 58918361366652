import { useMemo } from 'react';

import { ApiWorkspaceDetails } from 'typings/serverTypes';

interface Props {
	workspace: ApiWorkspaceDetails | undefined;
}

export function usePrimaryAttributes({ workspace }: Props) {
	const primaryAttributes = useMemo(() => {
		if (!workspace) {
			return null;
		}

		return workspace?.primaryAttributes;
	}, [workspace]);

	return primaryAttributes ?? [];
}
