import { getDefaultHeaders } from 'api/utils';
import { ApiActivity } from 'typings/types';

export type FetchActivityParams = number;
export type FetchActivityResponse = ApiActivity[];

export function fetchActivity(workspaceId: number) {
	return fetch(`/api/workspaces/${workspaceId}/activity`, {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
