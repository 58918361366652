import React, { useEffect, useState } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import styled, { css } from 'styled-components';

import { useCreateApiRecord } from 'api/reactQueryHooks/useCreateApiRecord';
import { KeyboardShortcut } from 'components/KeyboardShortcut';
import { FirstCellInRow } from 'components/Table/FirstCellInRow';
import { RowNumberCell } from 'components/Table/VirtualizedCell';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { background, neutral, primary } from 'utils/colors';
import { NEW_ROW_DATA_ROW_ATTRIBUTE_VALUE } from 'utils/constants';
import styles from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { usePrimaryAttributes } from 'utils/usePrimaryAttributes';
import { useWorkspace } from 'utils/useWorkspace';

import NewRecordCell from './NewRecordCell';

const NewRecordRowRoot = styled.div`
	background: ${background[1]};
	display: flex;
`;

const PlusCell = styled(RowNumberCell)`
	color: ${primary.text};
	cursor: default;
`;

const CheckCell = styled(RowNumberCell)`
	color: ${primary.text};
`;

const ClickableRow = styled.div<{
	active: boolean;
	$loading?: boolean;
}>`
	display: flex;
	border-top: 1px solid ${neutral[4]};
	position: absolute;
	bottom: 0;
	width: 100%;
	background: ${background[1]};

	${(props) =>
		!props.$loading &&
		css`
			cursor: pointer;

			&:hover {
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: ${neutral[4]};
					pointer-events: none;
					z-index: 2;
				}
			}
		`}
`;

const CellContainer = styled.span<{ rowHeight?: number }>`
	display: flex;
	align-items: center;
	padding: ${styles.table.cell.padding};
	color: ${neutral[2]};
	height: ${(props) => `calc(
		${styles.table.cell.paddingVertical} * 2 + ${styles.table.row.lineHeight} *
			${props.rowHeight ?? 1}
	)`};
`;

const StickyItems = styled.div`
	position: sticky;
	z-index: 4;
	top: 0;
	left: 0;
`;

const generateDefaultFieldState = (
	attributes: ApiRecordAttribute[],
	schemaName: string | null,
	tableName: string
) => {
	const defaultFields: {
		[key: string]: { value: string | null; errors: string[] | null };
	} = {};
	for (const column of attributes.filter(
		(column) =>
			column.schemaName === schemaName && column.tableName === tableName
	)) {
		defaultFields[column.attributeName] = {
			value: null,
			errors: null,
		};
	}
	return defaultFields;
};

interface NewRecordRowProps {
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	pageNumber: number;
	searchQuery: string;
	reload?: () => void;
	virtualItems: VirtualItem[];
	visibleAttributes: ApiRecordAttribute[];
	primaryAttributeAttribute?: ApiRecordAttribute;
	stickyGridColumnWidths: number[];
	rowHeightInPixels: number;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
}

const NewRecordRow = React.memo(
	({
		data,
		reload,
		extractedTableData,
		scrollToRowIndex,
		scrollToColumnIndex,
		virtualItems,
		visibleAttributes,
		primaryAttributeAttribute,
		stickyGridColumnWidths,
		rowHeightInPixels,
	}: NewRecordRowProps) => {
		const {
			attributes,
			databaseId,
			schemaName,
			tableName,
			tableId,
			rowHeight,
		} = extractedTableData;

		const [isCreatingNewRecord, setIsCreatingNewRecord] = useState(false);
		const [fields, setFields] = useState<{
			[key: string]: {
				value: string | boolean | null | Date;
				errors: string[] | null;
			};
		}>(generateDefaultFieldState(attributes, schemaName, tableName));

		const { workspace } = useWorkspace();
		const primaryAttributes = usePrimaryAttributes({ workspace });

		const { mutate: createApiRecord, isLoading } = useCreateApiRecord({
			onSuccess: () => {
				if (reload) {
					reload();
				}
				const defaultFields: {
					[key: string]: { value: string | null; errors: string[] | null };
				} = {};
				for (const column of attributes.filter(
					(column) =>
						column.schemaName === schemaName && column.tableName === tableName
				)) {
					defaultFields[column.attributeName] = {
						value: null,
						errors: null,
					};
				}
				setIsCreatingNewRecord(false);
				setFields(defaultFields);
				toast('New record added.');
			},
			onError: (error) => {
				if ('formErrors' in error) {
					const updatedFields = { ...fields };
					for (const field of Object.keys(error.formErrors)) {
						if (error.formErrors[field].length) {
							updatedFields[field].errors = error.formErrors[field];
						} else {
							updatedFields[field].errors = null;
						}
					}

					setFields(updatedFields);
					toast.error('Some column values are incorrect or missing.');
				} else if ('original' in error) {
					toast.error('Database error encountered.');
				} else {
					toast.error('Unable to create record');
					console.error(error);
				}
			},
		});

		const setFieldValue =
			(columnName: string) => (value: string | boolean | null | Date) => {
				setFields({
					...fields,
					[columnName]: {
						value,
						errors: null,
					},
				});
			};

		const newRecord = () => {
			setIsCreatingNewRecord(true);

			/* Allow React to do a render cycle before we try to focus on the first input
			   in order for the input to render in the DOM. */
			setTimeout(() => {
				const tableCellToFocus = document.querySelector(
					`[data-column="0"][data-row="${NEW_ROW_DATA_ROW_ATTRIBUTE_VALUE}"]`
				);
				if (tableCellToFocus instanceof HTMLElement) {
					tableCellToFocus.focus();
				}
			}, 0);
		};

		const saveNewRecord = async () => {
			if (isLoading) {
				return;
			}

			const record: { [key: string]: string | boolean | null } = {};
			for (const fieldName of Object.keys(fields)) {
				const value = fields[fieldName].value;
				if (value instanceof Date) {
					record[fieldName] = value.toISOString();
				} else {
					record[fieldName] = value;
				}
			}

			if (databaseId != null && schemaName != null) {
				createApiRecord({ tableId, record });
			}
		};

		useEffect(() => {
			window.addEventListener('keydown', handleKeyDown);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		});

		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.shiftKey && event.key === 'Enter') {
				if (!isCreatingNewRecord) {
					event.preventDefault();
					newRecord();
				}
			}

			if ((event.ctrlKey || event.metaKey) && event.key === 's') {
				if (isCreatingNewRecord) {
					event.preventDefault();
					saveNewRecord();
				}
			}
		};

		if (!isCreatingNewRecord) {
			return (
				<ClickableRow active={isCreatingNewRecord} onClick={newRecord}>
					<FirstCellInRow
						noBorderBottom
						noBackground
						rowHeight={rowHeight}
						isSticky
					>
						<PlusCell>+</PlusCell>
					</FirstCellInRow>
					<StyledTableCell
						style={{ position: 'sticky', left: '4rem', width: '20rem' }}
						noBorder
						noBackground
					>
						<CellContainer>
							New record
							<KeyboardShortcut keys={['Shift', 'Enter']} />
						</CellContainer>
					</StyledTableCell>
				</ClickableRow>
			);
		}

		const stickyColumnsTotalWidth = stickyGridColumnWidths.reduce(
			(a, b) => a + b,
			0
		);

		return (
			<>
				<NewRecordRowRoot>
					<StickyItems
						style={{
							height: rowHeightInPixels,
							width: stickyColumnsTotalWidth,
						}}
					>
						<FirstCellInRow noBackground rowHeight={rowHeight}>
							<PlusCell>+</PlusCell>
						</FirstCellInRow>
						{primaryAttributeAttribute?.visible && (
							<NewRecordCell
								value={fields[primaryAttributeAttribute.attributeName].value}
								setValue={setFieldValue(
									primaryAttributeAttribute.attributeName
								)}
								column={primaryAttributeAttribute}
								data={data}
								extractedTableData={extractedTableData}
								errors={fields[primaryAttributeAttribute.attributeName].errors}
								primaryAttributes={primaryAttributes}
								index={0}
								style={{
									position: 'absolute',
									top: 0,
									left: stickyGridColumnWidths[0],
									width: `${stickyGridColumnWidths[1]}px`,
								}}
								scrollToColumnIndex={scrollToColumnIndex}
								scrollToRowIndex={scrollToRowIndex}
							/>
						)}
					</StickyItems>
					{virtualItems.map((virtualColumn) => {
						const column = visibleAttributes[virtualColumn.index];

						return (
							<NewRecordCell
								key={virtualColumn.index}
								value={fields[column.attributeName].value}
								setValue={setFieldValue(column.attributeName)}
								column={column}
								data={data}
								extractedTableData={extractedTableData}
								errors={fields[column.attributeName].errors}
								primaryAttributes={primaryAttributes}
								index={virtualColumn.index + 1}
								style={{
									position: 'absolute',
									top: 0,
									left: stickyColumnsTotalWidth,
									width: `${virtualColumn.size}px`,
									transform: `translateX(${virtualColumn.start}px) translateY(0px)`,
								}}
								scrollToColumnIndex={scrollToColumnIndex}
								scrollToRowIndex={scrollToRowIndex}
							/>
						);
					})}
				</NewRecordRowRoot>
				<ClickableRow
					active={isCreatingNewRecord}
					onClick={saveNewRecord}
					$loading={isLoading}
				>
					<FirstCellInRow
						noBorderBottom
						noBackground
						rowHeight={rowHeight}
						isSticky
					>
						<CheckCell>✓</CheckCell>
					</FirstCellInRow>
					<StyledTableCell
						style={{ position: 'sticky', left: '4rem', width: '20rem' }}
						noBorder
						noBackground
					>
						<CellContainer>
							{isLoading ? (
								'Saving record'
							) : (
								<>
									Save new record
									<KeyboardShortcut keys={['Ctrl', 'S']} />
								</>
							)}
						</CellContainer>
					</StyledTableCell>
				</ClickableRow>
			</>
		);
	}
);

export default NewRecordRow;
