import styled from 'styled-components';

import Card from 'components/Card';
import LogoLink from 'components/LogoLink';
import RouteContainer from 'components/RouteContainer';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import { SignupForm } from 'components/forms/SignupForm';
import { media } from 'utils/styles';

const StyledRouteContainer = styled(RouteContainer)`
	flex-direction: column;

	html.todesktop-platform-darwin & {
		-webkit-app-region: drag;

		* {
			-webkit-app-region: no-drag;
		}
	}
`;

const Container = styled(SectionContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 40rem;

	${media.desktop`
		padding-top: 2rem;
		padding-bottom: 2rem;
	`};

	${media.tabletUp`
		min-height: 100vh;
	`};

	${media.mobile`
		min-height: 100vh;
		padding-top: 0;
		padding-bottom: 1rem;
	`};
`;

const Title = styled.h2`
	margin: 1rem 0;
	font-size: 2rem;
	font-weight: 500;
	text-align: center;
`;

export function SignupRoute() {
	return (
		<StyledRouteContainer>
			<Container>
				<LogoLink />
				<Section>
					<Card>
						<Title>Sign up</Title>
						<SignupForm />
					</Card>
				</Section>
			</Container>
		</StyledRouteContainer>
	);
}
