// Code from https://stackoverflow.com/a/58110124

/**
 * Ensures that the value is set
 * @param value Value to check
 * @returns True if the value is anything other than null or undefined
 */
export function nonNullable<T>(value: T): value is NonNullable<T> {
	return value !== null && value !== undefined;
}
