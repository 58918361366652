import styled from 'styled-components';
import { SetQuery } from 'use-query-params';

import { Icon } from 'components/Icon';
import { ReactComponent as BackIcon } from 'images/icons/back.svg';
import { neutral } from 'utils/colors';

import { QueryParamsConfig } from '.';

const BackButtonComponent = styled.button`
	display: flex;
	align-items: center;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 0;
	padding: 0.25rem;
	align-self: flex-start;
	background: none;
	border: none;
	color: ${neutral[1]};
	cursor: pointer;
`;

const Text = styled.span`
	display: inline-block;
	margin-left: 0.5rem;
`;

interface Props {
	databaseStep: number;
	setQuery: SetQuery<QueryParamsConfig>;
	className?: string;
}

export function BackButton({ databaseStep, setQuery, className }: Props) {
	const handleClick = () => {
		switch (databaseStep) {
			case 1: {
				setQuery({ hostingProvider: undefined });
				return;
			}
			case 2: {
				setQuery({ dataSourceName: undefined });
				return;
			}
			case 3: {
				setQuery({ trustedSourceCompleted: undefined });
				return;
			}
			case 4: {
				setQuery({ dataSourceDetails: undefined });
				return;
			}
		}
	};

	return (
		<BackButtonComponent onClick={handleClick} className={className}>
			<Icon>
				<BackIcon />
			</Icon>
			<Text>Back</Text>
		</BackButtonComponent>
	);
}
