import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useToken } from './providers/TokenProvider';

const PrivateRoute = ({
	component: Component,
	...rest
}: RouteProps & {
	component?: React.ComponentType;
}) => {
	const { token } = useToken();
	const authenticated = token !== null;
	return (
		<Route
			{...rest}
			render={(props) =>
				authenticated && Component !== undefined ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
