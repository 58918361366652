import { deleteDataSource as removeDataSourceApi } from 'api/deleteDataSource';
import { state } from 'valtioState/index';

export const removeWorkspaceDataSource = async (dataSourceId: number) => {
	const res = await removeDataSourceApi(dataSourceId);
	if (res.ok) {
		delete state.entities.dataSources.byId[dataSourceId];
		state.entities.dataSources.ids = state.entities.dataSources.ids.filter(
			(id) => id !== dataSourceId
		);
	} else {
		throw await res.json();
	}
};
