import { getDefaultHeaders } from 'api/utils';
import {
	CellRecord,
	Filter,
	RecordsColumnConfig,
	Sort,
} from 'typings/serverTypes';

export type FetchTableRecordsParams = {
	tableId: number;
	attributes: RecordsColumnConfig[] | null;
	filters: Filter[];
	sorts: Sort[];
	pageNumber: number | null;
	searchQuery: string | null;
	prettyAttributeNames?: boolean;
};
export type FetchRecordsResponse = {
	numRecords: number;
	records: CellRecord[];
};

export function fetchTableRecords({
	tableId,
	attributes,
	filters,
	sorts,
	pageNumber,
	searchQuery,
	prettyAttributeNames = false,
}: FetchTableRecordsParams) {
	return fetch(`/api/tables/${tableId}/records/get`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			filters,
			sorts,
			attributes,
			pageNumber,
			searchQuery,
			prettyAttributeNames,
		}),
	});
}
