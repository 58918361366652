import { getDefaultHeaders } from 'api/utils';
import { CellRecord } from 'typings/serverTypes';

export type CreateRecordParams = {
	tableId: number;
	record: CellRecord;
};

export function createRecord({ tableId, record }: CreateRecordParams) {
	return fetch(`/api/tables/${tableId}/records`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({ record }),
	});
}
