import { createAsyncThunk } from '@reduxjs/toolkit';

import { enableTwoFactorAuthentication as enableTwoFactorAuthenticationApi } from 'api/enableTwoFactorAuthentication';

export const enableTwoFactorAuthentication = createAsyncThunk(
	'account/enableTwoFactorAuthentication',
	async (
		{
			userId,
			countryCode,
			phoneNumber,
		}: { userId: number; countryCode: string; phoneNumber: string },
		thunkAPI
	) => {
		const response = await enableTwoFactorAuthenticationApi(
			countryCode,
			phoneNumber
		);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
		const authyId = await response.json();
		return { userId, authyId };
	}
);
