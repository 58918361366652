import styled from 'styled-components';
import { SetQuery } from 'use-query-params';

import Card from 'components/Card';
import { DatabaseCredentialsForm } from 'components/DatabaseCredentialsForm';
import { HostingProviderDashboardButton } from 'components/HostingProviderDashboardButton';
import Section from 'components/Section';
import {
	HostingProvider,
	DataSourceValue,
	DefaultDataSourceValues,
} from 'typings/types';

import { DatabaseSteps as DataSourceSteps, QueryParamsConfig } from '.';
import { NavigationButtons } from './NavigationButtons';

const StyledHostingProviderDashboardButton = styled(
	HostingProviderDashboardButton
)`
	margin-top: 1rem;
`;

const HostingProviderDetails = styled.div`
	margin-bottom: 2rem;
`;

const Text = styled.p`
	cursor: default;
`;

interface Props {
	setQuery: SetQuery<QueryParamsConfig>;
	databaseStep: keyof DataSourceSteps;
	workspaceId: number;
	databaseName: string;
	dataSourceValue: DataSourceValue;
	hostingProvider: HostingProvider;
	defaultDataSourceValues: DefaultDataSourceValues | null;
}

function DataSourceDetailsSection({
	setQuery,
	databaseStep,
	workspaceId,
	databaseName,
	dataSourceValue,
	hostingProvider,
	defaultDataSourceValues,
}: Props) {
	return (
		<Section title="Time to connect to your database">
			<Card>
				{!['other', 'localhost'].includes(hostingProvider.name) && (
					<HostingProviderDetails>
						<Text>
							You can find the following information on your{' '}
							{hostingProvider.verboseName} dashboard.
						</Text>
						{hostingProvider.dashboardUrl && (
							<StyledHostingProviderDashboardButton
								dashboardUrl={hostingProvider.dashboardUrl}
								logoLight={hostingProvider.logoLight}
								logoDark={hostingProvider.logoDark}
								name={hostingProvider.verboseName}
							/>
						)}
					</HostingProviderDetails>
				)}
				<DatabaseCredentialsForm
					workspaceId={workspaceId}
					databaseName={databaseName}
					dataSourceValue={dataSourceValue}
					hostingProvider={hostingProvider}
					hostingProviderDataSourceType={defaultDataSourceValues}
				/>
			</Card>
			<NavigationButtons step={databaseStep} setQuery={setQuery} />
		</Section>
	);
}

export { DataSourceDetailsSection };
