import React, { useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizable, {
	ResizableProps,
	ResizeCallbackData,
} from 'react-resizable';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiAttributeSettings } from 'api/reactQueryHooks/useUpdateApiAttributeSettings';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { ContextMenu, ContextMenuItem } from 'components/ContextMenu';
import { DataTypeIcon } from 'components/DataTypeIcon';
import { StyledTableCellContainer as _Cell } from 'components/StyledTableCellContainer';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import _Title from 'components/Title';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as PrimaryAttributeIcon } from 'images/icons/primaryAttribute.svg';
import { ReactComponent as SortAscendingIcon } from 'images/icons/sortAscending.svg';
import { ReactComponent as SortDescendingIcon } from 'images/icons/sortDescending.svg';
import { setPrimaryAttribute } from 'reduxState/thunks/setPrimaryAttribute';
import { PrimaryAttribute } from 'typings/models';
import {
	AdvancedViewAttribute,
	ApiRecordAttribute,
	ApiRunQueryResponse,
} from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { neutral } from 'utils/colors';
import { DEFAULT_COLUMN_WIDTH, REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import { isApiRecordAttribute } from 'utils/isApiRecordAttribute';
import { canManageViews } from 'utils/permissions';
import { sortDirectionLabel } from 'utils/sortDirectionLabel';
import { useCollaborator } from 'utils/useCollaborator';
import { useWorkspace } from 'utils/useWorkspace';
import { getHumanizedString } from 'utils/verboseNames';
import { state } from 'valtioState';
import { updateAttributeSettings as updateAttributeSettingsInValtio } from 'valtioState/attributes/updateAttributeSettings';
import { updateTableSettings } from 'valtioState/tables/updateTableSettings';
import { setAttributesInAView } from 'valtioState/views/setAttributesInAView';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

const StyledResizable = styled(ReactResizable.Resizable)`
	.react-resizable {
		position: relative;
	}
	.react-resizable-handle {
		width: 0.75rem;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		cursor: col-resize;

		&:hover {
			background: ${neutral[4]};
			opacity: 0.75;
		}
	}
`;

const Cell = styled(_Cell)`
	user-select: none;
`;

const Container = styled.div`
	display: flex;
	height: 2rem;
	align-items: center;
`;

const ColumnIdentifier = styled.div`
	width: 100%;
	margin: auto 0;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: default;
`;

const TableName = styled.span`
	color: ${neutral[2]};
	font-size: 0.625rem;
	font-weight: 500;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 1.125;
`;

const ColumnName = styled(_Title)`
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 1.125;
	align-self: flex-start;
`;

const SortIcon = styled.div`
	display: inline-block;
	padding: 0.25rem;
	height: 100%;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: ${neutral[1]};
		}
	}
`;

const TooltipContent = styled.div`
	text-align: left;
`;

interface Props {
	column: ApiRecordAttribute | ApiRunQueryResponse['attributes'][number];
	isPrimaryAttribute: boolean;
	editable: boolean;
	primaryAttribute?: PrimaryAttribute;
	data: TableData;
	extractedTableData: ExtractedTableData;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	measureRef?: (el: HTMLElement | null) => void;
}

export function TableHeadCell({
	column,
	isPrimaryAttribute,
	primaryAttribute,
	data,
	extractedTableData,
	setPageNumber,
	measureRef,
}: Props) {
	const dispatch = useDispatch();
	const snap = useSnapshot(state);
	const [resizing, setResizing] = useState(false);
	const cellRef = useRef<HTMLDivElement | null>(null);
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);
	const { workspace } = useWorkspace();
	const collaborator = useCollaborator();

	const columnWidth = 'width' in column ? column.width : undefined;

	const [width, setWidth] = useState<number>(
		columnWidth ?? DEFAULT_COLUMN_WIDTH
	);

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: 'view' in data ? data.view : null,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);
	// Same as above, but don't invalidate records since it causes many re-renders
	const { mutate: updateApiViewColumnWidth } = useUpdateApiView(
		{
			view: 'view' in data ? data.view : null,
		},
		{
			onSuccess: (_data, variables) => {
				if (
					!isAdvancedViewData(data) &&
					data.type === 'view' &&
					variables.view?.type !== 'ADVANCED'
				) {
					// @ts-expect-error fix
					setAttributesInAView(data.view.id, variables.view.attributes);
				} else if (
					data.type === 'view' &&
					variables.view?.advancedViewAttributes !== undefined
				) {
					setAttributesInAView(
						data.view.id,
						variables.view.advancedViewAttributes
					);
				}
			},
		}
	);

	const { mutate: updateAttributeSettings } = useUpdateApiAttributeSettings({
		onMutate: (variables) => {
			updateAttributeSettingsInValtio({
				attributeId: variables.attributeId,
				width: variables.width,
			});
		},
	});

	const columnIsPrimaryAttribute =
		'id' in column
			? primaryAttribute
				? primaryAttribute.schemaName === column.schemaName &&
				  primaryAttribute.tableName === column.tableName &&
				  primaryAttribute.columnName === column.attributeName
				: column.isPrimaryKeyInDb
			: undefined;

	const { databaseId, foreignKeys, sorts, joins, enums } = isAdvancedViewData(
		data
	)
		? {
				databaseId: data.view.sqlDatabaseId,
				sorts: [],
				joins: [],
				foreignKeys: [],
				enums: [],
		  }
		: extractedTableData;

	const attributeType = useMemo(
		() =>
			'typeInDb' in column && column.typeInDb
				? getAttributeTypeFromDbColumnType({ dbColumnType: column.typeInDb })
				: null,
		[column]
	);

	const handleResizeStop = (
		event: React.SyntheticEvent,
		{ size }: ResizeCallbackData
	) => {
		setResizing(false);
		setWidth(size.width);
		if (
			data.type === 'view' &&
			isApiRecordAttribute(column) &&
			!isAdvancedViewData(data)
		) {
			const attributes = extractedTableData.attributes;

			const updatedColumns = attributes.map((viewColumn) => {
				if (viewColumn.id !== column.id) {
					return viewColumn;
				}

				return {
					...viewColumn,
					width: size.width,
				};
			});

			updateApiViewColumnWidth({
				view: { id: data.view.id, attributes: updatedColumns },
			});
		} else if (
			data.type === 'view' &&
			isAdvancedViewData(data) &&
			!isApiRecordAttribute(column)
		) {
			// The typing is incorrect for ExtractedTableData, so we need to cast to unknown first
			const existingAttributes =
				extractedTableData.attributes as unknown as AdvancedViewAttribute[];

			const updateAttributes = existingAttributes.map((existingAttribute) => {
				if (existingAttribute.name !== column.name) {
					return existingAttribute;
				}

				return {
					...existingAttribute,
					width: size.width,
				};
			});

			updateApiViewColumnWidth({
				view: { id: data.view.id, advancedViewAttributes: updateAttributes },
			});
		} else if (data.type === 'table' && isApiRecordAttribute(column)) {
			updateAttributeSettings({ attributeId: column.id, width: size.width });
		}
	};

	const handleSetAsPrimaryAttribute = async () => {
		if (isAdvancedViewData(data)) {
			return;
		}
		const { schemaName, tableName, databaseId } = extractedTableData;
		const dataSourceId =
			snap.entities.sqlDatabases.byId[databaseId]?.dataSourceId;
		if (dataSourceId === undefined) {
			return;
		}
		await dispatch(
			setPrimaryAttribute({
				tableName,
				schemaName,
				columnName: column.name,
				dataSourceId,
			})
		);
		// Need to refetch the workspace query since it returns primary attributes
		queryClient.refetchQueries([REACT_QUERY_CACHE_KEY.WORKSPACE, workspaceId]);
	};

	const sort = sorts.find((sort) => sort.columnName === column.name);
	const getSortDirection = (ascending: boolean) => {
		if (attributeType === 'TEXT') {
			return !ascending;
		} else {
			return ascending;
		}
	};

	const handleSort = (ascending: boolean) => {
		if (!isApiRecordAttribute(column)) {
			return;
		}
		const updatedSorts = [
			{
				schemaName: column.schemaName,
				tableName: column.tableName,
				columnName: column.attributeName,
				ascending,
			},
		];
		setPageNumber(0);
		if (data.type === 'view') {
			updateApiView({ view: { id: data.view.id, sorts: updatedSorts } });
		} else if (data.type === 'table') {
			updateTableSettings({ tableId: data.id, sorts: updatedSorts });
		}
	};

	const handleToggleSort = () => {
		const ascending =
			sort === undefined ? getSortDirection(false) : !sort.ascending;
		handleSort(ascending);
	};

	const isBasicView = !isAdvancedViewData(data);
	const attributeDisplayName = isApiRecordAttribute(column)
		? getAttributeDisplayName({
				attributesById: snap.entities.attributes.byId,
				attributeNamesToIdMap: snap.attributeNamesToIdMap,
				attributeName: column.attributeName,
				tableName: column.tableName,
				schemaName: column.schemaName,
				sqlDatabaseId: databaseId,
		  })
		: getHumanizedString(column.name);
	const attributeDescription = isApiRecordAttribute(column)
		? snap.entities.attributes.byId[column.id]?.description ?? ''
		: undefined;

	const handleResize: ResizableProps['onResize'] = (event, { size }) => {
		setWidth(size.width);
	};

	const handleResizeStart = () => {
		setResizing(true);
	};

	return (
		<StyledResizable
			axis="x"
			width={width}
			minConstraints={[16 * 4, 16 * 4]}
			resizeHandles={['e']}
			onResizeStart={handleResizeStart}
			onResizeStop={handleResizeStop}
			onResize={handleResize}
			height={Infinity}
		>
			<StyledTableCell
				ref={(el) => {
					cellRef.current = el;
					if (measureRef) {
						// Uncomment to get all the table cells to resize (and not just the table header cells)
						// We are currently commenting this out since we are unable to have the same resize
						// behaviour for sticky columns
						// measureRef(el);
					}
				}}
				isPrimaryAttribute={isPrimaryAttribute}
				style={{ width }}
				$resizing={resizing}
			>
				<ContextMenu
					enabled={
						!(data.type === 'view' && data.view.type === ViewType.ADVANCED)
					}
					content={
						<>
							{isBasicView &&
								canManageViews(collaborator) &&
								!columnIsPrimaryAttribute && (
									<ContextMenuItem
										icon={<PrimaryAttributeIcon />}
										value="Set as primary attribute"
										onSelect={handleSetAsPrimaryAttribute}
									/>
								)}
							{!(sort?.ascending === true) && (
								<ContextMenuItem
									icon={<SortAscendingIcon />}
									value={`Sort ${sortDirectionLabel({
										attributeType,
										ascending: true,
										lowercase: true,
									})}`}
									onSelect={() => handleSort(true)}
								/>
							)}
							{!(sort?.ascending === false) && (
								<ContextMenuItem
									icon={<SortDescendingIcon />}
									value={`Sort ${sortDirectionLabel({
										attributeType,
										ascending: false,
										lowercase: true,
									})}`}
									onSelect={() => handleSort(false)}
								/>
							)}
						</>
					}
				>
					<Cell onClick={handleToggleSort}>
						<Container>
							{isApiRecordAttribute(column) && (
								<DataTypeIcon
									workspace={workspace}
									sqlDatabaseId={databaseId}
									attribute={column}
									foreignKeys={foreignKeys}
									enums={enums}
								/>
							)}
							<ColumnIdentifier>
								{joins.length > 0 && isApiRecordAttribute(column) && (
									<TableName>
										{getTableDisplayName({
											tablesById: snap.entities.tables.byId,
											tableId: column.tableId,
										})}
									</TableName>
								)}
								<ColumnName>
									<Tooltip
										value={
											<TooltipContent>
												<b>{attributeDisplayName}</b>
												<p>{attributeDescription}</p>
											</TooltipContent>
										}
									>
										<span>{attributeDisplayName}</span>
									</Tooltip>
								</ColumnName>
							</ColumnIdentifier>

							{isBasicView && sort !== undefined && (
								<SortIcon>
									{getSortDirection(sort.ascending) ? (
										<SortDescendingIcon />
									) : (
										<SortAscendingIcon />
									)}
								</SortIcon>
							)}
						</Container>
					</Cell>
				</ContextMenu>
			</StyledTableCell>
		</StyledResizable>
	);
}
