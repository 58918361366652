import { getDefaultHeaders } from 'api/utils';
import { Role } from 'typings/models';

export function inviteMember(workspaceId: number, email: string, role: Role) {
	return fetch(`/api/workspaces/${workspaceId}/members`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			email,
			role,
		}),
	});
}
