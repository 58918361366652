import { rgba } from 'polished';
import { css } from 'styled-components';

import { ONE_REM_IN_PIXELS } from 'utils/constants';

const colours = {
	logo: {
		red: '#eb2d60',
		blue: '#4ac6e9',
		green: '#34b57e',
	},
	primary: {
		100: '#002159',
		200: '#01337d',
		300: '#03449e',
		400: '#0552b5',
		500: '#0967d2',
		600: '#2186eb',
		700: '#47a3f3',
		800: '#7cc4fa',
		900: '#bae3ff',
		1000: '#e6f6ff',
	},
	neutral: {
		100: '#1f2933',
		200: '#323f4b',
		300: '#3e4c59',
		400: '#52606d',
		500: '#616e7c',
		600: '#7b8794',
		700: '#9aa5b1',
		800: '#cbd2d9',
		900: '#e4e7eb',
		1000: '#f5f7fa',
	},
	success: {
		100: '#05400a',
		200: '#0e5814',
		300: '#207227',
		400: '#2f8132',
		500: '#3f9142',
		600: '#57ae5b',
		700: '#7bc47f',
		800: '#a3d9a5',
		900: '#c1eac5',
		1000: '#e3f9e5',
	},
	warning: {
		100: '#513c06',
		200: '#7c5e10',
		300: '#a27c1a',
		400: '#c99a2e',
		500: '#e9b949',
		600: '#f7D070',
		700: '#f9da8b',
		800: '#f8e3a3',
		900: '#fcefc7',
		1000: '#fffaeb',
	},
	error: {
		100: '#610316',
		200: '#8a041a',
		300: '#ab091e',
		400: '#cf1124',
		500: '#e12d39',
		600: '#ef4e4e',
		700: '#f86a6a',
		800: '#ff9b9b',
		900: '#ffbdbd',
		1000: '#ffe3e3',
	},
};

const styles = {
	colours,
	shadows: {
		100: `
			0 5px 15px 0 ${rgba(colours.neutral[100], 0.25)},
			0 2px 3px 0 ${rgba(colours.neutral[100], 0.25)}
		`,
		300: `
			0 5px 15px 0 ${rgba(colours.neutral[100], 0.2)},
			0 2px 3px 0 ${rgba(colours.neutral[100], 0.2)}
		`,
		500: `
			0 5px 15px 0 ${rgba(colours.neutral[100], 0.15)},
			0 2px 3px 0 ${rgba(colours.neutral[100], 0.15)}
		`,
		700: `
			0 5px 15px 0 ${rgba(colours.neutral[100], 0.08)},
			0 2px 3px 0 ${rgba(colours.neutral[100], 0.08)}
		`,
		900: `
			0 5px 15px 0 ${rgba(colours.neutral[100], 0.05)},
			0 2px 3px 0 ${rgba(colours.neutral[100], 0.05)}
		`,
	},
	global: {
		borderRadius: '4px',
		transitionTime: '0.1s',
		overscrollHeight: '15rem',
	},
	sizes: {
		actionBarHeight: '3.125rem',
	},
	table: {
		head: {
			height: '2.375rem',
		},
		row: {
			lineHeight: '2rem',
			lineHeightInPixels: 2 * ONE_REM_IN_PIXELS,
		},
		cell: {
			paddingVertical: '0.25rem',
			paddingVerticalInPixels: 0.25 * ONE_REM_IN_PIXELS,
			padding: '0.25rem 0.625rem',
		},
	},
	text: {
		colourPrimary: colours.neutral[100],
		colourSecondary: colours.neutral[400],
		colourTertiary: colours.neutral[600],
		fontFamilyText: `InterVariable, sans-serif`,
		fontFamilyMonospace: `JetBrains MonoVariable, monospace`,
	},
};

const breakpointDesktopWideDesktop = 1100;
const breakpointTabletDesktop = 800;
const breakpointMobileTablet = 560;

export const media = {
	wideDesktop: (...args: Parameters<typeof css>) => css`
		@media (min-width: ${breakpointDesktopWideDesktop}px) {
			${css(...args)};
		}
	`,
	desktop: (...args: Parameters<typeof css>) => css`
		@media (min-width: ${breakpointTabletDesktop + 1}px) {
			${css(...args)};
		}
	`,
	tabletUp: (...args: Parameters<typeof css>) => css`
		@media (min-width: ${breakpointMobileTablet + 1}px) {
			${css(...args)};
		}
	`,
	tablet: (...args: Parameters<typeof css>) => css`
		@media (max-width: ${breakpointTabletDesktop}px) and (min-width: ${breakpointMobileTablet +
			1}px) {
			${css(...args)};
		}
	`,
	tabletDown: (...args: Parameters<typeof css>) => css`
		@media (max-width: ${breakpointTabletDesktop}px) {
			${css(...args)};
		}
	`,
	mobile: (...args: Parameters<typeof css>) => css`
		@media (max-width: ${breakpointMobileTablet}px) {
			${css(...args)};
		}
	`,
};

export default styles;
