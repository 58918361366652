import { createAction, createSlice } from '@reduxjs/toolkit';

import { deleteRecords } from 'reduxState/actions';
import { ObjectRecordId } from 'typings/serverTypes';

export const updateRecord = createAction<{
	tableId: number;
	attributeId: number;
	recordId: ObjectRecordId;
	valueBefore: string | boolean | null;
	valueAfter: string | boolean | null;
	formattedValueBefore: string | boolean | null;
	formattedValueAfter: string | boolean | null;
}>('updateRecord');

export type ActionLog = (
	| {
			type: 'UPDATE_RECORD';
			attributeId: number;
			tableId: number;
			recordId: ObjectRecordId;
			valueBefore: string | boolean | null;
			valueAfter: string | boolean | null;
			formattedValueBefore: string | boolean | null;
			formattedValueAfter: string | boolean | null;
			saved: boolean;
	  }
	| {
			type: 'DELETE_RECORDS';
			recordIds: ObjectRecordId[];
			databaseId: number;
			schemaName: string;
			tableName: string;
			saved: boolean;
	  }
)[];

const actionLog = createSlice({
	name: 'actionLog',
	initialState: [] as ActionLog,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(updateRecord, (state, action) => {
				const {
					recordId,
					tableId,
					valueBefore,
					valueAfter,
					attributeId,
					formattedValueAfter,
					formattedValueBefore,
				} = action.payload;

				return [
					...state,
					{
						type: 'UPDATE_RECORD',
						attributeId,
						tableId,
						recordId,
						valueBefore,
						valueAfter,
						formattedValueAfter,
						formattedValueBefore,
						saved: false,
					},
				];
			})
			.addCase(deleteRecords, (state, action) => {
				const { recordIds, databaseId, schemaName, tableName } = action.payload;

				return [
					...state,
					{
						type: 'DELETE_RECORDS',
						databaseId,
						schemaName,
						tableName,
						recordIds,
						saved: false,
					},
				];
			});
	},
});
export const reducer = actionLog.reducer;
