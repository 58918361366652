import { getDefaultHeaders } from 'api/utils';
import { View } from 'typings/models';

export type GetDataSourceAffectedViewsResponse = View[];
type GetDataSourceAffectedViewsParams = {
	dataSourceId: number;
};

export function getDataSourceAffectedViews({
	dataSourceId,
}: GetDataSourceAffectedViewsParams) {
	return fetch(`/api/data-sources/${dataSourceId}/affected-views`, {
		headers: getDefaultHeaders(),
	});
}
