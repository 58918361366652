import RichTextEditor, { EditorValue } from 'react-rte';

import { CELL_VIEW_TYPE } from 'utils/constants';

export const getInputValue = (
	rawValue: string | boolean | null | Date,
	cellViewType: CELL_VIEW_TYPE,
	richTextFormat: string
): string | null | EditorValue => {
	if (typeof rawValue === 'boolean') {
		return '';
	}
	if (rawValue instanceof Date) {
		return '';
	}
	if (cellViewType === CELL_VIEW_TYPE.JSON) {
		if (rawValue === null) {
			return null;
		}

		try {
			const jsonValue = JSON.parse(rawValue);
			return JSON.stringify(jsonValue, null, '\t');
		} catch (error) {
			return rawValue;
		}
	} else if (cellViewType === CELL_VIEW_TYPE.RICH_TEXT) {
		if (typeof rawValue === 'string') {
			return RichTextEditor.createValueFromString(rawValue, richTextFormat);
		} else {
			return RichTextEditor.createEmptyValue();
		}
	} else {
		return rawValue;
	}
};
