import { useState } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/Button';
import { background } from 'utils/colors';

const SectionComponent = styled.section<{ topLevel?: boolean }>`
	display: flex;
	flex-direction: column;

	${({ topLevel }) =>
		topLevel &&
		css`
			margin-top: 1.25rem;
		`};
`;

const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledButton = styled(Button)`
	padding: 0.25rem;
	background: ${({ primary: blue }) => (blue ? 'default' : background[1])};
`;

const Content = styled.div<{ limitHeight?: boolean }>`
	${({ limitHeight }) =>
		limitHeight &&
		css`
			max-height: 18rem;
			overflow: auto;
		`}
`;

export interface SidebarSectionProps {
	initiallyExpanded?: boolean;
	topLevel?: boolean;
	limitHeight?: boolean;
	Header?: (props: {
		onClick?: () => void;
		expanded: boolean;
		indentation?: number;
	}) => JSX.Element;
	button?: React.ReactNode;
	children: React.ReactNode;
	indentation?: number;
	/** Used to control the expanded state outside the component */
	expanded?: boolean;
	/** Used to keep the expanded state in local storage to persist state across page refreshes */
	localStorageKey?: string;
}

const getInitialExpandedState = ({
	localStorageKey,
	initiallyExpanded,
}: {
	localStorageKey?: string;
	initiallyExpanded: boolean;
}): boolean => {
	if (localStorageKey) {
		const expanded = localStorage.getItem(localStorageKey);
		if (expanded === null) {
			return initiallyExpanded;
		}
		return expanded === 'true';
	}
	return initiallyExpanded;
};

export function SidebarSection({
	initiallyExpanded = false,
	topLevel,
	limitHeight,
	indentation,
	Header,
	button,
	children,
	expanded: expandedProp,
	localStorageKey,
}: SidebarSectionProps) {
	const [expanded, setExpanded] = useState(
		getInitialExpandedState({ localStorageKey, initiallyExpanded })
	);

	const toggleExpanded = () => {
		setExpanded((expanded) => !expanded);
		if (localStorageKey !== undefined) {
			localStorage.setItem(localStorageKey, String(!expanded));
		}
	};

	return (
		<SectionComponent topLevel={topLevel}>
			{Header && (
				<HeaderContainer>
					<Header
						onClick={expandedProp === undefined ? toggleExpanded : undefined}
						expanded={expanded || (expandedProp ?? false)}
						indentation={indentation}
					/>
					{button}
				</HeaderContainer>
			)}
			<Content limitHeight={limitHeight}>
				{(expanded || expandedProp) && children}
			</Content>
		</SectionComponent>
	);
}
