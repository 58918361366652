import styled from 'styled-components';
import { DecodedValueMap, SetQuery } from 'use-query-params';

import { Callout } from 'components/Callout';
import Card from 'components/Card';
import Section from 'components/Section';
import TextLink from 'components/TextLink';
import { dataSourceTypes } from 'utils/dataSourceInfo';

import { DatabaseSteps as DataSourceSteps, QueryParamsConfig } from '.';
import { NavigationButtons } from './NavigationButtons';
import { OptionsGrid } from './OptionsGrid';

const StyledCallout = styled(Callout)`
	margin-top: 4rem;
`;

interface Props {
	setQuery: SetQuery<QueryParamsConfig>;
	dataSource: DecodedValueMap<QueryParamsConfig>['dataSource'];
	databaseStep: keyof DataSourceSteps;
	onNext: () => void;
}

export function SelectDataSourceSection({
	dataSource,
	setQuery,
	databaseStep,
	onNext,
}: Props) {
	return (
		<>
			<Section title="What would you like to add?">
				<Card>
					<OptionsGrid
						options={Object.values(dataSourceTypes).map((dataSourceType) => ({
							name: dataSourceType.verboseName,
							type: dataSourceType.name,
							image: dataSourceType.logoLight,
							imageDarkMode: dataSourceType.logoDark,
						}))}
						checkedType={dataSource}
						onChange={(value) => setQuery({ dataSource: value })}
					/>
				</Card>
				<NavigationButtons
					step={databaseStep}
					setQuery={setQuery}
					disabled={dataSource === undefined || dataSource === null}
					onNext={onNext}
				/>
			</Section>

			<StyledCallout>
				Someone else on your team better suited to connect your data source?{' '}
				<TextLink to="/members">Invite them to your workspace here.</TextLink>
			</StyledCallout>
		</>
	);
}
