import styled from 'styled-components';

import { Icon } from 'components/Icon';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { ViewType } from 'typings/types';

const Description = styled.p`
	margin-bottom: 1rem;
`;
const AffectedViewsTitle = styled.span`
	display: inline-block;
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
`;
const List = styled.ul`
	list-style: none;
`;
const ListItem = styled.li`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;

	&:last-child {
		margin-bottom: 0;
	}
`;
const ListIcon = styled(Icon)`
	margin-right: 0.5rem;
`;

type AffectedViewType = 'data source' | 'table' | 'attribute';

type AffectedViewsMessageProps = {
	affectedViews: {
		id: number;
		name: string;
		type: keyof typeof ViewType;
		isPublished: boolean;
	}[];
	type: AffectedViewType;
};

export function AffectedViewsMessage({
	affectedViews,
	type,
}: AffectedViewsMessageProps) {
	return (
		<div>
			<Description>
				One or more views are using data in this {type}. Disconnecting will
				delete the following views:
			</Description>
			<AffectedViewsTitle>
				Affected views ({affectedViews.length})
			</AffectedViewsTitle>
			<List>
				{affectedViews.map((view) => (
					<ListItem key={view.id}>
						<ListIcon>
							{view.type === ViewType.BASIC ? <ViewIcon /> : <QueryIcon />}
						</ListIcon>
						{view.isPublished ? '' : '[DRAFT] '}
						{view.name}
					</ListItem>
				))}
			</List>
		</div>
	);
}
