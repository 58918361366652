import { getDefaultHeaders } from 'api/utils';

type FetchAllSettingsParams = {
	workspaceId: number;
};

export function getWorkspaceSettings({ workspaceId }: FetchAllSettingsParams) {
	return fetch(`/api/workspaces/${workspaceId}/settings`, {
		headers: getDefaultHeaders(),
	});
}
