import { getDefaultHeaders } from 'api/utils';

type RunActionParams = {
	luckyNumber: number;
	pretendExternalCallSucceeds: boolean;
};

export function runAction({
	luckyNumber,
	pretendExternalCallSucceeds,
}: RunActionParams) {
	// The route is `/actions/:actionId/run`, but the `action` model isn't
	// implemented yet, so we hard-code a `0` for development in the meantime.
	return fetch(`/api/actions/0/run`, {
		method: 'POST',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			luckyNumber,
			pretendExternalCallSucceeds,
		}),
	});
}
