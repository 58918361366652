import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { Chip } from 'components/Chip';
import { Icon } from 'components/Icon';
import { StyledLinkButton } from 'components/TableCell/EditableTableCellContents/StyledLinkButton';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { ReactComponent as ArrowRightIcon } from 'images/icons/arrowRight.svg';
import { tableAdded } from 'reduxState/slices/tableStack';
import { canAccessRawTables } from 'utils/permissions';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';
import { state } from 'valtioState';

import { StyledNullOrEmptyText } from './StyledNullOrEmptyText';

const ForeignKeyContainer = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
`;

type ForeignKeyTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	| 'column'
	| 'foreignKey'
	| 'data'
	| 'databaseId'
	| 'dataSource'
	| 'routeLocationSearch'
	| 'rawValue'
	| 'highlighted'
	| 'displayValue'
>;

export const ForeignKeyTableCellContents = ({
	column,
	foreignKey,
	data,
	databaseId,
	dataSource,
	routeLocationSearch,
	rawValue,
	highlighted,
	displayValue,
}: ForeignKeyTableCellContentsProps): React.ReactElement | null => {
	const dispatch = useDispatch();
	const snap = useSnapshot(state);
	const columnSchemaName = column.schemaName;
	const columnTableName = column.tableName;
	const currentMember = useSelector((state) => state.currentMember);
	if (!foreignKey) {
		return null;
	}
	const handlePushTableStack = () => {
		if (data.type === 'view') {
			dispatch(
				tableAdded({
					type: 'view',
					viewId: data.view.id,
					name: data.view.name,
				})
			);
		} else {
			if (
				databaseId == undefined ||
				columnSchemaName == undefined ||
				columnTableName == undefined ||
				dataSource === undefined
			) {
				return;
			}
			const urlFilters = new URLSearchParams(routeLocationSearch);
			const filters = [];
			for (const columnName of urlFilters.keys()) {
				filters.push(columnName);
			}

			const hasUrlFilters = filters.length > 0;
			const filterColumnName = hasUrlFilters ? filters[0] : undefined;
			const filterColumnValue =
				hasUrlFilters && filterColumnName
					? urlFilters.get(filterColumnName)
					: undefined;

			dispatch(
				tableAdded({
					type: 'table',
					tableId: data.id,
					dataSourceId: dataSource.id,
					filterColumnName,
					filterColumnValue,
				})
			);
		}
	};
	const tableId =
		snap.tableNamesToIdMap[
			databaseId +
				'/' +
				foreignKey.foreignSchemaName +
				'/' +
				foreignKey.foreignTableName
		];
	const dataSourceLink = `/data-sources/${dataSource?.id}/tables/${tableId}?${foreignKey.foreignColumnName}=${rawValue}`;

	if (rawValue === null) {
		return (
			<StyledNullOrEmptyText>
				{renderNullOrDefaultValue({ isAddingNewRecord: false, column })}
			</StyledNullOrEmptyText>
		);
	}

	return (
		<ForeignKeyContainer>
			<Chip title={String(rawValue)} active={highlighted} maskContent>
				{displayValue}
			</Chip>

			{canAccessRawTables(currentMember) && (
				<StyledLinkButton
					as={Link}
					to={dataSourceLink}
					onClick={handlePushTableStack}
					onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => {
						if (event.key === 'Enter') {
							event.stopPropagation();
							handlePushTableStack();
						}
					}}
				>
					<Icon>
						<ArrowRightIcon />
					</Icon>
				</StyledLinkButton>
			)}
		</ForeignKeyContainer>
	);
};
