import { getAndSyncDataSourceSchema as getAndSyncDataSourceSchemaApi } from 'api/getAndSyncDataSourceSchema';
import { setDataSourceLoading } from 'valtioState/dataSources/index';
import { state } from 'valtioState/index';
import { setAllByIds, setAllIds } from 'valtioState/utils';

export const getAndSyncDataSourceSchema = async ({
	dataSourceId,
}: {
	dataSourceId: number;
}) => {
	setDataSourceLoading(dataSourceId, true);

	const response = await getAndSyncDataSourceSchemaApi({ dataSourceId });
	if (!response.ok) {
		setDataSourceLoading(dataSourceId, false);
		return;
	}
	const data = await response.json();

	state.attributeNamesToIdMap = {
		...state.attributeNamesToIdMap,
		...data.attributeNamesToIdMap,
	};
	state.tableNamesToIdMap = {
		...state.tableNamesToIdMap,
		...data.tableNamesToIdMap,
	};

	setAllByIds(data.schemas, state.entities.schemas.byId);
	setAllByIds(data.tables, state.entities.tables.byId);
	setAllByIds(data.attributes, state.entities.attributes.byId);

	setAllIds(data.schemas, state.entities.schemas.ids);
	setAllIds(data.tables, state.entities.tables.ids);
	setAllIds(data.attributes, state.entities.attributes.ids);

	setDataSourceLoading(dataSourceId, false);
};
