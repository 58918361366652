// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'down... Remove this comment to see the full error message
import { downloadCSV } from 'download-csv';
import { Emoji, EmojiData, Picker } from 'emoji-mart';
import { Parser } from 'json2csv';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';
import styled from 'styled-components';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useCreateAdvancedApiView } from 'api/reactQueryHooks/useCreateAdvancedApiView';
import { useDeleteApiView } from 'api/reactQueryHooks/useDeleteApiView';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { useUpdateApiViewFavorited } from 'api/reactQueryHooks/useUpdateApiViewFavorited';
import { runQuery as runQueryApi } from 'api/runQuery';
import {
	ActionBar,
	ActionBarItem,
	ActionBarSection,
	ActionBarTitle,
} from 'components/ActionBar';
import Button from 'components/Button';
import { DeleteViewAlertDialog } from 'components/DeleteViewAlertDialog';
import { DepracatedPopover } from 'components/DepracatedPopover';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import SearchBar from 'components/SearchBar';
import {
	SegmentedController,
	SegmentedControllerItem,
} from 'components/fields/SegmentedController';
import { useTheme } from 'components/providers/ThemeProvider';
import { ReactComponent as ChartIcon } from 'images/icons/chart.svg';
import { ReactComponent as CollapseDownIcon } from 'images/icons/collapseDown.svg';
import { ReactComponent as CollapseUpIcon } from 'images/icons/collapseUp.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg';
import { ReactComponent as DuplicateIcon } from 'images/icons/duplicate.svg';
import { ReactComponent as EditIcon } from 'images/icons/edit.svg';
import { ReactComponent as EmojiIcon } from 'images/icons/emoji.svg';
import { ReactComponent as FavoritedOffIcon } from 'images/icons/favoritedOff.svg';
import { ReactComponent as FavoritedOnIcon } from 'images/icons/favoritedOn.svg';
import { ReactComponent as MoreIcon } from 'images/icons/more.svg';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as ReloadIcon } from 'images/icons/reload.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { CellRecord } from 'typings/serverTypes';
import { Layout, TableData, ViewType } from 'typings/types';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { canManageViews } from 'utils/permissions';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';

const LayoutSelection = styled.div`
	margin-left: 0.5rem;
`;

interface QueryControlsProps {
	data: TableData<ViewType.ADVANCED>;
	editorVisible: boolean;
	resultsVisible: boolean;
	toggleEditor: () => void;
	toggleResults: () => void;
	runQuery: () => void;
	records: CellRecord[];
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	setPageNumber: (page: number) => void;
}

function QueryControls({
	data,
	editorVisible,
	resultsVisible,
	toggleEditor,
	toggleResults,
	runQuery,
	records,
	searchQuery,
	setSearchQuery,
	setPageNumber,
}: QueryControlsProps) {
	const { themeMode } = useTheme();
	const queryClient = useQueryClient();
	const history = useHistory();
	const workspaceId = useSelector((state) => state.workspaceId);
	const collaborator = useCollaborator();
	const sqlDatabaseId = data.view.sqlDatabaseId;

	const [close, setClose] = useState(null);
	const [isDeleteViewAlertDialogOpen, setIsDeleteViewAlertDialogOpen] =
		useState(false);

	const { mutate: createAdvancedApiView } = useCreateAdvancedApiView({
		onSuccess: (view) => {
			history.push(`/views/${view.id}`);
		},
	});

	const { mutate: deleteApiView } = useDeleteApiView({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			toast('View deleted.');
			history.push(`/views`);
		},
	});

	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
				runQuery();
			},
		}
	);
	const { mutate: updateApiViewFavorited } = useUpdateApiViewFavorited({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
		},
	});

	const updateClose = useCallback(
		(closeDropdown) => setClose(() => closeDropdown),
		[]
	);

	const toggleFavorite = () => {
		updateApiViewFavorited({
			viewId: data.view.id,
			favorited: !data.view.favorited,
		});
	};

	const handleDownloadCsv = async () => {
		const response = await runQueryApi({
			viewId: data.view.id,
			pageNumber: null,
			searchQuery: null,
		});
		if (!response.ok) {
			return;
		}
		const { records } = await response.json();

		const parser = new Parser({});
		const csv = parser.parse(records);
		const fileName = slugify(data.view.name);
		downloadCSV(csv, null, fileName);
	};

	const handleSelectEmoji = (emoji: EmojiData) => {
		updateApiView({
			view: { id: data.view.id, emoji: emoji.id },
		});

		if (close) {
			// @ts-expect-error ts-migrate(2721) FIXME: Cannot invoke an object which is possibly 'null'.
			close();
		}
	};

	const handleResetEmoji = () => {
		updateApiView({
			view: { id: data.view.id, emoji: null },
		});
	};

	const handleDeleteView = () => {
		if (data.type === 'view') {
			deleteApiView(data.view.id);
		}
	};

	const handleChangeLayout = (layout: string) => {
		if (layout === '') {
			return;
		}

		updateApiView({
			view: {
				id: data.view.id,
				layout: layout as Layout,
			},
		});
	};

	const handleDuplicateView = () => {
		const query = data.view.query;
		const name = data.view.name;

		if (workspaceId !== null && query !== undefined) {
			createAdvancedApiView({
				databaseId: sqlDatabaseId,
				query,
				name: `${name} copy`,
			});
		}
	};

	return (
		<>
			<ActionBar>
				<ActionBarSection>
					<ActionBarItem hideOnMobile>
						<Button
							onClick={toggleFavorite}
							icon={
								data.view.favorited ? <FavoritedOnIcon /> : <FavoritedOffIcon />
							}
							primaryIcon={data.view.favorited}
							title="Favorite view"
						/>
					</ActionBarItem>

					<ActionBarItem hideOnMobile>
						{canManageViews(collaborator) ? (
							<DepracatedPopover
								toggle={
									<Button
										icon={
											data.view.emoji ? (
												<Emoji emoji={data.view.emoji} size={15} />
											) : (
												<QueryIcon />
											)
										}
										title="View icon"
									/>
								}
								setClose={updateClose}
							>
								<Picker
									onSelect={handleSelectEmoji}
									title={undefined}
									showPreview={false}
									showSkinTones={false}
									theme={themeMode}
									autoFocus
								/>
							</DepracatedPopover>
						) : (
							<Button
								icon={
									data.view.emoji ? (
										<Emoji emoji={data.view.emoji} size={15} />
									) : (
										<QueryIcon />
									)
								}
							/>
						)}
					</ActionBarItem>

					<ActionBarItem>
						<ActionBarTitle title={data.view.name || ''} />
					</ActionBarItem>

					<ActionBarItem>
						<LayoutSelection>
							<SegmentedController
								type="single"
								value={data.view.layout}
								onValueChange={handleChangeLayout}
							>
								<SegmentedControllerItem
									value={Layout.TABLE}
									icon={<TableIcon />}
									title="Table layout"
									active={data.view.layout === Layout.TABLE}
								/>
								<SegmentedControllerItem
									value={Layout.CHART}
									icon={<ChartIcon />}
									title="Chart layout"
									active={data.view.layout === Layout.CHART}
								/>
							</SegmentedController>
						</LayoutSelection>
					</ActionBarItem>
				</ActionBarSection>

				<ActionBarSection padded>
					{location.pathname !== `/views/${data.view.id}/settings` &&
						canManageViews(collaborator) && (
							<ActionBarItem hideOnMobile>
								<Button
									to={`/views/${data.view.id}/settings`}
									icon={<EditIcon />}
									value="Edit view"
								/>
							</ActionBarItem>
						)}

					<ActionBarItem hideOnMobile>
						<Button
							onClick={toggleEditor}
							icon={<CollapseUpIcon />}
							title="Toggle editor"
							active={!editorVisible}
						/>
					</ActionBarItem>

					<ActionBarItem hideOnMobile>
						<Button
							onClick={toggleResults}
							icon={<CollapseDownIcon />}
							title="Toggle results"
							active={!resultsVisible}
						/>
					</ActionBarItem>

					<ActionBarItem hideOnMobile>
						<Button
							onClick={runQuery}
							disabled={!data.view.sqlDatabaseId}
							icon={<ReloadIcon />}
							title="Reload records"
						/>
					</ActionBarItem>

					<ActionBarItem>
						<DropdownMenu align="end" trigger={<Button icon={<MoreIcon />} />}>
							{canManageViews(collaborator) && (
								<>
									{data.view.emoji && (
										<DropdownMenuItem
											onSelect={handleResetEmoji}
											icon={<EmojiIcon />}
										>
											Reset emoji
										</DropdownMenuItem>
									)}
									{location.pathname !== `/views/${data.view.id}/settings` &&
										canManageViews(collaborator) && (
											<DropdownMenuItem
												to={`/views/${data.view.id}/settings`}
												icon={<EditIcon />}
											>
												Edit view
											</DropdownMenuItem>
										)}
									<DropdownMenuItem
										onSelect={handleDuplicateView}
										icon={<DuplicateIcon />}
									>
										Duplicate view
									</DropdownMenuItem>

									<DropdownMenuItem
										onSelect={() => setIsDeleteViewAlertDialogOpen(true)}
										icon={<DeleteIcon />}
									>
										Delete view
									</DropdownMenuItem>
								</>
							)}
							{data.view && records && (
								<DropdownMenuItem
									onSelect={handleDownloadCsv}
									icon={<DownloadIcon />}
								>
									Download CSV
								</DropdownMenuItem>
							)}
						</DropdownMenu>
					</ActionBarItem>

					<ActionBarItem>
						<SearchBar
							searchQuery={searchQuery}
							setSearchQuery={setSearchQuery}
							setPageNumber={setPageNumber}
						/>
					</ActionBarItem>
				</ActionBarSection>
			</ActionBar>
			{workspace && (
				<DeleteViewAlertDialog
					viewName={data.view.name}
					workspaceName={workspace.name}
					open={isDeleteViewAlertDialogOpen}
					onOpenChange={(open) => setIsDeleteViewAlertDialogOpen(open)}
					onConfirm={handleDeleteView}
				/>
			)}
		</>
	);
}

export default QueryControls;
