import { FormEvent, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { login } from 'api/login';
import { sendMagicLink } from 'api/sendMagicLink';
import { updateUser } from 'api/updateUser';
import { verifyTwoFactorAuthentication } from 'api/verifyTwoFactorAuthentication';
import { SignInWithGoogleButton } from 'components/SignInWithGoogleButton';
import TextLink from 'components/TextLink';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { signupLabelWidth } from 'components/forms/SignupForm';
import { useToken } from 'components/providers/TokenProvider';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';

const MagicLinkSentText = styled.p`
	max-width: 24rem;
	margin: 6rem auto;
`;

const SignupText = styled.p`
	margin-top: 2.5rem;
`;

export function LoginForm() {
	const queryClient = useQueryClient();
	const location = useLocation();
	const history = useHistory();
	const { setToken } = useToken();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const locationState: any = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(location.state as any)?.from.pathname !== '/welcome' && location.state,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const [loading, setLoading] = useState(false);
	const [useMagicLink, setUseMagicLink] = useState(false);
	const [magicLinkSent, setMagicLinkSent] = useState(false);
	const [
		twoFactorAuthenticationVerification,
		setTwoFactorAuthenticationVerification,
	] = useState(false);
	const [fields, setFields] = useState({
		email: {
			value: '',
			errors: null,
		},
		password: {
			value: '',
			errors: null,
		},
		verificationCode: {
			value: '',
			errors: null,
		},
	});

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleChange = (event) => {
		const { name, value } = event.target;

		setFields({
			...fields,
			[name]: {
				value,
				errors: null,
			},
		});
	};

	function setErrors(error: {
		formErrors:
			| { email: string[]; password: string[] }
			| { email: string[] }
			| { verificationCode: string[] };
	}) {
		const newFields = fields;
		for (const field of Object.keys(error.formErrors)) {
			if (
				(
					error.formErrors[
						field as keyof typeof error['formErrors']
					] as string[]
				).length
			) {
				// @ts-expect-error FIXME
				newFields[field].errors = error.formErrors[field];
			} else {
				// @ts-expect-error FIXME
				newFields[field].errors = null;
			}
		}
		setFields({ ...fields, ...newFields });
	}

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const handleSubmit = async (event) => {
		event.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);

		try {
			if (useMagicLink) {
				const response = await sendMagicLink(fields.email.value);
				if (!response.ok) {
					const error: {
						formErrors: { email: string[] };
					} = await response.json();
					setErrors(error);
				}

				setMagicLinkSent(true);
			} else {
				const response = await login(fields.email.value, fields.password.value);
				if (!response.ok) {
					const error: {
						formErrors: { email: string[]; password: string[] };
					} = await response.json();
					setErrors(error);
				}

				const { token, twoFactorAuthentication } = await response.json();

				if (twoFactorAuthentication) {
					setLoading(false);
					setTwoFactorAuthenticationVerification(true);
				} else {
					setToken(token);

					await updateUser();
					queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
					redirect();
				}
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const handleSubmitVerification = async (
		event: FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		if (loading) {
			return;
		}
		setLoading(true);

		try {
			const response = await verifyTwoFactorAuthentication(
				fields.email.value,
				fields.verificationCode.value
			);
			if (!response.ok) {
				const error: {
					formErrors: { verificationCode: string[] };
				} = await response.json();
				setErrors(error);
			}

			const { token } = await response.json();

			setToken(token);

			await updateUser();
			queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
			redirect();
		} catch (error) {
			setLoading(false);
		}
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
	const toggleUseMagicLink = (event) => {
		event.preventDefault();
		setUseMagicLink(!useMagicLink);
	};

	const redirect = () => {
		const redirectLocation = locationState?.from.pathname ?? '/';
		history.push(redirectLocation);
	};

	if (magicLinkSent) {
		return (
			<MagicLinkSentText>
				Magic link sent! Check your mailbox and click the link in the email to
				log in to your account. Can't find it? Check your junk folder just in
				case.
			</MagicLinkSentText>
		);
	}

	if (twoFactorAuthenticationVerification) {
		return (
			<Form onSubmit={handleSubmitVerification}>
				<Input
					name="verificationCode"
					type="text"
					label="Verification code"
					helpText="You should receive a code either by SMS or in the Authy app."
					value={fields.verificationCode.value}
					errors={fields.verificationCode.errors}
					onChange={handleChange}
					disabled={loading}
					autoFocus
					labelWidth={signupLabelWidth}
				/>
				<FormButton loading={loading}>Verify code</FormButton>
				<SignupText>
					Trouble verifying?{' '}
					<TextLink as="a" href="mailto:support@basedash.com">
						Contact support
					</TextLink>
				</SignupText>
			</Form>
		);
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="email"
				type="email"
				label="Email"
				value={fields.email.value}
				errors={fields.email.errors}
				onChange={handleChange}
				disabled={loading}
				autoFocus
				labelWidth={signupLabelWidth}
			/>
			{!useMagicLink && (
				<Input
					name="password"
					type="password"
					label="Password"
					value={fields.password.value}
					errors={fields.password.errors}
					onChange={handleChange}
					disabled={loading}
					labelWidth={signupLabelWidth}
				/>
			)}
			<FormButton loading={loading}>
				{useMagicLink ? 'Send magic link' : 'Log in'}
			</FormButton>

			{useMagicLink === false && <SignInWithGoogleButton />}

			<SignupText>
				<TextLink onClick={toggleUseMagicLink} as="button">
					{useMagicLink
						? 'Log in with password'
						: 'Forgot password? Log in with magic link'}
				</TextLink>
			</SignupText>

			<SignupText>
				Don't have an account?{' '}
				<TextLink to={`/signup?email=${fields.email.value}`}>Sign up</TextLink>
			</SignupText>
		</Form>
	);
}
