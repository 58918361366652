import {
	DateFormatProps,
	DateInput as BlueprintDateInput,
} from '@basedash/datetime';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import '@basedash/datetime/lib/css/blueprint-datetime.css';

import { ColumnType } from 'typings/models';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { background, neutral, primary } from 'utils/colors';
import { DATE_FORMAT, DATETIME_FORMAT } from 'utils/constants';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

function getDateInputFormatter(formatConfig: string): DateFormatProps {
	// note that locale argument comes from locale prop and may be undefined
	return {
		formatDate: (date, _locale) => dateFnsFormat(date, formatConfig),
		parseDate: (str, _locale) => dateFnsParse(str, formatConfig, new Date()),
		placeholder: formatConfig,
	};
}

const StyledDateInput = styled(BlueprintDateInput)<{
	isInTableCell: boolean;
	value: Date | null;
}>`
	width: 100%;

	.bp3-popover-target {
		width: 100%;
	}

	.bp3-input {
		padding: 0.5rem;
		font-size: 0.875rem;
		font-size: ${({ isInTableCell }) => (isInTableCell ? '1rem' : '0.875rem')};
		height: unset;
		line-height: inherit;
		outline: 0;
		border: 1px solid ${neutral[4]};
		box-shadow: none;
		text-overflow: ellipsis;
		color: ${neutral[1]};
		background: ${({ isInTableCell }) =>
			isInTableCell ? 'none' : background[1]};
		vertical-align: baseline;

		&:disabled {
			cursor: default;
		}

		&:focus {
			box-shadow: 0 0 0 2px ${primary[1]};
		}

		&::placeholder {
			font-style: italic;
			color: ${neutral[1]};
			opacity: 0.3;
		}

		${({ isInTableCell }) =>
			isInTableCell &&
			css`
				border: 0;
				padding: 0;
				cursor: default;

				&:focus {
					box-shadow: none;
					cursor: text;
				}
			`};
	}

	.DayPicker-Day.DayPicker-Day--selected {
		background-color: #061de0;
	}
`;

// Min date is 100 years in the past (useful for birth dates)
const MIN_DATE = new Date(new Date().getFullYear() - 100, 0);
// Max date is 20 years in the future
const MAX_DATE = new Date(new Date().getFullYear() + 20, 0);

type Props = {
	value: Date | null;
	attributeType: ColumnType;
	onChange?: (value: Date | null) => void;
	isInTableCell?: boolean;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	disabled: boolean;
	isAddingNewRecord?: boolean;
	column: ApiRecordAttribute;
};

export const DateInput = ({
	attributeType,
	onChange,
	value,
	onFocus,
	onBlur,
	isInTableCell = false,
	disabled,
	isAddingNewRecord = false,
	column,
	...rest
}: Props) => {
	const [inputValue, setInputValue] = useState(value);
	const getDateFormat = () => {
		const placeholder = renderNullOrDefaultValue({ isAddingNewRecord, column });
		switch (attributeType) {
			case 'DATETIME':
				return {
					format: DATETIME_FORMAT,
					placeholder,
				};
			default:
				return { format: DATE_FORMAT, placeholder };
		}
	};
	const { format, placeholder } = getDateFormat();
	useEffect(() => {
		setInputValue(value);
	}, [value]);
	return (
		<StyledDateInput
			{...rest}
			className={'fs-mask highlight-block'}
			isInTableCell={isInTableCell}
			value={inputValue}
			onChange={(selectedDate, isUserChange) => {
				if (isUserChange) {
					setInputValue(selectedDate);
				}
			}}
			ignoreRange
			minDate={MIN_DATE}
			maxDate={MAX_DATE}
			disabled={disabled}
			inputProps={{
				onBlur: (e) => {
					if (onChange) {
						onChange(inputValue ?? null);
					}
					if (onBlur) {
						onBlur(e);
					}
				},
				tabIndex: isInTableCell ? -1 : 0,
				onFocus,
			}}
			{...getDateInputFormatter(format)}
			placeholder={placeholder}
			onError={() => {
				console.log('Invalid date');
			}}
			popoverProps={{
				position: 'bottom',
				onClose: () => {
					if (onChange) {
						onChange(inputValue);
					}
				},
			}}
		/>
	);
};
