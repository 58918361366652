import { getDefaultHeaders } from 'api/utils';

export type UpdateAttributeSettingsParams = {
	attributeId: number;
} & Partial<{
	displayName: string;
	description: string;
	connected: boolean;
	editable: boolean;
	isObscured: boolean;
	hasAllowedValues: boolean;
	allowedValues: string;
	width: number;
}>;
export type UpdateAttributeSettingsResponse = {
	editable: boolean;
	connected: boolean;
	displayName: string;
	description: string;
	width: number | null;
};

export function updateAttributeSettings({
	attributeId,
	...rest
}: UpdateAttributeSettingsParams) {
	return fetch(`/api/attributes/${attributeId}`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify(rest),
	});
}
