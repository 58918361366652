import * as Sentry from '@sentry/browser';
import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import {
	FetchTableAffectedViewsResponse,
	getTableAffectedViews,
} from 'api/getTableAffectedViews';
import { useUpdateApiTableSettings } from 'api/reactQueryHooks/useUpdateApiTableSettings';
import { AlertDialog } from 'components/AlertDialog';
import { DataTypeIcon } from 'components/DataTypeIcon';
import { ScrollArea } from 'components/ScrollArea';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { Tooltip } from 'components/Tooltip';
import { AffectedViewsMessage } from 'components/pages/DataSourceSettings/AffectedViewsMessage';
import { SettingField } from 'components/pages/DataSourceSettings/SettingField';
import { ReactComponent as DataSourceIcon } from 'images/icons/database.svg';
import { ReactComponent as DisconnectedIcon } from 'images/icons/disconnected.svg';
import { ReactComponent as ViewOnlyIcon } from 'images/icons/viewOnly.svg';
import { setPrimaryAttribute } from 'reduxState/thunks/setPrimaryAttribute';
import { View } from 'typings/models';
import {
	ApiAttribute,
	ApiEnum,
	ApiForeignKey,
	ApiWorkspaceDetails,
} from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';
import { nonNullable } from 'utils/nonNullable';
import { toast } from 'utils/toast/toast';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';
import { state } from 'valtioState';
import { updateTableSettings as updateValtioTableSettings } from 'valtioState/tables/updateTableSettings';

import {
	EntitiesListContainer,
	EntitiesListLabel,
	NotConnectedContainer,
	Panel,
	PanelContent,
	PanelHeader,
} from '.';

type TableSettingsPanelProps = {
	tableId: number;
	dataSourceId: number;
	workspace: ApiWorkspaceDetails;
};

function AttributeSetting({
	dataSourceId,
	workspace,
	foreignKeys,
	enums,
	...attribute
}: ApiAttribute & {
	dataSourceId: number;
	foreignKeys: ApiForeignKey[];
	enums: ApiEnum[];
	workspace: ApiWorkspaceDetails;
}) {
	return (
		<SidebarItem
			to={`/settings/data-sources/${dataSourceId}/tables/${attribute.tableId}/attributes/${attribute.id}`}
			iconLeft={
				<DataTypeIcon
					workspace={workspace}
					// FIXME: dataSourceId should be sqlDatabaseId
					sqlDatabaseId={dataSourceId}
					attribute={attribute}
					foreignKeys={foreignKeys}
					enums={enums}
				/>
			}
			showRightChevron
			iconRightPrimary={
				!attribute.connected ? (
					<Tooltip value="Not connected">
						<DisconnectedIcon />
					</Tooltip>
				) : undefined
			}
			iconRightSecondary={
				!attribute.editable ? (
					<Tooltip value="Not editable">
						<ViewOnlyIcon />
					</Tooltip>
				) : undefined
			}
			title={attribute.displayName}
		/>
	);
}

function TableSettingsPanel({
	tableId,
	workspace,
	dataSourceId,
}: TableSettingsPanelProps) {
	const history = useHistory();
	const snap = useSnapshot(state);
	const table = snap.entities.tables.byId[tableId];
	const schema = table && snap.entities.schemas.byId[table.schemaId];
	const dispatch = useDispatch();
	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: schema?.sqlDatabaseId ?? 0,
		schemaName: schema?.name ?? '',
		tableName: table?.tableName ?? '',
	});
	const [tableDisplayName, setTableDisplayName] = useState('');
	const [isAffectedViewsModalOpen, setIsAffectedViewsModalOpen] =
		useState(false);
	const [affectedViews, setAffectedViews] = useState<View[]>([]);
	const queryClient = useQueryClient();
	const { mutate: updateTableSettings } = useUpdateApiTableSettings({
		onMutate: (variables) => {
			updateValtioTableSettings(variables);
		},
		onSuccess: (_data, variables) => {
			// Invalidate the workspace query in case views have been deleted
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspace.id,
			]);
			if (variables.displayName !== undefined) {
				toast('Table display name updated.');
			}
		},
		onError: (error) => {
			toast.error(error);
		},
	});

	const handleChangePrimaryAttribute = async (
		event: ChangeEvent<HTMLSelectElement>
	) => {
		if (!schema || !table) {
			return;
		}

		await dispatch(
			setPrimaryAttribute({
				dataSourceId,
				schemaName: schema.name,
				tableName: table.tableName,
				columnName: event.target.value,
			})
		);
		// Need to refetch the workspace query since it returns primary attributes
		queryClient.invalidateQueries([
			REACT_QUERY_CACHE_KEY.WORKSPACE,
			workspace.id,
		]);
		toast('Primary attribute updated.');
	};

	useEffect(() => {
		setTableDisplayName(table?.displayName ?? '');
	}, [table]);

	if (table === undefined || schema === undefined) {
		return null;
	}

	const attributes = Object.values(snap.entities.attributes.byId).filter(
		(attribute) => attribute?.tableId === tableId && attribute.foundInDb
	);

	const primaryKey = attributes.find(
		(attribute) => attribute?.isPrimaryKeyInDb
	);

	return (
		<Panel>
			<PanelHeader
				title={table.displayName}
				icon={<DataSourceIcon />}
				previousPageUrl={`/data-sources/${dataSourceId}/settings`}
			/>

			<ScrollArea>
				<PanelContent connected={table.connected}>
					<SettingField label={'Name'} readOnly value={table.tableName} />
					<SettingField
						label={'Display name'}
						value={tableDisplayName}
						onChange={(
							event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => {
							setTableDisplayName(event.target.value);
						}}
						onBlur={() => {
							if (tableDisplayName !== table.displayName) {
								updateTableSettings({
									tableId,
									displayName: tableDisplayName,
								});
							}
						}}
					/>
					<SettingField
						label="Primary attribute"
						value={
							primaryAttribute?.columnName ?? primaryKey?.attributeName ?? ''
						}
						options={[
							...(primaryKey === undefined
								? [{ value: '', label: 'Select one...' }]
								: []),
							...attributes
								.filter(nonNullable)
								.map(({ attributeName, displayName }) => ({
									value: attributeName,
									label: displayName,
								})),
						]}
						onChange={handleChangePrimaryAttribute}
					/>
					<SettingField
						label={'Connected'}
						value={table.connected}
						onClick={() => {
							if (table.connected) {
								getTableAffectedViews({
									tableId: table.id,
								})
									.then((res) => {
										if (res.ok) {
											return res.json();
										} else {
											Sentry.captureException(
												new ApiError('Problem fetching affected views')
											);
										}
									})
									.then((data: FetchTableAffectedViewsResponse) => {
										if (data.length > 0) {
											setAffectedViews(data);
											setIsAffectedViewsModalOpen(true);
										} else {
											updateTableSettings({
												tableId,
												connected: !table.connected,
											});
										}
									})
									.catch((_error) => {
										Sentry.captureException(
											new ApiError('Problem fetching affected views')
										);
										toast.error('Problem disconnecting table.');
									});
							} else {
								updateTableSettings({
									tableId,
									connected: !table.connected,
								});
							}
						}}
					/>
					{table.connected && (
						<>
							<SettingField
								label={'Editable'}
								value={table.editable}
								onClick={() => {
									updateTableSettings({
										tableId,
										editable: !table.editable,
									});
								}}
							/>
							<EntitiesListContainer>
								<EntitiesListLabel>Attributes</EntitiesListLabel>
								{attributes.map(
									(attribute) =>
										attribute && (
											<AttributeSetting
												key={attribute.id}
												dataSourceId={dataSourceId}
												{...attribute}
												workspace={workspace}
												/* @ts-expect-error Readonly issues */
												foreignKeys={table.foreignKeys}
												/* @ts-expect-error Readonly issues */
												enums={table.enums}
											/>
										)
								)}
							</EntitiesListContainer>
						</>
					)}
					{!table.connected && (
						<NotConnectedContainer>Table disconnected</NotConnectedContainer>
					)}
					<AlertDialog
						open={isAffectedViewsModalOpen}
						onOpenChange={(open) => setIsAffectedViewsModalOpen(open)}
						title={'Data in use'}
						description={
							<AffectedViewsMessage
								affectedViews={affectedViews}
								type={'table'}
							/>
						}
						actionText={'Disconnect table'}
						actionType={'danger'}
						onConfirm={() => {
							updateTableSettings({
								tableId,
								connected: !table.connected,
							});
							// If switching from connected to disconnected, we need to make sure to close any attribute settings panels
							if (table.connected) {
								history.replace(
									`/settings/data-sources/${dataSourceId}/tables/${tableId}`
								);
							}
						}}
					/>
				</PanelContent>
			</ScrollArea>
		</Panel>
	);
}

export { TableSettingsPanel };
