import React from 'react';
import { EditorValue } from 'react-rte';

import { JsonEditor } from 'components/JsonEditor';
import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { EditableTableCellProps } from 'components/TableCell/EditableTableCell/index';
import { TextTableCellContents } from 'components/TableCell/EditableTableCellContents/TextTableCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { UseEditableTableRecordValueResult } from 'utils/useEditableRecordValue';

type JsonTableCellProps = Pick<
	EditableTableCellProps,
	| 'isFirstCell'
	| 'isPrimaryAttribute'
	| 'usersById'
	| 'column'
	| 'dataColumn'
	| 'dataRow'
> &
	Pick<
		UseEditableTableRecordValueResult,
		| 'inputValue'
		| 'highlighted'
		| 'isEditing'
		| 'canEdit'
		| 'handleChange'
		| 'handleKeyDown'
		| 'handleFocus'
		| 'handleBlur'
		| 'cellRef'
		| 'handleDoubleClick'
		| 'selected'
		| 'rowHeight'
		| 'displayValue'
		| 'stopEditing'
		| 'setValue'
		| 'rawValue'
	> & { hasCompletedFirstRender: boolean };

export const JsonTableCell = ({
	inputValue,
	isFirstCell,
	isPrimaryAttribute,
	highlighted,
	isEditing,
	canEdit,
	handleChange,
	handleFocus,
	handleBlur,
	cellRef,
	handleDoubleClick,
	selected,
	rowHeight,
	handleKeyDown,
	usersById,
	column,
	hasCompletedFirstRender,
	displayValue,
	stopEditing,
	setValue,
	rawValue,
	dataColumn,
	dataRow,
}: JsonTableCellProps) => {
	if (inputValue instanceof EditorValue) {
		return null;
	}
	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			highlighted={highlighted}
			editing={isEditing}
			editable={canEdit}
			ref={cellRef}
			tabIndex={0}
			onFocus={handleFocus}
			onKeyDown={handleKeyDown}
			data-row={dataRow}
			data-column={dataColumn}
		>
			{isEditing ? (
				<JsonEditor
					value={inputValue || ''}
					onChange={handleChange}
					onBlur={handleBlur}
					stopEditing={stopEditing}
					saveJsonValue={(value) => {
						setValue({ valueAfter: value.getValue() });
						stopEditing();
					}}
					height="16rem"
				/>
			) : (
				<StyledTableCellContainer
					onDoubleClick={handleDoubleClick}
					selected={selected !== undefined}
					selectColour={selected && usersById[selected.userId]?.avatarColour}
					editable={canEdit}
					rowHeight={rowHeight}
					hasCompletedFirstRender={hasCompletedFirstRender}
				>
					<TextTableCellContents
						displayValue={displayValue}
						column={column}
						rawValue={rawValue}
					/>
				</StyledTableCellContainer>
			)}
		</StyledTableCell>
	);
};
