import { useQuery, UseQueryOptions } from 'react-query';

import {
	fetchWorkspace,
	FetchWorkspaceParams,
	FetchWorkspaceResponse,
} from 'api/fetchWorkspace';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';

export const useApiWorkspace = (
	params: FetchWorkspaceParams | null,
	options?: UseQueryOptions<
		unknown,
		string,
		FetchWorkspaceResponse,
		[string, FetchWorkspaceParams | null]
	>
) =>
	useQuery<
		unknown,
		string,
		FetchWorkspaceResponse,
		[string, FetchWorkspaceParams | null]
	>(
		[REACT_QUERY_CACHE_KEY.WORKSPACE, params],
		async ({ queryKey }) => {
			const [_key, workspaceId] = queryKey;

			if (!workspaceId) {
				throw new Error('Workspace ID required');
			}

			const response = await fetchWorkspace(workspaceId);
			if (!response.ok) {
				throw new ApiError('Network response was not ok');
			}
			return response.json();
		},
		{ refetchOnMount: false, ...options }
	);
