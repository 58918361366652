// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
export const handleFormErrors = (error, fields, setFields) => {
	if (error.formErrors) {
		const newFields = { ...fields };
		for (const field of Object.keys(error.formErrors)) {
			if (error.formErrors[field].length) {
				newFields[field].errors = error.formErrors[field];
			} else {
				newFields[field].errors = null;
			}
		}

		setFields({ ...fields, ...newFields });
	}
};
