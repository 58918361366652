import { createAsyncThunk } from '@reduxjs/toolkit';

import { setPaymentMethod as setPaymentMethodApi } from 'api/setPaymentMethod';

export const setPaymentMethod = createAsyncThunk(
	'setPaymentMethod',
	async (
		{
			workspaceId,
			tokenId,
		}: {
			workspaceId: number;
			tokenId: string;
		},
		thunkAPI
	) => {
		const response = await setPaymentMethodApi(workspaceId, tokenId);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
	}
);
