import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import NewRecordRow from 'components/Table/NewRecordRow';
import { VirtualizedCell } from 'components/Table/VirtualizedCell';
import { User } from 'typings/models';
import {
	AdvancedViewAttribute,
	ApiRecordAttribute,
	ApiWorkspaceDetails,
	CellRecord,
} from 'typings/serverTypes';
import { ExtractedTableData, TableData } from 'typings/types';
import { neutral } from 'utils/colors';
import {
	DEFAULT_COLUMN_WIDTH,
	ONE_REM_IN_PIXELS,
	pageSize,
} from 'utils/constants';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import styles from 'utils/styles';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';

const StyledTableBody = styled.div<{ $width: number; $height: number }>`
	overflow: auto;
	width: ${({ $width }) => $width}px;
	height: ${({ $height }) => $height}px;
`;

const TableHeaderAndBody = styled.div<{ $height: number; $width: number }>`
	position: relative;
	width: ${({ $width }) => $width}px;
	height: ${({ $height }) => $height}px;
`;

const TableHeader = styled.div<{ $height: number }>`
	position: sticky;
	z-index: 2;
	top: 0;
	height: ${({ $height }) => $height}px;
	width: 100%;
`;

const TableHeaderStickyColumn = styled.div<{ $height: number; $width: number }>`
	position: sticky;
	z-index: 4;
	top: 0;
	left: 0;
	height: ${({ $height }) => $height}px;
	width: ${({ $width }) => $width}px;
`;

const StickyNewRecordRow = styled.div`
	position: sticky;
	z-index: 2;
	bottom: 0;
	box-shadow: 0px 1px 0px 0px ${neutral[4]};
	border-right: 1px solid ${neutral[4]};
`;

const StickyColumn = styled.div<{ $width: number }>`
	position: sticky;
	z-index: 1;
	left: 0;
	height: 100%;
	width: ${({ $width }) => $width}px;
`;

const NoRecordsFound = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-right: 1px solid ${neutral[4]};
	border-bottom: 1px solid ${neutral[4]};
	color: ${neutral[1]};
	position: sticky;
	left: 0;
`;

interface Props {
	editable: boolean;
	records: CellRecord[];
	pageNumber: number;
	searchQuery: string;
	data: TableData;
	extractedTableData: ExtractedTableData;
	collaborators: ApiWorkspaceDetails['collaborators'];
	routeLocationSearch: string;
	reload?: () => void;
	workspace?: ApiWorkspaceDetails;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	numRecords: number | undefined;
	width: number;
	height: number;
}

const TableBody = React.memo(
	({
		editable,
		records,
		pageNumber,
		data,
		searchQuery,
		collaborators,
		routeLocationSearch,
		extractedTableData,
		workspace,
		setPageNumber,
		reload,
		numRecords,
		width,
		height,
	}: Props) => {
		const { databaseId, rowHeight, attributes, schemaName, tableName, joins } =
			extractedTableData;

		const parentRef = useRef<HTMLDivElement | null>(null);

		const canCreateNewRecord =
			editable && joins.length === 0 && !isAdvancedViewData(data);

		const usersById = useMemo(() => {
			return collaborators.reduce<{ [userId: string]: User }>((prev, curr) => {
				prev[curr.user.id] = curr.user;
				return prev;
			}, {});
		}, [collaborators]);

		const selectedRecords = useSelector((state) => state.selectedRecords);

		const primaryAttribute = usePrimaryAttribute({
			workspace,
			sqlDatabaseId: databaseId,
			schemaName,
			tableName,
		});

		const primaryAttributeAttribute = useMemo(() => {
			if (isAdvancedViewData(data)) {
				return undefined;
			}
			return attributes.find((column) =>
				primaryAttribute
					? primaryAttribute.schemaName === column.schemaName &&
					  primaryAttribute.tableName === column.tableName &&
					  primaryAttribute.columnName === column.attributeName
					: column.isPrimaryKeyInDb
			);
		}, [data, attributes, primaryAttribute]);

		const visibleAttributes: ApiRecordAttribute[] | AdvancedViewAttribute[] =
			useMemo(() => {
				if (isAdvancedViewData(data)) {
					return data.view.attributes;
				}
				const attributesWithoutPrimaryAttributeAttribute =
					attributes
						?.filter((attribute) => attribute.visible)
						.filter((attribute) => attribute !== primaryAttributeAttribute) ??
					null;
				return attributesWithoutPrimaryAttributeAttribute;
			}, [data, attributes, primaryAttributeAttribute]);

		const rowHeightInPixels =
			styles.table.cell.paddingVerticalInPixels * 2 +
			styles.table.row.lineHeightInPixels * rowHeight;

		const numberOfColumns = visibleAttributes.length;

		const columnWidths = useMemo(() => {
			return visibleAttributes.map((attribute) => {
				return attribute.width ?? DEFAULT_COLUMN_WIDTH;
			});
		}, [visibleAttributes]);

		const stickyColumnWidths = useMemo(() => {
			const getStickyGridColumnWidths = () => {
				// There will always be at least 1 sticky column with a fixed width for the
				// row number and checkbox (if the table is editable)
				const widths = [56];
				if (primaryAttributeAttribute?.visible) {
					widths.push(primaryAttributeAttribute.width ?? DEFAULT_COLUMN_WIDTH);
				}
				return widths;
			};
			return getStickyGridColumnWidths();
		}, [primaryAttributeAttribute?.visible, primaryAttributeAttribute?.width]);

		// Add 1 to account for the sticky header and another 1 for the new record row (if it exists)
		const numberOfRows =
			Math.min(records.length, pageSize) + 1 + (canCreateNewRecord ? 1 : 0);

		// Need to multiply by 2 since the new record row has 2 rows once the use clicks the button
		// to create a new record. Add 1 to account for the bottom border.
		const newRecordsRowHeight = rowHeightInPixels * 2 + 1;

		const headerHeightInPixel = ONE_REM_IN_PIXELS * 2.5;

		const estimateRowSize = useCallback(
			(index: number): number => {
				if (index === 0) {
					return headerHeightInPixel;
				}
				if (canCreateNewRecord && index === numberOfRows - 1) {
					return newRecordsRowHeight;
				}
				return rowHeightInPixels;
			},
			[
				canCreateNewRecord,
				headerHeightInPixel,
				newRecordsRowHeight,
				numberOfRows,
				rowHeightInPixels,
			]
		);

		const estimateColumnSize = useCallback(
			(index: number): number => {
				return columnWidths[index];
			},
			[columnWidths]
		);

		const rowVirtualizer = useVirtual({
			size: numberOfRows,
			parentRef,
			estimateSize: estimateRowSize,
		});

		const columnVirtualizer = useVirtual({
			horizontal: true,
			size: numberOfColumns,
			parentRef,
			estimateSize: estimateColumnSize,
		});

		const lastRowIndex = Math.min(pageSize, records.length);

		const totalWidthOfStickyColumns = stickyColumnWidths.reduce(
			(a, b) => a + b,
			0
		);

		// numRecords will be undefined when creating a new advanced view and no SQL
		// has been written yet.
		if (numRecords === undefined) {
			return null;
		}

		return (
			<StyledTableBody ref={parentRef} $width={width} $height={height}>
				<TableHeaderAndBody
					$height={
						canCreateNewRecord
							? rowVirtualizer.totalSize - newRecordsRowHeight
							: rowVirtualizer.totalSize
					}
					$width={columnVirtualizer.totalSize + totalWidthOfStickyColumns}
				>
					<TableHeader $height={headerHeightInPixel}>
						<TableHeaderStickyColumn
							$height={headerHeightInPixel}
							$width={totalWidthOfStickyColumns}
						>
							{stickyColumnWidths.map(
								(stickyColumnWidth, stickyColumnIndex) => {
									return (
										<div
											key={stickyColumnIndex}
											style={{
												position: 'absolute',
												left:
													stickyColumnIndex === 0 ? 0 : stickyColumnWidths[0],
												width: `${stickyColumnWidth}px`,
												height: `${headerHeightInPixel}px`,
											}}
										>
											<VirtualizedCell
												columnIndex={stickyColumnIndex}
												rowIndex={0}
												schemaName={schemaName}
												tableName={tableName}
												records={records}
												extractedTableData={extractedTableData}
												selectedRecords={selectedRecords}
												data={data}
												editable={editable}
												pageNumber={pageNumber}
												searchQuery={searchQuery}
												usersById={usersById}
												routeLocationSearch={routeLocationSearch}
												primaryAttribute={primaryAttribute}
												visibleAttributes={visibleAttributes}
												setPageNumber={setPageNumber}
												numberOfStickyColumns={stickyColumnWidths.length}
												primaryAttributeAttribute={primaryAttributeAttribute}
												scrollToRowIndex={rowVirtualizer.scrollToIndex}
												scrollToColumnIndex={columnVirtualizer.scrollToIndex}
											/>
										</div>
									);
								}
							)}
						</TableHeaderStickyColumn>
						{/* Non-sticky header columns */}
						{columnVirtualizer.virtualItems.map((virtualColumn) => (
							<div
								key={virtualColumn.index}
								style={{
									position: 'absolute',
									top: 0,
									left: totalWidthOfStickyColumns,
									width: `${virtualColumn.size}px`,
									height: `${headerHeightInPixel}px`,
									transform: `translateX(${virtualColumn.start}px) translateY(0px)`,
								}}
							>
								<VirtualizedCell
									columnIndex={virtualColumn.index + stickyColumnWidths.length}
									rowIndex={0}
									measureRef={virtualColumn.measureRef}
									schemaName={schemaName}
									tableName={tableName}
									records={records}
									extractedTableData={extractedTableData}
									selectedRecords={selectedRecords}
									data={data}
									editable={editable}
									pageNumber={pageNumber}
									searchQuery={searchQuery}
									usersById={usersById}
									routeLocationSearch={routeLocationSearch}
									primaryAttribute={primaryAttribute}
									visibleAttributes={visibleAttributes}
									setPageNumber={setPageNumber}
									numberOfStickyColumns={stickyColumnWidths.length}
									primaryAttributeAttribute={primaryAttributeAttribute}
									scrollToRowIndex={rowVirtualizer.scrollToIndex}
									scrollToColumnIndex={columnVirtualizer.scrollToIndex}
								/>
							</div>
						))}
					</TableHeader>
					{/* Sticky columns in rows */}
					<StickyColumn $width={totalWidthOfStickyColumns}>
						{stickyColumnWidths.map((stickyColumnWidth, stickyColumnIndex) => {
							return rowVirtualizer.virtualItems.map((virtualRow) => {
								// Don't render for the header row or the new record row since those
								// are handled separately
								if (virtualRow.index === 0 || virtualRow.index > lastRowIndex) {
									return null;
								}
								return (
									<div
										key={virtualRow.index}
										style={{
											position: 'absolute',
											left: stickyColumnIndex === 0 ? 0 : stickyColumnWidths[0],
											width: `${stickyColumnWidth}px`,
											height: `${virtualRow.size}px`,
											transform: `translateX(0px) translateY(${
												// Subtract the header row's height
												virtualRow.start - headerHeightInPixel
											}px)`,
										}}
									>
										<VirtualizedCell
											columnIndex={stickyColumnIndex}
											rowIndex={virtualRow.index}
											schemaName={schemaName}
											tableName={tableName}
											records={records}
											extractedTableData={extractedTableData}
											selectedRecords={selectedRecords}
											data={data}
											editable={editable}
											pageNumber={pageNumber}
											searchQuery={searchQuery}
											usersById={usersById}
											routeLocationSearch={routeLocationSearch}
											primaryAttribute={primaryAttribute}
											visibleAttributes={visibleAttributes}
											setPageNumber={setPageNumber}
											numberOfStickyColumns={stickyColumnWidths.length}
											primaryAttributeAttribute={primaryAttributeAttribute}
											scrollToRowIndex={rowVirtualizer.scrollToIndex}
											scrollToColumnIndex={columnVirtualizer.scrollToIndex}
										/>
									</div>
								);
							});
						})}
					</StickyColumn>
					{rowVirtualizer.virtualItems.map((virtualRow) => {
						const lastRowIndex = Math.min(pageSize - 1, records.length - 1) + 1;
						// Don't render for the header row or the new record row since those
						// are handled separately
						if (virtualRow.index === 0 || virtualRow.index > lastRowIndex) {
							return null;
						}
						return (
							<React.Fragment key={virtualRow.index}>
								{columnVirtualizer.virtualItems.map((virtualColumn) => (
									<div
										key={virtualColumn.index}
										style={{
											position: 'absolute',
											top: 0,
											left: totalWidthOfStickyColumns,
											width: `${virtualColumn.size}px`,
											height: `${virtualRow.size}px`,
											transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
										}}
									>
										<VirtualizedCell
											columnIndex={
												virtualColumn.index + stickyColumnWidths.length
											}
											rowIndex={virtualRow.index}
											schemaName={schemaName}
											tableName={tableName}
											records={records}
											extractedTableData={extractedTableData}
											selectedRecords={selectedRecords}
											data={data}
											editable={editable}
											pageNumber={pageNumber}
											searchQuery={searchQuery}
											usersById={usersById}
											routeLocationSearch={routeLocationSearch}
											primaryAttribute={primaryAttribute}
											visibleAttributes={visibleAttributes}
											setPageNumber={setPageNumber}
											numberOfStickyColumns={stickyColumnWidths.length}
											primaryAttributeAttribute={primaryAttributeAttribute}
											scrollToRowIndex={rowVirtualizer.scrollToIndex}
											scrollToColumnIndex={columnVirtualizer.scrollToIndex}
										/>
									</div>
								))}
							</React.Fragment>
						);
					})}
				</TableHeaderAndBody>
				{(numRecords === 0 || numRecords === undefined) && (
					<NoRecordsFound
						style={{
							width: `min(${
								columnVirtualizer.totalSize + totalWidthOfStickyColumns
							}px, ${width}px)`,
						}}
					>
						No records found
					</NoRecordsFound>
				)}
				{canCreateNewRecord && (
					<StickyNewRecordRow
						style={{
							height: newRecordsRowHeight,
							width: `${
								columnVirtualizer.totalSize + totalWidthOfStickyColumns
							}px`,
						}}
					>
						<NewRecordRow
							data={data}
							extractedTableData={extractedTableData}
							pageNumber={pageNumber}
							searchQuery={searchQuery}
							reload={reload}
							visibleAttributes={visibleAttributes as ApiRecordAttribute[]}
							virtualItems={columnVirtualizer.virtualItems}
							primaryAttributeAttribute={primaryAttributeAttribute}
							stickyGridColumnWidths={stickyColumnWidths}
							rowHeightInPixels={rowHeightInPixels}
							scrollToRowIndex={rowVirtualizer.scrollToIndex}
							scrollToColumnIndex={columnVirtualizer.scrollToIndex}
						/>
					</StickyNewRecordRow>
				)}
			</StyledTableBody>
		);
	}
);

export default TableBody;
