import { ApiUser } from 'typings/serverTypes';

export function getUserAvatarUrl(user: ApiUser) {
	if (user.avatarUrl) {
		return user.avatarUrl;
	}

	const avatarColour = user.avatarColour ?? '#aaa';
	const avatarUrl = `https://source.boringavatars.com/marble/120/${
		user.fullName
	}?colors=${avatarColour.substring(1)},061DE0,${avatarColour.substring(
		1
	)},ffffff,000000`;

	return avatarUrl;
}
