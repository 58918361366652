import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { primary } from 'utils/colors';
import styles from 'utils/styles';

export const StyledTableCellContainer = styled.div<{
	selected?: boolean;
	editable?: boolean;
	hasCompletedFirstRender?: boolean;
	rowHeight?: number;
	isRichText?: boolean;
	isEnum?: boolean;
	selectColour?: string | null;
	errors?: string[] | null;
	highlighted?: boolean;
}>`
	width: 100%;
	${(props) => css`
		height: calc(
			${styles.table.cell.paddingVerticalInPixels}px * 2 +
				${styles.table.row.lineHeightInPixels}px * ${props.rowHeight ?? 1}
		);
	`}
	line-height: ${styles.table.row.lineHeight};
	padding: ${(props) =>
		props.isRichText || props.isEnum ? '0' : styles.table.cell.padding};
	font-weight: 400;
	background: ${(props) =>
		props.errors ? rgba(styles.colours.error[500], 0.2) : 'transparent'};

	${(props) =>
		props.highlighted &&
		css`
			background: ${rgba(styles.colours.primary[700], 0.1)};
		`};

	${({ hasCompletedFirstRender, rowHeight }) =>
		hasCompletedFirstRender
			? css`
					white-space: nowrap;
			  `
			: css`
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
					display: -webkit-box;
					-webkit-line-clamp: ${rowHeight ?? 1};
					-webkit-box-orient: vertical;
			  `};

	${(props) =>
		props.editable &&
		css`
			cursor: default;
			user-select: none;
		`};

	${(props) =>
		props.selected &&
		css`
			outline: ${props.selectColour ?? '#aaa'} solid 2px;
			outline-offset: -2px;
		`};

	&:focus,
	&:focus-within {
		outline: ${primary[1]} solid 2px;
		outline-offset: -2px;
	}
`;
