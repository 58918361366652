import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { getSortedWorkspaceDataSources } from 'utils/getSortedWorkspaceDataSources';
import { useDataSourceHealthCheck } from 'utils/useDataSourceHealthCheck';
import { state } from 'valtioState';
import { DefinedDataSource } from 'valtioState/dataSources/index';

import { SectionTitle, UnhealthyDataSourceIndicator } from '../../Sidebar';
import { SidebarItem } from '../../Sidebar/SidebarItem';
import { SidebarSection } from '../../Sidebar/SidebarSection';

interface DataSourceSidebarItemProps {
	dataSource: DefinedDataSource;
	selectedDataSourceId: number | null;
	setSelectedDataSourceId: (id: number) => void;
}

function DataSourceSidebarItem({
	dataSource,
	selectedDataSourceId,
	setSelectedDataSourceId,
}: DataSourceSidebarItemProps) {
	useDataSourceHealthCheck(dataSource);

	return (
		<SidebarItem
			disabled={!dataSource.healthy}
			onClick={() => setSelectedDataSourceId(dataSource.id)}
			iconLeft={<CloudIcon />}
			title={dataSource.name}
			iconRightPrimary={
				dataSource.healthy === false ? (
					<UnhealthyDataSourceIndicator />
				) : undefined
			}
			active={selectedDataSourceId === dataSource.id}
		/>
	);
}

interface DatabasesSectionProps {
	selectedDataSourceId: number | null;
	setSelectedDataSourceId: (id: number) => void;
}

export function DatabasesSection({
	selectedDataSourceId,
	setSelectedDataSourceId,
}: DatabasesSectionProps) {
	const snap = useSnapshot(state);
	const workspaceId = useSelector((state) => state.workspaceId);
	if (!workspaceId) {
		return null;
	}

	const dataSources = getSortedWorkspaceDataSources(
		snap.entities.dataSources.byId,
		workspaceId
	);

	return (
		<SidebarSection
			Header={(props) => <SectionTitle title="Data source" {...props} />}
			initiallyExpanded={true}
			topLevel
		>
			{dataSources.map((dataSource) => {
				const sqlDatabaseId = dataSource.sqlDatabase?.id;
				if (!sqlDatabaseId) {
					return null;
				}
				return (
					dataSource.connected && (
						<DataSourceSidebarItem
							key={dataSource.id}
							dataSource={dataSource}
							selectedDataSourceId={selectedDataSourceId}
							setSelectedDataSourceId={setSelectedDataSourceId}
						/>
					)
				);
			})}
		</SidebarSection>
	);
}
