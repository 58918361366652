import arrayMove from 'array-move';
import React, { useMemo, useState } from 'react';
import {
	SortableContainer as sortableContainer,
	SortableElement as sortableElement,
	SortableHandle as sortableHandle,
	SortEnd,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import ButtonSecondary from 'components/ButtonSecondary';
import { Icon } from 'components/Icon';
import { Popover } from 'components/Popover';
import { StyledButton } from 'components/Sidebar/SidebarSection';
import { Switch } from 'components/Switch';
import { ReactComponent as DragIcon } from 'images/icons/drag.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { areAttributesEqual } from 'utils/areAttributesEqual';
import { neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';
import { useWorkspace } from 'utils/useWorkspace';
import { state } from 'valtioState';

const DropdownRow = styled.li<{ isPrimaryAttribute?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.25rem 0;
	font-size: 0.875rem;
	user-select: none;
	cursor: ${({ isPrimaryAttribute }) =>
		isPrimaryAttribute ? 'default' : 'move'};

	&:hover {
		background: ${neutral[5]};
	}
`;

const Label = styled.span`
	max-width: 12rem;
	margin-left: 0.75rem;
	color: ${neutral[1]};
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DragHandleContainer = styled.div`
	margin-left: auto;
	padding-left: 0.5rem;
`;

const DragHandle = sortableHandle(() => (
	<DragHandleContainer>
		<Icon>
			<DragIcon />
		</Icon>
	</DragHandleContainer>
));

const SortableItem = sortableElement(
	({
		column,
		showTableName,
		onClick,
		dataSourceId,
	}: {
		column: ApiRecordAttribute;
		showTableName: boolean;
		onClick: () => void;
		dataSourceId: number;
	}) => {
		const snap = useSnapshot(state);
		const tableDisplayName = getTableDisplayName({
			tablesById: snap.entities.tables.byId,
			tableId: column.tableId,
		});
		const attributeDisplayName = getAttributeDisplayName({
			attributesById: snap.entities.attributes.byId,
			attributeNamesToIdMap: snap.attributeNamesToIdMap,
			attributeName: column.attributeName,
			tableName: column.tableName,
			schemaName: column.schemaName,
			sqlDatabaseId: dataSourceId,
		});
		return (
			<DropdownRow>
				<Switch checked={column.visible ?? false} onChange={onClick} />
				<Label>
					{showTableName ? `${tableDisplayName}.` : ''}
					{attributeDisplayName}
				</Label>
				<DragHandle />
			</DropdownRow>
		);
	}
);

const SortableContainer = sortableContainer(
	({ children }: { children: React.ReactNode }) => {
		return <ul>{children}</ul>;
	}
);

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem 0.75rem;
`;

interface VisibleAttributesPopoverProps {
	data: TableData<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
}

export function VisibleAttributesPopover({
	data,
	extractedTableData,
}: VisibleAttributesPopoverProps) {
	const { workspace } = useWorkspace();
	const snap = useSnapshot(state);
	const [open, setOpen] = useState(false);

	const {
		databaseId,
		schemaName,
		tableName,
		joins,
		attributes,
		listAttributes,
	} = extractedTableData;

	const { mutate: updateApiView } = useUpdateApiView({
		view: data.view,
	});

	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: databaseId,
		schemaName,
		tableName,
	});
	const primaryAttributeColumn = useMemo(
		() =>
			attributes.find((column) =>
				primaryAttribute
					? primaryAttribute.schemaName === column.schemaName &&
					  primaryAttribute.tableName === column.tableName &&
					  primaryAttribute.columnName === column.attributeName
					: column.isPrimaryKeyInDb
			),
		[attributes, primaryAttribute]
	);

	const columnsWithoutPrimaryAttribute = useMemo(
		() => attributes?.filter((column) => column !== primaryAttributeColumn),
		[attributes, primaryAttributeColumn]
	);

	const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
		const updatedColumns = [
			...(primaryAttributeColumn ? [primaryAttributeColumn] : []),
			...arrayMove(columnsWithoutPrimaryAttribute ?? [], oldIndex, newIndex),
		];
		updateApiView({
			view: {
				id: data.view.id,
				attributes: updatedColumns,
			},
		});
	};

	const showAllColumns = () => {
		const updatedColumns =
			attributes?.map((column) => ({
				...column,
				visible: true,
			})) ?? [];
		updateApiView({
			view: {
				id: data.view.id,
				attributes: updatedColumns,
			},
		});
	};

	const hideAllColumns = () => {
		const updatedAttributes =
			attributes?.map((column) => ({
				...column,
				visible: false,
			})) ?? [];
		const updatedListAttributes =
			listAttributes?.map((column) => ({
				...column,
				visible: false,
			})) ?? [];
		updateApiView({
			view: {
				id: data.view.id,
				attributes: updatedAttributes,
				listAttributes: updatedListAttributes,
			},
		});
	};

	const updateColumnVisibility = (
		columnSchema: string,
		columnTable: string,
		columnName: string,
		visible: boolean
	) => {
		const updatedAttributes =
			attributes?.map((column) =>
				column.schemaName === columnSchema &&
				column.tableName === columnTable &&
				column.attributeName === columnName
					? {
							...column,
							visible,
					  }
					: column
			) ?? [];
		const updatedListAttributes = listAttributes.map((listAttribute) => {
			const attribute = updatedAttributes.find((attribute) =>
				areAttributesEqual({ attribute, listAttribute })
			);

			if (attribute?.visible === false) {
				return { ...listAttribute, visible: false };
			} else {
				return listAttribute;
			}
		});
		updateApiView({
			view: {
				id: data.view.id,
				attributes: updatedAttributes,
				listAttributes: updatedListAttributes,
			},
		});
	};

	const handleBlur = async () => {
		setOpen(false);
	};

	const showTableName = joins.length > 0;

	return (
		<Popover
			trigger={
				<StyledButton
					title="Add attribute"
					icon={<PlusIcon />}
					primary={open}
				/>
			}
			side="right"
			align="start"
			avoidCollisions={false}
			onBlur={handleBlur}
			open={open}
			setOpen={setOpen}
		>
			<SortableContainer
				onSortEnd={onSortEnd}
				lockAxis="y"
				transitionDuration={200}
				helperClass="sortableHelper"
				// Required in order to allow switch to be clickable
				distance={3}
			>
				{primaryAttributeColumn && (
					<DropdownRow isPrimaryAttribute>
						<Switch
							checked={primaryAttributeColumn.visible ?? false}
							onChange={() => {
								updateColumnVisibility(
									primaryAttributeColumn.schemaName,
									primaryAttributeColumn.tableName,
									primaryAttributeColumn.attributeName,
									!primaryAttributeColumn.visible
								);
							}}
						/>
						<Label>
							{showTableName
								? `${getTableDisplayName({
										tablesById: snap.entities.tables.byId,
										tableId: primaryAttributeColumn.tableId,
								  })}.`
								: ''}
							{getAttributeDisplayName({
								attributesById: snap.entities.attributes.byId,
								attributeNamesToIdMap: snap.attributeNamesToIdMap,
								attributeName: primaryAttributeColumn.attributeName,
								tableName: primaryAttributeColumn.tableName,
								schemaName: primaryAttributeColumn.schemaName,
								sqlDatabaseId: databaseId,
							})}
						</Label>
					</DropdownRow>
				)}

				{columnsWithoutPrimaryAttribute.map((column, index) => (
					<SortableItem
						key={`${column.schemaName}/${column.tableName}/${column.attributeName}`}
						index={index}
						column={column}
						showTableName={showTableName}
						dataSourceId={databaseId}
						onClick={() => {
							updateColumnVisibility(
								column.schemaName,
								column.tableName,
								column.attributeName,
								!column.visible
							);
						}}
					/>
				))}
			</SortableContainer>

			<ButtonContainer>
				<ButtonSecondary onClick={hideAllColumns}>Hide all</ButtonSecondary>
				<ButtonSecondary onClick={showAllColumns}>Show all</ButtonSecondary>
			</ButtonContainer>
		</Popover>
	);
}
