import { getDefaultHeaders } from 'api/utils';
import { Role } from 'typings/models';

export function updateMemberRole(
	workspaceId: number,
	memberId: number,
	role: Role
) {
	return fetch(`/api/workspaces/${workspaceId}/members/${memberId}/role`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			role,
		}),
	});
}
