import { fetchDataSourceHealth } from 'api/fetchDataSourceHealth';
import {
	DefinedDataSource,
	setDataSourceHealthy,
} from 'valtioState/dataSources/index';

export async function checkDataSourceHealth(dataSource: DefinedDataSource) {
	const res = await fetchDataSourceHealth({ dataSourceId: dataSource.id });
	if (res.ok) {
		const healthy = await res.json();
		setDataSourceHealthy(dataSource.id, healthy);
	} else {
		setDataSourceHealthy(dataSource.id, false);
	}
}
