import * as Dialog from '@radix-ui/react-dialog';
import styled, { keyframes } from 'styled-components';

import Button from 'components/Button';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { background, backgroundOverlay, neutral } from 'utils/colors';
import { MODAL_Z_INDEX } from 'utils/constants';
import styles from 'utils/styles';

export const overlayShow = keyframes`
	0% { opacity: 0 }
	100% { opacity: 1 }
`;

const Overlay = styled(Dialog.Overlay)`
	background-color: ${backgroundOverlay};
	position: fixed;
	inset: 0;
	z-index: ${MODAL_Z_INDEX};

	@media (prefers-reduced-motion: no-preference) {
		animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
	}
`;

export const contentShow = keyframes`
	0% { opacity: 0; transform: translate(-50%, -48%) scale(.96) }
	100% { opacity: 1; transform: translate(-50%, -50%) scale(1) }
`;

const ModalRoot = styled(Dialog.Content)`
	display: flex;
	flex-direction: column;
	width: 50rem;
	height: 35rem;
	max-width: calc(100% - 4rem);
	max-height: calc(100% - 4rem);
	background: ${background[1]};
	border: 1px solid ${neutral[2]};
	border-radius: ${styles.global.borderRadius};
	box-shadow: ${styles.shadows[500]};
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: none;
	z-index: ${MODAL_Z_INDEX + 1};

	@media (prefers-reduced-motion: no-preference) {
		animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
		will-change: transform;
	}
`;

export const Header = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 2rem;
	background: ${neutral[5]};
	border-bottom: 1px solid ${neutral[4]};
`;

export const Title = styled(Dialog.Title)`
	color: ${neutral[1]};
	font-size: 1rem;
	font-weight: 500;
	cursor: default;
`;

const Content = styled.div`
	flex-grow: 1;
	overflow: auto;
`;

interface ModalProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	title: string;
	children: React.ReactNode;
}

export function Modal({ open, onOpenChange, title, children }: ModalProps) {
	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Portal>
				<Overlay />
				<ModalRoot>
					<Header>
						<Title>{title}</Title>
						<Dialog.Close asChild>
							<Button size="small" icon={<CloseIcon />} />
						</Dialog.Close>
					</Header>
					<Content>{children}</Content>
				</ModalRoot>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
