import React from 'react';
import styled, { css } from 'styled-components';

import { neutral, danger } from 'utils/colors';

export const FieldComponent = styled.div<{ inline?: boolean }>`
	&& + & {
		${(props) =>
			!props.inline &&
			css`
				margin-top: 1rem;
			`}
	}
`;

const Label = styled.label`
	display: flex;
	font-size: 0.875rem;
	font-weight: 400;
	color: ${neutral[1]};
	user-select: none;
`;

const LabelTextContainer = styled.div<{
	$width: number | string;
	$size: FieldProps['labelSize'];
}>`
	display: flex;
	flex-direction: column;
	width: ${({ $width }) =>
		typeof $width === 'number' ? `${$width}rem` : $width};
	padding-top: 0.5rem;
	padding-right: 1rem;
	flex-shrink: 0;
	font-size: ${({ $size }) => ($size === 'medium' ? '0.875rem' : '0.75rem')};
`;

const InputContainer = styled.div<{ $type?: FieldProps['type'] }>`
	flex-grow: 1;

	${({ $type }) =>
		($type === 'checkbox' || $type === 'radio') &&
		css`
			padding-top: 0.375rem;
		`}
`;

const OptionalText = styled.span`
	color: ${neutral[2]};
	font-style: italic;
	font-weight: 400;
`;

const HelpText = styled.p`
	margin-top: 0.5rem;
	color: ${neutral[2]};
	font-size: 0.875rem;
`;

const ErrorText = styled.p`
	margin-top: 0.25rem;
	color: ${danger};
	font-size: 0.875rem;
`;

export interface FieldProps {
	type: 'checkbox' | 'select' | 'radio' | 'input' | 'textarea';
	label?: React.ReactNode;
	optional?: boolean;
	errors?: string[] | null;
	helpText?: React.ReactNode;
	inline?: boolean;
	children: React.ReactNode;
	className?: string;
	/** in rem */
	labelWidth?: number | string;
	/** defaults to medium */
	labelSize?: 'small' | 'medium';
}

export default function Field({
	type,
	label,
	optional,
	errors,
	helpText,
	inline,
	children,
	className,
	labelWidth = 9.5,
	labelSize = 'medium',
}: FieldProps) {
	if (type === 'checkbox') {
		return (
			<FieldComponent className={className}>
				<Label>
					{(label || optional) && (
						<LabelTextContainer $width={labelWidth} $size={labelSize}>
							{label}
							{optional && <OptionalText>Optional</OptionalText>}
						</LabelTextContainer>
					)}
					<InputContainer $type={type}>
						{children}
						{helpText && <HelpText>{helpText}</HelpText>}
						{errors &&
							errors.map((error) => <ErrorText key={error}>{error}</ErrorText>)}
					</InputContainer>
				</Label>
			</FieldComponent>
		);
	}

	if (type === 'radio') {
		return (
			<FieldComponent className={className}>
				<Label>
					{(label || optional) && (
						<LabelTextContainer $width={labelWidth} $size={labelSize}>
							{label}
							{optional && <OptionalText>Optional</OptionalText>}
						</LabelTextContainer>
					)}
					<InputContainer $type={type}>
						{children}
						{helpText && <HelpText>{helpText}</HelpText>}
						{errors &&
							errors.map((error) => <ErrorText key={error}>{error}</ErrorText>)}
					</InputContainer>
				</Label>
			</FieldComponent>
		);
	}

	return (
		<FieldComponent className={className} inline={inline}>
			<Label>
				{(label || optional) && (
					<LabelTextContainer $width={labelWidth} $size={labelSize}>
						{label}
						{optional && <OptionalText>Optional</OptionalText>}
					</LabelTextContainer>
				)}
				<InputContainer>
					{children}
					{helpText && <HelpText>{helpText}</HelpText>}
					{errors &&
						errors.map((error) => <ErrorText key={error}>{error}</ErrorText>)}
				</InputContainer>
			</Label>
		</FieldComponent>
	);
}
