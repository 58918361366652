import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import * as React from 'react';
import styled from 'styled-components';

import { backgroundOverlay, neutral } from 'utils/colors';
import { MODAL_Z_INDEX } from 'utils/constants';

const ScrollAreaRoot = styled(RadixScrollArea.Root)`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const Viewport = styled(RadixScrollArea.Viewport)`
	width: 100%;
	height: 100%;

	& > div {
		height: 100%;
	}
`;

const scrollbarSize = '1rem';

const Scrollbar = styled(RadixScrollArea.Scrollbar)`
	display: flex;
	user-select: none;
	// Disable browser handling of panning and zooming on touch devices
	touch-action: none;
	padding: 0.25rem;

	&[data-orientation='vertical'] {
		width: ${scrollbarSize};
	}

	&[data-orientation='horizontal'] {
		flex-direction: column;
		height: ${scrollbarSize};
	}
`;

const Thumb = styled(RadixScrollArea.Thumb)`
	flex: 1;
	background: ${backgroundOverlay};
	border: 1px solid ${neutral[4]};
	border-radius: ${scrollbarSize};
	// Increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
	position: relative;
	z-index: ${MODAL_Z_INDEX - 1};

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;

		// Extends target size beyond scrollbar track
		min-width: 2rem;
		min-height: 2rem;
	}
`;

type ScrollAreaProps = React.ComponentProps<typeof RadixScrollArea.Root> & {
	children: React.ReactNode;
};

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
	({ children, ...rest }, ref) => {
		return (
			<ScrollAreaRoot type="scroll" scrollHideDelay={1200} ref={ref} {...rest}>
				<Viewport>{children}</Viewport>

				<Scrollbar orientation="vertical">
					<Thumb />
				</Scrollbar>
				<Scrollbar orientation="horizontal">
					<Thumb />
				</Scrollbar>
			</ScrollAreaRoot>
		);
	}
);

ScrollArea.displayName = 'ScrollArea';
