import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { useSocket } from 'components/providers/SocketProvider';
import { useToken } from 'components/providers/TokenProvider';
import { setConnectedUsers } from 'reduxState/slices/connectedUsers';
import {
	addSelectedCell,
	removeSelectedCell,
} from 'reduxState/slices/selectedCells';
import {
	tableStackCleared,
	TableStackState,
} from 'reduxState/slices/tableStack';
import {
	ApiAttribute,
	ApiWorkspaceDetails,
	CellRecord,
	StringRecordId,
} from 'typings/serverTypes';
import { SelectedRecord } from 'typings/types';
import { formatAttributesForTableRecordsApiPayload } from 'utils/formatAttributesForTableRecordsApiPayload';
import { state } from 'valtioState';
import { getTableRecords } from 'valtioState/records/getRecords';
import { updateAttributeValueWithinRecord } from 'valtioState/records/updateAttributeValueWithinRecord';

import { getUrlFilters } from './getUrlFilters';
import { toast } from './toast/toast';

export interface UseTableRecordsResult {
	reload: () => void;
	records: CellRecord[] | null;
	recordsLoading: boolean;
	recordsError: string | null;
	pageNumber: number;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	numRecords: number;
	tableStack: TableStackState;
	windowRef: React.RefObject<HTMLDivElement>;
	collaborators: ApiWorkspaceDetails['collaborators'];
}

type Props = {
	tableId: number;
	workspace?: ApiWorkspaceDetails;
};

export const useTableRecords = ({
	workspace,
	tableId,
}: Props): UseTableRecordsResult => {
	const snap = useSnapshot(state);
	const table = snap.entities.tables.byId[tableId];
	const tableName = table?.tableName ?? '';
	const schema = table && snap.entities.schemas.byId[table.schemaId];
	const schemaName = schema?.name ?? '';
	const sqlDatabaseId = schema?.sqlDatabaseId;
	const windowRef = useRef<HTMLDivElement>(null);
	const socket = useSocket();
	const location = useLocation();
	const [autoFocusFirstTableCell, setAutoFocusFirstTableCell] = useState(true);
	const dispatch = useDispatch();
	const { token } = useToken();
	const queryClient = useQueryClient();

	const [recordsLoading, setRecordsLoading] = useState(true);
	const [recordsError, setRecordsError] = useState<null | string>(null);

	const [pageNumber, setPageNumber] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [recordIds, setRecordIds] = useState<StringRecordId[]>([]);
	const [numRecords, setNumRecords] = useState(0);

	const attributes: ApiAttribute[] = useMemo(() => {
		const value = [];
		for (const attributeId of table?.attributeIds ?? []) {
			const attributeFromState = snap.entities.attributes.byId[attributeId];
			if (attributeFromState) {
				value.push(attributeFromState);
			}
		}
		return value;
	}, [snap.entities.attributes.byId, table?.attributeIds]);

	const urlFilters = useMemo(
		() =>
			getUrlFilters({
				attributes,
				schemaName,
				tableName,
			}),
		[attributes, schemaName, tableName]
	);

	const fetchRecordsFromApiAndUpdateGlobalState = useCallback(async () => {
		// URL filters will take precedence over table filters
		const filters = urlFilters.length ? urlFilters : table?.filters ?? [];
		const sorts = table?.sorts ?? [];
		const attributesPayload = formatAttributesForTableRecordsApiPayload({
			attributes,
		});
		try {
			const result = await getTableRecords({
				tableId,
				attributes: attributesPayload,
				// If there are filters in the URL, those will take priority
				// @ts-expect-error Readonly issues
				filters,
				// @ts-expect-error Readonly issues
				sorts,
				pageNumber,
				searchQuery,
				primaryKeyAttributes: attributes.filter(
					(attribute) => attribute.isPrimaryKeyInDb
				),
			});
			setRecordIds(result.recordIds);
			setNumRecords(result.numRecords);
		} catch (e) {
			if (e instanceof Error) {
				setRecordsError(e.message);
			} else {
				setRecordsError('Something went wrong');
			}
		} finally {
			setRecordsLoading(false);
		}
	}, [urlFilters, table, attributes, tableId, pageNumber, searchQuery]);

	useEffect(() => {
		fetchRecordsFromApiAndUpdateGlobalState();
	}, [fetchRecordsFromApiAndUpdateGlobalState]);

	let primaryKeyColumnCount = 0;
	attributes.forEach((column) => {
		if (column.isPrimaryKeyInDb) {
			++primaryKeyColumnCount;
		}
	});

	useEffect(() => {
		if (!attributes.length) {
			toast.error('This table seems to have no columns.');
			return;
		}
		if (primaryKeyColumnCount === 0) {
			toast.error(
				'This table has no primary key. Add a primary key to avoid problems.'
			);
		} else if (primaryKeyColumnCount > 1) {
			toast.error(
				`This table has a composite primary key defined across
				${primaryKeyColumnCount} columns. Use a single-column primary key to
				avoid problems`
			);
		}
	}, [attributes.length, primaryKeyColumnCount]);

	const getApiRecordsFromGlobalState = (): CellRecord[] => {
		const result = [];
		for (const recordId of recordIds) {
			const record = snap.entities.records.byId[recordId];
			if (record) {
				result.push(record);
			}
		}
		return result;
	};

	const records = getApiRecordsFromGlobalState();

	const tableStack = useSelector((state) => state.tableStack);

	useEffect(() => {
		const shouldClearTableStack = urlFilters.length === 0;
		if (shouldClearTableStack) {
			dispatch(tableStackCleared());
		}
	}, [
		table,
		dispatch,
		location.search,
		schemaName,
		tableName,
		attributes,
		urlFilters.length,
	]);

	useEffect(() => {
		// Reset state on new table
		setPageNumber(0);
		setSearchQuery('');

		// Scroll to top of table
		if (windowRef.current) {
			windowRef.current.scrollTo(0, 0);
		}

		window.analytics.track('Table Viewed', {
			databaseId: sqlDatabaseId,
			schemaName: schemaName,
			tableName: tableName,
		});
	}, [sqlDatabaseId, schemaName, tableName, windowRef]);

	// Focus on first cell of table
	useEffect(() => {
		if (records === null || records.length === 0) {
			return;
		}

		if (autoFocusFirstTableCell && windowRef.current) {
			setAutoFocusFirstTableCell(false);

			const firstCell = windowRef.current.querySelector('.firstCell > div');
			if (firstCell) {
				(firstCell as HTMLTableCellElement).focus();
			}
		}
	}, [records, autoFocusFirstTableCell, windowRef]);

	// Realtime updates
	useEffect(() => {
		socket.emit('join-room', {
			token,
			tableId,
		});

		const handleUpdateRecord = (data: {
			value: string;
			attributeId: number;
			stringRecordId: StringRecordId;
		}) => {
			updateAttributeValueWithinRecord({
				recordId: data.stringRecordId,
				attributeId: data.attributeId,
				newValueForAttribute: data.value,
			});
		};
		socket.on('update-record', handleUpdateRecord);

		const handleUpdateConnectedUsers = (userIds: number[]) => {
			dispatch(setConnectedUsers(userIds));
		};
		socket.on('update-connected-users', handleUpdateConnectedUsers);

		const handleAddSelectedCell = ({ userId, cellId }: SelectedRecord) => {
			dispatch(addSelectedCell({ userId, cellId }));
		};
		socket.on('add-selected-cell', handleAddSelectedCell);

		const handleRemoveSelectedCell = ({ userId }: { userId: number }) => {
			dispatch(removeSelectedCell({ userId }));
		};
		socket.on('remove-selected-cell', handleRemoveSelectedCell);

		return () => {
			socket.emit('leave-room', {
				token,
				tableId,
			});

			socket.removeEventListener('update-record');
			socket.removeEventListener('update-connected-users');
			socket.removeEventListener('add-selected-cell');
			socket.removeEventListener('remove-selected-cell');
		};
	}, [
		token,
		socket,
		dispatch,
		queryClient,
		pageNumber,
		searchQuery,
		sqlDatabaseId,
		schemaName,
		tableName,
		tableId,
		snap.attributeNamesToIdMap,
	]);

	return {
		reload: fetchRecordsFromApiAndUpdateGlobalState,
		records,
		recordsLoading,
		recordsError,
		pageNumber,
		setPageNumber,
		searchQuery,
		setSearchQuery,
		numRecords,
		tableStack,
		windowRef,
		collaborators: workspace?.collaborators ?? [],
	};
};
