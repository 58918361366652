import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { StyledCheckbox } from 'components/Checkbox';
import { background, bodyBackground, neutral, primary } from 'utils/colors';
import styles, { media } from 'utils/styles';

export const StyledTableCell = styled.div<{
	isPrimaryAttribute?: boolean;
	editable?: boolean;
	editing?: boolean;
	highlighted?: boolean;
	noBorderBottom?: boolean;
	noBorder?: boolean;
	noBackground?: boolean;
	$resizing?: boolean;
}>`
	padding: 0;
	color: ${neutral[1]};
	text-align: left;
	background: ${background[1]};
	background-clip: padding-box; // Fixes hidden border bug on Firefox (https://stackoverflow.com/a/16337203)
	box-shadow: ${({ noBorder, noBorderBottom }) => {
			if (noBorder) {
				return `inset 0 0 0 0`;
			}
			return noBorderBottom ? `inset -1px 0 0 0` : `inset -1px -1px 0 0`;
		}}
		${neutral[4]};

	&:focus ${StyledCheckbox} {
		background: ${background[1]};
	}

	z-index: ${({ editing, $resizing }) => (editing || $resizing ? 1 : 0)};

	${({ editable, noBackground }) =>
		!editable &&
		css`
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: ${noBackground ? 'none' : bodyBackground};
				pointer-events: none;
			}
		`}

	${({ highlighted, editing }) =>
		highlighted &&
		!editing &&
		css`
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				content: '';
				width: 100%;
				height: 100%;
				background: ${rgba(styles.colours.primary[700], 0.1)};
				pointer-events: none;
				z-index: 0.5;
			}
		`}

  position: relative;

	&:focus,
	&:focus-within {
		outline: ${primary[1]} solid 2px;
		outline-offset: -2px;
	}

	${({ isPrimaryAttribute }) =>
		isPrimaryAttribute &&
		media.desktop`
			position: sticky;
			left: 4rem;
			z-index: 3;
			border-right: none;
		`}
`;
