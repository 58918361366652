import { CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import { background, neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

export const CardInput = styled(CardElement)`
	margin-top: 4px;
	height: 2.625rem;
	padding: 0.75rem 0.75rem;
	background: ${background[1]};
	border-radius: ${styles.global.borderRadius};
	box-shadow: 0 0 0 1px ${neutral[4]};
	cursor: text;

	&:focus,
	&.StripeElement--focus {
		box-shadow: 0 0 0 1px ${primary[1]};
		outline: none;
	}
`;
