import { useMutation, UseMutationOptions } from 'react-query';

import {
	updateSlackAlertSubscription,
	UpdateSlackAlertSubscriptionParams,
} from 'api/updateSlackAlertSubscription';

export const useUpdateApiTracker = (
	options?: UseMutationOptions<
		unknown,
		string,
		UpdateSlackAlertSubscriptionParams,
		unknown
	>
) =>
	useMutation<unknown, string, UpdateSlackAlertSubscriptionParams, unknown>(
		async (variables) => {
			const result = await updateSlackAlertSubscription(variables);

			if (!result.ok) {
				const { error } = await result.json();
				throw error;
			}
			return true;
		},
		{
			...options,
		}
	);
