import { getDefaultHeaders } from 'api/utils';

export type VerifyDatabaseNameAvailableResponse = boolean;

export function verifyDatabaseNameAvailable(workspaceId: number, name: string) {
	return fetch(
		`/api/workspaces/${workspaceId}/verify-database-name-available/${name}`,
		{
			method: 'GET',
			headers: getDefaultHeaders(),
		}
	);
}
