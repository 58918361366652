import * as Sentry from '@sentry/browser';
import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import {
	getDataSourceAffectedViews,
	GetDataSourceAffectedViewsResponse,
} from 'api/getDataSourceAffectedViews';
import { useUpdateApiDataSourceSettings } from 'api/reactQueryHooks/useUpdateApiDataSourceSettings';
import { AlertDialog } from 'components/AlertDialog';
import { ScrollArea } from 'components/ScrollArea';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { DataSourceNavigator } from 'components/pages/DataSourceNavigator';
import { AffectedViewsMessage } from 'components/pages/DataSourceSettings/AffectedViewsMessage';
import { SettingField } from 'components/pages/DataSourceSettings/SettingField';
import {
	EntitiesListContainer,
	EntitiesListLabel,
	Panel,
	PanelContent,
	PanelHeader,
} from 'components/pages/DataSourceSettings/index';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { View } from 'typings/models';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';
import { isSequinDataSource } from 'utils/isSequinDataSource';
import { toast } from 'utils/toast/toast';
import { state } from 'valtioState';
import { updateDataSourceSettings as updateValtioDataSourceSettings } from 'valtioState/dataSources/updateDataSourceSettings';

const StyledSidebarItem = styled(SidebarItem)`
	margin-top: 1rem;
`;

export function DataSourceSettingsPanel({
	workspace,
	dataSourceId,
}: {
	dataSourceId: number;
	workspace: ApiWorkspaceDetails;
}) {
	const history = useHistory();
	const snap = useSnapshot(state);
	const dataSource = snap.entities.dataSources.byId[dataSourceId];
	const sqlDatabase = Object.values(snap.entities.sqlDatabases.byId).find(
		(sqlDatabase) => sqlDatabase?.dataSourceId === dataSourceId
	);

	const [isAffectedViewsModalOpen, setIsAffectedViewsModalOpen] =
		useState(false);
	const [affectedViews, setAffectedViews] = useState<View[]>([]);
	const queryClient = useQueryClient();
	const workspaceId = workspace.id;
	const [dataSourceName, setDataSourceName] = useState('');
	const { mutate: updateDataSourceSettings } = useUpdateApiDataSourceSettings({
		onMutate: (variables) => {
			updateValtioDataSourceSettings(variables);
		},
		onSuccess: (_data, _variables) => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
		},
		onError: (error) => {
			toast.error(error);
		},
	});

	useEffect(() => {
		setDataSourceName(dataSource?.name ?? '');
	}, [dataSource?.name, dataSourceId]);
	if (!dataSource) {
		return null;
	}
	return (
		<Panel>
			<PanelHeader
				title={dataSource.name ?? ''}
				icon={<CloudIcon />}
				previousPageUrl="/data-sources"
			/>

			<ScrollArea>
				<PanelContent connected={dataSource.connected}>
					<SettingField
						label={'Display name'}
						value={dataSourceName}
						onChange={(
							e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => {
							setDataSourceName(e.target.value);
						}}
						onBlur={() => {
							if (dataSourceName !== dataSource?.name) {
								updateDataSourceSettings({
									dataSourceId,
									displayName: dataSourceName,
								});
							}
						}}
					/>
					<SettingField
						label={'Connected'}
						value={dataSource.connected}
						onClick={() => {
							if (dataSource.connected) {
								getDataSourceAffectedViews({ dataSourceId })
									.then((res) => {
										if (res.ok) {
											return res.json();
										} else {
											Sentry.captureException(
												new ApiError('Problem fetching affected views')
											);
										}
									})
									.then((data: GetDataSourceAffectedViewsResponse) => {
										if (data.length > 0) {
											setAffectedViews(data);
											setIsAffectedViewsModalOpen(true);
										} else {
											updateDataSourceSettings({
												dataSourceId,
												connected: !dataSource.connected,
											});
										}
									})
									.catch((_error) => {
										Sentry.captureException(
											new ApiError('Problem fetching affected views')
										);
										toast.error('Problem disconnecting data source.');
									});
							} else {
								updateDataSourceSettings({
									dataSourceId,
									connected: !dataSource.connected,
								});
							}
						}}
					/>
					{dataSource.connected && (
						<SettingField
							label={'Editable'}
							value={dataSource.editable}
							disabled={isSequinDataSource(sqlDatabase?.dialect)}
							tooltip={
								isSequinDataSource(sqlDatabase?.dialect)
									? 'Sequin data sources are read-only'
									: undefined
							}
							onClick={() => {
								updateDataSourceSettings({
									dataSourceId,
									editable: !dataSource.editable,
								});
							}}
						/>
					)}

					<StyledSidebarItem
						title="Manage connection"
						to={`/databases/${sqlDatabase?.id}/settings`}
						iconLeft={<SettingsIcon />}
					/>

					<EntitiesListContainer>
						<EntitiesListLabel>Tables</EntitiesListLabel>
						<DataSourceNavigator dataSource={dataSource} forSettings />
					</EntitiesListContainer>

					<AlertDialog
						open={isAffectedViewsModalOpen}
						onOpenChange={(open) => setIsAffectedViewsModalOpen(open)}
						title={'Data in use'}
						description={
							<AffectedViewsMessage
								affectedViews={affectedViews}
								type={'data source'}
							/>
						}
						actionText={'Disconnect data source'}
						actionType={'danger'}
						onConfirm={() => {
							updateDataSourceSettings({
								dataSourceId,
								connected: !dataSource.connected,
							});
							// Make sure to close all the other settings panels related to the data source tables and attributes.
							if (dataSource.connected) {
								history.replace(`/settings/data-sources/${dataSourceId}`);
							}
						}}
					/>
				</PanelContent>
			</ScrollArea>
		</Panel>
	);
}
