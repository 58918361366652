import { ChangeEvent, FormEvent, useState } from 'react';

import { changePassword } from 'api/changePassword';
import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { useToken } from 'components/providers/TokenProvider';
import { toast } from 'utils/toast/toast';

interface Props {
	loading?: boolean;
	userHasPassword: boolean;
}

const ChangePasswordForm = ({
	loading,
	userHasPassword,
}: Props): JSX.Element | null => {
	const [state, setState] = useState({
		loading: false,
		fields: {
			password: {
				value: '',
				errors: null,
			},
		},
	});
	const { setToken } = useToken();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setState({
			...state,
			fields: {
				...state.fields,
				[name]: {
					value,
					errors: [],
				},
			},
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (state.loading) {
			return;
		}
		setState({ ...state, loading: true });

		try {
			const response = await changePassword(state.fields.password.value);
			if (!response.ok) {
				throw await response.json();
			}

			const token = await response.json();
			setToken(token);

			toast('Password changed.');

			setState({
				loading: false,
				fields: { password: { value: '', errors: null } },
			});
		} catch (error) {
			setState({ ...state, loading: false });

			// @ts-expect-error FIX
			if (error.formErrors) {
				const fields = state.fields;
				// @ts-expect-error FIX
				for (const field of Object.keys(error.formErrors)) {
					if (field === 'password') {
						// @ts-expect-error FIX
						if (error.formErrors[field].length) {
							// @ts-expect-error FIX
							fields[field].errors = error.formErrors[field];
						} else {
							fields[field].errors = null;
						}
					}
				}

				setState({ ...state, fields });
			}
		}
	};

	if (loading) {
		return null;
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="password"
				type="password"
				label={userHasPassword ? 'New password' : 'Password'}
				value={state.fields.password.value}
				errors={state.fields.password.errors}
				onChange={handleChange}
				autoFocus
			/>
			<FormButton loading={state.loading ? true : undefined}>
				{userHasPassword ? 'Change password' : 'Set password'}
			</FormButton>
		</Form>
	);
};

export default ChangePasswordForm;
