import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FullHeightAndCenteredContent } from 'components/FullHeightAndCenteredContent';
import { FormPanel } from 'components/ListView/FormPanel';
import { ListViewTable } from 'components/ListView/ListViewTable';
import { InfoText } from 'components/Page';
import Spinner from 'components/Spinner';
import TableControls from 'components/TableControls';
import { ViewFooter } from 'components/ViewFooter';
import { OopsErrorPage } from 'components/pages/OopsError';
import { ApiWorkspaceDetails, CellRecord } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { background } from 'utils/colors';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';
import { usePrimaryAttributes } from 'utils/usePrimaryAttributes';

const StyledListView = styled.div`
	display: flex;
	position: relative;
	height: 100%;
`;

const NoRecordsFound = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

const ListSection = styled.div<{ isFormPanelOpen: boolean }>`
	display: flex;
	flex-direction: column;
	padding-right: ${({ isFormPanelOpen }) => (isFormPanelOpen ? `26rem` : `0`)};
	overflow: hidden;
	width: 100%;
	background: ${background[1]};
`;

const ListViewTableContainer = styled.div`
	flex-grow: 1;
	overflow: auto;
	margin-bottom: auto;
`;

export interface FormPanelProperties {
	isOpen: boolean;
	record: CellRecord | null;
	rowNumber: number | null;
	pageNumber: number | null;
}

interface Props {
	records: CellRecord[] | null;
	recordsLoading: boolean;
	recordsError: boolean | null;
	data: TableData<ViewType.BASIC>;
	numRecords: number;
	workspace: ApiWorkspaceDetails;
	reload: () => void;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	pageNumber: number;
	searchQuery: string;
	isInViewBuilder?: boolean;
	extractedTableData: ExtractedTableData;
}

export function ListView({
	records,
	recordsLoading,
	recordsError,
	data,
	numRecords,
	workspace,
	reload,
	setSearchQuery,
	setPageNumber,
	pageNumber,
	searchQuery,
	isInViewBuilder,
	extractedTableData,
}: Props) {
	const location = useLocation();
	const [hasFocusOnFirstListItem, setHasFocusOnFirstListItem] = useState(false);
	const attributes = data.view.type === 'BASIC' ? data.view.attributes : [];
	const [formPanelProperties, setFormPanelProperties] =
		useState<FormPanelProperties>({
			isOpen: false,
			record: null,
			rowNumber: null,
			pageNumber: 0,
		});

	const { databaseId, schemaName, tableName } = extractedTableData;
	const primaryAttributes = usePrimaryAttributes({ workspace });
	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: databaseId,
		schemaName,
		tableName,
	});

	const primaryAttributeConfig = attributes.find((attribute) =>
		primaryAttribute
			? primaryAttribute.schemaName === attribute.schemaName &&
			  primaryAttribute.tableName === attribute.tableName &&
			  primaryAttribute.columnName === attribute.attributeName
			: attribute.isPrimaryKeyInDb
	);
	const setPageNumberAndFocus = (pageNumber: number) => {
		setHasFocusOnFirstListItem(false);
		setPageNumber(pageNumber);
	};

	// Set the panel to the first record when first loading the list
	useEffect(() => {
		if (!formPanelProperties.isOpen && records?.length) {
			setFormPanelProperties({
				isOpen: true,
				record: records[0],
				rowNumber: 1,
				pageNumber,
			});
		}
	}, [pageNumber, formPanelProperties.isOpen, setFormPanelProperties, records]);

	// Re-select a record any time the page number changes
	useEffect(() => {
		if (formPanelProperties.pageNumber !== pageNumber && records?.length) {
			setFormPanelProperties({
				isOpen: true,
				record: records[0],
				rowNumber: 1,
				pageNumber,
			});
		}
	}, [formPanelProperties.pageNumber, pageNumber, records]);

	// Update form panel record whenever new record data is fetched (e.g. when toggling attribute visibility)
	useEffect(() => {
		if (formPanelProperties.rowNumber && records?.length) {
			setFormPanelProperties((oldValue) => ({
				...oldValue,
				record: records[(oldValue?.rowNumber ?? 1) - 1],
			}));
		}
	}, [formPanelProperties.rowNumber, records]);

	// Focus on first list view item
	useEffect(() => {
		if (!hasFocusOnFirstListItem && records?.length) {
			setHasFocusOnFirstListItem(true);
			const rowElements = document.querySelectorAll('.listViewRow');
			(
				rowElements?.[0]?.querySelector('.listViewRowCheckbox') as
					| HTMLElement
					| undefined
			)?.focus();
		}
	}, [setHasFocusOnFirstListItem, hasFocusOnFirstListItem, records]);

	return (
		<StyledListView>
			<ListSection isFormPanelOpen={formPanelProperties.isOpen}>
				{!isInViewBuilder && (
					<TableControls
						extractedTableData={extractedTableData}
						setSearchQuery={setSearchQuery}
						setPageNumber={setPageNumberAndFocus}
						pageNumber={pageNumber}
						workspaceId={workspace.id}
						data={data}
						searchQuery={searchQuery}
						reload={reload}
					/>
				)}
				<ListViewTableContainer>
					{recordsLoading ? (
						<FullHeightAndCenteredContent>
							<Spinner />
						</FullHeightAndCenteredContent>
					) : recordsError ? (
						<OopsErrorPage />
					) : (
						<>
							<ListViewTable
								primaryAttributeConfig={primaryAttributeConfig}
								view={data.view}
								records={records}
								primaryAttribute={primaryAttribute}
								primaryAttributes={primaryAttributes}
								formPanelProperties={formPanelProperties}
								setFormPanelProperties={setFormPanelProperties}
								pageNumber={pageNumber}
							/>
							{Number(numRecords) === 0 && !recordsLoading && (
								<NoRecordsFound>
									<InfoText>No records found</InfoText>
								</NoRecordsFound>
							)}
						</>
					)}
				</ListViewTableContainer>
				<ViewFooter
					loading={recordsLoading}
					canDelete
					tableData={data}
					pageNumber={pageNumber}
					searchQuery={searchQuery}
					numRecords={numRecords}
					setPageNumber={setPageNumberAndFocus}
					extractedTableData={extractedTableData}
					reload={reload}
				/>
			</ListSection>
			{formPanelProperties.isOpen &&
				formPanelProperties.record &&
				formPanelProperties.rowNumber !== null && (
					<FormPanel
						primaryAttributeConfig={primaryAttributeConfig}
						data={data}
						record={formPanelProperties.record}
						rowNumber={formPanelProperties.rowNumber}
						pageNumber={pageNumber}
						searchQuery={searchQuery}
						primaryAttributes={primaryAttributes}
						setFormPanelProperties={setFormPanelProperties}
						routeLocationSearch={location.search}
						rootTablePrimaryAttribute={primaryAttribute}
						extractedTableData={extractedTableData}
						reload={reload}
					/>
				)}
		</StyledListView>
	);
}
