import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { DataViewer } from 'components/DataViewer';
import { ApiBasicView } from 'typings/serverTypes';
import { TableData } from 'typings/types';
import { extractTableDataForView } from 'utils/extractTableData';
import { canWriteViewData } from 'utils/permissions';
import { useCollaborator } from 'utils/useCollaborator';
import { useViewRecords } from 'utils/useViewRecords';

type Props = {
	view: ApiBasicView;
	isInViewBuilder?: boolean;
};

export function ViewContent({ view, isInViewBuilder }: Props) {
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const {
		reload,
		records,
		pageNumber,
		searchQuery,
		recordsLoading,
		recordsError,
		numRecords,
		tableStack,
		windowRef,
		setPageNumber,
		setSearchQuery,
		collaborators,
	} = useViewRecords({
		workspace,
		viewId: view.id,
		// Filters and sorts to be passed when temporary filters/sorts are supported
		// filters: [],
		// sorts: [],
		// If isInViewBuilder is true, useViewRecords will ignore column visibility when fetching records
		isInViewBuilder,
		view,
	});

	const data: TableData = useMemo(() => ({ type: 'view', view }), [view]);

	const collaborator = useCollaborator();

	if (!workspace) {
		return null;
	}

	return (
		<DataViewer
			collaborators={collaborators}
			workspace={workspace}
			data={data}
			extractedTableData={extractTableDataForView(data)}
			reload={reload}
			records={records}
			recordsLoading={recordsLoading}
			recordsError={recordsError}
			pageNumber={pageNumber}
			searchQuery={searchQuery}
			numRecords={numRecords}
			tableStack={tableStack}
			windowRef={windowRef}
			setPageNumber={setPageNumber}
			setSearchQuery={setSearchQuery}
			canWriteData={canWriteViewData(collaborator)}
			isInViewBuilder={isInViewBuilder}
		/>
	);
}
