import { useSnapshot } from 'valtio';

import { SidebarInfoText } from 'components/Sidebar';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { SidebarSection } from 'components/Sidebar/SidebarSection';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as CaretDownIcon } from 'images/icons/caretDown.svg';
import { ReactComponent as CaretRightIcon } from 'images/icons/caretRight.svg';
import { ReactComponent as DisconnectedIcon } from 'images/icons/disconnected.svg';
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { ReactComponent as ViewOnlyIcon } from 'images/icons/viewOnly.svg';
import {
	filterSchemasByDataSource,
	filterTablesByMultipleSchemas,
	filterTablesBySchema,
	schemaHasSomeConnectedTables,
} from 'utils/filterSchemasAndTables';
import { state } from 'valtioState';
import { DefinedDataSource } from 'valtioState/dataSources/index';

interface TableItemProps {
	tableId: number;
	tableDisplayName: string;
	tableIsConnected: boolean;
	tableIsEditable: boolean;
	dataSourceId: number;
	forSettings?: boolean;
	indentation?: number;
	clickHandler?: (id: number) => void;
	indent?: boolean;
	active?: boolean;
}

function TableItem({
	tableId,
	tableDisplayName,
	tableIsConnected,
	tableIsEditable,
	dataSourceId,
	forSettings,
	indentation,
	clickHandler,
	active,
}: TableItemProps) {
	return (
		<SidebarItem
			title={tableDisplayName}
			to={
				clickHandler
					? undefined
					: `${
							forSettings ? '/settings' : ''
					  }/data-sources/${dataSourceId}/tables/${tableId}`
			}
			onClick={clickHandler && (() => clickHandler(tableId))}
			iconLeft={<TableIcon />}
			showRightChevron={forSettings}
			iconRightPrimary={
				forSettings && !tableIsConnected ? (
					<Tooltip value="Not connected">
						<DisconnectedIcon />
					</Tooltip>
				) : undefined
			}
			iconRightSecondary={
				forSettings && !tableIsEditable ? (
					<Tooltip value="Not editable">
						<ViewOnlyIcon />
					</Tooltip>
				) : undefined
			}
			indentation={indentation}
			active={active}
		/>
	);
}

interface DataSourceNavigatorProps {
	dataSource: DefinedDataSource;
	forSettings?: boolean;
	setTableId?: (id: number) => void;
	selectedTableId?: number | null;
	indent?: boolean;
}

export function DataSourceNavigator({
	dataSource,
	forSettings,
	setTableId,
	selectedTableId,
	indent,
}: DataSourceNavigatorProps) {
	const snap = useSnapshot(state);

	const schemas = filterSchemasByDataSource(
		dataSource.id,
		snap.entities.sqlDatabases.byId,
		snap.entities.schemas.byId
	);
	const dataSourceTables = filterTablesByMultipleSchemas(
		schemas,
		/* @ts-expect-error Readonly issues */
		snap.entities.tables.byId
	);

	if (!dataSource.connected) {
		return <SidebarInfoText>Data source disconnected</SidebarInfoText>;
	}

	if (dataSource.healthy === false) {
		return <SidebarInfoText>Connection failed</SidebarInfoText>;
	}

	if (dataSourceTables.length === 0) {
		if (dataSource.loading) {
			return <SidebarInfoText>Loading tables</SidebarInfoText>;
		} else {
			return <SidebarInfoText>No tables found</SidebarInfoText>;
		}
	}

	if (!forSettings && !dataSourceTables.some((table) => table?.connected)) {
		return <SidebarInfoText>No connected tables</SidebarInfoText>;
	}

	if (schemas.length > 1) {
		return (
			<>
				{schemas.map(
					(schema) =>
						schema &&
						(schemaHasSomeConnectedTables(schema.id, dataSourceTables) ||
							forSettings) && (
							<SidebarSection
								key={schema.id}
								Header={(props) => (
									<SidebarItem
										iconLeft={
											props.expanded ? <CaretDownIcon /> : <FolderIcon />
										}
										iconLeftHover={
											props.expanded ? <CaretDownIcon /> : <CaretRightIcon />
										}
										title={schema.name}
										{...props}
									/>
								)}
								initiallyExpanded
								indentation={indent ? 1 : 0}
							>
								{filterTablesBySchema(schema.id, dataSourceTables).map(
									(table) =>
										table &&
										(table.connected || forSettings) && (
											<TableItem
												key={table.id}
												tableId={table.id}
												tableDisplayName={table.displayName}
												tableIsEditable={table.editable}
												tableIsConnected={table.connected}
												dataSourceId={dataSource.id}
												forSettings={forSettings}
												indentation={indent ? 2 : 1}
												clickHandler={setTableId}
												active={selectedTableId === table.id}
											/>
										)
								)}
							</SidebarSection>
						)
				)}
			</>
		);
	}

	return (
		<>
			{dataSourceTables.map(
				(table) =>
					table &&
					(table.connected || forSettings) && (
						<TableItem
							key={table.id}
							tableId={table.id}
							tableDisplayName={table.displayName}
							tableIsEditable={table.editable}
							tableIsConnected={table.connected}
							dataSourceId={dataSource.id}
							forSettings={forSettings}
							clickHandler={setTableId}
							indentation={indent ? 1 : 0}
							active={selectedTableId === table.id}
						/>
					)
			)}
		</>
	);
}
