import {
	DataSourceType,
	HostingProvider,
	DefaultDataSourceValues,
} from 'typings/types';

// Trick taken from https://stackoverflow.com/a/54598743/8360496
function asDataSourceTypeTypes<T>(value: {
	[K in keyof T]: DataSourceType;
}): typeof value {
	return value;
}

export const dataSourceTypes = asDataSourceTypeTypes({
	postgres: {
		name: 'postgres',
		order: 1,
		verboseName: 'PostgreSQL',
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/postgres.svg',
		logoDark: null,
	},
	mysql: {
		name: 'mysql',
		order: 2,
		verboseName: 'MySQL',
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/mysql.png',
		logoDark:
			'https://basedash-images.nyc3.digitaloceanspaces.com/mysql-white.png',
	},
	mariadb: {
		name: 'mariadb',
		order: 3,
		verboseName: 'MariaDB',
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/mariadb.svg',
		logoDark:
			'https://basedash-images.nyc3.digitaloceanspaces.com/mariadb-white.svg',
	},
	redshift: {
		name: 'redshift',
		order: 4,
		verboseName: 'Redshift',
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/redshift.png',
		logoDark: null,
	},
	mssql: {
		name: 'mssql',
		order: 5,
		verboseName: 'SQL Server',
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/mssql.png',
		logoDark: null,
	},
	airtable: {
		name: 'airtable',
		order: 6,
		verboseName: 'Airtable',
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/airtable.svg',
		logoDark: null,
	},
	shopify: {
		name: 'shopify',
		order: 7,
		verboseName: 'Shopify',
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/shopify.svg',
		logoDark: null,
	},
	stripe: {
		name: 'stripe',
		order: 8,
		verboseName: 'Stripe',
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/stripe.svg',
		logoDark: null,
	},
	close: {
		name: 'close',
		order: 9,
		verboseName: 'Close',
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/close.svg',
		logoDark: null,
	},
	twilio: {
		name: 'twilio',
		order: 10,
		verboseName: 'Twilio',
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/twilio.svg',
		logoDark: null,
	},
});

// Trick taken from https://stackoverflow.com/a/54598743/8360496
function asHostingProviderTypes<T>(value: {
	[K in keyof T]: HostingProvider;
}): typeof value {
	return value;
}

export const hostingProviders = asHostingProviderTypes({
	aws: {
		name: 'aws',
		verboseName: 'AWS',
		order: 1,
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/aws.svg',
		logoDark:
			'https://basedash-images.nyc3.digitaloceanspaces.com/aws-white.svg',
		hidden: false,
		dashboardUrl: 'https://console.aws.amazon.com/',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl:
			'https://www.loom.com/embed/63d54008ab69492fb9b21a20ee486575',
		hasTrustedSource: true,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl:
			'https://www.loom.com/embed/9c1e89a3932f4332b767fd3b03ee266c',
		hostLabel: 'Endpoint',
		portLabel: 'Port',
		databaseNameLabel: 'DB name',
		usernameLabel: 'Username',
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	googleCloud: {
		name: 'googleCloud',
		verboseName: 'Google Cloud',
		order: 2,
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/google-cloud.svg',
		logoDark: '',
		hidden: false,
		dashboardUrl: 'https://console.cloud.google.com/',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl:
			'https://www.loom.com/embed/c71fd7610b1a43008b309fdfce584f4a',
		hasTrustedSource: true,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl:
			'https://www.loom.com/embed/5b3624614a4e4f2fb731d3cc5168fe0d',
		hostLabel: 'Public IP address',
		portLabel: null,
		databaseNameLabel: 'Database name',
		usernameLabel: 'Username',
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: 'Authorized network IP address',
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	azure: {
		name: 'azure',
		verboseName: 'Azure',
		order: 3,
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/azure.svg',
		logoDark: '',
		hidden: false,
		dashboardUrl: 'https://portal.azure.com/',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl:
			'https://www.loom.com/embed/e855332c5dbf4161b0ce9cc08ec0e7a0',
		hasTrustedSource: true,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl:
			'https://www.loom.com/embed/287bd40b285d4ad8bd1f3ece04888536',
		hostLabel: 'Server name',
		portLabel: null,
		databaseNameLabel: 'Database name',
		usernameLabel: 'Username',
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: 'Firewall rule IP address',
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	digitalOcean: {
		name: 'digitalOcean',
		verboseName: 'DigitalOcean',
		order: 4,
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/digital-ocean.svg',
		logoDark: '',
		hidden: false,
		dashboardUrl: 'https://cloud.digitalocean.com/',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl:
			'https://www.loom.com/embed/9b5a793e84254c5f87bb9d090f124961?sharedAppSource=personal_library',
		hasTrustedSource: true,
		trustedSourceInstructions:
			'Navigate to your database, then click "Edit Sources" in the trusted sources section. Click "Edit" in the trusted sources section, then enter the BaseDash IP address shown above.',
		trustedSourceVideoUrl:
			'https://www.loom.com/embed/7d6b13b56bc048d1b20cf6b8972e89f6',
		hostLabel: 'Host',
		portLabel: 'Port',
		databaseNameLabel: 'Database',
		usernameLabel: 'Username',
		passwordLabel: 'Password',
		hasConnectionString: true,
		connectionStringLabel: 'Connection string',
		trustedSourceLabel: 'Trusted source IP address',
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	heroku: {
		name: 'heroku',
		verboseName: 'Heroku',
		order: 5,
		logoLight: 'https://basedash-images.nyc3.digitaloceanspaces.com/heroku.svg',
		logoDark: '',
		hidden: false,
		dashboardUrl: 'https://dashboard.heroku.com/',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl:
			'https://www.loom.com/embed/6e459207b68347c88551b2671b604b96',
		hasTrustedSource: false,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl: null,
		hostLabel: 'Host',
		portLabel: 'Port',
		databaseNameLabel: 'Database',
		usernameLabel: 'User',
		passwordLabel: 'Password',
		hasConnectionString: true,
		connectionStringLabel: 'URI',
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	other: {
		name: 'other',
		verboseName: 'Other',
		order: 6,
		logoLight:
			'https://basedash-images.nyc3.cdn.digitaloceanspaces.com/other.svg',
		logoDark:
			'https://basedash-images.nyc3.cdn.digitaloceanspaces.com/other-white.svg',
		hidden: false,
		dashboardUrl: null,
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl: null,
		hasTrustedSource: true,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl: null,
		hostLabel: null,
		portLabel: null,
		databaseNameLabel: null,
		usernameLabel: null,
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	supabase: {
		name: 'supabase',
		verboseName: 'Supabase',
		order: 7,
		logoLight:
			'https://basedash-images.nyc3.digitaloceanspaces.com/supabase-logo-icon.svg',
		logoDark: null,
		hidden: false,
		dashboardUrl: 'https://app.supabase.io',
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl: null,
		hasTrustedSource: false,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl: null,
		hostLabel: 'Host',
		portLabel: 'Port',
		databaseNameLabel: 'Database name',
		usernameLabel: 'User',
		passwordLabel: 'Password',
		hasConnectionString: true,
		connectionStringLabel: 'Connection string',
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	localhost: {
		name: 'localhost',
		verboseName: 'Localhost',
		order: 8,
		logoLight:
			'https://basedash-images.nyc3.cdn.digitaloceanspaces.com/localhost.svg',
		logoDark:
			'https://basedash-images.nyc3.cdn.digitaloceanspaces.com/localhost-white.svg',
		hidden: false,
		dashboardUrl: null,
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl: null,
		hasTrustedSource: true,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl: null,
		hostLabel: 'Ngrok host',
		portLabel: 'Ngrok port',
		databaseNameLabel: null,
		usernameLabel: null,
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
	sequin: {
		name: 'sequin',
		verboseName: 'Sequin',
		order: 100,
		logoLight: '',
		logoDark: '',
		hidden: true,
		dashboardUrl: null,
		connectionStringInstructions: null,
		connectionStringVideoUrl: null,
		credentialsInstructions: null,
		credentialsVideoUrl: null,
		hasTrustedSource: false,
		trustedSourceInstructions: null,
		trustedSourceVideoUrl: null,
		hostLabel: null,
		portLabel: null,
		databaseNameLabel: null,
		usernameLabel: null,
		passwordLabel: null,
		hasConnectionString: false,
		connectionStringLabel: null,
		trustedSourceLabel: null,
		sslEnabledLabel: null,
		customSslCertificateLabel: null,
		sslClientCertificateLabel: null,
		sslClientKeyLabel: null,
		sshEnabledLabel: null,
		sshHostLabel: null,
		sshPortLabel: null,
		sshUsernameLabel: null,
		sshPasswordLabel: null,
		sshKeyEnabledLabel: null,
		sshPrivateKeyLabel: null,
		sshKeyPassphraseLabel: null,
	},
});

export const defaultDataSourceValues: {
	[dataSourceType in keyof typeof dataSourceTypes]: Partial<{
		[hostingProvider in keyof typeof hostingProviders]: DefaultDataSourceValues;
	}>;
} = {
	postgres: {
		digitalOcean: {
			hostDefaultValue: null,
			portDefaultValue: '25060',
			databaseNameDefaultValue: 'defaultdb',
			usernameDefaultValue: 'doadmin',
			sslEnabledDefaultValue: true,
		},
		googleCloud: {
			hostDefaultValue: null,
			portDefaultValue: '5432',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'postgres',
			sslEnabledDefaultValue: true,
		},
		aws: {
			hostDefaultValue: null,
			portDefaultValue: '5432',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'postgres',
			sslEnabledDefaultValue: true,
		},
		azure: {
			hostDefaultValue: null,
			portDefaultValue: '5432',
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
		heroku: {
			hostDefaultValue: null,
			portDefaultValue: '5432',
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
		supabase: {
			hostDefaultValue: null,
			portDefaultValue: '5432',
			databaseNameDefaultValue: 'postgres',
			usernameDefaultValue: 'postgres',
			sslEnabledDefaultValue: false,
		},
	},
	airtable: {
		sequin: {
			hostDefaultValue: null,
			portDefaultValue: null,
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
	},
	mysql: {
		digitalOcean: {
			hostDefaultValue: null,
			portDefaultValue: '25060',
			databaseNameDefaultValue: 'defaultdb',
			usernameDefaultValue: 'doadmin',
			sslEnabledDefaultValue: true,
		},
		googleCloud: {
			hostDefaultValue: null,
			portDefaultValue: '5439',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'root',
			sslEnabledDefaultValue: true,
		},
		aws: {
			hostDefaultValue: null,
			portDefaultValue: '3306',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'admin',
			sslEnabledDefaultValue: true,
		},
		azure: {
			hostDefaultValue: null,
			portDefaultValue: '3306',
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
	},
	mssql: {
		googleCloud: {
			hostDefaultValue: null,
			portDefaultValue: '1433',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'sqlserver',
			sslEnabledDefaultValue: true,
		},
		aws: {
			hostDefaultValue: null,
			portDefaultValue: '1433',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'admin',
			sslEnabledDefaultValue: true,
		},
		azure: {
			hostDefaultValue: null,
			portDefaultValue: '1433',
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
	},
	mariadb: {
		aws: {
			hostDefaultValue: null,
			portDefaultValue: '3306',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'admin',
			sslEnabledDefaultValue: true,
		},
		azure: {
			hostDefaultValue: null,
			portDefaultValue: '3306',
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: true,
		},
	},
	redshift: {
		aws: {
			hostDefaultValue: null,
			portDefaultValue: '5439',
			databaseNameDefaultValue: null,
			usernameDefaultValue: 'awsuser',
			sslEnabledDefaultValue: true,
		},
	},
	twilio: {
		sequin: {
			hostDefaultValue: null,
			portDefaultValue: null,
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: null,
		},
	},
	close: {
		sequin: {
			hostDefaultValue: null,
			portDefaultValue: null,
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: null,
		},
	},
	stripe: {
		sequin: {
			hostDefaultValue: null,
			portDefaultValue: null,
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: null,
		},
	},
	shopify: {
		sequin: {
			hostDefaultValue: null,
			portDefaultValue: null,
			databaseNameDefaultValue: null,
			usernameDefaultValue: null,
			sslEnabledDefaultValue: null,
		},
	},
};

export const hostingProvidersByDataSourceType: {
	[dataSourceType in keyof typeof dataSourceTypes]: (keyof typeof hostingProviders)[];
} = {
	postgres: [
		'aws',
		'googleCloud',
		'azure',
		'digitalOcean',
		'heroku',
		'supabase',
		'other',
		'localhost',
	],
	airtable: ['sequin', 'other', 'localhost'],
	mysql: ['aws', 'googleCloud', 'azure', 'digitalOcean', 'other', 'localhost'],
	mssql: ['aws', 'googleCloud', 'azure', 'other', 'localhost'],
	mariadb: ['aws', 'azure', 'other', 'localhost'],
	redshift: ['aws', 'other', 'localhost'],
	twilio: ['sequin', 'other', 'localhost'],
	close: ['sequin', 'other', 'localhost'],
	stripe: ['sequin', 'other', 'localhost'],
	shopify: ['sequin', 'other', 'localhost'],
};
