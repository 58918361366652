import * as Sentry from '@sentry/browser';
import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useSnapshot } from 'valtio';

import {
	fetchAttributeAffectedViews,
	FetchAttributeAffectedViewsResponse,
} from 'api/fetchAttributeAffectedViews';
import { useUpdateApiAttributeSettings } from 'api/reactQueryHooks/useUpdateApiAttributeSettings';
import { AlertDialog } from 'components/AlertDialog';
import { DataTypeIcon } from 'components/DataTypeIcon';
import { ScrollArea } from 'components/ScrollArea';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
} from 'components/Table/tableUtils';
import { AffectedViewsMessage } from 'components/pages/DataSourceSettings/AffectedViewsMessage';
import { SettingField } from 'components/pages/DataSourceSettings/SettingField';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';
import { toast } from 'utils/toast/toast';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';
import { state } from 'valtioState';
import { updateAttributeSettings as updateValtioAttributeSettings } from 'valtioState/attributes/updateAttributeSettings';

import { NotConnectedContainer, Panel, PanelContent, PanelHeader } from '.';

type TableSettingsPanelProps = {
	attributeId: number;
	dataSourceId: number;
	workspace: ApiWorkspaceDetails;
};

function AttributeSettingsPanel({
	attributeId,
	dataSourceId,
	workspace,
}: TableSettingsPanelProps) {
	const snap = useSnapshot(state);
	const attribute = snap.entities.attributes.byId[attributeId];
	const table = attribute && snap.entities.tables.byId[attribute.tableId];
	const schema = table && snap.entities.schemas.byId[table.schemaId];

	const [attributeDisplayName, setAttributeDisplayName] = useState('');
	const [attributeDescription, setAttributeDescription] = useState('');
	const [attributeAllowedValues, setAttributeAllowedValues] = useState('');
	const [isAffectedViewsModalOpen, setIsAffectedViewsModalOpen] =
		useState(false);
	const [affectedViews, setAffectedViews] =
		useState<FetchAttributeAffectedViewsResponse>([]);

	const queryClient = useQueryClient();
	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: schema?.sqlDatabaseId ?? 0,
		schemaName: schema?.name ?? '',
		tableName: table?.tableName ?? '',
	});

	const isAttributePrimaryAttribute =
		primaryAttribute?.columnName === attribute?.attributeName;

	const foreignKeys = table?.foreignKeys;
	const foreignKey = useMemo(
		() =>
			foreignKeys
				? /* @ts-expect-error Readonly issues */
				  getForeignKey(attribute?.attributeName ?? '', foreignKeys)
				: undefined,
		[foreignKeys, attribute]
	);
	const isForeignKey = !!foreignKey;

	const attributeType = getAttributeTypeFromDbColumnType({
		dbColumnType: attribute?.typeInDb,
	});

	const canBeObscured = useMemo(() => {
		if (isForeignKey) {
			return false;
		}
		if (!attribute) {
			return false;
		}

		if (isAttributePrimaryAttribute) {
			return false;
		}

		// Check if is primary key in db or not connected
		if (attribute.isPrimaryKeyInDb) {
			return false;
		}

		// Check if attribute is connected
		if (!attribute.connected) {
			return false;
		}

		// Only allow text and number attributes to be obscured for now
		// Other types require more complex editing UIs and will be added in the future
		const obscurableTypesInDb = ['TEXT', 'NUMBER'];
		return obscurableTypesInDb.includes(attributeType);
	}, [attribute, attributeType, isAttributePrimaryAttribute, isForeignKey]);

	const { mutate: updateAttributeSettings } = useUpdateApiAttributeSettings({
		onMutate: (variables) => {
			updateValtioAttributeSettings(variables);
		},
		onSuccess: (_data, variables) => {
			// Invalidate the workspace query in case views have been deleted
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspace.id,
			]);
			if (variables.displayName !== undefined) {
				toast('Attribute display name updated.');
			}
			if (variables.description !== undefined) {
				toast('Attribute description updated.');
			}
			if (variables.isObscured !== undefined) {
				toast(
					`Attribute is now  ${variables.isObscured ? '' : 'not'}  obscured.`
				);
			}
			if (variables.allowedValues !== undefined) {
				const allowedValueCount = variables.allowedValues.split('\n').length;

				toast(
					`Attribute now has ${allowedValueCount}  allowed ${
						allowedValueCount === 1 ? 'value' : 'values'
					}.`
				);
			}
		},
		onError: (error) => {
			toast.error(error);
		},
	});

	useEffect(() => {
		setAttributeDisplayName(attribute?.displayName ?? '');
		setAttributeDescription(attribute?.description ?? '');
		setAttributeAllowedValues(attribute?.allowedValues ?? '');
	}, [attribute]);

	if (attribute === undefined || table === undefined || schema === undefined) {
		return null;
	}

	return (
		<Panel>
			<PanelHeader
				title={attribute.displayName}
				icon={
					<DataTypeIcon
						workspace={workspace}
						// FIXME: dataSourceId should be sqlDatabaseId
						sqlDatabaseId={dataSourceId}
						attribute={attribute}
						/* @ts-expect-error Readonly issues */
						foreignKeys={table.foreignKeys}
						/* @ts-expect-error Readonly issues */
						enums={table.enums}
					/>
				}
				previousPageUrl={`/settings/data-sources/${dataSourceId}/tables/${table?.id}`}
			/>

			<ScrollArea>
				<PanelContent connected={attribute.connected}>
					<SettingField
						label={'Name'}
						readOnly
						value={attribute.attributeName}
					/>
					<SettingField
						label={'Display name'}
						value={attributeDisplayName}
						onChange={(
							event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => {
							setAttributeDisplayName(event.target.value);
						}}
						onBlur={() => {
							if (attributeDisplayName !== attribute.displayName) {
								updateAttributeSettings({
									attributeId,
									displayName: attributeDisplayName,
								});
							}
						}}
					/>
					<SettingField
						label={'Description'}
						value={attributeDescription}
						isTextArea
						onChange={(
							event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => {
							setAttributeDescription(event.target.value);
						}}
						onBlur={() => {
							if (attributeDescription !== attribute.description) {
								updateAttributeSettings({
									attributeId,
									description: attributeDescription,
								});
							}
						}}
					/>
					<SettingField
						label={'Connected'}
						disabled={attribute.isPrimaryKeyInDb || isAttributePrimaryAttribute}
						tooltip={
							attribute.isPrimaryKeyInDb
								? 'Cannot disconnect primary key'
								: isAttributePrimaryAttribute
								? 'Cannot disconnect primary attribute'
								: undefined
						}
						value={attribute.connected}
						onClick={() => {
							if (attribute.connected) {
								fetchAttributeAffectedViews({
									attributeId,
								})
									.then((res) => {
										if (res.ok) {
											return res.json();
										} else {
											Sentry.captureException(
												new ApiError('Problem fetching affected views')
											);
										}
									})
									.then((data: FetchAttributeAffectedViewsResponse) => {
										if (data.length > 0) {
											setAffectedViews(data);
											setIsAffectedViewsModalOpen(true);
										} else {
											updateAttributeSettings({
												attributeId,
												connected: !attribute.connected,
											});
										}
									})
									.catch((_error) => {
										Sentry.captureException(
											new ApiError('Problem fetching affected views')
										);
										toast.error('Problem disconnecting attribute.');
									});
							} else {
								updateAttributeSettings({
									attributeId,
									connected: !attribute.connected,
								});
							}
						}}
					/>
					{attribute.connected && (
						<SettingField
							label={'Editable'}
							value={attribute.editable}
							onClick={() => {
								updateAttributeSettings({
									attributeId,
									editable: !attribute.editable,
								});
							}}
						/>
					)}

					{canBeObscured && (
						<SettingField
							label={'Obscured'}
							value={attribute.isObscured}
							onClick={() => {
								updateAttributeSettings({
									attributeId,
									isObscured: !attribute.isObscured,
								});
							}}
						/>
					)}

					{attribute.connected && attributeType === 'TEXT' && (
						<SettingField
							label="Restrict values"
							value={attribute.hasAllowedValues}
							helpText={
								attribute.hasAllowedValues
									? 'Disable to allow any value.'
									: 'Enable to limit allowed values to a specified list.'
							}
							onClick={() => {
								updateAttributeSettings({
									attributeId,
									hasAllowedValues: !attribute.hasAllowedValues,
								});
							}}
						/>
					)}

					{attribute.connected &&
						attributeType === 'TEXT' &&
						attribute.hasAllowedValues && (
							<SettingField
								label="Allowed values"
								value={attributeAllowedValues}
								isTextArea
								helpText="Add each allowed value on a separate line."
								onChange={(
									event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
								) => {
									setAttributeAllowedValues(event.target.value);
								}}
								onBlur={() => {
									if (attributeAllowedValues !== attribute.allowedValues) {
										updateAttributeSettings({
											attributeId,
											allowedValues: attributeAllowedValues,
										});
									}
								}}
							/>
						)}

					{!attribute.connected && (
						<NotConnectedContainer>
							Attribute disconnected
						</NotConnectedContainer>
					)}
					<AlertDialog
						open={isAffectedViewsModalOpen}
						onOpenChange={(open) => setIsAffectedViewsModalOpen(open)}
						title={'Data in use'}
						description={
							<AffectedViewsMessage
								affectedViews={affectedViews}
								type={'attribute'}
							/>
						}
						actionText={'Disconnect attribute'}
						actionType={'danger'}
						onConfirm={() => {
							updateAttributeSettings({
								attributeId,
								connected: !attribute.connected,
							});
						}}
					/>
				</PanelContent>
			</ScrollArea>
		</Panel>
	);
}

export { AttributeSettingsPanel };
