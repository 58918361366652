import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useSnapshot } from 'valtio';

import { Checkbox } from 'components/Checkbox';
import { ListItem } from 'components/ListDirectory';
import { FormPanelProperties } from 'components/ListView/index';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
	getRecordId,
	recordIdsAreEqual,
} from 'components/Table/tableUtils';
import {
	deselectRecords,
	selectRecords,
} from 'reduxState/slices/selectedRecords';
import { PrimaryAttribute } from 'typings/models';
import {
	ApiBasicView,
	ApiForeignKey,
	ApiRecordAttribute,
	CellRecord,
} from 'typings/serverTypes';
import { areAttributesEqual } from 'utils/areAttributesEqual';
import { background, neutral, primary } from 'utils/colors';
import { EMPTY_VISUAL_SYMBOL, NULL_VISUAL_SYMBOL } from 'utils/constants';
import { formatDateAndTime } from 'utils/formatDateAndTime';
import { getCheckedValue } from 'utils/getCheckedValue';
import { getPrimaryAttributeAttributeFromGlobalStore } from 'utils/getPrimaryAttributeAttributeFromGlobalStore';
import { getRecordDisplayValue } from 'utils/getRecordDisplayValue';
import { getRecordValue } from 'utils/getRecordValue';
import styles from 'utils/styles';
import { truncateValue } from 'utils/truncateValue';
import { state } from 'valtioState';

export const TABLE_ROW_HORIZONTAL_PADDING = '0.75rem';
export const CELL_SPACING = '2rem';

const StyledCheckbox = styled(Checkbox)`
	transform: scale(0.75);
`;
const PrimaryAttributeValue = styled.div`
	width: 100%;
	margin-right: 1rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

interface StyledAttributeValueProps {
	isObscured?: boolean;
}

const StyledAttributeValue = styled.div`
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-family: ${({ isObscured }: StyledAttributeValueProps) =>
		isObscured ? `Basehash` : `${styles.text.fontFamilyText}`};
`;

const SelectRecordCheckbox = styled.input`
	width: 1rem;
	height: 1rem;
	padding: 0;
	margin: 0;
	vertical-align: middle;
	opacity: 0;
	margin-right: 1rem;
	cursor: pointer;
	outline: 0;

	&:checked,
	&:focus {
		opacity: 1;
	}
`;
export const CheckboxCell = styled.td<{ as?: string }>`
	padding-right: 0.5rem;
	padding-left: ${TABLE_ROW_HORIZONTAL_PADDING};
	border-radius: 4px 0 0 4px;
	outline: 0;
	${({ as }) =>
		as === 'th' &&
		css`
			background-color: ${background[1]};
			position: sticky;
			top: 0;
			border-radius: 0;
		`}
`;
const ListAttributeCell = styled.td<{ isPrimary?: boolean }>`
	padding-right: ${CELL_SPACING};
	font-size: ${({ isPrimary }) => (isPrimary ? '1rem' : '0.75rem')};

	&:last-child {
		border-radius: 0 4px 4px 0;
		padding-right: ${TABLE_ROW_HORIZONTAL_PADDING};
	}
`;
const StyledListItem = styled(ListItem)<{ isShowingInFormPanel: boolean }>`
	display: table-row;
	height: 2.25rem;
	cursor: pointer;

	${({ isShowingInFormPanel }) =>
		isShowingInFormPanel &&
		css`
			${ListAttributeCell} {
				border-top: 1px solid ${neutral[2]};
				border-bottom: 1px solid ${neutral[2]};

				&:last-child {
					border-right: 1px solid ${neutral[2]};
				}
			}

			${CheckboxCell} {
				border: 1px solid ${neutral[2]};
				border-right: 0;
			}
		`};

	${CheckboxCell}:focus-within {
		border-color: ${primary[1]};
	}

	${CheckboxCell}:focus-within ~ ${ListAttributeCell} {
		border-color: ${primary[1]};
	}

	&:hover {
		${ListAttributeCell}, ${CheckboxCell} {
			background: ${neutral[5]};
		}
	}

	&:hover,
	&:focus-within {
		background: none;
		${SelectRecordCheckbox} {
			opacity: 1;
		}
	}
`;

interface AttributeValueProps {
	record: CellRecord;
	attribute: ApiRecordAttribute;
	primaryAttributes: PrimaryAttribute[];
	foreignKeys?: ApiForeignKey[];
	databaseId: number;
}

const AttributeValue = ({
	record,
	attribute,
	primaryAttributes,
	foreignKeys,
	databaseId,
}: AttributeValueProps) => {
	const snap = useSnapshot(state);
	const foreignKey =
		foreignKeys && getForeignKey(attribute.attributeName, foreignKeys);

	const primaryAttributeAttribute = useMemo(() => {
		return getPrimaryAttributeAttributeFromGlobalStore({
			primaryAttributes,
			foreignKey: foreignKey,
			databaseId: databaseId,
			attributeNamesToIdMap: snap.attributeNamesToIdMap,
			attributesById: snap.entities.attributes.byId,
		});
	}, [
		primaryAttributes,
		foreignKey,
		databaseId,
		snap.attributeNamesToIdMap,
		snap.entities.attributes.byId,
	]);
	const attributeType = getAttributeTypeFromDbColumnType({
		dbColumnType: attribute.typeInDb,
		isForeignKey: Boolean(foreignKey),
	});
	const attributeId = attribute.id;

	const rawValue = getRecordValue({
		record,
		attributeId,
		attributeType,
	});
	const displayValue = getRecordDisplayValue({
		rawValue,
		attributeType,
		primaryAttributeAttribute,
		record,
		attributeId,
	});

	const renderValue = () => {
		if (rawValue === null) {
			return NULL_VISUAL_SYMBOL;
		}
		if (rawValue === '') {
			return EMPTY_VISUAL_SYMBOL;
		}
		if (rawValue instanceof Date) {
			const { formattedDateWithTime } = formatDateAndTime({
				type: attributeType,
				value: rawValue,
			});

			return <span>{formattedDateWithTime}</span>;
		}
		if (attributeType === 'BOOLEAN' || typeof rawValue === 'boolean') {
			const checked = getCheckedValue(rawValue);

			return <StyledCheckbox checked={checked} disabled />;
		}
		return truncateValue({ value: displayValue, length: 20 });
	};
	return (
		<StyledAttributeValue
			isObscured={attribute.isObscured}
			className={'fs-mask highlight-block'}
		>
			{renderValue()}
		</StyledAttributeValue>
	);
};

interface ListViewTableRowProps {
	record: CellRecord;
	view: ApiBasicView;
	primaryAttribute: ApiRecordAttribute | undefined;
	rowNumber: number;
	pageNumber: number;
	formPanelProperties: {
		isOpen: boolean;
		record: CellRecord | null;
		rowNumber: number | null;
	};
	setFormPanelProperties: React.Dispatch<
		React.SetStateAction<FormPanelProperties>
	>;
	primaryAttributes: PrimaryAttribute[];
}

export const ListViewTableRow = ({
	record,
	view,
	setFormPanelProperties,
	formPanelProperties,
	rowNumber,
	pageNumber,
	primaryAttributes,
	primaryAttribute,
}: ListViewTableRowProps) => {
	const dispatch = useDispatch();
	const snap = useSnapshot(state);
	const checkboxRef = useRef<HTMLInputElement | null>(null);
	const listAttributes = view.listAttributes;
	const selectedRecords = useSelector((state) => state.selectedRecords);
	const recordId = getRecordId({
		schemaName: view.schemaName,
		tableName: view.tableName,
		record,
		primaryKeyAttributes: view.primaryKeyAttributes,
	});
	const isSelected = useMemo(
		() =>
			selectedRecords.some(
				(selectedRecord) =>
					recordId && recordIdsAreEqual(selectedRecord, recordId)
			),
		[selectedRecords, recordId]
	);

	const visibleAttributes = useMemo(() => {
		return (
			listAttributes
				?.filter((listAttribute) => listAttribute.visible)
				.filter((listAttribute) =>
					primaryAttribute
						? !areAttributesEqual({
								attribute: primaryAttribute,
								listAttribute,
						  })
						: true
				) ?? null
		);
	}, [listAttributes, primaryAttribute]);
	const primaryAttributeForeignKey =
		primaryAttribute &&
		getForeignKey(primaryAttribute.attributeName, view.foreignKeys);
	const attributeId = primaryAttribute?.id;
	const primaryAttributeAttribute = useMemo(() => {
		return getPrimaryAttributeAttributeFromGlobalStore({
			primaryAttributes,
			foreignKey: primaryAttributeForeignKey,
			databaseId: view.sqlDatabaseId,
			attributeNamesToIdMap: snap.attributeNamesToIdMap,
			attributesById: snap.entities.attributes.byId,
		});
	}, [
		primaryAttributes,
		primaryAttributeForeignKey,
		view.sqlDatabaseId,
		snap.attributeNamesToIdMap,
		snap.entities.attributes.byId,
	]);

	const attributeType = getAttributeTypeFromDbColumnType({
		dbColumnType: primaryAttribute?.typeInDb,
		isForeignKey: Boolean(primaryAttributeForeignKey),
	});
	const rawValue = getRecordValue({
		record,
		attributeId,
		attributeType,
	});
	const primaryAttributeDisplayValue =
		primaryAttribute === undefined
			? null
			: getRecordDisplayValue({
					rawValue,
					attributeType,
					primaryAttributeAttribute,
					record,
					attributeId,
			  });

	const toggleSelected = (_event: ChangeEvent<HTMLInputElement>) => {
		if (recordId === null) {
			return;
		}
		if (isSelected) {
			dispatch(deselectRecords([recordId]));
		} else {
			dispatch(selectRecords([recordId]));
		}
	};
	const handleSetFormPanelProperties = useCallback(() => {
		setFormPanelProperties({
			isOpen: true,
			record,
			rowNumber,
			pageNumber,
		});
	}, [setFormPanelProperties, record, pageNumber, rowNumber]);
	const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
		if (
			checkboxRef.current &&
			checkboxRef.current?.contains(event.target as Node)
		) {
			return;
		}
		checkboxRef.current?.focus();
		handleSetFormPanelProperties();
	};
	const handleCheckboxKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === 'Enter') {
			(
				document
					.querySelector('#listViewFormPanel')
					?.querySelector(
						'div[tabIndex="0"], input:not(:disabled), textarea:not(:disabled), select:not(:disabled)'
					) as HTMLElement | undefined
			)?.focus();
		}
	};
	useEffect(() => {
		if (checkboxRef.current) {
			const currentCheckboxRef = checkboxRef.current;
			currentCheckboxRef.addEventListener(
				'focus',
				handleSetFormPanelProperties
			);
			return () =>
				currentCheckboxRef?.removeEventListener(
					'focus',
					handleSetFormPanelProperties
				);
		}
	}, [handleSetFormPanelProperties]);

	const handleRowKeyDown = (
		event: React.KeyboardEvent<HTMLTableRowElement>
	) => {
		const rowElements = document.querySelectorAll('.listViewRow');
		if (event.key === 'ArrowDown' || event.key === 'j') {
			event.preventDefault();
			(
				rowElements?.[rowNumber]?.querySelector('.listViewRowCheckbox') as
					| HTMLInputElement
					| undefined
			)?.focus();
		}
		if (event.key === 'ArrowUp' || event.key === 'k') {
			event.preventDefault();
			(
				rowElements?.[rowNumber - 2]?.querySelector('.listViewRowCheckbox') as
					| HTMLInputElement
					| undefined
			)?.focus();
		}
	};
	return (
		<StyledListItem
			as="tr"
			onClick={handleRowClick}
			isShowingInFormPanel={formPanelProperties.rowNumber === rowNumber}
			onKeyDown={handleRowKeyDown}
			className={'listViewRow'}
		>
			<CheckboxCell>
				<SelectRecordCheckbox
					type="checkbox"
					checked={isSelected}
					onChange={toggleSelected}
					onKeyDown={handleCheckboxKeyDown}
					ref={checkboxRef}
					className={'listViewRowCheckbox'}
				/>
			</CheckboxCell>
			<ListAttributeCell isPrimary>
				<PrimaryAttributeValue className={'fs-mask highlight-block'}>
					{primaryAttributeDisplayValue}
				</PrimaryAttributeValue>
			</ListAttributeCell>
			{visibleAttributes.map(
				(attribute, index) =>
					view.sqlDatabaseId !== null && (
						<ListAttributeCell key={index}>
							<AttributeValue
								record={record}
								attribute={attribute}
								foreignKeys={view.foreignKeys}
								primaryAttributes={primaryAttributes}
								databaseId={view.sqlDatabaseId}
							/>
						</ListAttributeCell>
					)
			)}
		</StyledListItem>
	);
};
