import styled from 'styled-components';

import { QueryContent } from 'components/pages/View/QueryContent';
import { ViewContent } from 'components/pages/View/ViewContent';
import { ApiView } from 'typings/serverTypes';
import { ViewType } from 'typings/types';
import { primary } from 'utils/colors';
import { media } from 'utils/styles';

const ViewSettingsPageContainer = styled.div`
	border-top: 1px solid ${primary[1]};
	width: 100%;
	height: 100%;
	overflow: auto;

	${media.tabletUp`
		border-left: 1px solid ${primary[1]};
	`}
`;

interface ViewSettingsPageProps {
	view: ApiView;
}

export function ViewSettingsPage({ view }: ViewSettingsPageProps) {
	return (
		<ViewSettingsPageContainer>
			{view.type === ViewType.BASIC && (
				<ViewContent view={view} isInViewBuilder />
			)}
			{view.type === ViewType.ADVANCED && (
				<QueryContent view={view} isInViewBuilder />
			)}
		</ViewSettingsPageContainer>
	);
}
