import React from 'react';
import { useVirtual } from 'react-virtual';

import {
	navigateDownWithKeyboard,
	navigateLeftWithKeyboard,
	navigateRightWithKeyboard,
	navigateUpWithKeyboard,
} from 'utils/tableKeyboardNavigation';

type Props = {
	value: string | boolean | null | Date;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
	lastRowIndex: number | undefined;
	lastVisibleColumnIndex: number | undefined;
};

export function getReadOnlyTableCellKeyboardShortcuts({
	value,
	scrollToColumnIndex,
	scrollToRowIndex,
	lastRowIndex,
	lastVisibleColumnIndex,
}: Props): (event: React.KeyboardEvent<Element>) => void {
	return (event: React.KeyboardEvent<Element>) => {
		switch (event.key) {
			case 'ArrowLeft':
			case 'h': {
				if (event.altKey) {
					break;
				}

				if (event.ctrlKey || event.metaKey) {
					// macOS shortcut: hide window
					if (event.key === 'h') {
						break;
					}
				}

				navigateLeftWithKeyboard({ event, scrollToColumnIndex });

				break;
			}
			case 'ArrowRight':
			case 'l': {
				if (event.altKey) {
					break;
				}
				if (event.ctrlKey || event.metaKey) {
					// Browser shortcut: focus URL bar
					if (event.key === 'l') {
						break;
					}
				}

				navigateRightWithKeyboard({
					event,
					scrollToColumnIndex,
					lastVisibleColumnIndex,
				});

				break;
			}
			case 'ArrowUp':
			case 'k': {
				if (event.ctrlKey || event.metaKey) {
					// App shortcut: open command bar
					if (event.key === 'k') {
						break;
					}
				}

				navigateUpWithKeyboard({
					event,
					scrollToRowIndex,
				});

				break;
			}
			case 'ArrowDown':
			case 'j': {
				if (event.ctrlKey || event.metaKey) {
					// Browser shortcut: open downloads
					if (event.key === 'j') {
						break;
					}
				}

				navigateDownWithKeyboard({
					event,
					lastRowIndex,
					scrollToRowIndex,
				});

				break;
			}
			case 'c': {
				if (!navigator.clipboard) {
					break;
				}

				if (typeof value === 'string') {
					if (event.ctrlKey || event.metaKey) {
						if (window.getSelection()?.toString()?.length) {
							break;
						}

						navigator.clipboard.writeText(value);
					}
				}
				break;
			}
			default: {
				break;
			}
		}
	};
}
