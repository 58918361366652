import { createAction } from '@reduxjs/toolkit';

import { ObjectRecordId } from 'typings/serverTypes';

export const logout = createAction('account/logout');

export const deleteRecords = createAction<{
	databaseId: number;
	schemaName: string;
	tableName: string;
	recordIds: ObjectRecordId[];
}>('deleteRecords');
