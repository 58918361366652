export const getHumanizedOperator = (
	operator: string,
	attributeType: string
): string => {
	const translations: { [key: string]: { [key: string]: string } } = {
		NUMBER: {
			'!=': '≠',
			'<=': '≤',
			'>=': '≥',
		},
		DEFAULT: {
			'=': 'is',
			'!=': 'is not',
			'<': 'is before',
			'>': 'is after',
			'<=': 'is on or before',
			'>=': 'is on or after',
		},
	};

	if (attributeType === 'NUMBER') {
		return translations.NUMBER[operator] ?? operator;
	}
	// This will handle all other cases not explicilty covered
	return translations.DEFAULT[operator] ?? operator;
};
