import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
	KeyboardShortcut,
	StyledKeyboardShortcut,
} from 'components/KeyboardShortcut';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as SearchIcon } from 'images/icons/search.svg';
import { background, neutral, primary } from 'utils/colors';
import styles, { media } from 'utils/styles';

const Form = styled.form`
	position: relative;
	width: 100%;
	max-width: 18rem;
	cursor: text;
`;

const IconContainer = styled.div<{ active: boolean }>`
	display: flex;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0.625rem;
	pointer-events: none;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: ${({ active }) => (active ? primary[1] : neutral[1])};
		}
	}
`;

const Field = styled.input`
	width: 100%;
	padding: 0.5rem 2.25rem;
	background: ${background[1]};
	border: none;
	border-radius: ${styles.global.borderRadius};
	box-shadow: 0 0 0 1px ${neutral[4]};
	color: ${neutral[1]};
	text-overflow: ellipsis;

	&:focus {
		box-shadow: 0 0 0 2px ${primary[1]};
		outline: none;
	}

	${media.tabletDown`
		padding-right: 0.5rem;
	`};
`;

const ClearQueryButton = styled.button`
	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	background: none;
	border: none;
	cursor: pointer;
	pointer-events: auto;

	svg {
		width: 15px;
		height: 15px;

		* {
			fill: ${primary[3]};
		}
	}
`;

const KeyContainer = styled.div<{ visibleOnFocus: boolean; hidden: boolean }>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0.625rem;
	align-items: center;
	display: ${({ visibleOnFocus }) => (visibleOnFocus ? 'none' : 'flex')};
	pointer-events: none;

	${({ hidden }) =>
		hidden &&
		css`
			${Field}:not(:focus) ~ && ${StyledKeyboardShortcut} {
				display: none;
			}
		`}

	${Field}:focus ~ && {
		display: ${({ visibleOnFocus }) => (visibleOnFocus ? 'flex' : 'none')};
	}

	${media.tabletDown`
		display: none !important;
	`};
`;

interface Props {
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	setPageNumber: (page: number) => void;
}

export default function SearchBar({
	searchQuery,
	setSearchQuery,
	setPageNumber,
}: Props) {
	const [searchQueryValue, setSearchQueryValue] = useState(searchQuery);
	const fieldRef = useRef<HTMLInputElement>(null);
	const clearButtonRef = useRef(null);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	const isMac = useMemo(() => {
		return navigator.platform.includes('Mac');
	}, []);

	const handleKeyDown = (event: KeyboardEvent) => {
		const ctrl = isMac ? event.metaKey : event.ctrlKey;

		switch (event.key) {
			case 'f': {
				if (ctrl && !event.shiftKey) {
					event.preventDefault();
					if (fieldRef.current) {
						fieldRef.current.focus();
						fieldRef.current.select();
					}
				}
				break;
			}
			case 'Enter': {
				if (fieldRef.current !== document.activeElement) {
					break;
				}

				event.preventDefault();
				handleSubmit(null);
				break;
			}
			case 'Escape': {
				if (fieldRef.current) {
					fieldRef.current.blur();
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	const handleBlur = () => {
		setSearchQueryValue(searchQuery);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQueryValue(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement> | null) => {
		if (event) {
			event.preventDefault();
		}

		if (fieldRef.current) {
			fieldRef.current.blur();
		}

		setPageNumber(0);
		setSearchQuery(searchQueryValue);
		setSearchQueryValue(searchQueryValue);
	};

	const clearSearchQuery = () => {
		setPageNumber(0);
		setSearchQueryValue('');
		setSearchQuery('');
	};

	return (
		<Form>
			<IconContainer active={searchQuery !== ''}>
				<SearchIcon />
			</IconContainer>

			<Field
				name="search"
				type="text"
				placeholder="Search"
				value={searchQueryValue}
				onChange={handleChange}
				onBlur={handleBlur}
				autoComplete="off"
				ref={fieldRef}
			/>

			<KeyContainer visibleOnFocus={false} hidden={searchQueryValue !== ''}>
				<KeyboardShortcut keys={['Ctrl', 'F']} />
				{searchQuery && (
					<ClearQueryButton onClick={clearSearchQuery} ref={clearButtonRef}>
						<CloseIcon />
					</ClearQueryButton>
				)}
			</KeyContainer>

			<KeyContainer visibleOnFocus={true} hidden={searchQueryValue !== ''}>
				<KeyboardShortcut keys={['Enter']} />
			</KeyContainer>
		</Form>
	);
}
