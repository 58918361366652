import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { neutral } from 'utils/colors';
import styles, { media } from 'utils/styles';

import { ChipRoot } from './Chip';
import { InfoContainer, InfoText } from './Page';
import { Input, InputRoot } from './fields/Input';

const ListDirectoryRoot = styled.div`
	padding-bottom: ${styles.global.overscrollHeight};
	flex-grow: 1;
`;

const SearchBar = styled(Input)`
	padding: 1rem;

	${InputRoot} {
		font-size: 1rem;
	}
`;

const Section = styled.section`
	:not(:last-child) {
		padding-bottom: 2.5rem;
	}
`;

const SectionTitle = styled.h4`
	display: flex;
	padding: 0.75rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	color: ${neutral[1]};
	background: ${neutral[5]};
	cursor: default;
`;

const SectionCount = styled.span`
	margin-left: 0.5rem;
	color: ${neutral[3]};
`;

const List = styled.ol`
	list-style-type: none;
`;

export const ListItem = styled(Link)`
	display: flex;
	height: 2.5rem;
	padding: 0 0.75rem;
	align-items: center;
	color: ${neutral[1]};

	&:focus {
		outline: none;
		border: 1px solid ${neutral[1]};
		padding: 0 calc(0.75rem - 1px);
	}

	&:hover {
		background: ${neutral[5]};
		color: ${neutral[1]};
	}
`;

export const Icon = styled.div`
	width: 15px;
	height: 15px;
	margin-right: 1rem;
	display: flex;
	align-items: center;

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${neutral[1]};
	}
`;

export const Title = styled.span`
	flex-grow: 1;
`;

export const DateText = styled.span`
	margin-right: 0.5rem;
	font-size: 0.75rem;
	color: ${neutral[2]};

	${media.mobile`
		display: none;
	`}
`;

export const ChipContainer = styled.div`
	display: flex;

	> ${ChipRoot}:not(:first-of-type) {
		margin-left: 0.5rem;
	}

	// Only show the first chip
	${media.mobile`
		*:not(:first-child) {
			display: none;
		}
	`}
`;

export interface Section {
	title: string;
	icon: React.ReactNode;
	items: Item[];
}

export interface Item {
	id: number;
	to: string;
	title: string;
}

interface ListDirectoryProps {
	sections: Section[];
	renderItem: (itemId: number) => React.ReactNode;
	renderContextMenu?: (
		itemId: number,
		children: React.ReactNode
	) => React.ReactNode;
	itemName: string;
}

export function ListDirectory({
	sections,
	renderItem,
	renderContextMenu,
	itemName,
}: ListDirectoryProps) {
	const itemRefs = useRef<HTMLAnchorElement[]>([]);
	const searchBarRef = useRef<HTMLInputElement>(null);

	const [searchQuery, setSearchQuery] = React.useState('');

	function getFocusedItemIndex() {
		const index = itemRefs.current.findIndex(
			(item) => item === document.activeElement
		);

		if (index === -1) {
			return null;
		}

		return index;
	}

	function handleKeyDown(event: KeyboardEvent) {
		if (window.CommandBar?.isOpen()) {
			return;
		}

		switch (event.key) {
			case 'Escape': {
				event.preventDefault();
				if (
					searchBarRef.current &&
					searchBarRef.current === document.activeElement
				) {
					searchBarRef.current.blur();
				}
				break;
			}
			case '/': {
				event.preventDefault();
				if (searchBarRef.current) {
					searchBarRef.current.focus();
				}
				break;
			}
			case 'ArrowUp':
			case 'k': {
				if (
					event.key === 'k' &&
					searchBarRef.current === document.activeElement
				) {
					break;
				}

				if (event.metaKey) {
					if (event.key === 'ArrowUp') {
						event.preventDefault();
						itemRefs.current[0].focus(); // Jump to top
					}
					break;
				}

				event.preventDefault();
				const focusedItemIndex = getFocusedItemIndex();
				if (focusedItemIndex === null) {
					itemRefs.current[0].focus();
				} else if (focusedItemIndex > 0) {
					itemRefs.current[focusedItemIndex - 1].focus();
				}
				break;
			}
			case 'ArrowDown':
			case 'j': {
				if (
					event.key === 'j' &&
					searchBarRef.current === document.activeElement
				) {
					break;
				}

				if (event.metaKey) {
					if (event.key === 'ArrowDown') {
						event.preventDefault();
						itemRefs.current[itemRefs.current.length - 1].focus(); // Jump to bottom
					}
					break;
				}

				event.preventDefault();
				const focusedItemIndex = getFocusedItemIndex();
				if (focusedItemIndex === null) {
					itemRefs.current[0].focus();
				} else if (focusedItemIndex < itemRefs.current.length - 1) {
					itemRefs.current[focusedItemIndex + 1].focus();
				}
				break;
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	function getRefIndex(sectionIndex: number, itemIndex: number) {
		let index = itemIndex;

		for (let i = 0; i < sectionIndex; i++) {
			index += sections[i].items.filter(searchQueryFilter).length;
		}

		return index;
	}

	const searchQueryFilter = (item: Item) =>
		item.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase());

	return (
		<ListDirectoryRoot>
			<SearchBar
				placeholder={`Search for a ${itemName}`}
				value={searchQuery}
				onChange={(event) => setSearchQuery(event.target.value)}
				ref={searchBarRef}
			/>

			{searchQuery == '' ? (
				<InfoContainer>
					<InfoText>
						Select a {itemName} from the sidebar or press '/' to search.
					</InfoText>
				</InfoContainer>
			) : (
				sections
					.filter(
						(section) => section.items.filter(searchQueryFilter).length > 0
					)
					.map((section, sectionIndex) => (
						<Section key={section.title}>
							<SectionTitle>
								<Icon>{section.icon}</Icon>
								{section.title}
								<SectionCount>
									{section.items.filter(searchQueryFilter).length}
								</SectionCount>
							</SectionTitle>
							<List>
								{section.items
									.filter(searchQueryFilter)
									.map((item, itemIndex) => {
										function renderListItem() {
											return (
												<ListItem
													key={item.id}
													to={item.to}
													ref={(element) =>
														element &&
														(itemRefs.current[
															getRefIndex(sectionIndex, itemIndex)
														] = element)
													}
												>
													{renderItem(item.id)}
												</ListItem>
											);
										}

										if (renderContextMenu) {
											return renderContextMenu(item.id, renderListItem());
										}

										return renderListItem();
									})}
							</List>
						</Section>
					))
			)}
		</ListDirectoryRoot>
	);
}
