export function verifyTwoFactorAuthentication(
	email: string,
	verificationCode: string
) {
	return fetch('/auth/verify-two-factor-authentication', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email,
			verificationCode,
		}),
	});
}
