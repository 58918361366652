import { getDefaultHeaders } from 'api/utils';
import { AdvancedViewAttribute, ApiView } from 'typings/serverTypes';

export type UpdateViewParams = {
	view:
		| (Pick<ApiView, 'id'> &
				Partial<ApiView> & { advancedViewAttributes?: AdvancedViewAttribute[] })
		| null;
};

export function updateView({ view }: UpdateViewParams) {
	if (!view) {
		return;
	}

	return fetch(`/api/views/${view.id}`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify(view),
	});
}
