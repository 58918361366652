import { getDefaultHeaders } from 'api/utils';

export function changeName(name: string) {
	return fetch('/api/user/name', {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			name,
		}),
	});
}
