import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { applyCreditToWorkspace } from 'reduxState/legacyWorkspaceThunksAndActions';

const Title = styled.h4`
	font-size: 1.25rem;
	font-weight: 500;
	margin-top: 3rem;
	margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
	margin-bottom: 1.5rem;
`;

interface Props {
	workspaceId: number;
	userCredit: number;
}

export default function ApplyCreditForm({ workspaceId, userCredit }: Props) {
	const dispatch = useDispatch();

	const [amount, setAmount] = useState(userCredit || 0);
	const [loading, setLoading] = useState(false);

	const invalidAmount = amount > userCredit || amount <= 0;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		setAmount(Number(value));
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (loading || invalidAmount) {
			return;
		}
		setLoading(true);

		await dispatch(applyCreditToWorkspace(workspaceId, amount));

		setAmount(0);
		setLoading(false);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Title>Apply personal credit to workspace</Title>
			<InfoText>You have ${userCredit} in personal credit.</InfoText>
			<Input
				name="amount"
				type="number"
				step={0.01}
				label="Amount of credit to apply"
				value={amount}
				onChange={handleChange}
			/>
			<FormButton loading={loading} disabled={invalidAmount}>
				Apply credit
			</FormButton>
		</Form>
	);
}
