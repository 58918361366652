import { truncatedText } from 'utils/truncatedText';

export const truncateValue = ({
	value,
	length,
}: {
	value: boolean | string | null;
	length?: number;
}) => {
	if (typeof value === 'string') {
		return truncatedText({ value, length });
	}
	return value;
};
