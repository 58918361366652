import { getDefaultHeaders } from 'api/utils';

export function changeWorkspacePlan(workspaceId: number, plan: string) {
	return fetch(`/api/workspaces/${workspaceId}/plan`, {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			plan,
		}),
	});
}
