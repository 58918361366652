import { QueryClient, useMutation, UseMutationOptions } from 'react-query';

import { deleteRecords, DeleteRecordsParams } from 'api/deleteRecords';
import { AffectedRecordsLink } from 'components/AffectedRecordsLink';
import { DeletionApiError } from 'typings/serverTypes';
import { TableData__Table, TableData__View } from 'typings/types';
import { canAccessRawTables } from 'utils/permissions';
import { toast } from 'utils/toast/toast';

export const useDeleteApiRecords = ({
	options,
	onMutate,
	dataSourceId,
	onSuccess,
}: {
	options?: UseMutationOptions<
		void,
		DeletionApiError,
		DeleteRecordsParams,
		unknown
	>;
	queryClient: QueryClient;
	data: TableData__View | TableData__Table;
	dataSourceId: number;
	pageNumber: number;
	searchQuery: string;
	onMutate?: () => void;
	onSuccess?: () => void;
}) =>
	useMutation<void, DeletionApiError, DeleteRecordsParams, unknown>(
		async (variables) => {
			const result = await deleteRecords(variables);

			if (!result.ok) {
				throw await result.json();
			}
			return;
		},
		{
			onMutate: () => {
				if (onMutate) {
					onMutate();
				}
			},
			onSuccess: (_data, { recordIds, tableId }) => {
				window.analytics.track('Records Deleted', {
					tableId,
					cellIds: recordIds,
				});
				toast.update(
					`${recordIds.length} ${
						recordIds.length === 1 ? 'record' : 'records'
					} deleted.`
				);
				if (onSuccess) {
					onSuccess();
				}
			},
			onError: (error, { recordIds, collaborator }) => {
				if (error.title === 'NotNullConstraint' && 'tableDetails' in error) {
					toast.error((t) => (
						<div>
							<p>{error.detail}</p>
							{/* Provide a link to view affected records if the user can access raw tables and there is no more than
							    one problematic record ID. Things get complicated if we needed to link to difference tables and/or
							    the same table with OR filters. */}
							{canAccessRawTables(collaborator) && recordIds.length === 1 && (
								<AffectedRecordsLink
									to={`/data-sources/${dataSourceId}/tables/${
										error.tableDetails.schemaName
									}/${error.tableDetails.tableName}?${recordIds
										.map(
											(recordId) =>
												`${error.tableDetails.columnName}=${
													Object.values(recordId.pk)[0]
												}`
										)
										.join('&')}`}
									onClick={() => toast.dismiss(t.id)}
								>
									View affected records
								</AffectedRecordsLink>
							)}
						</div>
					));
				} else {
					toast.error(error.title);
				}
			},

			...options,
		}
	);
