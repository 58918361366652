import styled, { css } from 'styled-components';

import { media } from 'utils/styles';

export const Form = styled.form<{ noMargin?: boolean }>`
	max-width: 24rem;

	${(props) =>
		!props.noMargin &&
		css`
			${media.desktop`
				margin: 2rem auto;
			`};

			${media.tabletDown`
				margin: 1rem auto;
			`};
		`};
`;
