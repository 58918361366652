import { useMutation, UseMutationOptions } from 'react-query';

import {
	updateTableSettings,
	UpdateTableSettingsParams,
	UpdateTableSettingsResponse,
} from 'api/updateTableSettings';

export const useUpdateApiTableSettings = (
	options?: UseMutationOptions<
		UpdateTableSettingsResponse,
		string,
		UpdateTableSettingsParams,
		unknown
	>
) =>
	useMutation<
		UpdateTableSettingsResponse,
		string,
		UpdateTableSettingsParams,
		unknown
	>(async (variables) => {
		const result = await updateTableSettings(variables);

		if (!result.ok) {
			throw 'Unable to update table settings';
		}
		return await result.json();
	}, options);
