import React from 'react';
import { useVirtual } from 'react-virtual';

import { ApiForeignKey } from 'typings/serverTypes';
import { CELL_VIEW_TYPE, PREVENT_SAVE_DATA_ATTRIBUTE } from 'utils/constants';
import {
	navigateDownWithKeyboard,
	navigateLeftWithKeyboard,
	navigateRightWithKeyboard,
	navigateUpWithKeyboard,
} from 'utils/tableKeyboardNavigation';

type GetEditableTableCellKeyboardShortcutsProps = {
	isEditing: boolean;
	canEdit: boolean;
	cellRef: React.RefObject<HTMLDivElement>;
	cellViewType: CELL_VIEW_TYPE;
	foreignKey: ApiForeignKey | undefined;
	startEditing: () => void;
	toggleCheckbox: () => void;
	isDropdownOpen: boolean;
	setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
	stopEditing: () => void;
	value: string | boolean | null | Date;
	setValue?: ({
		valueAfter,
		formattedValueAfter,
	}: {
		valueAfter: boolean | string | null;
		formattedValueAfter?: string | boolean | null;
	}) => void;
	setNewRecordValue?: (value: string | null) => void;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
	lastRowIndex?: number;
	lastVisibleColumnIndex?: number;
};

export function getEditableTableCellKeyboardShortcuts({
	isEditing,
	canEdit,
	setValue,
	cellRef,
	cellViewType,
	foreignKey,
	startEditing,
	toggleCheckbox,
	isDropdownOpen,
	setIsDropdownOpen,
	stopEditing,
	value,
	setNewRecordValue,
	scrollToColumnIndex,
	scrollToRowIndex,
	lastRowIndex,
	lastVisibleColumnIndex,
}: GetEditableTableCellKeyboardShortcutsProps): (
	event: React.KeyboardEvent<Element>
) => void {
	return (event: React.KeyboardEvent<Element>) => {
		switch (event.key) {
			case 'Backspace': {
				if (!canEdit) {
					return;
				}
				if (event.ctrlKey || event.metaKey) {
					event.preventDefault();
					if (canEdit && !isEditing) {
						if (setNewRecordValue) {
							setNewRecordValue(null);
						}
						if (setValue) {
							setValue({ valueAfter: null });
						}
					}
				}
				break;
			}
			case 'F2': {
				if (!canEdit) {
					break;
				}

				if (
					cellViewType === CELL_VIEW_TYPE.DATE ||
					cellViewType === CELL_VIEW_TYPE.ENUM ||
					foreignKey
				) {
					break;
				}

				if (!isEditing) {
					startEditing();
				}

				break;
			}
			case ' ': {
				if (!canEdit) {
					break;
				}
				if (cellViewType === CELL_VIEW_TYPE.ENUM && !isEditing) {
					event.currentTarget.querySelector('select')?.focus();
				}
				break;
			}
			case 'Enter': {
				if (!canEdit) {
					break;
				}

				if (cellViewType === CELL_VIEW_TYPE.DATE) {
					event.currentTarget.querySelector('input')?.focus();
					break;
				}

				if (cellViewType === CELL_VIEW_TYPE.CHECKBOX) {
					toggleCheckbox();
					break;
				}

				if (cellViewType === CELL_VIEW_TYPE.ENUM) {
					break;
				}

				if (
					cellViewType === CELL_VIEW_TYPE.RICH_TEXT ||
					cellViewType === CELL_VIEW_TYPE.JSON
				) {
					// Ctrl/Cmd for new paragraph, Shift for new line
					if (event.ctrlKey || event.metaKey || event.shiftKey) {
						event.stopPropagation(); // Prevent "New record" shortcut conflict
						break;
					}
				}

				if (foreignKey) {
					setIsDropdownOpen(true);
					break;
				}

				if (isEditing) {
					if (cellRef.current) {
						// Focusing on the table cell will trigger the onBlur event which will save the value
						cellRef.current.focus();
					}
					stopEditing();
				} else {
					startEditing();
				}

				break;
			}
			case 'Escape': {
				if (isEditing) {
					stopEditing();
				}
				if (cellRef.current) {
					// Setting a data-attribute to prevent saving the value on blur. The attribute
					// is removed during the on blur handler
					cellRef.current.setAttribute(PREVENT_SAVE_DATA_ATTRIBUTE, 'true');
					cellRef.current.focus();
				}
				break;
			}
			case 'Tab':
				if (isDropdownOpen) {
					break;
				}
				if (event.shiftKey) {
					navigateLeftWithKeyboard({
						event: event,
						scrollToColumnIndex,
					});
				} else {
					navigateRightWithKeyboard({
						event,
						scrollToColumnIndex,
						lastVisibleColumnIndex,
					});
				}
				break;
			case 'ArrowLeft':
			case 'h': {
				if (isEditing || isDropdownOpen) {
					break;
				}

				if (event.altKey) {
					break;
				}

				if (event.ctrlKey || event.metaKey) {
					// macOS shortcut: hide window
					if (event.key === 'h') {
						break;
					}
				}

				navigateLeftWithKeyboard({
					event: event,
					scrollToColumnIndex,
				});

				break;
			}
			case 'ArrowRight':
			case 'l': {
				if (isEditing || isDropdownOpen) {
					break;
				}

				if (event.altKey) {
					break;
				}
				if (event.ctrlKey || event.metaKey) {
					// Browser shortcut: focus URL bar
					if (event.key === 'l') {
						break;
					}
				}

				navigateRightWithKeyboard({
					event,
					scrollToColumnIndex,
					lastVisibleColumnIndex,
				});

				break;
			}
			case 'ArrowUp':
			case 'k': {
				if (isEditing || isDropdownOpen) {
					break;
				}
				if (event.ctrlKey || event.metaKey) {
					// App shortcut: open command bar
					if (event.key === 'k') {
						break;
					}
				}

				navigateUpWithKeyboard({
					event,
					scrollToRowIndex,
				});

				break;
			}
			case 'ArrowDown':
			case 'j': {
				if (isEditing || isDropdownOpen) {
					break;
				}
				if (event.ctrlKey || event.metaKey) {
					// Browser shortcut: open downloads
					if (event.key === 'j') {
						break;
					}
				}

				navigateDownWithKeyboard({
					event,
					lastRowIndex,
					scrollToRowIndex,
				});

				break;
			}
			case 'c': {
				if (!navigator.clipboard) {
					break;
				}

				if (!isEditing && typeof value === 'string') {
					if (event.ctrlKey || event.metaKey) {
						if (window.getSelection()?.toString()?.length) {
							break;
						}

						navigator.clipboard.writeText(value);
					}
				}
				break;
			}
			case 'v': {
				if (!navigator.clipboard) {
					break;
				}

				if (!isEditing) {
					if (event.ctrlKey || event.metaKey) {
						navigator.clipboard.readText().then((value) => {
							if (setNewRecordValue) {
								setNewRecordValue(value);
							}
							if (setValue) {
								setValue({ valueAfter: value });
							}
						});
					}
				}
				break;
			}
			default: {
				break;
			}
		}
	};
}
