import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { init as initCommandbar } from 'commandbar';
import { H as Highlight } from 'highlight.run';

import { initIntercom } from 'bootstrap/initIntercom';
import { renderApp } from 'bootstrap/renderApp';
import { AppConfig } from 'typings/serverTypes';
import { state } from 'valtioState';

const app = async () => {
	const response = await fetch('/api/config');
	const data: AppConfig = await response.json();

	state.config = data;

	if (data.segmentApiKey) {
		window.analytics.load(data.segmentApiKey);
	}

	if (data.intercomAppId) {
		initIntercom(data);
		// Anytime the user hides the intercom chat, we should also hide the
		// launcher. This way the launcher is only viewed when the user
		// clicks on the "chat with us" button.
		window.Intercom('onHide', function () {
			Intercom('update', {
				hide_default_launcher: true,
			});
		});
	}

	initCommandbar('738f0323');

	Sentry.init({
		dsn: data.sentryDsn ?? undefined,
		enabled: data.sentryDsn !== null,
		environment: data.environment,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.2,
		release: data.releaseVersion,
	});

	if (!data.isSelfHosted) {
		Highlight.init('j3ejlwdp', {
			environment: data.environment,
		});
	}

	await renderApp(data);
};

app();
