import { getDefaultHeaders } from 'api/utils';
import { ApiWorkspace } from 'typings/serverTypes';

export type FetchWorkspacesResponse = ApiWorkspace[];

export function fetchWorkspaces() {
	return fetch('/api/workspaces', {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
