import { getDefaultHeaders } from 'api/utils';

type GetAndSyncDataSourceSchemaParams = {
	dataSourceId: number;
};

export function getAndSyncDataSourceSchema({
	dataSourceId,
}: GetAndSyncDataSourceSchemaParams) {
	return fetch(`/api/data-sources/${dataSourceId}/schema`, {
		headers: getDefaultHeaders(),
	});
}
