import styled from 'styled-components';

import { neutral } from 'utils/colors';
import styles from 'utils/styles';

export const ActionButton = styled.button`
	border: none;
	background: none;
	font-size: 0;
	display: inline-flex;
	align-items: center;
	padding: 0.375rem;
	cursor: pointer;
	border-radius: ${styles.global.borderRadius};

	&:hover {
		background: ${neutral[5]};
	}
`;
