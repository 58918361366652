import { Elements } from '@stripe/react-stripe-js';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { devtools } from 'valtio/utils';

import App from 'components/App';
import { FeatureFlagProvider } from 'components/providers/FeatureFlagProvider';
import { SocketProvider } from 'components/providers/SocketProvider';
import { ThemeProvider } from 'components/providers/ThemeProvider';
import { TokenProvider } from 'components/providers/TokenProvider';
import { ToastContainer } from 'components/toast/ToastContainer';
import { getStore } from 'reduxState/store';
import { AppConfig } from 'typings/serverTypes';
import GlobalStyle from 'utils/GlobalStyle';
import { state } from 'valtioState';

export const renderApp = async (config: AppConfig) => {
	if (process.env.NODE_ENV === 'development') {
		devtools(state, 'valtio');
	}

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				// Inconvenient to have spinners show up every time we refocus on the window
				refetchOnWindowFocus: false,
				// Not retrying by default since it delays how long it takes before an error toast shows up
				retry: false,
			},
		},
	});
	let tree = (
		<Provider store={getStore(config)}>
			<QueryClientProvider client={queryClient}>
				<TokenProvider>
					<FeatureFlagProvider>
						<Router>
							<QueryParamProvider ReactRouterRoute={Route}>
								<SocketProvider>
									<ThemeProvider>
										<GlobalStyle />
										<ToastContainer />
										<App />
									</ThemeProvider>
								</SocketProvider>
							</QueryParamProvider>
						</Router>
						<ReactQueryDevtools initialIsOpen={false} />
					</FeatureFlagProvider>
				</TokenProvider>
			</QueryClientProvider>
		</Provider>
	);
	// We only want to load stripe if the app is not self-hosted
	if (!config.isSelfHosted) {
		const stripe = await import('utils/stripe');
		tree = <Elements stripe={stripe.stripePromise}>{tree}</Elements>;
	}
	ReactDOM.render(tree, document.getElementById('root'));
};
