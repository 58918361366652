import { createAsyncThunk } from '@reduxjs/toolkit';

import { updateAvatar as updateAvatarApi } from 'api/updateAvatar';

export const updateUserAvatar = createAsyncThunk(
	'account/updateAvatar',
	async (
		{ userId, avatar }: { userId: number; avatar: File | null },
		thunkAPI
	) => {
		const response = await updateAvatarApi(avatar);
		if (!response.ok) {
			const error = await response.json();
			return thunkAPI.rejectWithValue(error);
		}
		const avatarUrl = await response.json();
		return { userId, avatarUrl };
	}
);
