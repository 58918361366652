import styled from 'styled-components';

import { useTheme } from 'components/providers/ThemeProvider';
import logoDark from 'images/logos/logo-wordmark-dark.svg';
import logoLight from 'images/logos/logo-wordmark-light.svg';

const LogoLinkComponent = styled.a`
	margin-bottom: 3rem;

	&:hover {
		opacity: 0.6;
	}
`;

const Logo = styled.img`
	height: 2.5rem;
`;

export default function LogoLink() {
	const { themeMode } = useTheme();

	return (
		<LogoLinkComponent href="https://www.basedash.com">
			<Logo src={themeMode === 'dark' ? logoDark : logoLight} />
		</LogoLinkComponent>
	);
}
