import React from 'react';
import { useVirtual } from 'react-virtual';

import { EditableTableCell } from 'components/TableCell/EditableTableCell';
import { ReadOnlyTableCell } from 'components/TableCell/ReadOnlyTableCell';
import { PrimaryAttribute, User } from 'typings/models';
import {
	AdvancedViewAttribute,
	ApiRecordAttribute,
	CellRecord,
} from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import { isApiRecordAttribute } from 'utils/isApiRecordAttribute';

export type TableCellProps = {
	editable: boolean;
	record: CellRecord;
	isFirstCell?: boolean;
	isPrimaryAttribute?: boolean;
	pageNumber: number;
	searchQuery: string;
	rowNumber: number;
	usersById: { [userId: string]: User };
	routeLocationSearch: string;
	rootTablePrimaryAttribute: PrimaryAttribute | undefined;
	extractedTableData: ExtractedTableData;
	dataRow?: number;
	dataColumn?: number;
	scrollToColumnIndex:
		| ReturnType<typeof useVirtual>['scrollToOffset']
		| undefined;
	scrollToRowIndex: ReturnType<typeof useVirtual>['scrollToOffset'] | undefined;
	lastRowIndex: number;
	lastVisibleColumnIndex: number;
} & (
	| {
			column: ApiRecordAttribute;
			data: TableData<ViewType.BASIC | void>;
	  }
	| {
			column: AdvancedViewAttribute;
			data: TableData<ViewType.ADVANCED>;
	  }
);

const TableCell = React.memo(({ column, data, ...props }: TableCellProps) => {
	if (isApiRecordAttribute(column) && !isAdvancedViewData(data)) {
		return <EditableTableCell {...props} column={column} data={data} />;
	}
	if (!isApiRecordAttribute(column) && isAdvancedViewData(data)) {
		return <ReadOnlyTableCell {...props} column={column} data={data} />;
	}
	return null;
});

TableCell.displayName = 'TableCell';

export default TableCell;
