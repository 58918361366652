import '@fontsource/inter/variable-full.css';
import '@fontsource/jetbrains-mono/variable.css';

import { createGlobalStyle } from 'styled-components';

import { background, bodyBackground } from 'utils/colors';
import { ONE_REM_IN_PIXELS } from 'utils/constants';
import styles from 'utils/styles';

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	html {
		background: ${background[1]};
		font-size: ${ONE_REM_IN_PIXELS}px;
	}

	body {
		font-family: ${styles.text.fontFamilyText};
        line-height: 1rem;
		color: ${styles.text.colourPrimary};
		background: ${bodyBackground};
	}

	input,
	select,
	textarea,
	button {
		font-family: inherit;
		font-size: inherit;
	}

	button, a {
		&:focus:not(:focus-visible) {
			outline: none;
		}
	}

	p {
    	margin-bottom: 0;
    }

	a {
		text-decoration: none;
		line-height: normal;

        &:hover {
          text-decoration: none;
          color: inherit;
        }
	}

	.sortableHelper {
		z-index: 30;
	}

	// The input field on the CommandBar
	#commandbar__control {
		padding: 1.25rem !important;
	}
`;

export default GlobalStyle;
