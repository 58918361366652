import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarItem,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Button from 'components/Button';
import { ConnectDataSourceCta } from 'components/ConnectDataSourceCta';
import { Icon, ListDirectory, Section, Title } from 'components/ListDirectory';
import Page from 'components/Page';
import { ReactComponent as DatabaseIcon } from 'images/icons/database.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import {
	filterSchemasByDataSource,
	filterTablesByMultipleSchemas,
} from 'utils/filterSchemasAndTables';
import { nonNullable } from 'utils/nonNullable';
import { canManageDataSources } from 'utils/permissions';
import { useCollaborator } from 'utils/useCollaborator';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { state, ValtioState } from 'valtioState';

type DefinedDataSource = Exclude<
	ValtioState['entities']['dataSources']['byId'][number],
	undefined
>;

export function DataPage() {
	const snap = useSnapshot(state);
	const collaborator = useCollaborator();

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const dataSources = Object.values(snap.entities.dataSources.byId)
		.filter(
			(dataSource): dataSource is DefinedDataSource => dataSource !== undefined
		)
		.filter((dataSource) => dataSource.workspaceId === workspaceId)
		.sort(
			(a, b) =>
				// Explicitly check for false to group healthy and loading data sources
				((a.healthy === false) === (b.healthy === false)
					? 0
					: a.healthy === false
					? 1
					: -1) ||
				(a.connected === b.connected ? 0 : a.connected ? -1 : 1) ||
				a.name.localeCompare(b.name)
		);

	const sections: Section[] = dataSources
		.filter((dataSource) => dataSource.connected && dataSource.healthy)
		.map((dataSource) => {
			const schemas = filterSchemasByDataSource(
				dataSource.id,
				snap.entities.sqlDatabases.byId,
				snap.entities.schemas.byId
			);
			const tables = filterTablesByMultipleSchemas(
				schemas,
				/* @ts-expect-error Readonly issues */
				snap.entities.tables.byId
			);
			const items = tables.filter(nonNullable).map((table) => ({
				id: table.id,
				to: `/data-sources/${dataSource.id}/tables/${table.id}`,
				title: table.tableName,
			}));

			return {
				title: dataSource.name,
				icon: <DatabaseIcon />,
				items: items,
			};
		});

	function renderItem(itemId: number) {
		const table = snap.entities.tables.byId[itemId];
		if (!table) {
			return null;
		}

		return (
			<>
				<Icon>
					<TableIcon />
				</Icon>
				<Title>{table.tableName}</Title>
			</>
		);
	}

	useDocumentTitle('Data');

	return (
		<Page primary>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<DatabaseIcon />} title="Data" />
					</ActionBarSection>

					<ActionBarSection padded>
						<ActionBarItem>
							<Button
								icon={<PlusIcon />}
								value="Connect data source"
								primary
								to="/databases/add"
							/>
						</ActionBarItem>
					</ActionBarSection>
				</ActionBar>

				<Content>
					{workspace &&
					canManageDataSources(collaborator) &&
					workspace.sqlDatabases.length === 0 ? (
						<ConnectDataSourceCta />
					) : (
						<ListDirectory
							sections={sections}
							renderItem={renderItem}
							itemName="table"
						/>
					)}
				</Content>
			</Container>
		</Page>
	);
}
