import React, {
	ChangeEvent,
	FormEvent,
	useEffect,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Button from 'components/Button';
import { DataTypeIcon } from 'components/DataTypeIcon';
import { Popover } from 'components/Popover';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
} from 'components/Table/tableUtils';
import { Input } from 'components/fields/Input';
import Select from 'components/fields/Select';
import { Form as _Form } from 'components/forms/Form';
import { ReactComponent as MinusIcon } from 'images/icons/minus.svg';
import { ApiRecordAttribute } from 'typings/serverTypes';
import { TableData__View, ExtractedTableData, ViewType } from 'typings/types';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { useWorkspace } from 'utils/useWorkspace';
import { state } from 'valtioState';

const Form = styled(_Form)`
	width: 17rem;
`;

const StyledButton = styled(Button)`
	margin-top: 1rem;
	justify-content: center;
`;

const RemoveButton = styled(Button)`
	padding: 0.125rem;
	background: none;
	margin-right: -0.375rem;
	visibility: hidden;
`;

const StyledSidebarItem = styled(SidebarItem)`
	&:hover {
		${RemoveButton} {
			visibility: visible;
		}
	}
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;

	> * {
		margin-top: 1rem;

		&:first-child {
			margin-top: 0rem;
		}
	}
`;

interface AttributePopoverProps {
	tableData: TableData__View<ViewType.BASIC>;
	attribute: ApiRecordAttribute;
	extractedTableData: ExtractedTableData;
}

export function AttributePopover({
	tableData,
	attribute,
	extractedTableData,
}: AttributePopoverProps) {
	const snap = useSnapshot(state);
	const { workspace } = useWorkspace();

	const [open, setOpen] = useState(false);
	const [fields, setFields] = useState<{
		[key in 'viewAs' | 'baseUrl' | 'richTextFormat']: {
			value: string;
			errors: string[] | null;
		};
	}>({
		viewAs: {
			value: '',
			errors: null,
		},
		baseUrl: {
			value: '',
			errors: null,
		},
		richTextFormat: {
			value: 'html',
			errors: null,
		},
	});
	const [loading, setLoading] = useState(false);

	const { attributes, foreignKeys, enums, databaseId } = extractedTableData;
	const attributeType = useMemo(
		() =>
			attribute.typeInDb &&
			getAttributeTypeFromDbColumnType({ dbColumnType: attribute.typeInDb }),
		[attribute]
	);

	const { mutate: updateApiView } = useUpdateApiView({
		view: tableData.view,
	});

	const foreignKey = useMemo(
		() =>
			foreignKeys
				? getForeignKey(attribute.attributeName, foreignKeys)
				: undefined,
		[attribute, foreignKeys]
	);

	useEffect(() => {
		const viewOptionsType = attribute.viewOptions?.type ?? '';
		setFields((fields) => ({
			...fields,
			viewAs: { value: viewOptionsType, errors: null },
		}));
		if (viewOptionsType === 'image') {
			setFields((fields) => ({
				...fields,
				baseUrl: {
					value: attribute.viewOptions?.baseUrl ?? '',
					errors: null,
				},
			}));
		}
		if (viewOptionsType === 'richText') {
			setFields((fields) => ({
				...fields,
				richTextFormat: {
					value: attribute.viewOptions?.richTextFormat ?? '',
					errors: null,
				},
			}));
		}
	}, [attribute]);

	const removeAttribute = (attributeId: number) => {
		const updatedAttributes = attributes.map((attribute) =>
			attribute.id === attributeId
				? { ...attribute, visible: false }
				: attribute
		);
		updateApiView({
			view: {
				id: tableData.view.id,
				attributes: updatedAttributes,
			},
		});
	};

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value, type } = event.target;

		setFields((fields) => ({
			...fields,
			[name]: {
				value:
					type === 'checkbox'
						? (event.target as HTMLInputElement).checked
						: value,
				errors: null,
			},
		}));
	};

	const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
		if (event) {
			event.preventDefault();
		}

		if (loading || !viewOptionsChanged) {
			return;
		}

		setOpen(false);
		setLoading(true);

		try {
			const viewOptionsType = fields.viewAs.value;
			const viewOptions = {
				type: viewOptionsType,
			};
			if (viewOptionsType === 'image') {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'baseUrl' does not exist on type '{ type:... Remove this comment to see the full error message
				viewOptions.baseUrl = fields.baseUrl.value;
			}
			if (viewOptionsType === 'richText') {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'richTextFormat' does not exist on type '{ type: ... Remove this comment to see the full error message
				viewOptions.richTextFormat = fields.richTextFormat.value;
			}

			const updatedColumns = attributes.map((viewColumn) =>
				viewColumn.attributeName === attribute.attributeName
					? {
							...viewColumn,
							viewOptions,
					  }
					: viewColumn
			);

			updateApiView({
				view: { id: tableData.view.id, attributes: updatedColumns },
			});

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const viewAsOptions = useMemo(() => {
		let options: { label: string; value: string }[] = [];
		if (!foreignKey) {
			switch (attributeType) {
				case 'TEXT': {
					options = [
						{ label: 'Text', value: 'text' },
						{ label: 'Rich text', value: 'richText' },
						{ label: 'JSON', value: 'json' },
						{ label: 'Image', value: 'image' },
					];
					break;
				}
				case 'NUMBER': {
					options = [
						{ label: 'Number', value: 'number' },
						{ label: 'Checkbox', value: 'checkbox' },
					];
					break;
				}
			}
		}

		if (options.length) {
			setFields((fields) => ({
				...fields,
				viewAs: {
					value: options[0].value,
					errors: null,
				},
			}));
		}
		return options;
	}, [foreignKey, attributeType]);
	const viewOptionsChanged = useMemo(() => {
		const viewAs = fields.viewAs.value;
		if (!attribute || !attribute.viewOptions) {
			return viewAs !== 'text';
		}

		if (viewAs !== attribute.viewOptions.type) {
			return true;
		}

		if (viewAs === 'image') {
			if (fields.baseUrl.value !== attribute.viewOptions.baseUrl) {
				return true;
			}
		}

		if (viewAs === 'richText') {
			if (
				fields.richTextFormat.value !== attribute.viewOptions.richTextFormat
			) {
				return true;
			}
		}

		return false;
	}, [attribute, fields]);

	const handleHideColumn = () => {
		const updatedColumns = attributes.map((viewColumn) => {
			if (viewColumn.attributeName !== attribute.attributeName) {
				return viewColumn;
			}
			return {
				...viewColumn,
				visible: false,
			};
		});
		updateApiView({
			view: { id: tableData.view.id, attributes: updatedColumns },
		});

		setOpen(false);
	};

	const handleBlur = async () => {
		handleSubmit();
		setOpen(false);
	};

	const attributeDisplayName = getAttributeDisplayName({
		attributesById: snap.entities.attributes.byId,
		attributeNamesToIdMap: snap.attributeNamesToIdMap,
		attributeName: attribute.attributeName,
		tableName: attribute.tableName,
		schemaName: attribute.schemaName,
		sqlDatabaseId: databaseId,
	});

	return (
		<Popover
			trigger={
				<StyledSidebarItem
					iconLeft={
						<DataTypeIcon
							workspace={workspace}
							sqlDatabaseId={databaseId}
							attribute={attribute}
							foreignKeys={foreignKeys}
							enums={enums}
						/>
					}
					buttonRight={
						<RemoveButton
							onClick={removeAttribute.bind(null, attribute.id)}
							title="Remove attribute"
							icon={<MinusIcon />}
						/>
					}
					title={attributeDisplayName}
					clickable
				/>
			}
			side="right"
			align="start"
			onBlur={handleBlur}
			open={open}
			setOpen={setOpen}
		>
			<Contents>
				{viewAsOptions.length > 0 && (
					<Form noMargin onSubmit={handleSubmit}>
						<Select
							name="viewAs"
							label="Display as"
							options={viewAsOptions}
							value={fields.viewAs.value}
							errors={fields.viewAs.errors}
							onChange={handleChange}
							disabled={loading}
							labelWidth={6}
						/>
						{fields.viewAs.value === 'image' && (
							<Input
								name="baseUrl"
								type="text"
								label="Base URL"
								placeholder="e.g. basedash.com/images/"
								value={fields.baseUrl.value}
								errors={fields.baseUrl.errors}
								onChange={handleChange}
								disabled={loading}
								labelWidth={6}
							/>
						)}
						{fields.viewAs.value === 'richText' && (
							<Select
								name="richTextFormat"
								label="Output format"
								options={[
									{ label: 'HTML', value: 'html' },
									{ label: 'Markdown', value: 'markdown' },
								]}
								value={fields.richTextFormat.value}
								errors={fields.richTextFormat.errors}
								onChange={handleChange}
								disabled={loading}
								labelWidth={6}
							/>
						)}
						{viewOptionsChanged && (
							<StyledButton fullWidth primary size={'small'}>
								Save changes
							</StyledButton>
						)}
					</Form>
				)}

				<StyledButton
					onClick={handleHideColumn}
					fullWidth
					outlined
					size={'small'}
				>
					Hide
				</StyledButton>
			</Contents>
		</Popover>
	);
}
