import { getDefaultHeaders } from 'api/utils';
import { ViewType } from 'typings/types';

export type FetchAttributeAffectedViewsResponse = {
	id: number;
	name: string;
	type: keyof typeof ViewType;
	isPublished: boolean;
}[];
type FetchAttributeAffectedViewsParams = {
	attributeId: number;
};

export function fetchAttributeAffectedViews({
	attributeId,
}: FetchAttributeAffectedViewsParams) {
	return fetch(`/api/attributes/${attributeId}/affected-views`, {
		headers: getDefaultHeaders(),
	});
}
