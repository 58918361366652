import { useEffect, useRef } from 'react';

import { getBuildNumber } from 'api/getBuildNumber';
import { NewUpdateAvailable } from 'components/toast/NewUpdateAvailable';
import { toast } from 'utils/toast/toast';

const oneMinuteInMilliseconds = 1000 * 60;

export const useBuildNumber = () => {
	const buildNumber = useRef<string | undefined>();
	const showUpdateToast = useRef<boolean>(false);

	useEffect(() => {
		const fetchBuildNumberAsync = async () => {
			const result = await getBuildNumber();
			if (!result.ok) {
				return;
			}

			const buildNumberResult = (await result.json()) as string;
			if (buildNumber.current === undefined) {
				buildNumber.current = buildNumberResult;
			} else if (buildNumber.current !== buildNumberResult) {
				if (!showUpdateToast.current) {
					toast.persist(<NewUpdateAvailable />);
					showUpdateToast.current = true;
				}
			}
		};

		const intervalId = setInterval(() => {
			fetchBuildNumberAsync();
		}, oneMinuteInMilliseconds);

		return () => clearInterval(intervalId);
	}, []);
};
