import styled, { keyframes } from 'styled-components';

import { background, neutral } from 'utils/colors';
import styles from 'utils/styles';

const slideIn = keyframes`
	from {
		bottom: 6.5rem;
		opacity: 0;
	}

	to {
		bottom: 8rem;
		opacity: 1;
	}
`;

export const ButtonHovering = styled.button`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 100%);
	display: flex;
	align-items: center;
	padding: 1rem;
	background: ${background[1]};
	border: 1px solid ${neutral[4]};
	border-radius: ${styles.global.borderRadius};
	color: ${neutral[1]};
	animation: ${slideIn} 500ms forwards;
	cursor: pointer;

	&:hover::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: ${neutral[5]};
		pointer-events: none;
	}

	& > svg {
		width: 15px;
		height: 15px;
		margin-right: 0.5rem;

		* {
			fill: ${neutral[1]};
		}
	}
`;
