import React from 'react';
import { EditorValue } from 'react-rte';

import { RichTextEditor } from 'components/RichTextEditor';
import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

type RichTextTableCellContentsProps = Pick<
	EditableTableCellContentsProps,
	'rawValue' | 'inputValue' | 'isAddingNewRecord' | 'column' | 'handleChange'
>;

export const RichTextTableCellContents = ({
	rawValue,
	isAddingNewRecord,
	column,
	inputValue,
	handleChange,
}: RichTextTableCellContentsProps): React.ReactElement | null => {
	if (rawValue === null || rawValue === '') {
		return (
			<StyledNullOrEmptyText addDefaultCellPadding>
				{renderNullOrDefaultValue({ isAddingNewRecord, column })}
			</StyledNullOrEmptyText>
		);
	}
	if (inputValue instanceof EditorValue) {
		return (
			<RichTextEditor
				className="fs-mask highlight-block"
				value={inputValue}
				onChange={handleChange}
				readOnly
			/>
		);
	}
	return null;
};
