import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { useApiMemberInvitations } from 'api/reactQueryHooks/useApiMemberInvitations';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarItem,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Button from 'components/Button';
import Card from 'components/Card';
import Page from 'components/Page';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import { MemberList } from 'components/pages/Members/MemberList';
import { MembersModal } from 'components/pages/Members/MembersModal';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as MembersIcon } from 'images/icons/user.svg';
import { ApiUser } from 'typings/serverTypes';
import { canInviteMembers } from 'utils/permissions';
import { useDocumentTitle } from 'utils/useDocumentTitle';

export function MembersPage() {
	const [modalOpen, setModalOpen] = useState(false);

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const { data: memberInvitations } = useApiMemberInvitations(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		workspaceId!,
		{
			enabled: workspaceId !== null,
		}
	);
	const queryClient = useQueryClient();
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const member =
		workspace?.collaborators.find((member) => member.userId === userId) ?? null;

	useDocumentTitle('Members');

	if (!workspace) {
		return null;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<MembersIcon />} title="Members" />
					</ActionBarSection>

					<ActionBarSection padded>
						{canInviteMembers(member) && (
							<ActionBarItem>
								<Button
									icon={<PlusIcon />}
									value="Invite member"
									primary
									onClick={() => setModalOpen(true)}
								/>
							</ActionBarItem>
						)}
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section>
							<Card noPadding>
								<MemberList
									members={workspace?.collaborators ?? []}
									workspaceInvitations={memberInvitations ?? []}
								/>
							</Card>
						</Section>

						<MembersModal
							workspace={workspace}
							workspaceInvitations={memberInvitations ?? []}
							open={modalOpen}
							onOpenChange={setModalOpen}
						/>
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
