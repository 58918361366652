import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { Emoji } from 'emoji-mart';
import produce from 'immer';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { FetchViewParams } from 'api/fetchView';
import { FetchWorkspaceResponse } from 'api/fetchWorkspace';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useDeleteApiView } from 'api/reactQueryHooks/useDeleteApiView';
import { useUpdateApiViewFavorited } from 'api/reactQueryHooks/useUpdateApiViewFavorited';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarItem,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Button from 'components/Button';
import { Chip } from 'components/Chip';
import { ConnectDataSourceCta } from 'components/ConnectDataSourceCta';
import { ContextMenu, ContextMenuItem } from 'components/ContextMenu';
import { DeleteViewAlertDialog } from 'components/DeleteViewAlertDialog';
import {
	ChipContainer,
	DateText,
	Icon,
	Item,
	ListDirectory,
	ListItem,
	Title,
} from 'components/ListDirectory';
import Page, { InfoButton, InfoContainer, InfoText } from 'components/Page';
import { viewSort } from 'components/Sidebar/ViewsSidebar';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as AllViewsIcon } from 'images/icons/allViews.svg';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg';
import { ReactComponent as EditIcon } from 'images/icons/edit.svg';
import { ReactComponent as FavoritedOffIcon } from 'images/icons/favoritedOff.svg';
import { ReactComponent as FavoritedOnIcon } from 'images/icons/favoritedOn.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as PublishIcon } from 'images/icons/publish.svg';
import { ReactComponent as QueryIcon } from 'images/icons/query.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { ApiBasicView, ApiView, WorkspaceApiView } from 'typings/serverTypes';
import { ViewType } from 'typings/types';
import { primary, neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { getTableDisplayNameFromSchemaAndTableName } from 'utils/getTableDisplayNameFromSchemaAndTableName';
import { canManageDataSources, canManageViews } from 'utils/permissions';
import styles, { media } from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { state } from 'valtioState';

const FavoriteButton = styled.button<{ favorited: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${styles.global.borderRadius};
	margin-left: -0.25rem;
	margin-right: 0.5rem;
	padding: 0.25rem;
	cursor: pointer;
	background: transparent;
	border: none;

	${({ favorited }) => media.tabletUp`
		visibility: ${favorited ? 'visible' : 'hidden'};

		${ListItem}:focus &, ${ListItem}:hover & {
			visibility: visible;
		}
	`}

	& > svg,
	& > svg * {
		width: 15px;
		height: 15px;
		fill: ${({ favorited }) => (favorited ? primary.text : neutral[1])};
	}

	&:hover {
		background: ${neutral[4]};
	}
`;

export function ViewsPage() {
	const snap = useSnapshot(state);
	const queryClient = useQueryClient();
	const collaborator = useCollaborator();

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const { mutate: updateApiViewFavorited } = useUpdateApiViewFavorited();

	const views: WorkspaceApiView[] = workspace?.views.sort(viewSort) ?? [];

	const mapViewToItem = (view: WorkspaceApiView): Item => ({
		id: view.id,
		to: `/views/${view.id}`,
		title: view.name,
	});
	const draftItems = views
		.filter((views) => views.isPublished === false)
		.map(mapViewToItem);
	const publishedItems = views
		.filter((views) => views.isPublished === true)
		.map(mapViewToItem);

	function renderContextMenu(itemId: number, children: React.ReactNode) {
		const view = views.find((view) => view.id === itemId);
		if (!view) {
			return null;
		}

		return (
			<ContextMenu
				enabled={canManageViews(collaborator)}
				content={
					<>
						<ContextMenuItem
							icon={<EditIcon />}
							value="Edit"
							as={Link}
							to={`/views/${view.id}/settings`}
						/>
						<ContextMenuItem
							icon={view.favorited ? <FavoritedOffIcon /> : <FavoritedOnIcon />}
							value={view.favorited ? 'Unfavorite' : 'Favorite'}
							onSelect={() => toggleFavorite(null, view)}
						/>
						<ContextMenuItem
							icon={<DeleteIcon />}
							value="Delete"
							onSelect={() => {
								setIsDeleteViewAlertDialogOpen(true);
								setViewToBeDeleted({ id: view.id, name: view.name });
							}}
						/>
					</>
				}
			>
				{children}
			</ContextMenu>
		);
	}

	function renderItem(itemId: number) {
		const view = views.find((view) => view.id === itemId);
		if (!view) {
			return null;
		}

		return (
			<>
				<Tooltip
					value={view.favorited ? 'Remove from favorites' : 'Add to favorites'}
				>
					<FavoriteButton
						tabIndex={-1}
						favorited={view.favorited}
						onClick={(event) => toggleFavorite(event, view)}
					>
						{view.favorited ? <FavoritedOnIcon /> : <FavoritedOffIcon />}
					</FavoriteButton>
				</Tooltip>

				<Icon>
					{view.emoji ? (
						<Emoji emoji={view.emoji} size={15} />
					) : view.type === ViewType.BASIC ? (
						<ViewIcon />
					) : (
						<QueryIcon />
					)}
				</Icon>
				<Title>{view.name}</Title>

				<DateText>
					{formatDistanceToNowStrict(
						parseISO(view.createdAt as unknown as string)
					)}{' '}
					ago
				</DateText>
				<ChipContainer>
					{view.type === ViewType.BASIC && (
						<Chip icon={<TableIcon />}>
							{getTableDisplayNameFromSchemaAndTableName({
								tablesById: snap.entities.tables.byId,
								tableNamesToIdMap: snap.tableNamesToIdMap,
								schemaName: (view as unknown as ApiBasicView).schemaName ?? '',
								tableName: (view as unknown as ApiBasicView).tableName ?? '',
								sqlDatabaseId: view.sqlDatabaseId,
							})}
						</Chip>
					)}
					{view.database && (
						<Chip icon={<CloudIcon />}>{view.database.dataSource.name}</Chip>
					)}
				</ChipContainer>
			</>
		);
	}

	const [isDeleteViewAlertDialogOpen, setIsDeleteViewAlertDialogOpen] =
		useState(false);

	interface ViewToBeDeleted {
		id: number;
		name: string;
	}
	const [viewToBeDeleted, setViewToBeDeleted] =
		useState<ViewToBeDeleted | null>(null);

	function toggleFavorite(
		event: React.MouseEvent | null,
		view: WorkspaceApiView
	) {
		event?.preventDefault();

		updateApiViewFavorited(
			{ viewId: view.id, favorited: !view.favorited },
			{
				onSuccess: () => {
					queryClient.invalidateQueries([
						REACT_QUERY_CACHE_KEY.WORKSPACE,
						workspaceId,
					]);
				},
			}
		);
		queryClient.setQueryData<ApiView | undefined>(
			[
				REACT_QUERY_CACHE_KEY.VIEW,
				{
					viewId: view.id,
				} as FetchViewParams,
			],
			(old) => {
				if (old) {
					return { ...old, favorited: !view.favorited };
				}
				return old;
			}
		);
		queryClient.setQueryData<FetchWorkspaceResponse | undefined>(
			['workspace', workspaceId],
			(cache) => {
				if (cache) {
					const updatedCache = produce(cache, (draftCache) => {
						const updatedViewIndex = draftCache.views.findIndex(
							(cacheView) => cacheView.id === view.id
						);
						if (updatedViewIndex !== -1) {
							draftCache.views[updatedViewIndex].favorited = !view.favorited;
						}
					});
					return updatedCache;
				}
				return cache;
			}
		);
	}

	const { mutate: deleteView } = useDeleteApiView({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			toast('View deleted.');
		},
	});

	useDocumentTitle('Views');

	return (
		<Page primary>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<AllViewsIcon />} title="Views" />
					</ActionBarSection>

					<ActionBarSection padded>
						{workspace && canManageViews(collaborator) && (
							<ActionBarItem>
								<Button
									icon={<PlusIcon />}
									value="New view"
									primary
									to="/views/new"
									disabled={workspace.sqlDatabases.length === 0}
								/>
							</ActionBarItem>
						)}
					</ActionBarSection>
				</ActionBar>

				<Content>
					{workspace &&
					canManageDataSources(collaborator) &&
					workspace.sqlDatabases.length === 0 ? (
						<ConnectDataSourceCta />
					) : (
						<>
							{draftItems.length + publishedItems.length === 0 &&
								(canManageViews(collaborator) ? (
									<InfoContainer>
										<InfoText>Create your first view to get started.</InfoText>
										<InfoButton
											to="/views/new"
											icon={<PlusIcon />}
											value="New view"
										/>
									</InfoContainer>
								) : (
									<InfoContainer>
										<InfoText>You don't have access to any views yet.</InfoText>
									</InfoContainer>
								))}

							{draftItems.length + publishedItems.length > 0 && (
								<ListDirectory
									sections={[
										{
											title: 'Drafts',
											icon: <EditIcon />,
											items: draftItems,
										},
										{
											title: 'Published',
											icon: <PublishIcon />,
											items: publishedItems,
										},
									]}
									renderItem={renderItem}
									renderContextMenu={renderContextMenu}
									itemName="view"
								/>
							)}
						</>
					)}
				</Content>
			</Container>

			{workspace && viewToBeDeleted && (
				<DeleteViewAlertDialog
					viewName={viewToBeDeleted.name}
					workspaceName={workspace.name}
					open={isDeleteViewAlertDialogOpen}
					onOpenChange={(open) => {
						setIsDeleteViewAlertDialogOpen(open);
						if (!open) {
							setViewToBeDeleted(null);
						}
					}}
					onConfirm={() => {
						if (viewToBeDeleted) {
							deleteView(viewToBeDeleted.id);
						}
					}}
				/>
			)}
		</Page>
	);
}
