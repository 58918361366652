import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import FormButton from 'components/fields/FormButton';
import { Input } from 'components/fields/Input';
import { Form } from 'components/forms/Form';
import { changeUserName } from 'reduxState/thunks/changeUserName';
import { User } from 'typings/models';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { ApiUser } from 'typings/serverTypes';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';

const ChangeNameForm = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const workspaceId = useSelector((state) => state.workspaceId);
	const workspace =
		queryClient.getQueryData<ApiWorkspaceDetails>(['workspace', workspaceId]) ??
		null;
	const usersById = useMemo(() => {
		return (
			workspace?.collaborators.reduce<{ [userId: string]: User }>(
				(prev, curr) => {
					prev[curr.user.id] = curr.user;
					return prev;
				},
				{}
			) ?? {}
		);
	}, [workspace]);
	const userId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({
		name: {
			value: userId !== null ? usersById[userId].fullName : '',
			errors: null,
		},
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFields({
			...fields,
			[name]: {
				value,
				errors: [],
			},
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (loading || userId === null) {
			return;
		}
		setLoading(true);

		try {
			await dispatch(
				changeUserName({
					userId: userId,
					name: fields.name.value,
				})
			);

			queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
			setLoading(false);
			toast('Name changed.');
		} catch (error) {
			setLoading(false);

			// @ts-expect-error FIX
			if (error.formErrors) {
				const newFields = fields;
				// @ts-expect-error FIX
				for (const field of Object.keys(error.formErrors)) {
					// @ts-expect-error FIX
					if (error.formErrors[field].length) {
						newFields[field as keyof typeof fields].errors =
							// @ts-expect-error FIX
							error.formErrors[field];
					} else {
						newFields[field as keyof typeof fields].errors = null;
					}
				}

				setFields(newFields);
			}
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Input
				name="name"
				type="text"
				label="Full name"
				value={fields.name.value}
				errors={fields.name.errors}
				onChange={handleChange}
				autoFocus
			/>
			<FormButton loading={loading ? true : undefined}>Change name</FormButton>
		</Form>
	);
};

export default ChangeNameForm;
