import 'emoji-mart/css/emoji-mart.css';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'down... Remove this comment to see the full error message
import { downloadCSV } from 'download-csv';
import { Emoji, EmojiData, Picker } from 'emoji-mart';
import { Parser } from 'json2csv';
import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import styled from 'styled-components';

import { FetchRecordsResponse, fetchTableRecords } from 'api/fetchTableRecords';
import { fetchViewRecords } from 'api/fetchViewRecords';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useCreateBasicApiView } from 'api/reactQueryHooks/useCreateBasicApiView';
import { useDeleteApiView } from 'api/reactQueryHooks/useDeleteApiView';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import { useUpdateApiViewFavorited } from 'api/reactQueryHooks/useUpdateApiViewFavorited';
import {
	ActionBar,
	ActionBarItem,
	ActionBarSection,
	ActionBarTitle,
} from 'components/ActionBar';
import { AvatarList } from 'components/AvatarList';
import Button from 'components/Button';
import { DeleteViewAlertDialog } from 'components/DeleteViewAlertDialog';
import { DepracatedPopover } from 'components/DepracatedPopover';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import SearchBar from 'components/SearchBar';
import FilterDropdown from 'components/TableControls/FilterDropdown';
import {
	SegmentedController,
	SegmentedControllerItem,
} from 'components/fields/SegmentedController';
import SlackModal from 'components/modals/SlackModal';
import { useTheme } from 'components/providers/ThemeProvider';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/arrowLeft.svg';
import { ReactComponent as DataSourceIcon } from 'images/icons/database.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg';
import { ReactComponent as DuplicateIcon } from 'images/icons/duplicate.svg';
import { ReactComponent as EditIcon } from 'images/icons/edit.svg';
import { ReactComponent as EmojiIcon } from 'images/icons/emoji.svg';
import { ReactComponent as FavoritedOffIcon } from 'images/icons/favoritedOff.svg';
import { ReactComponent as FavoritedOnIcon } from 'images/icons/favoritedOn.svg';
import { ReactComponent as ListIcon } from 'images/icons/list.svg';
import { ReactComponent as MenuIcon } from 'images/icons/menu.svg';
import { ReactComponent as MoreIcon } from 'images/icons/more.svg';
import { ReactComponent as ReloadIcon } from 'images/icons/reload.svg';
import { ReactComponent as SlackIcon } from 'images/icons/slack.svg';
import { ReactComponent as TableIcon } from 'images/icons/table.svg';
import { ReactComponent as ViewIcon } from 'images/icons/view.svg';
import { sidebarToggled } from 'reduxState/slices/sidebarExpanded';
import { ApiUser, CellRecord } from 'typings/serverTypes';
import { Layout, ViewType, TableData, ExtractedTableData } from 'typings/types';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { formatAttributesForTableRecordsApiPayload } from 'utils/formatAttributesForTableRecordsApiPayload';
import { canManageViews } from 'utils/permissions';
import { media } from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';

const MobileActionBarButton = styled(Button)`
	margin-right: 0.25rem;

	${media.desktop`
		display: none;
		margin-right: 0;
		margin-left: 0;
	`}
`;

const StyledTableControls = styled.div`
	position: relative;
	z-index: 10;
`;

const LayoutSelection = styled.div`
	margin-left: 0.5rem;
`;

const SearchBarContainer = styled(ActionBarItem)`
	flex-basis: 18rem;
`;

interface TableControlsProps {
	workspaceId: number;
	reload: () => void;
	data: TableData<ViewType.BASIC | void>;
	extractedTableData: ExtractedTableData;
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	pageNumber: number;
	setPageNumber: (page: number) => void;
}

function TableControls({
	workspaceId,
	data,
	reload,
	searchQuery,
	setSearchQuery,
	setPageNumber,
	extractedTableData,
}: TableControlsProps) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { themeMode } = useTheme();
	const collaborator = useCollaborator();
	const {
		joins,
		attributes,
		filters,
		sorts,
		databaseId,
		schemaName,
		tableName,
		rowHeight,
	} = extractedTableData;

	const queryClient = useQueryClient();
	const { mutate: createBasicApiView } = useCreateBasicApiView({
		onSuccess: (view) => {
			history.push(`/views/${view.id}`);
		},
	});
	const { mutate: deleteApiView } = useDeleteApiView({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			toast('View deleted.');
			history.push(`/views`);
		},
	});
	const [isDeleteViewAlertDialogOpen, setIsDeleteViewAlertDialogOpen] =
		useState(false);
	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: 'view' in data ? data.view : null,
		},
		{
			onSuccess: (_data) => {
				// Workspace API call returns a list of views, so we need to refetch the workspace
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
			},
		}
	);
	const { mutate: updateApiViewFavorited } = useUpdateApiViewFavorited({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
		},
	});

	const [close, setClose] = useState<(() => void) | null>(null);
	const [slackModalOpen, setSlackModalOpen] = useState(false);

	const updateClose = useCallback(
		(closeDropdown) => setClose(() => closeDropdown),
		[]
	);

	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	const currentUserId = queryClient.getQueryData<ApiUser>('user')?.id ?? null;
	const connectedUsers = useSelector((state) => state.connectedUsers);
	const avatarUserIds = useMemo(
		() => connectedUsers.filter((userId) => userId !== currentUserId),
		[connectedUsers, currentUserId]
	);

	const toggleFavorite = () => {
		if (data.type === 'view') {
			updateApiViewFavorited({
				viewId: data.view.id,
				favorited: !data.view.favorited,
			});
		}
	};

	const handleSelectEmoji = (emoji: EmojiData) => {
		if (data.type === 'view') {
			updateApiView({
				view: {
					id: data.view.id,
					emoji: emoji.id,
				},
			});
		}

		if (close !== null) {
			close();
		}
	};

	const handleResetEmoji = () => {
		if (data.type === 'view') {
			updateApiView({
				view: {
					id: data.view.id,
					emoji: null,
				},
			});
		}
	};

	const handleDeleteView = () => {
		if (data.type === 'view') {
			deleteApiView(data.view.id);
		}
	};

	const handleChangeLayout = (layout: string) => {
		if (layout === '') {
			return;
		}

		if (data.type === 'view') {
			updateApiView({
				view: {
					id: data.view.id,
					layout: layout as Layout,
				},
			});
		}
	};

	const handleDuplicateView = () => {
		if (data.type === 'view') {
			createBasicApiView({
				databaseId,
				schemaName,
				tableName,
				workspaceId,
				view: {
					name: `${data.view.name} copy`,
					layout: Layout.TABLE,
					joins,
					attributes,
					attributeProperties: attributes,
					listAttributes:
						data.view.type === ViewType.BASIC ? data.view.listAttributes : [],
					filters,
					sorts,
					listRowHeight: rowHeight,
					tableRowHeight: rowHeight,
				},
			});
		}
	};

	const handleDownloadCsv = async () => {
		let csvRecordsData: CellRecord[] | null = null;
		if (data.type === 'view' && data.view.type === 'BASIC') {
			const response = await fetchViewRecords({
				viewId: data.view.id,
				filters: [],
				sorts: [],
				pageNumber: null,
				searchQuery: null,
				prettyAttributeNames: true,
			});
			if (response.ok) {
				const { records } = (await response.json()) as FetchRecordsResponse;
				csvRecordsData = records;
			}
		} else if (data.type === 'table') {
			const response = await fetchTableRecords({
				tableId: data.id,
				attributes: formatAttributesForTableRecordsApiPayload({
					attributes,
				}),
				filters,
				sorts,
				pageNumber: null,
				searchQuery: null,
				prettyAttributeNames: true,
			});
			if (response.ok) {
				const { records } = (await response.json()) as FetchRecordsResponse;
				csvRecordsData = records;
			}
		}
		if (csvRecordsData) {
			const parser = new Parser({});
			const csv = parser.parse(csvRecordsData);
			const fileName = slugify(tableName || 'data');
			downloadCSV(csv, null, fileName);
		} else {
			toast.error('Unable to download CSV');
		}
	};

	const handleToggleSidebar = () => {
		dispatch(sidebarToggled());
	};

	return (
		<>
			<StyledTableControls>
				<ActionBar ignoreMobileMenuButton>
					{data.type === 'view' && (
						<MobileActionBarButton
							icon={<MenuIcon />}
							onClick={handleToggleSidebar}
						/>
					)}
					{data.type === 'table' && (
						<MobileActionBarButton
							icon={<ArrowLeftIcon />}
							to="/data-sources"
						/>
					)}
					<ActionBarSection>
						{data.type === 'view' && (
							<>
								<ActionBarItem hideOnMobile>
									<Button
										onClick={toggleFavorite}
										icon={
											data.view.favorited ? (
												<FavoritedOnIcon />
											) : (
												<FavoritedOffIcon />
											)
										}
										primaryIcon={data.view.favorited}
										title="Favorite view"
									/>
								</ActionBarItem>

								<ActionBarItem hideOnMobile>
									{canManageViews(collaborator) ? (
										<DepracatedPopover
											toggle={
												<Button
													icon={
														data.view.emoji ? (
															<Emoji emoji={data.view.emoji} size={15} />
														) : (
															<ViewIcon />
														)
													}
													title="View icon"
												/>
											}
											setClose={updateClose}
										>
											<Picker
												onSelect={handleSelectEmoji}
												title={undefined}
												showPreview={false}
												showSkinTones={false}
												theme={themeMode}
												autoFocus
											/>
										</DepracatedPopover>
									) : (
										<Button
											icon={
												data.view.emoji ? (
													<Emoji emoji={data.view.emoji} size={15} />
												) : (
													<ViewIcon />
												)
											}
										/>
									)}
								</ActionBarItem>
							</>
						)}

						<ActionBarItem>
							{data.type === 'view' ? (
								<ActionBarTitle title={data.view.name} limitWidth />
							) : (
								<ActionBarTitle
									icon={<DataSourceIcon />}
									title={tableName}
									limitWidth
								/>
							)}
						</ActionBarItem>

						{data.type === 'view' && (
							<ActionBarItem>
								<LayoutSelection>
									<SegmentedController
										type="single"
										value={data.view.layout}
										onValueChange={handleChangeLayout}
									>
										<SegmentedControllerItem
											value={Layout.TABLE}
											icon={<TableIcon />}
											title="Table layout"
											active={data.view.layout === Layout.TABLE}
										/>
										<SegmentedControllerItem
											value={Layout.LIST}
											icon={<ListIcon />}
											title="List layout"
											active={data.view.layout === Layout.LIST}
										/>
									</SegmentedController>
								</LayoutSelection>
							</ActionBarItem>
						)}
					</ActionBarSection>

					<ActionBarSection padded>
						{data.type === 'view' &&
							location.pathname !== `/views/${data.view.id}/settings` &&
							canManageViews(collaborator) && (
								<ActionBarItem hideOnMobile>
									<Button
										to={`/views/${data.view.id}/settings`}
										icon={<EditIcon />}
										value="Edit view"
									/>
								</ActionBarItem>
							)}

						{canManageViews(collaborator) && (
							<ActionBarItem hideOnMobile>
								<FilterDropdown
									data={data}
									setPageNumber={setPageNumber}
									extractedTableData={extractedTableData}
								/>
							</ActionBarItem>
						)}

						<ActionBarItem hideOnMobile>
							<Button
								onClick={reload}
								icon={<ReloadIcon />}
								title="Reload records"
							/>
						</ActionBarItem>

						<ActionBarItem>
							<DropdownMenu
								align="end"
								trigger={<Button icon={<MoreIcon />} />}
							>
								{data.type === 'view' && (
									<>
										{data.view.type === ViewType.BASIC && (
											<DropdownMenuItem
												onSelect={() => setSlackModalOpen(true)}
												icon={<SlackIcon />}
											>
												{data.view.tracker
													? data.view.tracker.channelName
													: 'Enable Slack notifications'}
											</DropdownMenuItem>
										)}

										{canManageViews(collaborator) && (
											<>
												{data.view.emoji && (
													<DropdownMenuItem
														onSelect={handleResetEmoji}
														icon={<EmojiIcon />}
													>
														Reset emoji
													</DropdownMenuItem>
												)}
												{data.type === 'view' &&
													location.pathname !==
														`/views/${data.view.id}/settings` &&
													canManageViews(collaborator) && (
														<DropdownMenuItem
															to={`/views/${data.view.id}/settings`}
															icon={<EditIcon />}
														>
															Edit view
														</DropdownMenuItem>
													)}

												<DropdownMenuItem
													onSelect={handleDuplicateView}
													icon={<DuplicateIcon />}
												>
													Duplicate view
												</DropdownMenuItem>

												<DropdownMenuItem
													onSelect={() => setIsDeleteViewAlertDialogOpen(true)}
													icon={<DeleteIcon />}
												>
													Delete view
												</DropdownMenuItem>
											</>
										)}
									</>
								)}

								<DropdownMenuItem
									onSelect={handleDownloadCsv}
									icon={<DownloadIcon />}
								>
									Download CSV
								</DropdownMenuItem>
							</DropdownMenu>
						</ActionBarItem>

						{(data.type === 'table' || data.view.type === ViewType.BASIC) && (
							<>
								<SearchBarContainer>
									<SearchBar
										searchQuery={searchQuery}
										setSearchQuery={setSearchQuery}
										setPageNumber={setPageNumber}
									/>
								</SearchBarContainer>

								{avatarUserIds.length > 0 && (
									<ActionBarItem hideOnMobile>
										<AvatarList userIds={avatarUserIds} />
									</ActionBarItem>
								)}
							</>
						)}
					</ActionBarSection>
				</ActionBar>
			</StyledTableControls>
			{workspace && data.type === 'view' && (
				<DeleteViewAlertDialog
					viewName={data.view.name}
					workspaceName={workspace.name}
					open={isDeleteViewAlertDialogOpen}
					onOpenChange={(open) => setIsDeleteViewAlertDialogOpen(open)}
					onConfirm={handleDeleteView}
				/>
			)}
			{data.type === 'view' && data.view.type === 'BASIC' && (
				<SlackModal
					view={data.view}
					open={slackModalOpen}
					onOpenChange={setSlackModalOpen}
				/>
			)}
		</>
	);
}

export default React.memo(TableControls);
