import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { loginWithGoogle } from 'api/loginWithGoogle';
import { useToken } from 'components/providers/TokenProvider';
import { toast } from 'utils/toast/toast';
import { state } from 'valtioState';

const SignInWithGoogleButtonRoot = styled.div`
	margin-top: 2.25rem;
`;

const GoogleButtonTarget = styled.button`
	border: none;
	background: none;
`;

interface CredentialResponse {
	credential?: string;
	clientId?: string;
}

export function SignInWithGoogleButton() {
	const snap = useSnapshot(state);
	const history = useHistory();
	const { setToken } = useToken();
	const [loaded, setLoaded] = useState(false);

	const clientId = snap.config?.googleOauthClientId;

	const handleGoogleSignIn = useCallback(
		async (res: CredentialResponse) => {
			if (!res.clientId || !res.credential) return;

			const response = await loginWithGoogle(res.credential);

			if (!response.ok) {
				const { error } = await response.json();

				toast.error(`Sign in with Google failed: ${error}`);
				return;
			}

			const { token } = await response.json();

			setToken(token);
			history.push('/');
		},
		[history, setToken]
	);

	useEffect(() => {
		if (!document.getElementById('google-client-script')) {
			const script = document.createElement('script');
			script.src = 'https://accounts.google.com/gsi/client';
			script.async = true;
			script.id = 'google-client-script';
			document.body.appendChild(script);

			script.onload = () => {
				setLoaded(true);

				window.google.accounts.id.initialize({
					client_id: clientId,
					callback: handleGoogleSignIn,
				});
			};
		}

		return () => {
			document.getElementById('google-client-script')?.remove();
		};
	}, [clientId, loaded, handleGoogleSignIn]);

	return (
		<SignInWithGoogleButtonRoot>
			{loaded && (
				<GoogleButtonTarget className="g_id_signin" data-width="336" />
			)}
		</SignInWithGoogleButtonRoot>
	);
}
