import { createContext, useContext, useState } from 'react';

import { useApiUser } from 'api/reactQueryHooks/useApiUser';
import { FEATURE_FLAGS, LOCAL_STORAGE_KEY } from 'utils/constants';

import { useToken } from './TokenProvider';

const FeatureFlagContext = createContext<
	| {
			featureFlags: string[];
			hasFeatureFlag: (featureFlag: FEATURE_FLAGS) => boolean;
	  }
	| undefined
>(undefined);

export const useFeatureFlags = () => {
	const featureFlags = useContext(FeatureFlagContext);
	if (featureFlags === undefined) {
		throw new Error('useFeatureFlags must be used within a token provider');
	}
	return featureFlags;
};

interface FeatureFlagProviderProps {
	children: React.ReactNode;
}

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
	const { token } = useToken();
	const authenticated = token !== null;
	const [featureFlags, setFeatureFlags] = useState<FEATURE_FLAGS[] | undefined>(
		JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.FEATURE_FLAGS) ?? '[]')
	);
	useApiUser({
		onSuccess: ({ featureFlags }) => {
			localStorage.setItem(
				LOCAL_STORAGE_KEY.FEATURE_FLAGS,
				JSON.stringify(featureFlags)
			);
			setFeatureFlags(featureFlags as FEATURE_FLAGS[]);
		},
		enabled: authenticated,
	});

	const hasFeatureFlag = (featureFlag: FEATURE_FLAGS): boolean =>
		featureFlags ? featureFlags.includes(featureFlag) : false;

	return (
		<FeatureFlagContext.Provider
			value={featureFlags ? { featureFlags, hasFeatureFlag } : undefined}
		>
			{children}
		</FeatureFlagContext.Provider>
	);
}
