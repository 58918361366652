import Button from 'components/Button';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import { handleAction } from 'components/actions';
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevronDown.svg';

export function ActionsTester() {
	return (
		<DropdownMenu
			align="end"
			trigger={
				<Button
					value="Pretend actions"
					iconRight={<ChevronDownIcon />}
					outlined
				/>
			}
		>
			<DropdownMenuItem onSelect={() => handleAction(42, true)}>
				Succeeding action
			</DropdownMenuItem>
			<DropdownMenuItem onSelect={() => handleAction(42, false)}>
				Failing action
			</DropdownMenuItem>
			<DropdownMenuItem onSelect={() => handleAction(41, true)}>
				Invalid action
			</DropdownMenuItem>
		</DropdownMenu>
	);
}
