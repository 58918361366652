import styled from 'styled-components';

import { Tooltip } from 'components/Tooltip';
import { User } from 'typings/models';

const UserAvatarIcon = styled.div<{
	avatarColour: string;
	name: string;
}>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-image: ${({ name, avatarColour }) =>
		`url(
			'https://source.boringavatars.com/marble/120/${name}?colors=${avatarColour.substring(
			1
		)},061DE0,${avatarColour.substring(1)},ffffff,000000'
		)`};
	background-size: 100%;
`;

export const UserAvatarImage = styled.img`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
`;

interface Props {
	user?: Pick<User, 'id' | 'fullName' | 'avatarUrl' | 'avatarColour'>;
	email?: string;
}

export function UserAvatar({ user, email }: Props) {
	const avatarColour = user?.avatarColour || '#aaa';

	if (!user || !user.avatarUrl) {
		return (
			<Tooltip value={user?.fullName || email}>
				<UserAvatarIcon
					avatarColour={avatarColour}
					name={user?.fullName ?? ''}
				/>
			</Tooltip>
		);
	}

	return (
		<Tooltip value={user.fullName}>
			<UserAvatarImage src={user.avatarUrl} alt={user.fullName} />
		</Tooltip>
	);
}
