import styled from 'styled-components';

import { neutral } from 'utils/colors';

const Title = styled.p`
	font-size: 0.75rem;
	font-weight: 400;
	color: ${neutral[1]};
`;

export default Title;
