import arrayMove from 'array-move';
import { ChangeEventHandler, useEffect, useState } from 'react';
import {
	SortableContainer as sortableContainer,
	SortableElement as sortableElement,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Button from 'components/Button';
import { Icon } from 'components/Icon';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import Select from 'components/fields/Select';
import { ReactComponent as DragIcon } from 'images/icons/drag.svg';
import { ReactComponent as MinusIcon } from 'images/icons/minus.svg';
import { ApiRecordAttribute, Sort } from 'typings/serverTypes';
import { TableData, ExtractedTableData, ViewType } from 'typings/types';
import { neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { sortDirectionLabel } from 'utils/sortDirectionLabel';
import styles from 'utils/styles';
import { state } from 'valtioState';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

const DropdownContainer = styled.ul`
	min-width: 16rem;
	margin-bottom: ${(props) =>
		// @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
		props.children.length ? '1rem' : '0'};
`;

const DropdownRow = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.5rem 0;
	font-size: 0.875rem;
	user-select: none;
	cursor: grab;

	* + * {
		margin-left: 0.5rem;
	}
`;

const DragHandle = styled.div`
	margin-left: auto;
`;

const StyledSelect = styled(Select)`
	:nth-child(1) {
		width: 60%;
	}
	:nth-child(2) {
		width: 40%;
		min-width: 4.5rem;
	}

	select {
		width: 100%;
	}
`;

const RemoveButton = styled(Button)`
	padding: 0.125rem;
	background: none;
	visibility: hidden;
`;

const SortableItemContainer = styled(DropdownRow)`
	display: flex;
	width: 100%;
	height: 2rem;
	margin: 0;
	padding: 0 0.5rem;
	border-radius: ${styles.global.borderRadius};

	&:hover {
		background: ${neutral[5]};
		${RemoveButton} {
			visibility: visible;
		}
	}
`;

type SortableItemProps = {
	columns: ApiRecordAttribute[] | null;
	sort: Sort;
	showTableName: boolean;
	availableSorts: ApiRecordAttribute[];
	removeSort: () => void;
	updateSort: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
	dataSourceId: number;
};

const SortableItem = sortableElement(
	({
		sort,
		columns,
		showTableName,
		availableSorts,
		removeSort,
		updateSort,
		dataSourceId,
	}: SortableItemProps) => {
		const snap = useSnapshot(state);

		const column = columns?.find(
			(column) =>
				column.schemaName === sort.schemaName &&
				column.tableName === sort.tableName &&
				column.attributeName === sort.columnName
		);
		if (!column) {
			return null;
		}
		const attributeType = getAttributeTypeFromDbColumnType({
			dbColumnType: column.typeInDb,
		});

		return (
			<SortableItemContainer>
				<StyledSelect
					mini
					name="column"
					value={JSON.stringify({
						schemaName: sort.schemaName,
						tableName: sort.tableName,
						columnName: sort.columnName,
					})}
					onChange={updateSort}
					options={availableSorts.map((column) => {
						const tableDisplayName = getTableDisplayName({
							tablesById: snap.entities.tables.byId,
							tableId: column.tableId,
						});
						const attributeDisplayName = getAttributeDisplayName({
							attributesById: snap.entities.attributes.byId,
							attributeNamesToIdMap: snap.attributeNamesToIdMap,
							attributeName: column.attributeName,
							tableName: column.tableName,
							schemaName: column.schemaName,
							sqlDatabaseId: dataSourceId,
						});

						return {
							label: showTableName
								? `${tableDisplayName} ${attributeDisplayName}`
								: attributeDisplayName,
							value: JSON.stringify({
								schemaName: column.schemaName,
								tableName: column.tableName,
								columnName: column.attributeName,
							}),
						};
					})}
				/>

				<StyledSelect
					mini
					name="ascending"
					value={String(sort.ascending)}
					onChange={updateSort}
					options={[
						{
							label: sortDirectionLabel({ attributeType, ascending: true }),
							value: 'true',
						},
						{
							label: sortDirectionLabel({ attributeType, ascending: false }),
							value: 'false',
						},
					]}
				/>

				<RemoveButton onClick={removeSort} icon={<MinusIcon />} />

				<DragHandle>
					<Icon>
						<DragIcon />
					</Icon>
				</DragHandle>
			</SortableItemContainer>
		);
	}
);

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SortableContainer = sortableContainer(({ children }) => {
	return <DropdownContainer>{children}</DropdownContainer>;
});

interface SortsListProps {
	data: TableData<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
}

export function SortsList({ data, extractedTableData }: SortsListProps) {
	const {
		attributes,
		joins,
		sorts: savedSorts,
		databaseId,
	} = extractedTableData;

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);

	const [sorts, setSorts] = useState<Sort[]>([]);

	useEffect(() => {
		setSorts(savedSorts);
	}, [savedSorts, data]);

	const onSortEnd = async ({
		oldIndex,
		newIndex,
	}: {
		oldIndex: number;
		newIndex: number;
	}) => {
		const updatedSorts = arrayMove(sorts, oldIndex, newIndex);
		setSorts(updatedSorts);
		updateApiView({
			view: { id: data.view.id, sorts: updatedSorts },
		});
	};

	const availableSorts = (includedSort: Sort | null = null) => {
		return (
			attributes.filter((column) => {
				for (const sort of sorts) {
					if (
						includedSort &&
						column.schemaName === includedSort.schemaName &&
						column.tableName === includedSort.tableName &&
						column.attributeName === includedSort.columnName
					) {
						break;
					}

					if (
						column.schemaName === sort.schemaName &&
						column.tableName === sort.tableName &&
						column.attributeName === sort.columnName
					) {
						return false;
					}
				}
				return true;
			}) ?? []
		);
	};

	const removeSort = async (sortToRemove: Sort) => {
		const updatedSorts = sorts.filter(
			(sort) =>
				!(
					sort.schemaName === sortToRemove.schemaName &&
					sort.tableName === sortToRemove.tableName &&
					sort.columnName === sortToRemove.columnName
				)
		);

		setSorts(updatedSorts);
		updateApiView({ view: { id: data.view.id, sorts: updatedSorts } });
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'sortToRemove' implicitly has an 'any' t... Remove this comment to see the full error message
	const updateSort = async (sortToRemove, event) => {
		const updatedSorts = sorts.map((sort) =>
			sort.schemaName === sortToRemove.schemaName &&
			sort.tableName === sortToRemove.tableName &&
			sort.columnName === sortToRemove.columnName
				? event.target.name === 'column'
					? {
							...sort,
							...JSON.parse(event.target.value),
					  }
					: {
							...sort,
							[event.target.name]: event.target.value,
					  }
				: sort
		);

		setSorts(updatedSorts);
		updateApiView({ view: { id: data.view.id, sorts: updatedSorts } });
	};

	return (
		<SortableContainer
			onSortEnd={onSortEnd}
			lockAxis="y"
			transitionDuration={200}
			helperClass="sortableHelper"
			distance={1}
		>
			{sorts.map((sort, index) => (
				<SortableItem
					key={index}
					index={index}
					sort={sort}
					columns={attributes}
					showTableName={joins.length > 0}
					availableSorts={availableSorts(sort)}
					removeSort={removeSort.bind(null, sort)}
					updateSort={updateSort.bind(null, sort)}
					dataSourceId={databaseId}
				/>
			))}
		</SortableContainer>
	);
}
