import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/src/getDefaultMiddleware';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import logger from 'redux-logger';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistStore,
} from 'redux-persist';

import rootReducer, { RootState } from 'reduxState/reducers';
import { AppConfig } from 'typings/serverTypes';

export const getStore = (config: AppConfig): EnhancedStore => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => {
			let middleware = getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			});
			if (config.reduxLoggingEnabled) {
				middleware = middleware.concat(logger);
			}
			return middleware;
		},
	});

	persistStore(store);

	return store;
};

type AppDispatch = EnhancedStore<
	RootState,
	AnyAction,
	[ThunkMiddlewareFor<RootState>]
>['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
