import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { FetchViewParams } from 'api/fetchView';
import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import { useDeleteApiView } from 'api/reactQueryHooks/useDeleteApiView';
import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import {
	ActionBar,
	ActionBarItem,
	ActionBarSection,
	ActionBarTitle,
} from 'components/ActionBar';
import Button from 'components/Button';
import { DeleteViewAlertDialog } from 'components/DeleteViewAlertDialog';
import TextLink from 'components/TextLink';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/arrowLeft.svg';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg';
import { ReactComponent as PublishIcon } from 'images/icons/publish.svg';
import { ApiView } from 'typings/serverTypes';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { toast } from 'utils/toast/toast';

const InfoText = styled.span`
	margin-left: 1rem;
	color: ${neutral[2]};
	cursor: default;
`;

const PreviousButton = styled(Button)`
	margin-right: 0.25rem;
`;

interface ViewSettingsHeaderProps {
	view: ApiView;
}

export function ViewSettingsHeader({ view }: ViewSettingsHeaderProps) {
	const history = useHistory();
	const queryClient = useQueryClient();
	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const [isDeleteViewAlertDialogOpen, setIsDeleteViewAlertDialogOpen] =
		useState(false);

	const { mutate: updateApiViewIsPublished } = useUpdateApiView(
		{
			view,
		},
		{
			onError: (_error, params, context) => {
				queryClient.setQueryData<ApiView | undefined>(
					[
						REACT_QUERY_CACHE_KEY.VIEW,
						{
							viewId: view.id,
						} as FetchViewParams,
					],
					(old) => {
						if (old && context) {
							return { ...old, ...context.view } as ApiView;
						}
						return old;
					}
				);
				if (params.view) {
					toast.error(
						`Unable to ${
							params.view.isPublished ? 'unpublish' : 'publish'
						} view.`
					);
				}
			},
			onSuccess: (_data, params) => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					workspaceId,
				]);
				if (params.view?.isPublished) {
					history.push(`/views/${view.id}`);
				}
			},
		}
	);
	const { mutate: deleteApiView } = useDeleteApiView({
		onSuccess: () => {
			queryClient.invalidateQueries([
				REACT_QUERY_CACHE_KEY.WORKSPACE,
				workspaceId,
			]);
			toast('View deleted.');
			history.push(`/views`);
		},
	});

	const publishView = () => {
		updateApiViewIsPublished({ view: { id: view.id, isPublished: true } });
	};

	const unpublishView = () => {
		updateApiViewIsPublished({ view: { id: view.id, isPublished: false } });
	};

	const deleteView = () => {
		deleteApiView(view.id);
	};

	return (
		<ActionBar ignoreMobileMenuButton topLevel>
			<ActionBarSection>
				<ActionBarItem>
					<PreviousButton
						to={view.isPublished ? `/views/${view.id}` : '/views'}
						icon={<ArrowLeftIcon />}
					/>
				</ActionBarItem>
				<ActionBarTitle title={`${view.name} – Editing`} />

				<ActionBarItem>
					<InfoText>
						Autosaved to{' '}
						<TextLink to="/views">
							{view.isPublished ? 'published' : 'drafts'}
						</TextLink>
					</InfoText>
				</ActionBarItem>
			</ActionBarSection>

			<ActionBarSection padded>
				<ActionBarItem>
					<Button
						title="Delete view"
						icon={<DeleteIcon />}
						onClick={() => setIsDeleteViewAlertDialogOpen(true)}
					/>
				</ActionBarItem>

				<ActionBarItem>
					{view.isPublished ? (
						<Button
							value="Move to drafts"
							title="Drafts are only available to workspace admins"
							onClick={unpublishView}
						/>
					) : (
						<Button
							value="Publish"
							icon={<PublishIcon />}
							title="Publishing a view makes it available to all members of your workspace"
							primary
							onClick={publishView}
						/>
					)}
				</ActionBarItem>
				{view.isPublished && (
					<ActionBarItem>
						<Button
							icon={<CheckIcon />}
							value="Done editing"
							to={`/views/${view.id}`}
							primary
						/>
					</ActionBarItem>
				)}
			</ActionBarSection>
			{workspace && (
				<DeleteViewAlertDialog
					viewName={view.name}
					workspaceName={workspace.name}
					open={isDeleteViewAlertDialogOpen}
					onOpenChange={(open) => setIsDeleteViewAlertDialogOpen(open)}
					onConfirm={deleteView}
				/>
			)}
		</ActionBar>
	);
}
