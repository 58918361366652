import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import {
	ActionBar,
	ActionBarTitle,
	ActionBarSection,
	Container,
	Content,
} from 'components/ActionBar';
import Card from 'components/Card';
import Page from 'components/Page';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import TextLink from 'components/TextLink';
import ThemePicker from 'components/ThemePicker';
import { UserAvatar } from 'components/UserAvatar';
import WorkspaceAccess from 'components/WorkspaceAccess';
import AvatarForm from 'components/forms/AvatarForm';
import ChangeNameForm from 'components/forms/ChangeNameForm';
import ChangePasswordForm from 'components/forms/ChangePasswordForm';
import { TwoFactorAuthenticationForm } from 'components/forms/TwoFactorAuthenticationForm';
import { BillingOverview } from 'components/sections/BillingOverview';
import { PlansOverview } from 'components/sections/PlansOverview';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';
import { ApiUser } from 'typings/serverTypes';
import { Role } from 'typings/types';
import { canManageBilling } from 'utils/permissions';
import { media } from 'utils/styles';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { state } from 'valtioState';

const AccountOverviewContainer = styled.div`
	display: flex;
	justify-content: center;

	${media.mobile`
		flex-direction: column;
		align-items: center;
	`};
`;

const UserAvatarContainer = styled.div`
	width: 8rem;
	height: 8rem;
	flex-shrink: 0;
	background: none;
	border: none;

	${media.tabletUp`
		margin-right: 2rem;
	`};

	${media.mobile`
		margin-bottom: 1rem;
	`};
`;

const AccountInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.tabletUp`
		align-items: flex-start;
	`};

	${media.mobile`
		align-items: center;
	`};
`;

const Name = styled.p`
	font-size: 1.125rem;
	font-weight: 500;
`;

const ItemContainer = styled.div`
	display: flex;
	align-items: center;

	& + & {
		margin-top: 0.5rem;
	}
`;

const InlineTextLink = styled(TextLink)`
	margin-left: 1rem;
	font-size: 0.75rem;
`;

export function SettingsPage() {
	const workspaceId = useSelector((state) => state.workspaceId);
	const queryClient = useQueryClient();
	const snap = useSnapshot(state);
	const user = queryClient.getQueryData<ApiUser>('user') ?? null;
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});
	const collaborator = workspace?.collaborators.find(
		(collaborator) => collaborator.userId === user?.id
	);

	const canEditWorkspaceAccess =
		collaborator?.isOwner || collaborator?.role === Role.ADMIN;

	const [changeNameFormIsVisible, setChangeNameFormIsVisible] = useState(false);
	const [changePasswordFormIsVisible, setChangePasswordFormIsVisible] =
		useState(false);
	const [avatarFormIsVisible, setAvatarFormIsVisible] = useState(false);
	const [
		twoFactorAuthenticationFormIsVisible,
		setTwoFactorAuthenticationFormIsVisible,
	] = useState(false);

	const toggleAvatarForm = () => {
		if (avatarFormIsVisible) {
			setAvatarFormIsVisible(false);
			return;
		}

		setChangeNameFormIsVisible(false);
		setChangePasswordFormIsVisible(false);
		setAvatarFormIsVisible(true);
		setTwoFactorAuthenticationFormIsVisible(false);
	};

	const toggleTwoFactorAuthenticationForm = () => {
		if (twoFactorAuthenticationFormIsVisible) {
			setTwoFactorAuthenticationFormIsVisible(false);
			return;
		}

		setChangeNameFormIsVisible(false);
		setChangePasswordFormIsVisible(false);
		setAvatarFormIsVisible(false);
		setTwoFactorAuthenticationFormIsVisible(true);
	};

	const toggleChangeNameForm = () => {
		if (changeNameFormIsVisible) {
			setChangeNameFormIsVisible(false);
			return;
		}

		setChangeNameFormIsVisible(true);
		setChangePasswordFormIsVisible(false);
		setAvatarFormIsVisible(false);
		setTwoFactorAuthenticationFormIsVisible(false);
	};

	const toggleChangePasswordForm = () => {
		if (changePasswordFormIsVisible) {
			setChangePasswordFormIsVisible(false);
			return;
		}

		setChangeNameFormIsVisible(false);
		setChangePasswordFormIsVisible(true);
		setAvatarFormIsVisible(false);
		setTwoFactorAuthenticationFormIsVisible(false);
	};

	useDocumentTitle('Settings');

	if (!user || !workspace || !collaborator) {
		return null;
	}

	return (
		<Page>
			<Container>
				<ActionBar>
					<ActionBarSection>
						<ActionBarTitle icon={<SettingsIcon />} title="Settings" />
					</ActionBarSection>
				</ActionBar>

				<Content>
					<SectionContainer>
						<Section title="Account">
							<Card>
								<AccountOverviewContainer>
									<UserAvatarContainer>
										<UserAvatar user={user} />
									</UserAvatarContainer>
									<AccountInfoContainer>
										<ItemContainer>
											<Name>{user.fullName}</Name>
											<InlineTextLink
												as="button"
												onClick={toggleChangeNameForm}
											>
												Change name
											</InlineTextLink>
										</ItemContainer>
										<ItemContainer>{user.email}</ItemContainer>
										<ItemContainer>
											<TextLink as="button" onClick={toggleChangePasswordForm}>
												{user.hasPassword ? 'Change password' : 'Set password'}
											</TextLink>
										</ItemContainer>
										<ItemContainer>
											<TextLink as="button" onClick={toggleAvatarForm}>
												Upload avatar
											</TextLink>
										</ItemContainer>
										<ItemContainer>
											<TextLink
												as="button"
												onClick={toggleTwoFactorAuthenticationForm}
											>
												{user.authyId
													? 'Two-factor authentication enabled'
													: 'Enable two-factor authentication'}
											</TextLink>
										</ItemContainer>
									</AccountInfoContainer>
								</AccountOverviewContainer>
								{avatarFormIsVisible && <AvatarForm userId={user.id} />}
								{changeNameFormIsVisible && <ChangeNameForm />}
								{changePasswordFormIsVisible && (
									<ChangePasswordForm userHasPassword={user.hasPassword} />
								)}
								{twoFactorAuthenticationFormIsVisible && (
									<TwoFactorAuthenticationForm />
								)}
							</Card>
						</Section>

						<Section title="Theme">
							<Card>
								<ThemePicker />
							</Card>
						</Section>

						{canEditWorkspaceAccess && (
							<Section title="Workspace">
								<Card>
									<WorkspaceAccess workspace={workspace} />
								</Card>
							</Section>
						)}

						{canManageBilling(collaborator) && !snap.config?.isSelfHosted && (
							<>
								<PlansOverview workspace={workspace}></PlansOverview>
								<BillingOverview
									workspace={workspace}
									collaborator={collaborator}
								/>
							</>
						)}
					</SectionContainer>
				</Content>
			</Container>
		</Page>
	);
}
