import React from 'react';
import styled from 'styled-components';

import { neutral, onPrimary, primary } from 'utils/colors';
import styles, { media } from 'utils/styles';

const SectionComponent = styled.section`
	width: 100%;
	line-height: 1.5;

	${media.desktop`
		& + & {
			margin-top: 3rem;
		}
	`};

	${media.tabletDown`
		& + & {
			margin-top: 2rem;
		}
	`};
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 0.625rem;
	line-height: 1;
`;

const Title = styled.h2`
	font-size: 1.5rem;
	font-weight: 500;
	color: ${neutral[1]};
	cursor: default;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Dropdown = styled.div`
	display: flex;
	align-items: center;
	margin-left: 0.25rem;
`;

export const Button = styled.button`
	margin-left: 0.5rem;
	padding: 0.25rem 0.625rem;
	font-size: 0.875rem;
	border: none;
	border-radius: ${styles.global.borderRadius};
	line-height: 1.25;
	color: ${onPrimary};
	background: ${primary[1]};
	cursor: pointer;

	&:hover {
		color: ${onPrimary};
		background: ${primary[3]};
	}
`;

interface Props {
	title?: string;
	titleContent?: string;
	details?: React.ReactNode;
	dropdown?: string;
	children?: React.ReactNode;
}

export default function Section({
	title,
	titleContent,
	details,
	dropdown,
	children,
}: Props) {
	return (
		<SectionComponent>
			<Header>
				<TitleContainer>
					<Title>{title}</Title>
					{dropdown && <Dropdown>{dropdown}</Dropdown>}
					{titleContent}
				</TitleContainer>
				{details}
			</Header>
			{children}
		</SectionComponent>
	);
}
