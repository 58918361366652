import { createContext, useContext, useState } from 'react';

import { LOCAL_STORAGE_KEY } from 'utils/constants';

const TokenContext = createContext<{
	token: string | null | undefined;
	setToken: ((token: string | null) => void) | undefined;
}>({ token: undefined, setToken: undefined });

export const useToken = () => {
	const { token, setToken } = useContext(TokenContext);
	if (token === undefined || setToken === undefined) {
		throw new Error('useToken must be used within a token provider');
	}
	return { token, setToken };
};

interface TokenProviderProps {
	children: React.ReactNode;
}

export function TokenProvider({ children }: TokenProviderProps) {
	const [token, setTokenState] = useState<string | null | undefined>(
		localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)
	);

	const setToken = (value: string | null) => {
		if (value === null) {
			localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
		} else {
			localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, value);
		}
		setTokenState(value);
	};

	return (
		<TokenContext.Provider value={{ token, setToken }}>
			{children}
		</TokenContext.Provider>
	);
}
