import { getToken } from 'api/utils';

export function updateAvatar(avatar: File | null) {
	const formData = new FormData();
	if (avatar) {
		formData.append('avatar', avatar);
	}

	return fetch('/api/user/avatar', {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: formData,
	});
}
