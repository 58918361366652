import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { FetchWorkspaceInvitationsResponse } from 'api/getMemberInvitations';
import { updateMemberRole } from 'api/updateMemberRole';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import { Icon } from 'components/Icon';
import { List, ListItem } from 'components/List';
import { UserAvatar } from 'components/UserAvatar';
import { ReactComponent as MoreIcon } from 'images/icons/more.svg';
import { useAppDispatch } from 'reduxState/store';
import { deleteMemberInvitation } from 'reduxState/thunks/deleteMemberInvitation';
import { removeMember as removeMemberThunk } from 'reduxState/thunks/removeMember';
import { ApiWorkspaceDetails } from 'typings/serverTypes';
import { Role } from 'typings/types';
import { neutral } from 'utils/colors';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { canInviteMembersAsRole, canManageMembers } from 'utils/permissions';
import styles from 'utils/styles';
import { toast } from 'utils/toast/toast';
import { useCollaborator } from 'utils/useCollaborator';
import { getVerboseRole } from 'utils/verboseNames';

const UserAvatarContainer = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	margin-right: 1rem;
	flex-shrink: 0;
`;

const Stack = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
`;

const StackPrimary = styled.span`
	font-weight: 500;
	cursor: default;
`;

const StackSecondary = styled.span`
	color: ${neutral[2]};
	font-size: 0.875rem;
	cursor: default;
`;

const MemberListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	padding: 1rem 1.25rem;
	color: ${neutral[1]};
	flex-grow: 1;
`;

const DropdownButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${styles.global.borderRadius};
	padding: 0.25rem;
	cursor: pointer;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		fill: ${neutral[2]};
	}

	&:hover {
		background: ${neutral[5]};
	}
`;

interface MemberListProps {
	members: ApiWorkspaceDetails['collaborators'];
	workspaceInvitations: FetchWorkspaceInvitationsResponse;
}

export function MemberList({ members, workspaceInvitations }: MemberListProps) {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const member = useCollaborator();

	const sortedMembers = [...members].sort(
		(a, b) =>
			(getVerboseRole(a.role) ?? '').localeCompare(
				getVerboseRole(b.role) ?? ''
			) || a.user.fullName.localeCompare(b.user.fullName)
	);

	const sortedWorkspaceInvitations = [...workspaceInvitations].sort(
		(a, b) =>
			(getVerboseRole(a.role) ?? '').localeCompare(
				getVerboseRole(b.role) ?? ''
			) || a.email.localeCompare(b.email)
	);

	const removeMember = async (
		member: ApiWorkspaceDetails['collaborators'][number]
	) => {
		dispatch(
			removeMemberThunk({
				workspaceId: member.workspaceId,
				memberId: member.id,
			})
		)
			.unwrap()
			.then(() => {
				toast(`${member.user.fullName} removed as a member.`);
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					member.workspaceId,
				]);
			})
			.catch(() => {
				toast.error(`Unable to remove ${member.user.fullName}.`);
			});
	};

	const removeWorkspaceInvitation = async (
		workspaceInvitation: FetchWorkspaceInvitationsResponse[number]
	) => {
		dispatch(
			deleteMemberInvitation({
				workspaceId: workspaceInvitation.workspaceId,
				invitationId: workspaceInvitation.id,
			})
		)
			.unwrap()
			.then(() => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.MEMBER_INVITATIONS,
					workspaceInvitation.workspaceId,
				]);
				toast(`${workspaceInvitation.email} removed as a member.`);
			})
			.catch(() => {
				toast.error(
					`Unable to remove ${workspaceInvitation.email} as a member.`
				);
			});
	};

	const changeMemberRole = async (
		member: ApiWorkspaceDetails['collaborators'][number],
		role: Role
	) => {
		updateMemberRole(member.workspaceId, member.id, role)
			.then(() => {
				queryClient.invalidateQueries([
					REACT_QUERY_CACHE_KEY.WORKSPACE,
					member.workspaceId,
				]);
				toast(
					`${member.user.fullName} updated to a ${getVerboseRole(role)} member.`
				);
			})
			.catch(() => {
				toast.error(`Problem updating member role`);
			});
	};

	return (
		<List>
			{sortedMembers.map((listMember) => (
				<MemberListItem key={listMember.id} border>
					<UserAvatarContainer>
						<UserAvatar user={listMember.user} />
					</UserAvatarContainer>
					<Stack>
						<StackPrimary>{listMember.user.fullName}</StackPrimary>
						<StackSecondary>
							{getVerboseRole(listMember.role)}
							{listMember.isOwner && ' (Owner)'}
							{listMember.user.authyId && ' – 2FA enabled'}
						</StackSecondary>
					</Stack>
					{canManageMembers(member) && listMember.isOwner === false && (
						<DropdownMenu
							align="end"
							trigger={
								<DropdownButton>
									<Icon>
										<MoreIcon />
									</Icon>
								</DropdownButton>
							}
						>
							{Object.entries(Role)
								.filter(([key]) => listMember.role !== key)
								.map(([key, value]) => {
									if (canInviteMembersAsRole(member, value)) {
										return (
											<DropdownMenuItem
												key={key}
												onSelect={() => changeMemberRole(listMember, value)}
											>
												Change role to {getVerboseRole(value)}
											</DropdownMenuItem>
										);
									}
								})}
							<DropdownMenuItem onSelect={() => removeMember(listMember)}>
								Remove
							</DropdownMenuItem>
						</DropdownMenu>
					)}
				</MemberListItem>
			))}

			{sortedWorkspaceInvitations.map((workspaceInvitation) => (
				<MemberListItem key={workspaceInvitation.email} border>
					<UserAvatarContainer>
						<UserAvatar email={workspaceInvitation.email} />
					</UserAvatarContainer>
					<Stack>
						<StackPrimary>{workspaceInvitation.email}</StackPrimary>
						<StackSecondary>
							{getVerboseRole(workspaceInvitation.role)} (Invited)
						</StackSecondary>
					</Stack>
					{canManageMembers(member) && (
						<DropdownMenu
							align="end"
							trigger={
								<DropdownButton>
									<Icon>
										<MoreIcon />
									</Icon>
								</DropdownButton>
							}
						>
							<DropdownMenuItem
								onSelect={() => removeWorkspaceInvitation(workspaceInvitation)}
							>
								Remove
							</DropdownMenuItem>
						</DropdownMenu>
					)}
				</MemberListItem>
			))}
		</List>
	);
}
