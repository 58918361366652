import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { background, bodyBackground, neutral, primary } from 'utils/colors';
import styles from 'utils/styles';

export const StyledFormAttributeValue = styled.div<{
	editable: boolean;
	highlighted: boolean;
	isForeignKey: boolean;
}>`
	margin-left: auto;
	width: 100%;
	border: 1px solid ${neutral[4]};
	border-radius: 0.25rem;
	font-size: 0.875rem;
	position: relative;
	padding: 0.5rem;
	background: ${({ editable, highlighted }) => {
		if (editable) {
			return background[1];
		}
		if (highlighted) {
			return rgba(styles.colours.primary[700], 0.1);
		}
		return bodyBackground;
	}};

	cursor: ${({ isForeignKey }) => {
		if (isForeignKey) {
			return 'pointer';
		}
		return 'default';
	}};

	${(props) =>
		props.editable &&
		css`
			cursor: default;
			user-select: none;
		`};

	&:focus,
	&:focus-within {
		box-shadow: 0 0 0 2px
			${({ editable }) => (editable ? primary[1] : 'transparent')};
	}
`;
