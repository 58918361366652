import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/Checkbox';
import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { TableCellContentsText } from 'components/TableCell/TableCellContentsText';
import { EMPTY_VISUAL_SYMBOL, NULL_VISUAL_SYMBOL } from 'utils/constants';

type ReadOnlyTableCellContentsProps = {
	rawValue: string | boolean | Date | null;
	displayValue: string | boolean | null;
};

const CheckboxContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ReadOnlyCellContents = ({
	rawValue,
	displayValue,
}: ReadOnlyTableCellContentsProps) => {
	if (rawValue === '') {
		return <StyledNullOrEmptyText>{EMPTY_VISUAL_SYMBOL}</StyledNullOrEmptyText>;
	}

	if (rawValue === null) {
		return <StyledNullOrEmptyText>{NULL_VISUAL_SYMBOL}</StyledNullOrEmptyText>;
	}

	if (typeof rawValue === 'boolean') {
		return (
			<CheckboxContainer>
				<Checkbox checked={rawValue} disabled />
			</CheckboxContainer>
		);
	}

	return (
		<TableCellContentsText className="fs-mask highlight-block">
			{displayValue}
		</TableCellContentsText>
	);
};
