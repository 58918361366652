import { ApiView } from 'typings/serverTypes';
import { state } from 'valtioState/index';

export const setAttributesInAView = (
	viewId: number,
	attributes: ApiView['attributes']
) => {
	const data = state.entities.views.byId[viewId]?.data;
	if (data) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		state.entities.views.byId[viewId]!.data!.attributes = attributes;
	}
};
