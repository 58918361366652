import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { loginMagicLink } from 'api/loginMagicLink';
import { updateUser } from 'api/updateUser';
import Card from 'components/Card';
import LogoLink from 'components/LogoLink';
import RouteContainer from 'components/RouteContainer';
import Section from 'components/Section';
import { SectionContainer } from 'components/SectionContainer';
import Spinner from 'components/Spinner';
import { useToken } from 'components/providers/TokenProvider';
import { logout } from 'reduxState/actions';
import { REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { media } from 'utils/styles';

const StyledRouteContainer = styled(RouteContainer)`
	html.todesktop-platform-darwin & {
		-webkit-app-region: drag;

		* {
			-webkit-app-region: no-drag;
		}
	}
`;

const Container = styled(SectionContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 40rem;

	${media.desktop`
		padding-top: 2rem;
		padding-bottom: 2rem;
	`};

	${media.tabletUp`
		min-height: 100vh;
	`};

	${media.mobile`
		min-height: 100vh;
		padding-top: 0;
		padding-bottom: 1rem;
	`};
`;

function LoginMagicLinkRoute() {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { token, setToken } = useToken();
	const authenticated = token !== null;
	const { magicLinkKey } = useParams<{ magicLinkKey: string }>();

	const [error, setError] = useState(null);

	useEffect(() => {
		const loginWithMagicLink = async () => {
			try {
				const response = await loginMagicLink(magicLinkKey);
				if (!response.ok) {
					const error = await response.json();
					setError(error);
				}

				if (authenticated) {
					setToken(null);
					dispatch(logout());
				}

				const token = await response.json();
				setToken(token);

				updateUser().then(() => {
					queryClient.invalidateQueries(REACT_QUERY_CACHE_KEY.USER);
				});
			} catch (error) {}
		};

		loginWithMagicLink();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (authenticated) {
		return <Redirect to="/" />;
	}

	return (
		<StyledRouteContainer>
			<Container>
				<LogoLink />
				<Section>
					<Card>
						{!error ? (
							<Spinner />
						) : (
							<p>
								The magic link you used is not valid or has expired. Please try
								again.
							</p>
						)}
					</Card>
				</Section>
			</Container>
		</StyledRouteContainer>
	);
}

export default LoginMagicLinkRoute;
