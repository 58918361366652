import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { primary } from 'utils/colors';

export const AffectedRecordsLink = styled(Link)`
	margin-top: 0.5rem;
	display: inline-block;
	color: ${primary.text};
`;
