import styled from 'styled-components';

import _ButtonPrimary from 'components/Button';
import { ReactComponent as ChevronLeftIcon } from 'images/icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'images/icons/chevronRight.svg';
import { ReactComponent as DoubleChevronLeftIcon } from 'images/icons/doubleChevronLeft.svg';
import { ReactComponent as DoubleChevronRightIcon } from 'images/icons/doubleChevronRight.svg';
import { neutral } from 'utils/colors';
import { pageSize } from 'utils/constants';

const PaginationComponent = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.span`
	margin: 0 0.5rem;
	color: ${neutral[1]};
	font-weight: 500;
	cursor: default;
`;

const ButtonPrimary = styled(_ButtonPrimary)`
	padding: 0.5rem 0.75rem;
`;

interface PaginationProps {
	numRecords: number;
	pageNumber: number;
	setPageNumber: (page: number) => void;
}

export function Pagination({
	numRecords,
	pageNumber,
	setPageNumber,
}: PaginationProps) {
	const goToPage = async (pageNumber: number) => {
		setPageNumber(pageNumber);
	};

	const canGoToPreviousPage = () => {
		return pageNumber > 0;
	};

	const canGoToNextPage = () => {
		return (pageNumber + 1) * pageSize < numRecords;
	};

	const goToFirstPage = () => {
		if (canGoToPreviousPage()) {
			goToPage(0);
		}
	};

	const goToPreviousPage = () => {
		if (canGoToPreviousPage()) {
			goToPage(pageNumber - 1);
		}
	};

	const goToNextPage = () => {
		if (canGoToNextPage()) {
			goToPage(pageNumber + 1);
		}
	};

	const goToLastPage = () => {
		if (canGoToNextPage()) {
			goToPage(Math.ceil(numRecords / pageSize) - 1);
		}
	};

	if (!numRecords) {
		return null;
	}

	return (
		<PaginationComponent>
			<ButtonPrimary
				title="First page"
				onClick={goToFirstPage}
				disabled={!canGoToPreviousPage()}
				icon={<DoubleChevronLeftIcon />}
			/>

			<ButtonPrimary
				title="Previous page"
				onClick={goToPreviousPage}
				disabled={!canGoToPreviousPage()}
				icon={<ChevronLeftIcon />}
			/>

			<Text>
				{Math.min(numRecords, pageNumber * pageSize + 1)} &ndash;{' '}
				{Math.min(numRecords, (pageNumber + 1) * pageSize)}
			</Text>

			<ButtonPrimary
				title="Next page"
				onClick={goToNextPage}
				disabled={!canGoToNextPage()}
				icon={<ChevronRightIcon />}
			/>

			<ButtonPrimary
				title="Last page"
				onClick={goToLastPage}
				disabled={!canGoToNextPage()}
				icon={<DoubleChevronRightIcon />}
			/>
		</PaginationComponent>
	);
}
