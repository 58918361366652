import { createSlice } from '@reduxjs/toolkit';

import { logout } from 'reduxState/actions';

export type SidebarExpandedState = boolean;

const initialState: SidebarExpandedState = false;

const sidebarExpandedSlice = createSlice({
	name: 'sidebarExpanded',
	initialState,
	reducers: {
		sidebarToggled(state) {
			return !state;
		},
		sidebarClosed() {
			return false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { sidebarToggled, sidebarClosed } = sidebarExpandedSlice.actions;

export default sidebarExpandedSlice.reducer;
