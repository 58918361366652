import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useApiWorkspace } from 'api/reactQueryHooks/useApiWorkspace';
import Page from 'components/Page';
import TableContent from 'components/TableContent';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { state } from 'valtioState';

const DataSourceTablesRoot = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	width: 100%;
`;

export function DataSourceTables() {
	const { dataSourceId: dataSourceIdParam, tableId: tableIdParam } = useParams<{
		dataSourceId: string;
		tableId: string;
	}>();
	const dataSourceId = Number(dataSourceIdParam);
	const tableId = Number(tableIdParam);
	const snap = useSnapshot(state);
	const dataSource = snap.entities.dataSources.byId[dataSourceId];
	const table = snap.entities.tables.byId[tableId];

	const workspaceId = useSelector((state) => state.workspaceId);
	const { data: workspace } = useApiWorkspace(workspaceId, {
		enabled: workspaceId !== null,
	});

	useDocumentTitle(`${table?.displayName} - ${dataSource?.name}`);

	if (!dataSource || !workspace || !table) {
		return null;
	}

	return (
		<DataSourceTablesRoot>
			<Page>
				<TableContent tableId={Number(tableId)} />
			</Page>
		</DataSourceTablesRoot>
	);
}
