import { useQuery, UseQueryOptions } from 'react-query';

import { fetchUser } from 'api/fetchUser';
import { ApiUser } from 'typings/serverTypes';
import { LOCAL_STORAGE_KEY, REACT_QUERY_CACHE_KEY } from 'utils/constants';
import { ApiError } from 'utils/errors';

export const useApiUser = (
	options?: UseQueryOptions<ApiUser, string | undefined, ApiUser, string>
) =>
	useQuery<ApiUser, string | undefined, ApiUser, string>(
		REACT_QUERY_CACHE_KEY.USER,
		async () => {
			const response = await fetchUser();
			if (!response.ok) {
				if (response.status === 400) {
					return response.json();
				}
				// If the user is unauthorized (e.g. bad token), then remove the token from local storage and
				// reload the page so that they get redirected to the login page
				if (response.status === 401) {
					localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
					window.location.assign('/login');
				}
				throw new ApiError('Unable to fetch user');
			}
			return response.json();
		},
		options
	);
