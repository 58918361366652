import { getDefaultHeaders } from 'api/utils';
import { ApiWorkspaceDetails } from 'typings/serverTypes';

export type FetchWorkspaceResponse = ApiWorkspaceDetails;
export type FetchWorkspaceParams = number;

export function fetchWorkspace(workspaceId: FetchWorkspaceParams) {
	return fetch(`/api/workspaces/${workspaceId}`, {
		method: 'GET',
		headers: getDefaultHeaders(),
	});
}
