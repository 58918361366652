import styled from 'styled-components';

import { PaymentMethod } from 'typings/types';

const PaymentMethodItem = styled.span`
	cursor: default;

	& + & {
		margin-left: 1.5rem;
	}
`;

interface Props {
	paymentMethod: PaymentMethod;
}

function PaymentMethodInfo({ paymentMethod }: Props) {
	return (
		<div>
			<PaymentMethodItem>{paymentMethod.brand}</PaymentMethodItem>
			<PaymentMethodItem>
				&bull;&bull;&bull;&bull; {paymentMethod.last4}
			</PaymentMethodItem>
			<PaymentMethodItem>
				{paymentMethod.expMonth.toString().padStart(2, '0')} /{' '}
				{paymentMethod.expYear}
			</PaymentMethodItem>
			<PaymentMethodItem>{paymentMethod.name}</PaymentMethodItem>
		</div>
	);
}

export default PaymentMethodInfo;
