import React, { useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { EditorValue } from 'react-rte';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useDeleteApiRecords } from 'api/reactQueryHooks/useDeleteApiRecords';
import Button from 'components/Button';
import { DateInput } from 'components/DateInput';
import { ForeignKeyPopover } from 'components/ForeignKeyPopover';
import { JsonEditor } from 'components/JsonEditor';
import { FormPanelProperties } from 'components/ListView/index';
import { RichTextEditor } from 'components/RichTextEditor';
import { StyledFormAttributeValue } from 'components/StyledFormAttributeValue';
import {
	getAttributeTypeFromDbColumnType,
	getForeignKey,
	getRecordId,
} from 'components/Table/tableUtils';
import { EditableTableCellContents } from 'components/TableCell/EditableTableCellContents';
import { DropdownTableCellContents } from 'components/TableCell/EditableTableCellContents/DropdownTableCellContents';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg';
import { PrimaryAttribute } from 'typings/models';
import { ApiRecordAttribute, CellRecord } from 'typings/serverTypes';
import {
	TableData,
	TableData__View,
	ExtractedTableData,
	ViewType,
} from 'typings/types';
import { background, bodyBackground, neutral } from 'utils/colors';
import { CELL_VIEW_TYPE, richTextToolbarConfig } from 'utils/constants';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getPrimaryAttributeAttributeFromGlobalStore } from 'utils/getPrimaryAttributeAttributeFromGlobalStore';
import { getRecordDisplayValue } from 'utils/getRecordDisplayValue';
import { getRecordValue } from 'utils/getRecordValue';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import styles from 'utils/styles';
import { useCollaborator } from 'utils/useCollaborator';
import { useEditableRecordValue } from 'utils/useEditableRecordValue';
import { state } from 'valtioState';

const StyledFormPanel = styled.div`
	width: 26rem;
	height: 100%;
	background-color: ${bodyBackground};
	flex-shrink: 0;
	position: absolute;
	right: 0;
	top: 0;
	border-left: 1px solid ${neutral[4]};
	overflow-y: auto;
`;

const FormPanelAttributeItem = styled.div`
	padding: 0 0.5rem 1rem 0.75rem;
	color: ${neutral[1]};
	display: flex;
	align-items: flex-start;

	&:last-child {
		padding-bottom: 0;
	}
`;

const Header = styled.div`
	height: ${styles.sizes.actionBarHeight};
	background: ${background[1]};
	border-bottom: 1px solid ${neutral[4]};
	display: flex;
	align-items: center;
	color: ${neutral[1]};
	padding-left: 1rem;
	padding-right: 0.5rem;
	position: sticky;
	top: 0;
	z-index: 1;
`;

const RecordIdentifierValue = styled.div`
	font-size: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: default;
`;

const HeaderActions = styled.div`
	margin-left: auto;
`;

const Body = styled.div`
	padding: 1rem 0.5rem 12.5rem 0.5rem;
`;

const FormAttributeLabel = styled.label`
	font-size: 0.75rem;
	width: 7.5rem;
	padding-top: 0.5rem;
	padding-right: 1rem;
	flex-shrink: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

interface StyledFormAttributeValueInputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	isObscured: boolean;
	isEditing: boolean;
}

const FormAttributeValueInput = styled.input<StyledFormAttributeValueInputProps>`
	background: none;
	border: none;
	width: 100%;
	color: inherit;
	outline: none;
	font-family: ${(props) =>
		props.isObscured && !props.isEditing
			? 'Basehash'
			: styles.text.fontFamilyText};
`;

const ForeignKeyValue = styled.div`
	outline: none;
`;

interface FormAttributeValueInputProps {
	id: string;
	record: CellRecord;
	attribute: ApiRecordAttribute;
	data: TableData<ViewType.BASIC>;
	extractedTableData: ExtractedTableData;
	rowNumber: number;
	pageNumber: number;
	searchQuery: string;
	routeLocationSearch: string;
	rootTablePrimaryAttribute: PrimaryAttribute | undefined;
	setFormPanelProperties: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			record: CellRecord | null;
			rowNumber: number | null;
			pageNumber: number | null;
		}>
	>;
}

function FormAttributeValue({
	attribute,
	record,
	data,
	extractedTableData,
	rowNumber,
	pageNumber,
	searchQuery,
	setFormPanelProperties,
	id,
	routeLocationSearch,
	rootTablePrimaryAttribute,
}: FormAttributeValueInputProps) {
	const {
		handleDoubleClick,
		cellRef,
		canEdit,
		setValue,
		cellViewType,
		primaryAttributeAttribute,
		setIsDropdownOpen,
		stopEditing,
		inputValue,
		handleFocus,
		handleBlur,
		handleChange,
		foreignKey,
		isDropdownOpen,
		handleKeyDown,
		highlighted,
		attributeType,
		rawValue,
		handleChangeDate,
		databaseId,
		displayValue,
		checked,
		toggleCheckbox,
		columnEnumValues,
		dataSource,
		isObscured,
		isEditing,
	} = useEditableRecordValue({
		record,
		attribute: attribute,
		data,
		extractedTableData: extractedTableData,
		rowNumber,
		pageNumber,
		searchQuery,
		onUpdateRecord: (record) =>
			setFormPanelProperties((old) => ({ ...old, record })),
		isFormPanel: true,
		rootTablePrimaryAttribute,
	});

	const { tableId } = extractedTableData;

	const renderValue = () => {
		if (attribute.hasAllowedValues) {
			const allowedValuesArray = attribute.allowedValues.split('\n');

			return (
				<DropdownTableCellContents
					setValue={setValue}
					cellRef={cellRef}
					rawValue={rawValue}
					displayValue={displayValue}
					canEdit={canEdit}
					id={id}
					column={attribute}
					allowedValues={allowedValuesArray}
					isFormPanel={true}
				/>
			);
		}

		if (foreignKey) {
			return (
				<ForeignKeyValue
					ref={cellRef}
					onKeyDown={handleKeyDown}
					onClick={handleDoubleClick}
					onFocus={handleFocus}
					onBlur={handleBlur}
					tabIndex={canEdit ? 0 : -1}
					id={id}
				>
					<EditableTableCellContents
						id={id}
						cellViewType={cellViewType}
						rawValue={rawValue}
						foreignKey={foreignKey}
						displayValue={displayValue}
						column={attribute}
						attributeType={attributeType}
						handleChangeDate={handleChangeDate}
						handleFocus={handleFocus}
						handleBlur={handleBlur}
						canEdit={canEdit}
						checked={checked}
						toggleCheckbox={toggleCheckbox}
						columnEnumValues={columnEnumValues}
						inputValue={inputValue}
						databaseId={databaseId}
						dataSource={dataSource}
						highlighted={highlighted}
						routeLocationSearch={routeLocationSearch}
						setValue={setValue}
						data={data}
						cellRef={cellRef}
						handleChange={handleChange}
					/>
				</ForeignKeyValue>
			);
		}
		// TODO: Use a rich text input (https://linear.app/basedash/issue/BAS-659/use-rich-text-input-in-form-panel)
		if (inputValue instanceof EditorValue) {
			return (
				<RichTextEditor
					className="fs-mask highlight-block"
					value={inputValue}
					onChange={handleChange}
					toolbarConfig={richTextToolbarConfig}
					// @ts-expect-error Incorrect types from library
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					autoFocus
				/>
			);
		}
		if (cellViewType === CELL_VIEW_TYPE.JSON) {
			return (
				<JsonEditor
					value={inputValue || ''}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					stopEditing={stopEditing}
					saveJsonValue={(value) => {
						setValue({ valueAfter: value.getValue() });
						stopEditing();
					}}
					id={id}
				/>
			);
		}
		if (
			cellViewType === CELL_VIEW_TYPE.ENUM ||
			cellViewType === CELL_VIEW_TYPE.CHECKBOX ||
			typeof displayValue === 'boolean'
		) {
			return (
				<EditableTableCellContents
					id={id}
					cellViewType={cellViewType}
					rawValue={rawValue}
					foreignKey={foreignKey}
					displayValue={displayValue}
					column={attribute}
					attributeType={attributeType}
					handleChangeDate={handleChangeDate}
					handleFocus={handleFocus}
					handleBlur={handleBlur}
					canEdit={canEdit}
					checked={checked}
					toggleCheckbox={toggleCheckbox}
					columnEnumValues={columnEnumValues}
					inputValue={inputValue}
					databaseId={databaseId}
					dataSource={dataSource}
					highlighted={highlighted}
					routeLocationSearch={routeLocationSearch}
					setValue={setValue}
					data={data}
					cellRef={cellRef}
					handleChange={handleChange}
					isFormPanel={true}
				/>
			);
		}

		return (
			<FormAttributeValueInput
				isObscured={isObscured}
				isEditing={isEditing}
				value={isEditing ? inputValue ?? '' : displayValue ?? ''}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={!canEdit}
				id={id}
			/>
		);
	};
	if (
		(attributeType === 'DATE' || attributeType === 'DATETIME') &&
		(rawValue instanceof Date || rawValue === null)
	) {
		return (
			<DateInput
				value={rawValue}
				attributeType={attributeType}
				onChange={handleChangeDate}
				disabled={!canEdit}
				column={attribute}
			/>
		);
	}

	return (
		<StyledFormAttributeValue
			className="fs-mask highlight-block"
			editable={canEdit}
			highlighted={highlighted}
			isForeignKey={Boolean(foreignKey)}
		>
			{renderValue()}
			{foreignKey && isDropdownOpen && (
				<ForeignKeyPopover
					sqlDatabaseId={databaseId}
					primaryAttributeAttribute={primaryAttributeAttribute}
					foreignKey={foreignKey}
					cellRef={cellRef}
					tableId={tableId}
					setValue={setValue}
					setIsDropdownOpen={setIsDropdownOpen}
				/>
			)}
		</StyledFormAttributeValue>
	);
}

interface FormPanelProps {
	record: CellRecord;
	data: TableData__View;
	extractedTableData: ExtractedTableData;
	primaryAttributeConfig: ApiRecordAttribute | undefined;
	pageNumber: number;
	searchQuery: string;
	rowNumber: number;
	primaryAttributes: PrimaryAttribute[];
	setFormPanelProperties: React.Dispatch<
		React.SetStateAction<FormPanelProperties>
	>;
	routeLocationSearch: string;
	rootTablePrimaryAttribute: PrimaryAttribute | undefined;
	reload?: () => void;
}

export function FormPanel({
	data,
	record,
	primaryAttributeConfig,
	rowNumber,
	pageNumber,
	searchQuery,
	primaryAttributes,
	setFormPanelProperties,
	routeLocationSearch,
	rootTablePrimaryAttribute,
	extractedTableData,
	reload,
}: FormPanelProps) {
	const snap = useSnapshot(state);
	const queryClient = useQueryClient();
	const collaborator = useCollaborator();
	const formPanelRef = useRef<HTMLDivElement | null>(null);
	const {
		schemaName,
		tableName,
		joins,
		foreignKeys,
		databaseId: sqlDatabaseId,
		tableId,
		primaryKeyAttributes,
	} = extractedTableData;
	const sqlDatabase = snap.entities.sqlDatabases.byId[sqlDatabaseId];
	const primaryAttributeForeignKey =
		primaryAttributeConfig &&
		getForeignKey(primaryAttributeConfig.attributeName, foreignKeys);
	const attributeId = primaryAttributeConfig?.id;
	const primaryAttributeAttribute = useMemo(() => {
		return getPrimaryAttributeAttributeFromGlobalStore({
			primaryAttributes,
			foreignKey: primaryAttributeForeignKey,
			databaseId: sqlDatabaseId,
			attributeNamesToIdMap: snap.attributeNamesToIdMap,
			attributesById: snap.entities.attributes.byId,
		});
	}, [
		primaryAttributes,
		primaryAttributeForeignKey,
		sqlDatabaseId,
		snap.attributeNamesToIdMap,
		snap.entities.attributes.byId,
	]);

	const attributeType = getAttributeTypeFromDbColumnType({
		dbColumnType: primaryAttributeConfig?.typeInDb,
		isForeignKey: Boolean(primaryAttributeForeignKey),
	});
	const rawValue = getRecordValue({
		record,
		attributeId,
		attributeType,
	});
	const primaryAttributeDisplayValue =
		primaryAttributeConfig === undefined
			? null
			: getRecordDisplayValue({
					rawValue,
					attributeType,
					primaryAttributeAttribute,
					record,
					attributeId,
			  });

	const { mutate: deleteApiRecords } = useDeleteApiRecords({
		queryClient,
		dataSourceId: sqlDatabase?.dataSourceId ?? 0,
		data,
		pageNumber,
		searchQuery,
		onSuccess: reload,
		onMutate: () =>
			setFormPanelProperties({
				record: null,
				isOpen: false,
				rowNumber: null,
				pageNumber,
			}),
	});

	const recordId = getRecordId({
		schemaName: schemaName,
		tableName: tableName,
		record,
		primaryKeyAttributes,
	});

	function handleDelete() {
		if (
			recordId !== null &&
			sqlDatabaseId !== null &&
			schemaName !== null &&
			tableName !== null
		) {
			deleteApiRecords({
				tableId,
				recordIds: [recordId],
				collaborator,
			});
		}
	}

	useEffect(() => {
		if (formPanelRef.current) {
			const focusableElements =
				'button, [href], input:not(:disabled), textarea:not(:disabled), select:not(:disabled), [tabindex]:not([tabindex="-1"])';
			const focusableContent =
				formPanelRef.current.querySelectorAll(focusableElements);
			const firstFocusableElement = focusableContent?.[0] as HTMLElement;
			const lastFocusableElement = focusableContent?.[
				focusableContent.length - 1
			] as HTMLElement;
			const eventListener = (event: KeyboardEvent) => {
				// Return focus to list view rows
				if (event.key === 'Escape') {
					const rowElements = document.querySelectorAll('.listViewRow');
					(
						rowElements?.[rowNumber - 1]?.querySelector(
							'.listViewRowCheckbox'
						) as HTMLElement | undefined
					)?.focus();
				}
				// Keep focus within form panel
				if (event.key === 'Tab') {
					if (event.shiftKey) {
						if (document.activeElement === firstFocusableElement) {
							lastFocusableElement.focus();
							event.preventDefault();
						}
					} else {
						if (document.activeElement === lastFocusableElement) {
							firstFocusableElement.focus();
							event.preventDefault();
						}
					}
				}
			};
			const currentFormPanelRef = formPanelRef.current;
			currentFormPanelRef?.addEventListener('keydown', eventListener);
			return () =>
				currentFormPanelRef?.removeEventListener('keydown', eventListener);
		}
	}, [rowNumber]);

	return (
		<StyledFormPanel id={'listViewFormPanel'} ref={formPanelRef}>
			<Header>
				<RecordIdentifierValue className="fs-mask highlight-block">
					{primaryAttributeDisplayValue}
				</RecordIdentifierValue>
				<HeaderActions>
					<Button
						title="Delete record"
						onClick={handleDelete}
						icon={<DeleteIcon />}
					/>
				</HeaderActions>
			</Header>
			<Body>
				{data.view.type === 'BASIC' &&
					data.view.attributes
						.filter((attribute) => attribute.visible)
						.map((attribute) => {
							if (sqlDatabaseId == null) {
								return null;
							}
							const tableDisplayName = getTableDisplayName({
								tablesById: snap.entities.tables.byId,
								tableId: attribute.tableId,
							});
							const attributeDisplayName = getAttributeDisplayName({
								attributesById: snap.entities.attributes.byId,
								attributeNamesToIdMap: snap.attributeNamesToIdMap,
								attributeName: attribute.attributeName,
								tableName: attribute.tableName,
								schemaName: attribute.schemaName,
								sqlDatabaseId: sqlDatabaseId,
							});
							const verboseName =
								joins.length > 0
									? `${tableDisplayName}.${attributeDisplayName}`
									: `${attributeDisplayName}`;
							const id = `attributePanelField__${verboseName.replace(' ', '')}`;
							return (
								<FormPanelAttributeItem key={verboseName}>
									<Tooltip value={verboseName}>
										<FormAttributeLabel htmlFor={id}>
											{verboseName}
										</FormAttributeLabel>
									</Tooltip>
									<FormAttributeValue
										// Key used to make sure  the FormAttributeValue component re-mounts and re-initializes the
										// state in the useRecordValue hook.
										key={JSON.stringify(recordId)}
										attribute={attribute}
										record={record}
										data={data}
										extractedTableData={extractedTableData}
										rowNumber={rowNumber}
										pageNumber={pageNumber}
										searchQuery={searchQuery}
										setFormPanelProperties={setFormPanelProperties}
										id={id}
										routeLocationSearch={routeLocationSearch}
										rootTablePrimaryAttribute={rootTablePrimaryAttribute}
									/>
								</FormPanelAttributeItem>
							);
						})}
			</Body>
		</StyledFormPanel>
	);
}
