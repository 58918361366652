import styled, { css } from 'styled-components';

import { neutral } from 'utils/colors';

export const List = styled.ul`
	display: flex;
	flex-direction: column;
`;

interface ListItemProps {
	column?: boolean;
	padded?: boolean;
	border?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
	display: flex;
	flex-shrink: 0;

	${({ column }) =>
		column &&
		css`
			flex-direction: column;
		`};

	${({ padded }) =>
		padded &&
		css`
			padding: 0.375rem 0.625rem;
		`};

	${({ border }) =>
		border &&
		css`
			&:not(:first-child) {
				border-top: 1px solid ${neutral[4]};
			}
		`};
`;

export const ListSeparator = styled.li`
	margin: 0.25rem 0.5rem;
	height: 1px;
	background: ${neutral[4]};
`;
