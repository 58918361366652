import React from 'react';

import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { EditableTableCellProps } from 'components/TableCell/EditableTableCell/index';
import { CheckboxCellContents } from 'components/TableCell/EditableTableCellContents/CheckboxCellContents';
import { StyledTableCell } from 'components/TableCell/StyledTableCell';
import { UseEditableTableRecordValueResult } from 'utils/useEditableRecordValue';

type CheckboxTableCellProps = Pick<
	EditableTableCellProps,
	| 'isFirstCell'
	| 'isPrimaryAttribute'
	| 'usersById'
	| 'column'
	| 'dataColumn'
	| 'dataRow'
> &
	Pick<
		UseEditableTableRecordValueResult,
		| 'highlighted'
		| 'isEditing'
		| 'canEdit'
		| 'handleKeyDown'
		| 'handleFocus'
		| 'handleBlur'
		| 'cellRef'
		| 'handleDoubleClick'
		| 'selected'
		| 'rowHeight'
		| 'rawValue'
		| 'checked'
		| 'toggleCheckbox'
	> & { hasCompletedFirstRender: boolean };

export const CheckboxTableCell = ({
	isFirstCell,
	isPrimaryAttribute,
	highlighted,
	isEditing,
	canEdit,
	handleFocus,
	cellRef,
	handleDoubleClick,
	selected,
	rowHeight,
	rawValue,
	handleKeyDown,
	usersById,
	column,
	hasCompletedFirstRender,
	checked,
	toggleCheckbox,
	dataColumn,
	dataRow,
}: CheckboxTableCellProps) => {
	return (
		<StyledTableCell
			className={isFirstCell ? 'firstCell' : ''}
			isPrimaryAttribute={isPrimaryAttribute}
			highlighted={highlighted}
			editing={isEditing}
			editable={canEdit}
			ref={cellRef}
			tabIndex={0}
			onFocus={handleFocus}
			onKeyDown={handleKeyDown}
			data-row={dataRow}
			data-column={dataColumn}
		>
			<StyledTableCellContainer
				onDoubleClick={handleDoubleClick}
				selected={selected !== undefined}
				selectColour={selected && usersById[selected.userId]?.avatarColour}
				editable={canEdit}
				rowHeight={rowHeight}
				hasCompletedFirstRender={hasCompletedFirstRender}
			>
				<CheckboxCellContents
					rawValue={rawValue}
					checked={checked}
					toggleCheckbox={toggleCheckbox}
					canEdit={canEdit}
					column={column}
				/>
			</StyledTableCellContainer>
		</StyledTableCell>
	);
};
