import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

import { DataSourceNavigator } from 'components/pages/DataSourceNavigator';
import { ReactComponent as CaretDownIcon } from 'images/icons/caretDown.svg';
import { ReactComponent as CaretRightIcon } from 'images/icons/caretRight.svg';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { getSortedWorkspaceDataSources } from 'utils/getSortedWorkspaceDataSources';
import { useDataSourceHealthCheck } from 'utils/useDataSourceHealthCheck';
import { state } from 'valtioState';
import { DefinedDataSource } from 'valtioState/dataSources/index';

import { SectionTitle, UnhealthyDataSourceIndicator } from '../../Sidebar';
import { SidebarItem } from '../../Sidebar/SidebarItem';
import { SidebarSection } from '../../Sidebar/SidebarSection';

interface DataSourceSidebarItemProps {
	dataSource: DefinedDataSource;
	selectedTableId: number | null;
	setTableId: (id: number) => void;
}

function DataSourceSidebarItem({
	dataSource,
	selectedTableId,
	setTableId,
}: DataSourceSidebarItemProps) {
	useDataSourceHealthCheck(dataSource);

	return (
		<SidebarSection
			Header={(props) => (
				<SidebarItem
					iconLeft={!props.expanded ? <CloudIcon /> : <CaretDownIcon />}
					iconLeftHover={!props.expanded ? <CaretRightIcon /> : undefined}
					title={dataSource.name}
					iconRightPrimary={
						dataSource.healthy === false ? (
							<UnhealthyDataSourceIndicator />
						) : undefined
					}
					{...props}
				/>
			)}
			initiallyExpanded={false}
		>
			<DataSourceNavigator
				dataSource={dataSource}
				setTableId={setTableId}
				selectedTableId={selectedTableId}
				indent
			/>
		</SidebarSection>
	);
}

interface TablesSectionProps {
	tableId: number | null;
	setTableId: (id: number) => void;
}

export function TablesSection({ tableId, setTableId }: TablesSectionProps) {
	const snap = useSnapshot(state);
	const workspaceId = useSelector((state) => state.workspaceId);
	if (!workspaceId) {
		return null;
	}

	const dataSources = getSortedWorkspaceDataSources(
		snap.entities.dataSources.byId,
		workspaceId
	);

	return (
		<SidebarSection
			Header={(props) => <SectionTitle title="Data source" {...props} />}
			initiallyExpanded={true}
			topLevel
		>
			{dataSources.map(
				(dataSource) =>
					dataSource.connected && (
						<DataSourceSidebarItem
							key={dataSource.id}
							dataSource={dataSource}
							selectedTableId={tableId}
							setTableId={setTableId}
						/>
					)
			)}
		</SidebarSection>
	);
}
