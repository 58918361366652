import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import styled from 'styled-components';

import { Content } from 'components/ActionBar';
import { FullHeightAndCenteredContent } from 'components/FullHeightAndCenteredContent';
import Spinner from 'components/Spinner';
import TableControls from 'components/TableControls';
import { ViewFooter } from 'components/ViewFooter';
import { OopsErrorPage } from 'components/pages/OopsError';
import {
	AdvancedViewAttribute,
	ApiRecordAttribute,
	ApiWorkspaceDetails,
	CellRecord,
} from 'typings/serverTypes';
import { TableData, ExtractedTableData } from 'typings/types';
import { isAdvancedViewData } from 'utils/isAdvancedViewData';
import { usePrimaryAttribute } from 'utils/usePrimaryAttribute';

import TableBody from './TableBody';

const StyledTable = styled.div<{
	visibleColumns: ApiRecordAttribute[] | AdvancedViewAttribute[];
	primaryAttributeColumn: ApiRecordAttribute | undefined;
}>`
	border-collapse: collapse;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

interface TableProps {
	editable: boolean;
	records: CellRecord[] | null;
	recordsLoading: boolean;
	recordsError: boolean | null;
	data: TableData;
	pageNumber: number;
	searchQuery: string;
	setPageNumber: React.Dispatch<React.SetStateAction<number>>;
	collaborators: ApiWorkspaceDetails['collaborators'];
	numRecords?: number;
	workspace?: ApiWorkspaceDetails;
	reload?: () => void;
	setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
	windowRef?: React.RefObject<HTMLDivElement>;
	isInViewBuilder?: boolean;
	extractedTableData: ExtractedTableData;
}

export default function Table({
	editable,
	records,
	recordsLoading,
	recordsError,
	pageNumber,
	data,
	extractedTableData,
	searchQuery,
	setPageNumber,
	collaborators,
	setSearchQuery,
	workspace,
	reload,
	numRecords,
	windowRef,
	isInViewBuilder,
}: TableProps) {
	const location = useLocation();
	const tableRef = useRef<HTMLDivElement>(null);

	const { databaseId, schemaName, tableName } = isAdvancedViewData(data)
		? {
				databaseId: data.view.sqlDatabaseId,
				schemaName: undefined,
				tableName: undefined,
		  }
		: extractedTableData;

	const primaryAttribute = usePrimaryAttribute({
		workspace,
		sqlDatabaseId: databaseId,
		schemaName,
		tableName,
	});

	const primaryAttributeColumn = useMemo(() => {
		if (isAdvancedViewData(data)) {
			return undefined;
		}
		const attributes = extractedTableData.attributes;
		return attributes.find((column) =>
			primaryAttribute
				? primaryAttribute.schemaName === column.schemaName &&
				  primaryAttribute.tableName === column.tableName &&
				  primaryAttribute.columnName === column.attributeName
				: column.isPrimaryKeyInDb
		);
	}, [data, extractedTableData.attributes, primaryAttribute]);

	const visibleColumns: ApiRecordAttribute[] | AdvancedViewAttribute[] =
		useMemo(() => {
			if (isAdvancedViewData(data)) {
				return data.view.attributes;
			}
			const attributes = extractedTableData.attributes;
			return (
				attributes
					?.filter((column) => column.visible)
					.filter((column) => column !== primaryAttributeColumn) ?? null
			);
		}, [data, primaryAttributeColumn, extractedTableData.attributes]);

	return (
		<>
			{!isAdvancedViewData(data) &&
				workspace &&
				reload &&
				setSearchQuery &&
				!isInViewBuilder && (
					<TableControls
						workspaceId={workspace.id}
						data={data}
						extractedTableData={extractedTableData}
						reload={reload}
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						setSearchQuery={setSearchQuery}
						searchQuery={searchQuery}
					/>
				)}
			<Content ref={windowRef} style={{ overflow: 'hidden' }}>
				{recordsLoading ? (
					<FullHeightAndCenteredContent>
						<Spinner />
					</FullHeightAndCenteredContent>
				) : recordsError ? (
					<OopsErrorPage />
				) : (
					<>
						<StyledTable
							ref={tableRef}
							visibleColumns={visibleColumns}
							primaryAttributeColumn={primaryAttributeColumn}
						>
							<AutoSizer>
								{({ width, height }: Size) => (
									<TableBody
										editable={editable}
										records={records ?? []}
										data={data}
										setPageNumber={setPageNumber}
										extractedTableData={extractedTableData}
										pageNumber={pageNumber}
										searchQuery={searchQuery}
										collaborators={collaborators}
										routeLocationSearch={location.search}
										reload={reload}
										workspace={workspace}
										numRecords={numRecords}
										width={width}
										height={height}
									/>
								)}
							</AutoSizer>
						</StyledTable>
					</>
				)}
			</Content>
			{!isAdvancedViewData(data) && numRecords !== undefined && (
				<ViewFooter
					loading={recordsLoading}
					canDelete
					tableData={data}
					extractedTableData={extractedTableData}
					searchQuery={searchQuery}
					numRecords={numRecords}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					reload={reload}
				/>
			)}
		</>
	);
}
