import { createSlice } from '@reduxjs/toolkit';

import { logout } from 'reduxState/actions';
import { Member } from 'typings/models';

export type CurrentMemberState = Member | null;

const initialState: CurrentMemberState = null;

const currentMemberSlice = createSlice({
	name: 'currentMember',
	initialState,
	reducers: {
		setCurrentMember(_state, action) {
			return action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { setCurrentMember } = currentMemberSlice.actions;

export default currentMemberSlice.reducer;
