import React from 'react';
import styled, { css } from 'styled-components';

import { Checkbox } from 'components/Checkbox';
import { StyledTableCellContainer } from 'components/StyledTableCellContainer';
import { StyledNullOrEmptyText } from 'components/TableCell/EditableTableCellContents/StyledNullOrEmptyText';
import { EditableTableCellContentsProps } from 'components/TableCell/EditableTableCellContents/index';
import { renderNullOrDefaultValue } from 'utils/renderNullOrDefaultValue';

const CheckboxContainer = styled.div<{
	showOnFocus: boolean;
	isFormPanel?: boolean;
}>`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: ${({ isFormPanel }) =>
		isFormPanel ? 'flex-start' : 'center'};

	${(props) =>
		props.showOnFocus &&
		css`
			display: none;

			${StyledTableCellContainer}:focus-within && {
				display: flex;
			}
		`};
`;

type CheckboxCellContentsProps = Pick<
	EditableTableCellContentsProps,
	| 'rawValue'
	| 'isFormPanel'
	| 'checked'
	| 'toggleCheckbox'
	| 'canEdit'
	| 'id'
	| 'isAddingNewRecord'
	| 'column'
>;

export const CheckboxCellContents = ({
	rawValue,
	isFormPanel,
	checked,
	toggleCheckbox,
	canEdit,
	id,
	isAddingNewRecord,
	column,
}: CheckboxCellContentsProps): React.ReactElement | null => {
	return (
		<>
			{rawValue === null && (
				<StyledNullOrEmptyText hideOnFocus>
					{renderNullOrDefaultValue({ isAddingNewRecord, column })}
				</StyledNullOrEmptyText>
			)}
			<CheckboxContainer
				className="fs-exclude highlight-block"
				isFormPanel={isFormPanel}
				showOnFocus={rawValue === null}
			>
				<Checkbox
					checked={checked}
					onClick={toggleCheckbox}
					disabled={!canEdit}
					tabIndex={isFormPanel ? 0 : -1}
					id={id}
				/>
			</CheckboxContainer>
		</>
	);
};
