import { getDefaultHeaders } from 'api/utils';

export function changePassword(password: string) {
	return fetch('/api/user/password', {
		method: 'PATCH',
		headers: getDefaultHeaders(),
		body: JSON.stringify({
			password,
		}),
	});
}
