import { Ace } from 'ace-builds';
import React, { useEffect, useRef } from 'react';
import ReactAceEditor from 'react-ace';
import ReactAce from 'react-ace/lib/ace';
import styled from 'styled-components';

import { useTheme } from 'components/providers/ThemeProvider';
import {
	JSON_AND_RICH_TEXT_HEIGHT_IN_REM,
	JSON_AND_RICH_TEXT_WIDTH_IN_REM,
} from 'utils/constants';
import styles from 'utils/styles';

import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-xcode';
import 'ace-builds/src-min-noconflict/theme-monokai';

const StyledJsonEditor = styled(ReactAceEditor)<{ themeMode: string }>`
	flex-shrink: 0;
	font-size: 14px;
	font-family: ${styles.text.fontFamilyMonospace};
	line-height: 1.5;
	z-index: 1;

	.ace_scroller {
		background: ${(props) =>
			props.themeMode === 'light' ? '#ffffff' : '#151515'};
	}
`;

interface Props {
	value: string;
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur: () => void;
	stopEditing: () => void;
	saveJsonValue: (editor: Ace.Editor) => void;
	height?: string;
	id?: string;
}

export const JsonEditor = ({
	value,
	onChange,
	onFocus,
	onBlur,
	stopEditing,
	saveJsonValue,
	id,
	...rest
}: Props) => {
	const { themeMode } = useTheme();
	const ref = useRef<ReactAce>(null);

	// Stop editing if user clicks outside of the editor
	// Code taken from react-detect-click-outside
	// https://github.com/zhaluza/react-detect-click-outside/blob/d4b8245f21d8aeda4be6fc529303aa8c59b66c55/src/useDetectClickOutside.tsx#L45-L54
	useEffect(() => {
		const stopEditingOnClickOutside = (e: MouseEvent | TouchEvent) => {
			if (ref.current?.refEditor) {
				if (!ref.current.refEditor.contains(e.target as HTMLElement)) {
					stopEditing();
				}
			}
		};
		document.addEventListener('click', stopEditingOnClickOutside);
		document.addEventListener('touchstart', stopEditingOnClickOutside);

		return () => {
			document.removeEventListener('click', stopEditingOnClickOutside);
			document.removeEventListener('touchstart', stopEditingOnClickOutside);
		};
	}, [stopEditing]);

	return (
		<StyledJsonEditor
			ref={ref}
			className="fs-mask highlight-block"
			mode="json"
			theme={themeMode === 'light' ? 'xcode' : 'monokai'}
			themeMode={themeMode}
			width={`${JSON_AND_RICH_TEXT_WIDTH_IN_REM}rem`}
			height={`${JSON_AND_RICH_TEXT_HEIGHT_IN_REM}rem`}
			showGutter={false}
			highlightActiveLine={false}
			showPrintMargin={false}
			value={value || ''}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			editorProps={{
				$blockScrolling: Infinity,
			}}
			onLoad={(editor) => {
				// Allow use tab and shift + tab to focus in and out of editor
				// Users can use ctrl + [ and ctrl + ] to indent instead
				editor.commands.removeCommand(editor.commands.byName.indent);
				editor.commands.removeCommand(editor.commands.byName.outdent);
				if (id) {
					document
						.querySelector('#ace-editor textarea')
						?.setAttribute('id', id);
				}
			}}
			commands={[
				{
					name: 'Discard changes',
					bindKey: { win: 'Escape', mac: 'Escape' },
					exec: stopEditing,
				},
				{
					name: 'Submit changes',
					bindKey: { win: 'Ctrl-Enter', mac: 'Command-Enter' },
					exec: saveJsonValue,
				},
			]}
			{...rest}
		/>
	);
};
