import { HTMLInputProps } from '@blueprintjs/core';
import React from 'react';
import styled, { css } from 'styled-components';

import {
	background,
	bodyBackground,
	danger,
	neutral,
	primary,
	warning,
} from 'utils/colors';
import styles from 'utils/styles';

import Field, { FieldProps } from './Field';

const InputWrapper = styled.div<{ pointer?: boolean }>`
	position: relative;
	cursor: ${(props) => (props.pointer ? 'pointer' : 'select')};
`;

const IconWrapper = styled.div`
	position: absolute;
	right: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
`;

interface InputProps {
	as?: 'input' | 'textarea';
	label?: React.ReactNode;
	optional?: boolean;
	errors?: string[] | null;
	helpText?: React.ReactNode;
	name?: string;
	value?: string | number | null;
	type?: HTMLInputProps['type'];
	placeholder?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
	autoFocus?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	spellCheck?: boolean;
	step?: number;
	highlight?: boolean;
	mini?: boolean;
	maskContent?: boolean;
	icon?: React.ReactElement;
	/** Used for inputs that shouldn't be disabled, but have the background color of a disabled input */
	disabledBackground?: boolean;
	pointer?: boolean;
	className?: string;
	labelWidth?: FieldProps['labelWidth'];
	labelSize?: FieldProps['labelSize'];
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface InputRootProps {
	hasLabel?: boolean;
	hasHelpText?: boolean;
	hasErrors?: boolean;
	disabled?: boolean;
	shadow?: boolean;
	highlight?: boolean;
	mini?: boolean;
	pointer?: boolean;
	disabledBackground?: boolean;
	as: 'input' | 'textarea';

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[propName: string]: any;
}

export const InputRoot = styled.input<InputRootProps>`
	display: block;
	width: 100%;
	padding: 0.5rem;
	font-size: 0.875rem;
	color: ${neutral[1]};
	background: ${(props) =>
		props.disabled || props.disabledBackground || props.readOnly
			? bodyBackground
			: background[1]};
	border: none;
	border-radius: ${styles.global.borderRadius};
	box-shadow: 0 0 0 1px ${neutral[4]};
	appearance: none;
	resize: ${({ as }) => (as === 'input' ? 'none' : 'vertical')};
	cursor: ${({ pointer, disabled, readOnly }) => {
		if (pointer) {
			return 'pointer';
		} else if (disabled || readOnly) {
			return 'default';
		} else {
			return 'text';
		}
	}};

	&:focus,
	&:active {
		box-shadow: 0 0 0 2px ${primary[1]};
		outline: none;
	}

	${(props) =>
		props.highlight &&
		css`
			box-shadow: 0 0 0 2px ${warning};
		`};

	${(props) =>
		props.disabled &&
		css`
			box-shadow: none;
		`};

	${(props) =>
		props.hasErrors &&
		css`
			box-shadow: 0 0 0 2px ${danger};
		`};

	${(props) =>
		props.mini &&
		css`
			margin-top: 0;
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
		`};
`;

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef(
	(
		{
			label,
			optional,
			errors,
			helpText,
			name,
			value,
			type,
			placeholder,
			onChange,
			onKeyDown,
			onFocus,
			onClick,
			autoFocus,
			readOnly,
			disabled,
			spellCheck,
			step,
			highlight,
			mini,
			maskContent,
			icon,
			pointer,
			disabledBackground,
			className,
			labelWidth,
			labelSize,
			onBlur,
			as = 'input',
		}: InputProps,
		ref: React.Ref<HTMLInputElement>
	) => {
		return (
			<Field
				type="input"
				label={label}
				optional={optional}
				errors={errors}
				helpText={helpText}
				inline={mini}
				className={className}
				labelWidth={labelWidth}
				labelSize={labelSize}
			>
				<InputWrapper pointer={pointer}>
					<InputRoot
						ref={ref}
						as={as}
						className={maskContent ? 'fs-mask highlight-ignore' : undefined}
						hasLabel={!!label}
						hasHelpText={!!helpText}
						hasErrors={errors && errors.length}
						name={name}
						value={value}
						type={type}
						placeholder={placeholder}
						onChange={onChange}
						onKeyDown={onKeyDown}
						onFocus={onFocus}
						onClick={onClick}
						autoFocus={autoFocus}
						readOnly={readOnly}
						disabled={disabled}
						disabledBackground={disabledBackground}
						spellCheck={spellCheck}
						step={step?.toString()}
						highlight={highlight}
						mini={mini}
						pointer={pointer}
						onBlur={onBlur}
					/>
					{icon && <IconWrapper>{icon}</IconWrapper>}
				</InputWrapper>
			</Field>
		);
	}
);
