import deepEqual from 'deep-equal';
import { ChangeEvent, FormEvent, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import { useUpdateApiView } from 'api/reactQueryHooks/useUpdateApiView';
import Button from 'components/Button';
import { DataTypeIcon } from 'components/DataTypeIcon';
import { Popover } from 'components/Popover';
import { SidebarItem } from 'components/Sidebar/SidebarItem';
import { getAttributeTypeFromDbColumnType } from 'components/Table/tableUtils';
import { Input } from 'components/fields/Input';
import Select from 'components/fields/Select';
import { ReactComponent as MinusIcon } from 'images/icons/minus.svg';
import {
	ApiEnum,
	ApiForeignKey,
	ApiRecordAttribute,
	ApiWorkspaceDetails,
	Filter,
} from 'typings/serverTypes';
import { TableData__View, ExtractedTableData, ViewType } from 'typings/types';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getHumanizedOperator } from 'utils/getHumanizedOperator';
import { getTableDisplayNameFromSchemaAndTableName } from 'utils/getTableDisplayNameFromSchemaAndTableName';
import { state } from 'valtioState';
import { updateViewRecordsStaleStatus } from 'valtioState/views/updateViewRecordsStaleStatus';

const DropdownContainer = styled.ul`
	min-width: 16rem;
	margin-bottom: ${(props) =>
		// @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
		props.children.length ? '1rem' : '0'};
`;

const DropdownRow = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.5rem 0;
	font-size: 0.875rem;
	user-select: none;

	* + * {
		margin-left: 0.75rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ColumnSelect = styled(Select)`
	max-width: 10rem;
`;

const RemoveButton = styled(Button)`
	padding: 0.125rem;
	background: none;
	margin-right: -0.375rem;
	visibility: hidden;
`;

const StyledSidebarItem = styled(SidebarItem)`
	&:hover {
		${RemoveButton} {
			visibility: visible;
		}
	}
`;

interface FilterPopoverProps {
	data: TableData__View<ViewType.BASIC>;
	originalFilter: Filter;
	workspace: ApiWorkspaceDetails;
	column: ApiRecordAttribute;
	foreignKeys: ApiForeignKey[];
	enums: ApiEnum[];
	openFilterId: number;
	setOpenFilterId: (filterId: number) => void;
	extractedTableData: ExtractedTableData;
}

export function FilterPopover({
	data,
	originalFilter,
	workspace,
	column,
	foreignKeys,
	enums,
	openFilterId,
	setOpenFilterId,
	extractedTableData,
}: FilterPopoverProps) {
	const snap = useSnapshot(state);
	const { attributes, databaseId, joins } = extractedTableData;

	// Using useMemo to avoid infinite rerender warning
	const savedFilters = useMemo(() => data.view.filters ?? [], [data]);

	const { mutate: updateApiView } = useUpdateApiView(
		{
			view: data.view,
		},
		{
			onSuccess: () => {
				if (data.type === 'view') {
					updateViewRecordsStaleStatus(data.view.id, true);
				}
			},
		}
	);

	const [filter, setFilter] = useState<Filter>(originalFilter);
	const [filters, setFilters] = useState<Filter[]>(savedFilters);
	const [appliedFilters, setAppliedFilters] = useState<Filter[]>(savedFilters);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setFilters(savedFilters);
	}, [data.view, savedFilters]);

	useEffect(() => {
		setOpen(originalFilter.id === openFilterId);
	}, [openFilterId, originalFilter]);

	const filterChanged = useMemo(
		() =>
			!deepEqual(
				filters.filter((filter) => filter.id === originalFilter.id),
				appliedFilters.filter((filter) => filter.id === originalFilter.id)
			),
		[filters, originalFilter, appliedFilters]
	);

	const removeFilter = (filterId: number) => {
		const updatedFilters = filters.filter((filter) => filter.id !== filterId);
		setFilters(updatedFilters);
		updateApiView({
			view: {
				id: data.view.id,
				filters: updatedFilters,
			},
		});
	};

	const updateFilter = (
		filterId: number,
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const targetName = event.target.name as
			| 'column'
			| 'operator'
			| 'specifier'
			| 'content';

		const existingFilter = filters.find((filter) => filter.id === filterId);

		if (!existingFilter) {
			return;
		}

		const updatedFilter = { ...existingFilter };

		if (targetName === 'column') {
			const columnIdentifier = JSON.parse(event.target.value);
			const column = attributes.find(
				(column) =>
					column.schemaName === columnIdentifier.schemaName &&
					column.tableName === columnIdentifier.tableName &&
					column.name === columnIdentifier.columnName
			);
			if (!column) {
				return;
			}
			const columnType = column.typeInDb;
			const attributeType = getAttributeTypeFromDbColumnType({
				dbColumnType: columnType,
			});

			updatedFilter.schemaName = columnIdentifier.schemaName;
			updatedFilter.tableName = columnIdentifier.tableName;
			updatedFilter.columnName = columnIdentifier.columnName;
			updatedFilter.attributeType = attributeType;
			// If changing the column / attribute type, we need to set the specifier and content
			// to undefined in order to reset it
			updatedFilter.specifier = undefined;
			updatedFilter.content = undefined;

			switch (attributeType) {
				case 'NUMBER': {
					updatedFilter.operator = '=';
					updatedFilter.content = '0';
					break;
				}
				case 'DATETIME':
				case 'DATE': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'today';
					updatedFilter.content = null;
					break;
				}
				case 'TIME': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'now';
					updatedFilter.content = null;
					break;
				}
				case 'BOOLEAN': {
					updatedFilter.operator = '=';
					updatedFilter.specifier = 'is true';
					updatedFilter.content = null;
					break;
				}
				case 'ARRAY': {
					updatedFilter.operator = '=';
					updatedFilter.content = '{}';
					break;
				}
				case 'TEXT':
				case 'JSON':
				default: {
					updatedFilter.operator = '=';
					updatedFilter.content = '';
					break;
				}
			}
		} else {
			if (targetName in updatedFilter) {
				updatedFilter[targetName] = event.target.value;
			}
		}

		// Setting individual filter here so component will not have to rerender * any * filter changes
		// and rather, only when the local filter changes
		setFilter(updatedFilter);
		setFilters(
			filters.map((filter) => (filter.id === filterId ? updatedFilter : filter))
		);
	};

	const applyChanges = async (event?: FormEvent<HTMLFormElement>) => {
		if (event) {
			event.preventDefault();
		}

		if (!filterChanged) {
			return;
		}

		setAppliedFilters(filters.map((filter) => ({ ...filter })));
		setOpenFilterId(-1);
		updateApiView({
			view: {
				id: data.view.id,
				filters,
			},
		});
	};

	const hasOperator = (filter: Filter) => {
		return filter.attributeType !== 'BOOLEAN';
	};

	const hasSpecifier = (filter: Filter) => {
		if (filter.attributeType === 'BOOLEAN') {
			return true;
		}
		if (
			['DATETIME', 'DATE', 'TIME', 'ARRAY', 'JSON'].includes(
				filter.attributeType
			)
		) {
			if (filter.operator !== 'is null' && filter.operator !== 'is not null') {
				return true;
			}
		}

		return false;
	};

	const contentInputType = (filter: Filter): 'text' | 'number' | null => {
		if (
			['is empty', 'is not empty', 'is null', 'is not null'].includes(
				filter.operator
			)
		) {
			return null;
		}

		if (filter.attributeType === 'ARRAY') {
			if (hasSpecifier(filter)) {
				return 'text';
			}
		}

		if (filter.attributeType === 'JSON') {
			if (hasSpecifier(filter)) {
				return 'text';
			}
		}

		if (filter.attributeType === 'BOOLEAN') {
			return null;
		}

		if (filter.attributeType === 'NUMBER') {
			return 'number';
		}

		if (filter.attributeType === 'TEXT') {
			return 'text';
		}

		if (['DATETIME', 'DATE'].includes(filter.attributeType)) {
			if (hasSpecifier(filter)) {
				if (
					filter.specifier === 'number of days ago' ||
					filter.specifier === 'number of days from now'
				) {
					return 'number';
				}

				if (filter.specifier === 'exact date') {
					return 'text';
				}
			}
		}

		if (filter.attributeType === 'TIME') {
			if (hasSpecifier(filter)) {
				if (
					filter.specifier === 'number of minutes ago' ||
					filter.specifier === 'number of minutes from now'
				) {
					return 'number';
				}

				if (filter.specifier === 'exact time') {
					return 'text';
				}
			}
		}

		return null;
	};

	const hasContent = (filter: Filter) => {
		return contentInputType(filter) !== null;
	};

	const iconLeft = (
		<DataTypeIcon
			workspace={workspace}
			sqlDatabaseId={databaseId}
			attribute={column}
			foreignKeys={foreignKeys}
			enums={enums}
		/>
	);

	const attributeDisplayName = getAttributeDisplayName({
		attributesById: snap.entities.attributes.byId,
		attributeNamesToIdMap: snap.attributeNamesToIdMap,
		attributeName: column.name,
		tableName: column.tableName,
		schemaName: column.schemaName,
		sqlDatabaseId: databaseId,
	});

	const getFilterDescription = (filter: Filter): string => {
		const { operator, specifier, content } = filter;
		const humanizedOperator = getHumanizedOperator(
			operator,
			filter.attributeType
		);

		let formattedContent = '';
		switch (contentInputType(filter)) {
			case 'text': {
				formattedContent = `"${content}"`;
				break;
			}
			case 'number': {
				formattedContent = String(content);
				break;
			}
			case null: {
				formattedContent = '';
				break;
			}
		}

		return `${attributeDisplayName} ${humanizedOperator} ${
			specifier ?? ''
		} ${formattedContent}`;
	};

	const filterDescription = getFilterDescription(filter);

	return (
		<Popover
			trigger={
				<StyledSidebarItem
					key={filter.id}
					iconLeft={iconLeft}
					buttonRight={
						<RemoveButton
							onClick={removeFilter.bind(null, filter.id)}
							title="Remove filter"
							icon={<MinusIcon />}
						/>
					}
					title={filterDescription}
				/>
			}
			side="right"
			align="start"
			onBlur={applyChanges}
			open={open}
			setOpen={setOpen}
		>
			<DropdownContainer>
				<form onSubmit={applyChanges} id="filterForm">
					<DropdownRow key={filter.id}>
						<ColumnSelect
							mini
							name="column"
							value={JSON.stringify({
								schemaName: filter.schemaName,
								tableName: filter.tableName,
								columnName: filter.columnName,
							})}
							onChange={updateFilter.bind(null, filter.id)}
							options={attributes.map((column) => {
								const tableDisplayName =
									getTableDisplayNameFromSchemaAndTableName({
										tablesById: snap.entities.tables.byId,
										tableNamesToIdMap: snap.tableNamesToIdMap,
										schemaName: column.schemaName ?? '',
										tableName: column.tableName,
										sqlDatabaseId: databaseId,
									});
								const attributeDisplayName = getAttributeDisplayName({
									attributesById: snap.entities.attributes.byId,
									attributeNamesToIdMap: snap.attributeNamesToIdMap,
									attributeName: column.name,
									tableName: column.tableName,
									schemaName: column.schemaName,
									sqlDatabaseId: databaseId,
								});
								return {
									label:
										(joins.length ?? 0) > 0
											? `${tableDisplayName} ${attributeDisplayName}`
											: attributeDisplayName,
									value: JSON.stringify({
										schemaName: column.schemaName,
										tableName: column.tableName,
										columnName: column.name,
									}),
								};
							})}
						/>

						{hasOperator(filter) && (
							<Select
								mini
								name="operator"
								value={filter.operator}
								onChange={updateFilter.bind(null, filter.id)}
								options={
									filter.attributeType === 'NUMBER'
										? [
												{
													label: '=',
													value: '=',
												},
												{
													label: '≠',
													value: '!=',
												},
												{
													label: '<',
													value: '<',
												},
												{
													label: '≤',
													value: '<=',
												},
												{
													label: '>',
													value: '>',
												},
												{
													label: '≥',
													value: '>=',
												},
												{
													label: 'is null',
													value: 'is null',
												},
												{
													label: 'is not null',
													value: 'is not null',
												},
										  ]
										: ['ARRAY', 'JSON'].includes(filter.attributeType)
										? [
												{
													label: 'is...',
													value: '=',
												},
												{
													label: 'is not...',
													value: '!=',
												},
												{
													label: 'is null',
													value: 'is null',
												},
												{
													label: 'is not null',
													value: 'is not null',
												},
										  ]
										: ['DATETIME', 'DATE', 'TIME'].includes(
												filter.attributeType
										  )
										? [
												{
													label: 'is...',
													value: '=',
												},
												{
													label: 'is not...',
													value: '!=',
												},
												{
													label: 'is before...',
													value: '<',
												},
												{
													label: 'is after...',
													value: '>',
												},
												{
													label: 'is on or before...',
													value: '<=',
												},
												{
													label: 'is on or after...',
													value: '>=',
												},
												{
													label: 'is null',
													value: 'is null',
												},
												{
													label: 'is not null',
													value: 'is not null',
												},
										  ]
										: [
												{
													label: 'is...',
													value: '=',
												},
												{
													label: 'is not...',
													value: '!=',
												},
												{
													label: 'contains...',
													value: 'contains',
												},
												{
													label: 'does not contain...',
													value: 'does not contain',
												},
												{
													label: 'is empty',
													value: 'is empty',
												},
												{
													label: 'is not empty',
													value: 'is not empty',
												},
										  ]
								}
							/>
						)}

						{hasSpecifier(filter) && (
							<>
								{['DATETIME', 'DATE'].includes(filter.attributeType) && (
									<Select
										mini
										name="specifier"
										value={filter.specifier ?? ''}
										onChange={updateFilter.bind(null, filter.id)}
										options={[
											{
												label: 'today',
												value: 'today',
											},
											{
												label: 'number of days ago...',
												value: 'number of days ago',
											},
											{
												label: 'number of days from now...',
												value: 'number of days from now',
											},
											{
												label: 'exact date...',
												value: 'exact date',
											},
										]}
									/>
								)}
								{filter.attributeType === 'TIME' && (
									<Select
										mini
										name="specifier"
										value={filter.specifier ?? ''}
										onChange={updateFilter.bind(null, filter.id)}
										options={[
											{
												label: 'now',
												value: 'now',
											},
											{
												label: 'number of minutes ago...',
												value: 'number of minutes ago',
											},
											{
												label: 'number of minutes from now...',
												value: 'number of minutes from now',
											},
											{
												label: 'exact time...',
												value: 'exact time',
											},
										]}
									/>
								)}
								{filter.attributeType === 'BOOLEAN' && (
									<Select
										mini
										name="specifier"
										value={filter.specifier ?? ''}
										onChange={updateFilter.bind(null, filter.id)}
										options={[
											{
												label: 'is true',
												value: 'is true',
											},
											{
												label: 'is false',
												value: 'is false',
											},
											{
												label: 'is null',
												value: 'is null',
											},
											{
												label: 'is not null',
												value: 'is not null',
											},
										]}
									/>
								)}
							</>
						)}

						{hasContent(filter) && (
							<Input
								mini
								name="content"
								// @ts-expect-error contentInputType cannot be null
								type={contentInputType(filter)}
								// @ts-expect-error FIX
								value={filter.content}
								onChange={updateFilter.bind(null, filter.id)}
							/>
						)}
					</DropdownRow>
				</form>
			</DropdownContainer>

			<ButtonContainer>
				{filterChanged && (
					<Button primary form="filterForm">
						Apply changes
					</Button>
				)}
			</ButtonContainer>
		</Popover>
	);
}
