import styled, { css } from 'styled-components';

import Button from 'components/Button';
import { background, neutral } from 'utils/colors';
import { media } from 'utils/styles';

const Page = styled.main<{ primary?: boolean }>`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex-grow: 1;

	${({ primary }) =>
		primary &&
		css`
			background: ${background[1]};
		`}

	${media.tabletUp`
		height: 100%;
	`};

	${media.mobile`
		width: 50rem;
		align-self: stretch;
	`};
`;

export const InfoContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InfoText = styled.p`
	font-size: 1.5rem;
	color: ${neutral[2]};
	cursor: default;

	& + & {
		margin-top: 0.5rem;
	}
`;

export const InfoTextDetails = styled.p`
	margin-top: 2rem;
	max-width: 40rem;
	font-size: 1rem;
	line-height: 1.5;
	text-align: center;
	color: ${neutral[2]};
	cursor: default;
`;

export const InfoButton = styled(Button).attrs({
	primary: true,
	size: 'large',
})`
	margin-top: 2rem;
`;

export default Page;
