import styled from 'styled-components';
import { useSnapshot } from 'valtio';

import {
	CELL_SPACING,
	CheckboxCell,
	ListViewTableRow,
	TABLE_ROW_HORIZONTAL_PADDING,
} from 'components/ListView/ListViewTableRow';
import { FormPanelProperties } from 'components/ListView/index';
import { PrimaryAttribute } from 'typings/models';
import {
	ApiBasicView,
	ApiRecordAttribute,
	CellRecord,
} from 'typings/serverTypes';
import { areAttributesEqual } from 'utils/areAttributesEqual';
import { background, neutral } from 'utils/colors';
import { getAttributeDisplayName } from 'utils/getAttributeDisplayName';
import { getTableDisplayName } from 'utils/getTableDisplayName';
import { state } from 'valtioState';

const StyledListViewTable = styled.table`
	padding: 0 0.5rem 0.5rem 0.5rem;
	table-layout: fixed;
	background-color: ${background[1]};
`;
const AttributeHeaderRow = styled.tr`
	height: 2.75rem;
	font-size: 0.75rem;
	padding-top: 0.5rem;
`;
const AttributeHeaderText = styled.span`
	color: ${neutral[1]};
	opacity: 0.4;
	font-weight: 300;
`;

export const AttributeHeader = styled.th<{
	isPrimary?: boolean;
}>`
	text-align: left;
	width: ${({ isPrimary }) => (isPrimary ? '100%' : 'auto')};
	white-space: nowrap;
	padding-right: ${CELL_SPACING};
	position: sticky;
	top: 0;
	// Required to get sticky header to appear over checkboxes in list rows
	z-index: 1;
	background-color: ${background[1]};
	border-radius: 0;

	&:last-child {
		padding-right: ${TABLE_ROW_HORIZONTAL_PADDING};
	}
`;

interface Props {
	primaryAttributeConfig: ApiRecordAttribute | undefined;
	view: ApiBasicView;
	records: CellRecord[] | null;
	primaryAttribute: PrimaryAttribute | undefined;
	pageNumber: number;
	formPanelProperties: {
		isOpen: boolean;
		record: CellRecord | null;
		rowNumber: number | null;
	};
	setFormPanelProperties: React.Dispatch<
		React.SetStateAction<FormPanelProperties>
	>;
	primaryAttributes: PrimaryAttribute[];
}

export function ListViewTable({
	primaryAttributeConfig,
	view,
	records,
	formPanelProperties,
	setFormPanelProperties,
	pageNumber,
	primaryAttributes,
}: Props) {
	const hasJoins = view.joins.length > 0;
	const dataSourceId = view.sqlDatabaseId;
	const snap = useSnapshot(state);
	if (dataSourceId == null) {
		return null;
	}
	const primaryAttributeTableDisplayName =
		(primaryAttributeConfig &&
			getTableDisplayName({
				tablesById: snap.entities.tables.byId,
				tableId: primaryAttributeConfig.tableId,
			})) ??
		'';
	const primaryAttributeDisplayName =
		(primaryAttributeConfig &&
			getAttributeDisplayName({
				attributesById: snap.entities.attributes.byId,
				attributeNamesToIdMap: snap.attributeNamesToIdMap,
				attributeName: primaryAttributeConfig.attributeName,
				tableName: primaryAttributeConfig.tableName,
				schemaName: primaryAttributeConfig.schemaName,
				sqlDatabaseId: dataSourceId,
			})) ??
		'';
	return (
		<StyledListViewTable cellSpacing={0}>
			<thead>
				<AttributeHeaderRow>
					<CheckboxCell as={'th'} />
					<AttributeHeader isPrimary>
						<AttributeHeaderText>
							{primaryAttributeConfig
								? hasJoins
									? `${primaryAttributeTableDisplayName}.${primaryAttributeDisplayName}`
									: primaryAttributeDisplayName
								: ''}
						</AttributeHeaderText>
					</AttributeHeader>

					{view.listAttributes.map((listAttribute) => {
						if (
							!listAttribute.visible ||
							(primaryAttributeConfig
								? areAttributesEqual({
										listAttribute,
										attribute: primaryAttributeConfig,
								  })
								: false)
						) {
							return null;
						}
						const tableDisplayName = getTableDisplayName({
							tablesById: snap.entities.tables.byId,
							tableId: listAttribute.tableId,
						});
						const attributeDisplayName = getAttributeDisplayName({
							attributesById: snap.entities.attributes.byId,
							attributeNamesToIdMap: snap.attributeNamesToIdMap,
							attributeName: listAttribute.attributeName,
							tableName: listAttribute.tableName,
							schemaName: listAttribute.schemaName,
							sqlDatabaseId: dataSourceId,
						});
						const name = hasJoins
							? `${tableDisplayName}.${attributeDisplayName}`
							: `${attributeDisplayName}`;
						return (
							<AttributeHeader key={name}>
								<AttributeHeaderText>{name}</AttributeHeaderText>
							</AttributeHeader>
						);
					})}
				</AttributeHeaderRow>
			</thead>
			<tbody>
				{records?.map((record, index) => (
					<ListViewTableRow
						record={record}
						view={view}
						primaryAttribute={primaryAttributeConfig}
						setFormPanelProperties={setFormPanelProperties}
						formPanelProperties={formPanelProperties}
						rowNumber={index + 1}
						pageNumber={pageNumber}
						primaryAttributes={primaryAttributes}
						key={index}
					/>
				))}
			</tbody>
		</StyledListViewTable>
	);
}
